//Standard libraries
import { SvgStar } from "../../svg/star";

const Stars = (props: any) => {
  const big = props.big;
  const note = props.note;
  const color = props.color;
  const splitVlaue: any = (note + "").split(".");

  return (
    <>
      <SvgStar
        className={`${big && "big"} ${color}`}
        rating={note > 0 && note < 1 ? `${Number(splitVlaue[1]) * 1.4}` : note >= 1 ? "14" : "0"}
      />
      <SvgStar
        className={`${big && "big"} ${color}`}
        rating={note > 1 && note < 2 ? `${Number(splitVlaue[1]) * 1.4}` : note >= 2 ? "14" : "0"}
      />
      <SvgStar
        className={`${big && "big"} ${color}`}
        rating={note > 2 && note < 3 ? `${Number(splitVlaue[1]) * 1.4}` : note >= 3 ? "14" : "0"}
      />
      <SvgStar
        className={`${big && "big"} ${color}`}
        rating={note > 3 && note < 4 ? `${Number(splitVlaue[1]) * 1.4}` : note >= 4 ? "14" : "0"}
      />
      <SvgStar
        className={`${big && "big"} ${color}`}
        rating={note > 4 && note < 5 ? `${Number(splitVlaue[1]) * 1.4}` : note >= 5 ? "14" : "0"}
      />
    </>
  );
};

export { Stars };
