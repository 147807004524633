/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import { lazy } from "react";
//Custom libraries
import * as ROUTES from "../constants/routes";

//Common pages
const SEO = lazy(() => import("../_foundation/seo/SEO"));

//component
const SignIn = lazy(() => import("../components/pages/sign-in/SignIn"));
const Registration = lazy(() => import("../components/pages/registration/Registration"));
const ForgotPassword = lazy(() => import("../components/pages/password/ForgotPassword"));
const ResetPassword = lazy(() => import("../components/pages/password/ResetPassword"));
const SearchResults = lazy(() => import("../components/pages/search-results/SearchResults"));
const PromotionPage = lazy(() => import("../components/pages/promotion/Promotion"));
//const StoreLocator = lazy(() => import("../components/pages/store-locator/store-locator"));
const ProhibitedCharactersErrorView = lazy(() => import("../components/pages/error-view/prohibited-characters-error"));

const Realization = lazy(() => import("../components/pages/realization"));
const Collection = lazy(() => import("../components/pages/collection"));
const KitchenModel = lazy(() => import("../components/pages/kitchen-model"));
const ConfiguratorHub = lazy(() => import("../components/pages/configurator-hub"));
const Faq = lazy(() => import("../components/pages/faq"));
const Contact = lazy(() => import("../components/pages/contact"));
const PriseDeRdv = lazy(() => import("../components/pages/prise-de-rdv"));
const Project = lazy(() => import("../components/pages/project"));
const ProjectCreate = lazy(() => import("../components/pages/project-create"));
const ModelsList = lazy(() => import("../components/pages/models-list"));
const HubSav = lazy(() => import("../components/pages/sav"));
const SavClaim = lazy(() => import("../components/pages/sav-claim"));
const HubPromo = lazy(() => import("../components/pages/hub-promo"));
const OrderDrive = lazy(() => import("../components/pages/order-drive"));
const Rhinov = lazy(() => import("../components/layouts/rhinov"));
const StaticPageLayout = lazy(() => import("../components/layouts/static-page/static-page-layout"));

//checkout
const Shipping = lazy(() => import("../components/pages/checkout/shipping/Shipping"));
const Billing = lazy(() => import("../components/pages/checkout/payment"));
const Review = lazy(() => import("../components/pages/checkout/review"));

const Account = lazy(() => import("../components/pages/account/Account"));
const Checkout = lazy(() => import("../components/pages/checkout/Checkout"));
const OrderDetailsPage = lazy(() => import("../components/pages/order/OrderDetailsPage"));

const CheckoutRouteConfig = [
  {
    key: ROUTES.CHECKOUT_SHIPPING,
    path: ROUTES.CHECKOUT_SHIPPING,
    isProtected: ROUTES.REGISTER_PROTECTED,
    element: <Shipping />,
  },
  {
    key: ROUTES.CHECKOUT_PAYMENT,
    path: ROUTES.CHECKOUT_PAYMENT,
    isProtected: ROUTES.REGISTER_PROTECTED,
    element: <Billing />,
  },
  {
    key: ROUTES.CHECKOUT_REVIEW,
    path: ROUTES.CHECKOUT_REVIEW,
    isProtected: ROUTES.REGISTER_PROTECTED,
    element: <Review />,
  },
];

// const StoreLocatorConfig = {
//   key: ROUTES.STORE_LOCATOR,
//   path: ROUTES.STORE_LOCATOR,
//   exact: true,
//   element: <StoreLocator />,
// };

const B2CRouteConfig: any[] = [
  {
    key: ROUTES.SIGNIN,
    path: ROUTES.SIGNIN,
    exact: true,
    //isProtected: ROUTES.GENERIC_PROTECTED,
    element: <SignIn />,
  },
  {
    key: ROUTES.REGISTRATION,
    path: ROUTES.REGISTRATION,
    exact: true,
    //isProtected: ROUTES.GENERIC_PROTECTED,
    element: <Registration />,
  },
  {
    key: ROUTES.PROHIBITED_CHAR_ERROR_VIEW,
    path: ROUTES.PROHIBITED_CHAR_ERROR_VIEW,
    exact: true,
    isProtected: ROUTES.GENERIC_PROTECTED,
    element: <ProhibitedCharactersErrorView />,
  },
  {
    key: ROUTES.FORGOT_PASSWORD,
    path: ROUTES.FORGOT_PASSWORD,
    exact: true,
    isProtected: ROUTES.GENERIC_PROTECTED,
    element: <ForgotPassword />,
  },
  {
    key: ROUTES.RESET_PASSWORD,
    path: ROUTES.RESET_PASSWORD,
    exact: true,
    isProtected: ROUTES.GENERIC_PROTECTED,
    element: <ResetPassword />,
  },
  {
    key: `${ROUTES.ACCOUNT}/*`,
    path: `${ROUTES.ACCOUNT}/*`,
    isProtected: ROUTES.REGISTER_PROTECTED,
    element: <Account />,
  },
  {
    key: ROUTES.SEARCH,
    path: ROUTES.SEARCH,
    exact: true,
    element: <SearchResults />,
  },
  {
    key: `${ROUTES.PROMOTIONPAGE}/*`,
    path: `${ROUTES.PROMOTIONPAGE}/*`,
    element: <PromotionPage />,
  },
  {
    key: ROUTES.ORDER_DETAILS,
    path: ROUTES.ORDER_DETAILS_ROUTE,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    element: <OrderDetailsPage />,
  },
  {
    key: ROUTES.CHECKOUT,
    path: ROUTES.CHECKOUT,
    element: <Checkout />,
    children: CheckoutRouteConfig,
  },
  {
    key: ROUTES.REALIZATION,
    path: ROUTES.REALIZATION,
    exact: true,
    element: <Realization />,
  },
  {
    key: ROUTES.COLLECTION,
    path: ROUTES.COLLECTION,
    exact: true,
    element: <Collection />,
  },
  {
    key: ROUTES.KITCHEN_MODEL,
    path: ROUTES.KITCHEN_MODEL,
    exact: true,
    element: <KitchenModel />,
  },
  {
    key: ROUTES.CONFIGURATOR_HUB,
    path: ROUTES.CONFIGURATOR_HUB,
    exact: true,
    element: <ConfiguratorHub />,
  },
  {
    key: ROUTES.FAQ,
    path: ROUTES.FAQ,
    exact: true,
    element: <Faq />,
  },
  {
    key: ROUTES.CONTACT,
    path: ROUTES.CONTACT,
    exact: true,
    element: <Contact />,
  },
  {
    key: ROUTES.PRISEDERDV,
    path: ROUTES.PRISEDERDV,
    exact: true,
    element: <PriseDeRdv />,
  },
  {
    key: ROUTES.JAIUNPROJET,
    path: ROUTES.JAIUNPROJET,
    exact: true,
    element: <Project />,
  },
  {
    key: ROUTES.AMORCEPROJET,
    path: ROUTES.AMORCEPROJET,
    exact: true,
    element: <ProjectCreate />,
  },
  {
    key: ROUTES.MODELSLIST,
    path: ROUTES.MODELSLIST,
    exact: true,
    element: <ModelsList />,
  },
  {
    key: ROUTES.MODELSLIST_BIS,
    path: ROUTES.MODELSLIST_BIS,
    exact: true,
    element: <ModelsList />,
  },
  {
    key: ROUTES.SAV,
    path: ROUTES.SAV,
    exact: true,
    element: <HubSav />,
  },
  {
    key: ROUTES.SAVCLAIM,
    path: ROUTES.SAVCLAIM,
    exact: true,
    element: <SavClaim />,
  },
  {
    key: ROUTES.HUBPROMO,
    path: ROUTES.HUBPROMO,
    exact: true,
    element: <HubPromo />,
  },
  {
    key: ROUTES.ORDER_DRIVE,
    path: ROUTES.ORDER_DRIVE,
    exact: false,
    isProtected: ROUTES.SIGNIN_PROTECTED,
    element: <OrderDrive />,
  },
  {
    key: ROUTES.RHINOVFORM,
    path: ROUTES.RHINOVFORM,
    exact: false,
    element: <Rhinov />,
  },
  {
    key: ROUTES.MAGAZINE,
    path: ROUTES.MAGAZINE,
    exact: false,
  },
  {
    key: ROUTES.CONTENT,
    path: ROUTES.CONTENT,
    exact: true,
    element: <StaticPageLayout />,
  },
  {
    key: "other",
    path: "/*",
    exact: true,
    element: <SEO />,
  },
];

export const ROUTE_CONFIG = {
  B2C: B2CRouteConfig,
  Checkout: CheckoutRouteConfig,
};
