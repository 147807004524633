//Standard libraries
import React from "react";
import { StyledGrid } from "@hcl-commerce-store-sdk/react-component";
import { StyledBox, StyledContainer } from "../../styled-mui";
import { SvgVisa } from "../svg/visa";
import { SvgMastercard } from "../svg/mastercard";
import { SvgAmex } from "../svg/amex";
import { SvgCb } from "../svg/cb";
import { SvgSofinco } from "../svg/sofinco";

/**
 * FooterLogos component
 *
 * @param props
 */
const FooterLogos: React.FC = (props: any) => {
  return (
    <StyledBox sx={{ bgcolor: "gray.neutralGray800" }}>
      <StyledContainer className="footer-logos no-max-width">
        <StyledGrid container py={8}>
          <StyledGrid xs={12} item className="text-align-center">
            <ul className="footer-logos-list">
              <li>
                <SvgVisa />
              </li>
              <li>
                <SvgMastercard />
              </li>
              <li>
                <SvgAmex />
              </li>
              <li>
                <SvgCb />
              </li>
              <li>
                <SvgSofinco />
              </li>
            </ul>
          </StyledGrid>
        </StyledGrid>
      </StyledContainer>
    </StyledBox>
  );
};

export default FooterLogos;
