import { createAction } from "@reduxjs/toolkit";
import * as ACTIONTYPES from "../action-types/stores";
import { modalType, searchType, productDataType } from "../../types/store-types";
import { MapAttrData } from "../reducers";

const STORES_OPEN_MODAL_ACTION = createAction<{ modalType: modalType; productData?: productDataType }, string>(
  ACTIONTYPES.STORES_OPEN_MODAL
);
const STORES_CLOSE_MODAL_ACTION = createAction(ACTIONTYPES.STORES_CLOSE_MODAL);

const STORES_FAVORITE_GET_REQUEST_ACTION = createAction<any, string>(ACTIONTYPES.STORES_FAVORITE_GET_REQUEST);
const STORES_FAVORITE_GET_SUCCESS_ACTION = createAction<any, string>(ACTIONTYPES.STORES_FAVORITE_GET_SUCCESS);
const STORES_FAVORITE_GET_ERROR_ACTION = createAction<any, string>(ACTIONTYPES.STORES_FAVORITE_GET_ERROR);

const STORES_LIST_GET_REQUEST_ACTION = createAction<
  {
    parameters: any;
    type: searchType;
    productData?: productDataType;
    productStoreId?: string;
    onGeoStoresFetchSuccess?: () => any;
    onNoGeoStoresFound?: () => any;
  },
  string
>(ACTIONTYPES.STORES_LIST_GET_REQUEST);
const STORES_LIST_GET_SUCCESS_ACTION = createAction<any, string>(ACTIONTYPES.STORES_LIST_GET_SUCCESS);
const STORES_LIST_GET_ERROR_ACTION = createAction<any, string>(ACTIONTYPES.STORES_LIST_GET_ERROR);

const STORES_MAP_COORDS_SET_ACTION = createAction<MapAttrData, string>(ACTIONTYPES.STORES_MAP_COORDS_SET);

// const STORE_DELETE_REQUEST_ACTION = createAction<any, string>(ACTIONTYPES.STORE_DELETE_REQUEST);
// const STORE_DELETE_SUCCESS_ACTION = createAction<any, string>(ACTIONTYPES.STORE_DELETE_SUCCESS);
// const STORE_DELETE_ERROR_ACTION = createAction<any, string>(ACTIONTYPES.STORE_DELETE_ERROR);

const STORES_RESET_ACTION = createAction(ACTIONTYPES.STORES_RESET);

export {
  STORES_OPEN_MODAL_ACTION,
  STORES_CLOSE_MODAL_ACTION,
  STORES_FAVORITE_GET_REQUEST_ACTION,
  STORES_FAVORITE_GET_SUCCESS_ACTION,
  STORES_FAVORITE_GET_ERROR_ACTION,
  STORES_LIST_GET_REQUEST_ACTION,
  STORES_LIST_GET_SUCCESS_ACTION,
  STORES_LIST_GET_ERROR_ACTION,
  STORES_MAP_COORDS_SET_ACTION,
  // STORE_DELETE_REQUEST_ACTION,
  // STORE_DELETE_SUCCESS_ACTION,
  // STORE_DELETE_ERROR_ACTION,
  STORES_RESET_ACTION,
};
