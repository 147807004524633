import { useRef } from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import { STORELOCATORACTIONS } from "../../_foundation/constants/common";
import { StyledBox, StyledButton, StyledTypography } from "../../styled-mui";
import { SvgLocationOnOutlined } from "../svg/locationOnOutlined";
import { StyledGrid } from "@hcl-commerce-store-sdk/react-component";
import { useStoreLocatorValue } from "../../_foundation/context/store-locator-context";
import { useDispatch, useSelector } from "react-redux";
import { STORES_OPEN_MODAL_ACTION } from "../../redux/actions/stores";
import { modalType } from "../../types/store-types";
import { getOpening, transformStore } from "../../utils/storeLocatorUtils";
import { userAttributesSelector } from "../../redux/selectors/user";
import { useFetch } from "../../utils/hooks/useFetch";
import getDisplayName from "react-display-name";
import storeLocatorService from "../../_foundation/apis/transaction/storeLocator.service";
import useUpdateEffect from "../../utils/hooks/useUpdateEffect";
import { useTranslation } from "react-i18next";
import AsyncCall from "../../_foundation/gtm/async.service";
import { CLICK } from "../../_foundation/constants/gtm";

export const StoreLocatorButton = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();
  const widgetName = getDisplayName(StoreLocatorButton);
  const btnRef = useRef(null);

  const { userFavStoreId } = useSelector(userAttributesSelector);

  const { data } = useFetch(
    storeLocatorService.findByStoreUniqueIds,
    {
      widget: widgetName,
      physicalStoreId: userFavStoreId,
    },
    [userFavStoreId],
    userFavStoreId ? true : false
  );

  const dispatch = useDispatch();
  const { dispatch: dispatchStoreLocatorValue, storeLocator } = useStoreLocatorValue();
  const selectedStore = storeLocator?.selectedStore || null;

  useUpdateEffect(() => {
    if (data && data.PhysicalStore && userFavStoreId && !selectedStore) {
      dispatchStoreLocatorValue({
        type: STORELOCATORACTIONS.INIT_STORE_SELECTION,
        payload: { selectedStore: transformStore(data.PhysicalStore[0]) },
      });
    }
  }, [data]);

  const handleOpenModal = () => {
    AsyncCall.sendClick(CLICK.STORE_LOCATOR, {});
    dispatch(STORES_OPEN_MODAL_ACTION({ modalType: modalType.MapsSelectStore }));
  };

  const storeOpening = getOpening(
    selectedStore?.storeSchedule,
    selectedStore?.storeExceptionalOpening,
    selectedStore?.storeExceptionalClosing
  );

  return (
    <StyledGrid item className="separateur">
      <StyledButton
        //startIcon={}
        testId="store-locator-invoker-button"
        variant={isMobile && !selectedStore ? "outlined" : "text"}
        color={isMobile && !selectedStore ? "secondary" : "black"}
        className={
          selectedStore && storeOpening ? (storeOpening?.open ? "store-locator-open" : "store-locator-close") : ""
        }
        onClick={handleOpenModal}
        sx={{
          width: isMobile && !selectedStore ? "100%" : "auto",
          textTransform: selectedStore && "none",
          mx: { xs: selectedStore && 3, sm: 0 },
        }}>
        <StyledBox
          className={`${
            selectedStore && storeOpening ? (storeOpening?.open ? "store-locator-open" : "store-locator-close") : ""
          }`}>
          <SvgLocationOnOutlined className={`${isMobile && !selectedStore && "small"}`} />
        </StyledBox>
        {selectedStore ? (
          <StyledTypography variant={isMobile ? "body2" : "caption"} component="p" ml={2} sx={{ textAlign: "left" }}>
            <StyledBox component="span" sx={{ textDecoration: "underline" }}>
              {selectedStore?.name}
            </StyledBox>
            <br />
            {storeOpening && <strong>{`${storeOpening.label} ${storeOpening.nextState}`}</strong>}
          </StyledTypography>
        ) : (
          <div ref={btnRef}>
            <StyledTypography variant={isMobile ? "body1" : "body2"} ml={2} component="p">
              {t("Header.Actions.ChooseMyStore")}
            </StyledTypography>
          </div>
        )}
      </StyledButton>
    </StyledGrid>
  );
};
