/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
import React from "react";
import styled from "@mui/styled-engine-sc";
import Pagination from "@mui/material/Pagination";
import { dimensions } from "../../themes/variables";

const buttonSize = dimensions.pagination.button;

/**
 * Styled component on @material-ui
 * @see https://material-ui.com/api/pagination/
 */
const StyledPagination = styled(({ ...props }) => <Pagination {...props} />)`
  ${({ theme }) => `
  ul {
    li:first-child button {
      margin: 0 ${theme.spacing(6)} 0 0;
    }
    li:last-child button {
      margin: 0 0 0 ${theme.spacing(6)};
    }
    li:first-child,
    li:last-child {
      button {
        border: 0;
      }
    }
  }

  .MuiPaginationItem-root {
    height: 24px;
    min-width: 24px;
    padding: 0;
  }

  .MuiPaginationItem-page {
    background: ${theme.palette.white.main};
    color: ${theme.palette.gray.neutralGray600};
    margin: 0 ${theme.spacing(0.5)};
  }

  .MuiPaginationItem-previousNext {
    color: ${theme.palette.gray.neutralGray600};
  }

  .Mui-selected {
    background: ${theme.palette.secondary.main};
    color: ${theme.palette.white.main};
    font-weight: 700;

    &:hover {
      background: ${theme.palette.secondary.main};
    }
  }

  .MuiPaginationItem-circulared {
    border: 2px solid ${theme.palette.action.disabled};
    height: ${buttonSize}px;
    width: ${buttonSize}px;

    &.Mui-selected {
      border: 0;
      background: ${theme.palette.primary.main};
      color: white;
    }
  }
  `}
`;

export { StyledPagination };
