import SvgIcon from "@mui/material/SvgIcon";
import styled from "@mui/styled-engine-sc";
import { palette } from "../../themes/color-palette";

const CustomSvg = styled((props) => (
  <SvgIcon width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} />
))`
  ${({ theme }) => `
    width:18px;
    height:18px;
    fill: none;

    &.small {
      width:12px;
      height:12px;
    }

    &.medium {
      width:20px;
      height:20px;

      &.fill-white {
        background: ${palette.white.main};
        border-radius: 100%;
      }
    }

    &.success {
      circle {
        fill: ${palette.success.main};
      }
      rect {
        stroke: ${palette.success.main};
      }
    }
  `}
`;

export const SvgRadioButtonIconCheck = (props) => {
  const { className = "" } = props;

  return (
    <CustomSvg {...{ className }}>
      <circle
        cx="9"
        cy="9"
        r={className.indexOf("medium") !== -1 ? "5" : className.indexOf("small") !== -1 ? "4" : "6"}
        fill="#5D89A2"
      />
      <rect x=".5" y=".5" width="17" height="17" rx="8.5" stroke="#9E9E9E" />
    </CustomSvg>
  );
};
