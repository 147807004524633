/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
import { call, put } from "redux-saga/effects";
import productsService from "../../../_foundation/apis/search/products.service";
import * as ACTIONS from "../../action-types/catalog";
import categoryService from "../../../_foundation/apis/search/categories.service";
import { CATEGORY_FIND_IDENTIFIER, FIND_CATEGORIE_BY_UNIQUE_ID } from "../../../constants/common";
import storeUtil from "../../../utils/storeUtil";
import { PAGINATION_CONFIGS } from "../../../constants/order";

/**
 * Saga worker to invoke get product list API
 */
export function* fetchProductList(action: any) {
  const pageDefaultOffset: number = PAGINATION_CONFIGS.pageDefaultOffset;
  try {
    const payload = action.payload;
    const parameters: any = {
      identifier: payload.parameters.identifier,
      query: {
        profileName: CATEGORY_FIND_IDENTIFIER,
      },
    };

    //true state for loader etc.
    yield put({
      type: ACTIONS.PRODCUT_LIST_FETCHING,
      payload: true,
    });

    // console.log("PLP fetch worker param and states: ", payload);
    const response = yield call(productsService.findProductsUsingGET, payload.parameters);

    const selectedFacets = {};

    if (payload?.parameters?.query?.facet?.length > 0 && Array.isArray(payload?.parameters?.query?.facet)) {
      // Traverse all facets in payload.parameters.query.facet
      payload.parameters.query.facet.forEach((facetToFind) => {
        // Decode facetToFind for accurate matching
        const normalizedFacetToFind = decodeURIComponent(facetToFind).trim();

        // Traverse all facets in response.data.facets
        response.data.facets.forEach((facet) => {
          if (facet.entry && Array.isArray(facet.entry)) {
            // Traverse the entry array and compare the value
            facet.entry.forEach((entry) => {
              const normalizedEntryValue = decodeURIComponent(entry.value).trim();

              if (normalizedEntryValue === normalizedFacetToFind) {
                const key = entry.value; // This is the facet entry value
                const value = `${facet.name} : ${entry.label}`; // Concatenate the facet name and entry label

                // Add to selectedFacets
                if (facet.name !== "En promotion") {
                  selectedFacets[key] = value;
                }
              }
            });
          }
        });
      });

      let updatedStates = {};
      if (payload.states.promotionEnabled) {
        updatedStates = {
          selectedFacets: selectedFacets,
          selectedPageOffset: pageDefaultOffset,
          promotionEnabled: true,
        };
      } else {
        updatedStates = {
          selectedFacets: selectedFacets,
          selectedPageOffset: pageDefaultOffset,
        };
      }
      payload.states = {
        ...payload.states,
        ...updatedStates,
      };
      payload.parameters.widget = "facet-navigation-widget";
      delete payload.parameters.query.offset;
    } else {
      console.log("Invalid or missing facet query in payload.");
    }

    if (payload?.parameters?.searchTerm === "*") {
      parameters.identifier = "Promotion";
      parameters.query.profileName = "LP_HCL_V2_findCategoryByIdentifier_Promo";
    }
    const breadCrumbResponse = yield call(categoryService.getV2CategoryResourcesUsingGET, parameters);

    if (breadCrumbResponse.data?.contents?.[0]?.UserData?.[0]?.breadCrumbTrailEntryView) {
      response.data.breadCrumbTrailEntryView =
        breadCrumbResponse.data?.contents?.[0]?.UserData?.[0]?.breadCrumbTrailEntryView;
    }
    response.data.catentryCount = breadCrumbResponse.data?.contents?.[0]["custom.catgroup.catentryCount.raw"];
    response.data.maxPrice = breadCrumbResponse.data?.contents?.[0]["custom.catgroup.maxPrice.raw"];
    response.data.minPrice = breadCrumbResponse.data?.contents?.[0]["custom.catgroup.minPrice.raw"];
    response.data.minFilterPrice = breadCrumbResponse.data?.contents?.[0]["custom.catgroup.filterMinPrice.raw"];
    response.data.maxFilterPrice = breadCrumbResponse.data?.contents?.[0]["custom.catgroup.filterMaxPrice.raw"];
    response.data.reviewCount = breadCrumbResponse.data?.contents?.[0]["custom.catgroup.reviewCount.raw"];
    response.data.averageRating = breadCrumbResponse.data?.contents?.[0]["custom.catgroup.averageRating.raw"];
    response.data.minRating = breadCrumbResponse.data?.contents?.[0]["custom.catgroup.minRating.raw"];
    response.data.maxRating = breadCrumbResponse.data?.contents?.[0]["custom.catgroup.maxRating.raw"];

    //incase from url user wants to visit more than avail pages for product, so we redirect to first page
    if (!("next" in response.data) && !("previous" in response.data) && payload.parameters.offset !== 0) {
      const query = { ...payload.parameters.query, offset: 0 };
      const newParam = { ...payload.parameters, offset: 0, query };
      yield put({
        type: ACTIONS.PRODUCT_LIST_GET_REQUESTED,
        payload: {
          parameters: newParam,
          states: {
            selectedPageOffset: PAGINATION_CONFIGS.pageDefaultOffset,
          },
        },
      });
      const currentUrl = new URL(window.location.href);
      currentUrl.searchParams.delete("p");
      window.history.replaceState({}, "", currentUrl.toString());
    } else {
      for (let i = 0; i < response?.data.contents.length; ++i) {
        response.data.contents[i].promotions = storeUtil.getPromotionDetails(response.data.contents[i]);
      }
      yield put({
        type: ACTIONS.PRODUCT_LIST_GET_SUCCESS,
        response: response.data,
        payload: payload,
      });
    }
  } catch (error) {
    yield put({ type: ACTIONS.PRODUCT_LIST_GET_ERROR, error });
  }
  //false state for loader etc.
  yield put({
    type: ACTIONS.PRODCUT_LIST_FETCHING,
    payload: false,
  });
}

/**
 * Saga worker to invoke get product list API for PDP
 */
export function* fetchProductListForPDP(action: any) {
  try {
    const payload = action.payload;
    const parameters: any = {
      id: payload.parameters.categoryId,
      query: {
        profileName: FIND_CATEGORIE_BY_UNIQUE_ID,
      },
    };

    const response = yield call(productsService.findProductsUsingGET, payload.parameters);
    const breadCrumbResponse = yield call(categoryService.getV2CategoryResourcesUsingGET, parameters);
    if (breadCrumbResponse.data?.contents?.[0]?.UserData?.[0]?.breadCrumbTrailEntryView) {
      response.data.breadCrumbTrailEntryView =
        breadCrumbResponse.data?.contents?.[0]?.UserData?.[0]?.breadCrumbTrailEntryView;
    }
    response.data.catentryCount = breadCrumbResponse.data?.contents?.[0]["custom.catgroup.catentryCount.raw"];
    response.data.maxPrice = breadCrumbResponse.data?.contents?.[0]["custom.catgroup.maxPrice.raw"];
    response.data.minPrice = breadCrumbResponse.data?.contents?.[0]["custom.catgroup.minPrice.raw"];
    response.data.minFilterPrice = breadCrumbResponse.data?.contents?.[0]["custom.catgroup.filterMinPrice.raw"];
    response.data.maxFilterPrice = breadCrumbResponse.data?.contents?.[0]["custom.catgroup.filterMaxPrice.raw"];
    response.data.reviewCount = breadCrumbResponse.data?.contents?.[0]["custom.catgroup.reviewCount.raw"];
    response.data.averageRating = breadCrumbResponse.data?.contents?.[0]["custom.catgroup.averageRating.raw"];
    response.data.minRating = breadCrumbResponse.data?.contents?.[0]["custom.catgroup.minRating.raw"];
    response.data.maxRating = breadCrumbResponse.data?.contents?.[0]["custom.catgroup.maxRating.raw"];
    yield put({
      type: ACTIONS.PRODUCT_LIST_FOR_PDP_GET_SUCCESS,
      response: response.data,
      payload: payload,
    });
  } catch (error) {
    yield put({ type: ACTIONS.PRODUCT_LIST_FOR_PDP_GET_ERROR, error });
  }
}

/**
 * SAGA WORKER TO FETCH BREADCRUMBS ONLY
 */
export function* fetchBreadcrumbs(action: Record<any, any>) {
  const payload = action.payload;
  const parameters: any = {
    id: payload.parameters.categoryId,
    query: {
      profileName: FIND_CATEGORIE_BY_UNIQUE_ID,
    },
  };
  const response = yield call(categoryService.getV2CategoryResourcesUsingGET, parameters);
  yield put({
    type: ACTIONS.SET_BREADCRUMBS,
    payload,
    response,
  });
}

export function* fetchProductListDetails(action: any) {
  try {
    const payload = action.payload;
    const params = payload.parameters;
    const r = yield call(productsService.findProductsUsingGET, params);
    const response = r.data;
    yield put({ type: ACTIONS.PRODUCT_LIST_DEETS_S, response, payload });
  } catch (error) {
    yield put({ type: ACTIONS.PRODUCT_LIST_DEETS_F, error });
  }
}
