/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2021
 *
 *==================================================
 */
//Standard libraries
import { call, put } from "redux-saga/effects";
import jsonService from "../../../_foundation/apis/staticJson/json.service";
//REDUX
import {
  UPDATE_CATEGORIES_STATE_ACTION,
  UPDATE_HEADER_LINK_STATE_ACTION,
  UPDATE_CURRENT_CATEGORY_STATE_ACTION,
} from "../../actions/category";

export function* updateCategoriesState(action: any) {
  const payload = action.payload;
  yield put(UPDATE_CATEGORIES_STATE_ACTION(payload));
  //
  const navData = yield call(jsonService.fetchStaticJson, { file: "navigation-links.json", isLocal:false });
  yield put(UPDATE_HEADER_LINK_STATE_ACTION({ navData }));
}

export function* updateCategoryState(action: any) {
  const payload = action.payload;
  yield put(UPDATE_CURRENT_CATEGORY_STATE_ACTION(payload));
}
