//Standard libraries
import React from "react";
import { StyledGrid, StyledTypography } from "@hcl-commerce-store-sdk/react-component";
import { StyledContainer, StyledLink, StyledButton, StyledBox } from "../../styled-mui";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Trans, useTranslation } from "react-i18next";
import { useSite } from "../../_foundation/hooks/useSite";

/**
 * FooterMention component
 *
 * @param props
 */
const FooterMention: React.FC = (props: any) => {
  const { t } = useTranslation();
  const { mySite } = useSite();
  const proSiteUrl = mySite?.storeCfg?.userData?.PRO_SITE_URL || "";

  return (
    <StyledBox sx={{ bgcolor: "tertiary.main" }}>
      <StyledContainer className="no-max-width">
        <StyledGrid
          container
          pt={{
            xs: 10,
            md: 15,
          }}
          pb={15}
          sx={{ borderTop: 1, borderColor: "gray.neutralGray500" }}>
          <StyledGrid lg={9} md={8} xs={12} pr={2} item>
            <StyledTypography variant="body2" component="p" pb={3}>
              <Trans i18nKey="Footer.Mention.PrivacyPolicy" t={t} components={[<br />]} />
              <StyledLink className="underline" to="/c/vos-donnees-vos-droits">
                <strong
                  style={{
                    color: "black",
                    textDecoration: "underline",
                    textDecorationColor: "black",
                  }}>
                  {t("Footer.Mention.PrivacyLink")}
                </strong>
              </StyledLink>
            </StyledTypography>
            <StyledButton
              variant="text"
              color="black"
              className="link-hover-animated"
              endIcon={<ChevronRightIcon />}
              sx={{ textTransform: "none" }}>
              <span className="ot-sdk-show-settings">{t("Footer.Mention.CookieButton")}</span>
            </StyledButton>
          </StyledGrid>
          <StyledGrid
            lg={3}
            md={4}
            xs={12}
            item
            mt={{
              xs: 7,
              md: 0,
            }}
            className="text-right">
            <StyledLink href={proSiteUrl}>
              <StyledButton
                variant="outline"
                color="black"
                sx={{
                  width: { xs: "100%", md: "auto" },
                }}>
                {t("Footer.Mention.ProfessionalAreaButton")}
              </StyledButton>
            </StyledLink>
          </StyledGrid>
        </StyledGrid>
      </StyledContainer>
    </StyledBox>
  );
};

export default FooterMention;
