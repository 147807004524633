export interface Pictogramme {
  identifier: string;
  order: number;
  description?: string;
  className?: string;
  image?: any;
  value?: any;
  values?: any[];
  deactivationValue?: string;
}

export interface ESpotState {
  behavior: string;
  content: {
    id?: string;
    index?: number;
    title: string;
    templates: any[];
  };
  category: {
    title?: string;
    categories: any[];
    id: string;
  };
  catEntry: {
    title?: string;
    catEntries: any[];
    slides: JSX.Element[];
  };
}

export interface ConfiguratorData {
  customer?: string;
  callback?: (t) => void;
  skuInitial?: string;
  sku?: string;
  prixCatalogue?: string;
  prixVente?: string;
  ecopart?: string;
  attr?: any[];
  clicked?: string;
}

export enum modalType {
  Availability = "availability", // Page produit
  Registration = "registration", // Page registration
  MapsFav = "mapsFav", // Ajout Magasin espace client
  MapsSelectStore = "mapsSelectStore", //header, tunnel achat
}

export enum searchType {
  geoLocalisation = "geoLocalisation",
  postalCode = "postalCode",
  getFavUserStores = "getFavUserStores",
  allStores = "allStores",
}

interface skuItemType {
  sku: string;
  quantity: string;
}

export type productDataType = skuItemType[] | undefined | null;
