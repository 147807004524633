import React from "react";
import styled from "@mui/styled-engine-sc";
import Accordion, { AccordionProps } from "@mui/material/Accordion";
import { palette } from "../../themes/color-palette";

interface StyledAccordionProps extends AccordionProps {
  testId: string;
}

const StyledAccordionWrapper = styled((props: any) => <Accordion {...props} />)`
  ${({ theme }) => `
    background: transparent;
    box-shadow: none;
    width: 100%;
    padding: ${theme.spacing(3)} 0;

    &.MuiAccordion-root.Mui-expanded {
      margin: 0;

      &:before {
        opacity: 1;
      }
    }
    &.product-cart-accordion {
      padding: 0 0 ${theme.spacing(3)} 0;
      border-bottom: ${palette.gray.neutralGray300} 1px solid;
      margin-bottom: ${theme.spacing(3)};

      &.Mui-expanded {
        border-bottom: 0;
      }
    }

    &.order-method-accordion {
      padding: ${theme.spacing(4)};
      background: ${palette.blue.extraLight};
      border-radius: 8px;
      margin: 0 0 ${theme.spacing(3)} 0;

      &:before {
        display: none;
      }

      &.Mui-expanded {
        padding: ${theme.spacing(4)};
        margin: 0 0 ${theme.spacing(3)} 0;
      }
    }
    &.faq-accordion {
      padding: ${theme.spacing(4)} 0 ${theme.spacing(4)} 0;
      border-bottom: ${palette.gray.neutralGray300} 1px solid;
      //margin-bottom: ${theme.spacing(3)};

      &:before {
        display: none;
      }

      &:last-child {
        border-bottom: 0;
      }
    }
    &.choice-accordion {
      padding: ${theme.spacing(3)} 0 ${theme.spacing(8)} 0;
      border-bottom: 0;

      ${theme.breakpoints.down("sm")} {
        padding: ${theme.spacing(3)} 0 ${theme.spacing(5)} 0;
      }

      ${theme.breakpoints.up("sm")} {
        .Mui-expanded {
          cursor: text;
          user-select: text;
        }
      }

      &:before {
        display: none;
      }

      &:last-child {
        border-bottom: 0;
      }
    }

    &.product-choice-accordion {
      padding: 0;
      border-bottom: 0;

      .MuiAccordionSummary-expandIconWrapper .MuiSvgIcon-root {
        color: ${palette.blue.dark};
      }
    }

    &.projects-accordion {
      padding: 0;
      &:before {
        display: none;
      }

      &:last-child {
        border-bottom: 0;
      }

      .MuiAccordionSummary-root {
        padding-bottom: ${theme.spacing(2)};
      }
    }
    &.product-category-accordion {
      border-bottom: ${palette.gray.neutralGray500} 1px solid;

      &:before {
        display: none;
      }

    }
  `}
`;

const StyledAccordion: React.FC<StyledAccordionProps> = ({ testId, ...props }) => {
  return <StyledAccordionWrapper data-testid={testId} {...props} />;
};

export { StyledAccordion };
