import styled from "@mui/styled-engine-sc";
import SvgIcon from "@mui/material/SvgIcon";

const Pinterest = styled((props) => (
  <SvgIcon width="19" height="24" viewBox="0 0 19 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} />
))`
  ${({ theme }) => ``}
`;

export const SvgPinterest = (props) => {
  const { className = "" } = props;

  return (
    <Pinterest {...{ className }}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.96066 15.6581C7.96066 15.6581 7.91882 15.7811 7.8979 15.8425C6.99836 19.2848 6.89377 20.0634 5.97331 21.6615C5.534 22.4197 5.03193 23.1368 4.48803 23.8334C4.42527 23.9154 4.36251 24.0178 4.23699 23.9973C4.11147 23.9769 4.09056 23.8539 4.06964 23.7515C3.9232 22.7065 3.83952 21.6411 3.88136 20.5961C3.9232 19.2233 4.11147 18.752 5.91055 11.2734C5.93147 11.1504 5.91055 11.0685 5.86871 10.966C5.4294 9.81861 5.34572 8.65071 5.72227 7.46232C6.53813 4.92162 9.48778 4.71672 10.0108 6.82714C10.3246 8.13847 9.48778 9.8391 8.83928 12.3593C8.29537 14.4287 10.8057 15.904 12.9395 14.3878C14.9059 12.9945 15.6799 9.6342 15.5335 7.25742C15.2406 2.52435 9.94801 1.49987 6.57997 3.01609C2.73079 4.7577 1.85217 9.4498 3.58849 11.5807C3.8186 11.8471 3.98596 12.011 3.90228 12.2978C3.79768 12.7281 3.69309 13.1584 3.56757 13.5887C3.48389 13.896 3.21194 14.019 2.87723 13.896C2.22872 13.6501 1.70574 13.2404 1.26643 12.7076C-0.218857 10.9046 -0.637246 7.35987 1.32918 4.3684C3.52573 1.00812 7.54227 -0.323697 11.2241 0.0656041C15.6172 0.557352 18.3995 3.48735 18.9225 6.82714C19.1526 8.34336 18.9852 12.0929 16.8096 14.7361C14.2993 17.789 10.2409 17.9734 8.35813 16.1089C8.21169 15.9655 8.1071 15.8015 7.96066 15.6171V15.6581Z"
      />
    </Pinterest>
  );
};
