/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Services - Account and Contract
 * These services provide APIs to manage accounts and contracts.  a contract is an agreement that represents the terms and conditions that apply to a transaction. An account is a relationship between the merchant and the financial institution that processes transactions for that merchant.
 *
 * The version of the OpenAPI document: 9.1.6
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 * @interface DemandeRDVRequest
 */
export interface DemandeRDVRequest {
    /**
     * Type of request
     * @type {string}
     * @memberof DemandeRDVRequest
     */
    type: DemandeRDVRequestTypeEnum;
    /**
     * Store code of the request.
     * @type {string}
     * @memberof DemandeRDVRequest
     */
    codeMag?: string;
    /**
     * First name of the requester.
     * @type {string}
     * @memberof DemandeRDVRequest
     */
    nom: string;
    /**
     * First name of the requester.
     * @type {string}
     * @memberof DemandeRDVRequest
     */
    prenom?: string;
    /**
     * Phone number of the requester.
     * @type {string}
     * @memberof DemandeRDVRequest
     */
    telephone: string;
    /**
     * Email of the requester.
     * @type {string}
     * @memberof DemandeRDVRequest
     */
    email: string;
    /**
     * Comment of the request.
     * @type {string}
     * @memberof DemandeRDVRequest
     */
    comment?: string;
    /**
     * Postal code
     * @type {string}
     * @memberof DemandeRDVRequest
     */
    codePostal?: string;
    /**
     * Porject description.
     * @type {string}
     * @memberof DemandeRDVRequest
     */
    projetConcerne?: string;
    /**
     * Consulting for.
     * @type {string}
     * @memberof DemandeRDVRequest
     */
    conseilPour?: string;
    /**
     * Timing of the project.
     * @type {string}
     * @memberof DemandeRDVRequest
     */
    delaiTravaux?: string;
    /**
     * current URL
     * @type {string}
     * @memberof DemandeRDVRequest
     */
    currentUrl?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum DemandeRDVRequestTypeEnum {
    Commercial = 'commercial',
    Cad = 'CAD',
    Ri = 'RI'
}



