/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
import styled from "@mui/styled-engine-sc";
import Popover from "@mui/material/Popover";
import React from "react";

export const StyledPopover = styled(({ ...props }) => <Popover {...props} />)`
  ${({ theme }) => `
    .MuiPopover-paper {
      padding: 0 ${theme.spacing(1.5)};
      border-radius: ${theme.shape.borderRadius}px;
      box-shadow: ${theme.shadows[1]};
    }
    &.header-popover-store {
      .MuiPopover-paper {
        padding: ${theme.spacing(6)} ${theme.spacing(5)};
        padding: 0;
        border-radius: ${theme.shape.borderRadius}px;
        box-shadow: 0px 0px 12px 0px rgba(0, 70, 218, 0.12);
        border: 1px solid rgba(220, 225, 238, 1);
        margin-top: 12px;
        overflow: visible;
        max-width: 300px;

      }
      .header-popover-store-arrow {
        position: absolute;
        display: block;
        width: 12px;
        height: 6px;
        //margin: 0 .3rem;
        margin-top: -6px;
        left: 16px;

        &::before, &:after {
          position: absolute;
          display: block;
          content: "";
          border-color: transparent;
          border-style: solid;
          border-width: 0 6px 6px 6px;
        }

        &:before {
          top: 0;
          border-bottom-color: rgba(220, 225, 238, 1);
        }

        &:after {
          top: 1px;
          border-bottom-color: #fff;
        }
      }
    }
  `}
`;
