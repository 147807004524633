//Standard libraries
import React, { useEffect, useState } from "react";
import { StyledGrid } from "@hcl-commerce-store-sdk/react-component";
import { StyledBox, StyledContainer, StyledLink, StyledTypography } from "../../styled-mui";
import { useSelector } from "react-redux";
import { GetStaticLinksSelector } from "../../redux/selectors/category";
import { EXTERNAL_LINK /*INTERNAL_LINK*/ } from "../../constants/marketing";
import AsyncCall from "../../_foundation/gtm/async.service";
import { CLICK } from "../../_foundation/constants/gtm";

/**
 * FooterNav component
 *
 * @param props
 */
const FooterNav: React.FC = () => {
  const [navigations, setNavigations] = useState<any[]>([]);
  const StaticLinksRedux: any = useSelector(GetStaticLinksSelector);
  const year = new Date().getFullYear();

  useEffect(() => {
    if (StaticLinksRedux && StaticLinksRedux?.footerLinks) {
      setNavigations(StaticLinksRedux?.footerLinks || []);
    }
  }, [StaticLinksRedux]);

  return (
    <StyledBox sx={{ bgcolor: "gray.neutralGray900" }}>
      <StyledContainer className="footer-nav no-max-width">
        <StyledGrid container py={5}>
          <StyledGrid xs={12} item className="text-align-center">
            <ul className="footer-nav-list">
              <StyledTypography variant="body3" component="li">
                © {year} Lapeyre
              </StyledTypography>
              {navigations?.map((link, index) => (
                <StyledTypography key={index} variant="body4" component="li">
                  <StyledLink
                    to={link.url}
                    target={link.target}
                    href={link.type === EXTERNAL_LINK ? link.url : null}
                    onClick={() => {
                      AsyncCall.sendClick(CLICK.FOOTER, { textclick: link.label + "_" + link.url });
                    }}>
                    {link.label}
                  </StyledLink>
                </StyledTypography>
              ))}
            </ul>
          </StyledGrid>
        </StyledGrid>
      </StyledContainer>
    </StyledBox>
  );
};

export default FooterNav;
