import styled from "@mui/styled-engine-sc";
import Radio from "@mui/material/Radio";
import { palette } from "../../themes/color-palette";

const StyledRadio = styled(({ ...props }) => <Radio {...props} />)`
  ${({ theme }) => `
    padding: 0;

    /* &.MuiRadio-colorSecondary {
      color: ${palette.white.main};
    } */

    &.hidden {
      display: none;
    }

  `}
`;

export { StyledRadio };
