import React from "react";
import styled from "@mui/styled-engine-sc";
import AccordionDetails from "@mui/material/AccordionDetails";
import { palette } from "../../themes/color-palette";

const ComponentWrapper = React.forwardRef((props: any, ref: any) => {
  return <AccordionDetails {...props} ref={ref} />;
});

const StyledAccordionDetails = styled(ComponentWrapper)`
  ${({ theme }) => `
  padding: ${theme.spacing(3)} 0 0;

  &.product-cart-accordion-details {
    background: ${palette.blue.extraLight};
    padding: ${theme.spacing(4)};
    border-radius: 8px;
    margin-top: ${theme.spacing(3)};
  }

  &.faq-accordion-details {
    margin-top: ${theme.spacing(4)};
    border-top: ${palette.gray.neutralGray300} 1px solid;
    padding: ${theme.spacing(4)} ${theme.spacing(11)} ${theme.spacing(1)} ${theme.spacing(3)} ;
  }
  &.choice-accordion-details {
    border-top: none;
    padding: 0;
  }
  &.order-method-accordion-details {
    margin-top: ${theme.spacing(4)};
    padding: 0;
  }
`}
`;

export { StyledAccordionDetails };
