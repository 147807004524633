import styled from "@mui/styled-engine-sc";
import SvgIcon from "@mui/material/SvgIcon";

const CustomSvg = styled((props) => (
  <SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} />
))`
  ${({ theme }) => `
    width:16px;
    height:16px;

    &.big {
      width:24px;
      height:24px;
    }
  `}
`;

export const SvgIconPhone = (props) => {
  const { className = "" } = props;

  return (
    <CustomSvg {...{ className }}>
      <path d="M13.287 13.987c-1.431 0-2.83-.32-4.188-.96a12.863 12.863 0 0 1-3.616-2.53 12.546 12.546 0 0 1-2.53-3.616C2.32 5.53 2 4.131 2 2.7c0-.2.067-.366.2-.5.133-.132.3-.199.5-.199h2.696c.153 0 .293.053.42.153.126.1.193.227.22.386l.432 2.331c.02.153.02.3-.006.426a.676.676 0 0 1-.193.34L4.65 7.267c.467.8 1.053 1.552 1.758 2.251.706.7 1.479 1.305 2.325 1.818l1.564-1.565c.1-.1.233-.173.393-.226a.964.964 0 0 1 .473-.04l2.298.466a.676.676 0 0 1 .386.227c.1.12.153.253.153.406V13.3c0 .2-.067.366-.2.5-.133.132-.3.199-.5.199l-.013-.013Zm-9.27-7.991 1.1-1.1-.287-1.564H3.345c.053.453.133.906.233 1.352.1.446.247.885.433 1.318l.007-.006Zm8.645 6.625v-1.465l-1.565-.32-1.12 1.12c.434.186.873.34 1.326.452.453.114.899.187 1.358.22v-.007Z" />
    </CustomSvg>
  );
};
