/* eslint-disable react-hooks/exhaustive-deps */
import { Hidden, InputAdornment, Stack, Tab } from "@mui/material";
import { StyledGrid } from "@hcl-commerce-store-sdk/react-component";
import { useEffect, useRef, useState } from "react";
import { StyledTypography } from "../../../styled-mui/typography/styled-typography";
import { StyledTextField } from "../../../styled-mui/text-field/styled-text-field";
import { SvgLocalisation } from "../../svg/localisation";
import SearchIcon from "@mui/icons-material/Search";
import {
  StyledBox,
  StyledButton,
  StyledFormControl,
  StyledFormControlLabel,
  StyledIconButton,
  // StyledLink,
  // StyledInputLabel,
  StyledRadio,
  StyledRadioGroup,
} from "../../../styled-mui";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { SvgClose } from "../../svg/close";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { StyledStoreLocatorWidget } from "./styled-storeLocatorWidget";
import { StoreLocatorWidgetMap } from "./store-locator-widget-map";
import { StoreLocatorWidgetList } from "./store-locator-widget-list";
import { SvgChevronDown } from "../../svg/chevronDown";
import { StyledProductDelivery } from "../product-delivery/styled-productDelivery";
import { SvgRadioButtonIconCheck } from "../../svg/radioButtonIconCheck";
import { SvgRadioButtonIcon } from "../../svg/radioButtonIcon";
import { useDispatch, useSelector } from "react-redux";
import {
  isLoadingStoresListSelector,
  storeLocatorAvailabilitiesSelector,
  storeLocatorModalTypeSelector,
  storeLocatorProductDataSelector,
  //storeLocatorNearestStoresSelector,
  //storeLocatorProductDataSelector,
  storesListSelector,
} from "../../../redux/selectors/stores";
import { modalType, searchType } from "../../../types/store-types";
import { SvgLocationOnOutlined } from "../../svg/locationOnOutlined";
import { SvgTruck } from "../../svg/truck";
import {
  STORES_CLOSE_MODAL_ACTION,
  STORES_LIST_GET_REQUEST_ACTION,
  STORES_RESET_ACTION,
} from "../../../redux/actions/stores";
import getDisplayName from "react-display-name";
import { debounce } from "lodash-es";
import formsUtil from "../../../utils/formsUtil";
import { STORELISTRADIUS, STORELOCATORACTIONS } from "../../../_foundation/constants/common";
import { getSite, useSite } from "../../../_foundation/hooks/useSite";
import { StoreLocatorWidgetSelect } from "./store-locator-widget-select";
import { SvgTrust } from "../../svg/trust";
import { userAttributesSelector, userNameSelector } from "../../../redux/selectors/user";
import { useStoreLocatorValue } from "../../../_foundation/context/store-locator-context";
import useUpdateEffect from "../../../utils/hooks/useUpdateEffect";
import ElementLoader from "../loader/ElementLoader";
import { STORELOCATOR_CP_DEFAULT } from "../../../constants/common";
import { useMatch, useNavigate } from "react-router";
import { useFetch } from "../../../utils/hooks/useFetch";
import stockService from "../../../_foundation/apis/transaction/stock.service";
import { useCart } from "../../../_foundation/hooks/use-cart";
import { CART } from "../../../constants/routes";
import { isAvailableForShipping } from "../../../utils/orderUtil";
import storeLocatorService from "../../../_foundation/apis/transaction/storeLocator.service";
import AsyncCall from "../../../_foundation/gtm/async.service";
import { breadcrumbsSelector } from "../../../redux/selectors/catalog";
import { useJsApiLoader } from "@react-google-maps/api";
import { getMonthDate, isDateLessOrEqualToToday } from "../../../utils/storeLocatorUtils";
// import { HANDLE_SUCCESS_MESSAGE_ACTION } from "../../../redux/actions/success";
// import { format } from "date-fns";
// import { fr } from "date-fns/locale";
//import storeLocatorService from "../../../_foundation/apis/transaction/storeLocator.service";

interface StoreLocatorWidgetProps {
  handleCloseModal: () => void;
}

export const StoreLocatorWidget: React.FC<StoreLocatorWidgetProps> = ({ handleCloseModal }) => {
  const modalTypeSelected = useSelector(storeLocatorModalTypeSelector);
  const productData = useSelector(storeLocatorProductDataSelector);
  const { orderItems, cart } = useCart();
  const [productsDataParams, setProductsDataParams] = useState<any>(null);
  //const nearestStores = useSelector(storeLocatorNearestStoresSelector);
  const availabilities = useSelector(storeLocatorAvailabilitiesSelector);
  const storesList = useSelector(storesListSelector);
  const loadingStoresList = useSelector(isLoadingStoresListSelector);

  const { dispatch: dispatchStoreLocatorValue } = useStoreLocatorValue();

  const [nearestStoreOffset, setNearestStoreOffset] = useState<number>(0);
  const [maxItem, setMaxItem] = useState<number>(3); // eslint-disable-line @typescript-eslint/no-unused-vars

  const { zipCode } = useSelector(userNameSelector);
  const { userFavStoreId } = useSelector(userAttributesSelector);
  const breadcrumbs = useSelector(breadcrumbsSelector);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const site = getSite();
  const { mySite } = useSite();

  const [preferredStore, setPreferredStore] = useState<Record<any, any>>({});
  const [postalCodeValue, setPostalCodeValue] = useState<string>("");
  const modalRef = useRef<any | null>(null);
  const [selectedStoreValue, setSelectedStoreValue] = useState<string | null>(null);
  const [loadingLocation, setLoadingLocation] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState("1");
  const validZipCode = formsUtil.validateZipCode(postalCodeValue);
  const widgetName = getDisplayName(StoreLocatorWidget);
  const matchCartPage = useMatch(CART);
  const containerRef = useRef<any | null>(null);
  const scrollContainerRef = useRef<any>(null);
  const noStoresfoundError: boolean = !loadingStoresList && (!storesList || storesList?.length === 0);

  const payloadBase = {
    widget: widgetName,
    responseFormat: "application/json",
  };

  /* const { isLoaded } = */ useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY || "",
  });

  useEffect(() => {
    AsyncCall.sendStoreLocatorPopinViewEvent({ breadcrumbs });
    if (matchCartPage) {
      const prod: any = {};
      orderItems
        .filter((o) => o.buyable === "true")
        .forEach((order) => {
          prod[order.partNumber] = order.quantity;
        });
      setProductsDataParams(prod);
    } else {
      const products: any = {};
      productData?.forEach((sku) => {
        products[sku.sku] = String(sku.quantity);
      });
      setProductsDataParams(products);
    }
  }, [orderItems]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchShippingDate: boolean =
    modalTypeSelected === modalType.Availability && validZipCode && !!productsDataParams;

  const {
    data: dataShipping,
    loading: loadingShipping,
    error: errorShipping,
  } = useFetch(
    stockService.deliveryDate,
    {
      ...payloadBase,
      body: {
        zipCode: postalCodeValue,
        orderId: cart?.orderId && cart?.orderId,
        country: "FR",
        products: productsDataParams,
      },
      skipErrorSnackbar: true,
    },
    [fetchShippingDate],
    fetchShippingDate
  );

  useEffect(() => {
    // display the user's favorites stores in the list when the modal opens
    if (modalTypeSelected === modalType.MapsSelectStore && userFavStoreId) {
      const parameters = { ...payloadBase };
      dispatch(
        STORES_LIST_GET_REQUEST_ACTION({
          parameters,
          type: searchType.getFavUserStores,
          productStoreId: mySite.catalogStoreID,
        })
      );
    }
  }, []);

  useEffect(() => {
    // Displays the stores in the input select after the user register
    if (modalTypeSelected === modalType.Registration && zipCode) {
      setPostalCodeValue(zipCode);
      debouncedGetStoresList(zipCode);
    }
  }, [zipCode]);

  const handleChangeTab = (_event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };

  const handlePostalCodeChange = (v) => {
    setPreferredStore({});
    const validZipCode = formsUtil.validateZipCode(v);
    setPostalCodeValue(v);
    if (v === "") {
      getAllStoresList();
    }

    if (validZipCode /*&& modalTypeSelected !== modalType.Availability*/) {
      setNearestStoreOffset(0);
      getStoresList(v);
    } else {
      dispatch(STORES_RESET_ACTION());
    }
  };

  const debouncedGetStoresList = useRef(debounce((v) => getStoresList(v), 1000)).current;

  useEffect(() => {
    return () => {
      debouncedGetStoresList.cancel();
    };
  }, [debouncedGetStoresList]); // eslint-disable-line react-hooks/exhaustive-deps

  useUpdateEffect(() => {
    getStoresList(postalCodeValue);
  }, [nearestStoreOffset]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (modalTypeSelected === modalType.MapsSelectStore) {
      getAllStoresList();
    }
  }, []);

  const handleGetMoreStores = () => {
    setNearestStoreOffset((v) => v + 1);
  };

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current?.lastElementChild?.scrollIntoView();
    }

    /* scrolls modal to top after stores fetch */
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTo(0, 0);
    }
  }, [storesList]);

  const getStoresList = async (postalCode) => {
    debouncedGetStoresList.cancel();
    const data = await storeLocatorService.getLatLong(postalCode);
    const latitude = data.lat;
    const longitude = data.long;
    // const parameters = {
    //   ...payloadBase,
    //   body: {
    //     // zipCode: postalCode || STORELOCATOR_CP_DEFAULT,
    //     latitude: String(20.9),
    //     longitude: String(0.8),
    //     storeId: site?.storeID || "",
    //     //view: "NearestShopsInJsonView",
    //   },
    // };
    const parameters: any = {
      ...payloadBase,
      latitude: String(latitude),
      longitude: String(longitude),
      radius: STORELISTRADIUS,
      siteLevelStoreSearch: false,
      maxItems: modalTypeSelected === modalType.Availability && nearestStoreOffset === 0 && maxItem,
      query: { attributeName: "isPickup", attributeValue: "1" },
    };
    dispatch(
      STORES_LIST_GET_REQUEST_ACTION({
        parameters,
        type: searchType.geoLocalisation,
        productData: productsDataParams,
        productStoreId: mySite.catalogStoreID,
      })
    );
  };

  const getAllStoresList = async () => {
    const parameters: any = {
      ...payloadBase,
      query: { attributeName: "isPickup", attributeValue: "1" },
    };
    dispatch(
      STORES_LIST_GET_REQUEST_ACTION({
        parameters,
        type: searchType.allStores,
      })
    );
  };

  const getStoresListByLocation = () => {
    navigator.geolocation.getCurrentPosition(
      async (position: GeolocationPosition) => {
        debouncedGetStoresList.cancel();
        setLoadingLocation(true);
        const lat = position.coords.latitude;
        const lng = position.coords.longitude;
        // setCenter({ lat, lng });

        const parameters: any = {
          ...payloadBase,
          latitude: String(lat),
          longitude: String(lng),
          storeId: site?.storeID || "",
          radius: STORELISTRADIUS,
          siteLevelStoreSearch: false,
        };
        if (modalTypeSelected === modalType.Availability) {
          getStoresList(STORELOCATOR_CP_DEFAULT);
          setPostalCodeValue(STORELOCATOR_CP_DEFAULT);
          // dispatch(STORES_LIST_GET_REQUEST_ACTION({ parameters, type: searchType.geoLocalisation, productData }));
        } else {
          dispatch(
            STORES_LIST_GET_REQUEST_ACTION({
              parameters,
              type: searchType.geoLocalisation,
              productStoreId: mySite.catalogStoreID,
              onGeoStoresFetchSuccess: () => setPostalCodeValue(""),
              onNoGeoStoresFound: () => {
                dispatch(STORES_RESET_ACTION());
                setPostalCodeValue("");
              },
            })
          );
        }
        setLoadingLocation(false);
      },
      (err: any) => {
        console.error("location permission denied: ", err);
        dispatch(STORES_RESET_ACTION());
        setPostalCodeValue("");
        getAllStoresList();
        setLoadingLocation(false);
      }
    );
  };

  const handleChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedStoreValue(event.target.value);
  };

  const handleSetStore = () => {
    const store = storesList && storesList.find((s) => s.id === selectedStoreValue);
    dispatchStoreLocatorValue({
      type: STORELOCATORACTIONS.UPDATE_STORE_SELECTION,
      payload: { selectedStore: store, postalCode: postalCodeValue },
    });
    // dispatch(
    //   HANDLE_SUCCESS_MESSAGE_ACTION({
    //     key: "success-message.SELECTED_STORE",
    //   })
    // );
    dispatch(STORES_CLOSE_MODAL_ACTION());
  };

  const handleRedirectToCart = () => {
    handleCloseModal();
    navigate("/cart");
  };

  return (
    <StyledStoreLocatorWidget>
      <StyledBox
        className="modal-container store-choice-popin"
        ref={modalRef}
        style={modalTypeSelected === modalType.Registration ? { maxWidth: !userFavStoreId && 455 } : undefined}>
        {modalTypeSelected === modalType.Registration && !userFavStoreId ? (
          <StyledButton
            size="medium"
            variant="text"
            endIcon={<ChevronRightIcon />}
            color="secondary"
            sx={{ textTransform: "none", fontWeight: 400, mb: { xs: 4, sm: 6 }, mr: { xs: 4, sm: 1 } }}
            // onClick={() => {
            //   setOpenModalStore(false);
            //   redirectCheckout();
            // }}
            onClick={handleCloseModal}
            className="modal-close">
            Passer cette étape
          </StyledButton>
        ) : (
          <StyledIconButton onClick={handleCloseModal} className="modal-close">
            <SvgClose color="black" />
          </StyledIconButton>
        )}
        {modalTypeSelected === modalType.Availability ? (
          <StyledBox
            ref={scrollContainerRef}
            sx={{ overflowY: "scroll", maxHeight: { xs: "90vh", sm: "70vh", lg: "80vh" } }}>
            <StyledGrid container p={8}>
              <StyledGrid item xs={12}>
                {matchCartPage ? (
                  <>
                    <StyledTypography component="p" variant="h5" pb={1} className="">
                      vérifiez la disponibilité de votre panier
                    </StyledTypography>
                    <StyledTypography variant="body2" component="p" pb={6}>
                      Saisissez le code postal de votre adresse afin de vérifier la disponibilité des produits de votre
                      panier.
                    </StyledTypography>
                  </>
                ) : (
                  <>
                    <StyledTypography component="p" variant="h5" pb={1} className="">
                      Choisissez votre magasin
                    </StyledTypography>
                    <StyledTypography variant="body2" component="p" pb={6}>
                      Achetez en magasin ou en ligne votre commande dans votre magasin Lapeyre de référence
                    </StyledTypography>
                  </>
                )}
                <StyledTextField
                  type="number"
                  id="postal-code"
                  name="postal-code"
                  autoComplete="postal-code"
                  color="gray"
                  variant="outlined"
                  focused
                  className="border-gray-300"
                  required
                  fullWidth
                  label="Recherche par code postal"
                  onChange={(e) => handlePostalCodeChange(e.target.value)}
                  value={postalCodeValue}
                  max={5}
                  // sx={{ mb: 6 }}
                  InputProps={{
                    endAdornment: (loadingShipping || loadingStoresList) && (
                      <InputAdornment position="end">
                        <ElementLoader />
                      </InputAdornment>
                    ),
                    // <InputAdornment position="end">
                    //   <StyledIconButton
                    //     className={`${loadingLocation && "blink"} adornment-icon`}
                    //     onClick={() => getStoresListByLocation()}>
                    //     {" "}
                    //     <SvgLocalisation />
                    //   </StyledIconButton>
                    // </InputAdornment>
                  }}
                  error={errorShipping}
                  helperText="Le code postal que vous avez renseigné est invalide, veuillez réessayer avec un code postal différent."
                />
                {/* {loadingShipping || loadingStoresList ? (
                  <StyledCircularProgress />
                ) : ( */}
                <div style={{ marginTop: 24 }}>
                  {dataShipping && validZipCode && !loadingShipping ? (
                    <>
                      <Stack direction="row" gap={{ xs: 2, sm: 4 }} alignItems="center">
                        <SvgTruck />
                        <StyledTypography variant="body1" component="p">
                          Livraison
                        </StyledTypography>
                      </Stack>
                      <StyledProductDelivery>
                        <StyledBox
                          pl={10}
                          pt={{ xs: 4, sm: 6 }}
                          pb={6}
                          mb={6}
                          component="span"
                          className={`product-information-availability ${
                            isAvailableForShipping(dataShipping) ? "available" : "not-available"
                          }`}
                          sx={{ flexWrap: "wrap", borderBottom: 1, borderColor: "gray.neutralGray300" }}>
                          <StyledTypography variant="body4" component="p">
                            {isAvailableForShipping(dataShipping) ? (
                              <>
                                Livraison disponible à votre adresse ({postalCodeValue})<br />
                                {matchCartPage && (
                                  <p style={{ marginTop: 10, color: "#999999" }}>
                                    Livré à partir du{" "}
                                    {/* {format(dataShipping.message.dateDispo, "dd MMMM yyyy", { locale: fr })} */}
                                    {getMonthDate(dataShipping.message.dateDispo)}
                                  </p>
                                )}
                              </>
                            ) : (
                              <>Certains articles sont indisponibles en livraison à cette adresse ({postalCodeValue})</>
                            )}
                          </StyledTypography>
                        </StyledBox>
                      </StyledProductDelivery>
                    </>
                  ) : (
                    errorShipping && (
                      <>
                        <Stack direction="row" gap={{ xs: 2, sm: 4 }} alignItems="center">
                          <SvgTruck />
                          <StyledTypography variant="body1" component="p">
                            Livraison
                          </StyledTypography>
                        </Stack>
                        <StyledProductDelivery>
                          <StyledBox p={5} pl={10}>
                            <StyledTypography variant="body4" component="p">
                              Données de livraison indisponible
                            </StyledTypography>
                          </StyledBox>
                        </StyledProductDelivery>
                      </>
                    )
                  )}

                  {storesList && storesList.length > 0 && (
                    <>
                      <Stack direction="row" gap={{ xs: 2, sm: 4 }} alignItems="center">
                        <SvgLocationOnOutlined className="big" />
                        <StyledTypography variant="body1" component="p">
                          Retrait
                        </StyledTypography>
                      </Stack>
                      <StyledBox sx={{ borderBottom: 1, borderColor: "gray.neutralGray300" }} pl={10}>
                        <StyledFormControl fullWidth>
                          <StyledRadioGroup
                            ref={containerRef}
                            aria-labelledby=""
                            name="radio-buttons-group"
                            className="no-gap"
                            value={selectedStoreValue}
                            onChange={handleChangeRadio}>
                            {storesList.map((store, index) => {
                              const storeId = storesList[index].storeName;
                              const availabilitiesData = availabilities ? availabilities[0] : null;
                              // const productAvail = Object.keys(availabilitiesData)?.find(
                              //   (avail) => parseInt(avail) === parseInt(storeId)
                              // );
                              const productAvail: any = availabilitiesData ? availabilitiesData[storeId] : {};
                              const isAvailable: boolean =
                                productAvail &&
                                productAvail.cartAvailabilityDate &&
                                productAvail.cartAvailabilityDate.length > 0
                                  ? true
                                  : false;

                              // const todayDate = currentDate();
                              const availabilityDate = getMonthDate(productAvail?.cartAvailabilityDate);
                              const isPastDateOrToday: boolean = isDateLessOrEqualToToday(
                                productAvail?.cartAvailabilityDate
                              );

                              //const storeUniqueID = storesList[index].uniqueID;
                              // const storeDistanceData = nearestStores?.find(
                              //   (store) => store.strLocId === storeUniqueID
                              // );
                              //console.log("storeUniqueID", storeUniqueID, nearestStores);
                              const distance = store?.distance || "0 Km";
                              return (
                                <StyledFormControlLabel
                                  key={index}
                                  value={store.uniqueID}
                                  componentsProps={{ typography: { variant: "body4" } }}
                                  sx={{
                                    width: "100%",
                                    py: { xs: 4, sm: 6 },
                                    alignItems: "flex-start",
                                    borderBottom: index + 1 < storesList.length ? 1 : 0,
                                    borderColor: "gray.neutralGray300",
                                  }}
                                  control={
                                    <StyledRadio
                                      color="secondary"
                                      icon={<SvgRadioButtonIcon className="medium" />}
                                      checkedIcon={<SvgRadioButtonIconCheck className="medium" />}
                                      sx={{ mr: 1 }}
                                    />
                                  }
                                  label={
                                    <>
                                      <StyledBox component="strong" pb={3} sx={{ display: "block" }}>
                                        {store.name} - {distance}
                                      </StyledBox>
                                      <StyledProductDelivery>
                                        <StyledBox
                                          component="span"
                                          className={`product-information-availability ${
                                            isAvailable // || store.availabilityQuantity === "0"
                                              ? "available"
                                              : "not-available"
                                          }`}
                                          sx={{ flexWrap: "wrap" }}>
                                          {
                                            //!store.availabilityQuantity || store.availabilityQuantity === "0"
                                            isAvailable ? (
                                              <StyledTypography variant="body4" component="span">
                                                {isPastDateOrToday ? (
                                                  <>
                                                    Disponible, Retrait&nbsp;<strong>gratuit</strong>&nbsp;en magasin
                                                  </>
                                                ) : (
                                                  <>
                                                    Disponible à partir du {availabilityDate}, retrait&nbsp;
                                                    <strong>gratuit</strong>&nbsp;en magasin
                                                  </>
                                                )}
                                              </StyledTypography>
                                            ) : (
                                              <StyledTypography variant="body4" component="span">
                                                {matchCartPage
                                                  ? "Certains articles de votre panier sont indisponibles en retrait dans ce magasin."
                                                  : "Retrait indisponible en magasin"}
                                              </StyledTypography>
                                            )
                                          }
                                        </StyledBox>
                                        {!isAvailable && matchCartPage && (
                                          <StyledBox pt={2} pl={5}>
                                            <StyledButton
                                              variant="text"
                                              size="small"
                                              color="black"
                                              onClick={handleRedirectToCart}
                                              sx={{
                                                textTransform: "none",
                                                fontWeight: 400,
                                                textDecoration: "underline",
                                              }}>
                                              Modifier mon panier
                                            </StyledButton>
                                          </StyledBox>
                                        )}
                                      </StyledProductDelivery>
                                    </>
                                  }
                                />
                              );
                            })}
                          </StyledRadioGroup>
                        </StyledFormControl>
                      </StyledBox>
                      <StyledGrid container justifyContent="space-between" pt={6} alignItems="center">
                        <StyledGrid item xs={12} sm={"auto"} pb={{ xs: 6, sm: 0 }}>
                          {nearestStoreOffset === 0 && (
                            <StyledButton
                              onClick={handleGetMoreStores}
                              variant="text"
                              color="secondary"
                              fullWidth
                              endIcon={<SvgChevronDown />}
                              sx={{ textTransform: "none", fontWeight: 400 }}>
                              Afficher plus de magasins
                            </StyledButton>
                          )}
                        </StyledGrid>
                        <StyledGrid item xs={12} sm={"auto"}>
                          <StyledButton
                            // onClick={() => setOpenModalStore(false)}
                            testId="preferred-store-submit-confirm"
                            color="black"
                            className=""
                            sx={{ width: { xs: "100%", sm: " auto" } }}
                            onClick={handleSetStore}
                            disabled={!selectedStoreValue}
                            loading={loadingStoresList}>
                            Sélectionner ce magasin
                          </StyledButton>
                        </StyledGrid>
                      </StyledGrid>
                    </>
                  )}
                </div>
                {/* )} */}
              </StyledGrid>
            </StyledGrid>
          </StyledBox>
        ) : modalTypeSelected === modalType.MapsFav || modalTypeSelected === modalType.MapsSelectStore ? (
          <StyledGrid container>
            <StyledGrid
              item
              xs={12}
              sm={6}
              pt={{ xs: 5, sm: 10 }}
              pr={5}
              pl={{ xs: 5, sm: 10 }}
              sx={{ position: "relative" }}>
              <Stack
                direction={"column"}
                sx={{
                  minHeight: { xs: "85vh", sm: "480px" },
                  maxHeight: `calc(${modalRef.current?.clientHeight}px - 40px)`,
                }}>
                <StyledTypography variant="h5" component="p" pb={6}>
                  Choisir un magasin
                </StyledTypography>
                <StyledTextField
                  color="gray"
                  variant="outlined"
                  focused
                  className="border-gray-300"
                  required
                  fullWidth
                  // label=""
                  placeholder="Saisissez votre code postal"
                  name="adresse"
                  onChange={(e) => handlePostalCodeChange(e.target.value)}
                  value={postalCodeValue}
                  sx={{ mb: { xs: 1, sm: 6 } }}
                  error={noStoresfoundError}
                  helperText="Aucun magasin disponible pour le code postal saisi"
                  InputProps={{
                    sx: { pr: 0 },
                    startAdornment: (
                      <InputAdornment position="start" onClick={(v) => getStoresList(v)} sx={{ cursor: "pointer" }}>
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <StyledIconButton
                          className={`${loadingLocation && "blink"} adornment-icon`}
                          onClick={() => getStoresListByLocation()}>
                          {" "}
                          <SvgLocalisation />
                        </StyledIconButton>
                      </InputAdornment>
                    ),
                  }}
                  // onKeyPress={(event) => {
                  //   if (event.key === "Enter") {
                  //     getStoresList();
                  //   }
                  // }}
                />
                <Hidden smUp>
                  <TabContext value={selectedTab}>
                    <StyledBox className="store-locator-tabs-container">
                      <TabList
                        onChange={handleChangeTab}
                        variant="scrollable"
                        aria-label=""
                        textColor="inherit"
                        indicatorColor="primary">
                        <Tab label="Liste" value="1" />
                        <Tab label="Carte" value="2" />
                      </TabList>
                    </StyledBox>
                    <TabPanel value="1" sx={{ overflowY: "auto" }}>
                      <StyledBox sx={{ overflowY: "auto" }}>
                        <StoreLocatorWidgetList postalCodeInput={postalCodeValue} />
                      </StyledBox>
                    </TabPanel>
                    <TabPanel value="2" sx={{ overflowY: "auto" }}>
                      <StoreLocatorWidgetMap />
                    </TabPanel>
                  </TabContext>
                </Hidden>
                <Hidden smDown>
                  <StyledBox sx={{ overflowY: "auto" }}>
                    <StoreLocatorWidgetList postalCodeInput={postalCodeValue} />
                  </StyledBox>
                </Hidden>
              </Stack>
            </StyledGrid>
            <Hidden smDown>
              <StyledGrid item sm={6}>
                <StoreLocatorWidgetMap />
              </StyledGrid>
            </Hidden>
          </StyledGrid>
        ) : (
          modalTypeSelected === modalType.Registration && (
            <StyledGrid container p={8}>
              <StyledGrid item xs={12}>
                {userFavStoreId ? (
                  <StyledBox sx={{ textAlign: "center" }}>
                    <SvgTrust />
                    <StyledTypography component="p" variant="h5" py={3} className="">
                      Votre choix
                      <br />
                      est confirmé
                    </StyledTypography>
                    <StyledTypography variant="body4" component="p" pb={6}>
                      Nous avons bien enregistré votre choix de magasin.
                    </StyledTypography>
                    <StyledButton
                      onClick={handleCloseModal}
                      testId="preferred-store-submit-confirm"
                      color="secondary"
                      sx={{ width: { xs: "100%", sm: " auto" } }}>
                      Fermer
                    </StyledButton>
                  </StyledBox>
                ) : (
                  <>
                    <StyledTypography component="p" variant="h5" pb={6} className="">
                      Personnalisez votre expérience
                    </StyledTypography>
                    <StyledTypography variant="body1" component="p" pb={6}>
                      Renseignez votre code postal pour choisir votre magasin préféré et découvrez les promotions
                      exclusives, la disponibilité en retrait, et les stocks en temps réel.
                    </StyledTypography>
                    <StyledTextField
                      variant="standard"
                      focused
                      className="underline-gray"
                      required
                      //id={`${cid}-zipCode`}
                      name="zipCode"
                      data-testid="zipcode"
                      label={"Code postal"}
                      onChange={(e) => handlePostalCodeChange(e.target.value)}
                      // onChange={(e) => handleCheckZipCodeStore(e)}
                      // onBlur={() =>
                      //   setErrorZipCodeStore(!formsUtil.validateZipCode(zipCodeStore, addressFormData?.country))
                      // }
                      // error={errorZipCodeStore}
                      // helperText={errorZipCodeStore ? t("RegistrationLayout.Msgs.InvalidFormat") : ""}
                      value={postalCodeValue}
                      inputProps={{ maxLength: 5 }}
                      fullWidth
                      autoComplete="postal-code"
                    />
                    <StoreLocatorWidgetSelect preferredStore={preferredStore} setPreferredStore={setPreferredStore} />
                  </>
                )}
              </StyledGrid>
            </StyledGrid>
          )
        )}
      </StyledBox>
    </StyledStoreLocatorWidget>
  );
};
