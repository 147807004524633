import styled from "@mui/styled-engine-sc";
import SvgIcon from "@mui/material/SvgIcon";

const Facebook = styled((props) => (
  <SvgIcon width="13" height="24" viewBox="0 0 13 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} />
))`
  ${({ theme }) => `
    .background {
    }
  `}
`;

export const SvgFacebook = (props) => {
  const { className = "" } = props;

  return (
    <Facebook {...{ className }}>
      <path d="M12.1693 13.518L12.8339 9.16923H8.61821V6.35897C8.61821 5.16923 9.19968 4.02051 11.0895 4.02051H13V0.287179C13 0.287179 11.2556 0 9.59425 0C6.14696 0 3.86262 2.07179 3.86262 5.84615V9.14872H0V13.4974H3.86262V24H8.61821V13.518H12.1693Z" />
    </Facebook>
  );
};
