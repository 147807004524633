/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Services - Account and Contract
 * These services provide APIs to manage accounts and contracts.  a contract is an agreement that represents the terms and conditions that apply to a transaction. An account is a relationship between the merchant and the financial institution that processes transactions for that merchant.
 *
 * The version of the OpenAPI document: 9.1.6
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../../base';
// @ts-ignore
import { NearestShopALLRequest } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { NearestShopResponse } from '../../../../../com/hcl/commerce/typescript/axios';
/**
 * NearestShopStoreLocatorPageAllResultsApi - axios parameter creator
 * @export
 */
export const NearestShopStoreLocatorPageAllResultsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Gets all the stores - for page on load results
         * @summary Gets all the stores - for page on load results
         * @param {string} storeId The store identifier.
         * @param {NearestShopALLRequest} nearestShopALLRequest The request object for finding near by store based on coordinates.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nearestShopALLStoreLocatorPage: async (storeId: string, nearestShopALLRequest: NearestShopALLRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('nearestShopALLStoreLocatorPage', 'storeId', storeId)
            // verify required parameter 'nearestShopALLRequest' is not null or undefined
            assertParamExists('nearestShopALLStoreLocatorPage', 'nearestShopALLRequest', nearestShopALLRequest)
            const localVarPath = `/store/{storeId}/NearestShop/getNearestShopList`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(nearestShopALLRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NearestShopStoreLocatorPageAllResultsApi - functional programming interface
 * @export
 */
export const NearestShopStoreLocatorPageAllResultsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NearestShopStoreLocatorPageAllResultsApiAxiosParamCreator(configuration)
    return {
        /**
         * Gets all the stores - for page on load results
         * @summary Gets all the stores - for page on load results
         * @param {string} storeId The store identifier.
         * @param {NearestShopALLRequest} nearestShopALLRequest The request object for finding near by store based on coordinates.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async nearestShopALLStoreLocatorPage(storeId: string, nearestShopALLRequest: NearestShopALLRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NearestShopResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.nearestShopALLStoreLocatorPage(storeId, nearestShopALLRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NearestShopStoreLocatorPageAllResultsApi - factory interface
 * @export
 */
export const NearestShopStoreLocatorPageAllResultsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NearestShopStoreLocatorPageAllResultsApiFp(configuration)
    return {
        /**
         * Gets all the stores - for page on load results
         * @summary Gets all the stores - for page on load results
         * @param {string} storeId The store identifier.
         * @param {NearestShopALLRequest} nearestShopALLRequest The request object for finding near by store based on coordinates.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nearestShopALLStoreLocatorPage(storeId: string, nearestShopALLRequest: NearestShopALLRequest, options?: any): AxiosPromise<NearestShopResponse> {
            return localVarFp.nearestShopALLStoreLocatorPage(storeId, nearestShopALLRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NearestShopStoreLocatorPageAllResultsApi - object-oriented interface
 * @export
 * @class NearestShopStoreLocatorPageAllResultsApi
 * @extends {BaseAPI}
 */
export class NearestShopStoreLocatorPageAllResultsApi extends BaseAPI {
    /**
     * Gets all the stores - for page on load results
     * @summary Gets all the stores - for page on load results
     * @param {string} storeId The store identifier.
     * @param {NearestShopALLRequest} nearestShopALLRequest The request object for finding near by store based on coordinates.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NearestShopStoreLocatorPageAllResultsApi
     */
    public nearestShopALLStoreLocatorPage(storeId: string, nearestShopALLRequest: NearestShopALLRequest, options?: any) {
        return NearestShopStoreLocatorPageAllResultsApiFp(this.configuration).nearestShopALLStoreLocatorPage(storeId, nearestShopALLRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
