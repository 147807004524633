import styled from "@mui/styled-engine-sc";
import { dimensions } from "../../themes/variables";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { palette } from "../../themes/color-palette";

// const headerWidth = dimensions.header.width.desktop;
const headerHeight = dimensions.header.height.desktop;
const mobileHeaderWidth = dimensions.header.width.mobile;
const mobileHeaderHeight = dimensions.header.height.mobile;

export const StyledHeaderIcon = styled(AccountCircleIcon)`
  ${({ theme }) => `
    color: ${theme.palette.text.secondary};
    vertical-align: middle;

    &:hover {
      color: ${theme.palette.primary.main};
    }
  `}
`;

const StyledHeader = styled("header")`
  ${({ theme }) => `

  background-color: ${theme.palette.background.paper};
  // box-shadow: 0px 3px 4px 0px ${theme.palette.text.disabled};
  position: relative;
  z-index: 20;

  transform: translateY(0);
  transition: transform .8s;
  transition-delay: .15s;
  &.compact {
    transform: translateY(-13.5em);
  }

  position:fixed;
  top:0;
  width:100%;

  .MuiOutlinedInput-input {
    border: none;
  }

  .expanded-menu-item {
    //letter-spacing: 0.5px;
    text-transform: uppercase;
    text-align: center;
  }

  .MuiTypography-body3.expanded-menu-item {
    -webkit-text-stroke-width: 1px;
    font-weight: 400;
  }

  .header-container {
    position: relative;
    z-index: 1;
    background-color: ${theme.palette.background.paper};
  }

  .expanded-menu {
    position: relative;
    &:after {
      position: absolute;
      bottom: 0px;
      left: 0;
      content: "";
      width: 100%;
      height: 4px;
      background-color: ${theme.palette.primary.main};
      transition: transform 0.3s;
      transform: scaleX(0);
      transform-origin: right;
    }

    &.expanded-menu-hover-blue:after {
      background-color: ${theme.palette.secondary.main};
    }

    button {
      max-height: initial;
    }

    .MuiSvgIcon-root {
      transition: transform .2s;
    }
  }

  .expanded-menu-hover {
    position: relative;
    cursor: pointer;

    &:after {
      transform: scaleX(1);
      transform-origin: left;
    }

    .expanded-menu-item {
      -webkit-text-stroke-width: 1px;
    }

    .MuiSvgIcon-root {
      transform: rotate(180deg);
      transition: all .2s;
    }
  }

  .separateur {
    position: relative;
    padding: 0 ${theme.spacing(4)};
    @media (max-width: 320px) {
      padding: 0 ${theme.spacing(2)};
    }
    &:after {
      position: absolute;
      top: -3px;
      right: 0;
      content: "";
      width: 1px;
      height: 28px;
      border-right: 1px solid ${theme.palette.gray.neutralGray300};
    }
  }

  .header-topbarSection {
    .MuiButtonBase-root {
      text-transform: none;
    }
    height: ${headerHeight}px;
    width: auto;
    margin: 0 ${theme.spacing(8)} 0 0;

    ${theme.breakpoints.down("md")} {
      height: ${mobileHeaderHeight}px;
      margin: 0 ${theme.spacing(2)} 0 0;
    }

    @media (max-width: 320px) {
      margin: 0;
    }
  }

  .header-searchBarSection {
    margin: 0 ${theme.spacing(5)} 0 ${theme.spacing(5)};
    position: relative;

    &.border-right {
      &:after {
        position: absolute;
        top: 6px;
        right: -15px;
        content: "";
        width: 1px;
        height: 28px;
        border-right: 1px solid #E0E0E0;
      }
    }
  }

  .header-searchBarSection-mobile {
    position: relative;
    width: 100%;
    background-color: ${theme.palette.background.paper};
  }

  .welcome-text {
    .MuiTypography-button {
      color: ${theme.palette.text.primary};
    }

    .MuiTypography-root {
      line-height: 1.5;
    }
  }

  .header-actionsButton {
    padding: ${theme.spacing(1)};

    &.header-actionsButton-languageToggle .MuiTypography-body1 {
      line-height: 26px;
    }
  }

  .header-branding {
    a {
      display: flex;
    }
    img {
      width: auto;
      height: ${headerHeight}px;
    }

    svg {
      display: block;
      height: ${headerHeight}px;
    }


    ${theme.breakpoints.down("md")} {
      img {
        width: auto;
        height: ${mobileHeaderHeight}px;
      }
      svg {
        width: ${mobileHeaderWidth}px;
        height: ${mobileHeaderHeight}px;
      }
      flex-shrink: 0!important;
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
    }
  }

  .header-links {
    text-align: right;
    ${theme.breakpoints.up("sm")} {
      padding: 0 ${theme.spacing(2)};
    }
  }

  .menu-hamburger {
    background: none;
    border: 0;
    margin: 0;
    padding: 0;

    &:hover {
      color: ${theme.palette.primary.dark};
      cursor: pointer;
    }

    .MuiSvgIcon-root {
      vertical-align: middle;
    }
  }

  .popper-container {
    width: calc(100% - ${theme.spacing(12)});
    left: -20px !important;
    top: 12px !important;
  }

  .menu-container {
    display: none;

    &.open {
      display: flex;
    }
  }

  .expanded-menu-container{
    min-height: 54px;
    display: flex;
    align-items: center;
    background-color: ${theme.palette.header.main};
    line-height: normal;
    position: relative;
    color: ${theme.palette.text.primary};
    text-align: left;
    z-index: -1;
    &.hover {
      .MuiBox-root {
        background-color:${theme.palette.background.paper};
      }
    }
  }


  #signin_link,
  #myaccount_link,
  #cart_link {
    text-decoration: none;
    &:visited {
      color: ${theme.palette.text.primary};
    }

    &:hover {
      color: ${theme.palette.primary.main};

      .MuiSvgIcon-root {
        color: ${theme.palette.primary.main};
      }
    }

    .MuiSvgIcon-root {
      color: ${theme.palette.text.primary};
      vertical-align: middle;
      font-size: 1.3rem;
    }
  }

  .menu-container {
    position: absolute;
    z-index: 3;
    background: white;
    width: 100%;
    margin: 0;
    left: 0;
    border-top: 1px solid ${theme.palette.action.disabledBackground};
    box-shadow: 0px 3px 4px 0px ${theme.palette.text.disabled};
    top: ${headerHeight}px;

    ${theme.breakpoints.down("md")} {
      top: ${mobileHeaderHeight}px;
      width: 500px;
    }
  }

  .header-menu {
    .MuiDrawer-paperAnchorTop {
      margin-top: ${headerHeight}px;
    }
  }

  .MuiDrawer-paperAnchorLeft {
      padding-top: ${theme.spacing(16)};
    }

  /* MEGAMENU DESKTOP */

  .expanded-menu-three-tier-submenu {
    border-radius: 8px;

    .MuiTypography-body1 button {
      font-weight: 700;

      .MuiSvgIcon-root {
        path {
          fill: ${theme.palette.gray.neutralGray800};
        }
      }
    }
  }

  .expanded-menu-three-tier-submenu .expanded-menu-first-tier {
    background: ${theme.palette.gray.neutralGray200};
    position: relative;
  }
  .expanded-menu-three-tier-submenu .expanded-menu-second-tier {
    background: ${theme.palette.gray.neutralGray100};
    padding-top: ${theme.spacing(19)};
    position: relative;
  }

  .expanded-menu-three-tier-submenu .expanded-menu-third-tier {
    padding-top: ${theme.spacing(19)};
    position: relative;

    ul {
      display: flex;
      flex-wrap: wrap;

      li {
      width: 50%;
    }
    }
  }

  .expanded-menu-three-tier-submenu ul {
    list-style: none;
    display: block;
  }

  .expanded-menu-three-tier-submenu ul li {
    padding-bottom: ${theme.spacing(2)};
    width: 100%;

    a, .expanded-menu-button {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-right: ${theme.spacing(2)};

    }
  }


  .expanded-menu-three-tier-submenu .expanded-menu-button {
    width: 100%;
    display: block;
    text-align: left;
    position: relative;
    text-transform: none;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;

    .MuiSvgIcon-root {
      path {
        fill: ${theme.palette.gray.neutralGray400};
      }
    }

    .MuiButton-endIcon {
      position: absolute;
      right: 0;
      top: 0;
    }
    &:hover {
      font-weight: 700;

      .MuiSvgIcon-root {
        path {
          fill: ${theme.palette.gray.neutralGray800};
        }
      }
    }

    &.expanded-menu-button-active {
      font-weight: 700;

      .MuiSvgIcon-root {
        path {
          fill: ${theme.palette.gray.neutralGray800};
        }
      }
    }
  }

  .expanded-menu-three-tier-submenu .expanded-menu-bottom-link {
    position: absolute;
    bottom: ${theme.spacing(7)};
    left: ${theme.spacing(5)};
    text-align: left;
    text-transform: none;

    /*a {
      color: ${theme.palette.primary.main};
    }*/
  }

  .expanded-menu-svg-close {
    position: absolute;
    right: ${theme.spacing(3)};
    top: ${theme.spacing(3)};
  }

  .collection-menu-box {
    position: relative;
    height: 120px;
    border-radius: 8px;

    &:before {
      content :"";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 8px;
      background: ${theme.palette.gray.neutralGray900};
      opacity: 0.4;
    }

    .collection-menu-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 8px;
    }

    .collection-menu-text {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }




  /* Banner Info */
  .banner-info {
    z-index:1;
    background: ${palette.gray.neutralGray900};
    color: ${palette.gray.neutralGray400};
    position: relative;
    animation: bg-color ease-out 2s;
    padding-top: ${theme.spacing(3.5)};
    padding-bottom: ${theme.spacing(3.25)};
    border-bottom:  1px solid ${palette.gray.neutralGray900};
    @keyframes bg-color {
        0% { background: ${palette.primary.dark}; }
        50% { background: ${palette.primary.dark}; }
        100% { background: ${palette.gray.neutralGray900}; }
    }

    a {
      color: ${palette.gray.neutralGray400};
      text-decoration: underline;
    }
    .banner-info-scroll {
      width: 90%;
      overflow: hidden;
    }
    .banner-info-text {
      display: inline-block;
      white-space: nowrap;

      p {
        display: inline-block;
        margin: 0 ${theme.spacing(30)} 0 0;
        color: ${palette.gray.neutralGray400};
        white-space: nowrap;
      }
      animation: defilement-rtl 20s infinite linear;
      padding-left: 100%;
      padding-right: 30px;

      &:hover, &:focus {
        animation-play-state: paused;
      }
      &.scroll {

      }
    }

    ${theme.breakpoints.down("sm")} {
      .banner-info-scroll {
        max-width: 300px;
        width: 90%;
        overflow: hidden;
      }
      .banner-info-text {
        //white-space: nowrap;
        animation: defilement-rtl 15s infinite linear;
        //animation-delay: 3s;
        padding-left: 100%;
        padding-right: 30px;

        p {
          margin: 0 ${theme.spacing(10)} 0 0;
        }

        &:hover, &:focus {
            animation-play-state: paused;
        }
      }
    }
    @keyframes defilement-rtl {
        0% {
          transform: translate3d(0,0,0);
        }
        100% {
          transform: translate3d(-100%,0,0);
        }
      }

    .banner-info-svg {
      position: absolute;
      right: ${theme.spacing(6)};
      top: ${theme.spacing(3.5)};
      padding: ${theme.spacing(1)};

      ${theme.breakpoints.down("sm")} {
        right: ${theme.spacing(5)};
        padding: ${theme.spacing(1)} 0;
      }

      svg {
        color: ${palette.gray.neutralGray400};
      }
    }

  `}
`;

export { StyledHeader };
