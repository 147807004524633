import { useEffect, useState } from "react";

/*
 * Custom hook for fetching data in component without redux
 */
export const useFetch = (
  func: (parameters) => any,
  parameters?: any,
  updateCallbacks: any[] = [],
  souldExecute: boolean = true
) => {
  const [success, setSuccess] = useState<boolean>(false);
  const [data, setData] = useState<any>(null);
  const [error, setError] = useState<boolean>(false);
  const [errorData, setErrorData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    if (souldExecute) {
      const fetchData = async () => {
        setLoading(true);
        setError(false);
        setSuccess(false);
        try {
          const result = await func(parameters);
          if (!signal.aborted) {
            result.data && setData(result.data);
            setSuccess(true);
          }
        } catch (error) {
          if (!signal.aborted) {
            setError(true);
            setErrorData(error || "Unknown error");
            setSuccess(false);
          }
        }
        if (!signal.aborted) {
          setLoading(false);
        }
      };
      fetchData();
    }

    return () => {
      abortController.abort();
    };
  }, updateCallbacks); // eslint-disable-line
  return { success, data, error, loading, errorData };
};
