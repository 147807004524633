/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Services - Account and Contract
 * These services provide APIs to manage accounts and contracts.  a contract is an agreement that represents the terms and conditions that apply to a transaction. An account is a relationship between the merchant and the financial institution that processes transactions for that merchant.
 *
 * The version of the OpenAPI document: 9.1.6
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../../base';
// @ts-ignore
import { ComLapeyreCommerceControllersGetStatusRequest } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { ComLapeyreCommerceControllersGetStatusResponse } from '../../../../../com/hcl/commerce/typescript/axios';
/**
 * GetCheckoutStatusApi - axios parameter creator
 * @export
 */
export const GetCheckoutStatusApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get the status of the payment.
         * @summary getHostedCheckoutStatus
         * @param {string} storeId The store identifier.
         * @param {ComLapeyreCommerceControllersGetStatusRequest} comLapeyreCommerceControllersGetStatusRequest The request object for the checkout.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHostedCheckoutStatus: async (storeId: string, comLapeyreCommerceControllersGetStatusRequest: ComLapeyreCommerceControllersGetStatusRequest, responseFormat?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('getHostedCheckoutStatus', 'storeId', storeId)
            // verify required parameter 'comLapeyreCommerceControllersGetStatusRequest' is not null or undefined
            assertParamExists('getHostedCheckoutStatus', 'comLapeyreCommerceControllersGetStatusRequest', comLapeyreCommerceControllersGetStatusRequest)
            const localVarPath = `/store/{storeId}/resources/getHostedCheckoutStatus`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(comLapeyreCommerceControllersGetStatusRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GetCheckoutStatusApi - functional programming interface
 * @export
 */
export const GetCheckoutStatusApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GetCheckoutStatusApiAxiosParamCreator(configuration)
    return {
        /**
         * Get the status of the payment.
         * @summary getHostedCheckoutStatus
         * @param {string} storeId The store identifier.
         * @param {ComLapeyreCommerceControllersGetStatusRequest} comLapeyreCommerceControllersGetStatusRequest The request object for the checkout.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHostedCheckoutStatus(storeId: string, comLapeyreCommerceControllersGetStatusRequest: ComLapeyreCommerceControllersGetStatusRequest, responseFormat?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComLapeyreCommerceControllersGetStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHostedCheckoutStatus(storeId, comLapeyreCommerceControllersGetStatusRequest, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GetCheckoutStatusApi - factory interface
 * @export
 */
export const GetCheckoutStatusApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GetCheckoutStatusApiFp(configuration)
    return {
        /**
         * Get the status of the payment.
         * @summary getHostedCheckoutStatus
         * @param {string} storeId The store identifier.
         * @param {ComLapeyreCommerceControllersGetStatusRequest} comLapeyreCommerceControllersGetStatusRequest The request object for the checkout.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHostedCheckoutStatus(storeId: string, comLapeyreCommerceControllersGetStatusRequest: ComLapeyreCommerceControllersGetStatusRequest, responseFormat?: string, options?: any): AxiosPromise<ComLapeyreCommerceControllersGetStatusResponse> {
            return localVarFp.getHostedCheckoutStatus(storeId, comLapeyreCommerceControllersGetStatusRequest, responseFormat, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GetCheckoutStatusApi - object-oriented interface
 * @export
 * @class GetCheckoutStatusApi
 * @extends {BaseAPI}
 */
export class GetCheckoutStatusApi extends BaseAPI {
    /**
     * Get the status of the payment.
     * @summary getHostedCheckoutStatus
     * @param {string} storeId The store identifier.
     * @param {ComLapeyreCommerceControllersGetStatusRequest} comLapeyreCommerceControllersGetStatusRequest The request object for the checkout.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GetCheckoutStatusApi
     */
    public getHostedCheckoutStatus(storeId: string, comLapeyreCommerceControllersGetStatusRequest: ComLapeyreCommerceControllersGetStatusRequest, responseFormat?: string, options?: any) {
        return GetCheckoutStatusApiFp(this.configuration).getHostedCheckoutStatus(storeId, comLapeyreCommerceControllersGetStatusRequest, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }
}
