import styled from "@mui/styled-engine-sc";
import { palette } from "../../../themes/color-palette";

export const StyledKeenSlider = styled("div")`
  ${({ theme }) => `
    .arrow {
      position: relative;
      display: block;
      border-radius: 0;
      box-shadow: none;
      top: auto;
      cursor: pointer;
      transform: none;
      width: auto;
      height: auto;
      padding-top: 4px;

      &.arrow--left {
        left: auto;
        margin-right: auto;

        svg {
            transform: rotate(180deg);
        }
      }

      &.arrow--right {
        right: auto;
        margin-left: auto;
      }

      /*svg {
        fill: ${palette.text.primary};
      }*/
    }
    .arrow-big {
      position: absolute;
      top: calc(50% + 96px);
      transform: translateY(-50%);
      width: 44px;
      height: 44px;
      background: ${palette.white.main};
      box-shadow: 0px 2px 6px rgba(37, 36, 39, 0.25);
      border-radius: 23px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 19;
      cursor: pointer;

      &.arrow--left {
        left: ${theme.spacing(4)};

        svg {
          transform: rotate(180deg);
        }

        &.left-align-0 {
          top: 50%;
          left: 0;

          ${theme.breakpoints.up("sm")} {
            left: -24px;
          }
        }
      }

      &.arrow--right {
        left: auto;
        right: ${theme.spacing(4)};

        &.right-align-0 {
          top: 50%;
          right: 0;

          ${theme.breakpoints.up("sm")} {
            right: -24px;
          }
        }
      }

      &.arrow--disabled {
        visibility: hidden;
        //transition: all 0.3s;
      }

      /* svg {
        fill: ${palette.blue.dark};
      } */
    }

    &.absolute-arrow {
      .arrow {
        position: absolute;
        top: ${theme.spacing(6)};
        width: 35px;
        height: 35px;
        text-align: center;
      }

      .arrow--left {
        left: 5px;
      }

      .arrow--right {
        right: 5px;
      }

      .arrow--disabled {
        visibility: hidden;
      }
    }


    .dots {
      display: flex;
      justify-content: center;


    }
    .dot {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      margin: 0 ${theme.spacing(1)};
      padding: ${theme.spacing(0.5)};
      margin-top: ${theme.spacing(2.25)};
      cursor: pointer;
      border-width: 1px;
      border-style: solid;

      &.fill {
        width: stretch;
        border-radius: 0;
        margin: 0;
        border: 0;

        &.small {
          height: 4px;
        }
        &:first-child {
          border-radius: ${theme.spacing(1)} 0 0 ${theme.spacing(1)};
        }
        &:last-child {
          border-radius: 0 ${theme.spacing(1)} ${theme.spacing(1)} 0;
        }
      }
    }
    .dot:focus {
      outline: none;
    }
    /*.dot.active {
      background: ${palette.text.primary};
    }*/

    .range-nav {
      text-align: center;
      //padding: ${theme.spacing(10)} 0 0 0;
      --thumbWidth: 1.4rem;
      position: relative;
      display: flex;
      margin: 0 auto;
      top: -4px;
      opacity: 0;

      .slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: var(--thumbWidth);
        height: ${theme.spacing(1)};
        background: ${palette.blue.dark};
        border-radius: 4px;
        user-select: none;
        pointer-events: none;
        position: relative;
        top: 0;
      }
      .range-thumb {
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        height: ${theme.spacing(1)};
        outline: none;
        margin: 0 auto;
        user-select: none;
        pointer-events: none;
      }

      .range-input {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        outline: none;
        width: 100%;
        height: ${theme.spacing(1)};
        background: ${palette.blue.light};
        border-radius: 4px;
        margin: 0 auto;
        cursor: pointer;

        &::-webkit-slider-thumb {
          -webkit-appearance: none;
          appearance: none;
          width: var(--thumbWidth);
          height: ${theme.spacing(1)};
          background: ${palette.blue.light};
          cursor: pointer;
          border-radius: 4px;
          border: none;
        }
        &::-moz-range-thumb {
          -webkit-appearance: none;
          appearance: none;
          width: var(--thumbWidth);
          height: ${theme.spacing(1)};
          background: ${palette.blue.light};
          cursor: pointer;
          border-radius: 4px;
          border: none;
        }
      }
    }
  `}
`;
