import styled from "@mui/styled-engine-sc";
import SvgIcon from "@mui/material/SvgIcon";

const Youtube = styled((props) => (
  <SvgIcon width="25" height="18" viewBox="0 0 25 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} />
))`
  ${({ theme }) => ``}
`;

export const SvgYoutube = (props) => {
  const { className = "" } = props;

  return (
    <Youtube {...{ className }}>
      <path d="M24.5 5.82653C24.5 2.88775 22.1403 0.5 19.2361 0.5H5.76387C2.85966 0.5 0.5 2.88775 0.5 5.82653V12.1735C0.5 15.1122 2.85966 17.5 5.76387 17.5H19.2361C22.1403 17.5 24.5 15.1122 24.5 12.1735V5.82653ZM16.574 9.47959L10.5437 12.5C10.3017 12.6224 9.49496 12.4592 9.49496 12.1939V5.96939C9.49496 5.68367 10.3017 5.52041 10.5437 5.66327L16.3319 8.84694C16.574 8.9898 16.8361 9.33673 16.574 9.47959Z" />
    </Youtube>
  );
};
