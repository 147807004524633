import SvgIcon from "@mui/material/SvgIcon";
import styled from "@mui/styled-engine-sc";

const Svg = styled((props) => (
  <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} />
))`
  ${({ theme }) => `
    width:20px;
    height:20px;
    position: relative;

    &.small {
      width:16px;
      height:16px;
    }
    &.big {
      width:24px;
      height:24px;
    }
    &.primary-dark {
      path {
        fill: ${theme.palette.primary.dark};
      }
    }

    &.store-locator-open:after {
      content: '';
      position: absolute;
      top: -${theme.spacing(0.5)};
      left: ${theme.spacing(4.3)};
      width: 6px;
      height: 6px;
      background: ${theme.palette.success.main};
      border-radius: 100%;
    }

    /* Store Locator Close */
    &.store-locator-close:after {
      content: '';
      position: absolute;
      top: -${theme.spacing(0.5)};
      left: ${theme.spacing(4.3)};
      width: 6px;
      height: 6px;
      background: ${theme.palette.error.main};
      border-radius: 100%;
    }
  `}
`;

export const SvgLocationOnOutlined = (props) => {
  const { className = "" } = props;

  return (
    <Svg {...{ className }}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.73664 2.5C3.73664 1.94772 4.18435 1.5 4.73664 1.5H15.265C15.8173 1.5 16.265 1.94772 16.265 2.5C16.265 3.05228 15.8173 3.5 15.265 3.5H4.73664C4.18435 3.5 3.73664 3.05228 3.73664 2.5ZM14.2647 11.3231H12.7556V15.7345L12.7556 15.783C12.7556 16.1821 12.7557 16.5606 12.7221 16.8645C12.688 17.1728 12.6015 17.6254 12.2453 17.9837C11.888 18.343 11.435 18.4309 11.1263 18.4654C10.8232 18.4992 10.4459 18.4992 10.0496 18.4991L10.0008 18.4991H6.49137L6.44265 18.4991C6.04627 18.4992 5.66901 18.4992 5.36587 18.4654C5.05719 18.4309 4.60425 18.343 4.24689 17.9837C3.89065 17.6254 3.80417 17.1728 3.7701 16.8645C3.73653 16.5606 3.73658 16.1821 3.73663 15.783L3.73664 15.7345V11.323H3.73755C2.45587 11.3119 1.51334 10.1126 1.80936 8.86233L2.53348 5.80394C2.74707 4.90182 3.55261 4.26473 4.47967 4.26473H15.5211C16.4482 4.26473 17.2537 4.90182 17.4673 5.80394L18.1914 8.86233C18.4873 10.1121 17.5457 11.311 16.2647 11.323V17.4991C16.2647 18.0513 15.817 18.4991 15.2647 18.4991C14.7124 18.4991 14.2647 18.0513 14.2647 17.4991V11.3231ZM5.73664 11.3231V15.7345C5.73664 16.0821 5.73746 16.3178 5.74616 16.4898C5.91609 16.4983 6.14873 16.4991 6.49137 16.4991H10.0008C10.3435 16.4991 10.5761 16.4983 10.746 16.4898C10.7547 16.3178 10.7556 16.0821 10.7556 15.7345V11.3231H5.73664ZM10.7184 16.7412L10.7196 16.7368C10.7189 16.7399 10.7184 16.7413 10.7184 16.7412ZM10.993 16.4636C10.9959 16.4627 10.9975 16.4623 10.9976 16.4623C10.9976 16.4624 10.9962 16.4628 10.993 16.4636ZM5.49463 16.4623C5.4947 16.4623 5.49627 16.4627 5.49915 16.4636C5.49601 16.4628 5.49457 16.4624 5.49463 16.4623ZM5.77257 16.7368L5.77382 16.7412C5.77379 16.7413 5.77334 16.7399 5.77257 16.7368ZM4.47967 6.26473H15.5211L16.2452 9.32312L3.75556 9.32312L4.47967 6.26473Z"
      />
    </Svg>
  );
};
