/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */

//Standard libraries
import { AxiosPromise } from "axios";
//Foundation libraries
import { getSite } from "../../hooks/useSite";
import { site } from "../../constants/site";
import {
  DocumentsApi,
  StoreQuotesDetailsApi,
  StoreQuotesListApi,
  StoreQuotesValidateApi,
  StoreQuotesTransformApi,
} from "@hcl-commerce-store-sdk/typescript-axios-transaction";

const documentApiInstance = new DocumentsApi(undefined, site.transactionContext);
const storeQuotesDetailsApiInstance = new StoreQuotesDetailsApi(undefined, site.transactionContext);
const storeQuotesListApiInstance = new StoreQuotesListApi(undefined, site.transactionContext);
const storeQuoteValidateApiInstance = new StoreQuotesValidateApi(undefined, site.transactionContext);
const transformOrderInstance = new StoreQuotesTransformApi(undefined, site.transactionContext);

const documentService = {
  /**
   * Tranform Store Quotes
   * @summary tranforms store quotes
   * @param {object} body numeroDevis, siteId, dateMiseDispoPrevisionnelle
   * @throws {RequiredError}
   */
  tranformOrder(parameters: Record<any, any>): AxiosPromise<any> {
    const siteInfo = getSite();
    const { storeId = siteInfo?.storeID, body, ...options } = parameters;
    return transformOrderInstance.storeQuotesTransform(storeId, body, options);
  },

  /**
   * Validate Store Quotes
   * @summary Fetch store quotes details
   * @param {string} storeId The store identifier.
   * @param {FetchStoreQuotes} [body] The body data required for the REST method.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  validateQuote(parameters: Record<any, any>): AxiosPromise<any> {
    const siteInfo = getSite();
    const { storeId = siteInfo?.storeID, body, ...options } = parameters;
    return storeQuoteValidateApiInstance.storeQuotesValidate(storeId, body, options);
  },

  /**
   * Fetch store quotes details
   * @summary Fetch store quotes details
   * @param {string} storeId The store identifier.
   * @param {FetchStoreQuotes} [body] The body data required for the REST method.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  storeQuotesDetails(parameters: any): AxiosPromise<any> {
    const siteInfo = getSite();
    const { storeId = siteInfo?.storeID, body, ...options } = parameters;
    return storeQuotesDetailsApiInstance.storeQuotesDetails(storeId, body, options);
  },

  /**
   * Fetch store quotes
   * @summary Fetch store quotes
   * @param {string} storeId The store identifier.
   * @param {FetchStoreQuotes} [body] The body data required for the REST method.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  storeQuotes(parameters: any): AxiosPromise<any> {
    const siteInfo = getSite();
    const { storeId = siteInfo?.storeID, body, ...options } = parameters;
    return storeQuotesListApiInstance.storeQuotes(storeId, body, options);
  },

  /**
   * Display the list of document for the authenticated User
   * @summary Get the documents info for a User
   * @param {string} storeId The store identifier.
   * @param {DocumentsFilteringRequest} [documentsFilteringRequest] The request object for document request.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentsApi
   */
  getCustomerDocuments(parameters: any): AxiosPromise<any> {
    const siteInfo = getSite();
    const { storeId = siteInfo?.storeID, body, ...options } = parameters;
    return documentApiInstance.getCustomerDocuments(storeId, body, options);
  },

  /**
   * Display the details of document for the authenticated User and Document ID
   * @summary Get the document detail using the document ID
   * @param {string} storeId The store identifier.
   * @param {string} documentId The document identifier.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentsApi
   */
  getDocumentByID(parameters: any): AxiosPromise<any> {
    const siteInfo = getSite();
    const { storeId = siteInfo?.storeID, documentId, ...options } = parameters;
    return documentApiInstance.getDocumentByID(storeId, documentId, options);
  },
};

export default documentService;
