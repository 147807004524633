/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
export const GTM_ID = "google.tag.manager.container.id";
export const GTM_AUTH = "google.tag.manager.auth";
export const GTM_PREVIEW = "google.tag.manager.preview";
export const GA_VERSIONS = "google.analytics.versions";
export const GA_VERSION_UA = "UA";
export const GA_VERSION_GA4 = "GA4";

/* EVENT */
export const PAGE_LOAD_EVENT = {
  PAGE_LOAD: "virtual_pageview",
  ITEM_LIST: "view_item_list",
  ITEM: "view_item",
  SEARCH_RESULT: "view_search_results",
  SEARCH_NORESULT: "internal_search_no_result",
  STORE_LOCATOR: "localisateur_magasin",
  VIEW_PROMOTION: "view_promotion",
};
export const CLICK = {
  CTA: "click_cta",
  HEADER: "click_header",
  ARIANE: "click_fildariane",
  NAV: "click_navigation",
  PROJECT: "my_project",
  CONTACT: "click_chat",
  SERVICE: "click_service",
  CONTACT_SELECT: "click_contact",
  SLIDER: "slider",
  FOOTER: "click_footer",
  NEWSLETTER: "newsletter_subscription",
  PRODUCT_PICTURE: "click_picture",
  PRODUCT_USER: "user_interaction",
  PRODUCT_DETAIL: "click_detail",
  PRODUCT_RECOMMANDED: "click-on-reco",
  PRODUCT_RDV: "click_rdv",
  PRODUCT_ADD_TO_CART: "add_to_cart",
  PRODUCT_REMOVE_FROM_CART: "remove_from_cart",
  FILTER: "click_filter",
  SELECT_ITEM: "select_item",
  SELECT_PROMO: "select_promotion",
  WRITE_US: "click_form_contact",
  FORM_START: "form_start",
  FORM_SUBMIT: "form_submit",
  CONTACT_SEND: "contact_submitted",
  START_PROJECT: "start_project",
  TOGGLE: "click_toggle",
  PAYMENT: "click_payment",
  HUB_CONTACT: "click_hub_contact",
  FORM_SAV: "click_form_sav",
  SAV_SUBMITTED: "sav_submitted",
  DELETE_ACCOUNT: "delete_account",
  QUOTE_VALIDATION: "quote_validation",
  POPIN_QUOTE: "popin_quote",
  POPIN_RDV: "popin_rdv",
  SIGN_UP: "sign_up",
  LOGIN: "login",
  STORE_LOCATOR: "localisateur_magasin",
  RDV_STORE: "click_rdv_magasin",
  RDV_HOME: "click_rdv_domicile",
  POPIN_AVAILABILITY: "popin_verifdisponibilite",
  CONFIG: "click_config",
  CLEAR_CART: "clear_cart",
};

/* CONTENT_GROUP */
export const CONTENT_GROUP = {
  CONTENT: "contenu",
  FAQ: "faq",
  PROMOTION: "promotion",
  PRODUCT_LIST: "liste de produits",
  PRODUCT_DETAIL: "fiche produit",
  FUNNEL: "funnel",
  ACCOUNT: "espace client",
  CONTACT: "contact",
  SAV: "sav",
  PAC: "pac",
  SERVICES: "conseil & services",
  PROJET: "projet",
};

/* CONTENT_GROUP */
export const FORM_NAME = {
  CONTACT: "contact",
  RDVSTORE: "rdv magasin",
  RDVHOME: "rdv domicile",
  SAV: "sav",
};

/* PAGE TYPE */
export const TYPE = {
  HOME: "homepage",
  NOT_FOUND: "not found",
  ACCUEIL: "accueil",
  PROMO_ALL: "promo",
  KITCHEN_MODEL: "modele de cuisine",
  HUB: "hub",
  ACCOUNT: "mon compte",
  PRODUCT_STANDARD: "fiche produit standard",
  PRODUCT_COMPLEXE: "fiche produit complexe",
  PRODUCT_CUISINE: "fiche produit cuisine",
  PRODUCT_PERSONNALISABLE: "fiche produit personnalisable",
  PRODUCT_COLISE: "fiche produit colise",
  PRODUCT_SUR_MESURE: "fiche produit sur-mesure",
  PRODUCT_CONFIGURATEUR: "fiche produit configurateur",
  PRODUCT_LIST: "liste de produits",
  PANIER: "panier",
  CHECKOUT: "commande",
  CONFIRMATION: "confirmation",
  PROMOTION: "promotion",
  POPIN: "popin",
  FORM: "formulaire",
  UNIVERSE: "univers",
  PREFERENCES: "mes preferences",
  EMAIL_SENT: "email envoye",
  CREATE_ACCOUNT: "creation de compte",
  LOGIN: "login",
  INSTITUTIONNEL: "institutionnel",
  COLLECTION: "collection",
};

/********** CART EVENT ************/
export const CART_EVENT = {
  ADD_TO_CART: "add_to_cart",
  REMOVE_FROM_CART: "remove_from_cart",
  BEGIN_CHECKOUT: "begin_checkout",
  ADD_PAYMENT_INFO: "add_payment_info",
  ADD_SHIPPING_INFO: "add_shipping_info",
  POPIN_ADDRESS: "popin_adress",
  PURCHASE: "purchase",
  VIEW_CART: "view_cart",
  VIEW_PROMOTION: "view_promotion",
  SELECT_PROMOTION: "select_promotion",
  VIEW_ITEM_LIST: "view_item_list",
  SELECT_ITEM: "select_item",
  SEARCH: "search",
  VIEW_ITEM: "view_item",
};
/********** OLD FILE SDK ************/
export const KEYWORD_SEARCH = "keywordSearch";
export const HOME_CLICK = "homeClick";
export const NAVIGATION_CLICK = "navigationClick";
export const FORM_COMPLETION = "formCompletion";
export const PRODUCT_IMPRESSION = "productImpression";
export const PRODUCT_CLICK = "productClick";
export const PRODUCT_DETAIL = "productDetail";
export const ADD_TO_CART = "addToCart";
export const REMOVE_FROM_CART = "removeFromCart";
export const PROMO_VIEW = "promoView";
export const PROMOTION_CLICK = "promotionClick";
export const PURCHASE = "purchase";
export const CHECK_OUT = "checkout";
export const CHECK_OUT_OPTION = "checkoutOption";
export const ONSITE_SEARCH = "onsite Search";
export const LISTER = "lister";
export const PDP = "PDP";
export const CHECKOUT = "checkout";
export const CART = "cart";

// List of eSpots/promotions for which Google tagging is not required.
export const DISABLED_ESPOT_LIST = ["HeaderStoreLogo", "FooterStoreLogo"];
//
export const GA4_EVENT_ADD_TO_CART = "GA4_add_to_cart";
export const GA4_EVENT_REMOVE_FROM_CART = "GA4_remove_from_cart";
export const GA4_EVENT_BEGIN_CHECKOUT = "GA4_begin_checkout";
export const GA4_EVENT_ADD_PAYMENT_INFO = "GA4_add_payment_info";
export const GA4_EVENT_ADD_SHIPPING_INFO = "GA4_add_shipping_info";
export const GA4_EVENT_PURCHASE = "GA4_purchase";
export const GA4_EVENT_VIEW_CART = "GA4_view_cart";
export const GA4_EVENT_VIEW_PROMOTION = "GA4_view_promotion";
export const GA4_EVENT_SELECT_PROMOTION = "GA4_select_promotion";
export const GA4_EVENT_VIEW_ITEM_LIST = "GA4_view_item_list";
export const GA4_EVENT_SELECT_ITEM = "GA4_select_item";
export const GA4_EVENT_SEARCH = "GA4_search";
export const GA4_EVENT_VIEW_ITEM = "view_item";
