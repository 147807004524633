export function objectToArray(obj: object): object[] {
  if (!obj) {
    return [];
  }
  return Object.entries(obj).map(([key, value]) => ({
    key,
    value,
  }));
}

export function truncateNumberToDecimals(number: number, decimal: number = 2): number {
  const numString = number.toString();
  const decimalIndex = numString.indexOf(".");
  if (decimalIndex === -1) {
    return number;
  }
  const truncatedString = numString.slice(0, decimalIndex + (decimal + 1));
  return parseFloat(truncatedString);
}

/**
 * This function handles addition and subtration among array of numbers precisely upto specified decimal places
 * @param numbers array of numbers
 * @param decimals number to decide at which point after decimal point preciseness should be
 * @returns precisely calculated number without floating issues upto 2 decimal places
 * @example 21.86 - 2.19 = 19.669999999999998 in javascript
 * but preciseCalculate([21.86, -2.19]) returns 19.67
 */
export function preciseCalculate(numbers: number[], decimals: number = 3): number {
  if (!numbers || numbers.length === 0) {
    return 0;
  }
  const finalNum =
    numbers.reduce((acc, curr) => {
      const num = curr * Math.pow(10, decimals);
      return parseFloat((acc + num).toFixed(2));
    }, 0) / Math.pow(10, decimals);
  return truncateNumberToDecimals(finalNum);
}

/**
 *
 * @param item pagination item data with it's properties
 * @param pageCountTotal total pages available in particular product listing page
 * @param selectedPage current selected page
 * @returns true if pagination item should render otherwise false
 * @description to display pagination items which are
 * 1. previous and next pagination buttons.
 * 2. selected page, first page and last page always.
 * 3. two pages less than or more than selected page.
 * 4. pages which are multiples of 10 but not more than 30 (so 10, 20, 30 will be visible but not 40).
 */
export function shouldRenderPaginationItem(
  item: Record<any, any>,
  pageCountTotal: number,
  selectedPage: number
): boolean {
  if (!item) {
    return false;
  }
  if (item.type !== "page" && item.type !== "end-ellipsis" && item.type !== "start-ellipsis") {
    return true;
  }
  if (item.page === selectedPage || item.page === 1 || item.page === pageCountTotal) {
    return true;
  }
  if (item.page !== null && item.page >= selectedPage - 2 && item.page <= selectedPage + 2) {
    return true;
  }
  if (item.page !== null && item.page > 30) {
    return false;
  }
  if (item.page !== null && item.page % 10 === 0) {
    return true;
  }
  return false;
}
