import useSWR from "swr";
import { productDataType } from "../../../types/store-types";
import shippingInfoService from "../../../_foundation/apis/transaction/shippingInfo.service";
import stockService from "../../../_foundation/apis/transaction/stock.service";
import { swrOptionsNoRetry } from "./constants";

export const useShippingDate = (zipcode: string, skus: productDataType) => {
  let products = {};
  skus?.forEach((sku) => {
    products = { ...products, ...{ [`${sku.sku}`]: String(sku.quantity) } };
  });
  const payload = {
    widget: "useShippingDate",
    skipErrorSnackbar: true,
    body: { zipCode: zipcode, country: "FR", products },
  };

  return useSWR(zipcode && zipcode.length === 5 && skus ? payload : null, stockService.deliveryDate, swrOptionsNoRetry);
};

export const useShippingPrice = (payloadBase: any, orderId: string, skus?: productDataType) => {
  const payload = {
    widget: "useShippingPrice",
    ...payloadBase,
    body: { orderId, skus: skus },
  };

  return useSWR(orderId ? payload : null, shippingInfoService.getShippingPrice, swrOptionsNoRetry);
};
