import React from "react";
import styled from "@mui/styled-engine-sc";
import RadioGroup from "@mui/material/RadioGroup";

const ComponentWrapper = React.forwardRef((props: any, ref: any) => {
  return <RadioGroup {...props} ref={ref} />;
});

const StyledRadioGroup = styled(ComponentWrapper)`
  ${({ theme }) => `
    gap: ${theme.spacing(15)};

    ${theme.breakpoints.down("sm")} {
      gap: ${theme.spacing(10)};
    }

    &.no-gap {
      gap: 0;
    }
  `}
`;

export { StyledRadioGroup };
