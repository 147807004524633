//Standard libraries
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
//UI
import { StyledGrid } from "@hcl-commerce-store-sdk/react-component";
//custom
import MenubarMenuItem from "./ExpandedMenubarMenuItem";
import { StyledButton, StyledBox } from "../../styled-mui";
import { SvgContactUs } from "../svg/contactUs";
import { Link } from "react-router-dom";
import { JAIUNPROJET, PRISEDERDV } from "../../constants/routes";
import { useSelector } from "react-redux";
import { GetStaticLinksSelector } from "../../redux/selectors/category";
import { SvgIconProject } from "../svg/iconProject";
import { useTranslation } from "react-i18next";
import AsyncCall from "../../_foundation/gtm/async.service";
import { CLICK } from "../../_foundation/constants/gtm";

interface ExpandedMenuProps {
  topCategories?: any;
}

/**
 * ExpandedMenu component
 * expanded menu for desktop/tablet view
 * @param props
 */
const ExpandedMenu: React.FC<ExpandedMenuProps> = ({ topCategories }) => {
  const location: any = useLocation();
  const { t } = useTranslation();
  const [headerLinks, setHeaderLinks] = useState<any[]>([]);
  const staticLinksRedux: any = useSelector(GetStaticLinksSelector);
  const [selectedMenuItem, setSelectedMenuItem] = useState<any | null>(null);
  const categories = topCategories ? [...topCategories] : [];
  const selectMenuItem = (cid: string) => {
    setSelectedMenuItem(categories.filter((c) => c.id === cid)[0] || null);
  };

  useEffect(() => {
    setSelectedMenuItem(null);
  }, [location]);

  useEffect(() => {
    if (staticLinksRedux && staticLinksRedux?.headerLinks) {
      setHeaderLinks(staticLinksRedux?.headerLinks || []);
    }
  }, [staticLinksRedux]);

  return (
    <StyledBox className="expanded-menu-container">
      <StyledGrid container alignItems="center" overflow="hidden" justifyContent="space-between">
        <StyledGrid container item xs>
          <StyledBox
            component="nav"
            display="flex"
            direction="row"
            flexWrap="nowrap"
            justifyContent="flex-start"
            alignContent="flex-start"
            sx={{ flexBasis: { md: "inherit", lg: "auto" } }}
            //flexBasis="min-content"
            ml={11}>
            <MenubarMenuItem
              key={"products"}
              isProduct={true}
              selectedMenuItem={selectedMenuItem}
              selectMenuItem={selectMenuItem}
              categories={categories}></MenubarMenuItem>
            {headerLinks.map((item, index) => (
              <MenubarMenuItem key={index} isProduct={false} link={item}></MenubarMenuItem>
            ))}
          </StyledBox>
        </StyledGrid>
        <StyledGrid item>
          <StyledButton
            to={PRISEDERDV}
            onClick={()=>{
              AsyncCall.sendPageClickCtaEvent({event:CLICK.CONTACT, btn_label:'a votre ecoute'})
            }}
            component={Link}
            startIcon={<SvgContactUs />}
            size="small"
            color="secondary"
            className="right-margin-2">
            {t("Header.Actions.AtYourService")}
          </StyledButton>
          <StyledButton
            component={Link}
            to={JAIUNPROJET}
            onClick={()=>{
              AsyncCall.sendPageClickCtaEvent({event:CLICK.PROJECT, btn_label:'mon projet'})
            }}
            startIcon={<SvgIconProject />}
            size="small"
            color="secondary"
            className="right-margin-8">
            {t("Header.Actions.MyProject")}
          </StyledButton>
        </StyledGrid>
      </StyledGrid>
    </StyledBox>
  );
};

export default ExpandedMenu;
