/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React, { useState, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMatch, useNavigate } from "react-router-dom";
import getDisplayName from "react-display-name";
//Foundation libraries
import { useSite } from "../../../_foundation/hooks/useSite";
import Axios, { Canceler } from "axios";
import { useDispatch, useSelector } from "react-redux";
//Custom libraries
import { ACCOUNT, ADDRESS_BOOK, ORDER_HISTORY } from "../../../constants/routes";
import {
  forUserIdSelector,
  // userAttributesSelector,
  userIdCRMSelector,
  userIdSelector,
} from "../../../redux/selectors/user";
import personService from "../../../_foundation/apis/transaction/person.service";
import { IBM_ASSIGNED_ROLE_DETAILS } from "../../../constants/common";
//UI
import { StyledList } from "@hcl-commerce-store-sdk/react-component";
import { StyledSidebar } from "../../StyledUI/StyledSidebar";
import { StyledAccountSidebar } from "./styled-accountSidebar";
import { Collapse, Divider } from "@mui/material";
import { StyledListItemText, StyledListItemIcon, StyledListItemButton, StyledChip } from "../../../styled-mui";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
//import { SvgIconProject } from "../../svg/iconProject";
// import { SvgLocationOnOutlined } from "../../svg/locationOnOutlined";
import { SvgMyAccount } from "../../svg/myAccount";
import { SvgIconAddress } from "../../svg/iconAddress";
import { SvgIconPreference } from "../../svg/iconPreference";
import { SvgIconDevis } from "../../svg/iconDevis";
import { SvgIconOrder } from "../../svg/iconOrder";
import { SvgIconQuestion } from "../../svg/iconQuestion";
import { SvgIconPhone } from "../../svg/iconPhone";
import { SvgIconMessage } from "../../svg/iconMessage";
import { SvgIconLogout } from "../../svg/iconLogout";
import { ACCOUNT_URLS } from "../../pages/account/constants";
import { useStoreLocatorValue } from "../../../_foundation/context/store-locator-context";
import * as userAction from "../../../redux/actions/user";
import { STORELOCATORACTIONS } from "../../../_foundation/constants/common";
import { useCustomerQuotes } from "../../../utils/hooks/swr/useCustomerQuotes";
import { useCustomerOrders } from "../../../utils/hooks/swr/useCustomerOrders";
import { SvgIconProject } from "../../svg/iconProject";
import { site } from "../../../_foundation/constants/site";
import AsyncCall from "../../../_foundation/gtm/async.service";
import { CLICK } from "../../../_foundation/constants/gtm";
import { DOCUMENT_TYPE } from "../../../constants/order";

interface CustomAccountSidebarProps {
  sectionsArray: any[];
  onClose?: () => void;
}

function CustomAccountSidebar(props: CustomAccountSidebarProps) {
  const widgetName = getDisplayName(CustomAccountSidebar);
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { mySite } = useSite();
  const match = useMatch("/account/:accountPageId");
  const matchIndex = match?.params?.accountPageId;

  const dispatch = useDispatch();
  const storeLocatorDispach = useStoreLocatorValue().dispatch;
  const idCRM = useSelector(userIdCRMSelector);
  // const { userStoresIds } = useSelector(userAttributesSelector);
  // const storesNumber = userStoresIds.length || 0;
  const controller = useMemo(() => new AbortController(), []);
  const payloadBase: any = {
    widget: widgetName,
    signal: controller.signal,
  };

  const { data: dataQuotes } = useCustomerQuotes(payloadBase);
  const { data: dataOrders } = useCustomerOrders(mySite.storeID, idCRM, DOCUMENT_TYPE.Commande);

  const [open, setOpen] = useState(true);
  const [open2, setOpen2] = useState(true);
  const [open3, setOpen3] = useState(true);

  //const [closeMenuMobile, setCloseMenuMobile] = useState(false);
  const { onClose } = props;

  const handleListItemClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, link: any) => {
    navigate(`/account/${link}`);
    onClose && onClose();
    //setCloseMenuMobile(true);
  };

  const logOutUser = () => {
    const payload = {
      ...payloadBase,
    };
    if (onClose) {
      onClose();
    }
    dispatch(userAction.LOGOUT_REQUESTED_ACTION(payload));
    storeLocatorDispach({ type: STORELOCATORACTIONS.RESET_STORE_SELECTOR }); // TODO
  };

  useEffect(() => {
    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <StyledList component="nav" aria-labelledby="">
        {site.activeProject === "true" && (
          <>
            <StyledListItemButton
              selected={matchIndex?.startsWith(ACCOUNT_URLS.MY_PROJECTS.KEY)}
              onClick={(event) => handleListItemClick(event, ACCOUNT_URLS.MY_PROJECTS.KEY)}>
              <StyledListItemIcon>
                <SvgIconProject />
              </StyledListItemIcon>
              <StyledListItemText
                primary={ACCOUNT_URLS.MY_PROJECTS.LABEL}
                primaryTypographyProps={{
                  typography: "body2",
                }}
              />
              <StyledChip label="2" className="secondary-extralight small" />
            </StyledListItemButton>
            <Divider sx={{ mx: 6, my: 5, borderColor: "gray.neutralGray200" }} />
          </>
        )}
        {/* <StyledListItemButton
          selected={matchIndex === ACCOUNT_URLS.MY_FAVORITES_STORES.KEY}
          onClick={(event) => handleListItemClick(event, ACCOUNT_URLS.MY_FAVORITES_STORES.KEY)}>
          <StyledListItemIcon>
            <SvgLocationOnOutlined className="small" />
          </StyledListItemIcon>
          <StyledListItemText
            primary={ACCOUNT_URLS.MY_FAVORITES_STORES.LABEL}
            primaryTypographyProps={{
              typography: "body2",
            }}
          />
          <StyledChip label={storesNumber} className="secondary-extralight small" />
        </StyledListItemButton> */}
        {/* <Divider sx={{ mx: 6, my: 5, borderColor: "gray.neutralGray200" }} /> */}
        <StyledListItemButton className="no-hover" onClick={() => setOpen(!open)}>
          <StyledListItemText
            primary={t("AccountSidebar.Informations.Title")}
            primaryTypographyProps={{
              fontWeight: "700",
              textTransform: "uppercase",
            }}
          />
          {open ? <ExpandLess /> : <ExpandMore />}
        </StyledListItemButton>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <StyledList component="div" disablePadding>
            <StyledListItemButton
              selected={matchIndex === ACCOUNT_URLS.MY_ACCOUNT.KEY}
              onClick={(event) => handleListItemClick(event, ACCOUNT_URLS.MY_ACCOUNT.KEY)}>
              <StyledListItemIcon>
                <SvgMyAccount className="small" />
              </StyledListItemIcon>
              <StyledListItemText
                primary={ACCOUNT_URLS.MY_ACCOUNT.LABEL}
                primaryTypographyProps={{
                  typography: "body2",
                }}
              />
            </StyledListItemButton>
            <StyledListItemButton
              selected={matchIndex === ACCOUNT_URLS.MY_ADRESSES.KEY}
              onClick={(event) => handleListItemClick(event, ACCOUNT_URLS.MY_ADRESSES.KEY)}>
              <StyledListItemIcon>
                <SvgIconAddress />
              </StyledListItemIcon>
              <StyledListItemText
                primary={ACCOUNT_URLS.MY_ADRESSES.LABEL}
                primaryTypographyProps={{
                  typography: "body2",
                }}
              />
            </StyledListItemButton>
            <StyledListItemButton
              selected={matchIndex === ACCOUNT_URLS.MY_PREFERENCES.KEY}
              onClick={(event) => handleListItemClick(event, ACCOUNT_URLS.MY_PREFERENCES.KEY)}>
              <StyledListItemIcon>
                <SvgIconPreference />
              </StyledListItemIcon>
              <StyledListItemText
                primary={ACCOUNT_URLS.MY_PREFERENCES.LABEL}
                primaryTypographyProps={{
                  typography: "body2",
                }}
              />
            </StyledListItemButton>
          </StyledList>
        </Collapse>
        <Divider sx={{ mx: 6, my: 5, borderColor: "gray.neutralGray200" }} />
        <StyledListItemButton className="no-hover" onClick={() => setOpen2(!open2)}>
          <StyledListItemText
            primary={t("AccountSidebar.Documents.Title")}
            primaryTypographyProps={{
              fontWeight: "700",
              textTransform: "uppercase",
            }}
          />
          {open2 ? <ExpandLess /> : <ExpandMore />}
        </StyledListItemButton>
        <Collapse in={open2} timeout="auto" unmountOnExit>
          <StyledList component="div" disablePadding>
            <StyledListItemButton
              selected={matchIndex === ACCOUNT_URLS.MY_QUOTES.KEY}
              onClick={(event) => {
                handleListItemClick(event, ACCOUNT_URLS.MY_QUOTES.KEY);
                AsyncCall.sendClick(CLICK.CTA, { btn_label: ACCOUNT_URLS.MY_QUOTES.LABEL });
              }}>
              <StyledListItemIcon>
                <SvgIconDevis />
              </StyledListItemIcon>
              <StyledListItemText
                primary={ACCOUNT_URLS.MY_QUOTES.LABEL}
                primaryTypographyProps={{
                  typography: "body2",
                }}
              />
              {dataQuotes?.data?.doctrackList && (
                <StyledChip
                  label={dataQuotes?.data?.paginationObj?.totalElements || 0}
                  className="secondary-extralight small"
                />
              )}
            </StyledListItemButton>
            <StyledListItemButton
              selected={matchIndex === ACCOUNT_URLS.MY_ORDERS.KEY}
              onClick={(event) => handleListItemClick(event, ACCOUNT_URLS.MY_ORDERS.KEY)}>
              <StyledListItemIcon>
                <SvgIconOrder />
              </StyledListItemIcon>
              <StyledListItemText
                primary={ACCOUNT_URLS.MY_ORDERS.LABEL}
                primaryTypographyProps={{
                  typography: "body2",
                }}
                sx={{ pr: 2 }}
              />
              {dataOrders?.data?.pagination && (
                <StyledChip
                  label={dataOrders?.data?.pagination?.totalElement || 0}
                  className="secondary-extralight small"
                />
              )}
            </StyledListItemButton>
          </StyledList>
        </Collapse>
        <Divider sx={{ mx: 6, my: 5, borderColor: "gray.neutralGray200" }} />
        <StyledListItemButton className="no-hover" onClick={() => setOpen3(!open3)}>
          <StyledListItemText
            primary={t("AccountSidebar.Help.Title")}
            primaryTypographyProps={{
              fontWeight: "700",
              textTransform: "uppercase",
            }}
          />
          {open3 ? <ExpandLess /> : <ExpandMore />}
        </StyledListItemButton>
        <Collapse in={open3} timeout="auto" unmountOnExit>
          <StyledList component="div" disablePadding>
            <StyledListItemButton
              selected={matchIndex === ACCOUNT_URLS.FAQ.KEY}
              onClick={(event) => handleListItemClick(event, ACCOUNT_URLS.FAQ.KEY)}>
              <StyledListItemIcon>
                <SvgIconQuestion />
              </StyledListItemIcon>
              <StyledListItemText
                primary={ACCOUNT_URLS.FAQ.LABEL}
                primaryTypographyProps={{
                  typography: "body2",
                }}
              />
            </StyledListItemButton>
            <StyledListItemButton
              selected={matchIndex === ACCOUNT_URLS.CONTACT_US.KEY}
              onClick={(event) => {
                handleListItemClick(event, ACCOUNT_URLS.CONTACT_US.KEY);
                AsyncCall.sendClick(CLICK.HUB_CONTACT, { btn_label: ACCOUNT_URLS.CONTACT_US.LABEL });
              }}>
              <StyledListItemIcon>
                <SvgIconPhone />
              </StyledListItemIcon>
              <StyledListItemText
                primary={ACCOUNT_URLS.CONTACT_US.LABEL}
                primaryTypographyProps={{
                  typography: "body2",
                }}
              />
            </StyledListItemButton>
            <StyledListItemButton
              selected={matchIndex === ACCOUNT_URLS.SAV.KEY}
              onClick={(event) => handleListItemClick(event, ACCOUNT_URLS.SAV.KEY)}>
              <StyledListItemIcon>
                <SvgIconMessage />
              </StyledListItemIcon>
              <StyledListItemText
                primary={ACCOUNT_URLS.SAV.LABEL}
                primaryTypographyProps={{
                  typography: "body2",
                }}
              />
            </StyledListItemButton>
          </StyledList>
        </Collapse>
        <Divider sx={{ mx: 6, mt: 5, mb: 3.5, borderColor: "gray.neutralGray200" }} />
        <StyledListItemButton className="no-hover gray last" onClick={logOutUser}>
          <StyledListItemIcon>
            <SvgIconLogout />
          </StyledListItemIcon>
          <StyledListItemText
            primary={t("AccountSidebar.Disconnect.Title")}
            primaryTypographyProps={{
              typography: "body2",
            }}
          />
        </StyledListItemButton>
      </StyledList>
    </>
  );
}

interface AccountSidebarProps {
  isB2B?: boolean;
  onClose?: () => void;
}

const useSectionArray = (isB2B: boolean) => {
  const widgetName = getDisplayName(AccountSidebar);
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const CancelToken = Axios.CancelToken;
  const cancels: Canceler[] = [];

  const userId = useSelector(userIdSelector);
  const forUserId = useSelector(forUserIdSelector);

  const payloadBase: any = {
    widget: widgetName,
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c);
    }),
  };

  const [buyerRole, setBuyerRole] = useState<string[]>([]);

  const getPerson = () => {
    const param = {
      userId: forUserId ?? userId,
      profileName: IBM_ASSIGNED_ROLE_DETAILS,
      ...payloadBase,
    };
    personService
      .findByUserId(param)
      .then((response) => response.data)
      .then((data) => {
        const roleDetail = data?.rolesWithDetails;
        if (roleDetail) {
          checkBuyerAdmin(roleDetail);
        }
      })
      .catch((e) => {
        console.log("Could not retrieve role details");
      });
  };

  const checkBuyerAdmin = (roleDetail) => {
    const roles: string[] = [];
    for (const value of roleDetail) {
      roles.push(String(value.roleId));
    }
    setBuyerRole(roles);
  };

  const sectionsArray_B2C = [
    {
      title: t("MyAccount.AccountSettings"),
      pages: [
        {
          title: t("MyAccount.AddressBook"),
          link: ADDRESS_BOOK,
        },
        {
          title: t("MyAccount.OrderHistory"),
          link: ORDER_HISTORY,
        },
      ],
    },
  ];

  const formatSectionArray = () => {
    if (!isB2B) {
      return sectionsArray_B2C;
    } else {
      const array: any[] = [];
      return array;
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const sectionsArray = useMemo(() => formatSectionArray(), [buyerRole, isB2B, language]);

  React.useEffect(() => {
    if (userId || forUserId) {
      getPerson();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, forUserId]);

  React.useEffect(() => {
    return () => {
      cancels.forEach((cancel) => cancel());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { sectionsArray };
};

/**
 * AccountSidebar component
 * displays user's b2c or b2b account links in a sidebar
 * @param props
 */
function AccountSidebar(props: AccountSidebarProps) {
  const { mySite } = useSite();
  const isB2B = props.isB2B || mySite.isB2B;
  // const title = isB2B ? t("Dashboard.Title") : t("MyAccount.Title");
  const titleLink = ACCOUNT;

  const { sectionsArray } = useSectionArray(isB2B);

  const { onClose } = props;

  return (
    <StyledAccountSidebar>
      <StyledSidebar
        title={""}
        sidebarContent={<CustomAccountSidebar sectionsArray={sectionsArray} onClose={onClose} />}
        linkTo={titleLink}
        breakpoint="md"
        scrollable={true}
        className="account-menu"
      />
    </StyledAccountSidebar>
  );
}

export default AccountSidebar;
