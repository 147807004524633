import styled from "@mui/styled-engine-sc";
import Breadcrumbs from "@mui/material/Breadcrumbs";

const BreadcrumbWrapper = (props: any) => <Breadcrumbs {...props} separator={"|"} />;

export const StyledBreadcrumbs = styled(BreadcrumbWrapper)`
  ${({ theme }) => `
    padding: ${theme.spacing(6)} 0;
    font-size: ${theme.typography.caption.fontSize}px;
    font-weight: ${theme.typography.caption.fontWeight};
    line-height: ${theme.typography.caption.lineHeight};

    li {
      margin-bottom: ${theme.spacing(2)};
    }

    li > a {
      color: ${theme.palette.gray.neutralGray700};
      position: relative;
      &:after {
        content: "";
        position: absolute;
        bottom: -1px;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: #514E52;
      }
    }

    span {
      color: ${theme.palette.gray.neutralGray600};
    }
  `}
`;
