/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import { createReducer, AnyAction } from "@reduxjs/toolkit";
import { NOT_FOUND } from "http-status-codes";
//Custom libraries
import { INVENTORY_STATUS, SHIPMODE, PAYMENT } from "../../constants/order";
import { ORDER_ID, HYPHEN } from "../../constants/common";
//Foundation libraries
import { localStorageUtil } from "../../_foundation/utils/storageUtil";
import { ACCOUNT } from "../../_foundation/constants/common";
//Redux
import * as ACTIONS from "../action-types/order";
import initStates from "./initStates";
import { OrderReducerState } from "./reducerStateInterface";
import { LOGOUT_SUCCESS_ACTION } from "../actions/user";
import {
  FETCH_ORDERS_ERROR_ACTION,
  FETCH_ORDERS_SUCCESS_ACTION,
  FETCH_ALLOWABLE_SHIPMODES_SUCCESS_ACTION,
  FETCH_ALLOWABLE_PAYMETHODS_S_ACTION,
  ORDER_METHOD_SET_PICKUP_ACTION,
  ORDER_METHOD_RESET_ACTION,
  SET_PICKUP_PERSON_ACTION,
  // AVAILABILITY_IN_STORE_GET_REQUEST_ACTION,
  // AVAILABILITY_IN_STORE_GET_SUCCESS_ACTION,
  // AVAILABILITY_IN_STORE_GET_ERROR_ACTION,
  // AVAILABILITY_SHIPPING_GET_REQUEST_ACTION,
  // AVAILABILITY_SHIPPING_GET_SUCCESS_ACTION,
  // AVAILABILITY_SHIPPING_GET_ERROR_ACTION,
} from "../actions/order";

/**
 * Order reducer
 * handles states used by order related components
 * @param state State object managed by order reducer
 * @param action The dispatched action
 */

const orderReducer = createReducer(initStates.order, (builder) => {
  builder.addCase(ACTIONS.ITEM_REMOVE_REQUESTED, (state: OrderReducerState, action: AnyAction) => {
    state.isLoading = true;
  });
  builder.addCase(ACTIONS.ITEM_REMOVE_SUCCESS, (state: OrderReducerState, action: AnyAction) => {
    state.isLoading = false;
  });
  builder.addCase(ACTIONS.ITEM_REMOVE_ERROR, (state: OrderReducerState, action: AnyAction) => {
    state.isLoading = false;
  });
  builder.addCase(ACTIONS.ITEM_ADD_REQUESTED, (state: OrderReducerState, action: AnyAction) => {
    state.isLoading = true;
  });
  builder.addCase(ACTIONS.ITEM_ADD_SUCCESS, (state: OrderReducerState, action: AnyAction) => {
    if (action?.payload) {
      const itemsBeingAdded: number = parseInt(
        Array.isArray(action.payload.quantity) ? action.payload.quantity[0] : action.payload.quantity
      );
      state.itemsAdded = itemsBeingAdded;
    }
    //state.isLoading = false;
    state.cartModalOpen = true;
    state.cartItemAdded = action.response?.orderItem || [];
  });
  builder.addCase(ACTIONS.CART_CLOSE_MODAL_ACTION, (state: OrderReducerState, action: AnyAction) => {
    state.cartModalOpen = false;
    state.isLoading = false;
    state.cartItemAdded = [];
    state.itemsAdded = 0;
  });
  builder.addCase(ACTIONS.ITEM_ADD_ERROR, (state: OrderReducerState, action: AnyAction) => {
    state.isLoading = false;
  });
  builder.addCase(ACTIONS.CART_FETCHING_REQUESTED, (state: OrderReducerState, action: AnyAction) => {
    state.isFetching = true;
  });
  builder.addCase(ACTIONS.BILLING_ADDRESS_UPDATE_REQUESTED, (state: OrderReducerState, action: AnyAction) => {
    state.billingAddress = action.payload;
  });
  builder.addCase(ACTIONS.ORDER_SOFINCO_SIMULATION_SUCCESS, (state: OrderReducerState, action: AnyAction) => {
    const response = action.response;
    if (response) {
      if (response.mode === SHIPMODE.shipModeCode.DELIVERY) {
        state.simulationSofinco = { ...state.simulationSofinco, delivery: action.response };
      } else {
        state.simulationSofinco = { ...state.simulationSofinco, pickup: action.response };
      }
    }
  });
  builder.addCase(ACTIONS.CART_GET_SUCCESS, (state: OrderReducerState, action: AnyAction) => {
    const response = action.response;
    if (response) {
      const { orderItem: orderItems, ...cart } = response;
      const newCatentries = action.catentries;
      const checkInventory = action.checkInventory;

      state.cart = cart;

      let count = 0;
      if (orderItems) {
        count = orderItems.reduce((c: number, item: any) => +item.quantity + c, 0);
      }
      state.numItems = count;

      let newOrderItems: any[] = [];
      let disableRecurringOrder = false;
      let disableCheckout = false;
      if (orderItems && orderItems.length > 0) {
        let catentries = state.catentries;

        if (newCatentries !== undefined) {
          catentries = newCatentries;
          state.catentries = newCatentries;
        }

        newOrderItems = [];
        orderItems.forEach((item: any, index: number) => {
          if (checkInventory) {
            if (
              item.orderItemInventoryStatus !== INVENTORY_STATUS.available &&
              item.orderItemInventoryStatus !== INVENTORY_STATUS.allocated
            ) {
              disableCheckout = true;
            }
          }
          const obj = {
            ...item,
          };

          const catentryId = item.productId;
          if (catentries != null) {
            const catentry = catentries[catentryId];
            if (catentry !== undefined) {
              const { seller, sellerId, UserData } = catentry;

              if (catentry.buyable !== undefined) {
                obj["buyable"] = catentry.buyable;
              }
              if (catentry.name !== undefined) {
                obj["name"] = catentry.name;
              }
              if (catentry.thumbnail !== undefined) {
                obj["thumbnail"] = catentry.thumbnail;
              }
              if (catentry.thumbnailRaw !== undefined) {
                obj["thumbnailRaw"] = catentry.thumbnailRaw;
              }
              if (catentry.attributes !== undefined) {
                obj["attributes"] = catentry.attributes;
              }
              if (catentry.price !== undefined) {
                obj["price"] = catentry.price;
              }
              if (catentry.seo !== undefined) {
                obj["seo"] = catentry.seo;
              }
              if (catentry.disallowRecurringOrder !== undefined) {
                obj["disallowRecurringOrder"] = catentry.disallowRecurringOrder;
                if (catentry.disallowRecurringOrder === "1") {
                  disableRecurringOrder = true;
                }
              }
              if (catentry.parentCatalogGroupID !== undefined) {
                obj["parentCatalogGroupID"] = catentry.parentCatalogGroupID;
              }
              if (catentry.fpcId !== undefined) {
                obj["fpcId"] = catentry.fpcId;
              }
              if (catentry.parentAttributes !== undefined) {
                obj["parentAttributes"] = catentry.parentAttributes;
              }
              if (catentry.boxPrice !== undefined) {
                obj["boxPrice"] = catentry.boxPrice;
              }
              Object.assign(obj, { seller, sellerId, UserData });
            }
          }
          newOrderItems.push(obj);
        });

        state.isCheckoutDisabled = disableCheckout;
        state.isRecurringOrderDisabled = disableRecurringOrder;
      }
      state.orderItems = newOrderItems;
    }
    if (state.isRecurringOrderDisabled && state.cart && state.cart.orderId) {
      if (localStorageUtil.get(ACCOUNT + HYPHEN + ORDER_ID + HYPHEN + state.cart.orderId)) {
        const recurringOrderInfo: any[] = [false, "0", null];
        localStorageUtil.set(ACCOUNT + HYPHEN + ORDER_ID + HYPHEN + state.cart.orderId, recurringOrderInfo);
      }
    }
    state.isFetching = false;
  });

  builder.addCase(ACTIONS.CART_GET_ERROR, (state: OrderReducerState, action: AnyAction) => {
    if (
      action.error &&
      action.error.response &&
      action.error.response.status &&
      action.error.response.status === NOT_FOUND
    ) {
      state.cart = null;
      state.numItems = 0;
      state.orderItems = [];
    }
    if (!(action.error instanceof TypeError)) {
      /* fetchCart is being called on new legal page tabs and payment page causing errors */
      state.isCheckoutDisabled = true;
      state.isFetching = false;
    }
  });

  builder.addCase(ACTIONS.SHIPINFO_GET_SUCCESS, (state: OrderReducerState, action: AnyAction) => {
    state.shipInfos = action.response;
  });

  builder.addCase(ACTIONS.SHIPMODES_GET_SUCCESS, (state: OrderReducerState, action: AnyAction) => {
    const response = action.response;
    if (response && response.usableShippingMode) {
      state.shipModes = response.usableShippingMode.filter((s) => s.shipModeCode !== SHIPMODE.shipModeCode.PICKUP);
    }
  });
  builder.addCase(ACTIONS.SHIPPINGDATE_GET_SUCCESS, (state: OrderReducerState, action: AnyAction) => {
    const response = action.response;
    state.shipInfos = { ...state.shipInfos, ...response };
  });

  builder.addCase(ACTIONS.PAYMETHODS_GET_SUCCESS, (state: OrderReducerState, action: AnyAction) => {
    const response = action.response;
    if (response && response.usablePaymentInformation) {
      const cardsList: any[] = [];
      for (const payment of response.usablePaymentInformation) {
        if (
          payment.paymentMethodName === PAYMENT.paymentMethodName.ingenico ||
          payment.paymentMethodName === PAYMENT.paymentMethodName.testPunchout ||
          payment.paymentMethodName === PAYMENT.paymentMethodName.worldine
        ) {
          cardsList.push(payment);
        }
      }
      if (cardsList.length > 0) {
        state.payMethods = cardsList;
      }
    }
  });
  builder.addCase(LOGOUT_SUCCESS_ACTION, resetCart);
  builder.addCase(ACTIONS.CART_RESET_REQUESTED, resetCart);
  builder.addCase(FETCH_ORDERS_SUCCESS_ACTION, (state: OrderReducerState, action: AnyAction) => {
    state.listOfOrders = action.response || [];
  });
  builder.addCase(FETCH_ORDERS_ERROR_ACTION, (state: OrderReducerState, action: AnyAction) => {
    state.listOfOrders = [];
  });
  builder.addCase(ACTIONS.ITEM_UPDATE_REQUESTED, (state: OrderReducerState, action: AnyAction) => {
    state.isFetching = true;
  });
  builder.addCase(FETCH_ALLOWABLE_SHIPMODES_SUCCESS_ACTION, (state: OrderReducerState, action: AnyAction) => {
    state.allowableShipModes = action.payload.modes ?? [];
  });
  builder.addCase(ACTIONS.SET_ACTIVE_INPROGRESS_ORDER, (state: OrderReducerState, action: AnyAction) => {
    state.activeInprogressOrder = action.payload.order;
  });
  builder.addCase(ACTIONS.RESET_ACTIVE_INPROGRESS_ORDER, (state: OrderReducerState, action: AnyAction) => {
    state.activeInprogressOrder = null;
  });
  builder.addCase(ACTIONS.FETCH_ACTIVE_INPROGRESS_ORDER_ITEM_SUCCESS, (state: OrderReducerState, action: AnyAction) => {
    state.cart = action.payload.orderDetails;
    const checkInventory = action.payload.checkInventory;
    const newCatentries = action.payload.catentries;
    const orderItems = action.payload.orderDetails.orderItem;
    let count = 0;
    if (orderItems) {
      count = orderItems.reduce((c: number, item: any) => +item.quantity + c, 0);
    }
    state.numItems = count;

    let newOrderItems: any[] = [];
    let disableRecurringOrder = false;
    let disableCheckout = false;
    if (orderItems && orderItems.length > 0) {
      let catentries = state.catentries;

      if (newCatentries !== undefined) {
        catentries = newCatentries;
        state.catentries = newCatentries;
      }

      newOrderItems = [];
      orderItems.forEach((item: any, index: number) => {
        if (checkInventory) {
          if (
            item.orderItemInventoryStatus !== INVENTORY_STATUS.available &&
            item.orderItemInventoryStatus !== INVENTORY_STATUS.allocated
          ) {
            disableCheckout = true;
          }
        }
        const obj = {
          ...item,
        };
        const catentryId = item.productId;
        if (catentries != null) {
          const catentry = catentries[catentryId];
          if (catentry !== undefined) {
            const { seller, sellerId } = catentry;

            if (catentry.name !== undefined) {
              obj["name"] = catentry.name;
            }
            if (catentry.thumbnail !== undefined) {
              obj["thumbnail"] = catentry.thumbnail;
            }
            if (catentry.thumbnailRaw !== undefined) {
              obj["thumbnailRaw"] = catentry.thumbnailRaw;
            }
            if (catentry.attributes !== undefined) {
              obj["attributes"] = catentry.attributes;
            }
            if (catentry.seo !== undefined) {
              obj["seo"] = catentry.seo;
            }
            if (catentry.disallowRecurringOrder !== undefined) {
              obj["disallowRecurringOrder"] = catentry.disallowRecurringOrder;
              if (catentry.disallowRecurringOrder === "1") {
                disableRecurringOrder = true;
              }
            }
            if (catentry.parentCatalogGroupID !== undefined) {
              obj["parentCatalogGroupID"] = catentry.parentCatalogGroupID;
            }

            if (catentry.boxPrice !== undefined) {
              obj["boxPrice"] = catentry.boxPrice;
            }

            Object.assign(obj, { seller, sellerId });
          }
        }
        newOrderItems.push(obj);
      });

      state.isCheckoutDisabled = disableCheckout;
      state.isRecurringOrderDisabled = disableRecurringOrder;
    }
    state.orderItems = newOrderItems;
    state.isFetching = false;
  });
  builder.addCase(ACTIONS.FETCH_ACTIVE_INPROGRESS_ORDER_ITEM_ERROR, (state: OrderReducerState, action: AnyAction) => {
    state.activeInprogressOrder = null;
    if (
      action.error &&
      action.error.response &&
      action.error.response.status &&
      action.error.response.status === NOT_FOUND
    ) {
      state.cart = null;
      state.numItems = 0;
      state.orderItems = [];
    }
    state.isCheckoutDisabled = true;
    state.isFetching = false;
  });

  builder.addCase(FETCH_ALLOWABLE_PAYMETHODS_S_ACTION, (state: OrderReducerState, action: AnyAction) => {
    const m = action.payload.methods ?? [];
    state.allowablePaymethods = m.filter(({ policyName: p }) => PAYMENT.policies[p]);
  });

  builder.addCase(ORDER_METHOD_SET_PICKUP_ACTION, (state: OrderReducerState, action: AnyAction) => {
    state.orderMethodIsPickup = true;
    state.orderMethod = {
      mode: SHIPMODE.shipModeCode.PICKUP,
      physicalStoreId: action.payload.physicalStoreId,
    };
  });
  builder.addCase(ORDER_METHOD_RESET_ACTION, (state: OrderReducerState, action: AnyAction) => {
    state.orderMethodIsPickup = false;
    state.orderMethod = {
      mode: SHIPMODE.shipModeCode.DELIVERY,
      addressId: action.payload.addressId,
    };
  });
  builder.addCase(SET_PICKUP_PERSON_ACTION, (state: OrderReducerState, action: AnyAction) => {
    state.pickupPerson = action.payload;
  });
  // builder.addCase(AVAILABILITY_IN_STORE_GET_REQUEST_ACTION, (state: OrderReducerState, action: AnyAction) => {
  //   state.availabilityInStoreLoading = true;
  //   state.availabilityInStoreError = false;
  // });
  // builder.addCase(AVAILABILITY_IN_STORE_GET_SUCCESS_ACTION, (state: OrderReducerState, action: AnyAction) => {
  //   state.availabilityInStoreLoading = false;
  //   state.availabilityInStore = action.payload;
  //   state.availabilityInStoreError = false;
  // });
  // builder.addCase(AVAILABILITY_IN_STORE_GET_ERROR_ACTION, (state: OrderReducerState, action: AnyAction) => {
  //   state.availabilityInStoreLoading = false;
  //   state.availabilityInStoreError = true;
  // });

  // builder.addCase(AVAILABILITY_SHIPPING_GET_REQUEST_ACTION, (state: OrderReducerState, action: AnyAction) => {
  //   state.availabilityShippingLoading = true;
  //   state.availabilityShippingError = false;
  // });
  // builder.addCase(AVAILABILITY_SHIPPING_GET_SUCCESS_ACTION, (state: OrderReducerState, action: AnyAction) => {
  //   state.availabilityShippingLoading = false;
  //   state.availabilityShipping = action.payload;
  //   state.availabilityShippingError = false;
  // });
  // builder.addCase(AVAILABILITY_SHIPPING_GET_ERROR_ACTION, (state: OrderReducerState, action: AnyAction) => {
  //   state.availabilityShippingLoading = false;
  //   state.availabilityShippingError = true;
  // });
});

function resetCart(state: OrderReducerState, action: AnyAction) {
  state.cart = null;
  resetCartInfo(state, action);
}

function resetCartInfo(state: OrderReducerState, action: AnyAction) {
  state.numItems = 0;
  state.orderItems = [];
  state.catentries = null;
  state.isCheckoutDisabled = false;
  state.shipInfos = null;
  state.shipModes = [];
  state.payMethods = [];
  state.isRecurringOrderDisabled = false;
  state.isFetching = false;
  state.activeInprogressOrder = null;
  state.listOfOrders = [];
  state.orderMethod = null;
  state.allowableShipModes = [];
  state.allowablePaymethods = [];
  state.availabilityInStoreLoading = false;
  state.availabilityInStore = null;
  state.availabilityShippingLoading = false;
  state.availabilityShipping = null;
}

export default orderReducer;
