/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */

//Standard libraries
import { AxiosPromise } from "axios";
//Foundation libraries
import { getSite } from "../../hooks/useSite";
import { site } from "../../constants/site";
import { SubscriptionApi } from "@hcl-commerce-store-sdk/typescript-axios-transaction";

const subscriptionApiInstance = new SubscriptionApi(undefined, site.transactionContext);
const newsletterService = {
  /**
   * Update a subscription for a customer
   * @summary Update a subscription
   * @param {string} storeId The store identifier.
   * @param {SubscriptionRequest} subscriptionRequest The request object for project creation.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  subscription(parameters: any): AxiosPromise<any> {
    const siteInfo = getSite();
    const { storeId = siteInfo?.storeID, body, ...options } = parameters;
    return subscriptionApiInstance.subscription(storeId, body, options);
  },
};

export default newsletterService;
