import styled from "@mui/styled-engine-sc";
import { palette } from "../../../themes/color-palette";

export const StyledFormattedPriceDisplay = styled("div")`
  ${({ theme }) => `
    .product-price {
      /* &.MuiTypography-h1 {
        line-height: 30px;
      } */
      &.red {
        color: ${palette.primary.main};
      }
      sup {
        font-size: 17px;
        line-height: 17px;
        ${theme.breakpoints.down("sm")} {
          font-size: 15px;
          line-height: 15px;
        }
      }

      &.MuiTypography-h2 sup {
        font-size: 15px;
        line-height: 15px;
      }

      &.MuiTypography-h3 sup {
        font-size: 15px;
        line-height: 15px;
      }

      &.MuiTypography-h6 sup {
        font-size: 12px;
        line-height: 14px;
      }

      &.MuiTypography-subtitle1 {
        line-height: 16px;
        sup {
          font-size: 12px;
          line-height: 12px;
        }
      }
      // &.MuiTypography-subtitle1 sup {
      //   font-size: 10px;
      // }
      &.MuiTypography-body1 sup {
        font-size: 8px;
      }
    }

    .product-price-promo {
      display:flex;
      align-items: center;
      min-height: 20px;
      //justify-content: flex-end;

      .line-through {
        text-decoration: line-through;
      }

      .product-price-promo-reduc {
        background: ${palette.primary.main};
        border-radius: 4px;
        color: ${palette.white.main};
      }

    }
    .product-price-sup {
      font-size: 14px;
    }
  `}
`;
