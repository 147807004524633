/* eslint-disable no-useless-computed-key */
import useSWR from "swr";
import { productDataType } from "../../../types/store-types";
import inventoryavailabilityService from "../../../_foundation/apis/transaction/inventoryavailability.service";
import { useSite } from "../../../_foundation/hooks/useSite";
import { swrOptionsNoRetry } from "./constants";

export const useAvailabilitiesInStore = (stores: number[], skus: productDataType) => {
  const { mySite } = useSite();
  let products = {};
  skus?.forEach((sku) => {
    products = { ...products, ...{ [`${sku.sku}`]: sku.quantity } };
  });
  const payload = {
    widget: "useAvailabilitiesInStore",
    body: {
      ["shopIds[]"]: stores,
      products,
      productStoreId: mySite.catalogStoreID,
    },
  };

  return useSWR(
    stores && skus ? payload : null,
    inventoryavailabilityService.getInventoryAvailability,
    swrOptionsNoRetry
  );
};
