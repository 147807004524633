import styled from "@mui/styled-engine-sc";
import { palette } from "../../../themes/color-palette";

export const StyledStoreLocatorWidget = styled("div")`
  ${({ theme }) => `

    .store-locator-tabs-container {
      margin-bottom: ${theme.spacing(4)};
      &:after {
        content: "";
        width: 100%;
        display: block;
        border-bottom: ${palette.gray.neutralGray300} 1px solid;
        top: -1px;
        position: relative;
      }

      .MuiTab-textColorInherit {
        font-weight: 400;
        opacity: 1;
        width: 50%;

        &.Mui-selected {
          -webkit-text-stroke-width: 1px;
        }
      }
      ${theme.breakpoints.up("sm")} {
        .MuiTabs-flexContainer {
          justify-content: center;
        }
      }

      .MuiTabs-indicator {
        height: 1px;
        z-index: 9;
      }
    }

    .MuiTabPanel-root {
      padding: 0;
    }

    .customization-panel {
      position: relative;
      top: -1px;


    }

  `}
`;
