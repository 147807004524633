import styled from "@mui/styled-engine-sc";
import { LinearProgress } from "@mui/material";
import { palette } from "../../themes/color-palette";

export const StyledLinearProgress = styled(({ ...props }) => <LinearProgress {...props} />)`
  ${({ theme }) => `
    background: ${palette.gray.neutralGray300};
    border-radius: 8px;

    &.moderate {
      .MuiLinearProgress-bar {
        background: ${palette.alert.main};
      }
    }
    &.low {
      .MuiLinearProgress-bar {
        background: ${palette.primary.main};
      }
    }
    &.strong {
      .MuiLinearProgress-bar {
        background: ${palette.success.main};
      }
    }

`}
`;
