/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Redux
import { ADDRESS_LABEL_ATTRKEY, ADDRESS_LABEL_VALUE, ID_CRM_ATTRKEY_VALUE } from "../../constants/common";
import { REGISTERED, GUEST, GENERIC } from "../../constants/user";
import { RootReducerState } from "../reducers";

const userTypeSelector = (state: RootReducerState) => {
  if (state.user.userLoggedIn) {
    return REGISTERED;
  } else if (state.user.isGuest) {
    return GUEST;
  } else {
    return GENERIC;
  }
};

const loginStatusSelector = (state: RootReducerState) => {
  return state.user.userLoggedIn || false;
};

const userLoadingSelector = (state: RootReducerState) => {
  return state.user.isLoading || false;
};

const userIdCRMSelector = (state: RootReducerState) => {
  const attribute =
    state.user.details?.attributes?.filter((a) => a[ADDRESS_LABEL_ATTRKEY] === ID_CRM_ATTRKEY_VALUE) || [];

  return attribute.length > 0 ? attribute[0][ADDRESS_LABEL_VALUE] : "";
};

const loginErrorMsgSelector = (state: RootReducerState) => {
  return state.user.userLoginErrorMsg;
};
const guestStatusSelector = (state: RootReducerState) => {
  return state.user.isGuest || false;
};
const rememberMeSelector = (state: RootReducerState) => {
  return state.user.rememberMe || false;
};
const wcTokenSelector = (state: RootReducerState) => {
  return state.user.WCToken;
};
const wcTrustedTokenSelector = (state: RootReducerState) => {
  return state.user.WCTrustedToken;
};

const logonIdSelector = (state: RootReducerState) => {
  return state.user.details?.logonId || "";
};

const userIdSelector = (state: RootReducerState) => {
  return state.user.userId;
};

const forUserIdSelector = (state: RootReducerState) => {
  return state.user.forUserId;
};

const userNameSelector = (state: RootReducerState) => {
  const firstName = state.user.details?.firstName || "";
  const lastName = state.user.details?.lastName || "";
  const zipCode = state.user.details?.zipCode || "";
  const email = state.user.details?.email1 || "";
  const city = state.user.details?.city || "";
  const country = state.user.details?.country || "";
  const registrationNewsletterStatus = state?.user?.details?.receiveSMSNotification || false;
  const personTitle = state.user.details?.personTitle || "";
  const phone1 = state.user.details?.phone1 || "";
  return { firstName, lastName, zipCode, email, registrationNewsletterStatus, city, country, personTitle, phone1 };
};

const userAttributesSelector = (state: RootReducerState) => {
  const findStoresAttribute = state.user.details?.attributes?.find((u) => u.pProfileAttrKey === "demographicField5");
  const findFavAttribute = state.user.details?.attributes?.find((u) => u.pProfileAttrKey === "demographicField7");
  const findStoresAttributeData: string = findStoresAttribute?.pProfileAttrValue || "[]";
  const userStoresIds = typeof findStoresAttributeData === "string" ? JSON.parse(findStoresAttributeData) : [];
  const userFavStoreId = findFavAttribute?.pProfileAttrValue || "";
  return { userStoresIds, userFavStoreId };
};

const userInitStatusSelector = (state: RootReducerState) => {
  return state.user.initiatedFromStorage;
};

const userLastUpdatedSelector = (state: RootReducerState) => {
  return state.user.lastUpdated;
};

const registrationStatusSelector = (state: RootReducerState) => {
  return state.user.userRegistration || false;
};

const registrationNewsletterStatusSelector = (state: RootReducerState) => {
  return state.user.userRegistrationNewsletter || false;
};

const registrationNewsletterLoadingSelector = (state: RootReducerState) => {
  return state.user.isLoadingNewsletterRegistration || false;
};

const userFacturesSelector = (state: RootReducerState) => {
  return state.user.documents.factures;
};

export {
  userIdCRMSelector,
  loginStatusSelector,
  userLoadingSelector,
  loginErrorMsgSelector,
  guestStatusSelector,
  wcTokenSelector,
  wcTrustedTokenSelector,
  logonIdSelector,
  registrationStatusSelector,
  registrationNewsletterStatusSelector,
  userNameSelector,
  userAttributesSelector,
  userIdSelector,
  userInitStatusSelector,
  userLastUpdatedSelector,
  forUserIdSelector,
  rememberMeSelector,
  userTypeSelector,
  registrationNewsletterLoadingSelector,
  userFacturesSelector,
};
