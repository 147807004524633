import { useDispatch, useSelector } from "react-redux";
import { StyledModal } from "../../../styled-mui";
import { StoreLocatorWidget } from "../store-locator-widget";
import { storeLocatorModalOpenSelector } from "../../../redux/selectors/stores";
import { STORES_CLOSE_MODAL_ACTION } from "../../../redux/actions/stores";
// import { useNavigate } from "react-router";

export const StoreLocatorModal: React.FC = () => {
  const openModal = useSelector(storeLocatorModalOpenSelector);
  const dispatch = useDispatch();
  // const navigate = useNavigate();

  const handleCloseModal = () => {
    dispatch(STORES_CLOSE_MODAL_ACTION());
    // if (redirectToCart) {
    //   navigate("/cart");
    // }
  };

  return (
    <StyledModal open={openModal} onClose={handleCloseModal} /*keepMounted*/>
      <div>
        <StoreLocatorWidget handleCloseModal={handleCloseModal} />
      </div>
    </StyledModal>
  );
};
