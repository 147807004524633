//Standard libraries
import React from "react";
import { useSelector } from "react-redux";
//Redux
import { numItemsSelector } from "../../redux/selectors/order";

//UI
import { StyledBadge, StyledLink } from "../../styled-mui";

import { SvgShoppingCart } from "../svg/shoppingCart";
import * as ROUTES from "../../constants/routes";
import AsyncCall from "../../_foundation/gtm/async.service";
import { CLICK } from "../../_foundation/constants/gtm";

const CartButton: React.FC = () => {
  const numItems = useSelector(numItemsSelector);

  return (
    <StyledLink 
      to={ROUTES.CART}
      onClick={()=>{
        AsyncCall.sendClick(CLICK.HEADER, {linkclick:ROUTES.CART})
      }}
    >
      <StyledBadge badgeContent={Math.floor(numItems)} color="primary">
        <SvgShoppingCart />
      </StyledBadge>
    </StyledLink>
  );
};

export default CartButton;
