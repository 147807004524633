import { useMemo } from "react";
import { StyledCircularProgress } from "@hcl-commerce-store-sdk/react-component";
import {
  loginStatusSelector,
  logonIdSelector,
  userAttributesSelector,
  userLoadingSelector,
} from "../../../redux/selectors/user";
import { useDispatch, useSelector } from "react-redux";
import { StyledBox, StyledButton, StyledChip, StyledTypography } from "../../../styled-mui";
import { Divider, Stack } from "@mui/material";
import { SvgIconAddress } from "../../svg/iconAddress";
import {
  isLoadingStoresListSelector,
  storeLocatorModalTypeSelector,
  storesListSelector,
} from "../../../redux/selectors/stores";
import { STORES_CLOSE_MODAL_ACTION, STORES_MAP_COORDS_SET_ACTION } from "../../../redux/actions/stores";
import { useStoreLocatorValue } from "../../../_foundation/context/store-locator-context";
import { GOOGLEMAPZOOM, STORELOCATORACTIONS } from "../../../_foundation/constants/common";
import { UPDATE_USER_DETAILS_REQUEST_ACTION } from "../../../redux/actions/user";
import getDisplayName from "react-display-name";
import { modalType } from "../../../types/store-types";
import { getExceptionalClosing, getExceptionalOpening, getStoreSchedule } from "../../../utils/storeLocatorUtils";
import formsUtil from "../../../utils/formsUtil";
// import { HANDLE_SUCCESS_MESSAGE_ACTION } from "../../../redux/actions/success";

interface StoreLocatorWidgetListProps {
  postalCodeInput: string;
}

export const StoreLocatorWidgetList: React.FC<StoreLocatorWidgetListProps> = ({ postalCodeInput }) => {
  const dispatch = useDispatch();
  const modalTypeSelected = useSelector(storeLocatorModalTypeSelector);
  const { dispatch: dispatchStoreLocatorValue, storeLocator } = useStoreLocatorValue();
  const selectedStore = storeLocator?.selectedStore || null;
  const { userStoresIds, userFavStoreId } = useSelector(userAttributesSelector);
  const logonId = useSelector(logonIdSelector);
  const isLogin = useSelector(loginStatusSelector);
  const storedStoreData = useMemo(() => {
    const storedStoreId = localStorage.getItem("HCS-LapeyreB2C-SELECTED_STORE");
    return storedStoreId ? JSON.parse(storedStoreId) : null;
  }, []);

  const stores = useSelector(storesListSelector);
  const loading = useSelector(isLoadingStoresListSelector);
  const userLoading = useSelector(userLoadingSelector);

  const handleClickPin = (store: any, index: number) => {
    dispatch(
      STORES_MAP_COORDS_SET_ACTION({ center: store.coordinates, zoom: GOOGLEMAPZOOM.ZOOMIN, storeIndex: index })
    );
  };

  const addFavStoreToUserStoresList = (store) => {
    let storesIdsArrayCopy: any[] = [];

    if (userStoresIds.length) {
      storesIdsArrayCopy = userStoresIds.map((storeItem) => storeItem);
      if (!storesIdsArrayCopy.includes(store.id)) {
        storesIdsArrayCopy.push(store.id);
      }
    } else {
      storesIdsArrayCopy.push(store.id);
    }
    return storesIdsArrayCopy;
  };

  const handleSetStore = (store: Record<string, any>) => {
    const widgetName = getDisplayName(StoreLocatorWidgetList);
    const payloadBase: any = {
      widget: widgetName,
    };
    let parameters: any = {
      ...payloadBase,
    };

    if (modalTypeSelected === modalType.MapsFav || modalTypeSelected === modalType.MapsSelectStore) {
      dispatchStoreLocatorValue({
        type: STORELOCATORACTIONS.UPDATE_STORE_SELECTION,
        payload: { selectedStore: store, postalCode: postalCodeInput },
      });

      if (logonId) {
        parameters = {
          ...parameters,
          refreshUserStores: modalTypeSelected === modalType.MapsFav ? true : false,
          body: {
            logonId,
            email1: logonId,
            x_demographicField5: JSON.stringify(addFavStoreToUserStoresList(store)),
            x_demographicField7: store.id,
          },
        };
        dispatch(UPDATE_USER_DETAILS_REQUEST_ACTION({ parameters, closeModal: true, hideSnackbar: true }));
      }
      // dispatch(
      //   HANDLE_SUCCESS_MESSAGE_ACTION({
      //     key: "success-message.SELECTED_STORE",
      //   })
      // );
      dispatch(STORES_CLOSE_MODAL_ACTION());
    }
  };
  const reorderedStores = useMemo(() => {
    if (selectedStore && stores?.length) {
      const filteredStores = stores.filter((store: any) => store.id !== selectedStore.id);
      return [selectedStore, ...filteredStores];
    }
    return stores;
  }, [stores, selectedStore]);

  return (
    <div style={{ height: "50vh" }}>
      {loading ? (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: 200 }}>
          <StyledCircularProgress />
        </div>
      ) : (
        reorderedStores?.map((store: any, index) => {
          const alreadyInUserFav: boolean = userStoresIds?.includes(store.id) || false;
          const isReferenceStore: boolean = userFavStoreId === store.id;
          const isStoredStore = !isLogin && storedStoreData?.id === store.id;
          const shouldShowReferenceStore = isLogin && storedStoreData?.id ? storedStoreData.id === store.id : isReferenceStore;

          return (
            <StyledBox
              key={store.id}
              p={4}
              mb={3}
              sx={{ border: 1, borderColor: "gray.neutralGray300", borderRadius: 1 }}>
              <Stack direction={"row"} alignItems={"center"} pb={1}>
                <StyledTypography variant="subtitle2" component="p" pr={2} sx={{ flex: 1 }}>
                  {store.name}
                  {store.distance && ` - ${store.distance}`}
                </StyledTypography>
                <div onClick={() => handleClickPin(store, index)} style={{ cursor: "pointer" }}>
                  <SvgIconAddress className="medium" />
                </div>
              </Stack>
              {shouldShowReferenceStore && (
                <StyledChip label="Magasin de référence" className="secondary-extralight small" />
              )}
              {!isLogin && isStoredStore && (
                <StyledChip label="Magasin de référence" className="secondary-extralight small" />
              )}
              <Divider sx={{ my: 3, borderColor: "gray.neutralGray300" }} />
              <StyledTypography variant="body2" component="p">
                {store.address && (
                  <>
                    {store.address}
                    <br />
                  </>
                )}
                {`${store.postalCode || ""} ${store.city}`}
                <br />
                <strong>{formsUtil.getPhoneNumberWithIndicator(store.phone)}</strong>
              </StyledTypography>
              <StyledTypography variant="body2" component="p" py={3}>
                {getStoreSchedule(store.storeSchedule)}
                {getExceptionalOpening(store.storeExceptionalOpening)}
                {getExceptionalClosing(store.storeExceptionalClosing)}
              </StyledTypography>
              {!(modalTypeSelected === modalType.MapsSelectStore && selectedStore?.id === store.id) && (
                <StyledButton
                  disabled={modalTypeSelected === modalType.MapsFav && alreadyInUserFav}
                  onClick={() => handleSetStore(store)}
                  color="black"
                  loading={userLoading}>
                  {modalTypeSelected === modalType.MapsSelectStore
                    ? "Choisir ce magasin"
                    : alreadyInUserFav
                    ? "Déjà dans vos favoris"
                    : "Choisir ce magasin"}
                </StyledButton>
              )}
            </StyledBox>
          );
        })
      )}
    </div>
  );
};
