import React, { useEffect } from "react";
import {
  StyledTypography,
  StyledBox,
  StyledLink,
  StyledCheckbox,
  StyledFormControlLabel,
  StyledSwatch,
} from "../../../styled-mui";
import { FormGroup, useMediaQuery, useTheme } from "@mui/material";
import { TYPE_CARD } from "../../../constants/catalog";
import { ProductAvis } from "../product-avis/ProductAvis";
import { ImageLayout } from "../image";
import { dimensions } from "../../../themes/variables";
import { FormattedProductPriceDisplay } from "../formatted-price-display/formatted-price-display";
import storeUtil from "../../../utils/storeUtil";
import { SvgCheckBoxOutlineBlankIcon } from "../../svg/checkBoxOutlineBlankIcon";
import { SvgCheckThick } from "../../svg/checkThick";
import { StyledProductCard } from "./styled-productCard";
import { StyledProductImageLabel, StyledProductImageTag } from "../product-image/styled-productImage";
import AsyncCall from "../../../_foundation/gtm/async.service";
import { CLICK } from "../../../_foundation/constants/gtm";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import getDisplayName from "react-display-name";
import { kebabCase } from "lodash-es";
import { GetCategoriesSelector } from "../../../redux/selectors/category";
import { useSite } from "../../../_foundation/hooks/useSite";
// import { cartSelector } from "../../../redux/selectors/order";

interface ProductCardProps {
  product: any;
  type?: TYPE_CARD;
  defaultProduct?: boolean;
  onSelect?: (action: string, product: any) => void;
  addToCart?: (partNumber: string) => void;
  categoryId?: string;
  [key: string]: any;
  showAttribute?: boolean;
  attributes?: any;
  isRecommanded?: any;
  project?: boolean;
  productPosition?: number;
}

/**
 * ProductCard component
 *
 * @param product
 * @param props
 */
const ProductCard: React.FC<ProductCardProps> = ({
  product,
  onSelect,
  attributes,
  type = TYPE_CARD.RECOMMENDATION,
  defaultProduct = false,
  showAttribute = false,
  isRecommanded,
  project,
  productPosition = 0,
}) => {
  const swatches: any[] = [];
  const productAttributes: any = product?.attributes ? product?.attributes : [];
  const [promotionalOfferPrice, setPromotionalOfferPrice] = React.useState<any>(null);
  const [pourcentPromo, setPourcentPromo] = React.useState<any>(null);
  const [displayTags, setDisplayTags] = React.useState<any[]>([]);
  const productType = storeUtil.getTypeProduct(productAttributes, product);
  const categoriesRedux = useSelector(GetCategoriesSelector);
  const location: any = useLocation();
  const componentName: any = getDisplayName(ProductCard);
  const { pathname } = location;
  const pageName: string = pathname === "/" ? "homepage" : pathname.replaceAll("/", "");
  const { mySite } = useSite();
  const itemListId = componentName + "_" + kebabCase(pageName);
  const itemListName = componentName + " " + pageName;
  const badges: any[] = storeUtil.getBadgesFpcProduct(product, mySite);

  const projectFlow = location.state?.projectFlow;
  const trackingIndex = productPosition + 1;
  const remisedPriceDetail = storeUtil.getRemisedProductDetail(product);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const isPromotion: boolean = Number(product?.["promoPercentage.numeric"]) > 0;

  // const cart = useSelector(cartSelector);
  // const categories = product?.parentCatalogGroupID
  //   ? storeUtil.getCategoryBreadcrumbs(product?.parentCatalogGroupID, categoriesRedux)
  //   : categoriesRedux;

  useEffect(() => {
    setPromotionalOfferPrice(null);
    const bestSeller =
      product && product["custom.items.attribute.bestSeller.raw"]
        ? product["custom.items.attribute.bestSeller.raw"]
        : "false";
    setDisplayTags(storeUtil.getTagsProduct(product, bestSeller));
    getPromotionDetails(product);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  const getPromotionDetails = (productInfo: Record<any, any>) => {
    if (!productInfo || !(typeof productInfo === "object")) {
      return;
    }
    let promoPercent = 0;
    let promoPrice = 0;
    if ("promotions" in productInfo) {
      promoPercent = productInfo.promotions?.promoPercentage;
      promoPrice = productInfo.promotions?.displayPrice;
    } else {
      const { promoPercentage, displayPrice } = storeUtil.getPromotionDetails(productInfo);
      promoPercent = promoPercentage;
      promoPrice = displayPrice;
    }
    if (promoPercent > 0 && promoPrice > 0) {
      setPourcentPromo(promoPercent);
      setPromotionalOfferPrice(promoPrice);
    }
  };

  const handlerChange = (event) => {
    onSelect && onSelect(event.target.checked ? "add" : "remove", product);
  };

  const finitions = storeUtil.getFinitionValuesAttributes(productAttributes);
  const colors = storeUtil.getColorAttributes(productAttributes);

  colors?.values &&
    colors?.values?.forEach((attributeValue: any, index2: number) => {
      if (
        attributeValue.image1path &&
        Array.isArray(attributeValue.image1path) &&
        attributeValue.image1path.length > 0
      ) {
        attributeValue.image1.forEach((imagePath: any, index3: number) => {
          swatches.push(
            <StyledSwatch
              className="rounded-small"
              style={{
                backgroundImage: `url("${storeUtil.getUrlPicture(imagePath)}")`,
              }}
              key={`${attributeValue.id[index3]}_${index2}_${index3}`}
              title={attributeValue.value[index3]}
              data-testid={`product-card-layout-${attributeValue.id[index3].toLowerCase()}-swatch`}
            />
          );
        });
      } else if (attributeValue.image1path?.length > 0) {
        swatches.push(
          <StyledSwatch
            className="rounded-small"
            style={{
              backgroundImage: `url("${attributeValue.image1path}")`,
            }}
            key={`${attributeValue.id}_${index2}`}
            title={attributeValue.value}
            data-testid={`product-card-layout-${attributeValue.id.toLowerCase()}-swatch`}
          />
        );
      }
    });

  const cardClickHandler = () => {
    AsyncCall.sendClickProduct(CLICK.SELECT_ITEM, {
      topCategoriesList: categoriesRedux,
      product: product,
      label: `card-${type}-${product?.name}`,
      index: trackingIndex,
      item_list_id: itemListId,
      item_list_name: itemListName,
      itemPrice: promotionalOfferPrice || product?.price?.[0]?.value,
    });
    if (isRecommanded?.recommendationId && isRecommanded?.widgetId) {
      AsyncCall.sendClick(CLICK.PRODUCT_RECOMMANDED, {
        sourceID: isRecommanded?.recommendationId,
        widgetID: isRecommanded?.widgetId,
        product_fpc: product.partNumber,
      });
    }
  };

  function getImageCardWidth() {
    if (type === TYPE_CARD.LIST) {
      if (isMobile) {
        return dimensions.cardImage.list.xs;
      } else return dimensions.cardImage.list.sm;
    }
    if (type === TYPE_CARD.RECOMMENDATION) {
      if (isMobile) {
        return "100%";
      } else return dimensions.cardImage.small;
    } else return "100%";
  }

  const formatAttributes = (attributes) => {
    // If it's an array, join it into a single string
    const attributeString = Array.isArray(attributes) ? attributes.join("") : attributes;

    // Replace commas with ', ' in the final string
    return attributeString.replace(/,/g, ", ");
  };

  return (
    <StyledProductCard>
      <StyledBox className="product-card">
        <StyledLink
          to={
            !mySite.storeCfg.userData.SEO_FE_DISABLE ||
            (mySite.storeCfg.userData.SEO_FE_DISABLE && mySite.storeCfg.userData.SEO_FE_DISABLE !== "true")
              ? `/produits${product?.seo?.href}`
              : product?.seo?.href
          }
          state={{ projectFlow: project || projectFlow ? true : false }}
          onClick={cardClickHandler}>
          <StyledBox p={{ xs: 0, sm: type === TYPE_CARD.LIST && 5 }} style={{ position: "relative" }}>
            <>
              {isPromotion ? (
                <StyledProductImageTag className="promo promo-plp-card">Promotion</StyledProductImageTag>
              ) : (
                <>
                  {displayTags && displayTags.length > 0 && (
                    <StyledProductImageTag className={`list ${displayTags[0].className} `}>
                      {displayTags[0].description}
                    </StyledProductImageTag>
                  )}
                </>
              )}
              {badges && badges.length > 0 && (
                <StyledProductImageLabel>
                  {badges.map((badge, index) => (
                    <li key={index}>
                      <ImageLayout
                        src={mySite.assetsPictoUrl + badge.IMAGE1}
                        alt={badge.VALUE}
                        width="40"
                        height="40"
                      />
                    </li>
                  ))}
                </StyledProductImageLabel>
              )}
            </>
            <ImageLayout
              src={storeUtil.getUrlPicture(product.thumbnailRaw)}
              alt={product.shortDescription}
              width={getImageCardWidth()}
              height={200}
              className={`product-card-image ${type === TYPE_CARD.RECOMMENDATION ? "max-height-148" : ""}`}
            />
          </StyledBox>

          <StyledBox
            className="product-card-content"
            p={4}
            pb={15}
            // sx={{ borderBottom: type === TYPE_CARD.RECOMMENDATION ? `${palette.gray.neutralGray300} 1px solid` : "" }}
          >
            <StyledTypography
              variant={"subtitle2"}
              component="h2"
              className="product-card-title"
              pb={1}
              sx={{ textTransform: "none" }}>
              {product.name}
            </StyledTypography>
            <ProductAvis avis={storeUtil.getAvisProduct(product)} className="bottom-padding-4" />

            {showAttribute && (
              <StyledTypography
                variant="body2"
                component="p"
                pb={4}
                sx={{ height: "36px", overflow: "hidden", textOverflow: "ellipsis", marginBottom: "8px" }}>
                {attributes && formatAttributes(storeUtil.getAttributes(attributes))}
                {!attributes && product.attributes && formatAttributes(storeUtil.getAttributesProduct(product))}
              </StyledTypography>
            )}
            {product?.["promoPriceData.numeric"] && product?.["promoPriceData.numeric"] !== "null" && (
              <FormattedProductPriceDisplay
                productPartNumber={product.partNumber}
                type={productType}
                ecopart={product?.["ecoPriceData.numeric"] && product?.["ecoPriceData.numeric"]}
                variant={"h3"}
                pourcentPromo={pourcentPromo}
                prices={product.price}
                showPriceIsNull={true}
                promotionalOfferPrice={promotionalOfferPrice}
                remisedPriceDetail={remisedPriceDetail}
              />
            )}
            {type === TYPE_CARD.LIST && (
              <StyledBox pt={3}>
                {product &&
                  product["custom.items.childSkuCount.numeric"] !== undefined &&
                  parseInt(product["custom.items.childSkuCount.numeric"]) > 1 && (
                    <StyledTypography variant="body4" component="p" pb={1}>
                      {`${parseInt(product["custom.items.childSkuCount.numeric"])} déclinaisons`}
                    </StyledTypography>
                  )}
                {finitions?.length > 0 && (
                  <StyledTypography variant="body4" component="p" pb={1}>
                    {`${finitions?.length} ${finitions?.length > 1 ? "finitions" : "finition"}`}
                  </StyledTypography>
                )}

                {swatches?.length > 1 && (
                  <StyledTypography
                    variant="body4"
                    component="p"
                    pb={1}
                    sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    {`${swatches.length} couleur${swatches.length > 1 ? "s" : ""}`}{" "}
                    {swatches.length > 0 && <>{swatches.slice(0, 3)}</>}{" "}
                    {swatches.length > 3 && <> +{swatches.length - 3}</>}
                  </StyledTypography>
                )}
              </StyledBox>
            )}
          </StyledBox>
        </StyledLink>

        {type === TYPE_CARD.INDISPENSABLE && (
          <StyledBox py={2} px={4} className="product-card-pack">
            {defaultProduct ? (
              "Votre produit"
            ) : (
              <FormGroup>
                <StyledFormControlLabel
                  control={
                    <StyledCheckbox
                      icon={<SvgCheckBoxOutlineBlankIcon className="blue-ultra-dark" />}
                      checkedIcon={<SvgCheckThick className="beige" />}
                      color="primary"
                      defaultChecked
                    />
                  }
                  disableTypography
                  label="Ajouter ce produit"
                  onChange={handlerChange}
                />
              </FormGroup>
            )}
          </StyledBox>
        )}
      </StyledBox>
    </StyledProductCard>
  );
};

export default ProductCard;
