import React from "react";
import styled from "@mui/styled-engine-sc";
import Box from "@mui/material/Box";

const ComponentWrapper = React.forwardRef((props: any, ref: any) => {
  return <Box {...props} ref={ref} />;
});

export const StyledBox = styled(ComponentWrapper)`
  ${({ theme }) => `
    &.product-card-pack  {
      position: absolute;
      bottom: 1px;
      width: calc(100% - 2px);
      left: 1px;
      right: 1px;
    }

    &.basic-border {
      border-radius: ${theme.shape.borderRadius}px;
      border: 2px solid ${theme.palette.grey[400]};
    }
    &.accordion-show-expanded {
      display: none;
    }
    &.accordion-show-summary {
      display: block;
    }
    .Mui-expanded > &{
      .accordion-show-expanded {
        display: block;
      }
      .accordion-show-summary {
        display: none;
      }
    }
    &.border-gray-300 {
      border: 1px solid ${theme.palette.gray.neutralGray300};
      border-radius: 8px;
      padding: 0 ${theme.spacing(3)} ;
      height: 34px;
      display: inline-flex;
      align-items: center;

      &:last-child {
        margin-right: 0;
      }

      &.max-height-20 {
        svg {
          max-height: 20px;
        }
      }

      &.no-margin-bottom {
        margin-bottom: 0;
      }
      svg {
        max-height: 10px;
        width: auto;

        path {
          fill: ${theme.palette.gray.neutralGray700};
        }
      }
    }

    &.horizontal-scroll {
      overflow-x: auto;
      max-width: calc(${window.innerWidth}px -${theme.spacing(3)});
    }

    .assured-price{
      .product-price{
      font-size:12px;
      }
    }
  `}
`;
