import styled from "@mui/styled-engine-sc";

/**
 * These are for horizontal scroll on categories above coupon code on PLP, max and min width configs are necessary for keen slider to function
 * Configure spacing from useKeenSlider hook
 */

export const SliderContainer = styled("ul")`
  ${({ theme }) => `
    height: 50px;
    cursor: move;
    border-bottom: ${theme.palette.grey[300]} 1px solid;
    margin-bottom: 20px;
`}
`;

export const SliderItem = styled("li")`
  ${({ theme }) => `
  height: 100%;
  display: grid;
  place-items: center;
  min-width: max-content;
  max-width: max-content;

  &:hover {
    border-bottom: ${theme.palette.primary.main} 1px solid;
  }

  & a {
    height: 100%;
    width: max-content;
    text-wrap: nowrap;
    padding: 0px 20px;
    font-weight: 400;
  }
  `}
`;
