/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
import React from "react";
import styled from "@mui/styled-engine-sc";
import Slider from "@mui/material/Slider";
import { palette } from "../../themes/color-palette";

/**
 * Styled component on @material-ui
 * @see https://mui.com/material-ui/react-slider/
 */
const StyledRangeSlider = styled(React.forwardRef((props: any, ref: any) => <Slider {...props} ref={ref} />))`
  ${({ theme }) => `
    border-radius: ${theme.spacing(5)};
    max-width: 99%;
    .MuiSlider-rail {
      background: ${palette.gray.neutralGray300};
      opacity: 1;
    }
    .MuiSlider-track {
      background: ${palette.blue.dark};
      border: 0;
    }
    .MuiSlider-thumb {
      border: 1px solid ${palette.gray.neutralGray300};
      background: ${palette.white.main};

      &:before {
        display: none;
      }

      &:hover {
        box-shadow: none;
      }
    }
    .Mui-active {
      box-shadow: none;
    }
    .MuiSlider-valueLabel {
      background: ${palette.blue.dark};
    }
  `}
`;

export { StyledRangeSlider };
