import styled from "@mui/styled-engine-sc";
// import { palette } from "../../themes/color-palette";

const Star = styled((props) => {
  return (
    <svg width="14" height="12" style={{ marginRight: "4px" }} viewBox="0 0 16 14" fill="none">
      <mask
        id={`mask0_1120_${props.rating}`}
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="1"
        y="0"
        width={props.rating}
        height="14">
        <rect x="1" width="14" height="14" fill="#5D89A2" />
      </mask>
      <g mask={`url(#mask0_1120_${props.rating})`}>
        <path
          d="M8.24376 11.0596L7.98827 10.9072L7.73241 11.059L4.30168 13.0947L4.29189 13.1005L4.28238 13.1068C4.22557 13.1441 4.17379 13.1539 4.12637 13.1493C4.02921 13.1397 3.97895 13.1151 3.94868 13.0913C3.87372 13.0322 3.83209 12.9787 3.80563 12.9201C3.78678 12.8783 3.77786 12.836 3.79549 12.7588C3.79558 12.7584 3.79566 12.7581 3.79574 12.7577L4.70451 8.90862L4.77479 8.61098L4.54181 8.41285L1.51852 5.84171C1.45229 5.78367 1.43292 5.73823 1.42492 5.7008C1.41018 5.63181 1.41617 5.57833 1.4341 5.53292C1.46631 5.45133 1.50722 5.39482 1.5534 5.35282C1.55316 5.35304 1.56423 5.34296 1.5988 5.32915C1.63069 5.31642 1.67575 5.30324 1.73742 5.29176L5.72462 4.94356L6.02335 4.91747L6.14101 4.64165L7.68691 1.01809L7.68698 1.01812L7.69027 1.01001C7.71477 0.949677 7.74558 0.918077 7.78944 0.894077C7.87739 0.845947 7.93606 0.833313 7.99583 0.833313C8.05559 0.833313 8.11426 0.845947 8.20221 0.894077C8.23412 0.911538 8.26757 0.93873 8.30772 1.02503L9.85928 4.64254L9.97723 4.91752L10.2753 4.94356L14.2733 5.2927C14.3862 5.30858 14.4352 5.34253 14.4465 5.35282C14.4927 5.39482 14.5336 5.45133 14.5658 5.53292C14.5837 5.57833 14.5897 5.63181 14.575 5.7008C14.5694 5.72687 14.5492 5.77696 14.4749 5.84726L11.4414 8.42927L11.2086 8.6274L11.2789 8.92491L12.1876 12.774C12.1877 12.7743 12.1878 12.7746 12.1879 12.775C12.2055 12.8522 12.1966 12.8946 12.1778 12.9364C12.1513 12.995 12.1097 13.0485 12.0347 13.1076C12.0044 13.1314 11.9542 13.156 11.857 13.1656C11.8157 13.1696 11.7674 13.1642 11.6907 13.1165L11.6907 13.1164L11.6828 13.1116L8.24376 11.0596Z"
          fill="#5D89A2"
          stroke="#5D89A2"
        />
      </g>
      <path
        d="M8.24376 11.0596L7.98827 10.9072L7.73241 11.059L4.30168 13.0947L4.29189 13.1005L4.28238 13.1068C4.22558 13.1441 4.17379 13.1539 4.12637 13.1493C4.02921 13.1397 3.97895 13.1151 3.94868 13.0913C3.87372 13.0322 3.83209 12.9787 3.80563 12.9201C3.78678 12.8783 3.77786 12.836 3.7955 12.7588C3.79558 12.7584 3.79566 12.7581 3.79574 12.7577L4.70451 8.90862L4.77479 8.61098L4.54181 8.41285L1.51854 5.84173C1.4523 5.78368 1.43292 5.73823 1.42492 5.7008C1.41018 5.63181 1.41617 5.57833 1.4341 5.53292C1.46631 5.45134 1.50722 5.39482 1.5534 5.35283C1.5534 5.35283 1.5534 5.35283 1.5534 5.35282C1.55367 5.35259 1.565 5.34265 1.5988 5.32915C1.63069 5.31642 1.67575 5.30324 1.73742 5.29176L5.72462 4.94356L6.02335 4.91747L6.14102 4.64165L7.68691 1.01809L7.68698 1.01812L7.69027 1.01001C7.71477 0.949676 7.74558 0.918077 7.78944 0.894077C7.87739 0.845947 7.93606 0.833313 7.99583 0.833313C8.05559 0.833313 8.11426 0.845947 8.20221 0.894077C8.23412 0.911537 8.26757 0.938728 8.30771 1.02502L9.85928 4.64254L9.97723 4.91752L10.2753 4.94356L14.2733 5.2927C14.3862 5.30858 14.4352 5.34253 14.4465 5.35282C14.4927 5.39481 14.5336 5.45133 14.5658 5.53292C14.5837 5.57833 14.5897 5.63181 14.575 5.7008C14.5694 5.72687 14.5492 5.77696 14.4749 5.84725L11.4414 8.42927L11.2086 8.6274L11.2789 8.92491L12.1876 12.774C12.1877 12.7743 12.1878 12.7746 12.1879 12.775C12.2055 12.8522 12.1966 12.8946 12.1778 12.9364C12.1513 12.995 12.1097 13.0485 12.0347 13.1076C12.0044 13.1314 11.9542 13.156 11.857 13.1656C11.8157 13.1696 11.7674 13.1642 11.6907 13.1165L11.6907 13.1164L11.6828 13.1116L8.24376 11.0596Z"
        stroke="#5D89A2"
      />
    </svg>
  );
})`
  ${({ theme }) => `
    &.big {
      width: 20px;
      height: 20px;
    }
  `}
`;

export const SvgStar = (props) => {
  const { rating = "0" } = props;

  return <Star rating={rating}></Star>;
};
