import { call, put } from "redux-saga/effects";
import { UNIVERS_GET_ERROR_ACTION, UNIVERS_GET_SUCCESS_ACTION } from "../../actions/univers";
import jsonService from "../../../_foundation/apis/staticJson/json.service";

export function* getUniversData(action: any) {
  try {
    const universData = yield call(jsonService.fetchStaticJson, { file: "univers.json", isLocal: false });
    yield put(UNIVERS_GET_SUCCESS_ACTION({ universData }));
  } catch (error) {
    yield put(UNIVERS_GET_ERROR_ACTION(error));
  }
}
