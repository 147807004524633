import styled from "@mui/styled-engine-sc";

const Visa = styled((props) => (
  <svg width="51" height="16" viewBox="0 0 51 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} />
))`
  ${({ theme }) => `

  `}
`;

export const SvgVisa = (props) => {
  const { className = "" } = props;

  return (
    <Visa {...{ className }}>
      <path
        d="M46.808.293h-2.895c-1.452 0-1.886.293-2.45 1.637l-5.43 12.975c-.108.27-.228.542-.369.878h2.244c.64 0 1.431.206 1.875-.098.434-.292.51-1.116.738-1.712.021-.055.032-.13.075-.163.163-.13.337-.358.51-.358 1.463-.021 2.916-.021 4.38.011.15 0 .39.228.433.401.152.51.217 1.052.368 1.572.044.14.239.325.369.336 1.095.022 2.19.01 3.403.01-1.094-5.213-2.157-10.33-3.24-15.5l-.011.011Zm-4.932 9.962 2.027-5.54h.13c.379 1.832.758 3.675 1.149 5.54h-3.306ZM19.026.282c-.282.694-.52 1.323-.78 1.951-1.757 4.314-3.523 8.618-5.258 12.932-.184.455-.4.64-.91.618a33.153 33.153 0 0 0-2.938 0c-.488.022-.694-.12-.813-.607A956.089 956.089 0 0 0 5.433 4.228a1.81 1.81 0 0 0-.596-.9C3.699 2.44 2.528 1.605 1.368.748 1.216.64 1.076.51.837.315h7.967l2.06 10.438c.13-.303.227-.488.292-.672 1.138-3.067 2.287-6.135 3.404-9.224.162-.445.368-.618.856-.596 1.182.043 2.352.01 3.61.01v.011ZM25.204 11.913c1.28.293 2.46.65 3.675.802.704.087 1.485-.054 2.157-.303 1.073-.401 1.192-1.529.271-2.212-.51-.379-1.095-.65-1.648-.975-.52-.304-1.051-.585-1.528-.932-2.19-1.605-2.406-4.347-.51-6.298C28.696.878 30.062.347 31.557.12c1.561-.239 3.111-.12 4.629.347.39.119.542.281.444.726-.184.9-.314 1.81-.455 2.677-.932-.206-1.821-.488-2.742-.574a5.051 5.051 0 0 0-2.05.238c-1.061.369-1.17 1.355-.26 2.005.553.39 1.171.694 1.757 1.03.368.206.737.401 1.095.618 3.36 2.06 2.276 5.962.14 7.436-1.463 1.019-3.1 1.366-4.823 1.377-1.561.01-3.111-.185-4.607-.824l.52-3.252v-.01ZM24.662.271c-.52 3.209-1.019 6.309-1.517 9.398-.304 1.854-.618 3.707-.889 5.561-.065.423-.195.575-.629.564-1.138-.033-2.276 0-3.457 0l.758-4.78C19.47 7.641 20.034 4.281 20.554.9c.076-.466.217-.64.705-.629 1.116.033 2.222.011 3.414.011l-.01-.01Z"
        fill="#fff"
      />
    </Visa>
  );
};
