import styled from "@mui/styled-engine-sc";
import SvgIcon from "@mui/material/SvgIcon";

const Instagram = styled((props) => (
  <SvgIcon width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} />
))`
  ${({ theme }) => ``}
`;

export const SvgInstagram = (props) => {
  const { className = "" } = props;

  return (
    <Instagram {...{ className }}>
      <path d="M18.8433 4.28352C18.0645 4.28352 17.4291 4.91887 17.4291 5.69769C17.4291 6.47652 18.0645 7.11187 18.8433 7.11187C19.6221 7.11187 20.2575 6.47652 20.2575 5.69769C20.2575 4.91887 19.6221 4.28352 18.8433 4.28352Z" />
      <path d="M12.5922 6.0871C9.33348 6.0871 6.68958 8.731 6.68958 11.9898C6.68958 15.2485 9.33348 17.8924 12.5922 17.8924C15.851 17.8924 18.4949 15.2485 18.4949 11.9898C18.4949 8.731 15.851 6.0871 12.5922 6.0871ZM12.5922 15.7814C10.5017 15.7814 8.8006 14.0803 8.8006 11.9898C8.8006 9.89923 10.5017 8.19812 12.5922 8.19812C14.6827 8.19812 16.3839 9.89923 16.3839 11.9898C16.3839 14.0803 14.6827 15.7814 12.5922 15.7814Z" />
      <path d="M17.2857 24H7.71435C3.73826 24 0.5 20.7617 0.5 16.7857V7.21435C0.5 3.23826 3.73826 0 7.71435 0H17.2857C21.2617 0 24.5 3.23826 24.5 7.21435V16.7857C24.5 20.7617 21.2617 24 17.2857 24ZM7.71435 2.25448C4.98847 2.25448 2.75448 4.46798 2.75448 7.21435V16.7857C2.75448 19.5115 4.96798 21.7455 7.71435 21.7455H17.2857C20.0115 21.7455 22.2455 19.532 22.2455 16.7857V7.21435C22.2455 4.48847 20.032 2.25448 17.2857 2.25448H7.71435Z" />
    </Instagram>
  );
};
