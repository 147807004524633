/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Services - Account and Contract
 * These services provide APIs to manage accounts and contracts.  a contract is an agreement that represents the terms and conditions that apply to a transaction. An account is a relationship between the merchant and the financial institution that processes transactions for that merchant.
 *
 * The version of the OpenAPI document: 9.1.6
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Commentaires } from './commentaires';

/**
 * 
 * @export
 * @interface SuiviDevis
 */
export interface SuiviDevis {
    /**
     * 
     * @type {string}
     * @memberof SuiviDevis
     */
    attributionRelance?: string;
    /**
     * 
     * @type {number}
     * @memberof SuiviDevis
     */
    budget?: number;
    /**
     * 
     * @type {Commentaires}
     * @memberof SuiviDevis
     */
    commentairesProjet?: Commentaires;
    /**
     * 
     * @type {string}
     * @memberof SuiviDevis
     */
    dateFinValiditeDevis?: string;
    /**
     * 
     * @type {string}
     * @memberof SuiviDevis
     */
    dateProjet?: string;
    /**
     * 
     * @type {string}
     * @memberof SuiviDevis
     */
    dateRelanceExigee?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SuiviDevis
     */
    devisDoublon?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SuiviDevis
     */
    propCreditFaite?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SuiviDevis
     */
    repPropCredit?: string;
    /**
     * 
     * @type {string}
     * @memberof SuiviDevis
     */
    statutTransfoDevis?: SuiviDevisStatutTransfoDevisEnum;
    /**
     * 
     * @type {string}
     * @memberof SuiviDevis
     */
    typePoseEnvisage?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum SuiviDevisStatutTransfoDevisEnum {
    Atransformer = 'ATRANSFORMER',
    Transforme = 'TRANSFORME',
    Presumetransf = 'PRESUMETRANSF',
    Perdu = 'PERDU',
    Transfere = 'TRANSFERE'
}



