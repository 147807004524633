import React from "react";
import styled from "@mui/styled-engine-sc";
import AccordionSummary from "@mui/material/AccordionSummary";

const StyledAccordionSummary = styled(({ ...props }) => <AccordionSummary {...props} />)`
  ${({ theme }) => `
    min-height: initial;
    padding: 0;
    background: transparent;

    &.Mui-expanded {
      min-height: initial;
    }

    .MuiAccordionSummary-expandIconWrapper {
      //padding: ${theme.spacing(1.5)} 0;
    }

    .MuiAccordionSummary-content {
      margin: 0;
      .MuiGrid-container{
        padding:0;
      }

      .MuiButton-sizeMedium{
        padding:11px 14px;
      }

      &.Mui-expanded {
        min-height: initial;
      }
    }
`}
`;

export { StyledAccordionSummary };
