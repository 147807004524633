import styled from "@mui/styled-engine-sc";
import SvgIcon from "@mui/material/SvgIcon";

const CustomSvg = styled((props) => (
  <SvgIcon width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} />
))`
  ${({ theme }) => `
      width:8px;
      height:6px;
      margin: 1px ${theme.spacing(1)} 0 ${theme.spacing(0)};
      color: ${theme.palette.text.success};

      &.big {
        width:10px;
        height:7px;
      }
  `}
`;

export const SvgSuccess = (props) => {
  const { className = "" } = props;

  return (
    <CustomSvg {...{ className }}>
      <path d="M3.16874 5.62206C3.00034 5.79323 2.72881 5.79256 2.56122 5.62055L0.690829 3.70092C0.431766 3.43504 0.437171 3.00227 0.70279 2.74329C0.958951 2.49353 1.3624 2.49884 1.61223 2.75526L2.86425 4.04023L6.362 0.45039C6.62233 0.183203 7.04442 0.183203 7.30475 0.45039C7.56597 0.71849 7.56495 1.15349 7.30247 1.42029L3.16874 5.62206Z" />
    </CustomSvg>
  );
};
