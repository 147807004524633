import SvgIcon from "@mui/material/SvgIcon";
import styled from "@mui/styled-engine-sc";

const CustomSvg = styled((props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} />
))`
  ${({ theme }) => `
    width:24px;
    height:24px;
  `}
`;

export const SvgMenuIcon = (props) => {
  const { className = "" } = props;

  return (
    <CustomSvg {...{ className }}>
      <path d="M4 18C3.72 18 3.48 17.9 3.29 17.71C3.1 17.52 3 17.28 3 17C3 16.72 3.1 16.48 3.29 16.29C3.48 16.1 3.72 16 4 16H14C14.28 16 14.52 16.1 14.71 16.29C14.9 16.48 15 16.72 15 17C15 17.28 14.9 17.52 14.71 17.71C14.52 17.9 14.28 18 14 18H4ZM4 13C3.72 13 3.48 12.9 3.29 12.71C3.1 12.52 3 12.28 3 12C3 11.72 3.1 11.48 3.29 11.29C3.48 11.1 3.72 11 4 11H20C20.28 11 20.52 11.1 20.71 11.29C20.9 11.48 21 11.72 21 12C21 12.28 20.9 12.52 20.71 12.71C20.52 12.9 20.28 13 20 13H4ZM4 8C3.72 8 3.48 7.9 3.29 7.71C3.1 7.52 3 7.28 3 7C3 6.72 3.1 6.48 3.29 6.29C3.48 6.1 3.72 6 4 6H20C20.28 6 20.52 6.1 20.71 6.29C20.9 6.48 21 6.72 21 7C21 7.28 20.9 7.52 20.71 7.71C20.52 7.9 20.28 8 20 8H4Z" />
    </CustomSvg>
  );
};
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M12.0049 15.25C11.8769 15.25 11.7489 15.2302 11.6307 15.1905C11.5125 15.1509 11.4042 15.0816 11.3057 14.9825L6.75604 10.3948C6.57878 10.2165 6.5 9.98857 6.5 9.72104C6.5 9.45351 6.59848 9.2157 6.78559 9.03735C6.97269 8.85899 7.18935 8.75991 7.47493 8.75991C7.76052 8.75991 7.98702 8.84909 8.16428 9.03735L12.0049 12.9017L15.8653 9.00762C16.0524 8.82927 16.2789 8.75 16.5448 8.75C16.8107 8.75 17.0372 8.84909 17.2243 9.03735C17.4114 9.22561 17.5 9.45351 17.5 9.73095C17.5 10.0084 17.4114 10.2462 17.2243 10.4245L12.6943 14.9825C12.5958 15.0816 12.4875 15.1509 12.3693 15.1905C12.2511 15.2302 12.1329 15.25 11.9951 15.25H12.0049Z"
    fill="#424242"
  />
</svg>;
