import { call, put } from "redux-saga/effects";
import { KITCHENS_GET_ERROR_ACTION, KITCHENS_GET_SUCCESS_ACTION } from "../../actions/kitchens";
import jsonService from "../../../_foundation/apis/staticJson/json.service";

export function* getKitchensData(action: any) {
  try {
    const kitchensData = yield call(jsonService.fetchStaticJson, { file: "modeles-cuisine.json", isLocal: false });
    yield put(KITCHENS_GET_SUCCESS_ACTION({ kitchensData }));
  } catch (error) {
    yield put(KITCHENS_GET_ERROR_ACTION(error));
  }
}
