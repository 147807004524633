// Stores
export const STORES_OPEN_MODAL = "STORES_OPEN_MODAL";
export const STORES_CLOSE_MODAL = "STORES_CLOSE_MODAL";

export const STORES_FAVORITE_GET_REQUEST = "STORES_FAVORITE_GET_REQUESTED";
export const STORES_FAVORITE_GET_SUCCESS = "STORES_FAVORITE_GET_SUCCESS";
export const STORES_FAVORITE_GET_ERROR = "STORES_FAVORITE_GET_ERROR";

export const STORES_LIST_GET_REQUEST = "STORES_LIST_GET_REQUESTED";
export const STORES_LIST_GET_SUCCESS = "STORES_LIST_GET_SUCCESS";
export const STORES_LIST_GET_ERROR = "STORES_LIST_GET_ERROR";

export const STORES_MAP_COORDS_SET = "STORES_MAP_COORDS_SET";

// export const STORE_UPDATE_FAV_REQUEST = "STORE_UPDATE_FAV_REQUESTED";
// export const STORE_UPDATE_FAV_SUCCESS = "STORE_UPDATE_FAV_SUCCESS";
// export const STORE_UPDATE_FAV_ERROR = "STORE_UPDATE_FAV_ERROR";

// export const STORE_DELETE_REQUEST = "STORE_DELETE_REQUESTED";
// export const STORE_DELETE_SUCCESS = "STORE_DELETE_SUCCESS";
// export const STORE_DELETE_ERROR = "STORE_DELETE_ERROR";

export const STORES_RESET = "STORES_RESET";
