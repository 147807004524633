/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Services - Account and Contract
 * These services provide APIs to manage accounts and contracts.  a contract is an agreement that represents the terms and conditions that apply to a transaction. An account is a relationship between the merchant and the financial institution that processes transactions for that merchant.
 *
 * The version of the OpenAPI document: 9.1.6
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../../base';
// @ts-ignore
import { ComLapeyreCommerceControllersCreateCheckoutRequest } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { ComLapeyreCommerceControllersCreateCheckoutResponse } from '../../../../../com/hcl/commerce/typescript/axios';
/**
 * CreateHostedCheckoutApi - axios parameter creator
 * @export
 */
export const CreateHostedCheckoutApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create hosted page on worldline according shopping cart of customer.
         * @summary createHostedCheckout
         * @param {string} storeId The store identifier.
         * @param {ComLapeyreCommerceControllersCreateCheckoutRequest} comLapeyreCommerceControllersCreateCheckoutRequest The request object for the checkout.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createHostedCheckout: async (storeId: string, comLapeyreCommerceControllersCreateCheckoutRequest: ComLapeyreCommerceControllersCreateCheckoutRequest, responseFormat?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('createHostedCheckout', 'storeId', storeId)
            // verify required parameter 'comLapeyreCommerceControllersCreateCheckoutRequest' is not null or undefined
            assertParamExists('createHostedCheckout', 'comLapeyreCommerceControllersCreateCheckoutRequest', comLapeyreCommerceControllersCreateCheckoutRequest)
            const localVarPath = `/store/{storeId}/resources/createHostedCheckout`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(comLapeyreCommerceControllersCreateCheckoutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CreateHostedCheckoutApi - functional programming interface
 * @export
 */
export const CreateHostedCheckoutApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CreateHostedCheckoutApiAxiosParamCreator(configuration)
    return {
        /**
         * Create hosted page on worldline according shopping cart of customer.
         * @summary createHostedCheckout
         * @param {string} storeId The store identifier.
         * @param {ComLapeyreCommerceControllersCreateCheckoutRequest} comLapeyreCommerceControllersCreateCheckoutRequest The request object for the checkout.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createHostedCheckout(storeId: string, comLapeyreCommerceControllersCreateCheckoutRequest: ComLapeyreCommerceControllersCreateCheckoutRequest, responseFormat?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComLapeyreCommerceControllersCreateCheckoutResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createHostedCheckout(storeId, comLapeyreCommerceControllersCreateCheckoutRequest, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CreateHostedCheckoutApi - factory interface
 * @export
 */
export const CreateHostedCheckoutApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CreateHostedCheckoutApiFp(configuration)
    return {
        /**
         * Create hosted page on worldline according shopping cart of customer.
         * @summary createHostedCheckout
         * @param {string} storeId The store identifier.
         * @param {ComLapeyreCommerceControllersCreateCheckoutRequest} comLapeyreCommerceControllersCreateCheckoutRequest The request object for the checkout.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createHostedCheckout(storeId: string, comLapeyreCommerceControllersCreateCheckoutRequest: ComLapeyreCommerceControllersCreateCheckoutRequest, responseFormat?: string, options?: any): AxiosPromise<ComLapeyreCommerceControllersCreateCheckoutResponse> {
            return localVarFp.createHostedCheckout(storeId, comLapeyreCommerceControllersCreateCheckoutRequest, responseFormat, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CreateHostedCheckoutApi - object-oriented interface
 * @export
 * @class CreateHostedCheckoutApi
 * @extends {BaseAPI}
 */
export class CreateHostedCheckoutApi extends BaseAPI {
    /**
     * Create hosted page on worldline according shopping cart of customer.
     * @summary createHostedCheckout
     * @param {string} storeId The store identifier.
     * @param {ComLapeyreCommerceControllersCreateCheckoutRequest} comLapeyreCommerceControllersCreateCheckoutRequest The request object for the checkout.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreateHostedCheckoutApi
     */
    public createHostedCheckout(storeId: string, comLapeyreCommerceControllersCreateCheckoutRequest: ComLapeyreCommerceControllersCreateCheckoutRequest, responseFormat?: string, options?: any) {
        return CreateHostedCheckoutApiFp(this.configuration).createHostedCheckout(storeId, comLapeyreCommerceControllersCreateCheckoutRequest, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }
}
