import styled from "@mui/styled-engine-sc";
import FormLabel from "@mui/material/FormLabel";
import { palette } from "../../themes/color-palette";

const StyledFormLabel = styled(({ ...props }) => <FormLabel {...props} />)`
  ${({ theme }) => `
    font-size: 10px;
    font-weight: 400;
    color: ${palette.gray.neutralGray600};

    &.bottom-margin-1 {
      margin-bottom: ${theme.spacing(1)};
    }

    &.Mui-focused {
      color: ${palette.gray.neutralGray600};
    }
  `}
`;

export { StyledFormLabel };
