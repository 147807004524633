/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import { call, put } from "redux-saga/effects";
//Foundation libraries
import urlsService from "../../../_foundation/apis/search/urls.service";
import { getSite } from "../../../_foundation/hooks/useSite";
import { HOME, MANAGED_STATIC_PAGES, PAGE_TYPE, STATIC_PAGES_TOKEN } from "../../../_foundation/constants/common";
//Redux
import { GET_SEO_CONFIG_SUCCESS_ACTION } from "../../actions/seo";
//custom
import CategoryLandingPageLayoutJson from "../../../configs/default-layout/category-landing-page.json";
import ProductPageLayoutJson from "../../../configs/default-layout/product-page.json";
import ProductListingPageLayoutJson from "../../../configs/default-layout/product-listing-page.json";
import HomePageLayoutJson from "../../../configs/default-layout/home-page.json";
import NotFound from "../../../configs/default-layout/not-found.json";
import NoLayoutContentPageJson from "../../../configs/default-layout/no-layout-content-page.json";
import BundlePageLayoutJson from "../../../configs/default-layout/bundle-page.json";
//import CheckoutPageJson from "../../../configs/default-layout/checkout-page.json";
import CartPageJson from "../../../configs/default-layout/cart-page.json";
import OrderConfirmationPageJson from "../../../configs/default-layout/order-confirmation-page.json";
import KitPageJson from "../../../configs/default-layout/kit-page.json";
import KitchenModelPageJson from "../../../configs/default-layout/kitchen-model-page.json";
import { CART, CHECKOUT, ORDER_CONFIRMATION } from "../../../constants/routes";

const layouts: any = {
  CategoryPage: ProductListingPageLayoutJson,
  UniversalPage: CategoryLandingPageLayoutJson,
  ProductPage: ProductPageLayoutJson,
  ItemPage: ProductPageLayoutJson,
  VariantPage: ProductPageLayoutJson,
  BundlePage: BundlePageLayoutJson,
  ProductListPage: ProductListingPageLayoutJson,
  HomePage: HomePageLayoutJson,
  NotFound: NotFound,
  ContentPage: NoLayoutContentPageJson,
  //CheckOutPage: CheckoutPageJson,
  CartPage: CartPageJson,
  OrderConfirmationPage: OrderConfirmationPageJson,
  KitPage: KitPageJson,
  // eslint-disable-next-line no-useless-computed-key
  ["Page Marque"]: KitPageJson,
  KitchenModelPage: KitchenModelPageJson,
};

export function* getSEO(action: any) {
  let identi = action.payload.identifier;
  identi = identi.split("/");
  const identifier = identi[identi.length - 1];
  action.payload.identifier = identifier;
  // const { identifier } = action.payload;
  let seoconfig: any = {};
  try {
    const response = yield call(urlsService.getV2URLResourcesUsingGET, {
      ...action.payload,
    });
    if (!response?.data || !response?.data?.contents || !response?.data?.contents[0]) {
      //always make sure default home page is available.
      if (identifier === HOME) {
        seoconfig = {
          page: {
            type: PAGE_TYPE.HOME_PAGE,
            name: HOME,
            title: HOME,
          },
          tokenExternalValue: PAGE_TYPE.HOME_PAGE,
        };
      } else if (`/${identifier}` === CHECKOUT) {
        seoconfig = {
          page: {
            type: PAGE_TYPE.CHECK_OUT_PAGE,
            name: "checkout",
            title: getSite()?.storeCfg.description[0]?.displayName || getSite()?.storeName,
          },
          tokenExternalValue: PAGE_TYPE.CHECK_OUT_PAGE,
        };
      } else if (`/${identifier}` === CART) {
        seoconfig = {
          page: {
            type: PAGE_TYPE.CART_PAGE,
            name: "cart",
            title: getSite()?.storeCfg.description[0]?.displayName || getSite()?.storeName,
          },
          tokenExternalValue: PAGE_TYPE.CART_PAGE,
        };
      } else if (`/${identifier}` === ORDER_CONFIRMATION) {
        seoconfig = {
          page: {
            type: PAGE_TYPE.ORDER_CONFIRMATION_PAGE,
            name: "Order confirmation",
            title: getSite()?.storeCfg.description[0]?.displayName || getSite()?.storeName,
          },
          tokenExternalValue: PAGE_TYPE.ORDER_CONFIRMATION_PAGE,
        };
      } else {
        seoconfig = {
          page: {
            type: "NotFound",
            title: getSite()?.storeCfg.description[0]?.displayName || getSite()?.storeName,
          },
        };
      }
    } else {
      const validPageTypes = Object.keys(layouts).filter((key) =>
        ["ProductListPage", "ProductPage", "CategoryPage"].includes(key)
      );
      const pageTypeKey = Object.keys(layouts).find((key) => key === response?.data?.contents[0]?.page.type);
      if (
        pageTypeKey &&
        validPageTypes.includes(pageTypeKey) &&
        (!window.location.href.includes("/produits") || /\/produits(\/produits)+/.test(window.location.href))
      ) {
        seoconfig = {
          page: {
            type: "NotFound",
            title: getSite()?.storeCfg.description[0]?.displayName || getSite()?.storeName,
          },
        };
      } else {
        seoconfig = response.data.contents[0];
      }
    }
  } catch (error: any) {
    if (error.isAxiosError && error.response && error.response.status === 404) {
      //always make sure default home page is available.
      if (identifier === HOME) {
        seoconfig = {
          page: {
            type: PAGE_TYPE.HOME_PAGE,
            name: HOME,
            title: HOME,
          },
          tokenExternalValue: PAGE_TYPE.HOME_PAGE,
        };
      } else if (`/${identifier}` === CHECKOUT) {
        seoconfig = {
          page: {
            type: PAGE_TYPE.CHECK_OUT_PAGE,
            name: "checkout",
            title: getSite()?.storeCfg.description[0]?.displayName || getSite()?.storeName,
          },
          tokenExternalValue: PAGE_TYPE.CHECK_OUT_PAGE,
        };
      } else if (`/${identifier}` === CART) {
        seoconfig = {
          page: {
            type: PAGE_TYPE.CART_PAGE,
            name: "cart",
            title: getSite()?.storeCfg.description[0]?.displayName || getSite()?.storeName,
          },
          tokenExternalValue: PAGE_TYPE.CART_PAGE,
        };
      } else if (`/${identifier}` === ORDER_CONFIRMATION) {
        seoconfig = {
          page: {
            type: PAGE_TYPE.ORDER_CONFIRMATION_PAGE,
            name: "Order confirmation",
            title: getSite()?.storeCfg.description[0]?.displayName || getSite()?.storeName,
          },
          tokenExternalValue: PAGE_TYPE.ORDER_CONFIRMATION_PAGE,
        };
      } else {
        seoconfig = {
          page: {
            type: "NotFound",
            title: getSite()?.storeCfg.description[0]?.displayName || getSite()?.storeName,
          },
        };
      }
    }
  } finally {
    const pageType = seoconfig.page?.type;
    const tokenName = seoconfig.tokenName ?? "";
    const tokenExternalValue = seoconfig.tokenExternalValue ?? "";
    if (pageType) {
      if (!seoconfig.layout || Object.keys(seoconfig.layout).length === 0) {
        if (tokenName === STATIC_PAGES_TOKEN && MANAGED_STATIC_PAGES.includes(tokenExternalValue)) {
          seoconfig.layout = layouts[tokenExternalValue].layout;
        } else {
          const L1CatIDs = getSite()?.storeCfg?.userData?.TOP_UNIVERSAL_CAT_IDS?.split(",") || [];
          const isL1Category = L1CatIDs?.find((item) => item === seoconfig?.tokenValue);
          if (isL1Category && isL1Category.length > 0) {
            seoconfig.layout = layouts["UniversalPage"].layout;
          } else {
            seoconfig.layout = layouts[pageType].layout;
          }
        }
      }
      yield put(GET_SEO_CONFIG_SUCCESS_ACTION({ identifier, seoconfig }));
    }
  }
}
