import styled from "@mui/styled-engine-sc";
import SvgIcon from "@mui/material/SvgIcon";

const CustomSvg = styled((props) => (
  <SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} />
))`
  ${({ theme }) => `
    width:16px;
    height:16px;

    &.big {
      width:24px;
      height:24px;
    }
  `}
`;

export const SvgIconQuestion = (props) => {
  const { className = "" } = props;

  return (
    <CustomSvg {...{ className }}>
      <path d="m8.594 14.666-.11-1.91-.694-.009c-1.616-.072-2.985-.658-4.107-1.765C2.56 9.875 2 8.558 2 7.034s.598-2.926 1.79-4.036c1.195-1.11 2.637-1.665 4.327-1.665.831 0 1.606.141 2.317.426a5.584 5.584 0 0 1 3.112 2.988c.304.694.454 1.446.454 2.259 0 1.53-.494 2.979-1.486 4.34-.991 1.364-2.297 2.47-3.923 3.317l.003.003Zm-.47-3.913a.735.735 0 0 0 .517-.198.634.634 0 0 0 .21-.48.634.634 0 0 0-.21-.48.73.73 0 0 0-.517-.197.735.735 0 0 0-.518.198.634.634 0 0 0-.21.48c0 .188.07.348.21.48a.73.73 0 0 0 .518.197ZM8.09 8.558a.513.513 0 0 0 .337-.129c.1-.084.17-.197.21-.335.04-.141.114-.276.218-.411a4.56 4.56 0 0 1 .444-.461c.307-.295.524-.558.648-.793.127-.236.187-.499.187-.79 0-.496-.17-.904-.515-1.224-.344-.32-.818-.48-1.422-.48a2.39 2.39 0 0 0-1.125.267c-.34.176-.618.417-.832.721a.327.327 0 0 0-.043.358.496.496 0 0 0 .297.267c.107.037.22.037.344 0a.696.696 0 0 0 .324-.217.978.978 0 0 1 .424-.335c.17-.07.35-.104.54-.104.308 0 .548.066.722.2.174.136.26.318.26.553 0 .182-.06.373-.183.574-.124.2-.33.445-.625.73a3.923 3.923 0 0 0-.504.577c-.11.16-.18.348-.207.565a.368.368 0 0 0 .124.332.52.52 0 0 0 .37.141l.007-.006Zm1.663 2.963v.972A7.992 7.992 0 0 0 11.9 9.941c.517-.982.778-1.96.778-2.935 0-1.295-.43-2.358-1.296-3.183-.861-.825-1.95-1.239-3.265-1.239-1.336 0-2.468.436-3.4 1.305-.927.868-1.395 1.922-1.395 3.16 0 1.24.464 2.293 1.396 3.164.928.872 2.05 1.311 3.365 1.311h1.67v-.003Z" />
    </CustomSvg>
  );
};
