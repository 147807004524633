/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
import styled from "@mui/styled-engine-sc";
import FormControlLabel from "@mui/material/FormControlLabel";
import React from "react";

const StyledFormControlLabel = styled(({ ...props }) => <FormControlLabel {...props} />)`
  ${({ theme }) => `
    margin: 0;
    //gap: ${theme.spacing(0.75)};

    span {
      line-height: unset;
    }

    .MuiTypography-root {
      padding-left: ${theme.spacing(2)};
    }

    &.bordered-gray-300 {
      background: ${theme.palette.gray.neutralGray50};
      border-radius: 8px;
      //border: 1px solid;
      height: 100%;
      position: relative;
      padding: ${theme.spacing(2)};
      align-items: flex-start;

      .MuiTypography-root {
        padding-left: 0;
      }
      .MuiTypography-body3 {
        line-height: 16px;
      }
    }

    &.remember {
      margin-top: ${theme.spacing(6)};
    }

    &.MuiFormControlLabel-root.address-display{
      display: inline-block;
      margin: 0;
      width: 100%;
      height: 100%;
    }

    &.MuiFormControlLabel-root.pay-option {
      margin-left: 0px;
      margin-right: 0.5px;
    }

    &.MuiFormControlLabel-root.pay-option:nth-child(odd) {
      background-color: ${theme.palette.grey[100]};
      border-top-left-radius: ${theme.shape.borderRadius}px;
      border-top-right-radius: ${theme.shape.borderRadius}px;
    }

    &.line-between {
      position: relative;

      &:after {
        content: "";
        position: absolute;
        height: 1px;
        width: 100%;
        background: ${theme.palette.gray.neutralGray300};
        top: 10px;
        left: calc(50% + 8px);

        ${theme.breakpoints.down("sm")} {
          display: none;
        }
      }
    }

    &.toolbar-select-hours {
      background: ${theme.palette.secondary.extraLight};
      color: ${theme.palette.text.primary};
      border-radius: 8px;
      border: ${theme.palette.gray.neutralGray300} 1px solid;
      padding: ${theme.spacing(6)} ${theme.spacing(4)};
      font-weight: 400;
      margin-bottom: ${theme.spacing(4)};

      &.Mui-disabled {
        background: ${theme.palette.gray.neutralGray50};

        .MuiFormControlLabel-label.Mui-disabled {
          color: ${theme.palette.gray.neutralGray500};
        }

        .MuiSvgIcon-root {
          rect {
            stroke: ${theme.palette.gray.neutralGray300};
          }
        }
      }
    }
  `}
`;

export { StyledFormControlLabel };
