/*
 *---------------------------------------------------
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *---------------------------------------------------
 */
//Redux
import { RootReducerState } from "../reducers";

export const cartSelector = (state: RootReducerState) => state.order.cart;
export const numItemsSelector = (state: RootReducerState) => state.order.numItems;
export const orderItemsSelector = (state: RootReducerState) => state.order.orderItems;
export const cartModalOpenSelector = (state: RootReducerState) => state.order.cartModalOpen;
export const cartModalOpenItemAddedSelector = (state: RootReducerState) => state.order.cartItemAdded;
export const itemsAddedInCartOneTimeSelector = (state: RootReducerState) => state.order.itemsAdded;
export const catentriesSelector = (state: RootReducerState) => state.order.catentries;
export const isCheckoutDisabledSelector = (state: RootReducerState) => state.order.isCheckoutDisabled;
export const shipInfosSelector = (state: RootReducerState) => state.order.shipInfos;
export const shipModesSelector = (state: RootReducerState) => state.order.shipModes;
export const payMethodsSelector = (state: RootReducerState) => state.order.payMethods;
export const isRecurringOrderDisabledSelector = (state: RootReducerState) => state.order.isRecurringOrderDisabled;
export const isFetchingSelector = (state: RootReducerState) => state.order.isFetching;
export const ordersListSelector = (state: RootReducerState) => state.order.listOfOrders;
export const allowableShipModesSelector = (state: RootReducerState) => state.order.allowableShipModes;
export const activeInprogressOrderSelector = (state: RootReducerState) => state.order.activeInprogressOrder;
export const allowablePaymethodsSelector = (state: RootReducerState) => state.order.allowablePaymethods;
export const orderMethodIsPickupSelector = (state: RootReducerState) => state.order.orderMethodIsPickup;
export const orderMethodSelector = (state: RootReducerState) => state.order.orderMethod;
export const orderbillingAddressSelector = (state: RootReducerState) => state.order.billingAddress;
export const pickupPersonSelector = (state: RootReducerState) => state.order.pickupPerson;
export const orderIsLoadingSelector = (state: RootReducerState) => state.order.isLoading;
export const orderSimulationSofincoSelector = (state: RootReducerState) => state.order.simulationSofinco;
// export const availabilityInStoreLoading = (state: RootReducerState) => state.order.availabilityInStoreLoading;
// export const availabilityInStore = (state: RootReducerState) => state.order.availabilityInStore;
// export const availabilityInStoreError = (state: RootReducerState) => state.order.availabilityInStoreError;
// export const availabilityShippingLoading = (state: RootReducerState) => state.order.availabilityShippingLoading;
// export const availabilityShipping = (state: RootReducerState) => state.order.availabilityShipping;
// export const availabilityShippingError = (state: RootReducerState) => state.order.availabilityShippingError;
