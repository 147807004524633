//Standard libraries
import React from "react";
import { StyledGrid } from "@hcl-commerce-store-sdk/react-component";
import { SvgMadeInFrance } from "../svg/madeInFrance";
import { SvgPro } from "../svg/pro";
import { SvgDelivery } from "../svg/delivery";
import { SvgPickUp } from "../svg/pickUp";
import { StyledTypography, StyledContainer, StyledBox } from "../../styled-mui";
import { useTranslation } from "react-i18next";

/**
 * FooterReassurance component
 *
 * @param props
 */
const FooterReassurance: React.FC = (props: any) => {
  const { t } = useTranslation();

  return (
    <StyledBox sx={{ textAlign: "center", width: "100%" }}>
      <StyledContainer className="no-max-width">
        <StyledGrid container py={15} justifyContent="center">
          <StyledGrid container item lg={10}>
            <StyledGrid item xs={6} sm={3} lg={3} px={1}>
              <SvgMadeInFrance />
              <StyledTypography variant="body3" component="p" pt={3}>
                {t("Footer.Reinsurance.MadeInFrance.Title")}
              </StyledTypography>
              <StyledTypography variant="body4" component="p">
                {t("Footer.Reinsurance.MadeInFrance.Description")}
              </StyledTypography>
            </StyledGrid>
            <StyledGrid item xs={6} sm={3} lg={3} px={1}>
              <SvgPro />
              <StyledTypography variant="body3" component="p" pt={3}>
                {t("Footer.Reinsurance.ProfessionalInstallation.Title")}
              </StyledTypography>
              <StyledTypography variant="body4" component="p">
                {t("Footer.Reinsurance.ProfessionalInstallation.Description")}
              </StyledTypography>
            </StyledGrid>
            <StyledGrid
              item
              xs={6}
              sm={3}
              lg={3}
              px={1}
              pt={{
                xs: 10,
                sm: 0,
              }}>
              <SvgDelivery />
              <StyledTypography variant="body3" component="p" pt={3}>
                {t("Footer.Reinsurance.HomeDelivery.Title")}
              </StyledTypography>
              <StyledTypography variant="body4" component="p">
                {t("Footer.Reinsurance.HomeDelivery.Description")}
              </StyledTypography>
            </StyledGrid>
            <StyledGrid
              item
              xs={6}
              sm={3}
              lg={3}
              px={1}
              pt={{
                xs: 10,
                sm: 0,
              }}>
              <SvgPickUp />
              <StyledTypography variant="body3" component="p" pt={3}>
                {t("Footer.Reinsurance.PickUpInStore.Title")}
              </StyledTypography>
              <StyledTypography variant="body4" component="p">
                {t("Footer.Reinsurance.PickUpInStore.Description")}
              </StyledTypography>
            </StyledGrid>
          </StyledGrid>
        </StyledGrid>
      </StyledContainer>
    </StyledBox>
  );
};

export default FooterReassurance;
