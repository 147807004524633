/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
import styled from "@mui/styled-engine-sc";
import { palette } from "../../themes/color-palette";

const StyledFooter = styled("footer")`
  ${({ theme }) => `
  //margin-top: ${theme.spacing(7)};

  /* ${theme.breakpoints.down("md")} {
    margin-top: ${theme.spacing(4)};
  } */

  .contact-us {
    line-height: 2;

    a {
      color: ${theme.palette.text.disabled};
      text-decoration: none;
      &:hover {
        color: ${theme.palette.text.disabled};
      }
      &:visited {
        color: ${theme.palette.text.disabled};
      }
    }

    div {
      padding-top: ${theme.spacing(0.5)};
    }
  }

  .footer-social-link {
    border: 1px solid ${theme.palette.gray.neutralGray500};
    border-radius: 8px;
    padding: 10px;
    margin: 0 8px;
    &:hover {
      opacity: 0.9;
    }
  }

  .footer-links-list {
    list-style: none;
  }

  .footer-logos {
    .footer-logos-list {
      list-style: none;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
    }
    .footer-logos-list li {
      padding: 0 ${theme.spacing(3)};
    }
    .footer-logos-list li:first-child {
      padding: 0 ${theme.spacing(3)} 0 0;
    }
    .footer-logos-list li:last-child {
      padding: 0 0 0 ${theme.spacing(3)};
    }
  }
  .footer-nav {
    .footer-nav-list {
      list-style: none;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      max-width: 800px;
      margin: 0 auto;
      padding: ${theme.spacing(3)} 0 0 0;
    }
    .footer-nav-list li {
      padding: 0 ${theme.spacing(3)} ${theme.spacing(3)} 0;
      color: ${palette.white.main};
    }

    .footer-nav-list li a {
      color: ${palette.white.main};
    }
  }
  `}
`;

export { StyledFooter };
