export const swrOptionsNoRetry = {
  revalidateIfStale: true, // The revalidateIfStale controls if SWR should revalidate when it mounts and there is stale data.
  shouldRetryOnError: false, // default: true
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
  dedupingInterval: 2000,
};

export const swrOptionsSubmitNoRetry = {
  revalidateIfStale: false,
  shouldRetryOnError: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
  dedupingInterval: 7000,
};
