/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
import React from "react";
import Button from "@mui/material/Button";
import styled from "@mui/styled-engine-sc";
import { dimensions } from "../../../themes/variables";

// const searchBarWidth = dimensions.searchBar.width;
const searchBarHeight = dimensions.searchBar.height;
const headerHeight = dimensions.header.height.desktop;
const mobileHeaderHeight = dimensions.header.height.mobile;

const StyledSearchBar = styled("div")`
  ${({ theme }) => `
  position: relative;

  ${theme.breakpoints.down("md")} {
    padding: 0  ${theme.spacing(5)};
  }

  .searchBar-results {
    z-index: 1;
    position: absolute;
    top: ${(headerHeight - searchBarHeight) * 0.2 + searchBarHeight}px;
    left: 0;
    width: 100%;
    text-align: left;
    border-radius: ${theme.shape.borderRadius}px;
    background-color: ${theme.palette.white.main};
    box-shadow: 0px 2px 6px 0px rgba(37, 36, 39, 0.25);
    //box-shadow: 0px 3px 4px 0px ${theme.palette.text.disabled};
    padding: ${theme.spacing(4)};

    ${theme.breakpoints.down("md")} {
      top: ${(mobileHeaderHeight - searchBarHeight) * 0.5 + mobileHeaderHeight}px;
    }

    ul {
      list-style: none;
    }

    .MuiListItem-root {
      border-radius: ${theme.shape.borderRadius}px;
      margin-right: ${theme.spacing(1)};
      margin-left: ${theme.spacing(1)};
      padding-right: ${theme.spacing(1)};
      padding-left: ${theme.spacing(1)};
    }

    .searchBar-results-img-container {
      height: 100px;
      width: 100px;
      min-width: 100px;
      max-width: 100px;
      min-height: 100px;
      max-height: 100px;
      border-right: ${theme.palette.gray.neutralGray300} 1px solid;
      position: relative;
      overflow: hidden;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: 8px 0 0 8px;
      }
    }
  }

  .MuiInputBase-root {
    height: ${searchBarHeight}px;
  }

  .MuiFormControl-root {
    width: 100%;

    ${theme.breakpoints.down("md")} {
      width: 100%;
    }

    .MuiOutlinedInput-input {
      height: ${searchBarHeight}px;
    }
  }

  .Mui-focused {
    border-color: #fff;
  }

  .MuiOutlinedInput-root {
    padding-right: 0;
  }

  .MuiInputBase-input {
    padding-right: 0;
    padding-left: 0;
  }
  `}
`;

export { StyledSearchBar };

export const StyledSearchBarButton = styled(({ ...props }) => <Button {...props} />)`
  ${({ theme }) => `
    display: flex;
    width: ${theme.spacing(4.5)};
    height: ${theme.spacing(4.5)};

    ${theme.breakpoints.down("md")} {
      min-width: ${theme.spacing(3)};
      height: auto;
    }

    .MuiSvgIcon-root {
      color: ${theme.palette.gray.neutralGray800};
    }

    &:hover {
      .MuiSvgIcon-root {
        color: ${theme.palette.gray.neutralGray800};
      }
    }

    &.active {
      background-color: ${theme.palette.action.disabledBackground};

      .MuiSvgIcon-root {
        color: ${theme.palette.primary.main};
      }
    }

    &:focus {
      outline: none;
    }
  `}
`;
