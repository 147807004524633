/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Foundation libraries
import { localStorageUtil } from "../utils/storageUtil";
import { CURRENT_USER } from "../constants/common";

import { TYPE, CONTENT_GROUP, PAGE_LOAD_EVENT } from "../constants/gtm";
//Custom libraries
import { site } from "../constants/site";
import storeUtil from "../../utils/storeUtil";
import { PRODUCT_TYPE } from "../../constants/catalog";
import {
  ADDRESS_LABEL_ATTRKEY,
  ADDRESS_LABEL_VALUE,
  DISPLAY,
  ID_CRM_ATTRKEY_VALUE,
  OFFER,
} from "../../constants/common";
import { trim, deburr, kebabCase } from "lodash-es";
import sha256 from "crypto-js/sha256";
import { enc } from "crypto-js";
// import cartService from "../apis/transaction/cart.service";
// import productsService from "../apis/search/products.service";

export const cleanString = (text) => {
  // return text;
  if (!text) return text;
  return trim(deburr(text));
};
const removeWhiteSpace = (text) => {
  if (!text) return text;
  return cleanString(text.replace(/\s/g, ""));
};
export const removeDotsBeforeArobase = (email) => {
  if (!email) return email;
  const tab = email.split("@");
  const validString = tab.length > 1 ? `${tab[0].replace(/\./g, "")}@${tab[1]}` : email;
  return cleanString(validString);
};

export const getCodeCountry = (country) => {
  if (!country) return "";
  return country === "France" ? "FR" : "";
};
export const encryptSha256 = (value) => {
  const hex = enc.Hex.parse(cleanString(value));
  return sha256(hex).toString();
};

const UADataService = {
  PAGETYPE: "",
  CONTENTGROUP: "",
  FORMNAME: "",
  EVENT: "",
  MODELLISTID: "modeles-de-cuisine",
  MODELLISTNAME: "Modèles de cuisine",
  //
  setPageData(type: string, contentGroup: any = null, formName: any = null, event: any = null) {
    if (type) this.PAGETYPE = type;
    if (contentGroup) this.CONTENTGROUP = contentGroup || CONTENT_GROUP.CONTENT;
    if (formName) this.FORMNAME = formName;
    if (event) this.EVENT = event || PAGE_LOAD_EVENT.PAGE_LOAD;
  },
  setPageEvent(text: string) {
    this.EVENT = text;
  },
  setPageType(text: string) {
    this.PAGETYPE = text;
  },
  setPageContentGroup(text: string) {
    this.CONTENTGROUP = text;
  },
  setPageFormName(text: string) {
    this.FORMNAME = text;
  },
  getPagePath() {
    const locationPath = window.location.pathname;
    return locationPath === "/" ? locationPath : locationPath.replace(/\/$/, "");
  },
  setUserEncryptData(params) {
    let newObject = { ...params };
    for (const key in params) {
      switch (key) {
        case "email": {
          if (params.email) {
            newObject = { ...newObject, sha256_email_address: encryptSha256(removeDotsBeforeArobase(params.email)) };
          }
          break;
        }
        case "phone_number": {
          if (params.phone_number) {
            newObject = {
              ...newObject,
              phone_number: removeWhiteSpace(params.phone_number),
              sha256_phone_number: encryptSha256(removeWhiteSpace(params.phone_number)),
            };
          }
          break;
        }
        case "address_first_name": {
          if (params.address_first_name) {
            newObject = {
              ...newObject,
              address_first_name: cleanString(params.address_first_name),
              address_sha256_first_name: encryptSha256(params.address_first_name),
            };
          }
          break;
        }
        case "address_last_name": {
          if (params.address_last_name) {
            newObject = {
              ...newObject,
              address_last_name: cleanString(params.address_last_name),
              address_sha256_last_name: encryptSha256(params.address_last_name),
            };
          }
          break;
        }
        case "address_street": {
          if (params.address_street) {
            newObject = {
              ...newObject,
              address_street: cleanString(params.address_street),
              address_sha256_street: encryptSha256(params.address_street),
            };
          }
          break;
        }
      }
    }
    return newObject;
  },
  getUserInfoFromStore(cart, orderItems) {
    try {
      const currentUser = localStorageUtil.get(CURRENT_USER);
      let productsItems: any = [];
      let order: any = {};
      if (cart) {
        order = cart;
        if (orderItems && orderItems.length > 0) {
          productsItems = this.getItemsOrderEcommerceData({}, order, orderItems);
        }
      }
      if (currentUser) {
        const { /* userId, */ userLoggedIn, details } = currentUser;
        const attribute = details?.attributes?.filter((a) => a[ADDRESS_LABEL_ATTRKEY] === ID_CRM_ATTRKEY_VALUE) || [];
        const id_CRM = attribute.length > 0 ? attribute[0][ADDRESS_LABEL_VALUE] : "";

        return {
          user_connected: userLoggedIn ? "logged In" : "logged Out",
          user_id: id_CRM,
          email: details ? details?.email1 : "",
          sha256_email_address:
            details && details.email1 ? encryptSha256(removeDotsBeforeArobase(details?.email1)) : "",
          phone_number: details && details?.phone1 ? removeWhiteSpace(details?.phone1) : "",
          sha256_phone_number: details && details.phone1 ? encryptSha256(removeWhiteSpace(details?.phone1)) : "",
          address_first_name: details && details.firstName ? cleanString(details.firstName) : "",
          address_sha256_first_name: details && details.firstName ? encryptSha256(details?.firstName) : "",
          address_last_name: details && details.lastName ? cleanString(details.lastName) : "",
          address_sha256_last_name: details && details.lastName ? encryptSha256(details?.lastName) : "",
          address_street: details && details.addressLine ? cleanString(details.addressLine.join(" ")) : "",
          address_sha256_street: details && details.addressLine ? encryptSha256(details?.addressLine.join(" ")) : "",
          address_city: details && details.city ? cleanString(details.city) : "",
          //address_region: details && details.region ? cleanString(details.region) : "",
          address_postal_code: details && details.zipCode ? cleanString(details.zipCode) : "",
          address_country: details && details.country ? getCodeCountry(details.country) : "",
          cart_id: order?.orderId ? order?.orderId : "",
          cart_total: order?.grandTotal ? order?.grandTotal : "",
          cart_tax: order?.totalSalesTax ? order?.totalSalesTax : "",
          cart_discount: order?.totalAdjustment ? Math.abs(order?.totalAdjustment) : "",
          cart_items: productsItems,
        };
      } else {
        return {
          user_connected: "logged Out",
          user_id: 0,
          email: "",
          sha256_email_address: "",
          phone_number: "",
          sha256_phone_number: "",
          address_first_name: "",
          address_sha256_first_name: "",
          address_last_name: "",
          address_sha256_last_name: "",
          address_street: "",
          address_sha256_street: "",
          address_city: "",
          //address_region:  "",
          address_postal_code: "",
          address_country: "",
          cart_id: order?.orderId || "",
          cart_tax: order?.totalSalesTax || "",
          cart_discount: order?.totalAdjustment || "",
          cart_items: productsItems || "",
        };
      }
    } catch (err) {
      console.log("Error From Catch ", err);
    }
  },
  getTypePageProduct(product) {
    switch (product.type) {
      case PRODUCT_TYPE.BOTTE:
      case PRODUCT_TYPE.COLISE:
        return TYPE.PRODUCT_COLISE;
      case PRODUCT_TYPE.SUR_MESURE:
        return product.hasConfigurator ? TYPE.PRODUCT_CONFIGURATEUR : TYPE.PRODUCT_SUR_MESURE;
      default:
        if (product.hasConfigurator) {
          return TYPE.PRODUCT_PERSONNALISABLE;
        }
        return product.type !== "bundle" && !product.isKitFurniture ? TYPE.PRODUCT_COMPLEXE : TYPE.PRODUCT_STANDARD;
    }
  },
  getBreadCrumbsData(breadcrumbs) {
    const breadcrumbsData = { breadcrumb_level_1: "accueil" };
    const locationPath = window.location.pathname;
    const locationPathArray = locationPath.split("/");
    if (breadcrumbs) {
      let level;
      breadcrumbs?.length > 4 ? (level = 1) : (level = 2);
      breadcrumbs?.forEach(
        (key, index) =>
          (breadcrumbsData[`breadcrumb_level_${index + level}`] = key.seo
            ? key.seo?.href?.length > 0
              ? key.seo?.href?.replace("/", "")
              : locationPathArray[locationPathArray.length - 1]
            : locationPathArray[locationPathArray.length - 1])
      );
      return breadcrumbsData;
    } else {
      if (locationPathArray.length > 0) locationPathArray.shift();
      locationPathArray.forEach((key, index) => (breadcrumbsData[`breadcrumb_level_${index + 2}`] = key));
      return locationPath === "/" ? { breadcrumb_level_1: "accueil" } : breadcrumbsData;
    }
  },
  getItemsCategories(parentCatalogGroupID, topCategoriesList) {
    if (topCategoriesList && Object.keys(topCategoriesList).length > 0) {
      const categories = parentCatalogGroupID
        ? storeUtil.getCategoryBreadcrumbs(parentCatalogGroupID, topCategoriesList)
        : topCategoriesList;
      return {
        item_category: categories && categories.length > 0 ? categories[0]?.label : "",
        item_category2: categories && categories.length > 1 ? categories[1]?.label : "",
        item_category3: categories && categories.length > 2 ? categories[2]?.label : "",
        item_category4: categories && categories.length > 3 ? categories[3]?.label : "",
        item_category5: categories && categories.length > 4 ? categories[4]?.label : "",
      };
    }
  },
  getProductsData(product: any = null) {
    if (!product) return { nb_products: 0 };
    if (product.sku?.length > 0) {
      const avis = storeUtil.getAvisProduct(product.sku[0]);
      return {
        nb_products: product?.sku?.length,
        product_id: product?.fpcId || "",
        product_name: product?.sku[0]?.name || "",
        product_availability: product?.availability || "",
        product_energy: product?.product_energy || "",
        product_garanty: product?.product_garanty || "",
        product_origin: product?.product_origin || "",
        product_rating: avis?.note || "",
        product_review: avis?.count || "",
      };
    }
  },
  getProductsListData(products: any = null) {
    if (!products) return { nb_products: 0 };
    const productId: any = [];
    const productName: any = [];
    const productAvailability: any = [];

    for (let index = 0; index < products.length; index++) {
      const el = products[index];
      for (const key in el) {
        if (key === "partNumber") {
          productId.push(el[key]);
        }
        if (key === "name") {
          productName.push(el[key]);
        }
        if (key === "orderItemInventoryStatus") {
          productAvailability.push(el[key]);
        }
      }
    }
    return {
      nb_products: products.length,
      product_name: productName || "",
      product_id: productId || "",
      product_availability: productAvailability || "",
      product_energy: "", // TODO "A DEFINIR"
      product_garanty: "", // TODO "A DEFINIR"
      product_origin: "", // TODO "A DEFINIR"
      product_rating: "", // TODO "A DEFINIR"
      product_review: "", // TODO "A DEFINIR"
    };
  },
  // getProductAdjustement(elPrices) {
  //   let couponIs = "";
  //   let discountIs = 0;
  //   if (elPrices && elPrices.length > 1 && elPrices[0].value !== elPrices[1].value) {
  //     couponIs = elPrices[1].usage;
  //     discountIs = parseFloat(elPrices[0].value) - parseFloat(elPrices[1].value);
  //   }
  //   return {
  //     coupon: couponIs,
  //     discount: Math.abs(parseFloat(storeUtil.parseFloatDecimal(discountIs, 2))),
  //   };
  // },
  getItemsEcommerceData(
    categoriesList,
    products,
    itemListId,
    itemListName,
    label: any = null,
    fpcId: any = null,
    marketplaceSellers: any = null
  ) {
    const itemsArr: any = [];
    for (let index = 0; index < products.length; index++) {
      const element = products[index];
      const avis = storeUtil.getAvisProduct(element);

      const itemId = () => {
        if (element["UserData"]) {
          return element["UserData"][0]["custom.fields.*"][0]["catent_field1"]["raw"];
        } else {
          return element.partNumber;
        }
      };
      const boxPrice = Number(element?.boxPrice || element?.["boxPriceData.numeric"] || 0);
      const item = {
        index,
        ...(label && { btn_label: label }),
        item_id: itemId(),
        item_name: element.name,
        // ...this.getProductAdjustement(element.price),
        ...this.getItemsCategories(element.parentCatalogGroupID, categoriesList),
        affiliation: marketplaceSellers?.find((s) => s.id === element.sellerId)?.organizationName || "",
        item_list_id: itemListId,
        item_list_name: itemListName,
        item_marketing_tag: element["custom.items.attribute.tag.raw"] || "",
        item_type: element?.UserData ? element?.UserData[0]["custom.fields.*"][0].catent_field4.raw : "",
        //item_variant: element.partNumber,
        price: boxPrice || element?.promotions?.displayPrice || this.getProductPrice(element.price).price,
        discount: element?.promotions?.priceAdjustment || "",
        promotion_name: element?.promotions?.promotionName || "",
        promotion_id: element?.promotions?.promotionId || "",
        quantity: parseFloat(element.quantity) || 1,
        // (index === 0 ? fpcId : "") -> for add to Card (Bundle) only retrieve fcp on first product
        product_id: fpcId ? (index === 0 ? fpcId : "") : element.fpcId ? element.fpcId : element.partNumber,
        product_availability: element.buyable,
        ...this.getDetailAttributes(element.attributes),
        product_rating: avis?.note || "",
        product_review: avis?.count || "",
      };

      itemsArr.push(item);
    }
    return itemsArr;
  },
  getItemsOrderEcommerceData(categoriesList, order, orderItems, marketplaceSellers: any = null) {
    const itemsArr: any = [];
    for (let index = 0; index < orderItems.length; index++) {
      const element = orderItems[index];
      const avis = storeUtil.getAvisProduct(element);
      let hasAdjustement: any = [];
      if (element?.adjustment && element?.adjustment.length > 0) {
        hasAdjustement = element?.adjustment.filter((item) => item.displayLevel == "OrderItem");
      }
      let promotionCode: any = [];
      if (order?.promotionCode && order?.promotionCode.length > 0 && hasAdjustement.length > 0) {
        promotionCode = order?.promotionCode.filter(
          (promoCode) => promoCode?.associatedPromotion[0].description == hasAdjustement[0]?.description
        );
      }
      const itemAdjustment = element?.adjustment?.find((adjustmentItem) => adjustmentItem.displayLevel === "OrderItem");
      const elementPrice =
        parseFloat(element?.unitPrice) +
        parseFloat(element?.xitem_ECOPART_TTC) +
        parseFloat(itemAdjustment?.xiadju_unitLevelAdjustment || itemAdjustment?.xadju_unitLevelAdjustment || 0);

      const item = {
        index,
        item_id: element.partNumber,
        item_name: element?.fpcName || element?.name || element.xitem_nomArticle,
        coupon: promotionCode && promotionCode.length > 0 ? promotionCode[0].code : "",
        discount: Math.abs(element?.totalAdjustment?.value) || 0,
        ...this.getItemsCategories(element.parentCatalogGroupID, categoriesList),
        affiliation:
          (marketplaceSellers && marketplaceSellers?.find((s) => s.id === element.sellerId)?.organizationName) || "",
        shippingTier: element.shipModeCode,
        // price:
        //   parseFloat(element?.unitPrice) +
        //   parseFloat(element?.xitem_ECOPART_TTC) +
        //   parseFloat(element?.totalAdjustment?.value),
        price: elementPrice,
        quantity: parseFloat(element.quantity) || 1,
        product_id: element?.fpcId || element.partNumber,
        product_availability: element.buyable,
        ...this.getDetailAttributes(element.attributes),
        product_rating: avis?.note || "",
        product_review: avis?.count || "",
      };

      itemsArr.push(item);
    }
    return itemsArr;
  },
  getItemsModelEcommerceData(product) {
    const itemsArr: any = [];
    for (let index = 0; index < product.length; index++) {
      const element = product[index];
      const item = {
        index,
        item_id: element.id,
        item_name: element.title,
        ...this.getItemsCategories("", [{ label: "cuisine-electromenager" }, { label: "modeles-de-cuisine" }]),
        item_list_id: this.MODELLISTID,
        item_list_name: this.MODELLISTNAME,
        price: parseFloat(element.price),
        product_availability: "true",
        quantity: product.quantity || 1,
      };

      itemsArr.push(item);
    }
    return itemsArr;
  },
  getDetailAttributes(attributes) {
    return {
      product_energy: storeUtil.getEnergyAttributes(attributes) || "",
      product_garanty: storeUtil.getGarantyAttributes(attributes) || "",
      product_origin: storeUtil.getOrigneAttributes(attributes) || "",
    };
  },
  /**
   * get the product offer price and display price
   * @param priceArray
   */
  getProductPrice(priceArray) {
    let productOfferPrice = 0;
    let prodDisplayPrice = 0;
    let currency: string = "";
    if (priceArray) {
      for (const price of priceArray) {
        if (price.usage === OFFER && price.value !== "") {
          productOfferPrice = parseFloat(price.value);
          currency = price.currency;
        } else if (price.usage === DISPLAY && price.value !== "") {
          prodDisplayPrice = parseFloat(price.value);
          currency = price.currency;
        }
      }
    }
    const price = productOfferPrice > 0 ? productOfferPrice : prodDisplayPrice > 0 ? prodDisplayPrice : 0;
    return { price, currency };
  },

  // add_to_cart from PDP
  async sendAddToCartEvent(cart, product, breadcrumbs, sellers, label) {
    let cartTotal = 0;
    for (let index = 0; index < product.sku.length; index++) {
      const element = product.sku[index];
      const boxPrice = Number(element?.boxPrice || element?.["boxPriceData.numeric"] || 0);
      const priceProduct = boxPrice || element?.promotions.displayPrice || this.getProductPrice(element.price).price;
      cartTotal += priceProduct * product.quantity;

      element.quantity = product.quantity;
    }

    const cartObj = {
      grandTotal: cartTotal, //parseFloat(cart.grandTotal) || 0
      grandTotalCurrency: cart.grandTotalCurrency || "EUR",
    };

    const productsItem: Array<object> = this.getItemsEcommerceData(
      breadcrumbs,
      product.sku,
      product.fpcId + "_" + kebabCase(product.sku[0].name),
      product.fpcId + " " + product.sku[0].name,
      label,
      product.fpcId,
      sellers.sellers
    );

    return { cartObj, productsItem };
  },
  /* HEADER NAVIGATION CLICK */
  async NavigationClick(eventAction, eventLabel) {
    return { eventAction, eventLabel };
  },
  /* ALL EVENT CLICK */
  async sendProductClickEvent(
    { topCategoriesList, product, label, item_list_id, item_list_name, itemPrice }: any = null
  ) {
    return {
      page_type: this.PAGETYPE,
      content_group: this.CONTENTGROUP || CONTENT_GROUP.CONTENT,
      environment: site.gtmEnv,
      env_version: site.gtmV,
      ecommerce: {
        currency: product?.price[0].currency.toUpperCase() || "EUR",
        itemPrice,
        item_list_id,
        item_list_name,
        product: this.getItemsEcommerceData(topCategoriesList, [product], item_list_id, item_list_name, label),
      },
    };
  },
  async sendModelClickEvent({ product }: any = null) {
    const productItem = this.getItemsModelEcommerceData([product]);
    return {
      page_type: this.PAGETYPE,
      content_group: this.CONTENTGROUP || CONTENT_GROUP.CONTENT,
      environment: site.gtmEnv,
      env_version: site.gtmV,
      ecommerce: {
        currency: "EUR",
        value: parseFloat(productItem[0].price),
        item_list_id: this.MODELLISTID,
        item_list_name: this.MODELLISTNAME,
        product: productItem[0],
      },
    };
  },
  /* ALL EVENT CLICK */
  async sendQuantityClickEvent(eventData: any = null) {
    const { topCategoriesList, product, item_list_id, item_list_name, label, cartTotal } = eventData;
    return {
      page_type: this.PAGETYPE,
      content_group: this.CONTENTGROUP || CONTENT_GROUP.CONTENT,
      environment: site.gtmEnv,
      env_version: site.gtmV,
      ecommerce: {
        currency: product.price[0].currency.toUpperCase() || "",
        value: parseFloat(cartTotal) || "",
        items: this.getItemsEcommerceData(topCategoriesList, [product], item_list_id, item_list_name, label),
      },
    };
  },
  /* ALL EVENT CLICK */
  async sendClickEvent(eventData) {
    return {
      page_type: this.PAGETYPE,
      content_group: this.CONTENTGROUP || CONTENT_GROUP.CONTENT,
      environment: site.gtmEnv,
      env_version: site.gtmV,
      ...this.setUserEncryptData(eventData),
    };
  },
  /* HOME PAGE */
  async sendHomePageViewEvent(cart, orderItems) {
    this.setPageData(TYPE.HOME, CONTENT_GROUP.CONTENT);
    const obj = {
      page_type: this.PAGETYPE,
      content_group: this.CONTENTGROUP,
      environment: site.gtmEnv,
      env_version: site.gtmV,
      ...this.getBreadCrumbsData([]),
      ...this.getUserInfoFromStore(cart, orderItems),
    };
    return obj;
  },

  /* NOT FOUND */
  async sendNotFoundPageViewEvent(eventObj: Record<string, any>) {
    this.setPageData(TYPE.NOT_FOUND);
    const obj = {
      page_type: this.PAGETYPE,
      environment: site.gtmEnv,
      env_version: site.gtmV,
      page_location: eventObj.page_location,
    };
    return obj;
  },

  /* ALL CONTENT PAGE */
  async sendContentPageViewEvent(breadcrumbs) {
    let obj: any = {
      page_type: this.PAGETYPE,
      content_group: this.CONTENTGROUP || CONTENT_GROUP.CONTENT,
      environment: site.gtmEnv,
      env_version: site.gtmV,
      ...this.getBreadCrumbsData(breadcrumbs),
      ...this.getUserInfoFromStore(null, null),
    };
    if (this.FORMNAME) {
      obj = { ...obj, form_name: this.FORMNAME };
    }
    return obj;
  },
  /* PRODUCT DETAIL PAGE */
  async sendPDPPageViewEvent(breadcrumbs, product, cart, orderItems) {
    const type = this.getTypePageProduct(product);
    this.setPageData(type, CONTENT_GROUP.PRODUCT_DETAIL);
    const obj: any = {
      page_type: this.PAGETYPE,
      content_group: this.CONTENTGROUP,
      environment: site.gtmEnv,
      env_version: site.gtmV,
      ...this.getBreadCrumbsData(breadcrumbs),
      ...this.getProductsData(product),
      ...this.getUserInfoFromStore(cart, orderItems),
    };
    return obj;
  },
  /* PRODUCT DETAIL PAGE */
  async sendStoreLocatorPopinViewEvent(breadcrumbs) {
    const obj: any = {
      page_type: this.PAGETYPE,
      content_group: this.CONTENTGROUP,
      environment: site.gtmEnv,
      env_version: site.gtmV,
      ...this.getBreadCrumbsData(breadcrumbs),
      ...this.getUserInfoFromStore(null, null),
    };
    return obj;
  },
  /* FUNNEL (CHECKOUT) PAGE */
  async sendCheckoutPageViewEvent(pageSubCategory, pathname, breadcrumbs, cart, orderItems) {
    this.setPageData(TYPE.CHECKOUT, CONTENT_GROUP.FUNNEL);
    let obj: any = {
      page_type: this.PAGETYPE,
      content_group: this.CONTENTGROUP || CONTENT_GROUP.CONTENT,
      environment: site.gtmEnv,
      env_version: site.gtmV,
      ...this.getBreadCrumbsData(breadcrumbs),
      ...this.getUserInfoFromStore(cart, orderItems),
    };
    if (this.FORMNAME) {
      obj = { ...obj, form_name: this.FORMNAME };
    }
    return obj;
  },

  async sendListerPageViewEvent(breadcrumbs, productList, cart, orderItems) {
    const obj: any = {
      page_type: this.PAGETYPE,
      content_group: this.CONTENTGROUP || CONTENT_GROUP.PRODUCT_LIST,
      environment: site.gtmEnv,
      env_version: site.gtmV,
      ...this.getProductsListData(productList),
      ...this.getBreadCrumbsData(breadcrumbs),
      ...this.getUserInfoFromStore(cart, orderItems),
    };
    return obj;
  },

  async sendCartViewEvent(breadcrumbs, productList, cart, orderItems) {
    const obj: any = {
      page_type: this.PAGETYPE,
      content_group: this.CONTENTGROUP || CONTENT_GROUP.PRODUCT_LIST,
      environment: site.gtmEnv,
      env_version: site.gtmV,
      ...this.getProductsListData(productList),
      ...this.getBreadCrumbsData(breadcrumbs),
      ...this.getUserInfoFromStore(cart, orderItems),
    };
    return obj;
  },

  async sendCartPageViewEvent(event, topCategoriesList, productList, itemListId, itemListName, cart) {
    let obj: any = {
      event: event,
      value: Number(cart?.grandTotal),
      currency: "EUR",
      item_list_id: itemListId,
      item_list_name: itemListName,

      cart: {
        id: cart?.orderId,
        total: Number(cart?.grandTotal),
        tax: cart?.totalSalesTax,
        discount: cart?.totalAdjustment,
      },
      products: this.getItemsEcommerceData(topCategoriesList, productList, itemListId, itemListName),
    };

    if (this.FORMNAME) {
      obj = { ...obj, form_name: this.FORMNAME };
    }

    return obj;
  },

  async sendProductImpressionEvent(product, breadcrumbs, sellers, cart) {
    const element = product.sku[0];
    const currency = this.getProductPrice(element.price).currency;
    const priceProduct = this.getProductPrice(element.price).price;

    const cartObj = {
      grandTotal: cart ? parseFloat(cart.grandTotal) : priceProduct,
      grandTotalCurrency: cart ? cart?.grandTotalCurrency : currency,
    };

    const productItem = this.getItemsEcommerceData(
      breadcrumbs,
      [element],
      product.fpcId + "_" + kebabCase(element.name),
      product.fpcId + " " + element.name,
      null,
      product.fpcId,
      sellers.sellers
    );

    return { cartObj, productItem, currency };
  },
  async sendProductListImpressionEvent(topCategoriesList, productList, itemListId, itemListName) {
    const obj: any = {
      item_list_id: itemListId,
      item_list_name: itemListName,
      products: this.getItemsEcommerceData(topCategoriesList, productList, itemListId, itemListName),
    };

    return obj;
  },
  async sendPromoListImpressionEvent(promoList, itemListId, itemListName) {
    const promoArr: any = [];
    if (promoList.pushBanner.length > 0) {
      const pushBanner = promoList.pushBanner;
      //// for multiple banner
      // for (let index = 0; index < pushBanner.length; index++) {
      //   const element = pushBanner[index];
      //   promoArr.push(element);
      // }
      promoArr.push(pushBanner[0]);
    }
    if (promoList.pushProducts.length > 0) {
      const pushProducts = promoList.pushProducts;
      for (let index = 0; index < pushProducts.length; index++) {
        const element = pushProducts[index];
        promoArr.push(element);
      }
    }

    const obj: any = {
      item_list_id: itemListId,
      item_list_name: itemListName,
      promos: promoArr,
    };

    return obj;
  },
  async sendModelsImpressionEvent(products) {
    const obj: any = {
      item_list_id: this.MODELLISTID,
      item_list_name: this.MODELLISTNAME,
      products: this.getItemsModelEcommerceData(products),
    };
    return obj;
  },
  async sendSearchPageViewEvent(productListTotal, searchTerm) {
    return { searchTerm, productResults: productListTotal ?? 0 };
  },
  async sendPurchaseEvent(topCategoriesList, products, order, transactionId) {
    const orderItems = order.orderItem?.map((item) => {
      const product = products.filter((p) => p.partNumber === item.partNumber);
      if (product && product.length === 1) {
        return { ...product[0], ...item };
      }
      return { ...item };
    });
    const productsItems = this.getItemsOrderEcommerceData(topCategoriesList, order, orderItems);
    this.setPageData(TYPE.CONFIRMATION, CONTENT_GROUP.CONTENT);
    const obj = {
      page_type: this.PAGETYPE,
      content_group: this.CONTENTGROUP,
      environment: site.gtmEnv,
      env_version: site.gtmV,
      ...this.getBreadCrumbsData([]),
      ...this.getUserInfoFromStore(order, orderItems),
    };
    return {
      products: productsItems,
      order,
      transactionId,
      pageViewObj: obj,
    };
  },
  async sendCheckoutEvent(topCategoriesList, cart, orderItems, step, value, sellers) {
    let paymentMethod = "";
    if (cart && cart.paymentInstruction) {
      paymentMethod = cart.paymentInstruction[0].payMethodId;
    }

    const products = this.getItemsOrderEcommerceData(topCategoriesList, cart, orderItems, sellers.sellers);
    return {
      cart,
      step,
      value,
      products,
      paymentMethod,
    };
  },
};

export default UADataService;
