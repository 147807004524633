import styled from "@mui/styled-engine-sc";
import { ListItemButton } from "@mui/material";

const StyledListItemButton = styled(({ ...props }) => <ListItemButton {...props} />)`
  ${({ theme }) => `
    padding: ${theme.spacing(3)} ${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(4)};
    border-left: transparent 2px solid;

    &:hover {
      background: ${theme.palette.gray.neutralGray100};
      border-radius: 0px 8px 8px 0px;
    }

    &.no-hover {
      padding: ${theme.spacing(1.5)} ${theme.spacing(2)} ${theme.spacing(1.5)} ${theme.spacing(4)};

      &:hover {
        background: none;
        border-radius: 0px;
      }
    }

    &.last {
      padding-bottom: ${theme.spacing(4)};
    }
    &.gray {
      color: ${theme.palette.gray.neutralGray500};

      .MuiListItemIcon-root {
        color: ${theme.palette.gray.neutralGray500};
      }
    }

    &.Mui-selected {
      background: ${theme.palette.gray.neutralGray100};
      border-radius: 0px 8px 8px 0px;
      border-left: ${theme.palette.primary.dark} 2px solid;

      &:hover {
        background: ${theme.palette.gray.neutralGray100};
      }

      .MuiListItemText-root {
        .MuiTypography-body1 {
          font-weight: 700;
        }
      }
      .MuiListItemIcon-root {
        color: ${theme.palette.primary.dark};
      }

      .secondary-extralight {
        background: ${theme.palette.secondary.main};
        color: ${theme.palette.white.main};
      }
    }
  `}
`;

export { StyledListItemButton };
