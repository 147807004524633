/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Services - Account and Contract
 * These services provide APIs to manage accounts and contracts.  a contract is an agreement that represents the terms and conditions that apply to a transaction. An account is a relationship between the merchant and the financial institution that processes transactions for that merchant.
 *
 * The version of the OpenAPI document: 9.1.6
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../../base';
// @ts-ignore
import { CartPaymentInstruction } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { ComIbmCommerceEdpBeansEDPSensitiveDataMaskHelperDataBeanIBMSensitiveDataMaskByPlainString } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { ComIbmCommerceRestOrderHandlerPaymentInstructionHandlerPaymentInstructionList } from '../../../../../com/hcl/commerce/typescript/axios';
/**
 * PaymentInstructionApi - axios parameter creator
 * @export
 */
export const PaymentInstructionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Adds payment instructions to the shopping cart.
         * @summary Add payment instructions
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {CartPaymentInstruction} [body] The body data required for the REST method.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentInstructionAddPaymentInstruction: async (storeId: string, responseFormat?: string, body?: CartPaymentInstruction, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('paymentInstructionAddPaymentInstruction', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/cart/@self/payment_instruction`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes all payment instructions from the shopping cart.
         * @summary Delete payment instructions
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentInstructionDeleteAllPaymentInstructions: async (storeId: string, responseFormat?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('paymentInstructionDeleteAllPaymentInstructions', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/cart/@self/payment_instruction`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes payment instructions from the shopping cart.
         * @summary Delete payment instructions
         * @param {string} storeId The store identifier.
         * @param {string} paymentInstructionId Payment instruction identifier to delete.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentInstructionDeletePaymentInstruction: async (storeId: string, paymentInstructionId: string, responseFormat?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('paymentInstructionDeletePaymentInstruction', 'storeId', storeId)
            // verify required parameter 'paymentInstructionId' is not null or undefined
            assertParamExists('paymentInstructionDeletePaymentInstruction', 'paymentInstructionId', paymentInstructionId)
            const localVarPath = `/store/{storeId}/cart/@self/payment_instruction/{paymentInstruction_id}`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"paymentInstruction_id"}}`, String(paymentInstructionId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets payment instructions for the shopping cart.
         * @summary Get payment instructions
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [currency] The currency code to use for example, currency&#x3D;USD. If no currency code is specified, the store default currency is used.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentInstructionGetPaymentInfo: async (storeId: string, responseFormat?: string, currency?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('paymentInstructionGetPaymentInfo', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/cart/@self/payment_instruction`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }

            if (currency !== undefined) {
                localVarQueryParameter['currency'] = currency;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets payment instruction for the punch-out payment.
         * @summary Get punch-out payment instructions
         * @param {string} storeId The store identifier.
         * @param {string} orderId The order identifier.
         * @param {string} piId The payment instruction identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [profileName] The payment instruction ProfileName
         * @param {string} [currency] The currency code to use for example, currency&#x3D;USD. If no currency code is specified, the store default currency is used.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentInstructionGetPunchoutPaymentInfo: async (storeId: string, orderId: string, piId: string, responseFormat?: string, profileName?: string, currency?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('paymentInstructionGetPunchoutPaymentInfo', 'storeId', storeId)
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('paymentInstructionGetPunchoutPaymentInfo', 'orderId', orderId)
            // verify required parameter 'piId' is not null or undefined
            assertParamExists('paymentInstructionGetPunchoutPaymentInfo', 'piId', piId)
            const localVarPath = `/store/{storeId}/cart/@self/payment_instruction/punchoutPaymentInfo`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orderId !== undefined) {
                localVarQueryParameter['orderId'] = orderId;
            }

            if (piId !== undefined) {
                localVarQueryParameter['piId'] = piId;
            }

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }

            if (profileName !== undefined) {
                localVarQueryParameter['profileName'] = profileName;
            }

            if (currency !== undefined) {
                localVarQueryParameter['currency'] = currency;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets sensitive data mask information for plain string.
         * @summary Get sensitive data mask information
         * @param {string} storeId The store identifier.
         * @param {string} plainString The plain string for the sensitive data.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [profileName] Profile name. Profiles determine the subset of data returned by a query.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentInstructionGetSensitiveDataMaskByPlainString: async (storeId: string, plainString: string, responseFormat?: string, profileName?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('paymentInstructionGetSensitiveDataMaskByPlainString', 'storeId', storeId)
            // verify required parameter 'plainString' is not null or undefined
            assertParamExists('paymentInstructionGetSensitiveDataMaskByPlainString', 'plainString', plainString)
            const localVarPath = `/store/{storeId}/cart/@self/payment_instruction/sensitive_data_mask_by_plain_string/{plainString}`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"plainString"}}`, String(plainString));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }

            if (profileName !== undefined) {
                localVarQueryParameter['profileName'] = profileName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Processes punch-out call back requests.
         * @summary Process punch-out call back request
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentInstructionPunchoutPaymentCallBack: async (storeId: string, responseFormat?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('paymentInstructionPunchoutPaymentCallBack', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/cart/@self/payment_instruction/callback`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Processes the punch-out payment requests.
         * @summary Process payment punch-out request
         * @param {string} storeId The store identifier.
         * @param {string} orderId The order identifier.
         * @param {string} piId The payment instruction identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentInstructionRepay: async (storeId: string, orderId: string, piId: string, responseFormat?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('paymentInstructionRepay', 'storeId', storeId)
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('paymentInstructionRepay', 'orderId', orderId)
            // verify required parameter 'piId' is not null or undefined
            assertParamExists('paymentInstructionRepay', 'piId', piId)
            const localVarPath = `/store/{storeId}/cart/@self/payment_instruction/repay`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orderId !== undefined) {
                localVarQueryParameter['orderId'] = orderId;
            }

            if (piId !== undefined) {
                localVarQueryParameter['piId'] = piId;
            }

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates a payment instruction in the shopping cart.
         * @summary Update payment instructions
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {CartPaymentInstruction} [body] Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentInstructionUpdatePaymentInstruction: async (storeId: string, responseFormat?: string, body?: CartPaymentInstruction, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('paymentInstructionUpdatePaymentInstruction', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/cart/@self/payment_instruction`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets payment instructions for the shopping cart.
         * @summary fetch payment token data.
         * @param {string} storeId The store identifier.
         * @param {string} paymentToken payment_token
         * @param {string} paymentMethod payment_method
         * @param {string} [responseFormat] The response format. If the request has an input body, that body must also use the format specified in \&quot;responseFormat\&quot;. Valid values include \&quot;json\&quot; and \&quot;xml\&quot; without the quotes. If the responseFormat isn\&#39;t specified, the \&quot;accept\&quot; HTTP header shall be used to determine the format of the response. If the \&quot;accept\&quot; HTTP header isn\&#39;t specified as well, the default response format shall be in json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdCartSelfPaymentInstructionPaymentTokenGet: async (storeId: string, paymentToken: string, paymentMethod: string, responseFormat?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('storeStoreIdCartSelfPaymentInstructionPaymentTokenGet', 'storeId', storeId)
            // verify required parameter 'paymentToken' is not null or undefined
            assertParamExists('storeStoreIdCartSelfPaymentInstructionPaymentTokenGet', 'paymentToken', paymentToken)
            // verify required parameter 'paymentMethod' is not null or undefined
            assertParamExists('storeStoreIdCartSelfPaymentInstructionPaymentTokenGet', 'paymentMethod', paymentMethod)
            const localVarPath = `/store/{storeId}/cart/@self/payment_instruction/payment_token`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (paymentToken !== undefined) {
                localVarQueryParameter['payment_token'] = paymentToken;
            }

            if (paymentMethod !== undefined) {
                localVarQueryParameter['payment_method'] = paymentMethod;
            }

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets sensitive data mask information for plain string.
         * @summary Get sensitive data mask information
         * @param {string} storeId The store identifier.
         * @param {string} [profileName] The plain string for the sensitive data.
         * @param {string} [responseFormat] The response format. If the request has an input body, that body must also use the format specified in \&quot;responseFormat\&quot;. Valid values include \&quot;json\&quot; and \&quot;xml\&quot; without the quotes. If the responseFormat isn\&#39;t specified, the \&quot;accept\&quot; HTTP header shall be used to determine the format of the response. If the \&quot;accept\&quot; HTTP header isn\&#39;t specified as well, the default response format shall be in json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdCartSelfPaymentInstructionSensitiveDataMaskByPlainStringGet: async (storeId: string, profileName?: string, responseFormat?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('storeStoreIdCartSelfPaymentInstructionSensitiveDataMaskByPlainStringGet', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/cart/@self/payment_instruction/sensitive_data_mask_by_plain_string`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (profileName !== undefined) {
                localVarQueryParameter['profileName'] = profileName;
            }

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentInstructionApi - functional programming interface
 * @export
 */
export const PaymentInstructionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PaymentInstructionApiAxiosParamCreator(configuration)
    return {
        /**
         * Adds payment instructions to the shopping cart.
         * @summary Add payment instructions
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {CartPaymentInstruction} [body] The body data required for the REST method.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentInstructionAddPaymentInstruction(storeId: string, responseFormat?: string, body?: CartPaymentInstruction, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComIbmCommerceRestOrderHandlerPaymentInstructionHandlerPaymentInstructionList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentInstructionAddPaymentInstruction(storeId, responseFormat, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes all payment instructions from the shopping cart.
         * @summary Delete payment instructions
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentInstructionDeleteAllPaymentInstructions(storeId: string, responseFormat?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentInstructionDeleteAllPaymentInstructions(storeId, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes payment instructions from the shopping cart.
         * @summary Delete payment instructions
         * @param {string} storeId The store identifier.
         * @param {string} paymentInstructionId Payment instruction identifier to delete.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentInstructionDeletePaymentInstruction(storeId: string, paymentInstructionId: string, responseFormat?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentInstructionDeletePaymentInstruction(storeId, paymentInstructionId, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets payment instructions for the shopping cart.
         * @summary Get payment instructions
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [currency] The currency code to use for example, currency&#x3D;USD. If no currency code is specified, the store default currency is used.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentInstructionGetPaymentInfo(storeId: string, responseFormat?: string, currency?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CartPaymentInstruction>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentInstructionGetPaymentInfo(storeId, responseFormat, currency, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets payment instruction for the punch-out payment.
         * @summary Get punch-out payment instructions
         * @param {string} storeId The store identifier.
         * @param {string} orderId The order identifier.
         * @param {string} piId The payment instruction identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [profileName] The payment instruction ProfileName
         * @param {string} [currency] The currency code to use for example, currency&#x3D;USD. If no currency code is specified, the store default currency is used.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentInstructionGetPunchoutPaymentInfo(storeId: string, orderId: string, piId: string, responseFormat?: string, profileName?: string, currency?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CartPaymentInstruction>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentInstructionGetPunchoutPaymentInfo(storeId, orderId, piId, responseFormat, profileName, currency, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets sensitive data mask information for plain string.
         * @summary Get sensitive data mask information
         * @param {string} storeId The store identifier.
         * @param {string} plainString The plain string for the sensitive data.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [profileName] Profile name. Profiles determine the subset of data returned by a query.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentInstructionGetSensitiveDataMaskByPlainString(storeId: string, plainString: string, responseFormat?: string, profileName?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComIbmCommerceEdpBeansEDPSensitiveDataMaskHelperDataBeanIBMSensitiveDataMaskByPlainString>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentInstructionGetSensitiveDataMaskByPlainString(storeId, plainString, responseFormat, profileName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Processes punch-out call back requests.
         * @summary Process punch-out call back request
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentInstructionPunchoutPaymentCallBack(storeId: string, responseFormat?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentInstructionPunchoutPaymentCallBack(storeId, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Processes the punch-out payment requests.
         * @summary Process payment punch-out request
         * @param {string} storeId The store identifier.
         * @param {string} orderId The order identifier.
         * @param {string} piId The payment instruction identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentInstructionRepay(storeId: string, orderId: string, piId: string, responseFormat?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentInstructionRepay(storeId, orderId, piId, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates a payment instruction in the shopping cart.
         * @summary Update payment instructions
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {CartPaymentInstruction} [body] Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentInstructionUpdatePaymentInstruction(storeId: string, responseFormat?: string, body?: CartPaymentInstruction, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComIbmCommerceRestOrderHandlerPaymentInstructionHandlerPaymentInstructionList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentInstructionUpdatePaymentInstruction(storeId, responseFormat, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets payment instructions for the shopping cart.
         * @summary fetch payment token data.
         * @param {string} storeId The store identifier.
         * @param {string} paymentToken payment_token
         * @param {string} paymentMethod payment_method
         * @param {string} [responseFormat] The response format. If the request has an input body, that body must also use the format specified in \&quot;responseFormat\&quot;. Valid values include \&quot;json\&quot; and \&quot;xml\&quot; without the quotes. If the responseFormat isn\&#39;t specified, the \&quot;accept\&quot; HTTP header shall be used to determine the format of the response. If the \&quot;accept\&quot; HTTP header isn\&#39;t specified as well, the default response format shall be in json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storeStoreIdCartSelfPaymentInstructionPaymentTokenGet(storeId: string, paymentToken: string, paymentMethod: string, responseFormat?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storeStoreIdCartSelfPaymentInstructionPaymentTokenGet(storeId, paymentToken, paymentMethod, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets sensitive data mask information for plain string.
         * @summary Get sensitive data mask information
         * @param {string} storeId The store identifier.
         * @param {string} [profileName] The plain string for the sensitive data.
         * @param {string} [responseFormat] The response format. If the request has an input body, that body must also use the format specified in \&quot;responseFormat\&quot;. Valid values include \&quot;json\&quot; and \&quot;xml\&quot; without the quotes. If the responseFormat isn\&#39;t specified, the \&quot;accept\&quot; HTTP header shall be used to determine the format of the response. If the \&quot;accept\&quot; HTTP header isn\&#39;t specified as well, the default response format shall be in json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storeStoreIdCartSelfPaymentInstructionSensitiveDataMaskByPlainStringGet(storeId: string, profileName?: string, responseFormat?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComIbmCommerceEdpBeansEDPSensitiveDataMaskHelperDataBeanIBMSensitiveDataMaskByPlainString>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storeStoreIdCartSelfPaymentInstructionSensitiveDataMaskByPlainStringGet(storeId, profileName, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PaymentInstructionApi - factory interface
 * @export
 */
export const PaymentInstructionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PaymentInstructionApiFp(configuration)
    return {
        /**
         * Adds payment instructions to the shopping cart.
         * @summary Add payment instructions
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {CartPaymentInstruction} [body] The body data required for the REST method.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentInstructionAddPaymentInstruction(storeId: string, responseFormat?: string, body?: CartPaymentInstruction, options?: any): AxiosPromise<ComIbmCommerceRestOrderHandlerPaymentInstructionHandlerPaymentInstructionList> {
            return localVarFp.paymentInstructionAddPaymentInstruction(storeId, responseFormat, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes all payment instructions from the shopping cart.
         * @summary Delete payment instructions
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentInstructionDeleteAllPaymentInstructions(storeId: string, responseFormat?: string, options?: any): AxiosPromise<void> {
            return localVarFp.paymentInstructionDeleteAllPaymentInstructions(storeId, responseFormat, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes payment instructions from the shopping cart.
         * @summary Delete payment instructions
         * @param {string} storeId The store identifier.
         * @param {string} paymentInstructionId Payment instruction identifier to delete.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentInstructionDeletePaymentInstruction(storeId: string, paymentInstructionId: string, responseFormat?: string, options?: any): AxiosPromise<void> {
            return localVarFp.paymentInstructionDeletePaymentInstruction(storeId, paymentInstructionId, responseFormat, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets payment instructions for the shopping cart.
         * @summary Get payment instructions
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [currency] The currency code to use for example, currency&#x3D;USD. If no currency code is specified, the store default currency is used.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentInstructionGetPaymentInfo(storeId: string, responseFormat?: string, currency?: string, options?: any): AxiosPromise<CartPaymentInstruction> {
            return localVarFp.paymentInstructionGetPaymentInfo(storeId, responseFormat, currency, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets payment instruction for the punch-out payment.
         * @summary Get punch-out payment instructions
         * @param {string} storeId The store identifier.
         * @param {string} orderId The order identifier.
         * @param {string} piId The payment instruction identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [profileName] The payment instruction ProfileName
         * @param {string} [currency] The currency code to use for example, currency&#x3D;USD. If no currency code is specified, the store default currency is used.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentInstructionGetPunchoutPaymentInfo(storeId: string, orderId: string, piId: string, responseFormat?: string, profileName?: string, currency?: string, options?: any): AxiosPromise<CartPaymentInstruction> {
            return localVarFp.paymentInstructionGetPunchoutPaymentInfo(storeId, orderId, piId, responseFormat, profileName, currency, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets sensitive data mask information for plain string.
         * @summary Get sensitive data mask information
         * @param {string} storeId The store identifier.
         * @param {string} plainString The plain string for the sensitive data.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [profileName] Profile name. Profiles determine the subset of data returned by a query.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentInstructionGetSensitiveDataMaskByPlainString(storeId: string, plainString: string, responseFormat?: string, profileName?: string, options?: any): AxiosPromise<ComIbmCommerceEdpBeansEDPSensitiveDataMaskHelperDataBeanIBMSensitiveDataMaskByPlainString> {
            return localVarFp.paymentInstructionGetSensitiveDataMaskByPlainString(storeId, plainString, responseFormat, profileName, options).then((request) => request(axios, basePath));
        },
        /**
         * Processes punch-out call back requests.
         * @summary Process punch-out call back request
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentInstructionPunchoutPaymentCallBack(storeId: string, responseFormat?: string, options?: any): AxiosPromise<void> {
            return localVarFp.paymentInstructionPunchoutPaymentCallBack(storeId, responseFormat, options).then((request) => request(axios, basePath));
        },
        /**
         * Processes the punch-out payment requests.
         * @summary Process payment punch-out request
         * @param {string} storeId The store identifier.
         * @param {string} orderId The order identifier.
         * @param {string} piId The payment instruction identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentInstructionRepay(storeId: string, orderId: string, piId: string, responseFormat?: string, options?: any): AxiosPromise<void> {
            return localVarFp.paymentInstructionRepay(storeId, orderId, piId, responseFormat, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates a payment instruction in the shopping cart.
         * @summary Update payment instructions
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {CartPaymentInstruction} [body] Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentInstructionUpdatePaymentInstruction(storeId: string, responseFormat?: string, body?: CartPaymentInstruction, options?: any): AxiosPromise<ComIbmCommerceRestOrderHandlerPaymentInstructionHandlerPaymentInstructionList> {
            return localVarFp.paymentInstructionUpdatePaymentInstruction(storeId, responseFormat, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets payment instructions for the shopping cart.
         * @summary fetch payment token data.
         * @param {string} storeId The store identifier.
         * @param {string} paymentToken payment_token
         * @param {string} paymentMethod payment_method
         * @param {string} [responseFormat] The response format. If the request has an input body, that body must also use the format specified in \&quot;responseFormat\&quot;. Valid values include \&quot;json\&quot; and \&quot;xml\&quot; without the quotes. If the responseFormat isn\&#39;t specified, the \&quot;accept\&quot; HTTP header shall be used to determine the format of the response. If the \&quot;accept\&quot; HTTP header isn\&#39;t specified as well, the default response format shall be in json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdCartSelfPaymentInstructionPaymentTokenGet(storeId: string, paymentToken: string, paymentMethod: string, responseFormat?: string, options?: any): AxiosPromise<void> {
            return localVarFp.storeStoreIdCartSelfPaymentInstructionPaymentTokenGet(storeId, paymentToken, paymentMethod, responseFormat, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets sensitive data mask information for plain string.
         * @summary Get sensitive data mask information
         * @param {string} storeId The store identifier.
         * @param {string} [profileName] The plain string for the sensitive data.
         * @param {string} [responseFormat] The response format. If the request has an input body, that body must also use the format specified in \&quot;responseFormat\&quot;. Valid values include \&quot;json\&quot; and \&quot;xml\&quot; without the quotes. If the responseFormat isn\&#39;t specified, the \&quot;accept\&quot; HTTP header shall be used to determine the format of the response. If the \&quot;accept\&quot; HTTP header isn\&#39;t specified as well, the default response format shall be in json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdCartSelfPaymentInstructionSensitiveDataMaskByPlainStringGet(storeId: string, profileName?: string, responseFormat?: string, options?: any): AxiosPromise<ComIbmCommerceEdpBeansEDPSensitiveDataMaskHelperDataBeanIBMSensitiveDataMaskByPlainString> {
            return localVarFp.storeStoreIdCartSelfPaymentInstructionSensitiveDataMaskByPlainStringGet(storeId, profileName, responseFormat, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PaymentInstructionApi - object-oriented interface
 * @export
 * @class PaymentInstructionApi
 * @extends {BaseAPI}
 */
export class PaymentInstructionApi extends BaseAPI {
    /**
     * Adds payment instructions to the shopping cart.
     * @summary Add payment instructions
     * @param {string} storeId The store identifier.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {CartPaymentInstruction} [body] The body data required for the REST method.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentInstructionApi
     */
    public paymentInstructionAddPaymentInstruction(storeId: string, responseFormat?: string, body?: CartPaymentInstruction, options?: any) {
        return PaymentInstructionApiFp(this.configuration).paymentInstructionAddPaymentInstruction(storeId, responseFormat, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes all payment instructions from the shopping cart.
     * @summary Delete payment instructions
     * @param {string} storeId The store identifier.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentInstructionApi
     */
    public paymentInstructionDeleteAllPaymentInstructions(storeId: string, responseFormat?: string, options?: any) {
        return PaymentInstructionApiFp(this.configuration).paymentInstructionDeleteAllPaymentInstructions(storeId, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes payment instructions from the shopping cart.
     * @summary Delete payment instructions
     * @param {string} storeId The store identifier.
     * @param {string} paymentInstructionId Payment instruction identifier to delete.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentInstructionApi
     */
    public paymentInstructionDeletePaymentInstruction(storeId: string, paymentInstructionId: string, responseFormat?: string, options?: any) {
        return PaymentInstructionApiFp(this.configuration).paymentInstructionDeletePaymentInstruction(storeId, paymentInstructionId, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets payment instructions for the shopping cart.
     * @summary Get payment instructions
     * @param {string} storeId The store identifier.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {string} [currency] The currency code to use for example, currency&#x3D;USD. If no currency code is specified, the store default currency is used.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentInstructionApi
     */
    public paymentInstructionGetPaymentInfo(storeId: string, responseFormat?: string, currency?: string, options?: any) {
        return PaymentInstructionApiFp(this.configuration).paymentInstructionGetPaymentInfo(storeId, responseFormat, currency, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets payment instruction for the punch-out payment.
     * @summary Get punch-out payment instructions
     * @param {string} storeId The store identifier.
     * @param {string} orderId The order identifier.
     * @param {string} piId The payment instruction identifier.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {string} [profileName] The payment instruction ProfileName
     * @param {string} [currency] The currency code to use for example, currency&#x3D;USD. If no currency code is specified, the store default currency is used.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentInstructionApi
     */
    public paymentInstructionGetPunchoutPaymentInfo(storeId: string, orderId: string, piId: string, responseFormat?: string, profileName?: string, currency?: string, options?: any) {
        return PaymentInstructionApiFp(this.configuration).paymentInstructionGetPunchoutPaymentInfo(storeId, orderId, piId, responseFormat, profileName, currency, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets sensitive data mask information for plain string.
     * @summary Get sensitive data mask information
     * @param {string} storeId The store identifier.
     * @param {string} plainString The plain string for the sensitive data.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {string} [profileName] Profile name. Profiles determine the subset of data returned by a query.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentInstructionApi
     */
    public paymentInstructionGetSensitiveDataMaskByPlainString(storeId: string, plainString: string, responseFormat?: string, profileName?: string, options?: any) {
        return PaymentInstructionApiFp(this.configuration).paymentInstructionGetSensitiveDataMaskByPlainString(storeId, plainString, responseFormat, profileName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Processes punch-out call back requests.
     * @summary Process punch-out call back request
     * @param {string} storeId The store identifier.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentInstructionApi
     */
    public paymentInstructionPunchoutPaymentCallBack(storeId: string, responseFormat?: string, options?: any) {
        return PaymentInstructionApiFp(this.configuration).paymentInstructionPunchoutPaymentCallBack(storeId, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Processes the punch-out payment requests.
     * @summary Process payment punch-out request
     * @param {string} storeId The store identifier.
     * @param {string} orderId The order identifier.
     * @param {string} piId The payment instruction identifier.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentInstructionApi
     */
    public paymentInstructionRepay(storeId: string, orderId: string, piId: string, responseFormat?: string, options?: any) {
        return PaymentInstructionApiFp(this.configuration).paymentInstructionRepay(storeId, orderId, piId, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates a payment instruction in the shopping cart.
     * @summary Update payment instructions
     * @param {string} storeId The store identifier.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {CartPaymentInstruction} [body] Request body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentInstructionApi
     */
    public paymentInstructionUpdatePaymentInstruction(storeId: string, responseFormat?: string, body?: CartPaymentInstruction, options?: any) {
        return PaymentInstructionApiFp(this.configuration).paymentInstructionUpdatePaymentInstruction(storeId, responseFormat, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets payment instructions for the shopping cart.
     * @summary fetch payment token data.
     * @param {string} storeId The store identifier.
     * @param {string} paymentToken payment_token
     * @param {string} paymentMethod payment_method
     * @param {string} [responseFormat] The response format. If the request has an input body, that body must also use the format specified in \&quot;responseFormat\&quot;. Valid values include \&quot;json\&quot; and \&quot;xml\&quot; without the quotes. If the responseFormat isn\&#39;t specified, the \&quot;accept\&quot; HTTP header shall be used to determine the format of the response. If the \&quot;accept\&quot; HTTP header isn\&#39;t specified as well, the default response format shall be in json.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentInstructionApi
     */
    public storeStoreIdCartSelfPaymentInstructionPaymentTokenGet(storeId: string, paymentToken: string, paymentMethod: string, responseFormat?: string, options?: any) {
        return PaymentInstructionApiFp(this.configuration).storeStoreIdCartSelfPaymentInstructionPaymentTokenGet(storeId, paymentToken, paymentMethod, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets sensitive data mask information for plain string.
     * @summary Get sensitive data mask information
     * @param {string} storeId The store identifier.
     * @param {string} [profileName] The plain string for the sensitive data.
     * @param {string} [responseFormat] The response format. If the request has an input body, that body must also use the format specified in \&quot;responseFormat\&quot;. Valid values include \&quot;json\&quot; and \&quot;xml\&quot; without the quotes. If the responseFormat isn\&#39;t specified, the \&quot;accept\&quot; HTTP header shall be used to determine the format of the response. If the \&quot;accept\&quot; HTTP header isn\&#39;t specified as well, the default response format shall be in json.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentInstructionApi
     */
    public storeStoreIdCartSelfPaymentInstructionSensitiveDataMaskByPlainStringGet(storeId: string, profileName?: string, responseFormat?: string, options?: any) {
        return PaymentInstructionApiFp(this.configuration).storeStoreIdCartSelfPaymentInstructionSensitiveDataMaskByPlainStringGet(storeId, profileName, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }
}
