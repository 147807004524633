/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
import { createReducer, AnyAction } from "@reduxjs/toolkit";
import initStates from "./initStates";
import { KitchensState } from "./reducerStateInterface";
import {
  KITCHENS_GET_REQUEST_ACTION,
  KITCHENS_GET_SUCCESS_ACTION,
  KITCHENS_GET_ERROR_ACTION,
} from "../actions/kitchens";

/**
 * User reducer
 * handles states used by user related components
 * @param state State object managed by user reducer
 * @param action The dispatched action
 */
const storesReducer = createReducer(initStates.kitchens, (builder) => {
  builder.addCase(KITCHENS_GET_REQUEST_ACTION, (state: KitchensState, action: AnyAction) => {
    state.isLoading = true;
  });

  builder.addCase(KITCHENS_GET_SUCCESS_ACTION, (state: KitchensState, action: AnyAction) => {
    state.kitchens = action.payload.kitchensData;
    state.isLoading = false;
  });

  builder.addCase(KITCHENS_GET_ERROR_ACTION, (state: KitchensState, action: AnyAction) => {
    state.isLoading = false;
  });
});

export default storesReducer;
