/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Services - Account and Contract
 * These services provide APIs to manage accounts and contracts.  a contract is an agreement that represents the terms and conditions that apply to a transaction. An account is a relationship between the merchant and the financial institution that processes transactions for that merchant.
 *
 * The version of the OpenAPI document: 9.1.6
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 * @interface DemandeSAVRequest
 */
export interface DemandeSAVRequest {
    /**
     * Civilité du client
     * @type {string}
     * @memberof DemandeSAVRequest
     */
    civilite?: DemandeSAVRequestCiviliteEnum;
    /**
     * First name of the requester.
     * @type {string}
     * @memberof DemandeSAVRequest
     */
    nom: string;
    /**
     * First name of the requester.
     * @type {string}
     * @memberof DemandeSAVRequest
     */
    prenom?: string;
    /**
     * Phone number of the requester.
     * @type {string}
     * @memberof DemandeSAVRequest
     */
    telephone: string;
    /**
     * Email of the requester.
     * @type {string}
     * @memberof DemandeSAVRequest
     */
    email: string;
    /**
     * Comment of the request.
     * @type {string}
     * @memberof DemandeSAVRequest
     */
    commentaire: string;
    /**
     * Store code of the request.
     * @type {string}
     * @memberof DemandeSAVRequest
     */
    codeMagasin: string;
    /**
     * Code of the bill.
     * @type {string}
     * @memberof DemandeSAVRequest
     */
    codeFacture?: string;
    /**
     * Code of the article.
     * @type {string}
     * @memberof DemandeSAVRequest
     */
    codeArticle?: string;
    /**
     * Object of the request
     * @type {string}
     * @memberof DemandeSAVRequest
     */
    objet?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum DemandeSAVRequestCiviliteEnum {
    Mr = 'Mr',
    Mme = 'Mme',
    MrEtMme = 'Mr et Mme'
}



