/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Services - Account and Contract
 * These services provide APIs to manage accounts and contracts.  a contract is an agreement that represents the terms and conditions that apply to a transaction. An account is a relationship between the merchant and the financial institution that processes transactions for that merchant.
 *
 * The version of the OpenAPI document: 9.1.6
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 * @interface OrdersFilteringRequest
 */
export interface OrdersFilteringRequest {
    /**
     * The size of the page to be returned
     * @type {string}
     * @memberof OrdersFilteringRequest
     */
    size?: string;
    /**
     * Filter the result list by period of creation date of document ((y/Y = year, m/M = month and d/D = day) ex : [ (2y : 2 years) or (2m : 2 months) or (2d : 2 days) ]
     * @type {string}
     * @memberof OrdersFilteringRequest
     */
    period?: string;
    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof OrdersFilteringRequest
     */
    page?: number;
    /**
     * Filter the result list by orderStatus. Possible values PREP/DISP
     * @type {string}
     * @memberof OrdersFilteringRequest
     */
    orderstatus?: OrdersFilteringRequestOrderstatusEnum;
    /**
     * List of document IDs to filter the results. If provided, ignores pagination and returns a single page with all matching orders.
     * @type {Array<any>}
     * @memberof OrdersFilteringRequest
     */
    documentIds?: Array<any>;
}

/**
    * @export
    * @enum {string}
    */
export enum OrdersFilteringRequestOrderstatusEnum {
    Prep = 'PREP',
    Disp = 'DISP'
}



