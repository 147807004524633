/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Services - Account and Contract
 * These services provide APIs to manage accounts and contracts.  a contract is an agreement that represents the terms and conditions that apply to a transaction. An account is a relationship between the merchant and the financial institution that processes transactions for that merchant.
 *
 * The version of the OpenAPI document: 9.1.6
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../../base';
// @ts-ignore
import { FetchInventory } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { FetchInventoryResponse } from '../../../../../com/hcl/commerce/typescript/axios';
/**
 * InventoryDetailsApi - axios parameter creator
 * @export
 */
export const InventoryDetailsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Checks inventory details of the items in stores provided in input
         * @summary Fetch inventory
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {FetchInventory} [body] The body data required for the REST method.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryFetchInventory: async (storeId: string, responseFormat?: string, body?: FetchInventory, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('inventoryFetchInventory', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/lapeyre/getInventoryAvailability`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InventoryDetailsApi - functional programming interface
 * @export
 */
export const InventoryDetailsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InventoryDetailsApiAxiosParamCreator(configuration)
    return {
        /**
         * Checks inventory details of the items in stores provided in input
         * @summary Fetch inventory
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {FetchInventory} [body] The body data required for the REST method.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inventoryFetchInventory(storeId: string, responseFormat?: string, body?: FetchInventory, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FetchInventoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inventoryFetchInventory(storeId, responseFormat, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InventoryDetailsApi - factory interface
 * @export
 */
export const InventoryDetailsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InventoryDetailsApiFp(configuration)
    return {
        /**
         * Checks inventory details of the items in stores provided in input
         * @summary Fetch inventory
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {FetchInventory} [body] The body data required for the REST method.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inventoryFetchInventory(storeId: string, responseFormat?: string, body?: FetchInventory, options?: any): AxiosPromise<FetchInventoryResponse> {
            return localVarFp.inventoryFetchInventory(storeId, responseFormat, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InventoryDetailsApi - object-oriented interface
 * @export
 * @class InventoryDetailsApi
 * @extends {BaseAPI}
 */
export class InventoryDetailsApi extends BaseAPI {
    /**
     * Checks inventory details of the items in stores provided in input
     * @summary Fetch inventory
     * @param {string} storeId The store identifier.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {FetchInventory} [body] The body data required for the REST method.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InventoryDetailsApi
     */
    public inventoryFetchInventory(storeId: string, responseFormat?: string, body?: FetchInventory, options?: any) {
        return InventoryDetailsApiFp(this.configuration).inventoryFetchInventory(storeId, responseFormat, body, options).then((request) => request(this.axios, this.basePath));
    }
}
