import styled from "@mui/styled-engine-sc";
import SvgIcon from "@mui/material/SvgIcon";

const CustomSvg = styled((props) => (
  <SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} />
))`
  ${({ theme }) => `
    width:16px;
    height:16px;

    &.error {
      width: ${theme.spacing(2)};
      height: ${theme.spacing(2)};
      margin: 1px ${theme.spacing(1)} 0 ${theme.spacing(0)};
      color: ${theme.palette.primary.main}
    }

    &.small {
      width:13px;
      height:13px;
    }
  `}
`;

export const SvgClose = (props) => {
  const { className = "" } = props;

  return (
    <CustomSvg {...{ className }}>
      <path d="M7 8.38298L2.15957 13.2234C1.98176 13.4012 1.74468 13.5 1.46809 13.5C1.19149 13.5 0.954407 13.4111 0.776596 13.2234C0.598784 13.0357 0.5 12.8085 0.5 12.5319C0.5 12.2553 0.588906 12.0182 0.776596 11.8404L5.61702 7L0.776596 2.15957C0.598784 1.98176 0.5 1.74468 0.5 1.46809C0.5 1.19149 0.588906 0.954407 0.776596 0.776596C0.964286 0.598784 1.19149 0.5 1.46809 0.5C1.74468 0.5 1.98176 0.588906 2.15957 0.776596L7 5.61702L11.8404 0.776596C12.0182 0.598784 12.2553 0.5 12.5319 0.5C12.8085 0.5 13.0456 0.588906 13.2234 0.776596C13.4012 0.964286 13.5 1.19149 13.5 1.46809C13.5 1.74468 13.4111 1.98176 13.2234 2.15957L8.38298 7L13.2234 11.8404C13.4012 12.0182 13.5 12.2553 13.5 12.5319C13.5 12.8085 13.4111 13.0456 13.2234 13.2234C13.0357 13.4012 12.8085 13.5 12.5319 13.5C12.2553 13.5 12.0182 13.4111 11.8404 13.2234L7 8.38298Z" />
    </CustomSvg>
  );
};
