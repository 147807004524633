/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import { createReducer, AnyAction } from "@reduxjs/toolkit";
//Redux
import * as ACTIONS from "../action-types/account";
import initStates from "./initStates";
import { LOGOUT_SUCCESS_ACTION } from "../actions/user";
import formsUtil from "../../utils/formsUtil";
import {
  DELETE_ACCOUNT_ERROR_ACTION,
  DELETE_ACCOUNT_REQUEST_ACTION,
  DELETE_ACCOUNT_SUCCESS_ACTION,
} from "../actions/account";

/**
 * Account reducer
 * handles states used by account related components
 * @param state State object managed by account reducer
 * @param action The dispatched action
 */
const accountReducer = createReducer(initStates.account, (builder) => {
  builder.addCase(ACTIONS.ORDERS_GET_SUCCESS, (state, action: AnyAction) => {
    state.orders = action.response;
  });
  builder.addCase(ACTIONS.ADDRESS_DETAILS_GET_REQUESTED, (state, action: AnyAction) => {
    state.isLoading = true;
  });
  builder.addCase(ACTIONS.ADDRESS_DETAILS_GET_SUCCESS, (state, action: AnyAction) => {
    const response = action.response;
    //Convert phoneNumber
    let newPerson = { ...response, phone1: formsUtil.getPhoneNumberWithIndicator(response?.phone1, response?.country) };
    if (response) {
      const contactMap = {};
      const contactList = response.contact;
      if (contactList) {
        contactList.forEach((address: any, index: number) => {
          if (address && address.addressId) {
            contactMap[address.addressId] = address;
          }
        });

        const { contact, ...person } = response;
        newPerson = {
          ...person,
          contactMap,
          contactList,
        };
      }
      state.address = newPerson;
    }
    state.isLoading = false;
  });
  builder.addCase(ACTIONS.ADDRESS_DETAILS_GET_ERROR, (state, action: AnyAction) => {
    state.isLoading = false;
  });
  builder.addCase(ACTIONS.ACCOUNT_DETAILS_PUT_REQUESTED, (state, action: AnyAction) => {
    state.isLoading = true;
    state.accountUpdated = false;
  });
  builder.addCase(ACTIONS.ACCOUNT_DETAILS_PUT_SUCCESS, (state, action: AnyAction) => {
    state.isLoading = false;
    state.accountUpdated = true;
  });
  builder.addCase(ACTIONS.ACCOUNT_DETAILS_PUT_ERROR, (state, action: AnyAction) => {
    state.isLoading = false;
    state.accountUpdated = false;
  });
  builder.addCase(LOGOUT_SUCCESS_ACTION, (state, action: AnyAction) => {
    state.address = null;
    state.orders = null;
  });
  builder.addCase(DELETE_ACCOUNT_REQUEST_ACTION, (state) => {
    state.isLoading = true;
  });
  builder.addCase(DELETE_ACCOUNT_SUCCESS_ACTION, (state) => {
    state.isLoading = false;
  });
  builder.addCase(DELETE_ACCOUNT_ERROR_ACTION, (state) => {
    state.isLoading = false;
  });
});

export default accountReducer;
