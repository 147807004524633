import React from "react";
import { StyledMenuItem } from "@hcl-commerce-store-sdk/react-component";
import AsyncCall from "../../_foundation/gtm/async.service";
import { StyledMenuTypography, StyledButton, StyledLink } from "../../styled-mui";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { CLICK } from "../../_foundation/constants/gtm";
interface MegaMenuColumnProps {
  page: any;
  activeMenuId: { child0: undefined; child1: undefined; child2: undefined };
  setActiveMenuId: any;
  activeParentMenuId: number | undefined;
  setActiveParentMenuId: any;
  closeMegaMenu: () => void;
  level: number;
  parentId: number | undefined;
  breadcrumbs?: any[];
}

/**
 * MegaMenu component
 * displays top category links in desktop/mobile view
 * @param props
 */
const MegaMenuColumn: React.FC<MegaMenuColumnProps> = (props: any) => {
  const { page, activeMenuId, setActiveMenuId, activeParentMenuId, setActiveParentMenuId, level, closeMegaMenu } =
    props;
  const id = page.id;
  // const { mySite } = useSite();

  const icon = page.children && level !== 3 ? <ChevronRightIcon /> : "";

  const onClick = () => {
    //GA360
    // if (mySite.enableGA) {
    //   AsyncCall.sendNavigationClick(
    //     { eventAction: "Main", eventLabel: page.name },
    //     {
    //       enableUA: mySite.enableUA,
    //       enableGA4: mySite.enableGA4,
    //     }
    //   );
    // }
    AsyncCall.sendClick(CLICK.NAV, { navclick: page.name });
    setActiveMenuId({ ...activeMenuId, ["child" + level]: id });
    setActiveParentMenuId(activeParentMenuId !== id ? id : null);
  };

  return page.children && level !== 3 ? (
    <StyledButton
      fullWidth
      variant="text"
      endIcon={icon}
      color="black"
      className="menu-mobile-button"
      onClick={onClick}>
      <StyledMenuItem>
        <StyledMenuTypography variant="body1" sx={{ textTransform: "uppercase" }}>
          {page.name}
        </StyledMenuTypography>
      </StyledMenuItem>
    </StyledButton>
  ) : (
    <StyledLink
      variant="text"
      className="menu-mobile-button"
      onClick={() => {
        AsyncCall.sendClick(CLICK.NAV, { navlink: page.seo.href });
        closeMegaMenu();
      }}
      testId={`header-${page.id}`}
      key={"Link_" + page.id}
      to={page.seo?.href}
      state={{
        breadCrumbTrailEntryView: [
          { label: page.name, value: page.id, seo: page.seo },
          {
            label: page.name,
            value: page.id,
            seo: page.seo,
          },
        ],
      }}>
      <StyledMenuItem>
        <StyledMenuTypography variant="body1" sx={{ textTransform: "uppercase" }}>
          {page.name}
        </StyledMenuTypography>
      </StyledMenuItem>
    </StyledLink>
  );
};

export default MegaMenuColumn;
