export const HOME = "/";
export const PROHIBITED_CHAR_ERROR_VIEW = "/ProhibitedCharacterErrorView";

//New Page
export const RHINOVFORM = "/c/offre-rhinov";
export const MAGAZINE = "/c/magazine/*";
export const CONTENT = "/c/*";
export const COLLECTION = "/collection/:collectionId";
export const REALIZATION = "/realisation/:realisationId";
export const CONFIGURATOR_HUB = "/outils";
export const FAQ = "/faq";
export const CONTACT = "/nous-contacter";
export const PRISEDERDV = "/prise-de-rdv";
export const JAIUNPROJET = "/j-ai-un-projet";
export const AMORCEPROJET = "/amorce-projet";
export const MODELSLIST_BIS = "/produits/cuisine-electromenager/modele-cuisine/tous-les-meubles-cuisine";
export const KITCHEN_MODEL = "/produits/:universId/modele-cuisine/:modelesCuisineId";
export const MODELSLIST = "/tous-les-meubles-cuisine";
export const SAV = "/sav";
export const SAVCLAIM = "/sav/claim";
export const HUBPROMO = "/bons-plans";
export const PROMOTIONPAGE = "/toutes-les-promotions";

//Order
export const CART = "/cart";
export const CHECKOUT = "/checkout";
export const CHECKOUT_SHIPPING = `shipping`;
export const CHECKOUT_PAYMENT = `payment`;
export const CHECKOUT_REVIEW = `review`;
export const CHECKOUT_PICKUP = `pickup`;
export const CHECKOUT_PICKUP_STORE = `pickup-store`;
export const ORDER_CONFIRMATION = "/order-confirmation";
export const ORDER_HISTORY = "/order-history";
export const RECURRING_ORDERS = "/recurring-orders";
export const INPROGRESS_ORDERS = "/inprogress-orders";
export const REQUISITION_LISTS = "/requisition-lists";
export const VIEW_UPLOAD_LOGS = "/view-upload-logs";
export const ORDER_DETAILS = "/order-details";
export const ORDER_ID_PARAM = "/:orderId";
export const ORDER_DETAILS_ROUTE = `${ORDER_DETAILS}${ORDER_ID_PARAM}`;
export const LIST_ID_PARAM = "/:orderId";
export const ORDER_DRIVE = `/Drive`;
export const MY_ORDERS = "/account/my-orders";

//User
export const ADDRESS_BOOK = "/address-book";
export const ADD_ADDRESS = "/add-address";
export const EDIT_ADDRESS = "/edit-address";
export const ADDRESS_ID_PARAM = "/:addressId";
export const EDIT_ADDRESS_ROUTE = `${EDIT_ADDRESS}${ADDRESS_ID_PARAM}`;

//Account
export const SIGNIN = "/LogonForm";
export const REGISTRATION = "/UserRegistrationForm";
export const ACCOUNT = "/account";
export const FORGOT_PASSWORD = "/ForgotPasswordForm";
export const RESET_PASSWORD = "/ResetPasswordForm";
//Search
export const SEARCH = "/search";

//register user access only
export const REGISTER_PROTECTED = "register";
//only guest and generic user access
export const GENERIC_PROTECTED = "generic";
//SignIn before access to page
export const SIGNIN_PROTECTED = "signin-reg";
// marketplace store access and only when seller-reg access is enabled
//export const MP_SELLER_REG_PROTECTED = "marketplace-seller-reg";

// in-progress orders + items
export const IP_ORDER_DETAILS = "/inprogress-order-details";
export const IP_ORDER_DETAILS_ROUTE = `${IP_ORDER_DETAILS}${ORDER_ID_PARAM}`;

// Reqisition List + items
export const REQUISITION_LIST_DETAILS = "/requisition-list-details";
export const REQUISITION_LIST_DETAILS_ROUTE = `${REQUISITION_LIST_DETAILS}${LIST_ID_PARAM}`;

export const DELIVERY_STEPS = ["cart", "shipping", "payment", "confirmation"];
