import styled from "@mui/styled-engine-sc";
import { ListSubheader } from "@mui/material";

/**
 * Styled component over @material-ui
 * @see https://material-ui.com/api/list/
 */
const StyledListSubheader = styled(({ ...props }) => <ListSubheader {...props} />)`
  ${({ theme }) => `
    line-height: normal;
    font-weight: 700;
    padding: 0 ${theme.spacing(3)} ${theme.spacing(2)} ${theme.spacing(3)};

    &.padding-top {
      padding-top: ${theme.spacing(3)};
    }
  `}
`;

export { StyledListSubheader };
