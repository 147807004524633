/*
 *---------------------------------------------------
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *---------------------------------------------------
 */
//Foundation libraries
//Redux
import { DEFAULT_LOCATION, GOOGLEMAPZOOM } from "../../_foundation/constants/common";
import { modalType } from "../../types/store-types";
import {
  AccountReducerState,
  CatalogReducerState,
  OrderReducerState,
  ErrorReducerState,
  SearchReducerState,
  OrderDetailsMapReducerState,
  ApiReducerState,
  CategoryReducerState,
  SellerInfoState,
  CountryReducerState,
  StoresState,
  KitchensState,
  UniversState,
  PushsState,
} from "./reducerStateInterface";

const accountDefaultState: AccountReducerState = {
  orders: null,
  address: null,
  isLoading: false,
  accountUpdated: false,
};

const catalogDefaultState: CatalogReducerState = {
  productList: [],
  productListTotal: -1,
  priceMode: "1",
  facets: null,
  facetPrice: null,
  selectedFacets: {},
  selectedFacetLimits: [],
  selectedFacetPrices: { min: -1, max: -1 },
  promotionEnabled: false,
  selectedPageOffset: 1,
  pageNum: 1,
  selectedSortOption: "", //this is orderBy in PLP payload, init state here doesn't matter as it's overridden by category identifier before making call on PLP.
  breadcrumbs: [],
  selectFacetRemove: false,
  productCache: {
    byId: {},
    container: [],
    idx: 0,
    MAX: 36,
  },
  possiblePricesRange: [],
  catalogueURL: "",
  isFetching: null,
};
const orderDefaultState: OrderReducerState = {
  cart: null,
  numItems: 0,
  itemsAdded: 0,
  orderItems: [],
  catentries: null,
  isCheckoutDisabled: false,
  shipInfos: null,
  shipModes: [],
  payMethods: [],
  isRecurringOrderDisabled: false,
  allowableShipModes: [],
  activeInprogressOrder: null,
  allowablePaymethods: [],
  orderMethodIsPickup: true,
  pickupPerson: null,
  billingAddress: null,
  isLoading: false,
  orderMethod: null,
  cartModalOpen: false,
  cartItemAdded: [],
  simulationSofinco: null,
  availabilityInStoreLoading: false,
  availabilityInStore: null,
  availabilityInStoreError: false,
  availabilityShippingLoading: false,
  availabilityShipping: null,
  availabilityShippingError: false,
};
const errorDefaultState: ErrorReducerState = {
  errorKey: null,
  errorCode: null,
  errorTitleKey: null,
  errorMsgKey: null,
  handled: null,
  errorMessage: "",
  errorParameters: "",
};

const categoriesDefaultState: CategoryReducerState = {
  categories: [],
  staticLinks: [],
  currentCategory: null,
  topCategories: [],
  filteredCategories: [],
};

const searchDefaultState: SearchReducerState = {
  keywords: [],
};

const orderDetails: OrderDetailsMapReducerState = {};

const apiDefaultState: ApiReducerState = {
  apiFlowList: [],
};

const sellerDefaultState: SellerInfoState = {
  showSellerList: false,
  showSellerFacets: false,
  sellers: [],
  langId: -1,
};

const countryDefaultState: CountryReducerState = {
  countries: [],
};

export const storesDefaultState: StoresState = {
  storeLocatorModalOpen: false,
  storeLocatorModalType: modalType.MapsFav,
  productData: null,
  nearestStores: [],
  availabilities: [],
  userFavStores: [],
  isLoadingUserFavStores: false,
  storesList: [],
  map: { center: DEFAULT_LOCATION, zoom: GOOGLEMAPZOOM.INIT, storeIndex: null },
  isLoadingStoresList: false,
};

export const kitchensDefaultState: KitchensState = {
  isLoading: false,
  kitchens: null,
};

export const universDefaultState: UniversState = {
  isLoading: false,
  univers: null,
};

export const pushsDefaultState: PushsState = {
  isLoading: false,
  pushs: null,
};

export const defaultStates = {
  account: accountDefaultState,
  api: apiDefaultState,
  catalog: catalogDefaultState,
  order: orderDefaultState,
  user: { initiatedFromStorage: false, isLoading: false, details: { zipCode: null } },
  error: errorDefaultState,
  seo: {},
  contract: {},
  search: searchDefaultState,
  organization: {},
  context: {},
  success: {},
  confirmation: {},
  recurringOrder: { resultList: [] },
  orderDetails,
  checkoutProfile: { curUserProfiles: [] },
  site: { currentSite: null },
  categories: categoriesDefaultState,
  sellers: sellerDefaultState,
  countries: countryDefaultState,
  stores: storesDefaultState,
  kitchens: kitchensDefaultState,
  univers: universDefaultState,
  pushs: pushsDefaultState,
};

export const clearState = (o: any) => {
  for (const variableKey in o) {
    if (Object.prototype.hasOwnProperty.call(o, variableKey)) {
      delete o[variableKey];
    }
  }
};

const initStates = {
  ...defaultStates,
};
export default initStates;
