//Standard libraries
import React, { useEffect, useState } from "react";
//Custom libraries
import MegaMenuColumn from "./MegaMenuColumn";
//UI
import { StyledGrid } from "@hcl-commerce-store-sdk/react-component";
import {
  StyledBox,
  StyledButton,
  StyledIconButton,
  StyledLink,
  StyledSwipeableDrawer,
  StyledTypography,
} from "../../styled-mui";
import { SvgArrowBack } from "../svg/arrowBack";
import { SvgClose } from "../svg/close";
import { SvgContactUs } from "../svg/contactUs";
import { SvgMyProject } from "../svg/myProject";
import HeaderNavigationWidget from "../commerce-widgets/header-navigation-widget/header-navigation-widget";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { ImageLayout } from "../elements/image";
import { site } from "../../_foundation/constants/site";
import { Link } from "react-router-dom";
import { HUBPROMO, JAIUNPROJET, PRISEDERDV } from "../../constants/routes";
import { StoreLocatorButton } from "./storeLocatorButton";
import { useTranslation } from "react-i18next";
import AsyncCall from "../../_foundation/gtm/async.service";
import { CLICK } from "../../_foundation/constants/gtm";

interface MegaMenuProps {
  parent?: any;
  topCategories?: any;
  subMenuPage?: any;
  className?: string;
  open?: any;
  onOpen?: any;
  closeMegaMenu?: any;
  lvl?: number;
}

/**
 * MegaMenu component
 * displays top category links in desktop/mobile view
 * @param props
 */
const MegaMenu: React.FC<MegaMenuProps> = ({ topCategories, closeMegaMenu = null, open }) => {
  const { t } = useTranslation();
  const initMenu = { child0: undefined, child1: undefined, child2: undefined };
  const [activeMenuId, setActiveMenuId] = useState(initMenu);
  const [activeParentMenuId, setActiveParentMenuId] = useState<number | undefined>();
  const [productNav, setProductNav] = useState<any | null>(null);
  const [collectionMenu, setCollectionMenu] = useState(false);

  const mockCollection = {
    name: "Nos collections",
    identifier: "CCU3099822bis",
    shortDescription: "Collection",
    storeID: "21",
    sequence: "11446.0",
    children: [
      {
        seo: {
          href: "/collection/classique",
        },
        name: "Classique",
        image: `${site.defaultLocalImageUrl}/images/collection/classique.jpg`,
      },
      {
        seo: {
          href: "/collection/essentielle",
        },
        name: "Essentielle",
        image: `${site.defaultLocalImageUrl}/images/collection/essentielle.jpg`,
      },
      {
        seo: {
          href: "/collection/campagne",
        },
        name: "Campagne",
        image: `${site.defaultLocalImageUrl}/images/collection/campagne.jpg`,
      },
      {
        seo: {
          href: "/collection/contemporaine",
        },
        name: "contemporaine",
        image: `${site.defaultLocalImageUrl}/images/collection/contemporaine.jpg`,
      },
      {
        seo: {
          href: "/collection/nordique",
        },
        name: "Nordique",
        image: `${site.defaultLocalImageUrl}/images/collection/nordique.jpg`,
      },
      {
        seo: {
          href: "/collection/deco",
        },
        name: "Déco",
        image: `${site.defaultLocalImageUrl}/images/collection/deco.jpg`,
      },
      {
        seo: {
          href: "/collection/industrielle",
        },
        name: "Industrielle",
        image: `${site.defaultLocalImageUrl}/images/collection/industrielle.jpg`,
      },
      {
        seo: {
          href: "/collection/chalet",
        },
        name: "Chalet",
        image: `${site.defaultLocalImageUrl}/images/collection/chalet.jpg`,
      },
      {
        seo: {
          href: "/collection/marine",
        },
        name: "Marine",
        image: `${site.defaultLocalImageUrl}/images/collection/marine.jpg`,
      },
      {
        seo: {
          href: "/collection/studio",
        },
        name: "Studio",
        image: `${site.defaultLocalImageUrl}/images/collection/studio.jpg`,
      },
    ],
    id: "3074457345616678174bis",
    description: "Équipement & Outil",
  };
  const trackEvent = (link, text) => {
    AsyncCall.sendClick(CLICK.NAV, {
      navlink: text + "_" + link,
    });

    callCloseMegaMenu();
  };
  const callCloseMegaMenu = () => {
    setActiveMenuId(initMenu);
    setCollectionMenu(false);
    closeMegaMenu();
  };

  const closeChildren = () => {
    let dataMenu = { ...activeMenuId };
    if (activeMenuId.child2 !== undefined) {
      dataMenu = { ...dataMenu, child2: undefined };
    } else if (activeMenuId.child1 !== undefined) {
      dataMenu = { ...dataMenu, child1: undefined };
    } else {
      dataMenu = { ...initMenu };
    }
    setActiveMenuId(dataMenu);
    setCollectionMenu(false);
  };

  const turnOnOpen = () => {
    //console.log("activeMenuId", activeMenuId);
  };

  const ChildrenMenu = ({ level }) => {
    let activePage: any = null;
    if (level >= 0 && activeMenuId.child0 !== undefined) {
      activePage = productNav;
      if (level >= 1 && activeMenuId.child1 !== undefined) {
        activePage = activePage?.children?.filter((page) => page.id === activeMenuId.child1)[0];
        if (level >= 2 && activeMenuId.child2 !== undefined) {
          activePage = activePage?.children?.filter((page) => page.id === activeMenuId.child2)[0];
        }
      }
    }
    if (collectionMenu) {
      activePage = mockCollection;
    }
    return (
      <StyledGrid container className={"menu-container"}>
        <StyledGrid container p={5} className="menu-mobile-navigation-top">
          <StyledGrid item pl={0} py={1} pr={6} className="menu-mobile-navigation-top-svg-back">
            <StyledIconButton onClick={closeChildren}>
              <SvgArrowBack color="black" className="" />
            </StyledIconButton>
          </StyledGrid>
          <StyledGrid item pl={5} py={1} sx={{ maxWidth: "68%" }}>
            <StyledTypography
              variant="h5"
              sx={{ textTransform: "none", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
              {activePage?.name}
            </StyledTypography>
          </StyledGrid>
          <StyledGrid item py={1} className="menu-mobile-navigation-top-svg-close">
            <StyledIconButton onClick={callCloseMegaMenu}>
              <SvgClose color="black" className="" />
            </StyledIconButton>
          </StyledGrid>
        </StyledGrid>
        {collectionMenu && (
          <>
            <StyledGrid container p={5} rowSpacing={1}>
              {mockCollection.children?.map((collection, index) => (
                <StyledGrid item xs={12} key={index}>
                  <StyledLink to={collection.seo.href} onClick={callCloseMegaMenu}>
                    <StyledBox className="collection-menu-box">
                      <ImageLayout
                        src={collection.image}
                        alt={""}
                        width="100%"
                        height={120}
                        className="collection-menu-image"
                      />
                      <StyledTypography component="p" variant="h6" color="white.main" className="collection-menu-text">
                        {collection.name}
                      </StyledTypography>
                    </StyledBox>
                  </StyledLink>
                </StyledGrid>
              ))}
            </StyledGrid>
          </>
        )}
        {activePage?.children &&
          !collectionMenu &&
          activePage?.children?.map((page: any) => {
            return (
              <StyledGrid item xs={12} sm={12} md={3} lg={2} key={page.id}>
                <MegaMenuColumn
                  page={page}
                  activeMenuId={activeMenuId}
                  setActiveMenuId={setActiveMenuId}
                  activeParentMenuId={activeParentMenuId}
                  setActiveParentMenuId={setActiveParentMenuId}
                  closeMegaMenu={callCloseMegaMenu}
                  parentId={undefined}
                  level={level + 1}
                />
              </StyledGrid>
            );
          })}
        {level === 0 && (
          <StyledLink
            to={HUBPROMO}
            onClick={() => {
              trackEvent(activePage?.seo?.href || "NA", t("Header.Menu.Tracking.SeeGoodDeals"));
              // callCloseMegaMenu();
            }}
            className="menu-mobile-link-top bg icon">
            <StyledTypography variant="body3" color="secondary.dark">
              {t("Header.Menu.Actions.SeeGoodDeals")}
            </StyledTypography>
            <ChevronRightIcon />
          </StyledLink>
        )}
        {/* Don't show "Voir tous les produits" for kitchen model SCU1555698 (menu/Cuisine&Electromenager/modele-de-cuisine/) */}
        {level >= 1 && !collectionMenu && activePage?.identifier !== "SCU1555698" && (
          <StyledLink
            to={activePage?.seo?.href}
            onClick={() => {
              trackEvent(
                activePage?.seo?.href || "NA",
                level === 1 ? t("Header.Menu.Tracking.DiscoverUniverse") : t("Header.Menu.Tracking.SeeAllProducts")
              );
              // callCloseMegaMenu();
            }}
            className="menu-mobile-link-top bg icon">
            <StyledTypography variant="body3" color="secondary.dark">
              {level === 1 ? t("Header.Menu.Actions.DiscoverUniverse") : t("Header.Menu.Actions.SeeAllProducts")}
            </StyledTypography>
            <ChevronRightIcon />
          </StyledLink>
        )}
        {/* {level === 0 && (
          <>
            <StyledGrid item xs={12} sm={12} md={3} lg={2}>
              <StyledBox mt={5} pt={5} mx={5} sx={{ borderTop: 1, borderColor: "gray.neutralGray400" }}></StyledBox>
              <MegaMenuColumn
                page={mockCollection}
                activeMenuId={activeMenuId}
                setActiveMenuId={(e) => {
                  setCollectionMenu(true);
                  setActiveMenuId(e);
                }}
                activeParentMenuId={activeParentMenuId}
                setActiveParentMenuId={setActiveParentMenuId}
                closeMegaMenu={() => {
                  setCollectionMenu(false);
                  callCloseMegaMenu();
                }}
                parentId={undefined}
                level={level + 1}
              />
            </StyledGrid>
          </>
        )} */}
      </StyledGrid>
    );
  };

  useEffect(() => {
    setProductNav({
      name: "Produits",
      children: topCategories ? topCategories : [],
      identifier: "produits",
      id: "produits_root",
    });
  }, [topCategories]);

  return (
    <>
      <StyledSwipeableDrawer
        anchor={"left"}
        open={open}
        onClose={callCloseMegaMenu}
        onOpen={turnOnOpen}
        className="header-menu"
        data-testid="header-menu-drawer-element">
        <StyledGrid container className={"menu-container"}>
          <StyledGrid container p={5} className="menu-mobile-navigation-top">
            <StyledGrid item p={1} className="menu-mobile-navigation-top-svg-close">
              <StyledIconButton onClick={callCloseMegaMenu}>
                <SvgClose color="black" className="" />
              </StyledIconButton>
            </StyledGrid>
          </StyledGrid>

          {/* HEADER NAVIGATION WIDGET */}
          <StyledBox component="nav" sx={{ width: "100%" }}>
            {/* {headerNavigationWidget} */}
            <HeaderNavigationWidget
              callCloseMegaMenu={callCloseMegaMenu}
              productNav={
                productNav?.children && productNav?.children.length > 0 ? (
                  <StyledGrid item xs={12} sm={12} md={3} lg={2}>
                    <MegaMenuColumn
                      page={productNav}
                      activeMenuId={activeMenuId}
                      setActiveMenuId={setActiveMenuId}
                      activeParentMenuId={activeParentMenuId}
                      setActiveParentMenuId={setActiveParentMenuId}
                      closeMegaMenu={callCloseMegaMenu}
                      parentId={undefined}
                      level={0}
                    />
                  </StyledGrid>
                ) : (
                  <></>
                )
              }
            />
          </StyledBox>

          {/* ADD STATIC LINKS */}
          <StyledBox className="menu-mobile-button-bottom">
            <StoreLocatorButton />
            <StyledButton
              fullWidth
              startIcon={<SvgContactUs />}
              size="medium"
              color="secondary"
              className=""
              to={PRISEDERDV}
              onClick={() => {
                AsyncCall.sendPageClickCtaEvent({ event: CLICK.CONTACT, btn_label: "a votre ecoute" });
                callCloseMegaMenu();
              }}
              component={Link}
              sx={{ mt: 3, mb: 3 }}>
              {t("Header.Actions.AtYourService")}
            </StyledButton>
            <StyledButton
              fullWidth
              startIcon={<SvgMyProject />}
              size="medium"
              color="secondary"
              component={Link}
              to={JAIUNPROJET}
              onClick={() => {
                AsyncCall.sendPageClickCtaEvent({ event: CLICK.PROJECT, btn_label: "mon projet" });
                callCloseMegaMenu();
              }}>
              {t("Header.Actions.MyProject")}
            </StyledButton>
          </StyledBox>
        </StyledGrid>
      </StyledSwipeableDrawer>
      <StyledSwipeableDrawer
        anchor={"left"}
        open={activeMenuId.child0 !== undefined}
        onClose={callCloseMegaMenu}
        onOpen={turnOnOpen}
        className="header-menu-level2">
        <ChildrenMenu level={0} />
      </StyledSwipeableDrawer>
      <StyledSwipeableDrawer
        anchor={"left"}
        open={activeMenuId.child1 !== undefined}
        onClose={callCloseMegaMenu}
        onOpen={turnOnOpen}
        className="header-menu-level2">
        <ChildrenMenu level={1} />
      </StyledSwipeableDrawer>
      <StyledSwipeableDrawer
        anchor={"left"}
        open={activeMenuId.child2 !== undefined}
        onClose={callCloseMegaMenu}
        onOpen={turnOnOpen}
        className="header-menu-level2">
        <ChildrenMenu level={2} />
      </StyledSwipeableDrawer>
    </>
  );
};

export default MegaMenu;
