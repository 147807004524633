/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
export const INVENTORY_STATUS = {
  available: "Available",
  allocated: "Allocated",
  backordered: "Backordered",
  unallocated: "Unallocated",
};

export const PAGINATION_CONFIGS = {
  pageLimit: 5,
  pageDefaultOffset: 0, //The page offset to start with on pageload and filters
};

export const PAYMENT = {
  policies: {
    INGENICO: true,
  },
  paymentMethodName: {
    ingenico: "PunchoutIngenico",
    testPunchout: "TestPunchout",
    worldine: "WorldLine",
  },
  paymentType: {
    SOFINCO: "Sofinco",
    CB: "CB",
  },
};

export const SOFINCO_LIMIT_LABEL_MIN = "payment.multiple.min";
export const SOFINCO_LIMIT_LABEL_MAX = "payment.multiple.max";
export const SOFINCO_LIMIT_MIN = 150;
export const SOFINCO_LIMIT_MAX = 2000;
export const MAGAZINE_BASE_URL = "https://magazine.lapeyre.fr/wp-json/wp/v2/posts?_embed=true";
export const MAGAZINE_TAGS_URL = "https://uat-magazine.lapeyre.fr/wp-json/wp/v2/tags";

export const SHIPMODE = {
  shipModeCode: {
    PICKUP: "PickupInStore",
    DELIVERY: "Mail",
  },
};

export const PO_NUMBER = "poNumber";

export const ORDER_STATUS_TRANSACTION = {
  Preparation: "PREP",
  Available: "DISP",
  Canceled: "ANNL",
  Ended: "COMP",
};

export const DOCUMENT_TYPE = {
  Commande: "C",
  Facture: "F",
  Devis: "D",
};

export const ORDER_STATUS = {
  // BackOrdered: "B",
  // Submitted: "I",
  // Approved: "M",
  // NoInventory: "L",
  ApprovalDenied: "N",
  PendingOrder: "P",
  Approved: "C",
  // Released: "R",
  // Shipped: "S",
  // PendingApproval: "W",
  // Canceled: "X",
  Closed: "D",
  LockedByReturn: "RTN",
  LockedByAppeasement: "APP",
};

export const ACCOUNT_CC = "account";
export const CC_CVC = "cc_cvc";
export const EXPIRE_MONTH = "expire_month";
export const EXPIRE_YEAR = "expire_year";
export const ACCOUNT_FOR_VIEW_CC = "accountForView";
export const PRIVATE_ORDER_TYPE = "ORD";
export const SHARRED_ORDER_TTYPE = "SHR";
export const RESOURCE_NAME_CART = "cart";
export const N_ITEMS_ADDED = "addedNSuccessfully";
export const PARTIAL_COPY_ORDER = "PartialCopyOrder";
