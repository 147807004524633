export const typography = {
  htmlFontSize: 16,
  fontFamily: ["Montserrat", "sans-serif"].join(","),
  h1: {
    fontSize: 32,
    textTransform: "uppercase",
    fontWeight: 900,
    lineHeight: "36px",
  },
  h2: {
    fontSize: 32,
    fontWeight: 700,
    lineHeight: "36px",
  },
  h3: {
    fontSize: 26,
    fontWeight: 900,
    lineHeight: "32px",
  },
  h4: {
    fontSize: 26,
    fontWeight: 700,
    lineHeight: "32px",
  },
  h5: {
    fontSize: 20,
    fontWeight: 700,
    textTransform: "uppercase",
    lineHeight: "24px",
  },
  h6: {
    fontSize: 16,
    fontWeight: 900,
    lineHeight: "24px",
  },
  subtitle1: {
    fontSize: 16,
    fontWeight: 900,
    lineHeight: "24px",
  },
  subtitle2: {
    fontSize: 16,
    fontWeight: 700,
    lineHeight: "24px",
  },
  inspirationTitle: {
    fontSize: 64,
    textTransform: "uppercase",
    fontWeight: 900,
    lineHeight: "68px",
    "@media (max-width:960px)": {
      fontSize: 32,
      lineHeight: "36px",
    },
  },
  offersTitle: {
    fontSize: 60,
    textTransform: "uppercase",
    fontWeight: 900,
    lineHeight: "64px",
  },
  faqTitle: {
    fontSize: 54,
    textTransform: "uppercase",
    fontWeight: 900,
    lineHeight: "58px",
    "@media (max-width:960px)": {
      fontSize: 32,
      lineHeight: "36px",
    },
  },
  uspTitle: {
    fontSize: 24,
    textTransform: "uppercase",
    fontWeight: 900,
    lineHeight: "28px",
  },

  body1: {
    fontSize: 14,
    fontWeight: 700,
    lineHeight: "20px",
  },
  body2: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: "20px",
  },
  body3: {
    fontSize: 12,
    fontWeight: 700,
    lineHeight: "16px",
  },
  body4: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: "16px",
  },
  button: {
    fontSize: 14,
    fontWeight: 700,
    lineHeight: "16px",
  },
  caption: {
    fontSize: 10,
    fontWeight: 400,
    lineHeight: "12px",
  },
  overline: {
    fontSize: 12,
    textTransform: "uppercase",
    fontWeight: 600,
    letterSpacing: 1,
  },
};
