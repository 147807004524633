/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
import { SUCCESS_MSG_PREFIX } from "../constants/common";
import { N_ITEMS_ADDED, PARTIAL_COPY_ORDER } from "../constants/order";
//Redux
import * as ORDER_ACTIONS from "../redux/action-types/order";

export const ORDER_CONFIGS = {
  //calculationUsage: "-1,-2,-3,-4,-5,-6,-7",
  calculationUsage: "-1,-3",
  calculateOrder: "1", //The default value for this parameter is 0. If it is 1, OrderCalculateCmd will be called to calculate the charges for the order.
  inventoryValidation: false,
  //allocate: "***", //	Tous les articles de commande existants plus ceux créés par cette exécution d'URL.
  //backOrder: "***", //	Tous les articles de commande existants plus ceux créés par cette exécution d'URL.
  //remerge: "***", //	Tous les articles de commande existants plus ceux créés par cette exécution d'URL.
  //check: "*n", //	Pas d'objets de commande.
  sortOrderItemBy: "orderItemID",
};

/**
 * ShipInfo request body base.
 * shipAsComplete
 */
export const SHIP_INFO = {
  x_calculateOrder: ORDER_CONFIGS.calculateOrder,
  x_calculationUsage: "-1,-2,-4,-5,-7",
  //x_allocate: ORDER_CONFIGS.allocate,
  //x_backorder: ORDER_CONFIGS.backOrder,
  //x_remerge: ORDER_CONFIGS.remerge,
  //x_check: ORDER_CONFIGS.check,
  orderId: ".",
};

export const PAGINATION_CONFIGS = {
  pageLimit: 10,
  pageSizeOptions: [10, 25, 50],
};

export const MINICART_CONFIGS = {
  maxItemsToShow: 1,
  itemAddSuccessMsgKeys: [
    SUCCESS_MSG_PREFIX + ORDER_ACTIONS.ITEM_ADD_SUCCESS,
    SUCCESS_MSG_PREFIX + ORDER_ACTIONS.COPY_CART_SUCCESS,
    SUCCESS_MSG_PREFIX + PARTIAL_COPY_ORDER,
    SUCCESS_MSG_PREFIX + N_ITEMS_ADDED,
  ],
};

export const PAYMENT_CONFIGS = {
  maxNumPayment: 3,
};
