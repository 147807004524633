/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */

//Standard libraries
//UA
import DataService from "./data.service";
import GTMDLService from "./gtmDataLayer.service";
//GA4
import { CLICK } from "../constants/gtm";
import { site } from "../constants/site";

const AsyncCall = {
  measureHomePageView(cart, orderItems) {
    if (site.enableUA) {
      DataService.sendHomePageViewEvent(cart, orderItems)
        .then((obj) => {
          GTMDLService.measurePageView(obj);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
  sendPageClickEvent({ event, eventAction, eventLabel }) {
    if (site.enableUA) {
      GTMDLService.measurePageClick(event, { eventAction, eventLabel });
    }
  },
  sendPageClickCtaEvent(eventObj) {
    if (site.enableUA) {
      DataService.sendClickEvent(eventObj)
        .then((obj) => {
          GTMDLService.measurePageClick(CLICK.CTA, obj);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
  sendPageClickSliderEvent(eventObj) {
    if (site.enableUA) {
      DataService.sendClickEvent(eventObj)
        .then((obj) => {
          GTMDLService.measurePageClick(CLICK.SLIDER, obj);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
  sendPageClickServiceEvent(eventObj) {
    if (site.enableUA) {
      DataService.sendClickEvent(eventObj)
        .then((obj) => {
          GTMDLService.measurePageClick(CLICK.SERVICE, obj);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
  sendPDPPageViewEvent(data: { breadcrumbs?: any; product?: any; cart?: any; orderItems?: any; storeName?: string }) {
    if (site.enableUA) {
      DataService.sendPDPPageViewEvent(data?.breadcrumbs, data?.product, data?.cart, data?.orderItems)
        .then((obj) => {
          GTMDLService.measurePageView(obj);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
  sendStoreLocatorPopinViewEvent(data: { breadcrumbs?: any }) {
    if (site.enableUA) {
      DataService.sendStoreLocatorPopinViewEvent(data?.breadcrumbs)
        .then((obj) => {
          GTMDLService.measurePageView(obj);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },

  sendCheckoutPageViewEvent({ pageSubCategory, pathname, breadcrumbs, cart, orderItems }) {
    if (site.enableUA) {
      DataService.sendCheckoutPageViewEvent(pageSubCategory, pathname, breadcrumbs, cart, orderItems)
        .then((obj) => {
          GTMDLService.measurePageView(obj);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
  sendContentPageViewEvent(data: { breadcrumbs?: any; product?: any }) {
    if (site.enableUA) {
      DataService.sendContentPageViewEvent(data?.breadcrumbs)
        .then((obj) => {
          GTMDLService.measurePageView(obj);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
  sendClick(event, eventObj) {
    if (site.enableUA) {
      DataService.sendClickEvent(eventObj)
        .then((obj) => {
          GTMDLService.measurePageClick(event, obj);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
  sendClickProduct(event, eventObj) {
    if (site.enableUA) {
      DataService.sendProductClickEvent(eventObj)
        .then((obj) => {
          GTMDLService.measureProductClick(event, obj);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
  sendQuantityProduct(event, eventObj) {
    if (site.enableUA) {
      DataService.sendQuantityClickEvent(eventObj)
        .then((obj) => {
          GTMDLService.measureQuantityClick(event, obj);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
  sendClickModel(event, eventObj) {
    if (site.enableUA) {
      DataService.sendModelClickEvent(eventObj)
        .then((obj) => {
          GTMDLService.measureModelClick(event, obj);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
  sendImpressionModelsEvent({ products }) {
    if (!products || !Array.isArray(products)) {
      console.error("Invalid products passed to sendImpressionModelsEvent:", products);
      return;
    }

    if (site.enableUA) {
      DataService.sendModelsImpressionEvent(products)
        .then((obj) => {
          GTMDLService.measureModelsImpression(obj);
        })
        .catch((error) => {
          console.error("Error in sendImpressionModelsEvent:", error);
        });
    }
  },
  sendListerPageViewEvent({ breadcrumbs, productList, cart, orderItems }) {
    if (site.enableUA) {
      DataService.sendListerPageViewEvent(breadcrumbs, productList, cart, orderItems)
        .then((obj) => {
          GTMDLService.measurePageView(obj);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },

  sendCartViewEvent({ breadcrumbs, productList, cart, orderItems }) {
    if (site.enableUA) {
      DataService.sendCartViewEvent(breadcrumbs, productList, cart, orderItems)
        .then((obj) => {
          GTMDLService.measurePageView(obj);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },

  sendCartPageViewEvent({ event, topCategoriesList, productList, itemListId, itemListName, cart }) {
    if (site.enableUA) {
      DataService.sendCartPageViewEvent(event, topCategoriesList, productList, itemListId, itemListName, cart)
        .then((obj) => {
          GTMDLService.measureProductListImpression(obj);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },

  sendAddToCartClick(payload) {
    if (site.enableUA) {
      DataService.sendAddToCartEvent(payload.cart, payload.product, payload.breadcrumbs, payload.sellers, payload.label)
        .then((obj) => {
          GTMDLService.measureAddToCart(obj);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
  sendProductImpressionEvent({ product, breadcrumbs, sellers, cart }) {
    if (site.enableUA) {
      DataService.sendProductImpressionEvent(product, breadcrumbs, sellers, cart)
        .then((obj) => {
          const { cartObj, productItem, currency } = obj;
          GTMDLService.measureProductImpression(cartObj, productItem, currency);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
  sendProductListImpressionEvent({ productList, topCategoriesList, itemListId, itemListName }) {
    if (site.enableUA) {
      DataService.sendProductListImpressionEvent(topCategoriesList, productList, itemListId, itemListName)
        .then((obj) => {
          GTMDLService.measureProductListImpression(obj);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
  sendPromoListImpressionEvent({ promoList, itemListId, itemListName }) {
    if (site.enableUA) {
      DataService.sendPromoListImpressionEvent(promoList, itemListId, itemListName)
        .then((obj) => {
          GTMDLService.measurePromoListImpression(obj);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
  sendSearchPageViewEvent({ event, productListTotal, searchTerm }) {
    if (site.enableUA) {
      DataService.sendSearchPageViewEvent(productListTotal, searchTerm)
        .then((obj) => {
          GTMDLService.measurePageView(obj);
          GTMDLService.measureKeywordSearch(event, obj);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
  sendCartStepPageViews({ topCategoriesList, cart, orderItems, step, value, sellers }) {
    if (site.enableUA) {
      DataService.sendCheckoutEvent(topCategoriesList, cart, orderItems, step, value, sellers)
        .then((obj) => {
          GTMDLService.measurePageView(obj);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
  sendCheckoutEvent({ topCategoriesList, cart, orderItems, step, value, sellers }) {
    if (site.enableUA) {
      DataService.sendCheckoutEvent(topCategoriesList, cart, orderItems, step, value, sellers)
        .then((obj) => {
          GTMDLService.measureCheckout(obj);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
  sendPurchaseEvent({ topCategoriesList, products, order, transactionId }) {
    if (site.enableUA) {
      DataService.sendPurchaseEvent(topCategoriesList, products, order, transactionId)
        .then((obj) => {
          GTMDLService.measurePageView(obj.pageViewObj);
          GTMDLService.measurePurchase(obj);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
  sendNotFoundPageView(eventObj: Record<string, any>) {
    if (site.enableUA) {
      DataService.sendNotFoundPageViewEvent(eventObj)
        .then((obj) => {
          GTMDLService.measurePageView(obj, false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
};

export default AsyncCall;
