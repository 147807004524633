//Standard libraries
import { CART_EVENT, CLICK, PAGE_LOAD_EVENT } from "../constants/gtm";
import { cleanString } from "./data.service";

function formatTagObject(params: any) {
  for (const key in params) {
    if (typeof params[key] === "string") {
      if (params[key].includes("http") === false) {
        params[key] = cleanString(params[key]);
      }
    }
    if (typeof params[key] === "object") {
      formatTagObject(params[key]);
    }
  }
}

function setItem(product: any) {
  return {
    item_id: product.item_id || "",
    item_name: product.item_name || "",
    item_marketing_tag: product.item_marketing_tag || "",
    item_type: product?.item_type || "",
    //item_brand: product.brand || "",
    affiliation: product.affiliation || "",
    index: product.index || 0,
    item_category: product.item_category || "",
    item_category2: product.item_category2 || "",
    item_category3: product.item_category3 || "",
    item_category4: product.item_category4 || "",
    item_category5: product.item_category5 || "",
    item_list_id: product.item_list_id || "",
    item_list_name: product.item_list_name || "",
    //item_variant: "",
    //location_id: product.location_id || "",
    product_availability: product.product_availability || "",
    product_id: product.product_id || "",
    product_name: product.item_name || "",
    product_energy: product.product_energy || "",
    product_garanty: product.product_garanty || "",
    product_origin: product.product_origin || "",
    product_rating: product.product_rating || "",
    product_review: product.product_review || "",
    price: Number(parseFloat(product.price).toFixed(2)) || "",
    quantity: product.quantity || 1,
    discount: product.discount || "",
    coupon: product.coupon || "",
    ...(product.promotion_name && { promotion_name: product.promotion_name }),
    ...(product.promotion_id && { promotion_id: product.promotion_id }),
  };
}

const GTMDLService = {
  /**
   * Measure Page View of the pages user visits
   * `@method`
   * `@name GTM#measurePageView`
   **
   **/
  measurePageView(pageObj, sendBreadcrumbs = true) {
    if (pageObj) {
      let dataLayer = {
        event: PAGE_LOAD_EVENT.PAGE_LOAD,
        ...pageObj,
      };
      if (sendBreadcrumbs) {
        dataLayer = {
          ...dataLayer,
          breadcrumb_level_1: pageObj?.breadcrumb_level_1 || "",
          breadcrumb_level_2: pageObj?.breadcrumb_level_2 || "",
          breadcrumb_level_3: pageObj?.breadcrumb_level_3 || "",
          breadcrumb_level_4: pageObj?.breadcrumb_level_4 || "",
          breadcrumb_level_5: pageObj?.breadcrumb_level_5 || "",
          // cart_id: pageObj?.cart_id || "",
          // cart_tax: pageObj?.cart_tax || "",
          // cart_discount: Math.abs(pageObj?.cart_discount) || "",
          // cart_items: pageObj?.cart_items || [],}
        };
      }
      pageObj = JSON.parse(JSON.stringify(pageObj)); // to remove immutable keys throwing type error
      formatTagObject(pageObj);
      window["dataLayer"].push(dataLayer);
    }
  },
  /**
   * Measure click event on MenuItems as part of Navigation
   * `@method`
   * `@name GTM#measurePageClick`
   *
   * `@param {string} event` Page Click     e.g. HomeClick etc
   * `@param {string} eventAction` Page action Click      e.g. HeroBanner etc
   * `@param {any} eventLabel` Click menu Text e.g. Furniture
   **
   **/
  measurePageClick(event, eventObj) {
    const dataLayer = {
      event,
      ...eventObj,
    };
    formatTagObject(eventObj);
    window["dataLayer"].push(dataLayer);
  },
  /**
   * Measure clicks on product links by pushing a click action to the data layer, along with a productFieldObject to represent the clicked product.
   **/
  measureProductClick(event, productObj) {
    const dataLayer = {
      event: event,
      ecommerce: {
        // currency: productObj.ecommerce.currency || "",
        // value: parseFloat(productObj.ecommerce.itemPrice) || 0,
        item_list_id: productObj.ecommerce.item_list_id,
        item_list_name: productObj.ecommerce.item_list_name,
        items: [setItem(productObj.ecommerce.product[0])],
      },
    };
    formatTagObject(dataLayer);
    window["dataLayer"].push({ ecommerce: null });
    window["dataLayer"].push(dataLayer);
  },
  /**
   * Measure clicks on product links by pushing a click action to the data layer, along with a productFieldObject to represent the clicked product.
   **/
  measureQuantityClick(event, productObj) {
    const dataLayer = {
      event: event,
      ...productObj,
    };
    formatTagObject(dataLayer);
    window["dataLayer"].push({ ecommerce: null });
    window["dataLayer"].push(dataLayer);
  },
  measureModelClick(event, productObj) {
    const items: any = [];
    items.push(setItem(productObj.ecommerce.product));
    formatTagObject(items);
    //
    const dataLayer = {
      event: event,
      ...productObj,
      ecommerce: {
        currency: productObj.ecommerce.currency.toUpperCase() || "",
        value: parseFloat(productObj.ecommerce.value) || 0,
        item_list_id: productObj.ecommerce.item_list_id,
        item_list_name: productObj.ecommerce.item_list_name,
        items: items,
      },
    };

    window["dataLayer"].push({ ecommerce: null });
    window["dataLayer"].push(dataLayer);
  },
  measureModelsImpression(obj) {
    const productObj = obj.products;

    const items: any = [];
    if (productObj && productObj.length > 0) {
      productObj.forEach((product) => {
        items.push(setItem(product));
      });
      formatTagObject(items);
      const tagManagerArgs = {
        event: PAGE_LOAD_EVENT.ITEM_LIST,
        ecommerce: {
          item_list_id: obj.item_list_id,
          item_list_name: obj.item_list_name,
          items: items,
        },
      };
      window["dataLayer"].push({ ecommerce: null });
      window["dataLayer"].push(tagManagerArgs);
    }
  },
  /**
   * Measure product impressions by using the impression action and one or more impressionFieldObjects
   * `@method`
   * `@name GTM#measureProductImpression`
   **/
  measureProductImpression(cartObj, product, currency) {
    // const grandTotal = parseFloat(cartObj.grandTotal);
    const items: any = [];
    product.forEach((item) => items.push(setItem(item)));
    formatTagObject(items);
    const tagManagerArgs = {
      event: PAGE_LOAD_EVENT.ITEM,
      ecommerce: {
        currency: currency || "",
        value: parseFloat(product[0].price) || 0,
        items: items,
      },
    };
    window["dataLayer"].push({ ecommerce: null });
    window["dataLayer"].push(tagManagerArgs);
  },
  measureProductListImpression(obj) {
    const { products, cart } = obj;

    const items: any = [];
    if (products && products.length > 0) {
      products.forEach((product) => {
        items.push(setItem(product));
      });
      formatTagObject(items);
    }
    let tagManagerArgs;
    if (cart && items.length > 0) {
      tagManagerArgs = {
        event: obj.event || PAGE_LOAD_EVENT.ITEM_LIST,
        cart_id: cart.id,
        cart_total: parseFloat(cart.total),
        cart_tax: parseFloat(cart.tax),
        cart_discount: parseFloat(cart.discount),
        cart_items: items,
        ecommerce: {
          currency: obj.currency,
          value: obj.value,
          item_list_id: obj.item_list_id,
          item_list_name: obj.item_list_name,
          items: items,
        },
      };
    } else {
      tagManagerArgs = {
        event: obj.event || PAGE_LOAD_EVENT.ITEM_LIST,
        ecommerce: {
          item_list_id: obj.item_list_id,
          item_list_name: obj.item_list_name,
          items: items,
        },
      };
    }

    window["dataLayer"].push({ ecommerce: null });
    window["dataLayer"].push(tagManagerArgs);
  },
  measurePromoListImpression(obj) {
    const promoObj = obj.promos;

    const items: any = [];
    if (promoObj && promoObj.length > 0) {
      promoObj.forEach((promo) => {
        items.push({
          id: promo.id,
          href: promo.link.url,
          dateStart: promo.dateStart,
          dateEnd: promo.dateEnd,
        });
      });

      formatTagObject(items);
      const tagManagerArgs = {
        event: obj.event || PAGE_LOAD_EVENT.VIEW_PROMOTION,
        ecommerce: {
          item_list_id: obj.item_list_id,
          item_list_name: obj.item_list_name,
          items: items,
        },
      };

      window["dataLayer"].push({ ecommerce: null });
      window["dataLayer"].push(tagManagerArgs);
    }
  },
  /**
   *  Measure adding a product to a shopping cart by using an 'add' actionFieldObject and a list of productFieldObjects.
   * `@method`
   * `@name GTM#measureAddToCart`
   **/
  measureAddToCart({ cartObj, productsItem }) {
    const grandTotal = parseFloat(cartObj.grandTotal || 0);
    const items: any = [];
    if (productsItem && productsItem.length > 0) {
      productsItem.forEach((product) => {
        items.push({
          btn_label: product.btn_label,
          ...setItem(product),
        });
      });
      formatTagObject(items);
      const tagManagerArgs = {
        event: CLICK.PRODUCT_ADD_TO_CART,
        ecommerce: {
          currency: cartObj.grandTotalCurrency,
          value: grandTotal,
          items: items,
        },
      };

      window["dataLayer"].push({ ecommerce: null });
      window["dataLayer"].push(tagManagerArgs);
    }
  },
  /**
   * Measure search keyword
   * `@method`
   * `@name GTM#measureKeywordSearch`
   *
   * `@param {any} pageObj`  object and have following properties:
   ** `@property {string} searchTerm `  The search Text.
   ** `@property {number} productResults ` The total count of the products on search page.
   **/
  measureKeywordSearch(event, pageObj) {
    const tagManagerArgs = {
      event: event,
      nb_result: pageObj.productResults,
      keyword_used: pageObj.searchTerm,
    };
    formatTagObject(tagManagerArgs);
    window["dataLayer"].push(tagManagerArgs);
  },
  /**
   *  Measure checkout process into the Data Layer using the checkout action.
   * `@method`
   * `@name GTM#measureCheckout`   *
   **/
  measureCheckout(orderObj) {
    const { cart, products } = orderObj;
    let hasAdjustement: any = [];
    let promotionCode: any = [];
    let rcuCoupon: any = [];

    if (cart?.adjustment?.length) {
      hasAdjustement = cart?.adjustment?.filter((item) => item.displayLevel == "Order");
    }
    if (cart?.promotionCode?.length && hasAdjustement.length) {
      promotionCode = cart?.promotionCode.filter(
        (promoCode) => promoCode?.associatedPromotion[0].description == hasAdjustement[0]?.description
      );
    }
    if (cart?.orderExtendAttribute?.length && hasAdjustement.length) {
      rcuCoupon = cart?.orderExtendAttribute.filter((attribute) => attribute.attributeName == "COUPON_NUMERO");
    }

    if (orderObj.step === 1) {
      const items: any = [];
      products.forEach((product) => {
        items.push(setItem(product));
      });
      formatTagObject(items);
      const tagManagerArgs = {
        event: CART_EVENT.BEGIN_CHECKOUT,
        cart_id: cart.orderId,
        cart_total: parseFloat(cart.grandTotal),
        cart_tax: parseFloat(cart.totalSalesTax),
        cart_discount: parseFloat(cart.totalAdjustment),
        cart_items: items,
        ecommerce: {
          currency: cart.grandTotalCurrency || "",
          value: parseFloat(cart.grandTotal),
          // value: cart.totalProductPrice
          //   ? (parseFloat(cart.totalProductPrice) + parseFloat(cart.x_ECOPART_TTC)).toFixed(2)
          //   : 0,
          coupon: promotionCode?.[0]?.code || rcuCoupon?.[0]?.attributeValue || "",
          items: items,
        },
      };

      window["dataLayer"].push(tagManagerArgs);
    } else if (orderObj.step === 3) {
      let cartShippingTier = "";

      // Send add_shipping_info event
      const items: any = [];
      products.forEach((product) => {
        cartShippingTier = product.shippingTier;
        items.push(setItem(product));
      });
      formatTagObject(items);
      //
      const tagManagerArgs = {
        event: CART_EVENT.ADD_SHIPPING_INFO,
        cart_id: cart.orderId,
        cart_total: parseFloat(cart.grandTotal),
        cart_tax: parseFloat(cart.totalSalesTax),
        cart_discount: parseFloat(cart.totalAdjustment),
        cart_items: items,
        ecommerce: {
          currency: cart.grandTotalCurrency || "",
          value: parseFloat(cart.grandTotal),
          // value: cart.totalProductPrice
          //   ? (parseFloat(cart.totalProductPrice) + parseFloat(cart.x_ECOPART_TTC)).toFixed(2)
          //   : 0,
          coupon: promotionCode?.[0]?.code || rcuCoupon?.[0]?.attributeValue || "",
          ...(cartShippingTier && { shipping_tier: cartShippingTier }),
          items: items,
        },
      };

      window["dataLayer"].push(tagManagerArgs);
    } else if (orderObj.step === 4) {
      // Send add_payment_info event
      const items: any = [];
      const cartPaymentType = orderObj.paymentMethod;

      products.forEach((product) => {
        items.push(setItem(product));
      });
      formatTagObject(items);
      //
      const tagManagerArgs = {
        event: CART_EVENT.ADD_PAYMENT_INFO,
        cart_id: cart.orderId,
        cart_total: cart.totalProductPrice
          ? (parseFloat(cart.totalProductPrice) + parseFloat(cart.x_ECOPART_TTC)).toFixed(2)
          : 0,
        cart_tax: parseFloat(cart.totalSalesTax),
        cart_discount: parseFloat(cart.totalAdjustment),
        cart_items: items,
        ecommerce: {
          currency: cart.grandTotalCurrency || "",
          value: parseFloat(cart.grandTotal) || 0,
          coupon: promotionCode?.[0]?.code || rcuCoupon?.[0]?.attributeValue || "",
          ...(cartPaymentType && { payment_type: cartPaymentType }),
          items: items,
        },
      };

      window["dataLayer"].push(tagManagerArgs);
    }
  },
  measurePurchase(orderObj) {
    const { order, products, transactionId } = orderObj;

    const items: any = [];
    let tax: number | null = null;
    let shippingTax: number | null = null;
    let hasAdjustement: any = [];
    let promotionCode: any = [];
    let rcuCoupon: any = [];

    tax = order?.x_ORDERTOTALAMOUNT_VAT ? parseFloat(order?.x_ORDERTOTALAMOUNT_VAT) : null;
    shippingTax =
      order?.x_TOTALSHIPPING_TTC && order?.x_TOTALSHIPPING_HT
        ? parseFloat(order.x_TOTALSHIPPING_TTC) - parseFloat(order.x_TOTALSHIPPING_HT)
        : null;
    if (tax != null && shippingTax != null) {
      tax = tax + shippingTax;
    }

    if (order?.adjustment?.length > 0) {
      hasAdjustement = order?.adjustment?.filter((item) => item.displayLevel == "Order");
    }

    if (order?.promotionCode?.length > 0 && hasAdjustement.length > 0) {
      promotionCode = order?.promotionCode.filter(
        (promoCode: Record<any, any>) =>
          promoCode?.associatedPromotion[0].description?.value == hasAdjustement[0]?.description
      );
    }

    if (order?.orderExtendAttribute && order?.orderExtendAttribute.length > 0 && hasAdjustement.length > 0) {
      rcuCoupon = order?.orderExtendAttribute.filter((attribute) => attribute.attributeName == "COUPON_NUMERO");
    }

    const finalCoupon = promotionCode?.[0]?.code || rcuCoupon?.[0]?.attributeValue;

    if (!finalCoupon) {
      //only send item level coupon when there is no order level coupon
      const productsWithItemLevelCoupon = products.map((prod: Record<any, any>) => {
        const respectiveOrder = order?.orderItem?.find((order: Record<any, any>) => order.partNumber === prod.item_id);
        const orderDiscount = respectiveOrder?.adjustment?.find(
          (adjObj: Record<any, any>) => adjObj?.usage?.toLowerCase() === "discount"
        );
        return { ...prod, coupon: orderDiscount?.code };
      });
      productsWithItemLevelCoupon.forEach((product) => {
        items.push(setItem(product));
      });
    } else {
      products.forEach((product) => {
        items.push(setItem(product));
      });
    }

    formatTagObject(items);

    const tagManagerArgs = {
      event: CART_EVENT.PURCHASE,
      ecommerce: {
        transaction_id: transactionId.returnMac, // returnMac or hostedCheckoutId
        value: parseFloat(order.grandTotal),
        tax: tax,
        shipping: parseFloat(order.totalShippingCharge), // TO be validated
        currency: order.grandTotalCurrency,
        coupon: finalCoupon || "",
        items: items,
      },
    };

    window["dataLayer"].push(tagManagerArgs);
  },
};

export default GTMDLService;
