import styled from "@mui/styled-engine-sc";
import { palette } from "../../../themes/color-palette";
import { StyledBox, StyledLink } from "../../../styled-mui";

const StyledProductCard = styled(({ className, ...props }: any) =>
  props.to ? <StyledLink className={className} {...props} /> : <StyledBox className={className} {...props} />
)`
  ${({ theme }) => `
  height: 100%;

  .product-card {
    display: block;
    width: 100%;
    border-radius: 8px;
    border: ${palette.gray.neutralGray300} 1px solid;
    background: ${palette.white.main};
    color: ${palette.gray.neutralGray900};
    height: 100%;

    .product-card-title {
      height: 48px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .product-card-image {
      width: auto;
      height: 200px;

      &.max-height-148 {
        height: auto;
        max-height: 148px;
        border-radius: 8px 8px 0 0;
        width: auto;
        //object-fit: cover;
        ${theme.breakpoints.down("sm")} {
          max-height: 248px;
        }
      }
    }

    .product-card-content {
      border-top: ${palette.gray.neutralGray300} 1px solid;
      //border-bottom: ${palette.gray.neutralGray300} 1px solid;
    }


    .product-card-basket {
      display: flex;
      align-items: center;

      .add-to-cart {
        margin-left: auto;
      }

      .product-card-availaible {
        display: flex;
        align-items: center;
        &:before {
          content: '';
          background: ${palette.success.main};
          width: 10px;
          height: 10px;
          border-radius: 100%;
          margin-right: ${theme.spacing(2)}
        }
      }
    }
    .product-card-pack {
      display: none;
    }
  }

  `}
`;

export { StyledProductCard };
