/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
import React from "react";
import styled from "@mui/styled-engine-sc";
import Checkbox from "@mui/material/Checkbox";

export const StyledCheckbox = styled(({ ...props }) => <Checkbox {...props} />)`
  ${({ theme }) => `
    &.MuiCheckbox-root {
      padding: 0;
    }
  `}
`;
