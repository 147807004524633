/* eslint-disable react-hooks/exhaustive-deps */
//Standard libraries
import React, { useEffect, useMemo, useState } from "react";
import { StyledGrid } from "@hcl-commerce-store-sdk/react-component";
import { StyledTypography, StyledContainer, StyledBox } from "../../../styled-mui";
import ProductCard from "../product-card/ProductCard";
import { KeenSliderPlugin, useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import { Hidden } from "@mui/material";
import { TYPE_CARD } from "../../../constants/catalog";
import { StyledProductRecommended } from "./styled-productRecommended";
import useFetchCpProduct from "../../../_foundation/hooks/use-product-crownpeak";
import PageLoader from "../../elements/loader/PageLoader";
import KeenSliderArrow from "../keen-slider/KeenSliderArrow";
import { StyledKeenSlider } from "../keen-slider/styled-keenSlider";
import { useCartItem } from "../../../_foundation/hooks/use-cart-item";
import { useSite } from "../../../_foundation/hooks/useSite";
import UADataService from "../../../_foundation/gtm/data.service";
import { PAGE_LOAD_EVENT } from "../../../_foundation/constants/gtm";
import AsyncCall from "../../../_foundation/gtm/async.service";
import { GetCategoriesSelector } from "../../../redux/selectors/category";
import { useSelector } from "react-redux";

interface ProductOpenData {
  products?: any[]; // Products array
  recommendationId?: string; // Add recommendationId as an optional property
  widgetId?: string; // Add widgetId if needed
}

interface ProductRecommendedProps {
  widgetId: string;
  variable?: any;
  title?: string;
  description?: string;
  bgBeige?: boolean;
  isInModal?: boolean;
  orderPage?: boolean;
  dottedSlider?: boolean;
  origin?: string;
  isModalLoader?: boolean;
  isCartData?: ProductOpenData;
  allFpcIds?: string[];
  mostExpensiveFpcId?: string | null;
  lastProductFpcId?: string | null;
  basketAmount?: number | null;
  setTitleName?: (title: string) => void;
  emptyCart?: boolean;
}

const ResizePlugin: KeenSliderPlugin = (slider) => {
  const observer = new ResizeObserver(function () {
    slider.update();
  });
  slider.on("created", () => {
    observer.observe(slider.container);
  });
  slider.on("destroyed", () => {
    observer.unobserve(slider.container);
  });
};

/**
 * ProductRecommended component
 *
 * @param productList
 * @param recommendedProdTitle
 */
const ProductRecommended: React.FC<ProductRecommendedProps> = ({
  title = "Les clients Lapeyre ont aussi commandé ces produits",
  description = "Ils sont un « plus » pour votre projet.",
  widgetId,
  variable = null,
  bgBeige = false,
  isInModal = false,
  orderPage = false,
  dottedSlider = false,
  origin,
  isModalLoader = true,
  isCartData,
  allFpcIds = [],
  mostExpensiveFpcId = null,
  lastProductFpcId = null,
  basketAmount = null,
  setTitleName,
  emptyCart = false,
}) => {
  const { addToCart } = useCartItem();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [currentMaxIdx, setCurrentMaxIdx] = useState(0);

  const { data, loading, error, Name } = useFetchCpProduct(
    widgetId,
    variable,
    isModalLoader,
    allFpcIds, // Send the allFpcIds prop
    mostExpensiveFpcId, // Send the mostExpensiveFpcId prop
    lastProductFpcId, // Send the lastProductFpcId prop
    basketAmount,
    emptyCart
  );
  const categoriesRedux = useSelector(GetCategoriesSelector);

  const { mySite } = useSite();
  const widgetName = "ProductRecommended";

  const itemListId = widgetName + "_" + origin;
  const itemListName = widgetName + " " + origin;

  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>(
    {
      initial: 0,
      optionsChanged(slider) {
        setCurrentMaxIdx(slider.track?.details?.maxIdx);
      },
      slideChanged(slider) {
        setCurrentSlide(slider.track.details.rel);
      },
      created(slider) {
        setCurrentMaxIdx(slider.track?.details?.maxIdx);
        setLoaded(true);
      },
      slides: {
        perView: "auto",
        spacing: currentMaxIdx === 0 ? 0 : 20,
      },
      breakpoints: {
        "(max-width: 767px)": {
          slides: { perView: "auto", spacing: 12, origin: "center" },
        },
      },
    },
    [ResizePlugin]
  );

  useEffect(() => {
    if (mySite.enableGA) {
      if (data) {
        if (data.products && data.products.length > 0 && categoriesRedux?.length > 0) {
          UADataService.setPageEvent(PAGE_LOAD_EVENT.ITEM_LIST);
          AsyncCall.sendProductListImpressionEvent({
            productList: data?.products,
            topCategoriesList: categoriesRedux,
            itemListId: itemListId.toLocaleLowerCase(),
            itemListName: itemListName.toLocaleLowerCase(),
          });
        }
      }
    }
  }, [data, categoriesRedux]);

  useEffect(() => {
    if (Name && setTitleName) {
      // Check if setTitleName is provided
      setTitleName(Name); // Update the title if setTitleName exists
    }
  }, [Name, setTitleName]);

  useEffect(() => {
    if (isCartData?.recommendationId || data?.recommendationId) {
      window["ebRecoIds"] = window["ebRecoIds"] || [];
      window["ebRecoIds"].push(isCartData?.recommendationId ? isCartData?.recommendationId : data?.recommendationId);
    }
  }, [data]);

  const slides = useMemo(() => {
    let html: any | null = null;
    if (isCartData && isCartData?.products && isCartData?.products.length > 0) {
      // If isCartData has valid products, use them
      html = isCartData.products.map((p: any, i: number) => (
        <StyledBox className="keen-slider__slide" sx={{ minWidth: "265px", maxWidth: "265px" }} key={i}>
          <ProductCard
            product={p}
            productPosition={i}
            type={TYPE_CARD.RECOMMENDATION}
            key={i}
            showAttribute={false}
            addToCart={addToCart}
            isRecommanded={{ recommendationId: isCartData?.recommendationId, widgetId: isCartData?.widgetId }}
          />
        </StyledBox>
      ));
    } else if (data && data?.products && data.products.length > 0) {
      // If isCartData does not have valid products, fallback to data
      html = data.products.map((p: any, i: number) => (
        <StyledBox className="keen-slider__slide" sx={{ minWidth: "265px", maxWidth: "265px" }} key={i}>
          <ProductCard
            product={p}
            productPosition={i}
            type={TYPE_CARD.RECOMMENDATION}
            key={i}
            showAttribute={false}
            addToCart={addToCart}
            isRecommanded={{ recommendationId: data?.recommendationId, widgetId: data?.widgetId }}
          />
        </StyledBox>
      ));
    }

    return html;
  }, [isCartData, data, addToCart]);

  if (isModalLoader) {
    if (loading && !data && !isCartData) {
      return <PageLoader />;
    }
  }
  return (
    <>
      {!error && slides && (
        <StyledProductRecommended
          className={`${bgBeige ? "bg-beige" : ""}${isInModal ? " modal" : ""}${orderPage ? " order-page" : ""}`}
          data-cp-recommendation-id={isCartData ? isCartData?.recommendationId : data?.recommendationId}
          data-cp-widget-id={isCartData ? isCartData?.widgetId : data?.widgetId}>
          <StyledContainer
            className={`product-recommended no-padding-mobile ${isInModal || orderPage ? "no-padding" : ""}`}>
            <StyledGrid
              container
              pt={{ xs: 10, sm: isInModal || orderPage ? 10 : 15 }}
              pb={isInModal || orderPage ? 6 : 10}
              px={{ xs: 5, sm: isInModal ? 5 : 0 }}
              justifyContent="center">
              <StyledGrid item xs={12} md={isInModal || orderPage ? 12 : 7}>
                <StyledTypography
                  variant={!orderPage ? "h4" : "body1"}
                  component="h4"
                  pb={!orderPage ? 2 : 0}
                  align={!orderPage ? "center" : "left"}
                  sx={{ textTransform: "uppercase" }}>
                  {title}
                </StyledTypography>
                <StyledTypography
                  variant="body2"
                  component="p"
                  align="center"
                  color={bgBeige ? "gray.neutralGray600" : ""}>
                  {description}
                </StyledTypography>
              </StyledGrid>
            </StyledGrid>
            <StyledBox px={{ xs: 0, sm: isInModal ? 5 : 0 }}>
              <StyledBox ref={sliderRef} className={`${currentMaxIdx === 0 && "justify-center"} keen-slider`}>
                {slides}
              </StyledBox>
              {!dottedSlider && (
                <Hidden smDown>
                  <StyledKeenSlider>
                    {loaded && instanceRef.current && currentMaxIdx > 0 && (
                      <>
                        <KeenSliderArrow
                          color="secondary.main"
                          left
                          big
                          onClick={(e: any) => e.stopPropagation() || instanceRef.current?.prev()}
                          disabled={currentSlide === 0}
                        />
                        <KeenSliderArrow
                          color="secondary.main"
                          big
                          onClick={(e: any) => e.stopPropagation() || instanceRef.current?.next()}
                          disabled={currentSlide === currentMaxIdx}
                        />
                      </>
                    )}
                  </StyledKeenSlider>
                </Hidden>
              )}

              {/* dotted slider */}
              <Hidden smUp={!dottedSlider}>
                {loaded && instanceRef.current && currentMaxIdx > 0 && (
                  <StyledKeenSlider>
                    <StyledBox className="dots" pt={5} px={5}>
                      <KeenSliderArrow
                        color="secondary.main"
                        left
                        onClick={(e: any) => e.stopPropagation() || instanceRef.current?.prev()}
                        disabled={currentSlide === 0}
                        style={currentSlide === 0 ? { opacity: 0, cursor: "default" } : {}}
                      />
                      {[...Array(instanceRef.current.track.details.maxIdx + 1).keys()].map((idx) => {
                        return (
                          <StyledBox
                            component="button"
                            key={idx}
                            onClick={() => {
                              instanceRef.current?.moveToIdx(idx);
                            }}
                            className={"dot" + (currentSlide === idx ? " active" : "")}
                            sx={{
                              borderColor: "secondary.main",
                              bgcolor: "transparent",
                              "&.active": { bgcolor: "secondary.main" },
                            }}></StyledBox>
                        );
                      })}
                      <KeenSliderArrow
                        color="secondary.main"
                        onClick={(e: any) => e.stopPropagation() || instanceRef.current?.next()}
                        disabled={currentSlide === currentMaxIdx}
                        style={currentSlide === currentMaxIdx ? { opacity: 0, cursor: "default" } : {}}
                      />
                    </StyledBox>
                  </StyledKeenSlider>
                )}
              </Hidden>
            </StyledBox>
          </StyledContainer>
        </StyledProductRecommended>
      )}
    </>
  );
};

export default ProductRecommended;
