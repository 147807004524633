/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
import { createReducer, AnyAction } from "@reduxjs/toolkit";
import initStates from "./initStates";
import { UniversState } from "./reducerStateInterface";
import { UNIVERS_GET_REQUEST_ACTION, UNIVERS_GET_SUCCESS_ACTION, UNIVERS_GET_ERROR_ACTION } from "../actions/univers";

/**
 * User reducer
 * handles states used by user related components
 * @param state State object managed by user reducer
 * @param action The dispatched action
 */
const storesReducer = createReducer(initStates.univers, (builder) => {
  builder.addCase(UNIVERS_GET_REQUEST_ACTION, (state: UniversState, action: AnyAction) => {
    state.isLoading = true;
  });

  builder.addCase(UNIVERS_GET_SUCCESS_ACTION, (state: UniversState, action: AnyAction) => {
    state.univers = action.payload.universData;
    state.isLoading = false;
  });

  builder.addCase(UNIVERS_GET_ERROR_ACTION, (state: UniversState, action: AnyAction) => {
    state.isLoading = false;
  });
});

export default storesReducer;
