/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
// import { v4 as uuidv4 } from "uuid";

export const REG_EX = {
  PREFIX_ATTR_IDENTIFIER: /^\bCCU*[0-9]+-\b/,
  NUMERIC: /^[0-9]+$/,
  CP_FRANCE: /^(?:0[1-9]|[1-8]\d|9[0-8])\d{3}$/,
  CP_BELGIUM: /^(?:(?:[1-9])(?:\d{3}))$/,
  CP_SWISS: /^[1-9]\d{3}$/,
  CP_NETHERLANDS: /^(?:NL-)?(\d{4})\s*([A-Z]{2})$/i,

  FLOAT: /^[-+]?[0-9]*\.?[0-9]+$/,
  EMAIL: /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i,
  PHONE: /^\+(?:[0-9] ?){6,14}[0-9]$/,
  PHONE_FR: /^\+33(0[0-9]{9}|[1-9][0-9]{8})$/,
  PHONE_ES: /^\+34[6-9][0-9]{8}$/,
  PHONE_AN: /^\+376[0-9]{6}$/,
  PHONE_MO: /^\+377[0-9]{8,9}$/,
  PHONE_IT: /^\+39[0-9]{6,12}$/,
  PHONE_SU: /^\+41[1-9][0-9]{8}$/,
  PHONE_AL: /^\+49[1-9][0-9]{1,14}$/,
  PHONE_LU: /^\+352[0-9]{3,11}$/,
  PHONE_BE: /^\+32[1-9][0-9]{7,8}$/,
  PHONE_UK: /^\+44[1-9][0-9]{9,10}$/,

  //France, Espagne, Andorre, Monaco, Italie, Suisse, Allemagne, Luxembourg, Belgique, UK

  PHONE_INDICATOR: /(?:([+]\d{1,2}))/,
  PHONE_START: /^0/,
  PRICE: /^[0-9]+([.][0-9]{0,2})?$|^[.][0-9]{1,2}$/,
  PASSWORD: /^((?=\S*?[a-z])(?=\S*?[0-9]).{8,})\S$/,
  MAX_CHAR_CONSECUTIVE_2: /(.)\1{2,}/i,
  MAX_CHAR_CONSECUTIVE_4: /(.)\1{4,}/i,
  PASSWORD_ALPHA: /([A-Za-z]+)/g,
  PASSWORD_MAJ: /^((?=\S*?[A-Z]).{1,})$/,
  PASSWORD_MIN: /^((?=\S*?[a-z]).{1,})$/,
  PASSWORD_NUMBER: /^((?=\S*?[0-9]).{1,})$/,
  PASSWORD_NOWORD: /^((?=\S*?\W).{1,})$/,
  NOWHITESPACE: /^\S*$/,
  NICKNAME_ALPHA_NUMERIC_SPECIAL_CHAR: /^[a-zA-Z0-9 ]*$/,
  CARD_NUMBER_MASK: /[0-9](?=([0-9]{4}))/g,
  CLEAN_DATE: /.\d{3}Z/gm,
};
//
export const PASSWORD_LOW = "low";
export const PASSWORD_MODERATE = "moderate";
export const PASSWORD_STRONG = "strong";
export const DEFINING = "Defining";
export const DESCRIPTIVE = "Descriptive";
export const SELLER = "Seller";
export const OFFER = "Offer";
export const DISPLAY = "Display";
export const SEARCHTERM = "searchTerm";
//
export const EMPTY_STRING = "";
export const STRING_TRUE = "true";
export const STRING_FALSE = "false";
export const SLOTID = "id";
//
export const CCU_KITCHEN = "CCU0001";
export const STORELOCATOR_CP_DEFAULT = "93460";
export const STORELOCATOR_NODEID_LABEL_ATTRKEY = "stloc.geonodeId";

//Address Form
export const CHECKOUT = "checkout";
export const ADDRESS_BOOK = "address-book";
export const ADDRESS_SHIPPING = "Shipping";
export const ADDRESS_BILLING = "Billing";
export const ADDRESS_SHIPPING_BILLING = "ShippingAndBilling";
export const ADDRESS_LINE = "addressLine";
export const ADDRESSLINE1 = "addressLine1";
export const ADDRESSLINE2 = "addressLine2";
export const ADDRESSLINE3 = "addressLine3";
export const ATTRIBUTES = "attributes";
export const ADDRESS_LABEL_ATTRKEY = "attrKey";
export const ADDRESS_LABEL_ATTRKEY_VALUE = "addressField1";
export const ID_CRM_ATTRKEY_VALUE = "userField1";
export const ADDRESS_LABEL_VALUE = "attrValue";
export const ADDRESS_MAIN_LABEL_VALUE = "contactInfoAttrValue";
export const PHONE1 = "phone1";
export const GENDER_MR = "1";
export const GENDER_MS = "2";
export const GENDER_ENTREPRISE = "5";
//User
export const PROFILE_CREATION_TYPE = "C";
export const PROFILE_UPDATE_TYPE = "U";
export const REGISTER_TYPE = "G";

//
export const ADDRESS_NEW_INIT = {
  personTitle: EMPTY_STRING,
  firstName: EMPTY_STRING,
  lastName: EMPTY_STRING,
  addressLine1: EMPTY_STRING,
  addressLine2: EMPTY_STRING,
  addressLine3: "0",
  city: EMPTY_STRING,
  country: "FR",
  addressField1: EMPTY_STRING,
  zipCode: EMPTY_STRING,
  phone1: EMPTY_STRING,
  nickName: EMPTY_STRING,
  email1: EMPTY_STRING,
  addressType: ADDRESS_SHIPPING_BILLING,
  primary: "false",
  main: false,
};

//Admin Tools
export const IBM_ASSIGNED_ROLE_DETAILS = "IBM_Assigned_Roles_Details";
export const IBM_PUNCHOUT_PAYMENT_INFO = "IBM_PunchoutPaymentInfo";
export const BUYER_ADMIN_ROLE = "-21";
export const BUYER_APPROVAL_ROLE = "-22";

export const ADDRESS_TYPE_MAP = new Map();
ADDRESS_TYPE_MAP.set(ADDRESS_SHIPPING, "AddressBook.ShippingLabel");
ADDRESS_TYPE_MAP.set(ADDRESS_BILLING, "AddressBook.BillingLabel");
ADDRESS_TYPE_MAP.set(ADDRESS_SHIPPING_BILLING, "AddressBook.ShippingBillingLabel");

//ADDRESS PHONE
export const COUNTRIES_PHONE = {
  belgique: {
    code: "be",
    indicator: "32",
  },
  suisse: {
    code: "ch",
    indicator: "41",
  },
  "pays-bas": {
    code: "nl",
    indicator: "31",
  },
  france: {
    code: "fr",
    indicator: "33",
  },
};

//Popover localstorage key name
export const LOCAL_STORAGE_KEY_POPPER_EXP = "headerStoreLocatorPopperExpiry";

//Product Details Anchor
export const PRODUCT_DETAIL_ANCHOR = {
  DESCRIPTION: "description",
  CARACTERISTIQUES: "caracteristique",
  CONSEILS: "conseils",
  DOCUMENTS: "documents",
  AVIS: "avis",
};

export interface SellerTranslatedInfo {
  langId: string;
  sellerName: string;
  sellerDescription: string;
  isDefault: boolean;
  shown?: boolean;
}
//keycodes
export const KEY_CODES = {
  UP: 38,
  DOWN: 40,
  ENTER: 13,
};

export const CommerceEnvironment = {
  productSkeleton: {
    id: "",
    type: "Product",
    productInternal: "",
    productDesc: {},
    eSpotInternal: {},
    eSpotDescInternal: {},
  },
  suggestionSkeleton: {
    arrIndex: "",
    id: "",
    type: "Suggestion",
    name: "",
    url: {},
  },
  errorParamsSkeleton: {
    zero: "",
    one: "",
    two: "",
  },
  defaultLang: "fr_FR",
  languageMap: {
    "-1": "en_US",
    "-2": "fr_FR",
    "-3": "de_DE",
    "-4": "it_IT",
    "-5": "es_ES",
    "-6": "pt_BR",
    "-7": "zh_CN",
    "-8": "zh_TW",
    "-10": "ja_JP",
    "-20": "ru_RU",
    "-21": "ro_RO",
    "-9": "ko_KR",
    "-22": "pl_PL",
    "-23": "ar_EG",
  },
  reverseLanguageMap: {
    en_US: "-1",
    fr_FR: "-2",
    de_DE: "-3",
    it_IT: "-4",
    es_ES: "-5",
    pt_BR: "-6",
    zh_CN: "-7",
    zh_TW: "-8",
    ja_JP: "-10",
    ru_RU: "-20",
    ro_RO: "-21",
    ko_KR: "-9",
    pl_PL: "-22",
    ar_EG: "-23",
  },
  reverseLanguageMapForDateFns: {
    en_US: "en-US",
    fr_FR: "fr",
    de_DE: "de",
    it_IT: "it",
    es_ES: "es",
    pt_BR: "pt-BR",
    zh_CN: "zh-CN",
    zh_TW: "zh-TW",
    ja_JP: "ja",
    ru_RU: "ru",
    ro_RO: "ro",
    ko_KR: "ko",
    pl_PL: "pl",
    ar_EG: "ar",
  },
  dxLanguageMap: {
    "-1": "en",
    "-2": "fr",
    "-3": "de",
    "-4": "it",
    "-5": "es",
    "-6": "pt",
    "-7": "zh",
    "-8": "zh-TW",
    "-10": "ja",
    "-20": "ru",
    "-21": "ro",
    "-9": "ko",
    "-22": "pl",
    "-23": "ar",
  },
};

export const INVENTORY = {
  NON_ATP: -2,
};

export const SUCCESS_MSG_PREFIX = "success-message.";
export const ERROR_MSG_PREFIX = "error-message.";
export const DEFAULT_LANG_ID = "-2";

// Shipping
export const IS_PERSONAL_ADDRESS_ALLOWED = "x_isPersonalAddressesAllowedForShipping";
export const ORG_ADDRESS_DETAILS = "orgAddressDetails";
export const ORG_ADDRESS = "orgAddress";
export const ORDER_ID = "orderid";
export const HYPHEN = "-";
export const UNDERSCORE = "_";
export const SLASH = "/";

// Discover
export const DISCOVER_FEATURE = "Discover";

export const CARD_NUMBER_MASK_CHAR = "*";

//Requisition List, inprogress order
export const PRIVATE_ORDER = "private";
export const SHARED_ORDER = "shared";

export const PAGINATION = {
  sizes: [
    { size: 5, label: "commonTable.pageSz.five" },
    { size: 10, label: "commonTable.pageSz.ten" },
    { size: 20, label: "commonTable.pageSz.twenty" },
  ],
};

export const RF_JSON = "json";

export const CPROF = {
  EXPMTH_PATH: "billingData.expire_month.value",
  EXPYR_PATH: "billingData.expire_year.value",
};

export const OK = "OK";
export const CVV = "CVV";
export const CREATED = "Created";
export const Y = "Y";

export const XS_MOBILE_W = 25.6;
export const S_MOBILE_W = 40.0;
export const M_TABLET_W = 60.0;

//Requisition List action
export const UPLOAD = "upload";

//CSV file Upload Requisition List
export const URL = "URL";
export const UPLOADED_FILE = "UpLoadedFile";
export const CSV_FILE_EXTENSION = ".csv";
export const FILE_REQUEST_HEADERS = {
  "Content-Type": "multipart/form-data",
};
export const EXCLUSIVE = "exclusive";
export const SELLER_FACET = "seller.raw";
export const SELLER_STORAGE_KEY = "seller";
export const AVAILABLE_KEY = "Available";
export const CS = ", "; // comma-space

//PROFILE NAME
export const SEARCH_PLP_PROFILE = "LP_HCL_V2_findProductsByCategory";
export const SEARCH_FIND_PROFILE = "LP_HCL_V2_findProductsBySearchTerm";
export const SEARCH_FIND_PROFILE_PARTNUMBER = "HCL_V2_findProductByPartNumber_Summary";
export const PRODUCT_DETAIL_PROFILE = "LP_HCL_V2_findProductByPartNumber_Details";
export const CATEGORY_FIND_IDENTIFIER = "LP_HCL_V2_findCategoryByIdentifier";
export const PRODUCT_SUMMARY_PROFILE = "LP_HCL_V2_findProductByPartNumber_Summary";
export const FIND_SUB_CATEGORIES = "LP_HCL_V2_findSubCategories";
export const PRODUCT_DETAIL_BY_ID = "LP_HCL_V2_findProductByIds_Details";
export const FIND_CATEGORIE_BY_UNIQUE_ID = "LP_HCL_V2_findCategoryByUniqueIds";

export const FIND_PRODUCT_DETAIL_BY_ID_CART = "LP_HCL_V2_findProductByIds_Details_cart";
export const GENERIC_CUSTOMER = "GENERIC_CUSTOMER_SEGMENT";

export const SELF_PICKUP = "1";
export const PICKUP_ONBEHALF = "2";
export const ACOMP = {
  input: "input",
  reset: "reset",
};
export const PICKUP_PREFIX = "pickup_";
export const MP_ENABLED = "hcl.marketplace.enabled";
export const MP_SELLER_REG_ENABLED = "hcl.marketplace.self-registration";
export const MCW_NUMBER = 125;
export const ZERO_NUMBER = 0;

export const STORE_SCHEDULES = {
  STORE_SCHEDULE: "storeSchedule",
  STORE_EXCEPTIONAL_OPENING: "exceptionalStoreOpening",
  STORE_EXCEPTIONAL_CLOSING: "exceptionalStoreClosing",
};

export const WEEKDAYS = ["lundi", "mardi", "mercredi", "jeudi", "vendredi", "samedi", "dimanche"];
export const MONTHS = [
  "janvier",
  "février",
  "mars",
  "avril",
  "mai",
  "juin",
  "juillet",
  "août",
  "septembre",
  "octobre",
  "novembre",
  "décembre",
];

// CROWNPEAK
export const WIDGET_CROWNPEAK = {
  PRODUCT_DETAIL: "5e54f8e291c86c8a8c8fea82",
  PRODUCT_LIST: "5e54f8e291c86c8a8c8fea82",
  ADD_TO_CART: "5afad50601ffa8311d3df616",
  EMPTY_CART: "5b18eb3abc7bbe13246e9812",
  CART_IS_NO_EMPTY: "5afad528a8c24c31aa6cc168",
  EMPTY_SEARCH: "5b18eb3abc7bbe13246e9812",
  ORDER: "5b18eb3abc7bbe13246e9812",
};

export const BOOK_AN_APPOINTMENT_UNIVERS = [
  {
    title: "Une/des fenêtre.s",
    value: "Fenêtre",
  },
  {
    title: "Une/des porte.s",
    value: "Portes",
  },
  {
    title: "Une cuisine",
    value: "Cuisine",
  },
  {
    title: "Une salle de bain",
    value: "SDB",
  },
  {
    title: "Des rangements",
    value: "Rangements",
  },
  {
    title: "Un escalier",
    value: "Escalier",
  },
  {
    title: "Du sol et/ou des murs",
    value: "Sols et/ou murs",
  },
  {
    title: "Un extérieur",
    value: "Extérieur",
  },
];
export const PROJECT_CONCERNS = [
  {
    label: "Une maison dont je suis propriétaire",
    value: "Propriétaire maison",
  },
  {
    label: "Une maison dont je suis locataire",
    value: "Locataire maison",
  },
  {
    label: "Un appartement dont je suis propriétaire",
    value: "Propriétaire appartement",
  },
  {
    label: "Un appartement dont je suis locataire",
    value: "Locataire appartement",
  },
  {
    label: "Un local professionnel",
    value: "Local professionnel",
  },
];

export const BOOK_AN_APPOINTMENT_PLAN_TO_START_WORK = [
  {
    label: `J'envisage les travaux d'ici 3 mois`,
    value: `D'ici 3 mois`,
  },
  {
    label: `J'envisage les travaux dans 3 mois à 6 mois`,
    value: `Dans 3 mois à 6 mois`,
  },
  {
    label: `J'envisage les travaux dans plus de 6 mois`,
    value: `Dans plus de 6 mois`,
  },
];
