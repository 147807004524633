import { useSelector } from "react-redux";
import useSWR from "swr";
import { DOCUMENT_TYPE } from "../../../constants/order";
import { userIdCRMSelector } from "../../../redux/selectors/user";
import documentService from "../../../_foundation/apis/transaction/document.service";
import { useSite } from "../../../_foundation/hooks/useSite";
import { swrOptionsNoRetry } from "./constants";

export const useCustomerQuotes = (payloadBase: any, page: number = 0, size: number = 10) => {
  const { mySite } = useSite();
  const idCRM = useSelector(userIdCRMSelector);
  //
  const payload = {
    ...payloadBase,
    body: {
      idCRM,
      type: DOCUMENT_TYPE.Devis,
      langId: mySite.defaultLanguageID,
      storeId: mySite.storeID,
      page,
      size,
    },
  };

  return useSWR(idCRM ? payload : null, documentService.storeQuotes, swrOptionsNoRetry);
};

export const useCustomerQuoteById = (payloadBase, documentNumber) => {
  const { mySite } = useSite();

  const payload = {
    ...payloadBase,
    body: {
      storeEntity: mySite.storeID,
      documentNumber,
    },
  };

  return useSWR(documentNumber ? payload : null, documentService.storeQuotesDetails, swrOptionsNoRetry);
};

export const useCustomerQuoteValidity = (payloadBase: Record<any, any>, documentNumber: string | null) => {
  const { mySite } = useSite();
  const magasinCreation = documentNumber?.split("_")[2];
  const payload = {
    ...payloadBase,
    body: {
      storeEntity: mySite.storeID,
      documentNumber,
      magasinCreation,
    },
  };
  return useSWR(documentNumber ? payload : null, documentService.validateQuote, swrOptionsNoRetry);
};

export const useCustomerDocumentQuote = (docId) => {
  return {
    isLoading: false,
    data: {
      data: {
        detailsList: [
          {
            montantNetHT: "1.25",
            mntEcopartTTC: null,
            mntRemiseHt: "0.0",
            magasinCreation: null,
            mntRemiseTtc: "0.0",
            mntEcopartHt: "0.0",
            documentId: "D_24700289_0028",
            itemList: [
              {
                montantNetHT: "24.17",
                prixUnitVenteTTC: "29.0",
                codeArticle: "0643020",
                remiseTTC: "0.0",
                nomArticle: "DECOUPE PLAN P/ENCAST.(EVIER PLAQ.VASQ.)",
                prixUnitCatTTC: "29.0",
                prixUnitCatHT: "24.17",
                typeArticle: "Non utilisé",
                montantPromoTTC: "0.0",
                prixUnitVenteHT: "24.17",
                montantNetTTC: "29.0",
                montantPromoHT: "0.0",
                quantite: "1.0",
              },
              {
                montantNetHT: "0.06",
                prixUnitVenteTTC: "0.07",
                codeArticle: "1000070",
                remiseTTC: "0.0",
                nomArticle: "ECO-MOB 0.25 A 0.5KG STD TOUS MAT",
                prixUnitCatTTC: "0.07",
                prixUnitCatHT: "0.06",
                typeArticle: "Non utilisé",
                montantPromoTTC: "0.0",
                prixUnitVenteHT: "0.06",
                montantNetTTC: "0.07",
                montantPromoHT: "0.0",
                quantite: "1.0",
              },
              {
                montantNetHT: "8.75",
                prixUnitVenteTTC: "10.5",
                codeArticle: "0232310",
                remiseTTC: "0.0",
                nomArticle: "TASSEAU RABOTE 20X30 L.200",
                prixUnitCatTTC: "10.5",
                prixUnitCatHT: "8.75",
                typeArticle: "STANDARD",
                montantPromoTTC: "0.0",
                prixUnitVenteHT: "8.75",
                montantNetTTC: "10.5",
                montantPromoHT: "0.0",
                quantite: "1.0",
              },
              {
                montantNetHT: "8.33",
                prixUnitVenteTTC: "10.0",
                codeArticle: "0643450",
                remiseTTC: "0.0",
                nomArticle: "COLLAGE DE CHANT THERMOCOLLANT PAR ML",
                prixUnitCatTTC: "10.0",
                prixUnitCatHT: "8.33",
                typeArticle: "Non utilisé",
                montantPromoTTC: "0.0",
                prixUnitVenteHT: "8.33",
                montantNetTTC: "10.0",
                montantPromoHT: "0.0",
                quantite: "1.0",
              },
              {
                montantNetHT: "8.33",
                prixUnitVenteTTC: "10.0",
                codeArticle: "0643450",
                remiseTTC: "0.0",
                nomArticle: "COLLAGE DE CHANT THERMOCOLLANT PAR ML",
                prixUnitCatTTC: "10.0",
                prixUnitCatHT: "8.33",
                typeArticle: "Non utilisé",
                montantPromoTTC: "0.0",
                prixUnitVenteHT: "8.33",
                montantNetTTC: "10.0",
                montantPromoHT: "0.0",
                quantite: "1.0",
              },
              {
                montantNetHT: "12.5",
                prixUnitVenteTTC: "15.0",
                codeArticle: "0643050",
                remiseTTC: "0.0",
                nomArticle: "RECOUPE SIMPLE D UN PLAN DE TRAVAIL",
                prixUnitCatTTC: "15.0",
                prixUnitCatHT: "12.5",
                typeArticle: "Non utilisé",
                montantPromoTTC: "0.0",
                prixUnitVenteHT: "12.5",
                montantNetTTC: "15.0",
                montantPromoHT: "0.0",
                quantite: "1.0",
              },
              {
                montantNetHT: "14.0",
                prixUnitVenteTTC: "16.8",
                codeArticle: "3048630",
                remiseTTC: "0.0",
                nomArticle: "CHANT ABS.43 MM CAVIAR ROUL.5 ML",
                prixUnitCatTTC: "16.8",
                prixUnitCatHT: "14.0",
                typeArticle: "STANDARD",
                montantPromoTTC: "0.0",
                prixUnitVenteHT: "14.0",
                montantNetTTC: "16.8",
                montantPromoHT: "0.0",
                quantite: "1.0",
              },
              {
                montantNetHT: "115.5",
                prixUnitVenteTTC: "138.6",
                codeArticle: "3048750",
                remiseTTC: "0.0",
                nomArticle: "PLAN STRAT.CH EPAIS 38MM CAVIAR L.200",
                prixUnitCatTTC: "138.6",
                prixUnitCatHT: "115.5",
                typeArticle: "STANDARD",
                montantPromoTTC: "0.0",
                prixUnitVenteHT: "115.5",
                montantNetTTC: "138.6",
                montantPromoHT: "0.0",
                quantite: "1.0",
              },
              {
                montantNetHT: "24.17",
                prixUnitVenteTTC: "29.0",
                codeArticle: "0643020",
                remiseTTC: "0.0",
                nomArticle: "DECOUPE PLAN P/ENCAST.(EVIER PLAQ.VASQ.)",
                prixUnitCatTTC: "29.0",
                prixUnitCatHT: "24.17",
                typeArticle: "Non utilisé",
                montantPromoTTC: "0.0",
                prixUnitVenteHT: "24.17",
                montantNetTTC: "29.0",
                montantPromoHT: "0.0",
                quantite: "1.0",
              },
              {
                montantNetHT: "1.83",
                prixUnitVenteTTC: "2.2",
                codeArticle: "1000330",
                remiseTTC: "0.0",
                nomArticle: "ECO-MOB 20 A 30KG PAN PART BOIS MASSIF",
                prixUnitCatTTC: "2.2",
                prixUnitCatHT: "1.83",
                typeArticle: "VENTE EXCLUSIVE",
                montantPromoTTC: "0.0",
                prixUnitVenteHT: "1.83",
                montantNetTTC: "2.2",
                montantPromoHT: "0.0",
                quantite: "1.0",
              },
              {
                montantNetHT: "25.0",
                prixUnitVenteTTC: "15.0",
                codeArticle: "0643050",
                remiseTTC: "0.0",
                nomArticle: "RECOUPE SIMPLE D UN PLAN DE TRAVAIL",
                prixUnitCatTTC: "15.0",
                prixUnitCatHT: "12.5",
                typeArticle: "Non utilisé",
                montantPromoTTC: "0.0",
                prixUnitVenteHT: "12.5",
                montantNetTTC: "30.0",
                montantPromoHT: "0.0",
                quantite: "2.0",
              },
              {
                montantNetHT: "115.5",
                prixUnitVenteTTC: "138.6",
                codeArticle: "3051180",
                remiseTTC: "0.0",
                nomArticle: "PLAN STRAT.CH EPAIS 38MM MAGMA L.200",
                prixUnitCatTTC: "138.6",
                prixUnitCatHT: "115.5",
                typeArticle: "Non utilisé",
                montantPromoTTC: "0.0",
                prixUnitVenteHT: "115.5",
                montantNetTTC: "138.6",
                montantPromoHT: "0.0",
                quantite: "1.0",
              },
              {
                montantNetHT: "1.83",
                prixUnitVenteTTC: "2.2",
                codeArticle: "1000330",
                remiseTTC: "0.0",
                nomArticle: "ECO-MOB 20 A 30KG PAN PART BOIS MASSIF",
                prixUnitCatTTC: "2.2",
                prixUnitCatHT: "1.83",
                typeArticle: "VENTE EXCLUSIVE",
                montantPromoTTC: "0.0",
                prixUnitVenteHT: "1.83",
                montantNetTTC: "2.2",
                montantPromoHT: "0.0",
                quantite: "1.0",
              },
              {
                montantNetHT: "12.08",
                prixUnitVenteTTC: "14.5",
                codeArticle: "0037535",
                remiseTTC: "0.0",
                nomArticle: "CARTOUCHE SILICONE SA 2 TRANSPARENT",
                prixUnitCatTTC: "14.5",
                prixUnitCatHT: "12.08",
                typeArticle: "STANDARD",
                montantPromoTTC: "0.0",
                prixUnitVenteHT: "12.08",
                montantNetTTC: "14.5",
                montantPromoHT: "0.0",
                quantite: "1.0",
              },
              {
                montantNetHT: "83.33",
                prixUnitVenteTTC: "100.0",
                codeArticle: "0917060",
                remiseTTC: "0.0",
                nomArticle: "FRAIS DE PORT",
                prixUnitCatTTC: "100.0",
                prixUnitCatHT: "83.33",
                typeArticle: "Non utilisé",
                montantPromoTTC: "0.0",
                prixUnitVenteHT: "83.33",
                montantNetTTC: "100.0",
                montantPromoHT: "0.0",
                quantite: "1.0",
              },
              {
                montantNetHT: "96.63",
                prixUnitVenteTTC: "115.96",
                codeArticle: "7296221",
                remiseTTC: "0.0",
                nomArticle: "HAUT 1PTE H91 LUDIQUE CAVIAR L40 G",
                prixUnitCatTTC: "115.96",
                prixUnitCatHT: "96.63",
                typeArticle: "Non utilisé",
                montantPromoTTC: "0.0",
                prixUnitVenteHT: "96.63",
                montantNetTTC: "115.96",
                montantPromoHT: "0.0",
                quantite: "1.0",
              },
              {
                montantNetHT: "1.25",
                prixUnitVenteTTC: "1.5",
                codeArticle: "1000320",
                remiseTTC: "0.0",
                nomArticle: "ECO-MOB 10 A 20KG PAN PART BOIS MASSIF",
                prixUnitCatTTC: "1.5",
                prixUnitCatHT: "1.25",
                typeArticle: "VENTE EXCLUSIVE",
                montantPromoTTC: "0.0",
                prixUnitVenteHT: "1.25",
                montantNetTTC: "1.5",
                montantPromoHT: "0.0",
                quantite: "1.0",
              },
            ],
            documentRef: "24700289",
            montantNetTTC: "1.5",
            creationDate: "2024-04-09T17:24:14.000",
            statutDoc: "A transformer",
          },
        ],
      },
    },
  };
  //return useSWR(documentId ? payload : null, documentService.getDocumentByID, swrOptionsNoRetry);
};
