/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React from "react";

import { SessionErrorDialog } from "../elements/session-error-modal";
import ConfirmationDialog from "../elements/confirmation-dialog/ConfirmationDialog";
import { StyledFooter } from "./styled-footer";
import FooterReassurance from "./Reassurance";
import FooterLinks from "./FooterLinks";
import FooterContact from "./FContact";
import FooterMention from "./FooterMention";
import FooterLogos from "./FLogos";
import FooterNav from "./FooterNav";

function Footer(props: any) {
  return (
    <>
      <StyledFooter>
        <FooterReassurance />
        <FooterContact />
        <FooterLinks />
        <FooterMention />
        <FooterLogos />
        <FooterNav />
      </StyledFooter>
      <SessionErrorDialog />
      <ConfirmationDialog />
    </>
  );
}

export default React.memo(Footer);
