import styled from "@mui/styled-engine-sc";
import Chip from "@mui/material/Chip";
import { palette } from "../../themes/color-palette";

export const StyledChip = styled(({ ...props }) => <Chip {...props} />)`
  ${({ theme }) => `
  //background: ${theme.palette.gray.neutralGray200};
  &.MuiChip-root {
    font-weight: 400;
    height: 40px;
    border-radius: 100px;
  }

  .MuiChip-deleteIcon {
    color: ${theme.palette.gray.neutralGray800};
  }

  &.MuiChip-colorDefault {
    background: ${theme.palette.gray.neutralGray200};

    ${theme.breakpoints.down("sm")} {
      background: ${theme.palette.white.main};

      &:hover {
        background-color: ${theme.palette.white.main};
      }
    }
  }

  &.MuiChip-colorWarning {
    background: ${theme.palette.tertiary.light};
    color: ${theme.palette.text.alert};
    height: 24px;
    font-weight: 700;
    font-size: 10px;
  }

  &.MuiChip-colorSuccess {
    background: ${palette.success.light};
    color: ${theme.palette.text.successDark};
    height: 24px;
    font-weight: 700;
    font-size: 10px;
    &.small {
      height: 18px;
    }
  }
  &.MuiChip-colorInfo {
    background: ${theme.palette.gray.neutralGray200};
    color: ${theme.palette.gray.neutralGray700};
    height: 24px;
    font-weight: 700;
    font-size: 10px;
  }

  &:hover {
    .MuiChip-deleteIcon {
      color: ${theme.palette.primary.main};
    }
  }


  &.white {
    background: ${theme.palette.white.main};
    height: 20px;
    font-size: 10px;
    font-weight: 700;

    &.MuiChip-outlined {
      border-color: ${theme.palette.gray.neutralGray300};
    }

    &.MuiChip-sizeBig {
      height: 32px;
      font-size: 14px;
      font-weight: 400;
    }

    &.MuiChip-sizeBigger {
      height: 40px;
      font-size: 14px;
      font-weight: 700;
    }

    &.file {
      .MuiChip-label {
        padding: 0  ${theme.spacing(2)};
      }
      .MuiChip-avatar{
        margin-left: ${theme.spacing(3)}
      }
    }
  }

  &.secondary-extralight {
    background: ${theme.palette.secondary.extraLight};
    color: ${theme.palette.secondary.main};
    height: 24px;
    font-size: 12px;
    font-weight: 700;

    &.small {
      height: 18px;
      font-size: 10px;

      .MuiChip-label {
        padding: 0 ${theme.spacing(2)};
      }
    }
  }

  &.transparent {
    background: transparent;
    color: ${theme.palette.secondary.main};
  }

  &.chip-before-after {
    font-size: 14px;
    font-weight: 700;
    padding: 0 ${theme.spacing(1)};
    text-transform: uppercase;
  }

`}
`;
