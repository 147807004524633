import styled from "@mui/styled-engine-sc";
import { Link } from "react-router-dom";

const LinkWrapper = (props: any) => {
  const { testId, ...rest } = props;
  const { to = "" } = props;
  const { href = "" } = props;
  const { pathname } = to;
  let dataTestId;
  if (!testId) {
    dataTestId =
      pathname === "" || pathname
        ? { "data-testid": pathname.split("/").filter(Boolean).join("-") }
        : to
        ? { "data-testid": to.split("/").filter(Boolean).join("-") }
        : {};
  } else {
    dataTestId = { "data-testid": `${testId}-link` };
  }
  if (href) {
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    return <a {...{ ...rest, href }} target="_self" />;
  } else {
    return <Link {...{ ...dataTestId, ...rest, to, href }} />;
  }
};

const StyledLink = styled(LinkWrapper)`
  ${({ theme }) => `
    color: ${theme.palette.black.main};
    text-decoration: none;

    svg {
      color: ${theme.palette.black.main};
    }

    &:hover {
      //color: ${theme.palette.black.dark};

      svg {
        color: ${theme.palette.black.dark};
      }
    }

    &.disabled {
        color: ${theme.palette.text.disabled};
    }

    &.underline {
      text-decoration: underline;
    }

    &.secondary {
      color: ${theme.palette.secondary.main};

      svg {
        color: ${theme.palette.secondary.main};
      }
    }

  `}
`;

export const StyledHeaderFooterLink = styled(({ ...props }) => <StyledLink {...props} />)`
  ${({ theme }) => `
    color: ${theme.palette.text.primary};

    &:hover {
      font-weight: 700;
    }
    &.primary {
      color: ${theme.palette.primary.main};
      font-weight: 700;
    }
    &.secondary {
      color: ${theme.palette.secondary.main};
      font-weight: 700;
    }

  `}
`;

export { StyledLink };
