/* eslint-disable react-hooks/exhaustive-deps */
//Standard libraries
import { useCallback, useEffect, useMemo } from "react";
import getDisplayName from "react-display-name";
//Custom libraries
import { useSite } from "./useSite";
import { useDispatch, useSelector } from "react-redux";
import { currentContractIdSelector } from "../../redux/selectors/contract";
import { ADD_ITEM_ACTION } from "../../redux/actions/order";

export const useCartItem = () => {
  const widgetName = getDisplayName("useCartItem");
  const contractId = useSelector(currentContractIdSelector);
  const { mySite } = useSite();
  const defaultCurrencyID: string = mySite ? mySite.defaultCurrencyID : "";
  const dispatch = useDispatch();
  const controller = useMemo(() => new AbortController(), []);

  const payloadBase: any = {
    contractId: contractId,
    widget: widgetName,
    signal: controller.signal,
  };

  /**
   *Add the selected product and its quantities to the shopping cart
   */
  const addToCart = useCallback(
    (partnumber: any = []) => {
      const param = {
        ...payloadBase,
        currency: defaultCurrencyID,
        partnumber: [partnumber],
        quantity: [1],
        contractId: contractId,
      };
      dispatch(ADD_ITEM_ACTION(param));
    },
    [contractId, payloadBase]
  );

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, []);

  return {
    addToCart,
  };
};
