/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2021
 *
 *==================================================
 */

//Redux
import { RootReducerState } from "../reducers";

const GetCategoriesSelector = (state: RootReducerState) => state.categories.categories;
const GetStaticLinksSelector = (state: RootReducerState) => state.categories.staticLinks;
const GetCurrentCategorySelector = (state: RootReducerState) => state.categories.currentCategory;
const GetFilteredCategorySelector = (state: RootReducerState) => state.categories.filteredCategories;
const GetTopCategorySelector = (state: RootReducerState) => state.categories.topCategories;

export {
  GetCategoriesSelector,
  GetStaticLinksSelector,
  GetCurrentCategorySelector,
  GetFilteredCategorySelector,
  GetTopCategorySelector,
};
