export const dimensions = {
  header: {
    width: {
      desktop: 213,
      mobile: 125,
    },
    height: {
      desktop: 68,
      mobile: 44,
    },
  },
  menu: {
    height: {
      desktop: 40,
    },
  },
  searchBar: {
    width: 550,
    height: 40,
  },
  productCard: {
    swatch: 20,
    thumbnail: 140,
  },
  pagination: {
    button: 40,
  },
  inputFields: {
    height: 42,
  },
  drawerBleeding: {
    height: 80,
  },
  cardHeight: {
    xs: 454,
    sm: 494,
    md: 494,
    lg: 528,
  },
  cardImage: {
    list: {
      xs: 187,
      sm: 221,
    },
    small: 148,
  },
};
