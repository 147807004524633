import React, { useEffect, useRef, useState } from "react";
import { ClickAwayListener, Fade } from "@mui/material";
import { StyledPopper } from "@hcl-commerce-store-sdk/react-component";
import ExpandedMenuItem from "./ExpandedMenuItem";
import { StyledBox, StyledButton, StyledIconButton, StyledLink, StyledTypography } from "../../styled-mui";
import { SvgChevronDown } from "../svg/chevronDown";
import { SvgClose } from "../svg/close";
import { EXTERNAL_LINK, LINK_USAGE } from "../../constants/marketing";
import { useTranslation } from "react-i18next";
import AsyncCall from "../../_foundation/gtm/async.service";
import { CLICK } from "../../_foundation/constants/gtm";
// import { useSite } from "../../_foundation/hooks/useSite";

const MenubarMenuItem = (props) => {
  const { t } = useTranslation();
  //const { mySite } = useSite();
  const { isProduct = false, categories, link } = props;
  const [selectedMenuItem, setSelectedMenuItem] = useState<any | null>(null);
  const popperRef: React.RefObject<HTMLButtonElement> = useRef<HTMLButtonElement>(null);
  const [scrollDir, setScrollDir] = useState("up");

  const closeMenu = (event) => {
    const target = event.target;
    if (popperRef.current && (popperRef.current.contains(target) || popperRef.current === target)) {
      return;
    }
    if (selectedMenuItem === "product") {
      setSelectedMenuItem(null);
    }
  };

  //remove width update, since the menu title is repeated in menu item.
  const onMouseOver = () => {
    setSelectedMenuItem("product");
  };

  const onMouseOut = () => {
    if (isProduct) return;
    setSelectedMenuItem(null);
  };

  const onClick = (link) => {
    //GA360
    // if (mySite.enableGA) {
    //   AsyncCall.sendNavigationClick(
    //     { eventAction: "Main", eventLabel: link },
    //     {
    //       enableUA: mySite.enableUA,
    //       enableGA4: mySite.enableGA4,
    //     }
    //   );
    // }
  };

  const handleClose = (event) => {
    event.preventDefault();
    setSelectedMenuItem(null);
  };

  useEffect(() => {
    const threshold = 0;
    let lastScrollY = window.scrollY;
    let ticking = false;

    const updateScrollDir = () => {
      const scrollY = window.scrollY;

      if (scrollY > 150) {
        if (Math.abs(scrollY - lastScrollY) < threshold) {
          ticking = false;
          return;
        }
        setScrollDir(scrollY > lastScrollY ? "down" : "up");
        lastScrollY = scrollY > 0 ? scrollY : 0;
      }
      ticking = false;
    };

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir);
        ticking = true;
      }
    };

    window.addEventListener("scroll", onScroll);

    if (scrollDir === "down") {
      setSelectedMenuItem(null);
    }

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, [scrollDir]);

  return (
    <>
      <StyledBox
        pr={4}
        pl={isProduct ? 0 : 4}
        className={`${selectedMenuItem === "product" ? "expanded-menu expanded-menu-hover" : "expanded-menu"} ${
          link?.usage === LINK_USAGE.EVENT ? "expanded-menu-hover-blue" : ""
        }`}>
        {isProduct ? (
          <StyledButton
            testId={`header-product`}
            variant="text"
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
            color="black"
            className=""
            ref={popperRef}
            sx={{ height: "56px" }}
            endIcon={<SvgChevronDown />}>
            <StyledTypography className="expanded-menu-item" variant="body4" data-testid="menubar-menuitem-button">
              {t("Header.Menu.ProductTitle")}
            </StyledTypography>
          </StyledButton>
        ) : (
          <>
            {link && (
              <StyledLink
                onMouseOver={onMouseOver}
                onMouseOut={onMouseOut}
                className=""
                testId={`header-${link.seo.label}`}
                key={"Link_" + link.seo.label}
                to={link.seo.url}
                target={link.seo.target || "_self"}
                href={link.seo.type === EXTERNAL_LINK ? link.seo.url : null}
                onClick={() => {
                  AsyncCall.sendClick(CLICK.NAV, { navlink: link.seo.url });
                  onClick(link.seo.label);
                }}>
                <StyledTypography
                  color={
                    link?.usage === LINK_USAGE.PROMO
                      ? "primary"
                      : link?.usage === LINK_USAGE.EVENT
                      ? "secondary"
                      : "black"
                  }
                  className="expanded-menu-item"
                  variant={link?.usage === LINK_USAGE.STANDARD ? "body4" : "body3"}
                  data-testid="menubar-menuitem-button"
                  sx={{ display: "flex", alignItems: "center", height: "56px" }}>
                  {link.seo.label}
                </StyledTypography>
              </StyledLink>
            )}
          </>
        )}
      </StyledBox>

      {isProduct && (
        <ClickAwayListener onClickAway={closeMenu}>
          <StyledPopper
            onMouseLeave={() => setSelectedMenuItem(null)}
            id="menu-popper-produits"
            data-testid="menu-popper-produits"
            open={selectedMenuItem === "product"}
            anchorEl={popperRef.current}
            placement="bottom-start"
            disablePortal
            className="popper-container"
            modifiers={[
              {
                name: "flip",
                enabled: false,
              },
              {
                name: "preventOverflow",
                enabled: false,
              },
              {
                name: "hide",
                enabled: false,
              },
            ]}
            transition>
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <StyledBox>
                  <ExpandedMenuItem
                    categories={categories}
                    onSelectedItem={() => setSelectedMenuItem(null)}></ExpandedMenuItem>
                  <StyledIconButton onClick={handleClose} className="expanded-menu-svg-close">
                    <SvgClose color="black" />
                  </StyledIconButton>
                </StyledBox>
              </Fade>
            )}
          </StyledPopper>
        </ClickAwayListener>
      )}
    </>
  );
};

export default MenubarMenuItem;
