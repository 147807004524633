/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
import React from "react";
import styled from "@mui/styled-engine-sc";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { dimensions } from "../../themes/variables";
import { palette } from "../../themes/color-palette";

const headerHeight = dimensions.header.height.desktop;
const mobileHeaderHeight = dimensions.header.height.mobile;

const StyledSwipeableDrawer = styled(({ ...props }) => <SwipeableDrawer {...props} />)`
  ${({ theme }) => `

  .MuiBackdrop-root {

    ${theme.breakpoints.up("sm")} {
      background: none;
    }
  }

  .MuiDrawer-paperAnchorTop {
    margin-top: ${headerHeight}px;
    border-top: 1px solid ${theme.palette.action.disabledBackground};
    box-shadow: 0px 3px 4px 0px ${theme.palette.text.disabled};
    padding: ${theme.spacing(3)} 0 ${theme.spacing(2)};

    ${theme.breakpoints.down("md")} {
      margin-top: ${mobileHeaderHeight}px;
    }
  }

  &.header-menu-level2 {
    .MuiBackdrop-root {
      background: none;
    }
    .MuiTypography-body1 {
      text-transform: none;
      font-weight: 400;
      max-width: 100%;
      text-wrap: wrap;
    }
  }

  .MuiDrawer-paperAnchorLeft {
    width: 84%;
    max-width: 315px;
    padding-top: ${theme.spacing(16)};

    .menu-container {
      margin: 0;
      width: 100%;

      .menu-mobile-button {
        width: 100%;
        display: block;
        text-align: left;
        position: relative;
        text-transform: none;

        .MuiButton-endIcon {
          position: absolute;
          right: ${theme.spacing(5)};
          top: ${theme.spacing(3)};
        }

        &.menu-mobile-button-collection {
          .MuiButton-endIcon {
            right: 0;
          }
        }
      }

      .menu-mobile-link-top {
        padding: 0 ${theme.spacing(5)};
        min-height: ${theme.spacing(11)};
        display: flex;
        align-items: center;
        width: 100%;
        position: relative;
        &.bg {
          background: ${palette.blue.extraLight};
        }
        &.border {
          padding: 0 ${theme.spacing(4)};
          border-left: ${palette.primary.main} ${theme.spacing(1)} solid;
        }
        &.icon {
          svg {
            position: absolute;
            right: 17px;
            top: 12px;
            font-size: 20px;
            color: ${palette.blue.background};

            /* path {
              fill: ${palette.blue.background};
            } */
          }
        }
      }

      .menu-mobile-button-bottom {
        position: absolute;
        bottom: ${theme.spacing(8)};
        padding: 0 ${theme.spacing(5)};
      }
      .menu-mobile-navigation-top {
        position: absolute;
        top: 0;
        left: 0;
        //padding: ${theme.spacing(5)};
        height: ${theme.spacing(16)};
        border-bottom: ${palette.gray.neutralGray300} 1px solid;
      }

      .menu-mobile-navigation-top-svg-back {
        //margin: ${theme.spacing(1)} 0;
        border-right: ${palette.gray.neutralGray300} 1px solid;
      }
      .menu-mobile-navigation-top-svg-close {
        margin-left: auto;
      }
      .collection-menu-box {
        position: relative;
        height: 88px;
        border-radius: 8px;

        &:before {
          content :"";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: 8px;
          background: ${theme.palette.gray.neutralGray900};
          opacity: 0.4;
        }

        .collection-menu-image {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 8px;
        }

        .collection-menu-text {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          text-transform: uppercase;
        }
      }
    }
  }


  .MuiExpansionPanel-root:before {
    content: none;
  }

  .MuiExpansionPanelSummary-root {
    padding: 0;

    &:not(.level-1) {
      min-height: ${theme.spacing(4)};
      height: ${theme.spacing(4)};
    }
  }

  .MuiExpansionPanelSummary-content {
    margin: 0;

    ${theme.breakpoints.up("sm")} {
      display: block;
    }
  }

  .MuiExpansionPanelDetails-root {
    display: block;
    padding: 0;
  }


  .level-1 {
    ${theme.breakpoints.up("sm")} {
    .MuiExpansionPanelSummary-content {
        border-bottom: 1px solid ${theme.palette.action.disabled};
      }
      .MuiExpansionPanelSummary-expandIcon {
        display: none;
      }
    }
  }

  ${theme.breakpoints.down("md")} {
    .MuiMenuItem-root {
      padding: 0 ${theme.spacing(5)};
      min-height: ${theme.spacing(11)};
      /*min-height: ${theme.spacing(4)};*/

      &:hover {
        background: none;
        color: ${theme.palette.text.primary};

        .MuiTypography-root {
          color: ${theme.palette.text.primary};
        }
      }
    }

    /*.MuiGrid-item {
      padding: 0;
    }*/

    .MuiIconButton-edgeEnd {
      margin-right: 0;
    }

    .MuiIconButton-root {
      padding: ${theme.spacing(1)};
    }
  }
  &.product-drawer-basket {
    .product-drawer-container {
      padding: ${theme.spacing(5)} ${theme.spacing(5)} ${theme.spacing(1)} ${theme.spacing(5)};
    }

    .product-drawer-puller {
      width: 40px;
      height: 4px;
      background-color: ${palette.gray.neutralGray300};
      border-radius: 4px;
      position: absolute;
      top: 8px;
      left: calc(50% - 20px);
    }
    .MuiPaper-root {
      height: calc(50% - ${dimensions.drawerBleeding.height}px);
      overflow: visible;
    }
  }

  &.account-sidebar-drawer {
    top: calc(${mobileHeaderHeight}px + 48px);

    .MuiBackdrop-root {
      top: calc(${mobileHeaderHeight}px + 48px);
    }
    .MuiDrawer-paperAnchorTop,
    .MuiDrawer-paperAnchorRight {
      //top: calc(${mobileHeaderHeight}px + 48px);
      margin-top: 0;
      padding-top: 0;
      position: relative;
    }

  }

  `}
`;

export { StyledSwipeableDrawer };
