export const scrollToTop = () => {
  setTimeout(() => {
    (window.top ?? window).scrollTo(0, 0);
    const elementMain = document.getElementById("main");
    const elementHeader = document.getElementById("header");
    if (elementMain && elementHeader) {
      elementHeader.classList.remove("compact");
      setTimeout(() => {
        elementMain.style.paddingTop = `${elementHeader.clientHeight}px`;
      }, 300);
    }
  });
};
