import SvgIcon from "@mui/material/SvgIcon";
import styled from "@mui/styled-engine-sc";

const CustomSvg = styled((props) => (
  <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} />
))`
  ${({ theme }) => `
    width:20px;
    height:20px;

    &.small {
      width:12px;
      height:12px;
    }

    &.breadcrumbs {
      width:35px;
      height:35px;
      color: ${theme.palette.secondary.main}
    }
  `}
`;

export const SvgChevronBack = (props) => {
  const { className = "" } = props;

  return (
    <CustomSvg {...{ className }}>
      <path d="M7.292 10.004c0-.106.017-.213.05-.312a.69.69 0 0 1 .173-.27l3.823-3.792a.762.762 0 0 1 .562-.213c.223 0 .42.082.57.238a.79.79 0 0 1 .23.574.746.746 0 0 1-.23.575l-3.22 3.2 3.244 3.217a.784.784 0 0 1 .215.566c0 .222-.083.41-.24.567a.78.78 0 0 1-.577.23.768.768 0 0 1-.579-.23l-3.798-3.775a.69.69 0 0 1-.173-.271.95.95 0 0 1-.05-.312v.008Z" />
    </CustomSvg>
  );
};
