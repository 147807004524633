/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
export const PERSONALIZATION_ID = "personalizationID";
export const INITIATED_FROM_STORAGE = "initiatedFromStorage";
export const USER_ZIPCODE = "userZipcode";
