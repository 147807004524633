/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
import styled from "@mui/styled-engine-sc";
// import { useMatch } from "react-router";
import { useSelector } from "react-redux";
//custom libraries
// import { HOME } from "../../../constants/routes";
//custom redux
import { forUserIdSelector } from "../../../redux/selectors/user";
import { useSite } from "../../../_foundation/hooks/useSite";
import { MP_ENABLED, MP_SELLER_REG_ENABLED, STRING_TRUE } from "../../../constants/common";
import { palette } from "../../../themes/color-palette";

const StyledWrapper = styled("div")`
  ${(props) => {
    const theme = props.theme;
    // const match = useMatch({
    //   path: HOME,
    //   end: true,
    //   caseSensitive: true,
    // });
    const forUserId = useSelector(forUserIdSelector);
    const { mySite } = useSite();
    const { userData = {} } = mySite?.storeCfg ?? {};
    const mpSellerRegEnabled = STRING_TRUE === userData[MP_ENABLED] && STRING_TRUE === userData[MP_SELLER_REG_ENABLED];

    let headingClasses = "";

    for (let i = 1; i <= 6; i += 1) {
      headingClasses += `
        h${i}:not(.MuiTypography-root) {
          font-size: ${theme.typography[`h${i}`].fontSize}px;
          font-weight: ${theme.typography[`h${i}`].fontWeight};
          line-height: ${theme.typography[`h${i}`].lineHeight};
        }
      `;
    }

    let paddingClasses = "";

    for (let i = 1; i <= 20; i += 1) {
      paddingClasses += `
        .horizontal-padding-${i} {
          padding-left: ${theme.spacing(i)};
          padding-right: ${theme.spacing(i)};
        }
        .vertical-padding-${i} {
          padding-top: ${theme.spacing(i)};
          padding-bottom: ${theme.spacing(i)};
        }
        .top-padding-${i} {
          padding-top: ${theme.spacing(i)};
        }
        .bottom-padding-${i} {
          padding-bottom: ${theme.spacing(i)};
        }
        .left-padding-${i} {
          padding-left: ${theme.spacing(i)};
        }
        .right-padding-${i} {
          padding-right: ${theme.spacing(i)};
        }
        .horizontal-margin-${i} {
          margin-left: ${theme.spacing(i)};
          margin-right: ${theme.spacing(i)};
        }
        .vertical-margin-${i} {
          margin-top: ${theme.spacing(i)};
          margin-bottom: ${theme.spacing(i)};
        }
        .top-margin-${i} {
          margin-top: ${theme.spacing(i)};
        }
        .bottom-margin-${i} {
          margin-bottom: ${theme.spacing(i)};
        }
        .left-margin-${i} {
          margin-left: ${theme.spacing(i)};
        }
        .right-margin-${i} {
          margin-right: ${theme.spacing(i)};
        }
      `;
    }

    return `
      //font-family: ${theme.typography.fontFamily};

      ${mpSellerRegEnabled ? ".marketplace-seller-reg-off { display: none !important; }" : ""}
      ${!mpSellerRegEnabled ? ".marketplace-seller-reg-on { display: none !important; }" : ""}

      ${paddingClasses}
      ${headingClasses}

      #main {
        transition: all .2s;
      }

      .border-top {
        border-top: 1px solid ${theme.palette.gray.neutralGray300};
      }

      .vertical-center {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }

      .horizontal-center {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }

      .horizontal-center.vertical-center,
      .full-center {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
      }

      .full-height {
        height: 100%;
      }

      .full-width {
        width: 100%;
      }

      .full-viewport-height {
        ${forUserId !== undefined ? `min-height: 1000px;` : `min-height: 100vh;`}
      }

      .full-viewport-width {
        width: 100vw;
      }

      .text-align-center {
        text-align: center;
      }

      .text-align-right {
        text-align: right;
      }

      .text-align-left {
        text-align: left;
      }

      .display-block {
        display: block;
      }

      .display-flex {
        display: flex;
      }

      .flex-center {
        align-items: center;
      }

      .flex-wrap {
        flex-wrap: wrap;
      }

      .no-margin {
        margin: 0 !important;
      }

      .pointer-event-none {
        pointer-events: none;
      }

      .uppercase {
        text-transform: uppercase;
      }

      .line-through {
        text-decoration: line-through;
      }


      .hide {
        display: none;
      }

      .marketing-button.MuiButton-containedPrimary {
        border: none;

        &:hover {
          border: none;
        }

        &.Mui-disabled {
          background: ${theme.palette.primary.dark};
          border: none;
          color: white;
          opacity: 0.5;
        }
      }

      // .marketing-button {
      //   font-family: ${theme.typography.fontFamily};
      //   font-size: ${theme.typography.button.fontSize}px;
      //   border-radius: ${theme.shape.borderRadius}px;
      //   padding: ${theme.spacing(1)} ${theme.spacing(2)};
      //   letter-spacing: 0.02rem;
      //   box-shadow: none;
      //   color: ${theme.palette.text.secondary};
      //   line-height: 1.75;
      //   font-weight: 500;
      //   border: 2px solid ${theme.palette.primary.main};
      //   background: white;
      //   cursor: pointer;
      //   background: white;
      //   transition: all ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeIn};

      //   &:hover {
      //     border-color: ${theme.palette.primary.dark};
      //     color: ${theme.palette.primary.dark};
      //   }

      //   &.primary {
      //     background: ${theme.palette.primary.main};
      //     border-width: 0;
      //     color: white;

      //     &:hover {
      //       background: ${theme.palette.primary.dark};
      //     }
      //   }
      // }
      }

      img {
        max-width: 100%;
      }

      .product-list-conseil-rdv {
        background: ${palette.beige.main};
        border-radius: ${theme.spacing(2)};
        color: ${palette.blue.dark};
        overflow: hidden;
        height: 100%;
        padding-top: 20px;

        svg {
          width: 100%;
          max-height: 122px;
        }

        .product-list-conseil-rdv-svg {
          max-width: 100%;
          width: 100%;
          height: auto;
          display: block;
          margin: 0px auto;
        }

        .product-list-conseil-rdv-content {
          padding: ${theme.spacing(1)} ${theme.spacing(4)} ${theme.spacing(4)} ${theme.spacing(4)};
        }

        .product-list-conseil-rdv-txt {
          margin: 0;
          font-size: 26px;
          font-weight: 900;
          line-height: 32px;
          padding-bottom: ${theme.spacing(3)};
        }

        .product-list-conseil-rdv-stroke {
          color: ${palette.beige.main};
          text-shadow: ${palette.blue.dark} 1px 1px 0px, ${palette.blue.dark} -1px -1px 0px, ${
      palette.blue.dark
    } 1px -1px 0px, ${palette.blue.dark} -1px 1px 0px, ${palette.blue.dark} 1px 1px 0px;
        }

        .product-list-conseil-rdv-list {
          margin-left: ${theme.spacing(4)};
          padding-bottom: ${theme.spacing(2)};
          font-size: 12px;
          font-weight: 700;
          line-height: 16px;
          li {
            padding-bottom: ${theme.spacing(2)};
          }
        }
        .product-list-conseil-rdv-btn {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          position: relative;
          box-sizing: border-box;
          -webkit-tap-highlight-color: transparent;
          background-color: transparent;
          outline: 0px;
          border: 0px;
          margin: 0px;
          padding: 0px;
          cursor: pointer;
          user-select: none;
          vertical-align: middle;
          appearance: none;
          text-decoration: none;

          font-weight: 700;
          font-family: Montserrat, sans-serif;
          text-transform: uppercase;
          min-width: 64px;
          background-color: rgb(93, 137, 162);
          width: 100%;
          color: rgb(249, 244, 238);

          max-height: 47px;

          transition: border-radius 150ms ease 0s, background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

          font-size: 14px;
          line-height: 16px;
          padding: 12px 20px;

          border-radius: 8px;
          box-shadow: rgba(0, 0, 0, 0.004) 0px 1px 2px 2px;

        }
      }
    `;
  }}
`;

export default StyledWrapper;
