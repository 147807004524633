export const palette = {
  primary: {
    main: "#EB002D",
    light: "#FCE9EB",
    dark: "#AA192D",
    extraDark: "#1F1416",
  },
  gray: {
    neutralGray900: "#252427",
    neutralGray800: "#424242",
    neutralGray700: "#514E52",
    neutralGray600: "#787277",
    neutralGray500: "#9E9E9E",
    neutralGray400: "#BDBDBD",
    neutralGray300: "#E0E0E0",
    neutralGray200: "#EEEEEE",
    neutralGray100: "#F5F5F5",
    neutralGray50: "#FAFAFA",
  },
  alert: {
    main: "#ED810C",
    light: "#FFC382",
    dark: "#893603",
  },
  success: {
    main: "#5EBA30",
    light: "#D9F0C9",
    dark: "#237113",
  },
  error: {
    main: "#EB002D",
  },
  text: {
    primary: "#212121",
    secondary: "#3F6AB2",
    disabled: "#B5BECA",
    highlight: "#f44336",
    expandedMenu: "#FFFFFF",
  },
  border: {
    main: "#E2E1E4",
    dark: "#787277",
    hover: "#F0F4F7",
  },
  divider: {
    dark: "#4C5256",
  },
  background: {
    default: "#F4F4F4",
    paper: "#FFFFFF",
    transparency: "CC",
  },
  disabled: {
    background: "#F0F4F7",
  },
  white: {
    main: "#FFFFFF",
  },
  blue: {
    main: "#3F6AB2",
    light: "#DFE7EC",
    extraLight: "#EFF3F6",
    dark: "#5D89A2",
    background: "#3D6881",
  },
  beige: {
    main: "#F4EADE",
    light: "#F9F4EE",
  },

  vertAmenagementExterieurs: {
    main: "#86A781",
  },
  univers: {
    cuisine: "#8779B7",
    salleDeBain: "#6EBACB",
    fenetre: "#E6658B",
    amenagement: "#CC7364",
  },
};
