import { SelectChangeEvent } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { StyledButton, StyledFormControl, StyledInputLabel, StyledMenuItem, StyledSelect } from "../../../styled-mui";
import { StoreLocatorWidgetList } from "./store-locator-widget-list";
import { useDispatch, useSelector } from "react-redux";
import { isLoadingStoresListSelector, storesListSelector } from "../../../redux/selectors/stores";
import { STORES_CLOSE_MODAL_ACTION } from "../../../redux/actions/stores";
import getDisplayName from "react-display-name";
import { STORELOCATORACTIONS } from "../../../_foundation/constants/common";
import { logonIdSelector } from "../../../redux/selectors/user";
import { UPDATE_USER_DETAILS_REQUEST_ACTION } from "../../../redux/actions/user";
import { useStoreLocatorValue } from "../../../_foundation/context/store-locator-context";

interface Props {
  preferredStore: Record<any, any>;
  setPreferredStore: Dispatch<SetStateAction<Record<any, any>>>;
}

export const StoreLocatorWidgetSelect: React.FC<Props> = ({ preferredStore, setPreferredStore }) => {
  const dispatch = useDispatch();
  const { dispatch: dispatchStoreLocatorValue } = useStoreLocatorValue();
  const logonId = useSelector(logonIdSelector);
  const stores = useSelector(storesListSelector);
  const loading = useSelector(isLoadingStoresListSelector);

  const handleSetStore = () => {
    dispatchStoreLocatorValue({
      type: STORELOCATORACTIONS.UPDATE_STORE_SELECTION,
      payload: { selectedStore: preferredStore },
    });

    if (logonId && preferredStore) {
      const widgetName = getDisplayName(StoreLocatorWidgetList);

      const payloadBase: any = {
        widget: widgetName,
      };

      const parameters: any = {
        ...payloadBase,
        body: {
          logonId,
          email1: logonId,
          x_demographicField7: preferredStore.id,
          x_demographicField5: JSON.stringify([preferredStore.id]),
        },
      };
      dispatch(UPDATE_USER_DETAILS_REQUEST_ACTION({ parameters, closeModal: false, hideSnackbar: true }));
    } else {
      dispatch(STORES_CLOSE_MODAL_ACTION());
    }
  };

  const isEmpty = (obj: object) => {
    return Object.keys(obj).length === 0;
  };

  const handleChangeStore = (event: SelectChangeEvent) => {
    setPreferredStore(event.target.value as any);
  };

  const canSaveStore = (): boolean => {
    if (!isEmpty(preferredStore)) {
      return !!preferredStore.id.trim();
    } else {
      return false;
    }
  };

  return (
    <>
      <StyledFormControl variant="outlined" fullWidth focused sx={{ my: 6 }} className="outlined-label">
        <StyledInputLabel variant="standard" htmlFor="preferred-store">
          Votre magasin préféré
        </StyledInputLabel>
        <StyledSelect
          id="preferred-store"
          name="preferredStore"
          value={isEmpty(preferredStore) ? "" : preferredStore}
          onChange={handleChangeStore}
          disabled={!stores || stores.length === 0}
          displayEmpty>
          <StyledMenuItem disabled value="">
            Selectionnez un magasin
          </StyledMenuItem>
          {stores &&
            stores.map((store) => {
              return (
                <StyledMenuItem value={store} key={store.uniqueID}>
                  {store.Description?.length > 0 ? `${store.Description[0].displayStoreName} - ` : ""}
                  {store.postalCode || ""} {store.city}
                </StyledMenuItem>
              );
            })}
        </StyledSelect>
      </StyledFormControl>
      <StyledButton
        fullWidth
        testId="preferred-store-submit"
        type="submit"
        color="secondary"
        disabled={!canSaveStore()}
        onClick={handleSetStore}
        loading={loading}>
        Continuer
      </StyledButton>
    </>
  );
};
