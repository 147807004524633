import React from "react";

import CircularProgress from "@mui/material/CircularProgress";
const ElementLoader: React.FC = () => {
  return (
    <span style={{ display: "inline-flex", margin: "0 10px" }}>
      <CircularProgress style={{ width: "15px", height: "15px" }} />
    </span>
  );
};

export default ElementLoader;
