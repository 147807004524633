import styled from "@mui/styled-engine-sc";
import FormControl from "@mui/material/FormControl";
import { palette } from "../../themes/color-palette";

const StyledFormControl = styled(({ ...props }) => <FormControl {...props} />)`
  ${({ theme }) => `
    &.underline-gray {
      .MuiInputLabel-root {
        color: ${palette.gray.neutralGray600};
        font-weight: 400;
      }
      .MuiInputBase-root {
        height: auto;
        &:before {
          border-bottom: ${palette.gray.neutralGray400} 1px solid;
        }
        &:hover {
          &:not(.Mui-disabled) {
            &:after {
              border-bottom: ${palette.gray.neutralGray500} 1px solid;
            }
          }
        }
        &:after {
          border-bottom: ${palette.gray.neutralGray400} 1px solid;
        }
        &.Mui-error:after {
          border-bottom-color: ${palette.primary.main};
        }
        &.MuiInputBase-colorSuccess:after {
          border-bottom-color: ${palette.success.main};
        }
        .MuiInputBase-input {
          font-weight: 400;

          /* &:-webkit-autofill,
          &:-webkit-autofill:hover,
          &:-webkit-autofill:focus {
            -webkit-box-shadow: 0 0 0px 1000px ${palette.blue.extraLight} inset !important;
          } */
          &:autofill {
            background: none;
          }
        }

      }
      &.bg-beige {
        .MuiInputBase-input {
          font-weight: 400;

          &:-webkit-autofill,
          &:-webkit-autofill:hover,
          &:-webkit-autofill:focus {
            -webkit-box-shadow: 0 0 0px 1000px ${palette.beige.light} inset !important;
          }
        }
      }
    }
    &.outlined-label {
      .MuiInputLabel-root {
        color: ${palette.gray.neutralGray600};
        font-weight: 400;
      }
      label + .MuiOutlinedInput-root {
        margin-top: ${theme.spacing(5)};
      }
      .Mui-error {
        &:after {
          border-bottom-color: ${palette.primary.main};
        }
      }
    }
    &.outlined-bold {
      .MuiOutlinedInput-input {
        font-weight: 700;
      }
    }
  `}
`;

export { StyledFormControl };
