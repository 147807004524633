/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
import React from "react";
import styled from "@mui/styled-engine-sc";
import Modal from "@mui/material/Modal";

const ComponentWrapper = React.forwardRef((props: any, ref: any) => {
  return <Modal {...props} ref={ref} />;
});

export const StyledModal = styled(ComponentWrapper)`
  ${({ theme }) => `
    .MuiBackdrop-root {
      background-color:rgba(33, 33, 33, 0.85);
    }
    .modal-container {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      width: 96%;
      //max-width: 525px;
      max-width: 70%;
      background-color: ${theme.palette.white.main};
      border-radius: ${theme.shape.borderRadius}px;
      padding: ${theme.spacing(10)};
      outline: 0;

      ${theme.breakpoints.down("sm")} {
        width: 100%;
        max-width: 100%;
        top: auto;
        bottom: 0;
        transform: translateX(-50%) translateY(0);
        border-radius: ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0;
      }

      ${theme.breakpoints.down("md")} {
        padding: ${theme.spacing(5)};
      }

      &.small {
        max-width: 40%;

        ${theme.breakpoints.down("lg")} {
          max-width: 50%;
        }
        ${theme.breakpoints.down("sm")} {
          max-width: 100%;
        }

      }

      &.medium {
        max-width: 50%;

        ${theme.breakpoints.down("sm")} {
          max-width: 100%;
        }

      }

      &.big {
        max-width: 90%;

        /* ${theme.breakpoints.down("lg")} {
          max-width: 50%;
        } */
        ${theme.breakpoints.down("sm")} {
          max-width: 100%;
        }

      }

      &.product-image-popin {
        ${theme.breakpoints.down("sm")} {
          top: 50%;
          bottom: auto;
          transform: translateX(-50%) translateY(-50%);
          border-radius: ${theme.shape.borderRadius}px;
        }
      }

      &.mosaic-popin {
        padding: ${theme.spacing(6)} 0;
        ${theme.breakpoints.down("sm")} {
          top: 50%;
          bottom: auto;
          transform: translateX(-50%) translateY(-50%);
          border-radius: ${theme.shape.borderRadius}px;
        }
      }

      &.compare-popin {
        padding: ${theme.spacing(10)};

        ${theme.breakpoints.down("sm")} {
          padding: ${theme.spacing(6)};
          top: 50%;
          bottom: auto;
          transform: translateX(-50%) translateY(-50%);
          border-radius: ${theme.shape.borderRadius}px;
        }
      }

      &.modal-add-to-cart {
        padding: ${theme.spacing(10)} 0 0 0;
        border-top: ${theme.palette.text.success} 8px solid;
      }

      &.store-choice-popin {
        padding: 0;

      }

      &.project-popin {
        background-color: ${theme.palette.secondary.extraLight};
      }

      &.modal-collection {
        max-height: 75%;
        overflow: scroll;
      }



      &.modal-overflow {
        ${theme.breakpoints.down("sm")} {
          top: ${theme.spacing(15)};
          bottom: auto;
          transform: translateX(-50%) translateY(0);
          /*height: -webkit-fill-available;
          height: -moz-available;*/
          max-height: none;
        }
      }
    }


    .modal-close {
      position: absolute;
      right: 0;
      z-index: 21;
      color: white;
      top: -45px;
    }

    .modal-title {
      position: absolute;
      left: 0;
      z-index: 21;
      color: white;
      top: -55px;

      ${theme.breakpoints.down("sm")} {
        left: ${theme.spacing(3)};
      }
    }

    .modal-container-with-mention {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      width: 96%;
      //max-width: 525px;
      max-width: 70%;
      background-color: ${theme.palette.gray.neutralGray200};
      border-radius: ${theme.shape.borderRadius}px;
      outline: 0;

      ${theme.breakpoints.down("sm")} {
        width: 100%;
        max-width: 100%;
        top: auto;
        bottom: 0;
        transform: translateX(-50%) translateY(0);
        border-radius: ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0;
      }
      &.modal-overflow {
        ${theme.breakpoints.down("sm")} {
          top: ${theme.spacing(15)};
          bottom: auto;
          transform: translateX(-50%) translateY(0);
        }
      }
    }

    .modal-square-image {
      aspect-ratio: 1;
      border-radius: 8px;
      object-fit: cover;
      @media only screen and (min-device-width: 1280px) and (max-device-width: 1920px)   {
        max-width:370px;
      }
    }
  `}
`;
