// Account routes constants

export const ACCOUNT_URLS = {
  MY_PROJECTS: {
    KEY: "my-projects",
    LABEL: "Mes projets",
  },
  MY_PROJECTS_STEP_1: {
    KEY: "my-projects-step1",
    LABEL: "Mon projets - Etape 1",
  },
  MY_PROJECTS_STEP_2: {
    KEY: "my-projects-step2",
    LABEL: "Mon projets - Etape 2",
  },
  MY_PROJECTS_STEP_3: {
    KEY: "my-projects-step3",
    LABEL: "Mon projets - Etape 3",
  },
  MY_FAVORITES_STORES: {
    KEY: "my-favorite-stores",
    LABEL: "Mes magasins favoris",
  },
  MY_ACCOUNT: {
    KEY: "my-account",
    LABEL: "Mon compte",
  },
  MY_ADRESSES: {
    KEY: "my-adresses",
    LABEL: "Mes adresses",
  },
  MY_PREFERENCES: {
    KEY: "my-preferences",
    LABEL: "Mes préférences",
  },
  MY_QUOTES: {
    KEY: "my-quotes",
    LABEL: "Mes devis",
  },
  MY_ORDERS: {
    KEY: "my-orders",
    LABEL: "Mes commandes et mes factures",
  },
  FAQ: {
    KEY: "faq",
    LABEL: "Questions fréquentes",
  },
  CONTACT_US: {
    KEY: "contact-us",
    LABEL: "Nous contacter",
  },
  SAV: {
    KEY: "sav",
    LABEL: "Service Après-Vente",
  },
};
