import { useTranslation } from "react-i18next";
//Foundation libraries
import { StyledBox, StyledTypography } from "../../../styled-mui";
import { getPrices } from "../../../utils/priceUtil";
import { palette } from "../../../themes/color-palette";
import { StyledFormattedPriceDisplay } from "./styled-formattedPriceDisplay";
import { PRODUCT_TYPE } from "../../../constants/catalog";
import storeUtil from "../../../utils/storeUtil";
import { getDateMonth } from "../../../utils/storeLocatorUtils";

interface FormattedProductPriceDisplayProps {
  min?: number | null | void;
  offerPrice?: number;
  promotionalOfferPrice?: number | null | void;
  promotionalMin?: number | null | void;
  prices?: any[];
  pourcentPromo?: number;
  productPartNumber?: string;
  fromCartConfirmation?: boolean;
  isFromOrderConfirmation?: boolean;
  meterPrice?: number | null | any;
  meterSize?: number | null | any;
  ecopart?: number | null;
  color?: string;
  currency?: string;
  locale?: string;
  type?: string;
  variant?: string;
  showPriceIsNull?: boolean;
  hideInformation?: boolean;
  hideCrossedPrice?: boolean;
  hidePromoPrice?: boolean;
  remisedPriceDetail?: any;
  quantity?: number;
}

const setTwoDecimal = (price, sup = true) => {
  const start = price.toString().split(".")[0];
  const end = price.toString().split(".")[1];
  const endFormatted = end != null ? (end.length === 1 ? `${end}0` : end.substring(0, 2)) : "00";

  return (
    <>
      {start}
      {sup ? <sup>{`,${endFormatted}€`}</sup> : `,${endFormatted}€`}
    </>
  );
};

export const FormattedProductPriceDisplay: React.FC<FormattedProductPriceDisplayProps> = ({
  variant = "h1",
  offerPrice = 0,
  promotionalOfferPrice = null,
  promotionalMin = null,
  min = null,
  prices,
  productPartNumber,
  meterPrice,
  meterSize,
  pourcentPromo,
  type,
  ecopart,
  showPriceIsNull = false,
  color = palette.text.primary,
  hideInformation = false,
  hideCrossedPrice = false,
  fromCartConfirmation,
  isFromOrderConfirmation,
  hidePromoPrice = false,
  remisedPriceDetail,
  quantity = 1,
}) => {
  const currency = "EUR";
  const { t } = useTranslation();
  const startInfoCustomMade = t("PriceDisplay.StartInfo.CustomMade");
  // const startInfoOther = t("PriceDisplay.StartInfo.Other");
  const language = "fr-FR";
  const information = type && !hideInformation ? (type === PRODUCT_TYPE.SUR_MESURE ? startInfoCustomMade : null) : null;
  const priceArray = prices && getPrices(prices);
  const offer =
    promotionalOfferPrice != null
      ? promotionalOfferPrice
      : priceArray
      ? priceArray.offer !== priceArray.list
        ? priceArray.offer
        : 0
      : offerPrice !== min
      ? offerPrice
      : 0;
  const list =
    promotionalMin != null
      ? promotionalMin
      : promotionalOfferPrice != null
      ? priceArray?.offer
      : priceArray
      ? priceArray.list
      : min;

  return (
    <StyledFormattedPriceDisplay>
      {list != null && list > 0 && information && (
        <StyledTypography component="p" variant="body4" pb={1} sx={{ height: "20px" }}>
          {list != null && list > 0 && information}
        </StyledTypography>
      )}

      {list ? (
        <>
          {offer > 0 && offer < list && !hideCrossedPrice && (
            <StyledBox className="product-price-promo">
              <StyledTypography variant="caption" component="p" className="line-through">
                {offer > 0 && offer < list && (
                  <span id={`product_price_${productPartNumber}`} className="">
                    {Intl.NumberFormat(language, {
                      style: "currency",
                      currency,
                    }).format(list * quantity)}
                  </span>
                )}
              </StyledTypography>

              {offer > 0 && pourcentPromo != null && pourcentPromo !== 0 && (
                <StyledTypography variant="body3" component="p" p={0.5} mx={1} className="product-price-promo-reduc">
                  {pourcentPromo > 0 && "-"}
                  {pourcentPromo}%
                </StyledTypography>
              )}
            </StyledBox>
          )}

          {list > 0 && !hidePromoPrice && (
            <StyledBox component="div" pb={1} m={0} className="product-price-container">
              <>
                {
                  <>
                    {type === PRODUCT_TYPE.COLISE || type === PRODUCT_TYPE.BOTTE ? (
                      <>
                        {fromCartConfirmation ? (
                          <>
                            {offer && offer !== 0 ? (
                              <StyledTypography
                                variant={variant}
                                component="span"
                                color={palette.primary.main}
                                className={`product-price`}>
                                {setTwoDecimal(meterPrice * quantity)}
                              </StyledTypography>
                            ) : (
                              <StyledTypography
                                variant={variant}
                                component="span"
                                color={color}
                                className={`product-price`}>
                                {setTwoDecimal(meterPrice * quantity)}
                              </StyledTypography>
                            )}
                          </>
                        ) : (
                          <>
                            {offer && offer !== 0 ? (
                              <StyledTypography
                                variant={variant}
                                component="span"
                                color={palette.primary.main}
                                className={`product-price`}>
                                {setTwoDecimal(offer * quantity)}
                              </StyledTypography>
                            ) : (
                              <StyledTypography
                                variant={variant}
                                component="span"
                                color={color}
                                className={`product-price`}>
                                {setTwoDecimal(list * quantity)}
                              </StyledTypography>
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {offer && offer !== 0 ? (
                          <StyledTypography
                            variant={variant}
                            component="span"
                            color={palette.primary.main}
                            className={`product-price`}>
                            {setTwoDecimal(offer * quantity)}
                          </StyledTypography>
                        ) : (
                          <StyledTypography
                            variant={variant}
                            component="span"
                            color={color}
                            className={`product-price`}>
                            {setTwoDecimal(list * quantity)}
                          </StyledTypography>
                        )}
                      </>
                    )}

                    {(type === PRODUCT_TYPE.COLISE || type === PRODUCT_TYPE.BOTTE) &&
                      !fromCartConfirmation &&
                      !isFromOrderConfirmation && (
                        <StyledTypography variant="subtitle2" component="span" className="">
                          {" / m"}
                          <sup>2</sup>
                        </StyledTypography>
                      )}
                  </>
                }
              </>
            </StyledBox>
          )}
          {meterSize != null && meterPrice != null && !fromCartConfirmation && (
            <StyledTypography variant="caption" component="p" pb={1} className="">
              Soit{" "}
              {Intl.NumberFormat(language, {
                style: "currency",
                currency,
              }).format(meterPrice * quantity)}{" "}
              {type === PRODUCT_TYPE.BOTTE && "la botte de "}
              {type === PRODUCT_TYPE.COLISE && "la boite de "}
              {Intl.NumberFormat(language, {
                style: "unit",
                unit: "meter",
              }).format(storeUtil.parseFloatDecimal(meterSize, 3))}
              {"²"}
            </StyledTypography>
          )}

          {ecopart != null && ecopart > 0 && (
            <StyledTypography variant="caption" component="p" className="eco-part-price">
              dont{" "}
              {Intl.NumberFormat(language, {
                style: "currency",
                currency,
              }).format(ecopart)}{" "}
              <u>d’éco-part</u>
            </StyledTypography>
          )}
          {remisedPriceDetail && remisedPriceDetail.OldPriceEnd && remisedPriceDetail.OldPriceEnd !== "null" && (
            <StyledTypography variant="caption" component="p" className="old-price-end-date">
              Prix en baisse, valable depuis le {getDateMonth(remisedPriceDetail.OldPriceEnd, true, "DD/MM/YYYY")}
            </StyledTypography>
          )}
          {remisedPriceDetail && remisedPriceDetail.oldPrice && remisedPriceDetail.oldPrice !== "null" && (
            <StyledTypography variant="caption" component="p" className="old-price">
              Ancien prix:{" "}
              {Intl.NumberFormat(language, {
                style: "currency",
                currency,
              }).format(remisedPriceDetail.oldPrice * quantity)}
            </StyledTypography>
          )}
        </>
      ) : (
        <>
          {showPriceIsNull && (
            <StyledTypography
              variant={"body4"}
              component="p"
              color={color}
              pb={1}
              className={`product-price`}
              sx={{ height: 92 }}>
              Produit disponible uniquement en magasin
            </StyledTypography>
          )}
        </>
      )}
    </StyledFormattedPriceDisplay>
  );
};

interface FormattedPriceDisplayProps {
  min?: number | string | null | void;
  color?: string;
  productPartNumber?: string;
  variant?: string;
  sx?: any;
  currency?: string;
  sup?: boolean;
  className?: string;
  type?: string;
}

const FormattedPriceDisplay = ({
  min = null,
  variant = "subtitle1",
  color = palette.text.primary,
  sup = false,
  sx = {},
  type,
  className = "",
}: FormattedPriceDisplayProps) => {
  return (
    <StyledFormattedPriceDisplay>
      {min != null ? (
        <StyledTypography
          variant={variant}
          sx={sx}
          component="span"
          color={color}
          className={`product-price ${className}`}>
          {setTwoDecimal(min, sup)}
          {(type === PRODUCT_TYPE.COLISE || type === PRODUCT_TYPE.BOTTE) && (
            <StyledTypography variant="subtitle2" component="span" className="">
              {" / m"}
              <sup>2</sup>
            </StyledTypography>
          )}
        </StyledTypography>
      ) : (
        <StyledTypography
          variant={variant}
          sx={sx}
          component="span"
          color={color}
          className={`product-price ${className}`}>
          Produit disponible uniquement en magasin
        </StyledTypography>
      )}
    </StyledFormattedPriceDisplay>
  );
};
export default FormattedPriceDisplay;
