/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import { call, put, select } from "redux-saga/effects";
//Foundation libraries
import { PERSONALIZATION_ID } from "../../../_foundation/constants/user";
import { EXPIRED_PASSWORD_PAGE_ERROR, WC_PREVIEW_TOKEN } from "../../../_foundation/constants/common";
import loginIdentity from "../../../_foundation/apis/transaction/loginIdentity.service";
import { localStorageUtil, storageSessionHandler } from "../../../_foundation/utils/storageUtil";
import personService from "../../../_foundation/apis/transaction/person.service";
import userContextService from "../../../_foundation/apis/transaction/userContext.service";
//Redux
import * as ACTIONS from "../../action-types/user";
import {
  REGISTRATION_SUCCESS_ACTION,
  LOGOUT_SUCCESS_ACTION,
  LOGIN_SUCCESS_ACTION,
  INIT_USER_FROM_STORAGE_SUCCESS_ACTION,
  FETCH_USER_DETAILS_SUCCESS_ACTION,
  FETCH_USER_DETAILS_ERROR_ACTION,
  SESSION_ERROR_LOGIN_ERROR_ACTION,
  GUEST_LOGIN_SUCCESS_ACTION,
  LOGON_AND_CHANGE_PASSWORD_FAIL_ACTION,
  REGISTRATION_NEWSLETTER_SUCCESS_ACTION,
  UPDATE_USER_DETAILS_SUCCESS_ACTION,
  UPDATE_USER_DETAILS_ERROR_ACTION,
  FETCH_USER_DETAILS_REQUEST_ACTION,
} from "../../actions/user";

import { userLastUpdatedSelector } from "../../selectors/user";
import { USER_CONTEXT_REQUEST_SUCCESS_ACTION } from "../../actions/context";
// import { ENTITLED_ORG_ACTION } from "../../actions/organization";
import { FETCH_CONTRACT_REQUESTED_ACTION } from "../../actions/contract";
import newsletterService from "../../../_foundation/apis/transaction/newsletter.service";
// import { HANDLE_SUCCESS_MESSAGE_ACTION } from "../../actions/success";
import { STORES_CLOSE_MODAL_ACTION } from "../../actions/stores";
import { getSite } from "../../../_foundation/hooks/useSite";
import { FETCHING_CART_ACTION } from "../../actions/order";
import * as WORKERS from "../workers/order";
import { HANDLE_SUCCESS_MESSAGE_ACTION } from "../../actions/success";
import storeLocatorService from "../../../_foundation/apis/transaction/storeLocator.service";

export function* conditionallyFetchCart(action: any) {
  const { payload, ...otherAction } = action;
  const { url, ...other } = payload;
  // fetch cart only if non-cart action triggered the guest-login
  if (!url.match(/\bcart\b/)) {
    yield call(WORKERS.fetchCart, { ...otherAction, payload: other });
  }
}

function* loginAndFetchDetail(payload: any) {
  const isOAuth = payload.body?.authorizationProvider;
  const response = yield call(isOAuth ? loginIdentity.loginOauth : loginIdentity.login, payload);

  const _user = response.data;
  // if (payload?.query?.rememberMe) {
  //   //add rememberMe true to user state
  //   _user["rememberMe"] = payload.query.rememberMe;
  // }
  yield put(LOGIN_SUCCESS_ACTION(_user));

  const response2 = yield call(personService.findPersonBySelf, {
    widget: payload.widget,
  });
  const loginPayload2 = response2.data;
  yield put(FETCH_USER_DETAILS_SUCCESS_ACTION(loginPayload2));
}

const preProcessLogonAndChangePasswordError = (error: any) => {
  if (error?.isAxiosError && error.response?.data?.errors && error.response.data.errors[0]) {
    return {
      ...error.response.data.errors[0],
      [EXPIRED_PASSWORD_PAGE_ERROR]: true,
    };
  } else {
    return {
      errorMessage: error.toLocaleString(),
      [EXPIRED_PASSWORD_PAGE_ERROR]: true,
    };
  }
};

export function* logonAndChangePassword(action: any) {
  try {
    const payload = action.payload;
    yield* loginAndFetchDetail(payload);
  } catch (error) {
    yield put(LOGON_AND_CHANGE_PASSWORD_FAIL_ACTION(preProcessLogonAndChangePasswordError(error)));
  }
}

export function* login(action: any) {
  try {
    const payload = action.payload;
    yield* loginAndFetchDetail(payload);
  } catch (error) {
    yield put({ type: ACTIONS.LOGIN_ERROR, error });
  }
}

export function* sessionErrorReLogin(action: any) {
  try {
    const payload = action.payload;
    const currentUser = storageSessionHandler.getCurrentUserAndLoadAccount();
    if (currentUser?.rememberMe) {
      payload.query
        ? (payload.query.rememberMe = currentUser.rememberMe)
        : (payload.query = { rememberMe: currentUser.rememberMe });
    }
    storageSessionHandler.removeCurrentUser();
    yield* loginAndFetchDetail(payload);
  } catch (error: any) {
    if (error && error.response && error.response.data && error.response.data.errors && error.response.data.errors[0]) {
      yield put(SESSION_ERROR_LOGIN_ERROR_ACTION(error.response.data.errors[0]));
    }
  }
}

export function* logout(action: any) {
  const payload = action.payload;
  try {
    yield call(loginIdentity.logout, payload);
    yield put(LOGOUT_SUCCESS_ACTION(payload));
  } catch (error) {
    yield put({ type: ACTIONS.LOGOUT_ERROR, error });
    //still need to clear user token, event though logout fail to avoid infinite loop
    yield put(LOGOUT_SUCCESS_ACTION(payload));
  }
}

export function* registration(action: any) {
  const siteInfo = getSite();
  try {
    const receiveEmail = action.payload.body.receiveEmail === "true";
    const payload = {
      ...action.payload,
      body: {
        ...action.payload.body,
        receiveEmail: `${receiveEmail}`,
        receiveEmailPreference: [{ value: `${receiveEmail}`, storeID: siteInfo?.storeID }],
      },
    };
    const response = yield call(personService.registerPerson, payload);
    const registrationPayload = response.data;
    if (payload?.query?.rememberMe) {
      registrationPayload["rememberMe"] = payload.query.rememberMe;
    }
    yield put(REGISTRATION_SUCCESS_ACTION(registrationPayload));

    const response2 = yield call(personService.findPersonBySelf, {
      widget: payload.widget,
    });
    const registrationPayload2 = response2.data;
    yield put(FETCH_USER_DETAILS_SUCCESS_ACTION(registrationPayload2));
  } catch (error) {
    yield put({ type: ACTIONS.REGISTRATION_ERROR, error });
  }
}

export function* registrationNewsletter(action: any) {
  try {
    const payload = action.payload;
    const response = yield call(newsletterService.subscription, payload);
    yield put(REGISTRATION_NEWSLETTER_SUCCESS_ACTION(response.data));
    //
    //yield put(HANDLE_SUCCESS_MESSAGE_ACTION({ key: "success-message.NEWSLETTER_ADD_SUCCESS" }));
    // if (action.payload.confirmMsg) {
    //   yield put(HANDLE_SUCCESS_MESSAGE_ACTION({ ...action.payload.confirmMsg }));
    // }
  } catch (error) {
    yield put({ type: ACTIONS.REGISTRATION_NEWSLETTER_ERROR, error });
  }
}

export function* initStateFromStorage(action: any) {
  try {
    let currentUser = storageSessionHandler.getCurrentUserAndLoadAccount();
    if (currentUser === null) {
      //
      // if we have both previewtoken and newPreviewSession, the current user is removed in inistates.ts
      // then we should get new personalizationID from preview session
      const previewToken = storageSessionHandler.getPreviewToken();
      if (!previewToken || !previewToken[WC_PREVIEW_TOKEN]) {
        const personalizationID = localStorageUtil.get(PERSONALIZATION_ID);
        if (personalizationID !== null) {
          currentUser = { personalizationID };
        }
      }
    }
    yield put(INIT_USER_FROM_STORAGE_SUCCESS_ACTION(currentUser));
    if (currentUser && currentUser.WCToken) {
      const response2 = yield call(personService.findPersonBySelf, {
        ...action.payload,
      });
      const loginPayload2 = response2.data;
      yield put(FETCH_USER_DETAILS_SUCCESS_ACTION(loginPayload2));

      const payload = {
        ...action.payload,
        fetchCatentries: true,
      };
      yield put(FETCHING_CART_ACTION(payload));
    }
    const response3 = yield call(userContextService.getContextData, { ...action.payload });
    const userPayload = response3.data;
    yield put(USER_CONTEXT_REQUEST_SUCCESS_ACTION({ ...userPayload }));
    //yield put(ENTITLED_ORG_ACTION({ ...action.payload }));
    yield put(FETCH_CONTRACT_REQUESTED_ACTION({ ...action.payload, userContext: userPayload }));
  } catch (e) {
    console.warn(e);
    storageSessionHandler.removeCurrentUser();
    window.location.reload();
  }
}

export function* updateStateFromStorage(action: any) {
  try {
    const currentUser = storageSessionHandler.getCurrentUserAndLoadAccount();
    if (currentUser && currentUser.forUserId) {
      return;
    }
    const userLastUpdated = yield select(userLastUpdatedSelector);
    if (currentUser && currentUser.lastUpdated && (!userLastUpdated || userLastUpdated < currentUser.lastUpdated)) {
      yield put(INIT_USER_FROM_STORAGE_SUCCESS_ACTION(currentUser));
      if (currentUser.isGuest) {
        yield put(GUEST_LOGIN_SUCCESS_ACTION(null));
      } else {
        yield put(LOGIN_SUCCESS_ACTION(null));
      }
    }
  } catch (e) {
    console.warn(e);
  }
}

export function* fetchUserDetails(action: any) {
  try {
    const response = yield call(personService.findPersonBySelf, {
      widget: action.payload?.widget || "FromSaga",
    });
    if (response.status === 200) {
      const data = response.data;
      yield put(FETCH_USER_DETAILS_SUCCESS_ACTION(data));
    } else if (response.status === 500) {
      storageSessionHandler.removeCurrentUser();
      window.location.reload();
    }
  } catch (e) {
    storageSessionHandler.removeCurrentUser();
    window.location.reload();
  }
}

export function* updateUserDetails(action: {
  payload: { parameters: any; closeModal: boolean; hideSnackbar?: boolean };
}) {
  try {
    const storeResponse = yield call(storeLocatorService.defaultStoreSet, {
      body: {
        shopId: action.payload.parameters.body.x_demographicField7,
        shopName: false,
        localisation: "denied",
      },
    });

    if (storeResponse?.data?.message === "OK") {
      /*const personResponse = */ yield call(personService.updatePerson, action.payload.parameters);
      const messages: any = {
        message: {
          title: "Magasin mis à jour",
          description: "Nous avons adapté la disponibilité des produits selon le magasin choisi.",
        },
        link: {
          url: "https://www.lapeyre.fr/c/services/le-drive",
          text: "En savoir plus",
        },
      };

      if (!action.payload?.hideSnackbar) {
        yield put(HANDLE_SUCCESS_MESSAGE_ACTION(messages));
      }
    }
    //
    yield put(UPDATE_USER_DETAILS_SUCCESS_ACTION(null));
    yield put(FETCH_USER_DETAILS_REQUEST_ACTION(null));

    if (action.payload.closeModal) {
      yield put(STORES_CLOSE_MODAL_ACTION());
    }
  } catch (e) {
    yield put(UPDATE_USER_DETAILS_ERROR_ACTION(e));
  }
}
