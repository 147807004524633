import { RootReducerState } from "../reducers";

const userFavStoresSelector = (state: RootReducerState) => {
  return state.stores.userFavStores;
};

const isLoadingUserFavStores = (state: RootReducerState) => {
  return state.stores.isLoadingUserFavStores;
};

const storeLocatorModalOpenSelector = (state: RootReducerState) => {
  return state.stores.storeLocatorModalOpen;
};

const storeLocatorModalTypeSelector = (state: RootReducerState) => {
  return state.stores.storeLocatorModalType;
};

const storeLocatorProductDataSelector = (state: RootReducerState) => {
  return state.stores.productData;
};

const storesListSelector = (state: RootReducerState) => {
  return state.stores.storesList;
};

const storeLocatorNearestStoresSelector = (state: RootReducerState) => {
  return state.stores.nearestStores;
};

const storeLocatorAvailabilitiesSelector = (state: RootReducerState) => {
  return state.stores.availabilities;
};

const mapCoordsSelector = (state: RootReducerState) => {
  return state.stores.map;
};

const isLoadingStoresListSelector = (state: RootReducerState) => {
  return state.stores.isLoadingStoresList;
};

export {
  userFavStoresSelector,
  isLoadingUserFavStores,
  storeLocatorModalOpenSelector,
  storeLocatorModalTypeSelector,
  storeLocatorProductDataSelector,
  storesListSelector,
  storeLocatorNearestStoresSelector,
  storeLocatorAvailabilitiesSelector,
  mapCoordsSelector,
  isLoadingStoresListSelector,
};
