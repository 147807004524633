/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Services - Account and Contract
 * These services provide APIs to manage accounts and contracts.  a contract is an agreement that represents the terms and conditions that apply to a transaction. An account is a relationship between the merchant and the financial institution that processes transactions for that merchant.
 *
 * The version of the OpenAPI document: 9.1.6
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../../base';
// @ts-ignore
import { CustomerProject } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { PatchCustomerProject } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { PutCustomerProject } from '../../../../../com/hcl/commerce/typescript/axios';
/**
 * ProjectApi - axios parameter creator
 * @export
 */
export const ProjectApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Delete a project for the customer.
         * @summary Delete a project
         * @param {string} storeId The store identifier.
         * @param {string} projectId The project identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProjectById: async (storeId: string, projectId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('deleteProjectById', 'storeId', storeId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('deleteProjectById', 'projectId', projectId)
            const localVarPath = `/store/{storeId}/projects/deleteProjectById/{projectId}`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"projectId"}}`, String(projectId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the info of the customer project.
         * @summary Get the info of the customer project
         * @param {string} storeId The store identifier.
         * @param {string} projectId The project identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectById: async (storeId: string, projectId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('getProjectById', 'storeId', storeId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getProjectById', 'projectId', projectId)
            const localVarPath = `/store/{storeId}/projects/getProjectById/{projectId}`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"projectId"}}`, String(projectId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a project for the customer.
         * @summary Update or remove a project
         * @param {string} storeId The store identifier.
         * @param {string} projectId The project identifier.
         * @param {PatchCustomerProject} patchCustomerProject The request object for project quick modification.
         * @param {string} [patchType] Patch type: \&quot;UPDATE\&quot; or \&quot;REMOVE\&quot;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchProjectById: async (storeId: string, projectId: string, patchCustomerProject: PatchCustomerProject, patchType?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('patchProjectById', 'storeId', storeId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('patchProjectById', 'projectId', projectId)
            // verify required parameter 'patchCustomerProject' is not null or undefined
            assertParamExists('patchProjectById', 'patchCustomerProject', patchCustomerProject)
            const localVarPath = `/store/{storeId}/projects/patchProjectById/{projectId}`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"projectId"}}`, String(projectId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (patchType !== undefined) {
                localVarQueryParameter['patchType'] = patchType;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchCustomerProject, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Edit a project for the customer.
         * @summary Edit a project
         * @param {string} storeId The store identifier.
         * @param {string} projectId The project identifier.
         * @param {PutCustomerProject} putCustomerProject The request object for project quick modification.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putProjectById: async (storeId: string, projectId: string, putCustomerProject: PutCustomerProject, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('putProjectById', 'storeId', storeId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('putProjectById', 'projectId', projectId)
            // verify required parameter 'putCustomerProject' is not null or undefined
            assertParamExists('putProjectById', 'putCustomerProject', putCustomerProject)
            const localVarPath = `/store/{storeId}/projects/putProjectById/{projectId}`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"projectId"}}`, String(projectId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putCustomerProject, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectApi - functional programming interface
 * @export
 */
export const ProjectApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjectApiAxiosParamCreator(configuration)
    return {
        /**
         * Delete a project for the customer.
         * @summary Delete a project
         * @param {string} storeId The store identifier.
         * @param {string} projectId The project identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProjectById(storeId: string, projectId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProjectById(storeId, projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the info of the customer project.
         * @summary Get the info of the customer project
         * @param {string} storeId The store identifier.
         * @param {string} projectId The project identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectById(storeId: string, projectId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerProject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectById(storeId, projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a project for the customer.
         * @summary Update or remove a project
         * @param {string} storeId The store identifier.
         * @param {string} projectId The project identifier.
         * @param {PatchCustomerProject} patchCustomerProject The request object for project quick modification.
         * @param {string} [patchType] Patch type: \&quot;UPDATE\&quot; or \&quot;REMOVE\&quot;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchProjectById(storeId: string, projectId: string, patchCustomerProject: PatchCustomerProject, patchType?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchProjectById(storeId, projectId, patchCustomerProject, patchType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Edit a project for the customer.
         * @summary Edit a project
         * @param {string} storeId The store identifier.
         * @param {string} projectId The project identifier.
         * @param {PutCustomerProject} putCustomerProject The request object for project quick modification.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putProjectById(storeId: string, projectId: string, putCustomerProject: PutCustomerProject, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putProjectById(storeId, projectId, putCustomerProject, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProjectApi - factory interface
 * @export
 */
export const ProjectApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjectApiFp(configuration)
    return {
        /**
         * Delete a project for the customer.
         * @summary Delete a project
         * @param {string} storeId The store identifier.
         * @param {string} projectId The project identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProjectById(storeId: string, projectId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteProjectById(storeId, projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the info of the customer project.
         * @summary Get the info of the customer project
         * @param {string} storeId The store identifier.
         * @param {string} projectId The project identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectById(storeId: string, projectId: string, options?: any): AxiosPromise<CustomerProject> {
            return localVarFp.getProjectById(storeId, projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a project for the customer.
         * @summary Update or remove a project
         * @param {string} storeId The store identifier.
         * @param {string} projectId The project identifier.
         * @param {PatchCustomerProject} patchCustomerProject The request object for project quick modification.
         * @param {string} [patchType] Patch type: \&quot;UPDATE\&quot; or \&quot;REMOVE\&quot;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchProjectById(storeId: string, projectId: string, patchCustomerProject: PatchCustomerProject, patchType?: string, options?: any): AxiosPromise<void> {
            return localVarFp.patchProjectById(storeId, projectId, patchCustomerProject, patchType, options).then((request) => request(axios, basePath));
        },
        /**
         * Edit a project for the customer.
         * @summary Edit a project
         * @param {string} storeId The store identifier.
         * @param {string} projectId The project identifier.
         * @param {PutCustomerProject} putCustomerProject The request object for project quick modification.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putProjectById(storeId: string, projectId: string, putCustomerProject: PutCustomerProject, options?: any): AxiosPromise<void> {
            return localVarFp.putProjectById(storeId, projectId, putCustomerProject, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProjectApi - object-oriented interface
 * @export
 * @class ProjectApi
 * @extends {BaseAPI}
 */
export class ProjectApi extends BaseAPI {
    /**
     * Delete a project for the customer.
     * @summary Delete a project
     * @param {string} storeId The store identifier.
     * @param {string} projectId The project identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public deleteProjectById(storeId: string, projectId: string, options?: any) {
        return ProjectApiFp(this.configuration).deleteProjectById(storeId, projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the info of the customer project.
     * @summary Get the info of the customer project
     * @param {string} storeId The store identifier.
     * @param {string} projectId The project identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public getProjectById(storeId: string, projectId: string, options?: any) {
        return ProjectApiFp(this.configuration).getProjectById(storeId, projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a project for the customer.
     * @summary Update or remove a project
     * @param {string} storeId The store identifier.
     * @param {string} projectId The project identifier.
     * @param {PatchCustomerProject} patchCustomerProject The request object for project quick modification.
     * @param {string} [patchType] Patch type: \&quot;UPDATE\&quot; or \&quot;REMOVE\&quot;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public patchProjectById(storeId: string, projectId: string, patchCustomerProject: PatchCustomerProject, patchType?: string, options?: any) {
        return ProjectApiFp(this.configuration).patchProjectById(storeId, projectId, patchCustomerProject, patchType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Edit a project for the customer.
     * @summary Edit a project
     * @param {string} storeId The store identifier.
     * @param {string} projectId The project identifier.
     * @param {PutCustomerProject} putCustomerProject The request object for project quick modification.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public putProjectById(storeId: string, projectId: string, putCustomerProject: PutCustomerProject, options?: any) {
        return ProjectApiFp(this.configuration).putProjectById(storeId, projectId, putCustomerProject, options).then((request) => request(this.axios, this.basePath));
    }
}
