import styled from "@mui/styled-engine-sc";
import SvgIcon from "@mui/material/SvgIcon";

const CustomSvg = styled((props) => (
  <SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} />
))`
  ${({ theme }) => `
    width:16px;
    height:16px;

    &.big {
      width:24px;
      height:24px;
    }
  `}
`;

export const SvgIconMessage = (props) => {
  const { className = "" } = props;

  return (
    <CustomSvg {...{ className }}>
      <path d="M14.413 3.66a.876.876 0 0 0-.633-.266H2.22a.89.89 0 0 0-.887.886v7.447c0 .487.4.887.887.887h11.56a.89.89 0 0 0 .886-.887V4.28a.856.856 0 0 0-.253-.613V3.66ZM2.32 5.02l3.053 2.847L2.32 10.92v-5.9Zm7.246 2.487-.586.547a1.438 1.438 0 0 1-1.954 0L6.433 7.5 3.08 4.374h9.84L9.566 7.5v.007Zm-3.473 1.04.253.24c.46.433 1.06.647 1.654.647.593 0 1.186-.214 1.646-.647l.254-.24 3.08 3.08H3.02l3.073-3.08Zm4.533-.673 3.054-2.847v5.9l-3.054-3.053Z" />
    </CustomSvg>
  );
};
