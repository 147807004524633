import SvgIcon from "@mui/material/SvgIcon";
import styled from "@mui/styled-engine-sc";

const CustomSvg = styled((props) => (
  <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} />
))`
  ${({ theme }) => `
    width:20px;
    height:20px;

    ${theme.breakpoints.down("md")} {
      width:24px;
      height:24px;
    }

    &.small {
      width:16px;
      height:16px;
    }
  `}
`;

export const SvgMyAccount = (props) => {
  const { className = "" } = props;

  return (
    <CustomSvg {...{ className }}>
      <path d="M9.99999 10.0084C9.08333 10.0084 8.29999 9.68377 7.64166 9.02626C6.99166 8.37707 6.65833 7.58639 6.65833 6.67087C6.65833 5.75535 6.98333 4.97299 7.64166 4.31548C8.29999 3.65797 9.08333 3.33337 9.99999 3.33337C10.9167 3.33337 11.7 3.65797 12.3583 4.31548C13.0167 4.97299 13.3417 5.75535 13.3417 6.67087C13.3417 7.58639 13.0167 8.36875 12.3583 9.02626C11.7083 9.67545 10.9167 10.0084 9.99999 10.0084ZM15 16.6667H4.99999C4.54166 16.6667 4.14999 16.5002 3.82499 16.1757C3.49999 15.8511 3.33333 15.4599 3.33333 15.0021V14.3363C3.33333 13.8619 3.45833 13.4291 3.69999 13.0379C3.94166 12.6467 4.26666 12.3388 4.66666 12.1307C5.52499 11.6979 6.39999 11.3816 7.29166 11.1653C8.18333 10.9489 9.08333 10.8407 9.99999 10.8407C10.9167 10.8407 11.8167 10.9489 12.7083 11.1653C13.6 11.3816 14.475 11.7062 15.3333 12.1307C15.7333 12.3388 16.0583 12.6384 16.3 13.0379C16.5417 13.4374 16.6667 13.8702 16.6667 14.3363V15.0021C16.6667 15.4599 16.5 15.8511 16.175 16.1757C15.85 16.5002 15.4583 16.6667 15 16.6667ZM4.99999 15.0021H15V14.3363C15 14.1865 14.9583 14.045 14.8833 13.9201C14.8083 13.7953 14.7083 13.6954 14.5833 13.6288C13.8333 13.2543 13.075 12.9713 12.3083 12.7882C11.5417 12.6051 10.775 12.5052 9.99166 12.5052C9.20833 12.5052 8.44166 12.5968 7.67499 12.7882C6.90833 12.9796 6.14999 13.2543 5.39999 13.6288C5.27499 13.6954 5.17499 13.7953 5.09999 13.9201C5.02499 14.045 4.98333 14.1865 4.98333 14.3363V15.0021H4.99999ZM9.99999 8.34378C10.4583 8.34378 10.85 8.17732 11.175 7.85272C11.5 7.52813 11.6667 7.13695 11.6667 6.67919C11.6667 6.22143 11.5 5.83025 11.175 5.50566C10.85 5.18106 10.4583 5.01461 9.99999 5.01461C9.54166 5.01461 9.14999 5.18106 8.82499 5.50566C8.49999 5.83025 8.33333 6.22143 8.33333 6.67919C8.33333 7.13695 8.49999 7.52813 8.82499 7.85272C9.14999 8.17732 9.54166 8.34378 9.99999 8.34378Z" />
    </CustomSvg>
  );
};
