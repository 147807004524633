/* eslint-disable react-hooks/exhaustive-deps */
/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2021
 *
 *==================================================
 */
//Standard libraries
import { useState, MouseEvent, ChangeEvent, KeyboardEvent, useMemo, useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Axios, { Canceler } from "axios";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import getDisplayName from "react-display-name";

//Foundation libraries
import { useSite } from "../../_foundation/hooks/useSite";
import assignedPromotionCode from "../../_foundation/apis/transaction/assignedPromotionCode.service";
import { localStorageUtil } from "../../_foundation/utils/storageUtil";
import { ACCOUNT, SELECTED_PROFILE } from "../../_foundation/constants/common";
//Custom libraries
import { CHECKOUT, CHECKOUT_REVIEW, SIGNIN } from "../../constants/routes";
import {
  INVENTORY,
  CommerceEnvironment,
  KEY_CODES,
  ORDER_ID,
  HYPHEN,
  EMPTY_STRING,
  SLASH,
} from "../../constants/common";
//Redux
import * as orderActions from "../../redux/actions/order";
import { cartSelector, orderItemsSelector, isFetchingSelector } from "../../redux/selectors/order";
import { forUserIdSelector, loginStatusSelector } from "../../redux/selectors/user";
import { currentContractIdSelector } from "../../redux/selectors/contract";

import { enUS, fr, de, it, es, ptBR, zhCN, zhTW, ja, ru, ro } from "date-fns/locale";
import cartService from "../apis/transaction/cart.service";
import { ORDER_CONFIGS } from "../../configs/order";
import {
  // getAvailableForShippingAttributes,
  getAvailableInStoreAttributes,
  getProductsParamsFromOrders,
  isAvailableForShipping,
  // isAvailableInStore,
  isProductAvailableInStore,
} from "../../utils/orderUtil";
import { useAvailabilitiesInStore } from "../../utils/hooks/swr/useAvailabilitiesInStore";
import { useShippingDate, useShippingPrice } from "../../utils/hooks/swr/useShippingDate";
import { useStoreLocatorValue } from "../context/store-locator-context";
import { getUserZipcode } from "../../utils/userUtil";
import { useJsApiLoader } from "@react-google-maps/api";
import { GetCategoriesSelector } from "../../redux/selectors/category";
/**
 * Shopping cart component
 * displays order item table, order total summary and promo code section
 * @param props
 */
export const useCart = () => {
  const forUserId = useSelector(forUserIdSelector);
  const widgetName = getDisplayName("CartWidget");
  const contractId = useSelector(currentContractIdSelector);
  const cart = useSelector(cartSelector);
  const orderItems = useSelector(orderItemsSelector);
  const { storeLocator } = useStoreLocatorValue();
  const selectedStore = storeLocator?.selectedStore || null;
  const zipcode = getUserZipcode();
  const categoriesRedux = useSelector(GetCategoriesSelector);
  const [availableProductsData, setAvailableProductsData] = useState<any[] | null>(null);
  const [unavailableProductsData, setUnavailableProductsData] = useState<any[] | null>(null);
  const [productsUnavailable, setProductsUnavailable] = useState<boolean>(true);

  const { mySite } = useSite();
  const defaultCurrencyID: string = mySite ? mySite.defaultCurrencyID : "";
  const checkInventory: boolean = mySite ? mySite.inventorySystem === INVENTORY.NON_ATP : false;

  const payloadBase: any = {
    currency: defaultCurrencyID,
    contractId: contractId,
    checkInventory: checkInventory,
    widget: widgetName,
  };

  /* const { isLoaded } = */ useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY || "",
  });

  const productsData = getProductsParamsFromOrders(orderItems);
  const {
    data: dataAvailabilityInStore,
    isLoading: dataAvailabilityInStoreLoading,
    error: errorAvailabilityInStore,
  } = useAvailabilitiesInStore(selectedStore && [parseInt(selectedStore?.storeName)], productsData);

  const {
    data: dataAvailabilityShipping,
    isLoading: dataAvailabilityShippingLoading,
    error: errorAvailabilityShipping,
  } = useShippingDate(zipcode, productsData);

  const {
    data: dataShippingPrice,
    isLoading: dataShippingPriceLoading,
    error: errorShippingPrice,
  } = useShippingPrice(payloadBase, cart && cart.orderId, productsData);

  useEffect(() => {
    const availableProdTable: any[] = [];
    const unavailableProdTable: any[] = [];
    if (orderItems) {
      orderItems
        .filter((o) => o.buyable === "true")
        .forEach((order) => {
          if (dataAvailabilityShipping || dataAvailabilityInStore) {
            // const availShipAttr = getAvailableForShippingAttributes(dataAvailabilityShipping?.data, order);
            const availStoreAttr = getAvailableInStoreAttributes(
              dataAvailabilityInStore?.data,
              order?.partNumber,
              selectedStore?.storeName
            );

            const availShip = isAvailableForShipping(dataAvailabilityShipping?.data);
            const availStore = isProductAvailableInStore(availStoreAttr, order?.partNumber);

            if (availShip === false && availStore === false) {
              unavailableProdTable.push({ order, availShip, availStore });
            } else {
              availableProdTable.push({ order, availShip, availStore });
            }
          } else {
            unavailableProdTable.push({ order, availShip: false, availStore: false });
          }
        });

      setAvailableProductsData(availableProdTable);
      setUnavailableProductsData(unavailableProdTable);
      setProductsUnavailable(unavailableProdTable.length > 0);
    }
  }, [orderItems, dataAvailabilityShipping, dataAvailabilityInStore]); // eslint-disable-line react-hooks/exhaustive-deps

  const [selectedProfile, setSelectedProfile] = useState<string>(
    localStorageUtil.get(SELECTED_PROFILE) ?? EMPTY_STRING
  );

  const isFetching = useSelector(isFetchingSelector);
  const loginStatus = useSelector(loginStatusSelector);
  const [promoCode, setPromoCode] = useState<string>(EMPTY_STRING);
  const [promoCodeError, setPromoCodeError] = useState<boolean>(false);
  const [promoCodeErrorMsg, setPromoCodeErrorMsg] = useState<string>(EMPTY_STRING);

  const selectedPromoCodes: any[] = cart ? (cart.promotionCode ? cart.promotionCode : []) : [];
  const isCartLocked = (): boolean => {
    if (forUserId) {
      return false;
    } else {
      return cart ? (cart.locked === "true" || cart.locked === true ? true : false) : false;
    }
  };
  const hasDiscounts = cart && cart.adjustment ? true : false;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  const CancelToken = Axios.CancelToken;
  const cancels: Canceler[] = useMemo(() => [], []);

  const locale: string = i18n.languages[0].split("-").join("_");
  const localeList = [enUS, fr, de, it, es, ptBR, zhCN, zhTW, ja, ru, ro];
  const localeMap = initLocaleMap();

  useEffect(() => {
    if (cart?.promotionCode && cart?.promotionCode.length > 0) {
      setPromoCode(cart.promotionCode[0].code);
    }
    if (cart && cart.orderExtendAttribute && cart.orderExtendAttribute.length > 0) {
      const promoApplied = cart.orderExtendAttribute.filter((item) => item.attributeName === "COUPON_NUMERO");
      if (
        promoApplied &&
        promoApplied.length > 0 &&
        promoApplied[0].attributeValue &&
        promoApplied[0].attributeValue.length > 0
      ) {
        setPromoCode(promoApplied[0].attributeValue);
      }
    }
  }, [cart]);

  useEffect(() => {
    return () => {
      cancels.forEach((cancel) => cancel());
    };
  }, []);

  function initLocaleMap() {
    const localeMap = {};
    for (const i in CommerceEnvironment.reverseLanguageMapForDateFns) {
      const dateFnCode = CommerceEnvironment.reverseLanguageMapForDateFns[i];

      for (let j = 0; j < localeList.length; j++) {
        if (localeList[j].code === dateFnCode) {
          localeMap[i] = localeList[j];
        }
      }
    }
    return localeMap;
  }

  /**
   * Reset promo code error if it was true
   */
  function resetPromoCodeError() {
    if (promoCodeError === true) {
      setPromoCodeError(false);
      setPromoCodeErrorMsg(EMPTY_STRING);
    }
  }

  const applyPromotionCode = useCallback(() => {
    const code = promoCode.trim();

    if (code !== "") {
      const payload = {
        ...payloadBase,
        promoCode: code,
        orderId: cart?.orderId,
      };
      const body = {
        body: { ...payload },
        skipErrorSnackbar: true,
        cancelToken: new CancelToken(function executor(c) {
          cancels.push(c);
        }),
      };

      if (payload?.widget) {
        body["widget"] = payload.widget;
      }

      assignedPromotionCode
        .applyPromotioncode(body)
        .then((res) => {
          const payload = {
            ...payloadBase,
            fetchCatentries: true,
            cancelToken: new CancelToken(function executor(c) {
              cancels.push(c);
            }),
          };
          dispatch(orderActions.FETCHING_CART_ACTION({ ...payload }));
          setPromoCodeError(false);
          setPromoCodeErrorMsg(EMPTY_STRING);
          // setPromoCode("");
        })
        .catch((e) => {
          setPromoCodeError(true);
          setPromoCodeErrorMsg(e.response.data.errors[0]?.errorMessage  || "Une erreur technique est survenue");
        });
    } else {
      setPromoCodeError(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promoCode]);

  function applyPromoCodeBasedOnKey(e: KeyboardEvent<HTMLAnchorElement>) {
    if (e.keyCode === KEY_CODES.ENTER) {
      applyPromotionCode();
    }
  }

  /**
   * Update promotion code state upon input change
   */
  function onPromoCodeChange(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const newPromoCode = event.target.value;
    setPromoCode(newPromoCode);
  }

  /**
   * Apply promo code to cart if code is not empty
   */
  function applyPromoCode(event: MouseEvent<HTMLAnchorElement>) {
    event.preventDefault();
    applyPromotionCode();
  }

  /**
   * Remove applied promo code from cart
   */
  const onPromoCodeRemove = useCallback(
    (code: string) => {
      if (code !== "") {
        const parameters = {
          ...payloadBase,
          promoCode: code,
          cancelToken: new CancelToken(function executor(c) {
            cancels.push(c);
          }),
        };

        if (payloadBase?.widget) {
          parameters["widget"] = payloadBase.widget;
        }

        assignedPromotionCode
          .removePromotionCode({ ...parameters })
          .then((res) => {
            setPromoCode("");
            const payload = {
              ...payloadBase,
              fetchCatentries: true,
              cancelToken: new CancelToken(function executor(c) {
                cancels.push(c);
              }),
            };
            dispatch(orderActions.FETCHING_CART_ACTION({ ...payload }));
          })
          .catch((e) => {
            console.log("Could not remove promo code");
          });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  /**
   * Check if user can continue to checkout
   */
  function canContinue() {
    return !isCartLocked();
  }

  /**
   * Proceed to checkout if cart is valid
   */
  async function checkout() {
    if (canContinue()) {
      if (cart && cart.orderId) {
        const recurringOrderInfo: any[] = [];
        localStorageUtil.set(ACCOUNT + HYPHEN + ORDER_ID + HYPHEN + cart.orderId, recurringOrderInfo);
      }

      if (selectedProfile) {
        dispatch(orderActions.FETCH_ALLOWABLE_SHIPMODES_ACTION({}));
        const parameters = {
          ...payloadBase,
          body: {
            toOrderId: ".",
            payInfoFrom: selectedProfile,
            shippingAddressFromOrderProfile: 1,
            shippingModeFromOrderProfile: 1,
          },
        };

        try {
          let res = await cartService.copyOrder(parameters);

          // also need to call calculate -- since the copy_order API doesn't
          parameters.body = { calculationUsageId: ORDER_CONFIGS.calculationUsage.split(",") };
          res = await cartService.calculateOrder(parameters);

          if (res?.status === 200) {
            navigate(`${CHECKOUT}${SLASH}${CHECKOUT_REVIEW}`, { state: { selectedProfile } });
          }
        } catch (e) {
          console.log("Couldn't copy shipping info from checkout profile");
        }
      } else {
        if (loginStatus) {
          navigate(CHECKOUT);
        } else {
          navigate(SIGNIN, { state: { checkoutFlow: true } });
        }
      }
    }
  }

  return {
    isFetching,
    orderItems,
    loginStatus,
    hasDiscounts,
    localeMap,
    locale,
    promoCode,
    resetPromoCodeError,
    onPromoCodeChange,
    applyPromoCodeBasedOnKey,
    promoCodeError,
    promoCodeErrorMsg,
    applyPromoCode,
    selectedPromoCodes,
    onPromoCodeRemove,
    cart,
    canContinue,
    checkout,
    categoriesRedux,
    selectedProfile,
    setSelectedProfile,
    payloadBase,
    dataAvailabilityShipping: dataAvailabilityShipping?.data ? dataAvailabilityShipping?.data : null,
    dataAvailabilityShippingLoading,
    errorAvailabilityShipping,
    dataAvailabilityInStore: dataAvailabilityInStore?.data ? dataAvailabilityInStore?.data : null,
    dataAvailabilityInStoreLoading,
    errorAvailabilityInStore,
    dataShippingPrice: dataShippingPrice?.data?.shippingChargesWithoutTax
      ? parseFloat(dataShippingPrice?.data?.shippingChargesWithoutTax)
      : null,
    dataShippingPriceLoading,
    errorShippingPrice,
    availableProductsData,
    unavailableProductsData,
    productsUnavailable,
  };
};
