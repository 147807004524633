import styled from "@mui/styled-engine-sc";
import SvgIcon from "@mui/material/SvgIcon";

const CustomSvg = styled((props) => (
  <SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} />
))`
  ${({ theme }) => `
    width:16px;
    height:16px;

    &.big {
      width:24px;
      height:24px;
    }
    &.blue {
      path {
        fill: ${theme.palette.secondary.main};
      }
    }
  `}
`;

export const SvgIconDevis = (props) => {
  const { className = "" } = props;

  return (
    <CustomSvg {...{ className }}>
      <path d="M4 14.66c-.367 0-.68-.134-.94-.393a1.283 1.283 0 0 1-.393-.94V2.666c0-.367.133-.68.393-.94.26-.26.573-.393.94-.393h4.783a1.295 1.295 0 0 1 .933.386l3.231 3.232a1.32 1.32 0 0 1 .386.933v7.45c0 .366-.133.68-.392.94-.26.26-.573.392-.94.392H4v-.006ZM8.662 5.33V2.666H4v10.661h7.995v-7.33H9.33a.648.648 0 0 1-.473-.193.649.649 0 0 1-.193-.473Z" />
    </CustomSvg>
  );
};
