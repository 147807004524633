/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Services - Account and Contract
 * These services provide APIs to manage accounts and contracts.  a contract is an agreement that represents the terms and conditions that apply to a transaction. An account is a relationship between the merchant and the financial institution that processes transactions for that merchant.
 *
 * The version of the OpenAPI document: 9.1.6
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../../base';
// @ts-ignore
import { DevisTransformation } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { DevisTransformation403 } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { DevisValidity } from '../../../../../com/hcl/commerce/typescript/axios';
/**
 * DevisApi - axios parameter creator
 * @export
 */
export const DevisApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Transform devis into an order
         * @summary Transform devis into an order
         * @param {string} storeId The store identifier.
         * @param {string} devis The devis identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transfoDevis: async (storeId: string, devis: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('transfoDevis', 'storeId', storeId)
            // verify required parameter 'devis' is not null or undefined
            assertParamExists('transfoDevis', 'devis', devis)
            const localVarPath = `/store/{storeId}/resources/transfoDevis/{devis}`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"devis"}}`, String(devis));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the validity of a devis.
         * @summary Get the validity of a devis
         * @param {string} storeId The store identifier.
         * @param {string} devisId The devis identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validiteDevis: async (storeId: string, devisId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('validiteDevis', 'storeId', storeId)
            // verify required parameter 'devisId' is not null or undefined
            assertParamExists('validiteDevis', 'devisId', devisId)
            const localVarPath = `/store/{storeId}/resources/validiteDevis/{devisId}`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"devisId"}}`, String(devisId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DevisApi - functional programming interface
 * @export
 */
export const DevisApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DevisApiAxiosParamCreator(configuration)
    return {
        /**
         * Transform devis into an order
         * @summary Transform devis into an order
         * @param {string} storeId The store identifier.
         * @param {string} devis The devis identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transfoDevis(storeId: string, devis: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DevisTransformation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.transfoDevis(storeId, devis, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the validity of a devis.
         * @summary Get the validity of a devis
         * @param {string} storeId The store identifier.
         * @param {string} devisId The devis identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validiteDevis(storeId: string, devisId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DevisValidity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validiteDevis(storeId, devisId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DevisApi - factory interface
 * @export
 */
export const DevisApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DevisApiFp(configuration)
    return {
        /**
         * Transform devis into an order
         * @summary Transform devis into an order
         * @param {string} storeId The store identifier.
         * @param {string} devis The devis identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transfoDevis(storeId: string, devis: string, options?: any): AxiosPromise<DevisTransformation> {
            return localVarFp.transfoDevis(storeId, devis, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the validity of a devis.
         * @summary Get the validity of a devis
         * @param {string} storeId The store identifier.
         * @param {string} devisId The devis identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validiteDevis(storeId: string, devisId: string, options?: any): AxiosPromise<DevisValidity> {
            return localVarFp.validiteDevis(storeId, devisId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DevisApi - object-oriented interface
 * @export
 * @class DevisApi
 * @extends {BaseAPI}
 */
export class DevisApi extends BaseAPI {
    /**
     * Transform devis into an order
     * @summary Transform devis into an order
     * @param {string} storeId The store identifier.
     * @param {string} devis The devis identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevisApi
     */
    public transfoDevis(storeId: string, devis: string, options?: any) {
        return DevisApiFp(this.configuration).transfoDevis(storeId, devis, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the validity of a devis.
     * @summary Get the validity of a devis
     * @param {string} storeId The store identifier.
     * @param {string} devisId The devis identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevisApi
     */
    public validiteDevis(storeId: string, devisId: string, options?: any) {
        return DevisApiFp(this.configuration).validiteDevis(storeId, devisId, options).then((request) => request(this.axios, this.basePath));
    }
}
