/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import { createAction } from "@reduxjs/toolkit";
//Redux
import * as ACTIONTYPES from "../action-types/account";

/**
 * Action creator for getting list of user's orders
 * @returns Object action with action type get orders
 */
export function getOrders() {
  return { type: ACTIONTYPES.ORDERS_GET_REQUESTED };
}

const GET_ADDRESS_DETAIL_ACTION = createAction<any>(ACTIONTYPES.ADDRESS_DETAILS_GET_REQUESTED);
const PUT_ACCOUNT_DETAIL_ACTION = createAction<any>(ACTIONTYPES.ACCOUNT_DETAILS_PUT_REQUESTED);
const DELETE_ACCOUNT_REQUEST_ACTION = createAction<any>(ACTIONTYPES.ACCOUNT_DELETE_REQUESTED);
const DELETE_ACCOUNT_SUCCESS_ACTION = createAction<any>(ACTIONTYPES.ACCOUNT_DELETE_SUCCESS);
const DELETE_ACCOUNT_ERROR_ACTION = createAction<any>(ACTIONTYPES.ACCOUNT_DELETE_ERROR);

export {
  GET_ADDRESS_DETAIL_ACTION,
  PUT_ACCOUNT_DETAIL_ACTION,
  DELETE_ACCOUNT_REQUEST_ACTION,
  DELETE_ACCOUNT_SUCCESS_ACTION,
  DELETE_ACCOUNT_ERROR_ACTION,
};
