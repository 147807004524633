import React, { CSSProperties } from "react";
import { StyledCircularProgress } from "@hcl-commerce-store-sdk/react-component";
interface PageLoaderProps {
  minHeight?: number;
  positionFixed?: boolean; //this when true css positions fixed center of visible page
}

const fixedStyles = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  zIndex: "2000",
};

const PageLoader: React.FC<PageLoaderProps> = ({ minHeight = 400, positionFixed = false }) => {
  return (
    <div style={{ display: "flex", minHeight: minHeight, width: "100%", textAlign: "center" }}>
      <div style={{ margin: "auto", ...((positionFixed ? fixedStyles : {}) as CSSProperties) }}>
        <StyledCircularProgress />
      </div>
    </div>
  );
};

export default PageLoader;
