/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2021
 *
 *==================================================
 */
//Standard libraries
import { createAction } from "@reduxjs/toolkit";
//Redux
import * as ACTIONTYPES from "../action-types/category";

const UPDATE_CATEGORIES_STATE_ACTION = createAction<any>(ACTIONTYPES.UPDATE_CATEGORIES_STATE);
const UPDATE_HEADER_LINK_STATE_ACTION = createAction<any>(ACTIONTYPES.UPDATE_HEADER_LINK_STATE);
const UPDATE_CURRENT_CATEGORY_STATE_ACTION = createAction<any>(ACTIONTYPES.UPDATE_CATEGORY_STATE);
const UPDATE_TOP_CATEGORIES_STATE_ACTION = createAction<Record<any, any>[]>(ACTIONTYPES.UPDATE_TOP_CATEGORY_STATE);
const UPDATE_FILTERED_CATEGORIES_STATE_ACTION = createAction<Record<any, any>[]>(
  ACTIONTYPES.UPDATE_FILTERED_CATEGORY_STATE
);

export {
  UPDATE_CATEGORIES_STATE_ACTION,
  UPDATE_HEADER_LINK_STATE_ACTION,
  UPDATE_CURRENT_CATEGORY_STATE_ACTION,
  UPDATE_TOP_CATEGORIES_STATE_ACTION,
  UPDATE_FILTERED_CATEGORIES_STATE_ACTION,
};
