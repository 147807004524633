import React from "react";
import styled from "@mui/styled-engine-sc";
import Typography from "@mui/material/Typography";

const ComponentWrapper = React.forwardRef((props: any, ref: any) => {
  return <Typography {...props} ref={ref} />;
});

const StyledTypography = styled(ComponentWrapper)`
  ${({ theme }) => `
  &.hidden {
    visibility: hidden;
  }

    li, a {
      text-decoration: none;
    }
    a {
      //color: ${theme.palette.primary.main};

      //&:hover {
        //color: ${theme.palette.primary.dark};
      //}

      &:visited {
        //color: ${theme.palette.primary.main};
      }
    }
    &.success {
      color: ${theme.palette.success.main};
    }
    &.accounts-links-title {
      margin: ${theme.spacing(3)} 0 ${theme.spacing(2)};
    }
    &.MuiTypography-gutterBottom {
      margin-bottom: ${theme.spacing(1)};
    }
    &.total-discount {
      color: ${theme.palette.text.highlight};
    }
    &.selected {
      color: ${theme.palette.primary.main};
    }
    .strikethrough {
      text-decoration: line-through;
      font-size: 0.9em;
      opacity: 0.8;
      margin-right: ${theme.spacing(1)};
    }

    &.expanded-menu-sub-links {
      margin-bottom: ${theme.spacing(0.75)};
      margin-top: ${theme.spacing(0.75)};
    }

    &.wrapText {
      white-space: normal;
      overflow-wrap: break-word;
      word-wrap: break-word;
      word-break: break-word;
    }

    &.text-overflow {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &.address-card-width {
      width: ${theme.spacing(30)};
      padding-right: ${theme.spacing(1)};
    }

    &.error {
      color: ${theme.palette.primary.main};
    }

    &.number-stroke {
      font-size: 115px;
      line-height: 123px;
      font-weight: 900;
      text-shadow: -1px 0 ${theme.palette.tertiary.light}, 0 1px ${theme.palette.tertiary.light}, 1px 0 ${
    theme.palette.tertiary.light
  }, 0 -1px ${theme.palette.tertiary.light};
      color: ${theme.palette.secondary.dark};
      padding-left: ${theme.spacing(0.5)};

      &.small {
        font-size: 64px;
        line-height: 78px;
      }

      &.beige {
        text-shadow: -1px 0 ${theme.palette.secondary.dark}, 0 1px ${theme.palette.secondary.dark}, 1px 0 ${
    theme.palette.secondary.dark
  }, 0 -1px ${theme.palette.secondary.dark};
      color: ${theme.palette.tertiary.main};
      padding-left: 0;
      }

      &.blue {
        text-shadow: -1px 0 ${theme.palette.secondary.dark}, 0 1px ${theme.palette.secondary.dark}, 1px 0 ${
    theme.palette.secondary.dark
  }, 0 -1px ${theme.palette.secondary.dark};
      color: ${theme.palette.secondary.extraLight};
      //padding-left: 0;
      }

    }

    &.line-clamp-1 {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
    }

    &.line-clamp-2 {
      //height: 48px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &.line-clamp-4 {
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }


    // checkout review shipment group summary heading
    &.shipment-group-heading{
      font-weight: bold
    }

    &.no-promo {
      display: flex;
      align-items: center;
      gap: 5px;

      &::before {
        content: "";
        display: block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #EB002D;
      }
  `}
`;

export { StyledTypography };

export const StyledMenuTypography = styled(({ ...props }) => <StyledTypography {...props} />)`
  ${({ theme }) => `
  color: ${theme.palette.text.primary};
  text-decoration: none;

  &:hover {
    color: ${theme.palette.primary.main};
  }

  li {
    color: ${theme.palette.text.primary};

    &:hover {
      color: ${theme.palette.primary.main};

      /*a, span {
        color: ${theme.palette.primary.main};
      }*/
    }
  }

  /*a {
    color: ${theme.palette.text.primary};

    &:hover {
      color: ${theme.palette.primary.main};
    }

    &:visited {
      color: ${theme.palette.text.primary};
    }
  }*/

  /*&.searchBar-resultsCategory {
    color: ${theme.palette.text.secondary};
    padding: ${theme.spacing(1)} ${theme.spacing(2)};

    &.MuiTypography-body2 {
      display: block;
    }

    &:hover {
      color: ${theme.palette.text.secondary};
    }

    &:not(:first-child) {
      border-top: 1px solid ${theme.palette.text.disabled};
      padding-top: ${theme.spacing(2)};
    }
  }*/
  `}
`;

export const StyledClickableText = styled("p")`
  display: inline;
  text-decoration: underline;
  cursor: pointer;
  font-weight: bold;
`;

/* export const StyledLinkTypography = styled(({ ...props }) => <StyledTypography {...props} />)`
  ${({ theme }) => `
  color: ${theme.palette.text.primary};

  a {
    color: ${theme.palette.text.primary};

    &:hover {
      color: ${theme.palette.primary.main};
    }

    &:visited {
      color: ${theme.palette.text.primary};
    }
  }
  `}
`; */
