//Standard libraries
import { StyledBox, StyledTypography } from "../../../styled-mui";
import { Stars } from "../stars/Stars";

interface ProductAvisProps {
  avis: { note?: number; count?: number };
  className?: string;
  big?: boolean;
}

const ProductAvis: React.FC<ProductAvisProps> = ({ avis, className = "", big = false }) => {
  const note = avis?.note || 0;
  const count = avis?.count || "0";

  return (
    <StyledBox sx={{ display: "flex", alignItems: "center" }} className={className}>
      {note !== 0 && <Stars big={false} note={note} />}
      {count !== "0" ? (
        <StyledTypography
          variant="body4"
          component="p"
          px={note !== 0 ? 1 : 0}
          sx={{ textDecoration: "underline" }}
          className="">
          {`${Math.floor(count)} avis`}
        </StyledTypography>
      ) : (
        <>
          {note !== 0 ? (
            <StyledTypography variant={`${big ? "body4" : "caption"}`} component="p" pl={big ? 2 : 1}>
              {big ? (
                <>
                  <StyledTypography variant={"subtitle2"} component="span">
                    {Math.floor(note)}
                  </StyledTypography>
                  /5
                </>
              ) : (
                <>{Math.floor(note)} sur 5</>
              )}
            </StyledTypography>
          ) : (
            <StyledTypography
              variant="body4"
              component="p"
              sx={{ cursor: "default" }}
              px={note !== 0 ? 1 : 0}
              className="">
              Aucun avis
            </StyledTypography>
          )}
        </>
      )}
    </StyledBox>
  );
};

export { ProductAvis };
