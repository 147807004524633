import styled from "@mui/styled-engine-sc";

const MadeInFrance = styled((props) => (
  <svg
    width="81"
    height="80"
    viewBox="0 0 81 80"
    fill="none"
    style={{
      display: "block",
      margin: "0 auto",
    }}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  />
))`
  ${({ theme }) => `
    &.blue {
      .blue {
        fill: ${theme.palette.secondary.main};
      }
      .opacity {
        opacity: 0.5;
      }
    }
  `}
`;

export const SvgMadeInFrance = (props) => {
  const { className = "" } = props;

  return (
    <MadeInFrance {...{ className }}>
      <path d="M35.553 26.8915H20.4072V54.8977H35.553V26.8915Z" fill="#3F6AB2" className="blue opacity" />
      <path d="M64.7773 26.6858H50.3145V55.1082H64.7773V26.6858Z" fill="#EB002D" className="blue" />
      <g>
        <path
          d="M67.5618 25.7959V25.3175H64.1229V25.0783H63.8824V20.4425H63.4014V25.0783H49.2705V23.6622H48.7895V25.0783H34.6586V23.6622H34.1776V25.0783H19.5272V20.9544H19.0462V25.0783H18.8058V25.3175H14.7319V25.7959H18.8058V53.5295H14.7319V54.0079H18.8058V54.3045H19.0462V59.5574H19.5272V54.3045H34.1776V56.1559H34.6586V54.3045H48.7895V56.1559H49.2705V54.3045H63.4014V59.5574H63.8824V54.3045H64.1229V54.0079H67.5618V53.5295H64.1229V25.7959H67.5618ZM34.6586 26.0351H48.7895V53.3477H34.6586V26.0351ZM34.1776 53.3477H19.7677V26.0351H34.1776V53.3477ZM49.2705 26.0351H63.161V53.3477H49.2705V26.0351Z"
          fill="#212121"
          className="blue opacity"
        />
      </g>
      <path
        d="M79.7967 40.5379C79.6788 49.8761 76.2011 58.9029 70.0008 65.9573C63.7392 73.0778 55.1087 77.6926 45.6998 78.9478C24.2252 81.8073 4.42101 66.6698 1.5615 45.1905C-1.30744 23.7159 13.8347 3.91177 35.3093 1.05227C44.3361 -0.150988 53.5563 1.83556 61.2713 6.64387L61.6441 6.0446C53.7923 1.14664 44.4021 -0.87766 35.2149 0.349189C13.3534 3.26532 -2.06242 23.4234 0.853706 45.2849C3.52918 65.3628 20.7475 80 40.4762 80C42.2268 80 44.0058 79.882 45.7894 79.6461C55.3683 78.3674 64.1544 73.6723 70.5293 66.4245C76.8429 59.2474 80.3819 50.0555 80.5045 40.5474L79.7967 40.5379Z"
        fill="#212121"
        className="blue opacity"
      />
    </MadeInFrance>
  );
};
