/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
import PropTypes from "prop-types";
import styled from "@mui/styled-engine-sc";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material";
// import { StyledGrid } from "@hcl-commerce-store-sdk/react-component";

//Custom libraries
import { StyledTypography, StyledLink, StyledPaper, StyledBox } from "../../../styled-mui";
import { palette } from "../../../themes/color-palette";

const StyledSidebarWrapper = styled((props: any) => {
  const { scrollable, className, ...other } = props;
  return <StyledPaper className={className + (scrollable ? " scrollable" : "")} {...other} />;
})`
  ${({ theme }) => `

    &.product-filter{
      margin-top:${theme.spacing(15)};
      padding: ${theme.spacing(5)};

      ${theme.breakpoints.down("sm")} {
        margin-top: 0;
        height: 75vh;
      }
    }

    &.account-menu {
      padding: ${theme.spacing(1)} ${theme.spacing(2)} ${theme.spacing(1)} 0;
    }

    .MuiList-root {
      padding: 0;

      a {
        display: block;
      }
    }

    .MuiAppBar-root {
      box-shadow: none;
    }

    .MuiListItem-root {
      &.Mui-selected {
        background: ${theme.palette.primary.dark};
        color: white;

        span {
          font-weight: 600;
          letter-spacing: 0.02rem;
        }
      }
    }

    /*.section-title:not(.section-heading) {
      padding: ${theme.spacing(2)};
      border-top: 1px solid ${theme.palette.text.disabled};
      span {
        line-height: 1.3;
      }
    }

    .section-heading {
      line-height: ${theme.spacing(8)};
      height: ${theme.spacing(8)};
    }*/

    .section-link {
      transition: background 250ms ease-in-out;
      &:hover {
        color: white;
        background: ${theme.palette.primary.main};
      }
    }
    .horizontal-margin{
      margin-left: ${theme.spacing(1)};
      margin-right: ${theme.spacing(1)};
    }
    .price-filter{
      display: flex;
    }
    .section-disabled {
      color: ${theme.palette.text.disabled};
    }

    .section-link .MuiListItem-root,
    .section-disabled {
        padding: 0 ${theme.spacing(2)};
        height: ${theme.spacing(5)};
        line-height: ${theme.spacing(5)};
      }
    }

    .product-list-link-show-more {
      color: ${theme.palette.text.primary};
    }

    .product-list-link-show-less {
      color: ${theme.palette.text.primary};
      svg {
        transform: rotate(180deg);
      }
    }

    .product-list-input-price {
      .MuiInputBase-input {
        padding: ${theme.spacing(2.5)};
        border-color: ${palette.gray.neutralGray200};
        text-align: center;
        font-weight: 400;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
      .MuiOutlinedInput-notchedOutline {
        border-color: ${theme.palette.gray.neutralGray200};
      }
      &.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-width: 1px;
      }
      .MuiInputAdornment-root {
        font-size: 12px;
        font-weight: 400;
        color: ${palette.gray.neutralGray500};
      }
    }
  `}
`;

const StyledNavigationHeader = styled(({ ...props }) => <StyledBox {...props} />)`
  ${({ theme }) => `
    justify-content: space-between;

    .MuiListItemIcon-root {
      min-width: auto;
    }
  `}
`;

StyledSidebar.propTypes = {
  title: PropTypes.string.isRequired,
  sidebarContent: PropTypes.any.isRequired,
  linkTo: PropTypes.string,
  selectedFacets: PropTypes.element,
  breakpoint: PropTypes.string,
  className: PropTypes.string,
  scrollable: PropTypes.bool,
};

function StyledSidebar({ title, sidebarContent, linkTo, selectedFacets, breakpoint, className, scrollable }: any) {
  const theme = useTheme();
  const mobileBreakpoint = breakpoint ? breakpoint : "sm";
  const isMobile = useMediaQuery(theme.breakpoints.down(mobileBreakpoint));

  const sidebarElement = (

      <StyledSidebarWrapper className={className} scrollable={scrollable}>
        <StyledNavigationHeader className="section-title section-heading">
          {linkTo ? (
            <StyledLink testId={`account-sidebar-${title.toLowerCase()}`} to={linkTo}>
              <StyledTypography variant="subtitle1">{title}</StyledTypography>
            </StyledLink>
          ) : (
            <>
              {/* <StyledTypography variant="subtitle1">{title}</StyledTypography> */}
              <StyledTypography
                variant={isMobile ? "h5" : "body1"}
                component="p"
                sx={{
                  textTransform: "uppercase",
                  borderBottom: 1,
                  borderColor: { xs: "gray.neutralGray600", sm: "secondary.main" },
                  color: { sm: "secondary.main" },
                  pb: 6,
                }}>
                Filtres
              </StyledTypography>
              {!isMobile && selectedFacets}
            </>
          )}
        </StyledNavigationHeader>

        {sidebarContent}
      </StyledSidebarWrapper>
  );

  return sidebarElement;
}

export { StyledSidebar };
