import React, { useState } from "react";
//UI
import { StyledPaper, StyledGrid } from "@hcl-commerce-store-sdk/react-component";
import { StyledLink, StyledHeaderFooterLink, StyledTypography, StyledBox, StyledButton } from "../../styled-mui";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { ImageLayout } from "../elements/image";
import { site } from "../../_foundation/constants/site";
import { useTranslation } from "react-i18next";
import AsyncCall from "../../_foundation/gtm/async.service";
import { CLICK } from "../../_foundation/constants/gtm";
import { HUBPROMO } from "../../constants/routes";
import { useSite } from "../../_foundation/hooks/useSite";

interface ExpandedMenuItemProps {
  categories?: any;
  onSelectedItem?: () => void;
}

/**
 * ExpandedMenuItem component
 * expanded menu parent items for desktop/tablet view
 * @param props
 */
const ExpandedMenuItem: React.FC<ExpandedMenuItemProps> = (props: any) => {
  const { t } = useTranslation();
  const categories = props.categories ? props.categories : [];
  const initMenu = { child0: 0, child1: 0 };
  const [activeMenuId, setActiveMenuId] = useState(initMenu);
  const [collectionMenu, setCollectionMenu] = useState(false);
  const { mySite } = useSite();

  const mockCollection = [
    {
      seo: {
        href: "/collection/classique",
      },
      name: "Classique",
      image: `${site.defaultLocalImageUrl}/images/collection/classique.jpg`,
    },
    {
      seo: {
        href: "/collection/essentielle",
      },
      name: "Essentielle",
      image: `${site.defaultLocalImageUrl}/images/collection/essentielle.jpg`,
    },
    {
      seo: {
        href: "/collection/campagne",
      },
      name: "Campagne",
      image: `${site.defaultLocalImageUrl}/images/collection/campagne.jpg`,
    },
    {
      seo: {
        href: "/collection/contemporaine",
      },
      name: "contemporaine",
      image: `${site.defaultLocalImageUrl}/images/collection/contemporaine.jpg`,
    },
    {
      seo: {
        href: "/collection/nordique",
      },
      name: "Nordique",
      image: `${site.defaultLocalImageUrl}/images/collection/nordique.jpg`,
    },
    {
      seo: {
        href: "/collection/deco",
      },
      name: "Déco",
      image: `${site.defaultLocalImageUrl}/images/collection/deco.jpg`,
    },
    {
      seo: {
        href: "/collection/industrielle",
      },
      name: "Industrielle",
      image: `${site.defaultLocalImageUrl}/images/collection/industrielle.jpg`,
    },
    {
      seo: {
        href: "/collection/chalet",
      },
      name: "Chalet",
      image: `${site.defaultLocalImageUrl}/images/collection/chalet.jpg`,
    },
    {
      seo: {
        href: "/collection/marine",
      },
      name: "Marine",
      image: `${site.defaultLocalImageUrl}/images/collection/marine.jpg`,
    },
    {
      seo: {
        href: "/collection/studio",
      },
      name: "Studio",
      image: `${site.defaultLocalImageUrl}/images/collection/studio.jpg`,
    },
  ];

  const Buttons = ({ name, endIcon, onClick }) => {
    return (
      <StyledButton
        fullWidth
        variant="text"
        endIcon={endIcon}
        color="black"
        className="expanded-menu-button"
        onClick={onClick}>
        {name}
      </StyledButton>
    );
  };

  const Links = ({ page }) => {
    let finalSeoHref = "";
    if (
      (!mySite.storeCfg.userData.SEO_FE_DISABLE ||
        (mySite.storeCfg.userData.SEO_FE_DISABLE && mySite.storeCfg.userData.SEO_FE_DISABLE != "true")) &&
      page?.seo?.href?.split("/").length == 2
    ) {
      const seoHref = page?.seo.href;
      finalSeoHref = `/produits${categories[activeMenuId.child0].seo?.href}${
        categories[activeMenuId.child0]?.children[activeMenuId.child1].seo?.href
      }${seoHref}`;
    } else {
      finalSeoHref = page?.seo?.href;
    }
    return (
      <StyledHeaderFooterLink
        testId={`header-${page.id}`}
        key={"Link_" + page.id}
        onClick={() => {
          AsyncCall.sendClick(CLICK.NAV, { navlink: finalSeoHref });
          props.onSelectedItem();
        }}
        to={finalSeoHref}
        state={{
          breadCrumbTrailEntryView: [
            { label: page.name, value: page.id, seo: page.seo },
            {
              label: page.name,
              value: page.id,
              seo: page.seo,
            },
          ],
        }}>
        {page.name}
      </StyledHeaderFooterLink>
    );
  };

  return (
    <StyledPaper className="expanded-menu-three-tier-submenu">
      <StyledGrid container>
        <StyledGrid item className="expanded-menu-first-tier" md={3} pt={6} pb={20} px={5}>
          <StyledTypography variant="h5" pb={7}>
            {t("Header.Menu.ProductTitle")}
          </StyledTypography>
          {categories && (
            <StyledBox component="ul">
              {categories?.map((page: any, index: number) => {
                return (
                  <StyledTypography
                    component="li"
                    variant={index === activeMenuId.child0 ? "body1" : "body2"}
                    key={page.id}>
                    {page.children ? (
                      <Buttons
                        name={`${page.name}`}
                        endIcon={<ChevronRightIcon />}
                        onClick={() => {
                          AsyncCall.sendClick(CLICK.NAV, { navlink: page.name });
                          setActiveMenuId({ child0: index, child1: 0 });
                          setCollectionMenu(false);
                        }}
                      />
                    ) : (
                      <Links page={page} />
                    )}
                  </StyledTypography>
                );
              })}
            </StyledBox>
          )}
          {/* <StyledBox mt={4} pt={4} sx={{ borderTop: 1, borderColor: "gray.neutralGray400" }}>
            <StyledButton
              fullWidth
              variant="text"
              endIcon={<ChevronRightIcon />}
              color="black"
              className={`expanded-menu-button ${collectionMenu ? "expanded-menu-button-active" : ""}`}
              onClick={() => {
                setActiveMenuId({ child0: -1, child1: 0 });
                setCollectionMenu(true);
              }}
              sx={{ fontWeight: collectionMenu ? 700 : 400 }}>
              Nos collections
            </StyledButton>
          </StyledBox> */}
          <StyledButton
            variant="text"
            endIcon={<ChevronRightIcon />}
            color="primary"
            className="expanded-menu-bottom-link link-hover-animated"
            onClick={() => {
              AsyncCall.sendClick(CLICK.NAV, { navlink: t("Header.Menu.Tracking.SeeGoodDeals") });
              // AsyncCall.sendClick(CLICK.NAV,{navlink:"bonne-affaire"})
              props.onSelectedItem();
            }}>
            <StyledHeaderFooterLink
              className="primary"
              testId={`header-bonne-affaire}`}
              to={HUBPROMO}
              state={{
                breadCrumbTrailEntryView: [
                  {
                    label: "name",
                    value: "id",
                    seo: "seo",
                  },
                ],
              }}>
              <StyledTypography variant="body4">{t("Header.Menu.Actions.SeeGoodDeals")}</StyledTypography>
            </StyledHeaderFooterLink>
          </StyledButton>
        </StyledGrid>

        {collectionMenu && (
          <StyledGrid container item className="" md={9} pt={18} pb={20} px={5} spacing={2}>
            {mockCollection.map((item, index) => (
              <StyledGrid item xs={12} sm={3} key={index}>
                <StyledLink
                  to={item.seo.href}
                  onClick={() => {
                    AsyncCall.sendClick(CLICK.NAV, { navlink: item.seo.href });
                    props.onSelectedItem();
                    setCollectionMenu(false);
                  }}>
                  <StyledBox className="collection-menu-box">
                    <ImageLayout
                      src={item.image}
                      alt={""}
                      width="100%"
                      height={120}
                      className="collection-menu-image"
                    />
                    <StyledTypography
                      component="p"
                      variant="h6"
                      color="white.main"
                      className="uppercase collection-menu-text">
                      {item.name}
                    </StyledTypography>
                  </StyledBox>
                </StyledLink>
              </StyledGrid>
            ))}
          </StyledGrid>
        )}

        {categories[activeMenuId.child0]?.children && (
          <StyledGrid item className="expanded-menu-second-tier" md={3} pt={6} pb={20} px={5}>
            <StyledBox component="ul">
              {categories[activeMenuId.child0]?.children?.map((page2: any, index: number) => (
                <StyledTypography
                  component="li"
                  variant={index === activeMenuId.child1 ? "body1" : "body2"}
                  key={page2.id}>
                  {page2.children ? (
                    <Buttons
                      name={page2.name}
                      endIcon={<ChevronRightIcon />}
                      onClick={() => {
                        AsyncCall.sendClick(CLICK.NAV, { navlink: page2.name });
                        setActiveMenuId({ child0: activeMenuId.child0, child1: index });
                      }}
                    />
                  ) : (
                    <Links page={page2} />
                  )}
                </StyledTypography>
              ))}
            </StyledBox>
            <StyledButton
              variant="text"
              endIcon={<ChevronRightIcon />}
              color="secondary"
              className="expanded-menu-bottom-link link-hover-animated"
              onClick={() => {
                AsyncCall.sendClick(CLICK.NAV, {
                  navlink:
                    t("Header.Menu.Tracking.DiscoverUniverse") + "_" + categories[activeMenuId.child0]?.seo?.href,
                });
                // AsyncCall.sendClick(CLICK.NAV,{navlink:categories[activeMenuId.child0]?.seo?.href})
                props.onSelectedItem();
              }}>
              <StyledLink
                className="secondary"
                testId={`header-bonne-affaire}`}
                to={
                  !mySite.storeCfg.userData.SEO_FE_DISABLE ||
                  (mySite.storeCfg.userData.SEO_FE_DISABLE && mySite.storeCfg.userData.SEO_FE_DISABLE != "true")
                    ? `/produits${categories[activeMenuId.child0]?.seo?.href}`
                    : categories[activeMenuId.child0]?.seo?.href
                }
                state={{
                  breadCrumbTrailEntryView: [
                    {
                      label: categories[activeMenuId.child0]?.name,
                      value: categories[activeMenuId.child0]?.id,
                      seo: categories[activeMenuId.child0]?.seo,
                    },
                  ],
                }}>
                <StyledTypography variant="body4">{t("Header.Menu.Actions.DiscoverUniverse")}</StyledTypography>
              </StyledLink>
            </StyledButton>
          </StyledGrid>
        )}
        {categories[activeMenuId.child0]?.children &&
          categories[activeMenuId.child0]?.children[activeMenuId.child1]?.children && (
            <StyledGrid item className="expanded-menu-third-tier" md={6} pt={6} pb={20} px={5}>
              <StyledBox component="ul">
                {categories[activeMenuId.child0]?.children[activeMenuId.child1]?.children?.map((page2: any) => (
                  <StyledTypography component="li" variant="body2" key={page2.id}>
                    <Links page={page2} />
                  </StyledTypography>
                ))}
              </StyledBox>
              {/** Don't show for kitchen model SCU1555698 */}
              {categories[activeMenuId.child0]?.children[activeMenuId.child1]?.identifier !== "SCU1555698" && (
                <StyledButton
                  variant="text"
                  endIcon={<ChevronRightIcon />}
                  color="secondary"
                  className="expanded-menu-bottom-link link-hover-animated"
                  onClick={() => {
                    AsyncCall.sendClick(CLICK.NAV, {
                      navlink:
                        t("Header.Menu.Tracking.SeeAllProducts") +
                        "_" +
                        categories[activeMenuId.child0]?.children[activeMenuId.child1]?.seo?.href,
                    });
                    props.onSelectedItem();
                  }}>
                  <StyledLink
                    className="secondary"
                    testId={`header-bonne-affaire}`}
                    to={
                      !mySite.storeCfg.userData.SEO_FE_DISABLE ||
                      (mySite.storeCfg.userData.SEO_FE_DISABLE && mySite.storeCfg.userData.SEO_FE_DISABLE != "true")
                        ? `/produits${categories[activeMenuId.child0].seo?.href}${
                            categories[activeMenuId.child0]?.children[activeMenuId.child1].seo?.href
                          }`
                        : categories[activeMenuId.child0]?.children[activeMenuId.child1]?.seo?.href
                    }
                    state={{
                      breadCrumbTrailEntryView: [
                        {
                          label: categories[activeMenuId.child0]?.children[activeMenuId.child1]?.name,
                          value: categories[activeMenuId.child0]?.children[activeMenuId.child1]?.id,
                          seo: categories[activeMenuId.child0]?.children[activeMenuId.child1]?.seo,
                        },
                      ],
                    }}>
                    <StyledTypography variant="body4">{t("Header.Menu.Actions.SeeAllProducts")}</StyledTypography>
                  </StyledLink>
                </StyledButton>
              )}
            </StyledGrid>
          )}
      </StyledGrid>
    </StyledPaper>
  );
};

export default ExpandedMenuItem;
