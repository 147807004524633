/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */

//Standard libraries
import { AxiosPromise } from "axios";
//Foundation libraries
import { getSite } from "../../hooks/useSite";
import { site } from "../../constants/site";
import { SofincoApi } from "@hcl-commerce-store-sdk/typescript-axios-transaction";

const tokenSofincoApiInstance = new SofincoApi(undefined, site.transactionContext);
const tokenSofincoService = {
  /**
   * Get the token from Sofinco.
   * @summary getSofincoToken
   * @param {string} storeId The store identifier.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SofincoApi
   */
  getTokenSofinco(parameters: any): AxiosPromise<any> {
    const siteInfo = getSite();
    const { storeId = siteInfo?.storeID, ...options } = parameters;
    return tokenSofincoApiInstance.sofinco(storeId, options);
  },

  getSimulation(parameters: any): Promise<any> {
    const siteInfo = getSite();
    const { amount, token } = parameters;
    const uri = siteInfo?.storeCfg?.userData?.SOFINCO_URI;
    const partners = siteInfo?.storeCfg?.userData?.SOFINCO_PARTNERS;
    const campaigns = siteInfo?.storeCfg?.userData?.SOFINCO_CAMPAIGNS;

    const url = `${uri}/splitPaymentSimulation/v1/partners/${partners}/campaigns/${campaigns}/simulations/splitPayments/calculate`;

    const myHeaders = new Headers();
    myHeaders.append("Context-ApplicationId", "splitPayment");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const raw = JSON.stringify({
      amount: amount,
      scaleCode: siteInfo?.storeCfg?.userData?.SOFINCO_SCALE_CODE,
      businessProviderId: siteInfo?.storeCfg?.userData?.SOFINCO_BUSINESS_PROVIDER_ID,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
    };

    return fetch(url, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        return result;
      })
      .catch((error) => {
        console.log("error", error);
      });
  },
};

export default tokenSofincoService;
