import styled from "@mui/styled-engine-sc";
import ListItemIcon from "@mui/material/ListItemIcon";
import React from "react";

const StyledListItemIcon = styled(({ ...props }) => <ListItemIcon {...props} />)`
  ${({ theme }) => `
    min-width: 28px;
  `}
`;

export { StyledListItemIcon };
