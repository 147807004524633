import styled from "@mui/styled-engine-sc";
import { palette } from "../../../themes/color-palette";

export const StyledProductDelivery = styled("div")`
  ${({ theme }) => `
    .product-information-availability {
      display: flex;
      align-items: flex-start;

      &:before {
        content: '';
        min-width: 10px;
        width: 10px;
        height: 10px;
        border-radius: 100%;
        margin-right: ${theme.spacing(2.5)};
        margin-top: ${theme.spacing(0.75)};
      }

      &.mt {
        &:before {
          margin-top: ${theme.spacing(1.25)};
        }
      }

      &.not-available {
        &:before {
          background: inherit;
          border: ${palette.primary.main} 1px solid;
        }
        &.bg-primary-light {
          //margin-right: ${theme.spacing(20)};
          &:before {
            margin-top: ${theme.spacing(2.5)};
          }
          .bg-primary-light-span {
            background: ${palette.primary.light};
            padding: ${theme.spacing(2)};
            border-radius: 8px;
          }
          .bg-white-span {
            background: ${palette.white};
            padding: ${theme.spacing(2)};
            border-radius: 8px;
          }
        }
      }

      &.available {
        &:before {
          background: ${palette.success.main};
        }
      }

      &.unconfirmed {
        &:before {
          background: transparent;
        }
      }

      &.available-in-store {
        &:before {
          background: ${palette.alert.main};
        }
      }

      .MuiTypography-root {
        padding: 0;
        line-height: 16px;
        flex: 1;
      }
    }
  `}
`;
