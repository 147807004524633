import React from "react";
import styled from "@mui/styled-engine-sc";
import Switch from "@mui/material/Switch";
import { StyledFormGroup } from "@hcl-commerce-store-sdk/react-component";
import { StyledFormControlLabel } from "../form/styled-form-control-label";

interface CustomSwitchProps {
  checked: boolean;
  setChecked: (event: any) => void;
  label?: string;
  [key: string]: any;
}

const CustomSwitch = React.forwardRef<any, CustomSwitchProps>((props: any, ref: any) => {
  const { checked, setChecked, label, ...other } = props;

  return label ? (
    <StyledFormGroup>
      <StyledFormControlLabel
        control={<Switch checked={checked} onChange={setChecked} {...other} ref={ref} />}
        label={label}
      />
    </StyledFormGroup>
  ) : (
    <Switch checked={checked} onChange={setChecked} {...other} ref={ref} />
  );
});

const StyledSwitch = styled(CustomSwitch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&.big": {
    width: 36,
    height: 20,
    "& .MuiSwitch-thumb": {
      width: 16,
      height: 16,
      borderRadius: 8,
    },
    "& .MuiSwitch-track": {
      borderRadius: 20 / 2,
    },
    "& .MuiSwitch-switchBase": {
      "&.Mui-checked": {
        transform: "translateX(16px)",
      },
    },
  },
  /* "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  }, */
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#06AB65",
      },
      "&.Mui-disabled": {
        "& + .MuiSwitch-track": {
          backgroundColor: "#B9C2D7",
        },
      },
    },
  },
  "& .MuiSwitch-thumb": {
    //boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 100,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: "#B9C2D7",
    boxSizing: "border-box",
  },
}));

export { StyledSwitch };
