//Standard libraries
import { AxiosPromise } from "axios";
//Foundation libraries
import { getSite } from "../../hooks/useSite";
import { site } from "../../constants/site";
import { MessagesApi, DeleteAccountApi } from "@hcl-commerce-store-sdk/typescript-axios-transaction";

const messageApiInstance = new MessagesApi(undefined, site.transactionContext);
const deleteAccountApiInstance = new DeleteAccountApi(undefined, site.transactionContext);
const messageService = {
  /**
   * Send the contact form to SF.
   * @summary SF Contact Form
   * @param {string} storeId The store identifier.
   * @param {FormSFRequest} formSFRequest The request object for document request.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MessagesApi
   */
  sendFormSF(parameters: any): AxiosPromise<any> {
    const siteInfo = getSite();
    const { storeId = siteInfo?.storeID, body, ...options } = parameters;
    return messageApiInstance.formSF(storeId, body, options);
  },

  /**
   * Send the email to the lapeyre service to handle the account deletion.
   * @summary Customer account deletion
   * @param {string} storeId The store identifier.
   * @param {DeleteCustomerRequest} deleteCustomerRequest The request object for document request.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MessagesApi
   */
  deleteCustomer(parameters: any): AxiosPromise<any> {
    const siteInfo = getSite();
    const { storeId = siteInfo?.storeID, body, ...options } = parameters;
    return messageApiInstance.deleteCustomer(storeId, body, options);
  },
  deleteAccount(parameters: any): AxiosPromise<any> {
    const siteInfo = getSite();
    const { storeId = siteInfo?.storeID, body, ...options } = parameters;
    return deleteAccountApiInstance.deleteAccount(storeId, body, options);
  },
};

export default messageService;
