import { createTheme } from "@mui/material";
import { TypographyOptions } from "@mui/material/styles/createTypography";
import { palette } from "./color-palette";
import { typography } from "./typography";
import { Theme } from "@mui/material/styles";
import type {} from "@mui/lab/themeAugmentation";

type ShadowType = [
  "none",
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string
];

export const lapeyreOverrides = {
  name: "Lapeyre",
  spacing: 4,
  palette: {
    primary: {
      light: palette.primary.light,
      main: palette.primary.main,
      dark: palette.primary.dark,
      extraDark: palette.primary.extraDark,
      contrastText: palette.white.main,
    },
    secondary: {
      extraLight: palette.blue.extraLight,
      light: palette.blue.light,
      main: palette.blue.dark,
      contrastText: palette.white.main,
      dark: palette.blue.background,
    },
    tertiary: {
      light: palette.beige.light,
      main: palette.beige.main,
      contrastText: palette.blue.dark,
    },
    black: {
      main: palette.gray.neutralGray900,
      contrastText: palette.white.main,
    },
    white: {
      main: palette.white.main,
    },
    text: {
      primary: palette.text.primary,
      secondary: palette.text.primary,
      disabled: palette.text.disabled,
      highlight: palette.text.highlight,
      alert: palette.alert.main,
      expandedMenu: palette.gray.neutralGray900,
      success: palette.success.main,
      error: palette.error.main,
      successDark: palette.success.dark,
      vertAmenagementExterieurs: palette.vertAmenagementExterieurs.main,
    },
    gray: { ...palette.gray },
    success: { ...palette.success },
    action: {
      active: palette.gray.neutralGray800,
      hover: palette.border.hover,
      disabled: palette.text.disabled,
      disabledBackground: palette.disabled.background,
    },
    background: {
      default: palette.background.default,
      paper: palette.background.paper,
      transparency: palette.background.transparency,
    },
    divider: palette.text.disabled,
    border: {
      dark: palette.divider.dark,
      alert: palette.alert.main,
    },
    header: {
      main: palette.blue.extraLight,
    },
    univers: {
      cuisine: palette.univers.cuisine,
      salleDeBain: palette.univers.salleDeBain,
      fenetre: palette.univers.fenetre,
      amenagement: palette.univers.amenagement,
    },
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  typography: typography as TypographyOptions,
  shape: {
    borderRadius: 8,
  },
  button: {
    backgroundColor: palette.primary.main,
    "&:visited": {
      backgroundColor: palette.primary.main,
    },
  },
  child: {
    backgroundColor: palette.primary.main,
  },
  rippleVisible: {
    opacity: 0.5,
  },
  shadows: [
    "none",
    "0px 1px 4px 2px rgba(0,0,0,0.05), 0px 2px 1px 0px rgba(0,0,0,0.03)",
    "0px 1px 4px 2px rgba(0,0,0,0.15), 0px 2px 1px 0px rgba(0,0,0,0.13)",
    "0px 1px 2px 2px rgba(0,0,0,0.1), 0px 2px 1px 0px rgba(0,0,0,0.1)",
    "inset 0px 1px 2px 2px rgba(0,0,0,0.1), inset 0px 2px 1px 0px rgba(0,0,0,0.1)",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
  ] as ShadowType,
};

declare module "@mui/material/styles" {
  interface Palette {
    tertiary: Palette["primary"];
    black: Palette["primary"];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    tertiary?: PaletteOptions["primary"];
    black?: PaletteOptions["primary"];
  }
}

// @babel-ignore-comment-in-output Update the Button's color prop options
declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    tertiary: true;
    black: true;
  }
}

const theme: Theme = createTheme(lapeyreOverrides);
export default theme;
