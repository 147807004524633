import styled from "@mui/styled-engine-sc";
import ListItemText from "@mui/material/ListItemText";
import React from "react";

const StyledListItemText = styled(({ ...props }) => <ListItemText {...props} />)`
  ${({ theme }) => `
    margin: 0;
  `}
`;

export { StyledListItemText };
