import styled from "@mui/styled-engine-sc";
import { palette } from "../../themes/color-palette";

const PickUp = styled((props) => (
  <svg
    width="81"
    height="81"
    viewBox="0 0 81 81"
    style={{
      display: "block",
      margin: "0 auto",
    }}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  />
))`
  ${({ theme }) => `
    width:80px;
    height:80px;

    &.product-colors {
      path:first-child {
        fill: ${palette.blue.dark};
        opacity: 0.5;
      }
      path {
        fill: ${palette.blue.dark};
      }
    }
  `}
`;

export const SvgPickUp = (props) => {
  const { className = "" } = props;

  return (
    <PickUp {...{ className }}>
      <path d="M55.1547 26.032L37.5338 14.6376L19.908 26.032V59.426H55.1547V26.032Z" fill="#71ABBB" />
      <path
        d="M77.7037 26.6491C74.4742 17.5412 68.0679 9.91146 59.6569 5.17096C51.3321 0.478305 41.6054 -1.0907 32.2758 0.755751L32.4624 1.69333C41.5671 -0.110071 51.0594 1.42066 59.1881 6.0033C67.3981 10.629 73.6513 18.077 76.7994 26.9648C83.9904 47.2566 73.326 69.6101 53.0353 76.795C32.7399 83.9799 10.3824 73.3221 3.19618 53.0351C0.17244 44.5061 0.2155 35.1351 3.30622 26.6539L2.40676 26.3286C-0.760518 35.0203 -0.803577 44.6209 2.29193 53.3604C8.1002 69.7488 23.5634 80.0047 40.0361 80.0047C44.4569 80.0047 48.9542 79.2633 53.3559 77.7086C74.1489 70.3467 85.0669 47.4431 77.7037 26.6539V26.6491Z"
        fill="#252427"
      />
      <path
        d="M17.5397 27.4766L21.7643 24.707V57.3403H18.6736V57.8187H21.7643V58.0578H22.0035V60.9854H22.482V58.0578H57.2502V60.9854H57.7287V58.0578H57.9679V57.8187H61.2883V57.3403H57.9679V24.6448L63.3216 28.0507L63.58 27.6489L57.9679 24.0803V23.932L57.7287 23.779V20.4353H57.2502V23.468L40.3421 12.5329L42.7965 10.9256L42.5334 10.5238L39.902 12.2458L39.8637 12.2219L39.6006 12.3893L36.2658 10.2655L36.0075 10.6673L39.1556 12.6716L22.4772 23.4585V20.4257H21.9987V23.7694L21.7595 23.9225V24.1282L17.2717 27.07L17.5349 27.4719L17.5397 27.4766ZM22.7212 47.7876H31.0891V57.1059H22.7212V47.7876ZM40.1029 38.0818H48.423V47.3092H40.1029V38.0818ZM39.6245 47.3092H31.5628V38.0818H39.6245V47.3092ZM48.9014 38.0818H57.011V47.3092H48.9014V38.0818ZM48.9014 37.6034V27.4192H57.011V37.6034H48.9014ZM48.423 37.6034H40.1029V27.4192H48.423V37.6034ZM39.6245 37.6034H31.5628V27.4192H39.6245V37.6034ZM31.0843 37.6034H22.7164V27.4192H31.0843V37.6034ZM31.0843 38.0818V47.3092H22.7164V38.0818H31.0843ZM31.5628 47.7876H39.6245V57.1059H31.5628V47.7876ZM40.1029 57.1059V47.7876H48.423V57.1059H40.1029ZM48.9014 47.7876H57.011V57.1059H48.9014V47.7876ZM57.011 26.9409H48.9014V19.2059L57.011 24.4487V26.9409ZM48.423 26.9409H40.1029V13.5183L48.423 18.895V26.9409ZM39.6245 26.9409H31.5628V18.7323L39.6245 13.5183V26.9409ZM31.0843 26.9409H22.7164V24.4534L31.0843 19.0433V26.9409Z"
        fill="#252427"
      />
    </PickUp>
  );
};
