import styled from "@mui/styled-engine-sc";
import { palette } from "../../themes/color-palette";

const Delivery = styled((props) => (
  <svg
    width="81"
    height="80"
    viewBox="0 0 81 80"
    style={{
      display: "block",
      margin: "0 auto",
    }}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  />
))`
  ${({ theme }) => `
    width:80px;
    height:80px;

    &.product-colors {
      path:first-child {
        fill: ${palette.blue.dark};
        opacity: 0.5;
      }
      path {
        fill: ${palette.blue.dark};
      }
    }
  `}
`;

export const SvgDelivery = (props) => {
  const { className = "" } = props;

  return (
    <Delivery {...{ className }}>
      <path d="M43.8333 66.6667L22.1667 55.6718V31.6667L43.8333 42.6664V66.6667Z" fill="#C89C5E" />
      <path
        d="M79.6349 31.7663C77.4646 21.315 71.4114 12.3417 62.5888 6.5062C54.6059 1.22077 45.019 -0.9508 35.5924 0.383712C26.2555 1.70866 17.6971 6.36271 11.4929 13.4897L12.2006 14.121C18.2538 7.16628 26.6094 2.62225 35.7198 1.33078C44.9199 0.0249719 54.2804 2.14871 62.0745 7.30021C70.6896 12.997 76.5965 21.755 78.7149 31.9576C80.8332 42.1601 78.9036 52.5875 73.2845 61.3216C67.6653 70.0557 59.0267 76.0443 48.9632 78.1919C38.8997 80.3396 28.6145 78.3833 19.9995 72.6865C12.5262 67.7407 6.9495 60.2741 4.29327 51.6548L3.39213 51.9418C6.11441 60.7715 11.8279 68.4247 19.4852 73.4901C25.9724 77.7806 33.3891 80.0048 40.9615 80C43.6838 80 46.4297 79.713 49.1614 79.1294C59.4702 76.9292 68.3211 70.7923 74.0771 61.8478C79.833 52.9032 81.8099 42.2175 79.6443 31.7663H79.6349Z"
        fill="#252427"
      />
      <path
        d="M21.179 47.7421L10.5682 42.3706L10.1483 43.2268L21.1837 48.8136V52.8027L17.8669 51.0904L17.6546 51.5161L21.1884 53.3385V53.592L21.4243 53.7163V58.5043H21.8961V53.9603L42.2967 64.512V64.9999L42.5326 64.8755V68.1807H43.0044V64.8755L43.2403 64.9999V64.512L63.6409 53.9603V58.3895H64.1127V53.7163L64.3486 53.592V53.0706L68.2409 51.0473L68.0239 50.6216L64.3439 52.5301V30.3696L68.1041 32.3115L68.3164 31.8858L64.3439 29.8338V29.0829L64.108 29.2072V26.1603H63.6362V29.2072L43.5989 18.8468L47.0147 17.0723L46.7977 16.6466L43.0752 18.579L42.7591 18.4164L42.4948 18.5551L38.5317 16.5079L38.3194 16.9336L41.9806 18.8229L21.8914 29.212V26.2799H21.4196V29.212L21.1837 29.0877V29.9534L17.7254 31.7519L17.9424 32.1776L21.1884 30.4939V34.0718L9.95486 28.3845L9.53495 29.2407L21.1931 35.1432V40.883L0.919901 30.6135L0.5 31.4697L21.179 41.9401V47.7421ZM22.1226 41.347V35.6072L24.3494 36.736L24.7693 35.8798L22.1178 34.5405V30.647L42.2825 41.0744V63.4358L22.1178 53.0084V49.2919L24.4344 50.4638L24.8543 49.6076L22.1178 48.2204V42.4136L24.3589 43.5473L24.7788 42.6911L22.1178 41.3422L22.1226 41.347ZM43.2309 41.0744L51.242 36.9321V45.1401L56.2195 42.6145V34.3587L63.3955 30.647V53.0084L43.2309 63.4358V41.0744ZM55.7477 34.6027V42.318L51.7138 44.3652V36.6882L55.7477 34.6027ZM55.776 33.5121L35.8472 23.0608L42.7591 19.4878L62.8294 29.8673L55.776 33.5169V33.5121ZM55.257 33.78L51.2562 35.8463L31.2991 25.4094L35.3282 23.3239L55.257 33.7752V33.78ZM30.7801 25.6772L50.7372 36.1142L42.9902 40.1225V36.6355H42.5184V40.1225L22.684 29.8673L30.7801 25.682V25.6772Z"
        fill="#252427"
      />
    </Delivery>
  );
};
