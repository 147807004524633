import HTMLReactParser from "html-react-parser";
import { MONTHS, STORE_SCHEDULES, WEEKDAYS } from "../constants/common";
import { KILOMETERS } from "../_foundation/constants/common";
import { getDistance } from "geolib";
import formsUtil from "./formsUtil";
import storeUtil from "./storeUtil";

enum LABEL {
  OPEN = "Ouvert",
  CLOSE = "Fermé.",
  UNTIL = "jusqu'à",
  OPENING = "Ouvre à",
}

/**
 * Get Store Schedule
 * @param storeSchedule {"id_magasin":"27","horaires":[{"jour":"2","ouverture":"09:30","fermeture":"19:00","fermetureAM":"12:00","ouverturePM":"14:00"},{"jour":"3","ouverture":"09:30","fermeture":"19:00","fermetureAM":"12:00","ouverturePM":"14:00"},{"jour":"4","ouverture":"09:30","fermeture":"19:00","fermetureAM":"12:00","ouverturePM":"14:00"},{"jour":"5","ouverture":"09:30","fermeture":"19:00","fermetureAM":"12:00","ouverturePM":"14:00"},{"jour":"1","ouverture":"09:30","fermeture":"19:00","fermetureAM":"12:00","ouverturePM":"14:00"}]}
 * @returns string Ouvert du lundi au samedi, 9:30-12:00 et 14:00-18:30
 */
export const getStoreSchedule = (storeSchedule: any | null) => {
  if (storeSchedule?.horaires) {
    const days = storeSchedule.horaires.map((d) => Number(d.jour)).sort((a, b) => (a < b ? -1 : 1));
    if (days && days.length > 0) {
      if (storeSchedule.horaires[0].fermetureAM && storeSchedule.horaires[0].ouverturePM) {
        return `Ouvert du ${WEEKDAYS[days[0]]} au ${WEEKDAYS[days[days.length - 1]]}, ${
          storeSchedule.horaires[0].ouverture
        }-${storeSchedule.horaires[0].fermetureAM} et ${storeSchedule.horaires[0].ouverturePM}-${
          storeSchedule.horaires[0].fermeture
        }`;
      } else {
        return `Ouvert du ${WEEKDAYS[days[0]]} au ${WEEKDAYS[days[days.length - 1]]}, ${
          storeSchedule.horaires[0].ouverture
        } à ${storeSchedule.horaires[0].fermeture}`;
      }
    }
  }
  return null;
};

/**
 * Get Store Exceptional Opening
 * @param storeSchedule {\"id_magasin\":\"27\",\"horaires\":[{\"date\":\"2023-05-08\",\"ouverture\":\"09:30\",\"fermeture\":\"18:00\",\"fermetureAM\":\"12:00\",\"ouverturePM\":\"14:00\"},{\"date\":\"2023-05-29\",\"ouverture\":\"09:30\",\"fermeture\":\"18:00\",\"fermetureAM\":\"12:00\",\"ouverturePM\":\"14:00\"},{\"date\":\"2023-11-11\",\"ouverture\":\"09:30\",\"fermeture\":\"18:00\",\"fermetureAM\":\"12:00\",\"ouverturePM\":\"14:00\"}]}
 * @returns string Ouverture exceptionnelle le 14 août
 */
export const getExceptionalOpening = (storeExceptionalOpening: any | null) => {
  if (storeExceptionalOpening?.horaires) {
    const today = new Date().getTime();
    //const today = new Date("2023-04-08").getTime();
    const dates = storeExceptionalOpening.horaires
      .filter((h) => new Date(h.date).getTime() >= today)
      .map((h) => h.date)
      .sort((a, b) => (a < b ? -1 : 1));
    if (dates && dates.length > 0) {
      const nextDate = new Date(dates[0]);
      return HTMLReactParser(`<br />Ouverture exceptionnelle le ${nextDate.getDate()} ${MONTHS[nextDate.getMonth()]}`);
    }
  }
  return null;
};

/**
 * Get Store Exceptional Closing
 * @param storeSchedule {\"id_magasin\":\"27\",\"dates\":[{\"date\":\"2023-05-08\"},{\"date\":\"2023-05-29\"},{\"date\":\"2023-11-11\"}]}
 * @returns string Fermeture exceptionnelle le 14 août
 */
export const getExceptionalClosing = (storeExceptionalClosing: any | null) => {
  if (storeExceptionalClosing?.dates) {
    const today = new Date().getTime();
    //const today = new Date("2023-04-08").getTime();
    const dates = storeExceptionalClosing.dates
      .filter((d) => new Date(d.date).getTime() >= today)
      .map((d) => d.date)
      .sort((a, b) => (a < b ? -1 : 1));
    if (dates && dates.length > 0) {
      const nextDate = new Date(dates[0]);
      return HTMLReactParser(`<br />Fermeture exceptionnelle le ${nextDate.getDate()} ${MONTHS[nextDate.getMonth()]}`);
    }
  }
  return null;
};

/**
 * Get Store Exceptional Day CLOSE OR OPEN
 * @param storeSchedule
 */
const getExceptionalDay = (storeSchedule) => {
  if (storeSchedule?.horaires) {
    const dates = storeSchedule.horaires.filter((h) => formsUtil.isToday(new Date(h.date)));
    return dates;
  } else if (storeSchedule?.dates) {
    const dates = storeSchedule.dates.filter((h) => formsUtil.isToday(new Date(h.date)));
    return dates;
  }
  return null;
};

/**
 * Get Store Opening
 * @param storeSchedule {"id_magasin":"27","horaires":[{"jour":"2","ouverture":"09:30","fermeture":"19:00","fermetureAM":"12:00","ouverturePM":"14:00"},{"jour":"3","ouverture":"09:30","fermeture":"19:00","fermetureAM":"12:00","ouverturePM":"14:00"},{"jour":"4","ouverture":"09:30","fermeture":"19:00","fermetureAM":"12:00","ouverturePM":"14:00"},{"jour":"5","ouverture":"09:30","fermeture":"19:00","fermetureAM":"12:00","ouverturePM":"14:00"},{"jour":"1","ouverture":"09:30","fermeture":"19:00","fermetureAM":"12:00","ouverturePM":"14:00"}]}
 */
export const getOpening = (
  storeSchedule: any | null,
  storeExceptionalOpening: any | null = null,
  storeExceptionalClosing: any | null = null
) => {
  let open: boolean = false;
  let label = "";
  let nextState = "";
  if (storeSchedule) {
    //const today = new Date(2024-01-20T19:27:59.043);
    const today = new Date();
    const day = today.getDay();
    const currHours = `${today.getHours().toString().padStart(2, "0")}:${today
      .getMinutes()
      .toString()
      .padStart(2, "0")}:00`; // example: "14:27:00"

    /**
     * NOTE
     * jour === 0 is monday
     * today.getDay() === 1 is monday
     */
    let todayOpening = storeSchedule.horaires?.filter((d) => +d.jour + 1 === day);

    //CHECK Exceptional OPEN
    const todayExOpening = getExceptionalDay(storeExceptionalOpening);
    if (todayExOpening && todayExOpening.length > 0) {
      todayOpening = todayExOpening;
    }

    //CHECK Exceptional CLOSE
    const todayExClosing = getExceptionalDay(storeExceptionalClosing);

    if (todayExClosing && todayExClosing.length > 0) {
      return findNextOpenDay(storeSchedule.horaires, today, "23:59:59");
    }

    if (todayOpening?.length > 0) {
      const storeOpenTime = todayOpening[0].ouverture !== "" ? `${todayOpening[0].ouverture}:00` : null;
      const storeCloseTime = todayOpening[0].fermeture !== "" ? `${todayOpening[0].fermeture}:00` : null;
      const storeBreakStartTime = todayOpening[0].fermetureAM !== "" ? `${todayOpening[0].fermetureAM}:00` : null;
      const storeBreakEndTime = todayOpening[0].ouverturePM !== "" ? `${todayOpening[0].ouverturePM}:00` : null;

      //CHECK HAS NOT HOURS return null
      if (!storeOpenTime || !storeCloseTime) {
        return null;
      }
      //CHECK OPEN AM AND PM + don't close
      if (!storeBreakStartTime && !storeBreakEndTime && storeOpenTime <= currHours && currHours < storeCloseTime) {
        open = true;
        label = LABEL.OPEN;
        nextState = `${LABEL.UNTIL} ${todayOpening[0].fermeture?.replace(":00", ":").split(":").join("h")}`;
        return { open, label, nextState };
      }
      //CHECK OPEN AM
      if (storeBreakStartTime && storeOpenTime <= currHours && currHours < storeBreakStartTime) {
        open = true;
        label = LABEL.OPEN;
        nextState = `${LABEL.UNTIL} ${todayOpening[0].fermetureAM?.replace(":00", ":").split(":").join("h")}`;
        return { open, label, nextState };
      }
      //CHECK OPEN PM
      if (storeBreakEndTime && storeBreakEndTime <= currHours && currHours < storeCloseTime) {
        open = true;
        label = LABEL.OPEN;
        nextState = `${LABEL.UNTIL} ${todayOpening[0].fermeture?.replace(":00", ":").split(":").join("h")}`;
        return { open, label, nextState };
      }
      //CHECK CLOSE NEXT OPEN PM
      if (
        storeBreakStartTime &&
        storeBreakEndTime &&
        currHours > storeBreakStartTime &&
        storeBreakEndTime > currHours
      ) {
        open = false;
        label = LABEL.CLOSE;
        nextState = `${LABEL.OPENING} ${todayOpening[0].ouverturePM?.replace(":00", ":").split(":").join("h")}`;
        return { open, label, nextState };
      }
      //CHECK CLOSE NEXT OPEN AM
      if (currHours < storeOpenTime) {
        open = false;
        label = LABEL.CLOSE;
        nextState = `${LABEL.OPENING} ${todayOpening[0].ouverture?.replace(":00", ":").split(":").join("h")}`;
        return { open, label, nextState };
      }
      //CHECK CLOSE NEXT DAY
      return findNextOpenDay(storeSchedule.horaires, today, currHours);
    } else {
      //IF NOT DATA DAY CLOSE AND CHECK OPEN NEXT DAY
      return findNextOpenDay(storeSchedule.horaires, today, currHours);
    }
  }
  return null;
};

function findNextOpenDay(hours, today, hourly) {
  let nextState = "";
  let day = today.getDay();
  day = day === 0 ? 6 : day - 1; // We are offsetting because jour === 0 is monday but getDay() === 0 is sunday, so now day === 6 means sunday
  const days = hours?.map((d) => Number(d.jour)).sort((a, b) => (a < b ? -1 : 1));

  if (days?.length > 0) {
    const index = days.indexOf(day);

    //Get next index Day opening
    const nextIndexDay = index === -1 ? 0 : (index + 1) % 6; // days only contain [0, 1, 2, 3, 4, 5] but sunday is 6 so indexOf will return -1 for sunday

    let addDay = 7 - day + days[nextIndexDay];
    addDay = addDay > 7 ? addDay - 7 : addDay;

    //Set new Date openning to display
    const nextDate = new Date(today);
    nextDate.setDate(today.getDate() + addDay);

    let nextDay = nextDate.getDay();
    nextDay = nextDay === 0 ? 6 : nextDay - 1;

    //Get data filter next Day opening
    const nextOpening = hours?.filter((d) => d.jour === days[nextIndexDay]?.toString());
    const openAM = `${nextOpening[0].ouverture}:00`;

    nextState = `Ouvre le ${WEEKDAYS[nextDay]} ${nextDate.getDate()} ${MONTHS[nextDate.getMonth()]} à ${
      openAM > hourly
        ? nextOpening[0]?.ouverturePM?.replace(":00", ":").split(":").join("h")
        : nextOpening[0]?.ouverture?.replace(":00", ":").split(":").join("h")
    }`;
  }

  return { open: false, label: LABEL.CLOSE, nextState };
}

export const getDateMonth = (dateString, year = false, format: null | string = null) => {
  if (dateString && dateString !== "") {
    try {
      if (format === "DD/MM/YYYY") {
        const dateParts = dateString.split("/");
        const date = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
        return `${date.getDate()} ${MONTHS[date.getMonth()]}${year ? ` ${date.getFullYear()}` : ""}`;
      } else if (format === "DD-MM-YYYY") {
        const dateParts = dateString.split("-");
        const date = new Date(+dateParts[2], +dateParts[1] - 1, +dateParts[0]);
        return `${date.getDate()} ${MONTHS[date.getMonth()]}${year ? ` ${date.getFullYear()}` : ""}`;
      }
      const date = new Date(dateString);
      return `${date.getDate()} ${MONTHS[date.getMonth()]}${year ? ` ${date.getFullYear()}` : ""}`;
    } catch {
      return null;
    }
  }
  return null;
};

export const currentDate = () => {
  let today: any = new Date();
  let dd: any = today.getDate();
  let mm: any = today.getMonth() + 1;

  const yyyy = today.getFullYear();

  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  today = dd + "/" + mm + "/" + yyyy;

  today = getMonthDate(today);

  return today;
};

export function getWeekdayIndexFromDate(dateString: string) {
  const dateParts = dateString.split("/");

  const day = parseInt(dateParts[0], 10);
  const month = parseInt(dateParts[1], 10) - 1; // JavaScript months are 0-indexed
  const year = parseInt(dateParts[2], 10);

  const date = new Date(year, month, day);

  // Get day of week (0-6 where 0 is Sunday)
  let weekdayIndex = date.getDay();

  // Convert from Sunday=0 to Monday=0 format
  // New mapping: Sunday:0→6, Monday:1→0, Tuesday:2→1, etc.
  weekdayIndex = weekdayIndex === 0 ? 6 : weekdayIndex - 1;
  return weekdayIndex;
}

export const getMonthDate = (dateString, year = true) => {
  if (dateString && dateString !== "") {
    try {
      let date;
      if (dateString.includes("/")) {
        // Old format: DD/MM/YYYY
        const [day, month, yearPart] = dateString.split("/");
        date = new Date(`${month}/${day}/${yearPart}`);
      } else {
        // New format: YYYY-MM-DD HH:mm:ss.SSS
        date = new Date(dateString);
      }

      if (isNaN(date.getTime())) {
        throw new Error("Invalid date");
      }

      return `${date.getDate()} ${MONTHS[date.getMonth()]}${year ? ` ${date.getFullYear()}` : ""}`;
    } catch (error) {
      console.error("Error parsing date:", error);
      return null;
    }
  }
  return null;
};

export function isDateLessOrEqualToToday(dateStr: string): boolean {
  if (!dateStr) {
    return false;
  }
  const [day, month, year] = dateStr.split("/").map(Number);
  const inputDate = new Date(year, month - 1, day);
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return inputDate <= today;
}

export const transformStore = (store, location = null) => {
  const coordinates = { lng: Number(store?.longitude?.trim()), lat: Number(store?.latitude?.trim()) };

  //STORE_SCHEDULE
  const storeHours = store.Attribute?.filter((store) => store.name === STORE_SCHEDULES.STORE_SCHEDULE);
  const storeSchedule =
    storeHours.length > 0 && storeHours[0]?.displayValue ? JSON.parse(storeHours[0].displayValue) : null;
  //STORE_EXCEPTIONAL_OPENING
  const storeExceptOpening = store.Attribute?.filter(
    (store) => store.name === STORE_SCHEDULES.STORE_EXCEPTIONAL_OPENING
  );
  const storeExceptionalOpening =
    storeExceptOpening.length > 0 && storeExceptOpening[0]?.displayValue
      ? JSON.parse(storeExceptOpening[0].displayValue)
      : null;
  //STORE_EXCEPTIONAL_CLOSING
  const storeExceptClosing = store.Attribute?.filter(
    (store) => store.name === STORE_SCHEDULES.STORE_EXCEPTIONAL_CLOSING
  );
  const storeExceptionalClosing =
    storeExceptClosing.length > 0 && storeExceptClosing[0]?.displayValue
      ? JSON.parse(storeExceptClosing[0].displayValue)
      : null;
  //Distance
  let distance =
    coordinates && location
      ? `${getDistance(location, coordinates, 100) / 1000}`
      : store.distance
      ? `${storeUtil.parseFloatDecimal(store?.distance)}`
      : "";
  if (distance && !distance.includes("km")) {
    distance += ` ${KILOMETERS.toLocaleLowerCase()}`;
  }

  return {
    ...store,
    coordinates,
    distance,
    name: store.Description[0].displayStoreName,
    address: store.addressLine ? store.addressLine[0] : "",
    city: store.city || "",
    phone: store.telephone1.trim(),
    id: store.uniqueID,
    storeSchedule,
    storeExceptionalOpening,
    storeExceptionalClosing,
  };
};

export const transformStoresJson = (stores, location = null) => {
  if (stores && stores.length > 0) {
    return stores.map((store) => transformStore(store, location));
  }
  return null;
};
