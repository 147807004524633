/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
import React from "react";
import styled from "@mui/styled-engine-sc";
import CheckIcon from "@mui/icons-material/Check";
// import { palette } from "../../themes/color-palette";

const StyledSwatchButton = styled("button")`
  ${({ theme }) => `
    padding: 0;
    display: inline-block;
    width: ${theme.spacing(3)};
    height: ${theme.spacing(3)};
    margin-right: ${theme.spacing(1)};
    margin-bottom: ${theme.spacing(1)};
    border-radius: ${theme.spacing(1)};
    border: 1px solid ${theme.palette.white.main};
    padding: 1px;
    overflow: hidden;
    background-repeat: repeat;
    cursor: pointer;
    outline: 1px solid ${theme.palette.gray.neutralGray300};
    box-shadow: 0px 1px 1px 0px rgba(37, 36, 39, 0.25) inset;
    transition: outline-color 100ms ease-in;

    &.rounded {
      border-radius: 100%;
      width: ${theme.spacing(4.5)};
      height: ${theme.spacing(4.5)};
      margin-bottom: ${theme.spacing(2)};

      &.selected {
        svg {
          display: none;
        }
      }

      &.no-margin-bottom {
        margin-bottom: 0;
      }

      &.no-border {
        border: 0;
        outline: 0;
        box-shadow: none;
      }
    }

    &.rounded-small {
      border-radius: 100%;
      width: ${theme.spacing(2.5)};
      height: ${theme.spacing(2.5)};
      margin-right: 0;
      margin-bottom: 0;
      border: none;
      outline: none;
      box-shadow: none;
      pointer-events: none;
      background-size: cover;
      position: relative;

      &:before {
        content: " ";
        width: 100%;
        height: 100%;
        box-shadow: rgb(213 213 213) 0px 0px 0px 1px inset;
        z-index: 99999;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 50%;
        mix-blend-mode: multiply;
      }

      &.selected {
        svg {
          display: none;
        }
      }
    }

    &.product-swatch-medium,
    &.medium {
      width: ${theme.spacing(6)};
      height: ${theme.spacing(6)};
    }

    &.big {
      width: ${theme.spacing(11)};
      height: ${theme.spacing(11)};
    }

    &.customization-panel-swatch {
      width: ${theme.spacing(8)};
      height: ${theme.spacing(8)};
      margin-right: ${theme.spacing(3)};
      margin-bottom: 0;
      overflow: visible;

      &.selected {
        &:before {
          content: "";
          background: ${theme.palette.secondary.dark};
          position: absolute;
          top: -9px;
          left: -1px;
          width: 32px;
          height: 2px;
          border-radius: 0px 0px 4px 4px ;
        }
      }
    }

    &.selected {
      outline-color: ${theme.palette.secondary.main};
      position: relative;

      svg {
        color: white;
        font-size: ${theme.spacing(2)};
      }

      &.product-swatch-medium,
      &.medium {
        svg {
          display: none; // Hide for colors
          font-size: ${theme.spacing(2.5)};
        }
      }
      &.big {
        svg {
          width: ${theme.spacing(6)};
          height: ${theme.spacing(6)};
          background: ${theme.palette.secondary.main};
          padding: ${theme.spacing(1)};
          border-radius: 100%;
          font-size: ${theme.spacing(4)};
        }
      }
    }

    &.disabled {
      position: relative;
      outline: none;
      &:after {
        content: '';
        position: absolute;
        left: 5px;
        width: 32px;
        height: 2px;
        background: ${theme.palette.primary.main};
        transform: rotate(135deg);
      }
    }

    &:last-child {
      margin-right: 0;
    }
  `}
`;

function StyledSwatch(props: {
  className?: string;
  selected?: boolean;
  disabled?: boolean;
  size?: string;

  [extraPrps: string]: any;
}) {
  const { className, selected, disabled, size } = props;
  const classList = `${className ? className : ""} ${selected ? "selected" : ""} ${disabled ? "disabled" : ""} ${
    size ? size : ""
  }`;
  return (
    <StyledSwatchButton {...props} className={classList}>
      {selected && <CheckIcon className="full-center" />}
    </StyledSwatchButton>
  );
}

export { StyledSwatch };
