/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Services - Account and Contract
 * These services provide APIs to manage accounts and contracts.  a contract is an agreement that represents the terms and conditions that apply to a transaction. An account is a relationship between the merchant and the financial institution that processes transactions for that merchant.
 *
 * The version of the OpenAPI document: 9.1.6
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Commentaires } from './commentaires';
import { Criteres } from './criteres';
import { InfoFournisseur } from './info-fournisseur';

/**
 * 
 * @export
 * @interface OrderItem
 */
export interface OrderItem {
    /**
     * 
     * @type {string}
     * @memberof OrderItem
     */
    categorieArticle?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderItem
     */
    codeArticle?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderItem
     */
    codeCategorieArticle?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderItem
     */
    codeFamille?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderItem
     */
    codeGrandeFamille?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderItem
     */
    codePromotion?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderItem
     */
    codeSousFamille?: string;
    /**
     * 
     * @type {Commentaires}
     * @memberof OrderItem
     */
    commentaires?: Commentaires;
    /**
     * 
     * @type {boolean}
     * @memberof OrderItem
     */
    cpv?: boolean;
    /**
     * 
     * @type {Array<Criteres>}
     * @memberof OrderItem
     */
    criteres?: Array<Criteres>;
    /**
     * 
     * @type {string}
     * @memberof OrderItem
     */
    dateCreationDocument?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderItem
     */
    dateDisponibiliteReelle?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderItem
     */
    dateMADMagasin?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderItem
     */
    dateModTech?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderItem
     */
    documentId?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderItem
     */
    ecoPartHT?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderItem
     */
    ecoPartTTC?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderItem
     */
    famille?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderItem
     */
    grandeFamille?: string;
    /**
     * 
     * @type {InfoFournisseur}
     * @memberof OrderItem
     */
    infoFournisseur?: InfoFournisseur;
    /**
     * 
     * @type {string}
     * @memberof OrderItem
     */
    infoStatutLigne?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderItem
     */
    libelleArticle?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderItem
     */
    libellePromotion?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderItem
     */
    magasinOrigine?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderItem
     */
    modeADV?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderItem
     */
    montantNetHT?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderItem
     */
    montantNetTTC?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderItem
     */
    montantPromoHT?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderItem
     */
    montantPromoTTC?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderItem
     */
    nomArticle?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderItem
     */
    nombreIterationRetard?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderItem
     */
    numeroCommandeVente?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderItem
     */
    numeroDevis?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderItem
     */
    numeroLigne?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderItem
     */
    numeroLigneParent?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderItem
     */
    numeroPosteCommandeVente?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderItem
     */
    origineADV?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderItem
     */
    parentLine?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderItem
     */
    prixUnitAchatHT?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderItem
     */
    prixUnitCatHT?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderItem
     */
    prixUnitCatTTC?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderItem
     */
    prixUnitNetHT?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderItem
     */
    prixUnitNetTTC?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderItem
     */
    prixUnitVenteHT?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderItem
     */
    prixUnitVenteTTC?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderItem
     */
    quantite?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderItem
     */
    remiseTTC?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderItem
     */
    sourceconfigurateur?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderItem
     */
    sousFamille?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderItem
     */
    statutFacturation?: OrderItemStatutFacturationEnum;
    /**
     * 
     * @type {number}
     * @memberof OrderItem
     */
    tauxPromotion?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderItem
     */
    tauxTVA?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderItem
     */
    tva?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderItem
     */
    typeArticle?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderItem
     */
    typePromo?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderItem
     */
    unite?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderItem
     */
    version?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum OrderItemStatutFacturationEnum {
    Order = 'ORDER',
    Fact = 'FACT'
}



