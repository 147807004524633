import React, { useState } from "react";
import { get } from "lodash-es";
import styled from "@mui/styled-engine-sc";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";

function ToggleGroupWrapper({
  id,
  name,
  size,
  onChangeHandler,
  values,
  useSwatches,
  className,
  productDetailsAny,
  skusAsAttrs,
  isB2B,
  currentSelection,
  ...props
}: any) {
  let defaultSelected = "";
  const [value, setValue] = useState(defaultSelected);

  const currentSelectedAttributes: any = get(currentSelection, "selectedAttributes", {});
  defaultSelected = currentSelectedAttributes[id] ?? "";
  if (defaultSelected) {
    defaultSelected = Array.isArray(defaultSelected) ? currentSelectedAttributes[id][0] : currentSelectedAttributes[id];
  }

  const handleChange = (event: any) => {
    setValue(event.target.value);
    onChangeHandler(id, event.target.value);
  };

  return (
    <ToggleButtonGroup
      {...props}
      exclusive
      onChange={handleChange}
      aria-label={name}
      name={name}
      className={className}
      value={value}>
      {values?.map((item, index) => {
        return (
          <StyledToggleButton
            key={index}
            value={item.value}
            aria-label={item.value}
            className={`bottom-margin-2 product-information-toggle-button`}>
            {item.value}
          </StyledToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
}

const StyledToggleButtonGroup = styled(ToggleGroupWrapper)`
  ${({ theme }) => `
 `}
`;

const ComponentWrapper = (props: any) => <ToggleButton {...props} />;

const StyledToggleButton = styled(ComponentWrapper)`
  ${({ theme }) => `
    padding: ${theme.spacing(1)} ${theme.spacing(3)};
    border-radius: ${theme.spacing(1)};
    margin-right: ${theme.spacing(2)};
    border: ${theme.palette.gray.neutralGray300} 1px solid;
    font-weight: 400;
    line-height: 18px;


    ${theme.breakpoints.down("sm")} {
      padding: ${theme.spacing(2)} ${theme.spacing(3)};
    }

    &.Mui-selected {
      color: ${theme.palette.white.main};
      background: ${theme.palette.secondary.main};
      -webkit-text-stroke-width: 1px;

      &:hover {
        background: ${theme.palette.secondary.main};
      }
    }
    &.Mui-disabled {
        background: ${theme.palette.gray.neutralGray100};
        color: ${theme.palette.gray.neutralGray500};
        border: 1px solid ${theme.palette.gray.neutralGray300};
    }

    &.MuiToggleButtonGroup-grouped:not(:first-of-type) {
      margin-left: 0;
      border-left: 1px solid ${theme.palette.gray.neutralGray300};
      border-top-left-radius: ${theme.spacing(1)};
      border-bottom-left-radius: ${theme.spacing(1)};
    }

    &.MuiToggleButtonGroup-grouped:not(:last-of-type) {
        border-top-right-radius: ${theme.spacing(1)};
        border-bottom-right-radius: ${theme.spacing(1)};
    }

  `}
`;

export { StyledToggleButton, StyledToggleButtonGroup };
