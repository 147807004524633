import { TFunction } from "react-i18next";
import { REG_EX, SLASH, EMPTY_STRING, STRING_TRUE, OFFER, CS, MP_ENABLED, DEFINING } from "../constants/common";
import { INVENTORY_STATUS, SHIPMODE, SOFINCO_LIMIT_MAX, SOFINCO_LIMIT_MIN } from "../constants/order";
import {
  PRODUCT_TYPE,
  ATTR_IDENTIFIER,
  TAGS_OPTIONS,
  USAGE_PICTOGRAMME,
  ATTR_UNIT_OF_MEASURE,
} from "../constants/catalog";
import { site } from "../_foundation/constants/site";
import { getSite } from "../_foundation/hooks/useSite";
import { isAvailableForShipping, isAvailableInStore } from "./orderUtil";
import { getPrices } from "./priceUtil";
// import eSpotService from "../_foundation/apis/transaction/eSpot.service";

/**
 * @param obj
 * @returns obj if it's an array, otherwise a one-elem array with obj
 */
const asArray = (obj: any) => (Array.isArray(obj) ? obj : [obj]);

const storeUtil = {
  isNumeric: (input: string) => {
    const NUMERIC = REG_EX.NUMERIC;
    return NUMERIC.test(input);
  },

  maskCardNumber: (input: string) => {
    const CARD_NUMBER_MASK = REG_EX.CARD_NUMBER_MASK;
    return input.replace(CARD_NUMBER_MASK, "*");
  },

  parseFloatDecimal: (input, decimal = 1) => {
    const FLOAT = REG_EX.FLOAT;
    if (FLOAT.test(input) && !storeUtil.isNumeric(input)) {
      return parseFloat(input).toFixed(decimal);
    }
    return input;
  },

  getUnitOfMeasure: (entry) => {
    switch (entry?.extendedData?.unitID) {
      case ATTR_UNIT_OF_MEASURE.CMT:
        return "cm";
      case ATTR_UNIT_OF_MEASURE.MMT:
        return "mm";
      default:
        return "";
    }
  },

  getUnivers: (topCategoriesList: any[]) => {
    return topCategoriesList.filter((item) => item?.parentCatalogGroupID?.toString().replace("/", "") === item.id);
  },

  getParentCategoryId: (parentCatalogGroupID: any, topCategoriesList: any[]): string => {
    let categoryIdentifier: string = EMPTY_STRING;
    const categoryByIdMap = storeUtil.toMap(topCategoriesList, "id");
    const parentCategories = Array.isArray(parentCatalogGroupID) ? parentCatalogGroupID : [parentCatalogGroupID];

    let ids: string[] = [];
    if (parentCategories.length > 0) {
      for (let i = 0; i < parentCategories.length; i++) {
        const categoryIds = parentCategories[i].split(SLASH).filter(Boolean);
        if (storeUtil.isValidCategory(categoryIds, categoryByIdMap)) {
          ids = categoryIds;
          // break;
        }
      }
    }
    if (ids && ids.length > 0) {
      categoryIdentifier = ids[ids.length - 1];
    }
    return categoryIdentifier;
  },

  getDocumentArticles: (items): any[] => {
    const articles: any[] = [];
    items?.forEach((item, i) => {
      const type = item.criteres.find((crit) => crit.codeCritere === "ART_TYPE");
      if (type.valeurCritere === "ORD" || type.valeurCritere === "CPV" || type.valeurCritere === "STA") {
        articles.push(item);
      }
    });
    return articles;
  },

  getCategoryBreadcrumbs: (parentCatalogGroupID: any, topCategoriesList: any[]): any[] | null => {
    const categoryByIdMap =
      topCategoriesList?.length > 0 && topCategoriesList[0].id
        ? storeUtil.toMap(topCategoriesList, "id")
        : storeUtil.toMap(topCategoriesList, "value");
    const parentCategories = Array.isArray(parentCatalogGroupID) ? parentCatalogGroupID : [parentCatalogGroupID];

    let ids: string[] = [];
    if (parentCategories.length > 0) {
      for (let i = 0; i < parentCategories.length; i++) {
        const categoryIds = parentCategories[i].split(SLASH).filter(Boolean);
        if (storeUtil.isValidCategory(categoryIds, categoryByIdMap)) {
          ids = categoryIds;
          break;
        }
      }
    }
    if (ids.length > 0) {
      return ids.map((id) => {
        return {
          label: categoryByIdMap[id].name || categoryByIdMap[id].label,
          value: id,
          seo: categoryByIdMap[id].seo,
        };
      });
    } else return null;
  },

  toMap: (a, k?) => {
    return a?.reduce((m, v) => {
      m[k ? v[k] : v] = v;
      return m;
    }, {});
  },

  isValidCategory: (categoryIds: any, categoryByIdMap: any): boolean => {
    let isValid: boolean = false;
    if (categoryIds && categoryByIdMap) {
      isValid = categoryIds.every(
        (id) =>
          categoryByIdMap[id] &&
          categoryByIdMap[id]?.UserData &&
          (!categoryByIdMap[id]?.UserData[0]?.isrbsc || categoryByIdMap[id]?.UserData[0]?.isrbsc !== "true")
      );
    }
    return isValid;
  },

  getAttributes: (attributes) => {
    if (attributes) {
      const tabAttributes = Array.isArray(attributes) ? attributes : Object.entries(attributes);
      const length = tabAttributes.length;
      return tabAttributes?.map((a, index) => {
        const id = a.identifier ? a.identifier : a[0];
        const value = a.identifier ? a.values[0]?.value : a[1];
        if (id.indexOf(ATTR_IDENTIFIER.LARGEUR) > -1 || id.indexOf(ATTR_IDENTIFIER.LONGUEUR) > -1) {
          return `L. ${storeUtil.parseFloatDecimal(value)} ${length - 1 !== index ? "cm | " : "cm"}`;
        } else if (id.indexOf(ATTR_IDENTIFIER.HAUTEUR) > -1) {
          return `H. ${storeUtil.parseFloatDecimal(value)} ${length - 1 !== index ? "cm | " : "cm"}`;
        } else if (id.indexOf(ATTR_IDENTIFIER.PROFONDEUR) > -1) {
          return `P. ${storeUtil.parseFloatDecimal(value)} ${length - 1 !== index ? "cm | " : "cm"}`;
        } else {
          return `${storeUtil.parseFloatDecimal(value)} ${length - 1 !== index ? " | " : ""}`;
        }
      });
    }
    return "";
  },

  getAttributesProduct: (product, usage: any = DEFINING) => {
    if (usage) {
      const attrUsage = product.attributes?.filter(({ usage: u }) => u === usage);
      return storeUtil.getAttributes(attrUsage);
    } else {
      return "";
    }
  },

  getRemisedProductDetail: (product) => {
    const remisedPriceObj = { oldPrice: "", OldPriceEnd: "" };
    if (product.attributes) {
      for (const att of product.attributes) {
        if (att.identifier === ATTR_IDENTIFIER.OLD_PRICE) {
          remisedPriceObj.oldPrice = att?.values[0]?.value;
        }
        if (att.identifier === ATTR_IDENTIFIER.OLD_PRICE_END) {
          remisedPriceObj.OldPriceEnd = att?.values[0]?.value.replaceAll("-", "/");
        }
      }
    }
    if (product?.["custom.oldPrice.raw"]) {
      remisedPriceObj.oldPrice = product["custom.oldPrice.raw"];
    }
    if (product?.["custom.oldPriceEndDate.raw"]) {
      remisedPriceObj.OldPriceEnd = product["custom.oldPriceEndDate.raw"];
    }
    return remisedPriceObj;
  },

  getOrigneAttributes: (attributes) => {
    if (attributes) {
      const tabAttributes = Array.isArray(attributes) ? attributes : Object.entries(attributes);
      const filter = tabAttributes?.filter((a) => a.identifier?.indexOf(ATTR_IDENTIFIER.ORIGINE) > -1);
      const values = filter.length > 0 ? filter[0].values : [];
      return values.length > 0 && values[0].value !== "0" ? values[0].value : null;
    }
    return null;
  },

  getEnergyAttributes: (attributes) => {
    if (attributes) {
      const tabAttributes = Array.isArray(attributes) ? attributes : Object.entries(attributes);
      const filter = tabAttributes?.filter((a) => a.identifier?.indexOf(ATTR_IDENTIFIER.ENERGY) > -1);
      const values = filter.length > 0 ? filter[0].values : [];
      return values.length > 0 && values[0].value !== "0" ? values[0].value : null;
    }
    return null;
  },

  getGarantyAttributes: (attributes) => {
    if (attributes) {
      const tabAttributes = Array.isArray(attributes) ? attributes : Object.entries(attributes);
      const filter = tabAttributes?.filter((a) => a.identifier?.indexOf(ATTR_IDENTIFIER.GARANTIE) > -1);
      const values = filter.length > 0 ? filter[0].values : [];
      return values.length > 0 && values[0].value !== "0" ? values[0].value : null;
    }
    return null;
  },

  getEcoPartAttributes: (sku) => {
    if (sku && sku?.["ecoPriceData.numeric"]) {
      // const tabAttributes = Array.isArray(attributes) ? attributes : Object.entries(attributes);
      // const filter = tabAttributes?.filter((a) => a.identifier?.indexOf(ATTR_IDENTIFIER.ECO_PART) > -1);
      // const values = filter.length > 0 ? filter[0].values : [];

      return sku?.["ecoPriceData.numeric"];
    }
    return null;
  },

  getUnitPriceForCart: (data) => {
    let unitPrice = data.unitPrice;

    if (data) {
      unitPrice =
        (parseFloat(data?.unitPrice) + parseFloat(data?.xitem_ECOPART_TTC)) * parseFloat(data?.xitem_COEFF_CONVERSION);

      return unitPrice > 0 ? unitPrice.toFixed(2) : null;
    }
    return unitPrice;
  },

  getUnitPrice: (data) => {
    let unitPrice = data.unitPrice;

    if (data) {
      unitPrice = parseFloat(data?.unitPrice) + parseFloat(data?.xitem_ECOPART_TTC);

      return unitPrice > 0 ? unitPrice.toFixed(2) : null;
    }
    return unitPrice;
  },

  getPromotionalPercentage: (data) => {
    let promotionalPercentage = 0;
    if (data && data.orderItemExtendAttribute && data.orderItemExtendAttribute.length > 0) {
      data.orderItemExtendAttribute.forEach((item) => {
        if (item.attributeName === ATTR_IDENTIFIER.ITEM_PERCENTAGE_OFF) {
          promotionalPercentage = item.attributeValue;
        }
      });
    }
    return promotionalPercentage;
  },

  getOrderItemPrice: (data) => {
    let orderItemPrice = data.orderItemPrice;
    let totalAdjustment: any = 0;
    if (data) {
      orderItemPrice = parseFloat(data?.orderItemPrice) + parseFloat(data?.xitem_TOTALECOPART_TTC);

      if (data.adjustment && data.adjustment.length > 0) {
        data.adjustment.forEach((adjustmentItem) => {
          if (adjustmentItem.displayLevel === "OrderItem") {
            totalAdjustment = totalAdjustment + parseFloat(adjustmentItem?.amount);
          }
        });
      }

      if (totalAdjustment && Math.abs(totalAdjustment) > 0) {
        orderItemPrice = orderItemPrice + totalAdjustment;
      }

      return orderItemPrice > 0 ? orderItemPrice.toFixed(2) : null;
    }
    return orderItemPrice;
  },

  getColorAttributes: (attributes) => {
    if (attributes) {
      const tabAttributes = Array.isArray(attributes) ? attributes : Object.entries(attributes);
      const filter = tabAttributes?.filter((a) => a.identifier?.indexOf(ATTR_IDENTIFIER.COULEUR) > -1);
      return filter.length > 0 ? filter[0] : null;
    }
    return null;
  },

  getFinitionValuesAttributes: (attributes) => {
    if (attributes) {
      const tabAttributes = Array.isArray(attributes) ? attributes : Object.entries(attributes);
      const filter = tabAttributes?.filter((a) => a.identifier?.indexOf(ATTR_IDENTIFIER.FINITION) > -1);
      if (filter.length > 0 && filter[0].values.length > 0) {
        return filter[0].values[0].value;
      }
    }
    return null;
  },
  getUrlPicture: (raw: string, folder: any = undefined) => {
    if (!raw || raw === "null") return site.defaultImage;
    if (raw.indexOf("https") === 0) return raw;
    if (raw.indexOf("/wps") === 0) return raw;
    const siteHcl = getSite();
    const path = !folder && folder !== "" ? siteHcl?.assetsFolder : folder;
    return `${siteHcl?.assetsUrl}${path}${raw.indexOf("/") !== 0 ? "/" : ""}${raw}`;
  },
  getTagsProduct: (productInfo, bestSeller = "false") => {
    if (
      productInfo &&
      productInfo["custom.items.attribute.tag.raw"] !== "null" &&
      productInfo["custom.items.attribute.tag.raw"] !== undefined &&
      productInfo["custom.items.attribute.tag.raw"] !== "Best-seller"
    ) {
      const tags = TAGS_OPTIONS.filter(
        (tag) =>
          productInfo["custom.items.attribute.tag.raw"] === tag.description && !tag.deactivationValue && !tag.value
        // const tags = TAGS_OPTIONS.filter((tag) =>
        //   attributes.some(
        //     (p) =>
        //       p.identifier === tag.identifier &&
        //       (!tag.deactivationValue || (tag.deactivationValue && p.values[0]?.value !== tag.deactivationValue)) &&
        //       (!tag.value || (tag.value && p.values[0]?.value === tag.value))
        //   )
      );
      if (bestSeller === "true") {
        tags.push(TAGS_OPTIONS.filter((t) => t.identifier === "BEST-SELLER")[0]);
      }
      return tags.sort((a, b) => a.order - b.order);
    }
    return [];
  },

  getLabelsProduct: (productInfo, siteInfo) => {
    const { userData = {} } = siteInfo?.storeCfg ?? {};
    const badgeLabelData = userData?.BADGES_LEBELS_DATA;
    if (badgeLabelData && badgeLabelData.length > 0) {
      let str = badgeLabelData;
      str = str.replace(/\|\|/g, ",");
      const badgeLabelObj = JSON.parse(str);
      const labelLegalList = badgeLabelObj.LABEL_LEGAL.split(",");
      const lableMktgList = badgeLabelObj.LABEL_MRKT.split(",");

      const lablesList = labelLegalList.concat(lableMktgList);

      if (
        productInfo &&
        productInfo["custom.items.attribute.badges.raw"] &&
        productInfo["custom.items.attribute.badges.raw"] !== "null"
      ) {
        const attributes = productInfo["custom.items.attribute.badges.raw"];
        const attr = JSON.parse(`{ "labels" : [${attributes}]}`);
        const labels = lablesList.map((lable) => {
          const filteredLable = attr.labels.filter(
            (a) =>
              lable == a.ATTR &&
              a.value !== "0" &&
              a.value !== "NON" &&
              a?.IMAGE1?.length > 0 &&
              !a.IMAGE1.includes("/none.png")
          );
          if (filteredLable && filteredLable.length > 0) {
            return filteredLable[0];
          }
        });
        return labels.filter((lab) => lab !== undefined);
      }
    }
    return [];
  },

  getBadgesProduct: (attributes) => {
    if (attributes) {
      const badges = attributes.filter(
        (a) => a.custom?.FIELD3 === USAGE_PICTOGRAMME.BADGE && a.values[0]?.value !== "Non utilisé"
      );
      return badges.sort((a, b) => a.sequence - b.sequence);
    }
    return [];
  },

  getBadgesFpcProduct: (productInfo, siteInfo) => {
    const { userData = {} } = siteInfo?.storeCfg ?? {};
    const badgeLabelData = userData?.BADGES_LEBELS_DATA;
    if (badgeLabelData && badgeLabelData.length > 0) {
      let str = badgeLabelData;
      str = str.replace(/\|\|/g, ",");
      const badgeLabelObj = JSON.parse(str);
      const badgeList = badgeLabelObj.BADGES.split(",");
      if (
        productInfo &&
        productInfo["custom.items.attribute.badges.raw"] &&
        productInfo["custom.items.attribute.badges.raw"] !== "null"
      ) {
        try {
          const attributes = productInfo["custom.items.attribute.badges.raw"];
          const fpc = JSON.parse(`{ "badges" : [${attributes}]}`);
          const badgeslist = badgeList.map((badge) => {
            const filteredBadge = fpc.badges.filter(
              (a) =>
                badge == a.ATTR && a.value !== "Non utilisé" && a?.IMAGE1?.length > 0 && !a.IMAGE1.includes("/none.png")
            );
            if (filteredBadge && filteredBadge.length > 0) {
              return filteredBadge[0];
            }
          });
          return badgeslist.filter((bagelist) => bagelist !== undefined);
        } catch {
          return [];
        }
      }
    }
    return [];
  },

  getAvisProduct: (product) => {
    const avis = { count: 0, note: 0 };
    if (product?.parentAttributes) {
      for (const att of product.parentAttributes) {
        if (att.identifier === ATTR_IDENTIFIER.AVIS_NB) {
          avis.count = att?.values[0]?.value;
        }
        if (att.identifier === ATTR_IDENTIFIER.AVIS_NOTE) {
          const note = Array.isArray(att?.values[0]?.value) ? att?.values[0]?.value[0] : att?.values[0]?.value;
          avis.note = +parseFloat(note).toFixed(1);
        }
      }
    } else if (product?.attributes) {
      for (const att of product.attributes) {
        if (att.identifier === ATTR_IDENTIFIER.AVIS_NB) {
          avis.count = att?.values[0]?.value;
        }
        if (att.identifier === ATTR_IDENTIFIER.AVIS_NOTE) {
          const note = Array.isArray(att?.values[0]?.value) ? att?.values[0]?.value[0] : att?.values[0]?.value;
          avis.note = +parseFloat(note).toFixed(1);
        }
      }
    }
    // if (product["avNote.numeric"]) {
    //   avis.note = +parseFloat(product["avNote.numeric"]).toFixed(1);
    // }
    return avis;
  },

  getIsKitFurniture: (attributes) => {
    if (attributes) {
      for (const att of attributes) {
        if (att.identifier === ATTR_IDENTIFIER.FURNITURE_KIT) {
          return att?.values[0]?.value === "1.0" || att?.values[0]?.value === "1";
        }
      }
    }
    return false;
  },

  checkHasConfigurator: (attributes) => {
    if (attributes) {
      const lienConfigurator = attributes.find((a) => a.identifier === ATTR_IDENTIFIER.CONFIGURATOR_LINK);

      if (lienConfigurator) {
        const target = attributes.find((a) => a.identifier === ATTR_IDENTIFIER.CONFIGURATOR_LINK_TARGET);
        return { url: lienConfigurator?.values[0]?.value, target: target?.values[0]?.value };
      }
    }
    return null;
  },

  getSalesUnitProduct: (attributes, productType) => {
    const identifier =
      productType === PRODUCT_TYPE.BOTTE ? ATTR_IDENTIFIER.SALES_UNIT_BOTTE : ATTR_IDENTIFIER.SALES_UNIT_COLISE;
    if (attributes) {
      const filter = attributes.filter((a) => a.identifier === identifier);
      if (filter.length > 0 && filter[0].values.length > 0) {
        if (filter[0].identifier === ATTR_IDENTIFIER.SALES_UNIT_BOTTE) {
          return 1 / filter[0].values[0].value;
        } else {
          return filter[0].values[0].value;
        }
      }
    }
    return null;
  },

  getSalesUnitPromotionProduct: (attributes, productType) => {
    const identifier =
      productType === PRODUCT_TYPE.BOTTE ? ATTR_IDENTIFIER.SALES_UNIT_BOTTE : ATTR_IDENTIFIER.SALES_UNIT_COLISE;
    if (attributes) {
      const filter = attributes.filter((a) => a.identifier === identifier);
      if (filter.length > 0 && filter[0].values.length > 0) {
        if (filter[0].identifier === ATTR_IDENTIFIER.SALES_UNIT_BOTTE) {
          return filter[0].values[0].value;
        } else {
          return 1 / filter[0].values[0].value;
        }
      }
    }
    return null;
  },

  getTypeProduct: (attributes, product) => {
    if (attributes) {
      for (const att of attributes) {
        if (att.identifier === ATTR_IDENTIFIER.SALES_UNIT_COLISE) {
          return PRODUCT_TYPE.COLISE;
        }
        if (att.identifier === ATTR_IDENTIFIER.SALES_UNIT_BOTTE) {
          return PRODUCT_TYPE.BOTTE;
        }
      }
    }
    if (product && product?.UserData && product?.UserData[0].coeffConvQty && product?.UserData[0].coeffConvQty !== "") {
      return PRODUCT_TYPE.COLISE;
    } else if (
      product &&
      product?.UserData &&
      product?.UserData[0].coeffPrix &&
      product?.UserData[0].coeffPrix !== ""
    ) {
      return PRODUCT_TYPE.BOTTE;
    }
    const customFields =
      (product && product?.UserData
        ? product?.UserData[0]["custom.fields.*"]
        : product && product.items && product.items[0]?.UserData && product.items[0]?.UserData[0]["custom.fields.*"]) ||
      [];
    if (customFields && customFields.length > 0 && customFields[0]?.catent_field4.raw === "STANDARD") {
      return PRODUCT_TYPE.STANDARD;
    } else {
      return PRODUCT_TYPE.SUR_MESURE;
    }
  },

  getCCInitDates: () => {
    const dt = new Date();
    const m = dt.getMonth();
    const y = dt.getFullYear();
    const expire_month = `${m < 9 ? "0" : ""}${m + 1}`;
    const expire_year = `${y}`;
    return { expire_month, expire_year };
  },

  //TODO REMOVE getRibbonAdAttrs
  // getRibbonAdAttrs: (product) => {
  //   const rc: any[] = [];

  //   product?.attributes
  //     ?.filter((a) => a.usage === DESCRIPTIVE && a.storeDisplay === STRING_TRUE)
  //     .map(({ identifier, values }) => ({ identifier, values }))
  //     .forEach(({ identifier, values }) => {
  //       values?.forEach(({ value }) => {
  //         rc.push(...asArray(value).map((v) => ({ identifier, value: v })));
  //       });
  //     });

  //   return rc.sort((a, b) =>
  //     a.identifier.toLowerCase().includes(EXCLUSIVE) ? -1 : b.identifier.toLowerCase().includes(EXCLUSIVE) ? 1 : 0
  //   );
  // },

  canPayWithSofinco: (price: number, min: number, max: number) => {
    const sofincoLimitMin = min || SOFINCO_LIMIT_MIN;
    const sofincoLimitMax = max || SOFINCO_LIMIT_MAX;
    return price && price >= sofincoLimitMin && price <= sofincoLimitMax ? true : false;
  },

  /**
   * @param {Record<any, any>} productInfo - The product information object.
   * @returns {boolean} True if a promotion is available, false otherwise.
   * @description
   * Checks if a promotion is available for a product by examining its attribute
   * with the identifier 'ATC-POINT-ROGUE' and checks if its value is not equal to 1.0
   */
  checkIsPromoAvailable: (productInfo: Record<any, any>): boolean =>
    !!productInfo.attributes?.find(
      (attr: Record<any, any>) =>
        attr.identifier === ATTR_IDENTIFIER.ATC_POINT_ROGUE && parseFloat(attr.values[0].value) !== 1.0
    ),

  getPromotionDetails: (product: Record<string, any> /* ecoPartPrice: number*/) => {
    let displayPrice = 0;
    let promoPercentage = 0;
    let priceAdjustment = 0;
    let promotionId: string | null = null;
    let promotionName: string | null = null;

    if (!product) {
      return { displayPrice, promoPercentage, promotionName, promotionId };
    }

    if ("promoPercentage.numeric" in product && Number(product["promoPercentage.numeric"]) > 0) {
      promoPercentage = Number(Number(product["promoPercentage.numeric"]).toFixed(2));
    }

    const prices = getPrices(product.price);
    if ("promoPriceData.numeric" in product && Number(product["promoPriceData.numeric"]) < prices.list) {
      displayPrice = Number(Number(product["promoPriceData.numeric"]).toFixed(2));
      priceAdjustment = Number(Number(prices.list - displayPrice).toFixed(2));
    }

    if ("promotionId.raw" in product && product["promotionId.raw"] !== "") {
      promotionId = product["promotionId.raw"];
    }

    if ("promotionName.raw" in product && product["promotionName.raw"] !== "") {
      promotionName = product["promotionName.raw"];
    }

    return { displayPrice, promoPercentage, promotionName, promotionId, priceAdjustment };

    // const productType = storeUtil.getTypeProduct(productInfo?.attributes, productInfo);
    // let coeff = 0;
    // if (productInfo?.UserData && productInfo?.UserData[0]?.coeffPrix && productInfo?.UserData[0]?.coeffPrix !== "") {
    //   coeff = parseFloat(productInfo?.UserData[0]?.coeffPrix);
    // } else if (
    //   productInfo?.UserData &&
    //   productInfo?.UserData[0]?.coeffPrix &&
    //   productInfo?.UserData[0]?.coeffConvQty !== ""
    // ) {
    //   coeff = 1 / productInfo?.UserData[0]?.coeffConvQty;
    // } else {
    //   coeff = parseFloat(storeUtil.getSalesUnitPromotionProduct(productInfo?.attributes, productType));
    // }

    // const parameters = {
    //   name: "productPrice_RecoPromo",
    //   productId: productInfo["custom.defaultSkuId.raw"] ? productInfo["custom.defaultSkuId.raw"] : productInfo.id,
    // };
    // await eSpotService
    //   .findByNameWithProduct(parameters)
    //   .then((res) => {
    //     if (res.data?.MarketingSpotData?.[0]?.baseMarketingSpotActivityData?.[0]?.properties?.length > 0) {
    //       let finalPriceTotal = 0;
    //       res.data?.MarketingSpotData[0]?.baseMarketingSpotActivityData[0]?.properties.forEach(
    //         (item: Record<string, string>) => {
    //           if (item?.baseMarketingKey === "PriceAdjustment0") {
    //             priceAdjustment = parseFloat(item.baseMarketingValue);
    //           }
    //           // if (item?.baseMarketingKey === "OriginalPriceTotal") {
    //           //   originalPriceTotal = parseFloat(item.baseMarketingValue);
    //           // }
    //           if (priceAdjustment > 0 && item?.baseMarketingKey === "FinalPriceTotal") {
    //             finalPriceTotal = parseFloat(item.baseMarketingValue);
    //           }
    //           if (item?.baseMarketingKey === "PercentOffAdjustment") {
    //             promoPercentage = parseFloat(item.baseMarketingValue);
    //           }
    //           if (item?.baseMarketingKey === "promotionId") {
    //             promotionId = item.baseMarketingValue;
    //           }
    //           if (item?.baseMarketingKey === "promotionName") {
    //             promotionName = item.baseMarketingValue;
    //           }
    //         }
    //       );

    //       ecoPartPrice = parseFloat((ecoPartPrice as any) || "0");
    //       if ((productType === PRODUCT_TYPE.COLISE || productType === PRODUCT_TYPE.BOTTE) && !!coeff) {
    //         displayPrice = finalPriceTotal + ecoPartPrice * coeff;
    //       } else {
    //         displayPrice = finalPriceTotal + ecoPartPrice;
    //       }
    //     }
    //   })
    //   .catch((e) => {
    //     console.log("Could not retrieve product associated promotion details", e);
    //     console.log("fetching promotions failed for product: ", productInfo);
    //   });
  },

  getOfferPrice: (c) => {
    const { items, price, groupingProperties } = c;
    let min;
    let max;

    if (items) {
      items.forEach(({ price: p }) => {
        const o = p.find(({ usage: u, value: v }) => u === OFFER && v !== "");
        if (o?.value) {
          const v = parseFloat(o.value);
          min = min == null || v < min ? v : min;
          max = max == null || v > max ? v : max;
        }
      });
    } else if (
      groupingProperties?.groupCount > 1 &&
      groupingProperties.groupMinPriceValue != null &&
      groupingProperties.groupMaxPriceValue != null
    ) {
      min = parseFloat(groupingProperties.groupMinPriceValue);
      max = parseFloat(groupingProperties.groupMaxPriceValue);
    } else {
      const o = price.find(({ usage: u, value: v }) => u === OFFER && v !== "");

      min = o?.value ? parseFloat(o.value) : null;
      max = null;
    }

    max = max === min ? null : max;
    return { min, max };
  },

  /**
   * @param obj
   * @returns obj elements joined by comma-space if it's an array, otherwise obj itself
   */
  csValue: (obj) => {
    return asArray(obj)
      .map((u) => storeUtil.parseFloatDecimal(u))
      .join(CS);
  },

  /**
   * by-seller partitioner
   */
  partitionBySellers: (orderItems, storeName, siteInfo) => {
    const parts: any[] = [];
    const { userData = {} } = siteInfo?.storeCfg ?? {};
    const isMP = STRING_TRUE === userData[MP_ENABLED];

    if (isMP && orderItems?.length) {
      const collector: { [k: string]: any } = {};
      const unk: any[] = [];
      let n = 0;

      // partition products by their sellers -- collect products with no-known sellers into a separate list
      orderItems.forEach((p, i) => {
        if (p.sellerId) {
          const o = collector[p.sellerId] ?? { idx: i, seller: { id: p.sellerId, seller: p.seller }, data: [] };
          o.data.push(p);
          collector[p.sellerId] = o;
          ++n;
        } else {
          unk.push(p);
        }
      });

      // partition only if there is at least one SKU with a seller specified
      if (n) {
        parts.push(...Object.values(collector).sort((a, b) => a.idx - b.idx));
        if (unk.length) {
          parts.push({ seller: { id: storeName, seller: storeName }, data: unk });
        }
      }
    }
    return parts;
  },

  constructInventoryMessage: (
    rowData: any,
    translate: TFunction<"translation", undefined>,
    cartPage = false,
    physicalStoreName = ""
  ) => {
    if (cartPage) {
      return rowData.availability
        ? translate(`Cart.Availability.${rowData.availability}`, { storeName: physicalStoreName })
        : "";
    } else {
      return rowData.availableDate === ""
        ? rowData.orderItemInventoryStatus === INVENTORY_STATUS.available ||
          rowData.orderItemInventoryStatus === INVENTORY_STATUS.allocated
          ? rowData.physicalStoreExternalId
            ? translate("CommerceEnvironment.inventoryStatusStore.Available", {
                store: rowData.physicalStoreExternalId,
              })
            : translate("CommerceEnvironment.inventoryStatusOnline.Available")
          : rowData.physicalStoreExternalId
          ? translate("CommerceEnvironment.inventoryStatusStore.OOS", { store: rowData.physicalStoreExternalId })
          : translate("CommerceEnvironment.inventoryStatusOnline.OOS")
        : rowData.availableDate <= new Date()
        ? rowData.physicalStoreExternalId
          ? translate("CommerceEnvironment.inventoryStatusStore.Available", {
              store: rowData.physicalStoreExternalId,
            })
          : translate("CommerceEnvironment.inventoryStatusOnline.Available")
        : rowData.orderItemInventoryStatus !== INVENTORY_STATUS.backordered
        ? rowData.physicalStoreExternalId
          ? translate("CommerceEnvironment.inventoryStatusStore.Available", {
              store: rowData.physicalStoreExternalId,
            })
          : translate("CommerceEnvironment.inventoryStatusOnline.Available")
        : rowData.physicalStoreExternalId
        ? translate("CommerceEnvironment.inventoryStatusStore.Backordered", {
            store: rowData.physicalStoreExternalId,
          })
        : translate("CommerceEnvironment.inventoryStatusOnline.Backordered");
    }
  },
  /**
   * Validate orderitems to make sure it not pickup mixed with other shipping method.
   * @param orderItems
   * @returns true if it not mixed, false otherwise.
   */
  validatePickupOrOnline: (orderItems: any[]) => {
    if (orderItems.length === 0) {
      return true;
    }
    const pickups = orderItems.filter((e: any) => e.shipModeCode === SHIPMODE.shipModeCode.PICKUP);
    return pickups.length === orderItems.length;
  },

  isOrderItemsPickup: (orderItems: any[]) => {
    if (orderItems.length === 0) {
      return false;
    }
    const notPickup = orderItems.some((e: any) => e.shipModeCode !== SHIPMODE.shipModeCode.PICKUP);
    return !notPickup;
  },

  hasAllProductsAvailableForShipping: (data: any, orderItems) => {
    let resp = false;
    if (data) {
      const availProd: any[] = [];
      const prodAvails = data.availabilityDetails;
      if (prodAvails && orderItems) {
        orderItems
          .filter((o) => o.buyable === "true")
          .forEach((order) => {
            for (let index = 0; index < prodAvails.length; index++) {
              const prod = prodAvails[index];
              if (order.partNumber === prod.sku) {
                if (isAvailableForShipping(prod)) {
                  availProd.push(prod);
                }
              }
            }
          });
      }
      if (availProd.length >= orderItems.filter((o) => o.buyable === "true").length) {
        resp = true;
      }
    }
    return resp;
  },

  hasAllProductsAvailableInStore: (data: any, orderItems, storeId) => {
    let resp = false;
    if (data) {
      const availProd: any[] = [];
      //const prodAvails = data.availabilities && data.availabilities?.availabilityDetails;
      const prodAvails = data.result[storeId];
      if (prodAvails && orderItems) {
        orderItems
          .filter((o) => o.buyable === "true")
          .forEach((order) => {
            if (isAvailableInStore(prodAvails[order.partNumber])) {
              availProd.push(prodAvails[order.partNumber]);
            }
            // for (let index = 0; index < prodAvails.length; index++) {
            //   const prod = prodAvails[index];
            //   if (order.partNumber === prod.sku) {
            //     if (isAvailableInStore(prod)) {
            //       availProd.push(prod);
            //     }
            //   }
            // }
          });
      }
      if (availProd.length >= orderItems.filter((o) => o.buyable === "true").length) {
        resp = true;
      }
    }

    return resp;
  },
};

export default storeUtil;
