import { StyledBox } from "../../../styled-mui";
import { palette } from "../../../themes/color-palette";

function KeenSliderArrow(props: {
  color: string;
  big?: boolean;
  disabled?: boolean;
  left?: boolean;
  style?: any;
  className?: string;
  onClick: (e: any) => void;
}) {
  const disabled = props.disabled ? " arrow--disabled" : "";
  const color = props.color;
  const big = props.big;
  const className = props.className ? props.className : "";

  function setColor() {
    if (color === "text.primary") {
      return palette.text.primary;
    }
    if (color === "text.vertAmenagementExterieurs") {
      return palette.vertAmenagementExterieurs.main;
    }
    if (color === "secondary.main") {
      return palette.blue.dark;
    }
    if (color === "white.main") {
      return palette.white.main;
    }
    if (color === "gray.neutralGray600") {
      return palette.gray.neutralGray600;
    }
  }

  return (
    <StyledBox
      onMouseDown={(e) => e.preventDefault()}
      onClick={props.onClick}
      className={`${big ? "arrow-big" : "arrow"} ${
        props.left ? "arrow--left" : "arrow--right"
      } ${disabled} ${className}`}
      style={props.style}>
      <svg xmlns="http://www.w3.org/2000/svg" width="8" height="12" viewBox="0 0 8 12">
        <path
          fill={setColor()}
          d="M7.25 5.995c0 .128-.02.256-.06.374a.829.829 0 0 1-.208.325l-4.587 4.55c-.179.177-.406.256-.674.256a.923.923 0 0 1-.684-.286.947.947 0 0 1-.277-.689c0-.286.09-.512.277-.69l3.865-3.84-3.894-3.86a.94.94 0 0 1-.258-.68c0-.266.1-.492.287-.68A.937.937 0 0 1 1.731.5c.277 0 .515.089.694.276l4.557 4.53c.1.098.169.207.209.325.04.118.059.236.059.374v-.01Z"
        />
      </svg>
    </StyledBox>
  );
}

export default KeenSliderArrow;
