import styled from "@mui/styled-engine-sc";
// import { palette } from "../../themes/color-palette";
import ErrorBoundary from "../../utils/ErrorBoundary";

const CustomContainer = (props: any) => (
  <ErrorBoundary
    fallback={
      <div style={{ textAlign: "center" }}>
        <p>Something went wrong.</p>
      </div>
    }>
    <div {...props} />
  </ErrorBoundary>
);

const StyledContainer = styled(CustomContainer)`
  ${({ theme }) => `
    padding: 0 ${theme.spacing(20)};
    max-width: 1440px;
    margin: 0 auto;

    ${theme.breakpoints.up("xl")} {
      padding: 0 ${theme.spacing(40)};
      max-width: 1760px;
    }
    ${theme.breakpoints.down("lg")} {
      padding: 0 ${theme.spacing(10)};
    }
    ${theme.breakpoints.down("md")} {
      padding: 0 ${theme.spacing(5)};
    }

    &.no-padding {
      padding: 0;
    }

    &.no-padding-right {
      padding: 0 0 0 ${theme.spacing(20)};

      ${theme.breakpoints.up("xl")} {
        padding: 0 0 0 ${theme.spacing(40)};
      }
      ${theme.breakpoints.down("lg")} {
        padding: 0 0 0 ${theme.spacing(10)};
      }
      ${theme.breakpoints.down("md")} {
        padding: 0 0 0 ${theme.spacing(5)};
      }
    }

    &.no-padding-mobile {
      ${theme.breakpoints.down("sm")} {
        padding: 0;
      }
    }
    &.no-max-width {
      max-width: none;
      ${theme.breakpoints.up("xl")} {
        max-width: none;
      }
    }
    &.full-height {
      height: 100%;
    }

  `}
`;

export { StyledContainer };
