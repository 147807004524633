import styled from "@mui/styled-engine-sc";
import Snackbar from "@mui/material/Snackbar";
import React from "react";

const StyledSnackbar = styled(({ ...props }) => <Snackbar {...props} />)`
  ${({ theme }) => `
    &.MuiSnackbar-anchorOriginTopRight {
      top: 150px;

      ${theme.breakpoints.down("sm")} {
        top: 100px;
      }
    }
    &.relative {
      position: relative;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      justify-content: center;
    }

  `}
`;

export { StyledSnackbar };
