import styled from "@mui/styled-engine-sc";
import SvgIcon from "@mui/material/SvgIcon";

const CustomSvg = styled((props) => (
  <SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} />
))`
  ${({ theme }) => `
    width:16px;
    height:16px;

    &.big {
      width:24px;
      height:24px;
    }
  `}
`;

export const SvgIconPreference = (props) => {
  const { className = "" } = props;

  return (
    <CustomSvg {...{ className }}>
      <path d="M3.334 12.666a.649.649 0 0 1-.474-.193.649.649 0 0 1-.193-.473c0-.187.067-.347.193-.474a.649.649 0 0 1 .474-.193H4V6.666c0-.92.28-1.74.834-2.46A3.815 3.815 0 0 1 7 2.8v-.467c0-.28.1-.513.294-.707A.972.972 0 0 1 8 1.333c.274 0 .514.1.707.293A.972.972 0 0 1 9 2.333V2.8c.887.22 1.614.693 2.167 1.406.553.714.833 1.534.833 2.46v4.667h.667c.187 0 .347.067.473.193a.649.649 0 0 1 .194.474.649.649 0 0 1-.194.473.648.648 0 0 1-.473.193H3.334Zm4.666 2c-.366 0-.68-.133-.94-.393a1.284 1.284 0 0 1-.393-.94h2.667c0 .367-.134.68-.394.94-.26.26-.573.393-.94.393Zm-2.666-3.333h5.333V6.666c0-.733-.26-1.36-.787-1.886a2.568 2.568 0 0 0-1.886-.787c-.734 0-1.36.26-1.887.787a2.568 2.568 0 0 0-.787 1.886v4.667h.014Z" />
    </CustomSvg>
  );
};
