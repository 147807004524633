/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import styled from "@mui/styled-engine-sc";
import { get, isEqual } from "lodash-es";
import { StyledRadioGroup, StyledFormControl, StyledToggleButtonGroup } from "@hcl-commerce-store-sdk/react-component";
import { StyledButton, StyledSwatch, StyledToggleButton } from "../../styled-mui";
import { useMediaQuery, useTheme } from "@mui/material";
import storeUtil from "../../utils/storeUtil";
import { ATTR_IDENTIFIER } from "../../constants/catalog";
import AsyncCall from "../../_foundation/gtm/async.service";
import { CLICK } from "../../_foundation/constants/gtm";
import { useTranslation } from "react-i18next";

function RadioGroupWrapper({
  id,
  name,
  onChangeHandler,
  values,
  useSwatches,
  className,
  productDetailsAny,
  skusAsAttrs,
  isB2B,
  currentSelection,
  rowNo,
  ...props
}: any) {
  let defaultSelected = "";
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useTranslation();
  const currentSelectedAttributes = get(currentSelection, "selectedAttributes", {});
  defaultSelected = currentSelectedAttributes[id] ?? "";
  if (defaultSelected) {
    defaultSelected = Array.isArray(defaultSelected) ? currentSelectedAttributes[id][0] : currentSelectedAttributes[id];
  }

  const getMaxItem = () => {
    if (
      id.indexOf(ATTR_IDENTIFIER.LARGEUR) > -1 ||
      id.indexOf(ATTR_IDENTIFIER.LONGUEUR) > -1 ||
      id.indexOf(ATTR_IDENTIFIER.HAUTEUR) > -1 ||
      id.indexOf(ATTR_IDENTIFIER.PROFONDEUR) > -1
    ) {
      return isMobile ? 5 : 6;
    } else if (id.indexOf(ATTR_IDENTIFIER.FINITION) > -1) {
      return 5;
    } else {
      return isMobile ? 7 : 9;
    }
  };

  const [unfold, setUnfold] = useState(getMaxItem() < values.findIndex((v) => v.value === defaultSelected));

  // from current selection's attributes, discard the current name/value pair
  const otherAttrs = Object.keys(currentSelectedAttributes)
    .filter((k) => k !== id)
    .reduce((m, v) => {
      m[v] = currentSelectedAttributes[v];
      return m;
    }, {});

  const [value, setValue] = React.useState(defaultSelected);

  const handleChange = (event: any) => {
    setValue(event.target.value);
    onChangeHandler(id, event.target.value);
  };

  const handleClick = (optionValue: string) => {
    setValue(optionValue);
    onChangeHandler(id, optionValue);
  };
  const MoreAttributes = useMemo(
    () => () => {
      let label = "";
      if (
        id.indexOf(ATTR_IDENTIFIER.LARGEUR) > -1 ||
        id.indexOf(ATTR_IDENTIFIER.LONGUEUR) > -1 ||
        id.indexOf(ATTR_IDENTIFIER.HAUTEUR) > -1 ||
        id.indexOf(ATTR_IDENTIFIER.PROFONDEUR) > -1
      ) {
        label = t("ProductAttributes.More.Dimension");
      } else if (id.indexOf(ATTR_IDENTIFIER.FINITION) > -1) {
        label = t("ProductAttributes.More.Finition");
      } else {
        label = t("ProductAttributes.More.Couleur");
      }
      const handleClickShowMore = () => {
        AsyncCall.sendPageClickCtaEvent({
          event: CLICK.PRODUCT_DETAIL,
          btn_label: !unfold ? label : t("ProductAttributes.Less"),
          product_id: currentSelection.sku[0].partNumber,
          product_name: currentSelection.sku[0].name,
        });
        setUnfold(!unfold);
      };

      return values?.length > getMaxItem() ? (
        <StyledButton
          variant="text"
          className="link-hover-animated product-information-link-more"
          color="secondary"
          onClick={handleClickShowMore}
          sx={{ textTransform: "none", fontWeight: "400" }}>
          {!unfold ? label : t("ProductAttributes.Less")}
        </StyledButton>
      ) : (
        <></>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [unfold]
  );

  /* update rest of the values based on possible values because currentSelection doesn't update if combination is not possible */
  useEffect(() => {
    setValue(currentSelection.selectedAttributes[id]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSelection]);

  const orderValues = values?.sort((a, b) => {
    if (isNaN(a.value) && isNaN(b.value)) {
      return a.value < b.value ? -1 : 1;
    } else {
      return parseInt(a.value) < parseInt(b.value) ? -1 : 1;
    }
  });

  return (
    <StyledFormControl component="fieldset" className={useSwatches ? `${className} swatch-group` : className}>
      {useSwatches ? (
        <StyledRadioGroup aria-label={name} name={name} value={value} onChange={handleChange}>
          {orderValues?.map((v, index) => {
            return (
              <StyledSwatch
                style={{
                  backgroundImage: `url("${storeUtil.getUrlPicture(v.image1)}")`,
                  backgroundSize: "contain",
                }}
                onClick={() => handleClick(v.value)}
                selected={defaultSelected === v.value}
                size={name.toUpperCase() === ATTR_IDENTIFIER.COULEUR ? "medium" : "big"}
                key={index}
                data-testid={`radio-group-wrapper-${v.identifier.toLowerCase()}-swatch`}
              />
            );
          })}
        </StyledRadioGroup>
      ) : (
        <StyledToggleButtonGroup
          value={value}
          exclusive
          onChange={handleChange}
          aria-label={name}
          className="bottom-margin-1 flex-wrap">
          {values?.map((v, index) => {
            // for B2C scenarios, if this attribute's value isn't in current selection, check to see
            //   if there exists such a SKU -- if it doesn't we will disable this attribute value, e.g.,
            //
            //   currentSku = { Color: "blue", Size: "48x48x48" }
            //   currentAttr = { Size: "52x52x52" };
            //
            // merge with `otherAttrs`, i.e., { Color: "blue" } --> { Color: "blue", Size: "52x52x52" }
            //   then look in skusAsAttrs array to see if such a sku exists
            //
            // if it exists, keep this attr-value enabled, otherwise disable it because no such SKU exists
            const findSku =
              !isB2B &&
              !useSwatches &&
              !Array.isArray(currentSelectedAttributes[id]) &&
              v.value !== currentSelectedAttributes[id];
            const skuToFind = findSku ? { ...otherAttrs, [id]: v.value } : null;
            const skuExists = findSku ? skusAsAttrs.find((sku) => isEqual(sku, skuToFind)) : null;
            return (
              <StyledToggleButton
                key={index}
                value={v.value}
                aria-label={v.value}
                disabled={rowNo !== 0 && findSku && !skuExists}
                className={`${
                  index >= getMaxItem() && !unfold && "hide"
                } bottom-margin-2 product-information-toggle-button`}>
                {storeUtil.parseFloatDecimal(v.value)}
              </StyledToggleButton>
            );
          })}
        </StyledToggleButtonGroup>
      )}
      <MoreAttributes />
    </StyledFormControl>
  );
}

RadioGroupWrapper.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChangeHandler: PropTypes.any.isRequired,
  values: PropTypes.any.isRequired,
  useSwatches: PropTypes.bool,
};

const StyledSwatchRadioGroup = styled(({ ...props }) => <RadioGroupWrapper {...props} />)`
  ${({ theme }) => `
    &.swatch-group .MuiFormGroup-root {
      flex-direction: row;
    }
  `}
`;

export { StyledSwatchRadioGroup };
