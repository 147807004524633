/* eslint-disable react-hooks/exhaustive-deps */
import Axios, { Canceler } from "axios";
import { useLocation } from "react-router-dom";
import { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { currentContractIdSelector } from "../../redux/selectors/contract";
import { site } from "../../_foundation/constants/site";
import productsService from "../apis/search/products.service";
import { localStorageUtil } from "../utils/storageUtil";
import { useSite } from "./useSite";
import { PRODUCT_SUMMARY_PROFILE } from "../../constants/common";
/**
 * useFetchCpProduct component to fetch wp posts
 *
 * @param {number} widgetId Number of posts to fetch (default 3)
 * @param {string | null} [category] Wordpress category to fetch
 */
const useFetchCpProduct = (
  widgetId,
  variables,
  isModalLoader = true,
  allFpcId,
  mostExpensiveFpcId,
  lastProductFpcId,
  basketAmount,
  emptyCart
) => {
  const [data, setData] = useState<any | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [Name, setName] = useState<string | undefined>("");
  const location = useLocation();
  const currentPath = location.pathname;

  const { mySite } = useSite();
  const contract = useSelector(currentContractIdSelector);
  const CancelToken = Axios.CancelToken;
  const cancels: Canceler[] = [];
  const storeId: string = mySite ? mySite.storeID : "";
  const widgetName = widgetId;

  useEffect(() => {
    if (isModalLoader && currentPath !== "/cart") {
      fetchRemoteFeed();
    }
    return () => {
      cancels.forEach((cancel) => cancel());
    };
  }, [isModalLoader]);

  const allFpcIdMemo = useMemo(() => allFpcId, [JSON.stringify(allFpcId)]);

  useEffect(() => {
    if (currentPath === "/cart" && allFpcIdMemo.length > 0 && !emptyCart) {
      fetchRemoteFeed();
    }
    else if (currentPath === "/cart" && allFpcIdMemo.length === 0 && emptyCart) {
      fetchRemoteFeed();
    }
    return () => {
      cancels.forEach((cancel) => cancel());
    };
  }, [allFpcIdMemo, currentPath, emptyCart]);



  const fetchRemoteFeed = async () => {
    setError(false);
    const ebProfileid = localStorageUtil.getCrownPeakProfileId();
    let url;

    // Check if the current path is '/cart'
    if (currentPath === "/cart" && allFpcId.length > 0 && !emptyCart) {
      // Handle the cart-specific API call
      const productIdsString = JSON.stringify(allFpcId);
      //Construct the variables part of the query string
      const variables = `{"$productIds":${productIdsString},"$basketamount":"${basketAmount}","$mostexpensiveproduct":"${mostExpensiveFpcId}","$lastaddedproduct":"${lastProductFpcId}"}`; // ["FPC2399131", "FPC804193"]
      url = new URL(
        `${site.crownpeakUrl}/widget/${widgetId}/recommendations?variables=${variables}&fields=id,recommendations.id,widget.title`
      );
    } else if (currentPath === "/cart" && allFpcId.length === 0 && emptyCart) {
      url = new URL(`${site.crownpeakUrl}/widget/${widgetId}/recommendations${ebProfileid ? `/${ebProfileid}` : ""}`);
      url.searchParams.append("fields", "id,recommendations.id");
      if (variables) {
        const variableValue = variables.match(/FPC\d+/g);
        const VariableObjectArray = { "$productId": variableValue }
        url.searchParams.append("variables", JSON.stringify(VariableObjectArray));
      }
    } else {
      // Default API call for non-cart pages

      url = new URL(`${site.crownpeakUrl}/widget/${widgetId}/recommendations${ebProfileid ? `/${ebProfileid}` : ""}`);
      url.searchParams.append("fields", "id,recommendations.id");
      if (variables) {
        const variableValue = variables.match(/FPC\d+/g);
        const VariableObjectArray = { "$productId": variableValue }
        url.searchParams.append("variables", JSON.stringify(VariableObjectArray));
      }
    }
    // https://api-console.early-birds.io/widget/5e54f8e291c86c8a8c8fea82/recommendations/{{ebProfileId}}?variables={"$productId":"FPC5768157"}
    await fetch(url)
      .then((response) => {
        return response.json();
      })
      .then((resp) => {
        //const products: any[] = [];
        if (resp && resp.widget?.title) {
          setName(resp.widget.title);
        }
        if (resp && resp.recommendations) {
          const params: any = {
            contractId: contract,
            storeId: storeId,
            widget: widgetName,
            excludedAttribute:
              mySite?.storeCfg?.userData?.PLP_FILTER_ATTRIBUTE &&
              mySite?.storeCfg?.userData?.PLP_FILTER_ATTRIBUTE.length > 0 &&
              mySite?.storeCfg?.userData?.PLP_FILTER_ATTRIBUTE,
            profileName: PRODUCT_SUMMARY_PROFILE,

            cancelToken: new CancelToken(function executor(c) {
              cancels.push(c);
            }),
            partNumber: resp.recommendations?.map((p) => p.id),
          };

          productsService
            .findProductsUsingGET(params)
            .then((productData: any) => {
              const products = productData?.data?.contents || []; // Fallback to empty array
              const productMap = new Map(products.map((product: any) => [product.partNumber, product]));
              // Sort products based on the order of recommendations
              const sortedProducts = resp.recommendations.map((rec: any) => {
                return productMap.get(rec.id);
              });
              setData({
                widgetId,
                recommendationId: resp.id,
                products: sortedProducts.filter((item) => item && item.partNumber), // Set data with merged recommendations and products
              });
            })
            .catch((e) => {
              setError(true);
              console.log("Could not retrieve product details page informarion", e);
            });
        }
      })
      .catch((_err) => {
        // If remote fetch fail fallback to the local feed
        console.log("error fetching remote feeds", _err);
        setError(true);
      });

    setLoading(false);
  };

  return { data, loading, error, Name };
};

export default useFetchCpProduct;
