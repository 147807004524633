import styled from "@mui/styled-engine-sc";
import Alert from "@mui/material/Alert";

export const StyledAlert = styled(Alert)`
  ${({ theme }) => `
    border: none;
    border-radius: 8px;
    background: ${theme.palette.white.main};
    box-shadow: 0px 2px 6px 0px rgba(37, 36, 39, 0.25);
    color: ${theme.palette.text.primary};
    padding: ${theme.spacing(4)};

    .MuiAlert-icon,
    .MuiAlert-message {
      padding: 0;
    }

    .MuiAlert-action {
      padding: 0 0 0 ${theme.spacing(2)};

      .MuiButtonBase-root {
        padding: 0;
      }

      .MuiSvgIcon-root {
        color: ${theme.palette.gray.neutralGray600};
      }
    }

    &.MuiAlert-filledError {
      .MuiAlert-icon {
        color: #f44336;
      }
    }
    &.MuiAlert-filledSuccess {
      .MuiAlert-icon {
        color: rgb(26, 147, 15);
      }
    }
    &.MuiAlert-outlined {
      background: ${theme.palette.secondary.extraLight};
      border: 1px solid rgba(61, 104, 129, 0.25);
      box-shadow: none;
      padding: ${theme.spacing(1.5)} ${theme.spacing(2)};
      align-items: center;
    }
  `}
`;
