import styled from "@mui/styled-engine-sc";
import Badge, { BadgeProps } from "@mui/material/Badge";

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 0,
    top: 3,
    width: 16,
    minWidth: 16,
    height: 16,
    fontWeight: 700,
    fontSize: 10,
    backgroundColor: theme.palette.primary.dark,
  },
}));

export { StyledBadge };
