import styled from "@mui/styled-engine-sc";
import SvgIcon from "@mui/material/SvgIcon";

const CustomSvg = styled((props) => (
  <SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} />
))`
  ${({ theme }) => `
    width:16px;
    height:16px;

    &.medium {
      width:20px;
      height:20px;
    }

    &.big {
      width:24px;
      height:24px;
    }
  `}
`;

export const SvgIconAddress = (props) => {
  const { className = "" } = props;

  return (
    <CustomSvg {...{ className }}>
      <path d="M8.00033 7.99634C8.36699 7.99634 8.68033 7.86308 8.94033 7.60321C9.20033 7.34334 9.33366 7.03016 9.33366 6.66368C9.33366 6.29719 9.20033 5.98402 8.94033 5.72415C8.68033 5.46428 8.36699 5.33101 8.00033 5.33101C7.63366 5.33101 7.32033 5.46428 7.06033 5.72415C6.80033 5.98402 6.66699 6.29719 6.66699 6.66368C6.66699 7.03016 6.80033 7.34334 7.06033 7.60321C7.32033 7.86308 7.63366 7.99634 8.00033 7.99634ZM8.00033 12.8939C9.35366 11.6479 10.3603 10.5217 11.0203 9.50226C11.6803 8.48277 12.007 7.58322 12.007 6.79694C12.007 5.58422 11.6203 4.59804 10.847 3.8251C10.0737 3.05215 9.12699 2.66567 8.00699 2.66567C6.88699 2.66567 5.94033 3.05215 5.16699 3.8251C4.39366 4.59804 4.00699 5.59088 4.00699 6.79694C4.00699 7.58322 4.33366 8.48943 4.99366 9.50226C5.65366 10.5151 6.65366 11.6479 8.01366 12.8939H8.00033ZM8.00033 14.6597C6.21366 13.1404 4.87366 11.7278 3.99366 10.4218C3.11366 9.11578 2.66699 7.90972 2.66699 6.79694C2.66699 5.13111 3.20033 3.80511 4.27366 2.81893C5.34699 1.82609 6.58699 1.33301 8.00033 1.33301C9.41366 1.33301 10.6537 1.82609 11.727 2.81893C12.8003 3.80511 13.3337 5.13777 13.3337 6.80361C13.3337 7.91638 12.8937 9.12245 12.007 10.4285C11.1203 11.7345 9.78699 13.1471 8.00033 14.6663V14.6597Z" />
    </CustomSvg>
  );
};
