/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
import { call, put } from "redux-saga/effects";
import { PROFILE_UPDATE_TYPE } from "../../../constants/common";
import messageService from "../../../_foundation/apis/transaction/messages.service";
import newsletterService from "../../../_foundation/apis/transaction/newsletter.service";
import personService from "../../../_foundation/apis/transaction/person.service";
import * as ACTIONS from "../../action-types/account";
import { DELETE_ACCOUNT_ERROR_ACTION, DELETE_ACCOUNT_SUCCESS_ACTION } from "../../actions/account";
import { HANDLE_SUCCESS_MESSAGE_ACTION } from "../../actions/success";
import { LOGOUT_REQUESTED_ACTION } from "../../actions/user";
// import { getSite } from "../../../_foundation/hooks/useSite";
// import { initUserZipcode } from "../../../utils/userUtil";

/**
 * Saga worker to invoke get person details
 */
export function* fetchAddressDetails(action: any) {
  try {
    const payload = action.payload;

    const response = yield call(personService.findPersonBySelf, payload);
    const personData = response.data;
    // initUserZipcode(response.data);

    yield put({
      type: ACTIONS.ADDRESS_DETAILS_GET_SUCCESS,
      response: personData,
    });
  } catch (error) {
    yield put({ type: ACTIONS.ADDRESS_DETAILS_GET_ERROR, error });
  }
}

export function* updateAccountDetails(action: any) {
  try {
    let payload = action.payload;
    const callSubscriptionNl = action.payload.callSubscriptionNl === true;
    if (callSubscriptionNl) {
      const resSubscription = yield call(newsletterService.subscription, {
        body: {
          type: PROFILE_UPDATE_TYPE,
          statusEmail: action.payload.body.receiveEmail,
          source_optin: "LapeyreFR souscription NL",
          recaptchaToken: action.payload.body.recaptchaToken,
          optin: true,
          isFooter: false,
          newsletterOn: action.payload.newsletterOn,
          isMyAccount: true,
          email: action.payload.email.toLowerCase(),
        },
      });
      const receiveEmail = action.payload.body.receiveEmail === "true" && resSubscription.data.status === "OK";

      payload = {
        ...action.payload,
        body: {
          email: action.payload.email.toLowerCase(),
          recaptchaToken: action.payload.body.recaptchaToken,
          receiveEmail: `${receiveEmail}`,
          receiveEmailPreference: [{ value: `${receiveEmail}`, storeID: action.payload.storeId }],
        },
      };
    }

    if (!("newsletterOn" in action.payload)) {
      yield call(personService.updatePerson, payload);
      yield put({ type: ACTIONS.ACCOUNT_DETAILS_PUT_SUCCESS });
    }
    if (action.payload.confirmMsg) {
      yield put(HANDLE_SUCCESS_MESSAGE_ACTION({ ...action.payload.confirmMsg }));
    } else {
      yield put(HANDLE_SUCCESS_MESSAGE_ACTION({ key: "PersonalInformation.UpdateSuccessful" }));
    }

    yield put({
      type: ACTIONS.ADDRESS_DETAILS_GET_REQUESTED,
      payload: { widget: action.payload.widget || "UpdateAccountDetailsSaga" },
    });
  } catch (error) {
    yield put({ type: ACTIONS.ACCOUNT_DETAILS_PUT_ERROR, error });
  }
}

export function* deleteAccount(action: any) {
  try {
    const response = yield call(messageService.deleteAccount, action.payload);
    yield put({ type: DELETE_ACCOUNT_SUCCESS_ACTION });
    if (response.status === 200) {
      if (response.data.accountDeleted === "true") {
        yield put(HANDLE_SUCCESS_MESSAGE_ACTION({ key: "PersonalInformation.deleteUserSuccessful" }));
        yield put(LOGOUT_REQUESTED_ACTION({}));
      }
    }
  } catch (error) {
    yield put({ type: DELETE_ACCOUNT_ERROR_ACTION, error });
  }
}
