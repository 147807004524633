import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import HTMLReactParser from "html-react-parser";
import { SnackbarOrigin } from "@mui/material";
import { StyledAlert, StyledBox, StyledButton, StyledSnackbar, StyledTypography } from "../../../styled-mui";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { SvgCheckThick } from "../../svg/checkThick";

interface MessageSnackbarProperty {
  anchorOrigin: SnackbarOrigin;
  handleClose: () => void;
  message: any | null;
  severity: "success" | "info" | "warning" | "error" | undefined;
  linkAction?: { url: string; text: string };
  autoHideDuration?: number;
  ClickAwayListenerProps?: any;
}
const MessageSnackbar = ({
  anchorOrigin,
  handleClose,
  message,
  severity,
  linkAction,
  autoHideDuration,
  ClickAwayListenerProps,
}: MessageSnackbarProperty) => {
  const location: any = useLocation();
  const open = !!message;
  //const [open, setOpen] = useState(false);
  let clickAway = {};
  if (ClickAwayListenerProps) {
    clickAway = { ClickAwayListenerProps };
  }
  const MessageToDisplay = () => {
    return (
      <StyledBox>
        <>
          {message?.title ? (
            <>
              <StyledTypography variant="body1" className={`${severity}`}>
                {message && HTMLReactParser(message?.title)}
              </StyledTypography>
              <StyledTypography variant="body2">{message && HTMLReactParser(message?.description)}</StyledTypography>
            </>
          ) : (
            <StyledTypography variant="body2">{message && HTMLReactParser(message)}</StyledTypography>
          )}
        </>
        {linkAction && linkAction.text && (
          <StyledBox mt={3}>
            <StyledButton
              href={linkAction.url}
              variant="text"
              size="small"
              color="secondary"
              endIcon={<ChevronRightIcon />}
              sx={{ fontWeight: 400, textTransform: "none" }}>
              {linkAction.text}
            </StyledButton>
          </StyledBox>
        )}
      </StyledBox>
    );
  };
  const onClose = () => {
    //setOpen(false);
    if (open) {
      handleClose();
    }
  };

  useEffect(() => {
    //close on location change.
    onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  return (
    <StyledSnackbar
      open={open}
      onClose={onClose}
      anchorOrigin={anchorOrigin}
      autoHideDuration={autoHideDuration}
      transitionDuration={{ exit: 10 }}
      {...clickAway}>
      <StyledAlert
        variant="filled"
        severity={severity}
        iconMapping={{ success: <SvgCheckThick className="rounded-green" /> }}
        onClose={onClose}>
        <MessageToDisplay />
      </StyledAlert>
    </StyledSnackbar>
  );
};

export default MessageSnackbar;
