import SvgIcon from "@mui/material/SvgIcon";
import styled from "@mui/styled-engine-sc";
import { palette } from "../../themes/color-palette";

const CustomSvg = styled((props) => (
  <SvgIcon width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} />
))`
  ${({ theme }) => `
    width:18px;
    height:18px;
    background: ${palette.blue.dark};
    fill: ${palette.white.main};
    border-radius: 4px;

    &.rounded-red {
      width:24px;
      height:24px;
      background: ${palette.primary.main};
      border-radius: 100%;
    }

    &.rounded-green {
      width:24px;
      height:24px;
      background: ${palette.success.main};
      border-radius: 100%;
    }

    &.rounded {
      border-radius: 100%;
    }

    &.black {
      background: ${palette.gray.neutralGray900};
    }

    &.blue {
      background: ${palette.blue.dark};
    }

    &.green-dark {
      background: ${palette.success.dark};
    }

    &.beige {
      background: ${palette.beige.light};
      fill: ${palette.blue.dark};
    }

    &.small {
      width:12px;
      height:12px;
    }

    &.medium {
      width:20px;
      height:20px;
    }

    &.large {
      width:24px;
      height:24px;
    }

    &.medium-big {
      width:24px;
      height:24px;

      ${theme.breakpoints.down("sm")} {
        width:40px;
        height:40px;
      }
    }

    &.big {
      width:40px;
      height:40px;
    }
  `}
`;

export const SvgCheckThick = (props) => {
  return (
    <CustomSvg {...props} className={props?.className || ""}>
      <path d="M7.753 12.933a.634.634 0 0 1-.911-.002l-2.806-2.88a1.022 1.022 0 0 1 .018-1.436.96.96 0 0 1 1.364.018l1.878 1.927 5.247-5.384a.982.982 0 0 1 1.414 0c.392.402.39 1.054-.003 1.454l-6.2 6.303Z" />
    </CustomSvg>
  );
};
