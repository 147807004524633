import styled from "@mui/styled-engine-sc";

const Cb = styled((props) => (
  <svg width="40" height="16" viewBox="0 0 40 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} />
))`
  ${({ theme }) => `
  `}
`;

export const SvgCb = (props) => {
  const { className = "" } = props;

  return (
    <Cb {...{ className }}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.8032 3.86972C39.8032 1.72973 38.0735 0 35.9335 0H21.8628V7.73943H35.9335C38.0624 7.73943 39.7921 5.99861 39.7921 3.86972H39.8032ZM39.8032 12.0748C39.8032 9.94595 38.0735 8.20513 35.9446 8.20513H21.8739V15.9446H35.9446C38.0735 15.9446 39.8032 14.2037 39.8032 12.0748ZM11.2627 8.20513V7.73943H21.5967V7.08524C21.5967 3.20444 18.4477 0.0554401 14.5558 0.0554401H7.99169C4.0998 0.0554401 0.950806 3.20444 0.950806 7.08524V8.95911C0.950806 12.851 4.0998 16 7.99169 16H14.5558C18.4477 16 21.5967 12.851 21.5967 8.95911V8.20513H11.2627Z"
        fill="white"
      />
    </Cb>
  );
};
