import styled from "@mui/styled-engine-sc";
import { palette } from "../../../themes/color-palette";

export const StyledOrderItem = styled("div")`
  ${({ theme }) => `
    .order-item {
      border-bottom: ${palette.gray.neutralGray300} 1px solid;
      position: relative;

      &:last-child {
        border-bottom: 0;
      }

      &.unavailable {
        opacity: 0.5;
      }

      ${theme.breakpoints.down("sm")} {
        border: ${palette.gray.neutralGray300} 1px solid;
        border-radius: 8px;

        &:last-child {
          border-bottom: ${palette.gray.neutralGray300} 1px solid;
        }
      }
      &.modal {
        border: ${palette.gray.neutralGray300} 1px solid;
        border-radius: 8px;

        .order-item-left {
            border-right: ${palette.gray.neutralGray300} 1px solid;
            ${theme.breakpoints.down("sm")} {
              border-right: 0;
            }

            .order-item-left-image {
              border: 0;
            }
        }

        .order-item-right {
          border-left: 0;
          ${theme.breakpoints.down("sm")} {
            border-left: ${palette.gray.neutralGray300} 1px solid;
          }
        }
      }

      .order-item-left {
        ${theme.breakpoints.down("sm")} {
          align-self: center;
        }
      }
      .order-item-right {
        ${theme.breakpoints.down("sm")} {
          border-left: ${palette.gray.neutralGray300} 1px solid;
          align-self: center;
        }
      }
      .order-item-price {
        display: flex;
        justify-content: center;
        flex-direction: column;
        min-width:115px
      }

      .order-item-left-image {
        border: ${palette.gray.neutralGray300} 1px solid;
        border-radius: 8px;
        aspect-ratio: 1;
        display: flex;
        align-items: center;

        ${theme.breakpoints.down("sm")} {
          &.no-border {
            border: 0;
            border-radius: 0;
          }
        }
      }
      .order-item-btn-delete {
        position: absolute;
        top: ${theme.spacing(6)};
        right: 0;

        ${theme.breakpoints.down("sm")} {
          top: ${theme.spacing(3)};
          right: ${theme.spacing(3)};

          .MuiButton-startIcon {
            margin: 0;

            svg {
              width: 20px;
              height: auto;
            }
          }
          a {
            display: none;
          }
        }
      }

      .product-price-promo-reduc {
        background: #EB002D;
        border-radius: 4px;
        color: #FFFFFF;
      }

    .cart-item-price-container{
        ${theme.breakpoints.up("sm")} {
          display:flex;
          align-items:center;
        }
    }

    }
  `}
`;
