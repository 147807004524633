import styled from "@mui/styled-engine-sc";
import TextField from "@mui/material/TextField";
import { dimensions } from "../../themes/variables";
import { palette } from "../../themes/color-palette";
import FormHelperText from "@mui/material/FormHelperText";
import { SvgClose } from "../../components/svg/close";
import { SvgSuccess } from "../../components/svg/success";
import { Input } from "@mui/material";
import { StyledBox } from "../box/styled-box";

const CustomTextField = (props: any) => {
  const textFieldProps = { ...props };
  delete textFieldProps.success;
  return (
    <>
      {textFieldProps.endAdornment ? (
        <Input {...textFieldProps} />
      ) : (
        <>
          <TextField variant="outlined" {...textFieldProps} helperText="" />
          {props.error && (
            <FormHelperText id={`erreur-${props.id || "input"}`} error sx={{ display: "flex", alignItems: "center" }}>
              <SvgClose className="error" />
              <StyledBox component="span" sx={{ color: "primary.main" }}>
                {props.helperText}
              </StyledBox>
            </FormHelperText>
          )}
        </>
      )}

      {/* {props.error && (
        <FormHelperText id={`erreur-${props.id || "input"}`} error sx={{ display: "flex", alignItems: "center" }}>
          <SvgClose className="error" />
          {props.helperText}
        </FormHelperText>
      )} */}
      {props.success && (
        <FormHelperText
          id={`succes-${props.id || "input"}`}
          sx={{ display: "flex", alignItems: "center", color: palette.success.main }}>
          <SvgSuccess className="success" />
          {props.helperText}
        </FormHelperText>
      )}
    </>
  );
};

const StyledTextField = styled(CustomTextField)`
  ${({ theme }) => `
  //margin: 0;


  .MuiOutlinedInput-root {
    &:hover {
      &:not(.Mui-focused) .MuiOutlinedInput-notchedOutline {
        border-color: ${theme.palette.text.secondary};
      }
      .MuiInputLabel-outlined {
        color: ${theme.palette.primary.main};
      }
    }

    &.Mui-focused fieldset {
      border: 1px solid currentcolor;
    }

    legend {
      width: 0px !important;
    }
  }



  .MuiOutlinedInput-input {
    padding-top: 0;
    padding-bottom: 0;
    font-weight: 500;
    color: ${theme.palette.text.primary};
    height: ${dimensions.inputFields.height}px;
    background: ${palette.white.main};
    border-radius: ${theme.shape.borderRadius}px;
    //border: ${palette.gray.neutralGray600} 1px solid;
  }

  .MuiInputLabel-outlined {
    transform: none;
    position: relative;
    margin-bottom: ${theme.spacing(1)};
    font-weight: 500;
    color: ${theme.palette.text.secondary};
    z-index: unset;
    pointer-events: unset;

    &.Mui-focused {
      color: ${theme.palette.text.primary};
      border-color: ${theme.palette.text.primary};

    }

    &.Mui-disabled {
      color: ${theme.palette.text.disabled};
    }
  }

  .MuiFormHelperText-contained {
    margin: ${theme.spacing(1)} 0 0 ${theme.spacing(4)};
    color: ${theme.palette.primary.main};
    &:before {
      position: absolute;
      content: "";
      background-color: ${theme.palette.gray.neutralGray600};
    }
  }

  .MuiOutlinedInput-root.Mui-disabled {
    color: ${theme.palette.text.disabled};

    &:hover {
      .MuiOutlinedInput-notchedOutline {
        border-color: ${theme.palette.text.disabled};
      }
    }

    .MuiOutlinedInput-input {
      color: ${theme.palette.text.disabled};
      background: ${theme.palette.action.disabledBackground};
      border-radius: ${theme.shape.borderRadius}px;
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: ${theme.palette.text.disabled};
    }
  }

  .MuiInputBase-input {
    &::placeholder {
      color: ${theme.palette.gray.neutralGray600};
      opacity: 1;
    }
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  input[type=number] {
    -moz-appearance: textfield;
    appearance: textfield;
  }


  .MuiInputBase-inputSizeSmall {
    height: 40px;
  }

  &.border-gray-300 {
    .MuiInputLabel-outlined {
      color: ${palette.gray.neutralGray600};
    }
    .MuiOutlinedInput-input {
      //border: ${palette.gray.neutralGray300} 1px solid;
    }
    .MuiOutlinedInput-root.Mui-focused fieldset {
      border-color: ${palette.gray.neutralGray300};
    }

    .MuiOutlinedInput-root:hover {
      .MuiOutlinedInput-notchedOutline {
        border-color: ${palette.gray.neutralGray300};
      }
    }
    &.text-big {
      background: ${palette.white.main};
      border-radius: 8px;

      .MuiOutlinedInput-root {
        padding: ${theme.spacing(2.5)} ${theme.spacing(3)};
      }
      .MuiInputBase-input {
        font-size: 20px;
        font-weight: 700;
        margin-top: 0;
        border-radius: 0;
      }
    }
  }

  &.border-gray-500 {
    .MuiOutlinedInput-input {
      &::placeholder {
        color: ${palette.gray.neutralGray900};
      }
    }
    .MuiInputLabel-outlined {
      color: ${palette.gray.neutralGray900};
    }
    .MuiOutlinedInput-root.Mui-focused fieldset {
      border-color: ${palette.gray.neutralGray500};
      border-radius: 0;
    }

    .MuiOutlinedInput-root:hover {
      .MuiOutlinedInput-notchedOutline {
        border-color: ${palette.gray.neutralGray500};
      }
    }
  }

  label.Mui-required {
    .MuiInputLabel-asterisk {
      display: none;
    }
  }

  &.underline-gray {
    .MuiInputLabel-root {
      color: ${palette.gray.neutralGray600};
      font-weight: 400;

      &.Mui-disabled {
        transform: translate(0, -1.5px) scale(0.75);
      }
    }
    .MuiInputBase-root {
      height: auto;
      &:before {
        border-bottom: ${palette.gray.neutralGray400} 1px solid;
      }
      &:hover {
        &:not(.Mui-disabled) {
          &:after {
            border-bottom: ${palette.gray.neutralGray500} 1px solid;
          }
        }
      }
      &:after {
        border-bottom: ${palette.gray.neutralGray400} 1px solid;
      }
      &.Mui-error {
        &:before {
          border-bottom: 0;
        }
        &:after {
          border-bottom-color: ${palette.primary.main};
        }
      }
      .MuiInputBase-input {
        font-weight: 400;

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus {
          -webkit-box-shadow: 0 0 0px 1000px ${palette.blue.extraLight} inset !important;
        }
      }
    }
    &.text-big {
      .MuiInputBase-input {
        font-size: 20px;
        text-transform: uppercase;
        font-weight: 700;
        margin-top: 0;
      }
    }
    &.bg-beige {
      .MuiInputBase-input {
        font-weight: 400;

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus {
          -webkit-box-shadow: 0 0 0px 1000px ${palette.beige.light} inset !important;
        }
      }
    }
    &.optional {
      .MuiInput-root {
        &:before {
          border-bottom: ${palette.gray.neutralGray300} 1px solid;
        }
        &:after {
          border-bottom: ${palette.gray.neutralGray300} 1px solid;
        }
      }
    }
  }
  `}
`;

export { StyledTextField };
