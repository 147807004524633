/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
import styled from "@mui/styled-engine-sc";
import Popper from "@mui/material/Popper";
import React from "react";

const mods = [
  { name: "flip", enabled: false },
  { name: "preventOverflow", enabled: false },
  { name: "hide", enabled: true },
];

const CustomPopper = React.forwardRef((props: any, ref: any) => {
  const { modifiers = [], ...rest } = props;
  const _modifiers = [
    ...mods
      .concat(modifiers)
      .reduce((m, o) => m.set(o.name, Object.assign(m.get(o.name) || {}, o)), new Map())
      .values(),
  ];
  return <Popper {...rest} modifiers={_modifiers} ref={ref} />;
});

const StyledPopper = styled(CustomPopper)`
  ${({ theme }) => `
    &.popper-with-arrow {
      z-index: 1;
      position: relative;
      background: ${theme.palette.white.main};
      border-radius: ${theme.shape.borderRadius}px;
      box-shadow: 0px 2px 6px 0px #25242740, 0px 0px 12px 0px rgba(0, 70, 218, 0.12);
      border: 1px solid ${theme.palette.gray.neutralGray300};
      max-width: 300px;
      margin-right: 15px !important;
      margin-top: -20px !important;

      &.big {
        max-width: 320px;
      }

      &.bottom {
        margin-top: 16px !important;
        margin-right: 0 !important;
        margin-left: -220px !important;
      }

      &.mobile {
        top: 122px !important;
        right: auto !important;
        left: 20px !important;
      }

      &.desktop {
        top: 130px !important;
        right: 300px !important;
        left: auto !important;
      }

      &.text-left {
        text-align: left;
      }

      &.bottom-left {
        margin-top: 12px !important;
        margin-right: 0 !important;
        margin-left: -20px !important;
        z-index: 88;

        ${theme.breakpoints.down("md")} {
          margin-right: -20px !important;
        }
      }

      .arrow-right {
        right: 0;
        top: 18px !important;
        margin-right: -8px;
        height: 16px;
        width: 8px;
        position: absolute;

        &:before, &:after {
          position: absolute;
          content: "";
          margin: auto;
          display: block;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 8px 0 8px 8px;
          border-color: transparent;
        }
        &:before {
          border-left-color: ${theme.palette.gray.neutralGray300};
        }
        &:after {
          right: 1px;
          border-left-color: ${theme.palette.white.main};
        }
      }

      .arrow-top {
        top: -8px !important;
        left: auto !important;
        right: 40px !important;
        height: 8px;
        width: 16px;
        position: absolute;

        &:before, &:after {
          position: absolute;
          content: "";
          margin: auto;
          display: block;
          width: 0;
          height: 0;
          border-style: solid;
          border-width:  0 8px 8px 8px;
          border-color: transparent;
        }
        &:before {
          border-bottom-color: ${theme.palette.gray.neutralGray300};
        }
        &:after {
          top: 1px;
          border-bottom-color: ${theme.palette.white.main};
        }

        &.left {
          left: 18px !important;
          right: auto !important;
        }

        &.right {
          right: 18px !important;
          left: auto !important;

          ${theme.breakpoints.down("md")} {
            left: -18px !important;
            right: auto !important;
            transform: translate3d(0px, 0px, 0px);
          }
        }
      }

      .popper-close {
        position: abolute;
        right: 0;
        top: 0;
      }
    }
    `}
`;

export { StyledPopper };
