import useSWR from "swr";
import orderService from "../../../_foundation/apis/transaction/order.service";
import { swrOptionsNoRetry } from "./constants";
import storeLocatorService from "../../../_foundation/apis/transaction/storeLocator.service";
// import { DOCUMENT_TYPE } from "../../../constants/order";

export const useCustomerOrders = (storeId: any, idCRM: any, type) => {
  const payload = {
    widget: "useCustomerOrders",
    body: {
      idCRM: idCRM,
      type: type,
      langId: "-2",
      storeId: storeId,
    },
    langId: null,
  };
  // TODO SET NEW API FROM SOLVEDA
  const wait = false;

  return useSWR(!wait ? payload : null, orderService.getCustomerOrders, swrOptionsNoRetry);
};

export const useCustomerOrdersById = (payloadBase, order, type) => {
  const payload = {
    orderId: order?.documentId?.includes("_") ? order?.documentId?.split("_")[1] : order?.documentRef,
    langId: null,
    shopId: order?.magasinCreation,
    ...payloadBase,
  };
  if (type) {
    payload.query = { docType: type };
  }
  // TODO SET NEW API FROM SOLVEDA
  const wait = !order ? true : false;

  return useSWR(!wait ? payload : null, orderService.getCustomerOrdersById, swrOptionsNoRetry);
};

export const useStoreDetailByStoreId = (payloadBase, physicalStoreId) => {
  const payload = {
    physicalStoreId: physicalStoreId,
    ...payloadBase,
  };
  // TODO SET NEW API FROM SOLVEDA
  const wait = physicalStoreId && physicalStoreId.length > 0 ? false : true;

  return useSWR(!wait ? payload : null, storeLocatorService.findByStoreUniqueIds, swrOptionsNoRetry);
};

export const useCustomerDocumentOrder = (documentId) => {
  // const payload = {
  //   widget: "useCustomerDocumentById",
  //   documentId,
  //   langId: null,
  // };

  return {
    isLoading: false,
    data: {
      data: {
        Customer_Document: {
          dateMiseADispo: "2024-05-16T00:00:00.000",
          savInfos: {
            numDossier: null,
          },
          codeTransport: null,
          type: "C",
          montantFinancement: null,
          mntPromoTtc: 0,
          precDocumentCreationDate: null,
          modeReglement: "E",
          precDocumentType: null,
          dateDisponibiliteReelle: null,
          montantNetHT: 4230.05,
          vrProject: false,
          creationDate: "2024-03-13T03:18:07.000",
          universDoc: null,
          codeOrganismeFinancement: null,
          webId: null,
          acompteRegle: 3000,
          magasinCreation: "0028",
          refCommandeArtisan: null,
          universProduit: null,
          agentModificateur: "Administrateur (002800)",
          suiviInstallation: {
            numeroChantierInstallation: null,
            statutInstallation: null,
            codeStatutInstallation: null,
            dateInstallation: null,
          },
          items: [
            {
              remiseTTC: 0,
              codeCategorieArticle: "G3469",
              sourceconfigurateur: null,
              codeSousFamille: "G3469",
              infoFournisseur: null,
              origineADV: "N",
              grandeFamille: "FENETRES",
              prixUnitVenteHT: 13.33,
              ecoPartTTC: null,
              libelleArticle: null,
              famille: "PRODUITS DE MISE EN OEUVRE FENETRES",
              dateDisponibiliteReelle: null,
              tva: 2.67,
              montantPromoHT: 0,
              quantite: 1,
              montantNetHT: 13.33,
              numeroLigne: 3,
              codeArticle: "2570090",
              nomArticle: "ROUL.ETANCH. TP600 10/1.5-3 GRIS 12.5M",
              cpv: false,
              numeroLigneParent: null,
              prixUnitCatTTC: 16,
              criteres: [
                {
                  codeCritere: "FAM",
                  valeurCritere: "020",
                },
                {
                  codeCritere: "ART_TYPE",
                  valeurCritere: "ORD",
                },
              ],
              version: null,
              tauxTVA: 20,
              categorieArticle: "ETANCHEITE",
              modeADV: "",
              sousFamille: "ETANCHEITE",
              tauxPromotion: null,
              unite: "UN",
              numeroCommandeVente: null,
              ecoPartHT: null,
              codeFamille: "G654",
              typePromo: null,
              parentLine: null,
              prixUnitAchatHT: 0,
              nombreIterationRetard: null,
              montantNetTTC: 16,
              prixUnitVenteTTC: 16,
              codeGrandeFamille: "G13",
              magasinOrigine: null,
              codePromotion: null,
              libellePromotion: null,
              prixUnitNetHT: 13.33,
              prixUnitCatHT: 13.33,
              typeArticle: "ORDINAIRE",
              numeroDevis: null,
              montantPromoTTC: 0,
              dateMADMagasin: "2024-03-20T00:00:00.000",
              numeroPosteCommandeVente: null,
              prixUnitNetTTC: 16,
              infoStatutLigne: "ALF",
              documentId: "C_24000195_0028",
              commentaires: null,
              dateModTech: "2024-03-15T10:15:35.416",
            },
            {
              remiseTTC: 0,
              codeCategorieArticle: "G3467",
              sourceconfigurateur: null,
              codeSousFamille: "G3467",
              infoFournisseur: null,
              origineADV: "N",
              grandeFamille: "FENETRES",
              prixUnitVenteHT: 55,
              ecoPartTTC: null,
              libelleArticle: null,
              famille: "PRODUITS DE MISE EN OEUVRE FENETRES",
              dateDisponibiliteReelle: null,
              tva: 11,
              montantPromoHT: 0,
              quantite: 1,
              montantNetHT: 55,
              numeroLigne: 4,
              codeArticle: "2540010",
              nomArticle: "KIT ETANCHEITE MENUIS. EXT POSE APPLIQUE",
              cpv: false,
              numeroLigneParent: null,
              prixUnitCatTTC: 66,
              criteres: [
                {
                  codeCritere: "FAM",
                  valeurCritere: "020",
                },
                {
                  codeCritere: "ART_TYPE",
                  valeurCritere: "ORD",
                },
              ],
              version: null,
              tauxTVA: 20,
              categorieArticle: "KIT DE POSE FENETRE",
              modeADV: "",
              sousFamille: "KIT DE POSE FENETRE",
              tauxPromotion: null,
              unite: "UN",
              numeroCommandeVente: null,
              ecoPartHT: null,
              codeFamille: "G654",
              typePromo: null,
              parentLine: null,
              prixUnitAchatHT: 0,
              nombreIterationRetard: null,
              montantNetTTC: 66,
              prixUnitVenteTTC: 66,
              codeGrandeFamille: "G13",
              magasinOrigine: null,
              codePromotion: null,
              libellePromotion: null,
              prixUnitNetHT: 55,
              prixUnitCatHT: 55,
              typeArticle: "Non utilisé",
              numeroDevis: null,
              montantPromoTTC: 0,
              dateMADMagasin: "2024-03-20T00:00:00.000",
              numeroPosteCommandeVente: null,
              prixUnitNetTTC: 66,
              infoStatutLigne: "ALF",
              documentId: "C_24000195_0028",
              commentaires: null,
              dateModTech: "2024-03-15T10:15:35.416",
            },
            {
              remiseTTC: 0,
              codeCategorieArticle: null,
              sourceconfigurateur: "NEC",
              codeSousFamille: null,
              infoFournisseur: null,
              origineADV: "N",
              grandeFamille: null,
              prixUnitVenteHT: 1447.92,
              ecoPartTTC: null,
              libelleArticle: "DIMENSIONS H 1000x L 1000 1 VTL - Uw: 1.",
              famille: null,
              dateDisponibiliteReelle: null,
              tva: 289.58,
              montantPromoHT: 0,
              quantite: 1,
              montantNetHT: 1447.92,
              numeroLigne: 1,
              codeArticle: "2640440",
              nomArticle: "MEN.FRAPPE OC HERA ALU+VRI AUTRE COULEUR",
              cpv: false,
              numeroLigneParent: null,
              prixUnitCatTTC: 1737.5,
              criteres: [
                {
                  codeCritere: "FAM",
                  valeurCritere: "020",
                },
                {
                  codeCritere: "ART_TYPE",
                  valeurCritere: "STA",
                },
              ],
              version: "000001",
              tauxTVA: 20,
              categorieArticle: null,
              modeADV: "",
              sousFamille: null,
              tauxPromotion: null,
              unite: "UN",
              numeroCommandeVente: "001844",
              ecoPartHT: null,
              codeFamille: null,
              typePromo: null,
              parentLine: null,
              prixUnitAchatHT: 0,
              nombreIterationRetard: null,
              montantNetTTC: 1737.5,
              prixUnitVenteTTC: 1737.5,
              codeGrandeFamille: null,
              magasinOrigine: null,
              codePromotion: null,
              libellePromotion: null,
              prixUnitNetHT: 1447.92,
              prixUnitCatHT: 1447.92,
              typeArticle: null,
              numeroDevis: null,
              montantPromoTTC: 0,
              dateMADMagasin: "2024-05-16T00:00:00.000",
              numeroPosteCommandeVente: 7088,
              prixUnitNetTTC: 1737.5,
              infoStatutLigne: "ALF",
              documentId: "C_24000195_0028",
              commentaires: {
                libelle1Commentaire: "DIMENSIONS H 1000x L 1000 1 VTL - Uw: 1.",
                dateCommentaire: null,
                libelle2Commentaire: "3 W/m2.K - Sw: 0.46 - MONTANT ELIGIBLE M",
                libelle3Commentaire: "A PRIME RENOV'",
                libelle4Commentaire: null,
              },
              dateModTech: "2024-03-15T10:15:35.416",
            },
            {
              remiseTTC: 0,
              codeCategorieArticle: "G622",
              sourceconfigurateur: null,
              codeSousFamille: "G48",
              infoFournisseur: null,
              origineADV: null,
              grandeFamille: "AUTRES",
              prixUnitVenteHT: 0.6,
              ecoPartTTC: null,
              libelleArticle: null,
              famille: "DIVERS",
              dateDisponibiliteReelle: null,
              tva: 0.12,
              montantPromoHT: 0,
              quantite: 1,
              montantNetHT: 0.6,
              numeroLigne: 2,
              codeArticle: "0609999",
              nomArticle: "ECO-PMCB CODE INTERNE BLOC-BAIE",
              cpv: false,
              numeroLigneParent: null,
              prixUnitCatTTC: 0.72,
              criteres: [
                {
                  codeCritere: "FAM",
                  valeurCritere: "001",
                },
                {
                  codeCritere: "ART_TYPE",
                  valeurCritere: "EXV",
                },
              ],
              version: null,
              tauxTVA: 20,
              categorieArticle: "ECO-PARTICIPATION (PMCB)",
              modeADV: null,
              sousFamille: "DIVERS",
              tauxPromotion: null,
              unite: "UN",
              numeroCommandeVente: null,
              ecoPartHT: null,
              codeFamille: "G7",
              typePromo: null,
              parentLine: null,
              prixUnitAchatHT: 0,
              nombreIterationRetard: null,
              montantNetTTC: 0.72,
              prixUnitVenteTTC: 0.72,
              codeGrandeFamille: "G9",
              magasinOrigine: null,
              codePromotion: null,
              libellePromotion: null,
              prixUnitNetHT: 0.6,
              prixUnitCatHT: 0.6,
              typeArticle: "VENTE EXCLUSIVE",
              numeroDevis: null,
              montantPromoTTC: 0,
              dateMADMagasin: null,
              numeroPosteCommandeVente: null,
              prixUnitNetTTC: 0.72,
              infoStatutLigne: null,
              documentId: "C_24000195_0028",
              commentaires: null,
              dateModTech: "2024-03-15T10:15:35.416",
            },
            {
              remiseTTC: 0,
              codeCategorieArticle: "G622",
              sourceconfigurateur: null,
              codeSousFamille: "G48",
              infoFournisseur: null,
              origineADV: null,
              grandeFamille: "AUTRES",
              prixUnitVenteHT: 0.6,
              ecoPartTTC: null,
              libelleArticle: null,
              famille: "DIVERS",
              dateDisponibiliteReelle: null,
              tva: 0.12,
              montantPromoHT: 0,
              quantite: 1,
              montantNetHT: 0.6,
              numeroLigne: 12,
              codeArticle: "0609999",
              nomArticle: "ECO-PMCB CODE INTERNE BLOC-BAIE",
              cpv: false,
              numeroLigneParent: null,
              prixUnitCatTTC: 0.72,
              criteres: [
                {
                  codeCritere: "FAM",
                  valeurCritere: "001",
                },
                {
                  codeCritere: "ART_TYPE",
                  valeurCritere: "EXV",
                },
              ],
              version: null,
              tauxTVA: 20,
              categorieArticle: "ECO-PARTICIPATION (PMCB)",
              modeADV: null,
              sousFamille: "DIVERS",
              tauxPromotion: null,
              unite: "UN",
              numeroCommandeVente: null,
              ecoPartHT: null,
              codeFamille: "G7",
              typePromo: null,
              parentLine: null,
              prixUnitAchatHT: 0,
              nombreIterationRetard: null,
              montantNetTTC: 0.72,
              prixUnitVenteTTC: 0.72,
              codeGrandeFamille: "G9",
              magasinOrigine: null,
              codePromotion: null,
              libellePromotion: null,
              prixUnitNetHT: 0.6,
              prixUnitCatHT: 0.6,
              typeArticle: "VENTE EXCLUSIVE",
              numeroDevis: null,
              montantPromoTTC: 0,
              dateMADMagasin: null,
              numeroPosteCommandeVente: null,
              prixUnitNetTTC: 0.72,
              infoStatutLigne: null,
              documentId: "C_24000195_0028",
              commentaires: null,
              dateModTech: "2024-03-15T10:15:35.416",
            },
            {
              remiseTTC: 0,
              codeCategorieArticle: "G53952",
              sourceconfigurateur: null,
              codeSousFamille: "G2646",
              infoFournisseur: null,
              origineADV: "N",
              grandeFamille: "FENETRES",
              prixUnitVenteHT: 9.08,
              ecoPartTTC: null,
              libelleArticle: null,
              famille: "ACCESSOIRES FENETRES",
              dateDisponibiliteReelle: "2024-03-14T00:00:00.000",
              tva: 1.82,
              montantPromoHT: 0,
              quantite: 1,
              montantNetHT: 9.08,
              numeroLigne: 13,
              codeArticle: "2182620",
              nomArticle: "POIGNEE SECURITE BLC 9016 1 VTL PVC/BOIS",
              cpv: false,
              numeroLigneParent: null,
              prixUnitCatTTC: 10.9,
              criteres: [
                {
                  codeCritere: "FAM",
                  valeurCritere: "020",
                },
                {
                  codeCritere: "ART_TYPE",
                  valeurCritere: "ORD",
                },
              ],
              version: null,
              tauxTVA: 20,
              categorieArticle: "POIGNEES",
              modeADV: "",
              sousFamille: "ACCESSOIRES FENETRES",
              tauxPromotion: null,
              unite: "UN",
              numeroCommandeVente: null,
              ecoPartHT: null,
              codeFamille: "G123",
              typePromo: null,
              parentLine: null,
              prixUnitAchatHT: 0,
              nombreIterationRetard: null,
              montantNetTTC: 10.9,
              prixUnitVenteTTC: 10.9,
              codeGrandeFamille: "G13",
              magasinOrigine: null,
              codePromotion: null,
              libellePromotion: null,
              prixUnitNetHT: 9.08,
              prixUnitCatHT: 9.08,
              typeArticle: "ORDINAIRE",
              numeroDevis: null,
              montantPromoTTC: 0,
              dateMADMagasin: null,
              numeroPosteCommandeVente: null,
              prixUnitNetTTC: 10.9,
              infoStatutLigne: "DIS",
              documentId: "C_24000195_0028",
              commentaires: null,
              dateModTech: "2024-03-15T10:15:35.416",
            },
            {
              remiseTTC: 0,
              codeCategorieArticle: "G3467",
              sourceconfigurateur: null,
              codeSousFamille: "G3467",
              infoFournisseur: null,
              origineADV: "N",
              grandeFamille: "FENETRES",
              prixUnitVenteHT: 55,
              ecoPartTTC: null,
              libelleArticle: null,
              famille: "PRODUITS DE MISE EN OEUVRE FENETRES",
              dateDisponibiliteReelle: null,
              tva: 11,
              montantPromoHT: 0,
              quantite: 1,
              montantNetHT: 55,
              numeroLigne: 10,
              codeArticle: "2540010",
              nomArticle: "KIT ETANCHEITE MENUIS. EXT POSE APPLIQUE",
              cpv: false,
              numeroLigneParent: null,
              prixUnitCatTTC: 66,
              criteres: [
                {
                  codeCritere: "FAM",
                  valeurCritere: "020",
                },
                {
                  codeCritere: "ART_TYPE",
                  valeurCritere: "ORD",
                },
              ],
              version: null,
              tauxTVA: 20,
              categorieArticle: "KIT DE POSE FENETRE",
              modeADV: "",
              sousFamille: "KIT DE POSE FENETRE",
              tauxPromotion: null,
              unite: "UN",
              numeroCommandeVente: null,
              ecoPartHT: null,
              codeFamille: "G654",
              typePromo: null,
              parentLine: null,
              prixUnitAchatHT: 0,
              nombreIterationRetard: null,
              montantNetTTC: 66,
              prixUnitVenteTTC: 66,
              codeGrandeFamille: "G13",
              magasinOrigine: null,
              codePromotion: null,
              libellePromotion: null,
              prixUnitNetHT: 55,
              prixUnitCatHT: 55,
              typeArticle: "Non utilisé",
              numeroDevis: null,
              montantPromoTTC: 0,
              dateMADMagasin: "2024-03-20T00:00:00.000",
              numeroPosteCommandeVente: null,
              prixUnitNetTTC: 66,
              infoStatutLigne: "ALF",
              documentId: "C_24000195_0028",
              commentaires: null,
              dateModTech: "2024-03-15T10:15:35.416",
            },
            {
              remiseTTC: 0,
              codeCategorieArticle: null,
              sourceconfigurateur: "NEC",
              codeSousFamille: null,
              infoFournisseur: null,
              origineADV: "N",
              grandeFamille: null,
              prixUnitVenteHT: 1068.33,
              ecoPartTTC: null,
              libelleArticle: "DIMENSIONS H 1000x L 1000 1 VTL - Uw: 1.",
              famille: null,
              dateDisponibiliteReelle: null,
              tva: 213.67,
              montantPromoHT: 0,
              quantite: 1,
              montantNetHT: 1068.33,
              numeroLigne: 11,
              codeArticle: "2640400",
              nomArticle: "MEN.FRAPPE GRAPHIQUE ALU+VRI COUL. PREF",
              cpv: false,
              numeroLigneParent: null,
              prixUnitCatTTC: 1282,
              criteres: [
                {
                  codeCritere: "FAM",
                  valeurCritere: "020",
                },
                {
                  codeCritere: "ART_TYPE",
                  valeurCritere: "STA",
                },
              ],
              version: "000001",
              tauxTVA: 20,
              categorieArticle: null,
              modeADV: "",
              sousFamille: null,
              tauxPromotion: null,
              unite: "UN",
              numeroCommandeVente: "001844",
              ecoPartHT: null,
              codeFamille: null,
              typePromo: null,
              parentLine: null,
              prixUnitAchatHT: 0,
              nombreIterationRetard: null,
              montantNetTTC: 1282,
              prixUnitVenteTTC: 1282,
              codeGrandeFamille: null,
              magasinOrigine: null,
              codePromotion: null,
              libellePromotion: null,
              prixUnitNetHT: 1068.33,
              prixUnitCatHT: 1068.33,
              typeArticle: null,
              numeroDevis: null,
              montantPromoTTC: 0,
              dateMADMagasin: "2024-05-16T00:00:00.000",
              numeroPosteCommandeVente: 7086,
              prixUnitNetTTC: 1282,
              infoStatutLigne: "ALF",
              documentId: "C_24000195_0028",
              commentaires: {
                libelle1Commentaire: "DIMENSIONS H 1000x L 1000 1 VTL - Uw: 1.",
                dateCommentaire: null,
                libelle2Commentaire: "3 W/m2.K - Sw: 0.46 - MONTANT ELIGIBLE M",
                libelle3Commentaire: "A PRIME RENOV'",
                libelle4Commentaire: null,
              },
              dateModTech: "2024-03-15T10:15:35.416",
            },
            {
              remiseTTC: 0,
              codeCategorieArticle: "G3469",
              sourceconfigurateur: null,
              codeSousFamille: "G3469",
              infoFournisseur: null,
              origineADV: "N",
              grandeFamille: "FENETRES",
              prixUnitVenteHT: 16.67,
              ecoPartTTC: null,
              libelleArticle: null,
              famille: "PRODUITS DE MISE EN OEUVRE FENETRES",
              dateDisponibiliteReelle: null,
              tva: 3.33,
              montantPromoHT: 0,
              quantite: 1,
              montantNetHT: 16.67,
              numeroLigne: 9,
              codeArticle: "2567440",
              nomArticle: "MEMBRANE ADHESIVE ME508 70MM",
              cpv: false,
              numeroLigneParent: null,
              prixUnitCatTTC: 20,
              criteres: [
                {
                  codeCritere: "FAM",
                  valeurCritere: "020",
                },
                {
                  codeCritere: "ART_TYPE",
                  valeurCritere: "ORD",
                },
              ],
              version: null,
              tauxTVA: 20,
              categorieArticle: "ETANCHEITE",
              modeADV: "",
              sousFamille: "ETANCHEITE",
              tauxPromotion: null,
              unite: "UN",
              numeroCommandeVente: null,
              ecoPartHT: null,
              codeFamille: "G654",
              typePromo: null,
              parentLine: null,
              prixUnitAchatHT: 0,
              nombreIterationRetard: null,
              montantNetTTC: 20,
              prixUnitVenteTTC: 20,
              codeGrandeFamille: "G13",
              magasinOrigine: null,
              codePromotion: null,
              libellePromotion: null,
              prixUnitNetHT: 16.67,
              prixUnitCatHT: 16.67,
              typeArticle: "STANDARD",
              numeroDevis: null,
              montantPromoTTC: 0,
              dateMADMagasin: "2024-03-20T00:00:00.000",
              numeroPosteCommandeVente: null,
              prixUnitNetTTC: 20,
              infoStatutLigne: "ALF",
              documentId: "C_24000195_0028",
              commentaires: null,
              dateModTech: "2024-03-15T10:15:35.416",
            },
            {
              remiseTTC: 0,
              codeCategorieArticle: "G3469",
              sourceconfigurateur: null,
              codeSousFamille: "G3469",
              infoFournisseur: null,
              origineADV: "N",
              grandeFamille: "FENETRES",
              prixUnitVenteHT: 16.67,
              ecoPartTTC: null,
              libelleArticle: null,
              famille: "PRODUITS DE MISE EN OEUVRE FENETRES",
              dateDisponibiliteReelle: null,
              tva: 3.33,
              montantPromoHT: 0,
              quantite: 1,
              montantNetHT: 16.67,
              numeroLigne: 16,
              codeArticle: "2567440",
              nomArticle: "MEMBRANE ADHESIVE ME508 70MM",
              cpv: false,
              numeroLigneParent: null,
              prixUnitCatTTC: 20,
              criteres: [
                {
                  codeCritere: "FAM",
                  valeurCritere: "020",
                },
                {
                  codeCritere: "ART_TYPE",
                  valeurCritere: "ORD",
                },
              ],
              version: null,
              tauxTVA: 20,
              categorieArticle: "ETANCHEITE",
              modeADV: "",
              sousFamille: "ETANCHEITE",
              tauxPromotion: null,
              unite: "UN",
              numeroCommandeVente: null,
              ecoPartHT: null,
              codeFamille: "G654",
              typePromo: null,
              parentLine: null,
              prixUnitAchatHT: 0,
              nombreIterationRetard: null,
              montantNetTTC: 20,
              prixUnitVenteTTC: 20,
              codeGrandeFamille: "G13",
              magasinOrigine: null,
              codePromotion: null,
              libellePromotion: null,
              prixUnitNetHT: 16.67,
              prixUnitCatHT: 16.67,
              typeArticle: "STANDARD",
              numeroDevis: null,
              montantPromoTTC: 0,
              dateMADMagasin: "2024-03-20T00:00:00.000",
              numeroPosteCommandeVente: null,
              prixUnitNetTTC: 20,
              infoStatutLigne: "ALF",
              documentId: "C_24000195_0028",
              commentaires: null,
              dateModTech: "2024-03-15T10:15:35.416",
            },
            {
              remiseTTC: 0,
              codeCategorieArticle: "G622",
              sourceconfigurateur: null,
              codeSousFamille: "G48",
              infoFournisseur: null,
              origineADV: null,
              grandeFamille: "AUTRES",
              prixUnitVenteHT: 0.6,
              ecoPartTTC: null,
              libelleArticle: null,
              famille: "DIVERS",
              dateDisponibiliteReelle: null,
              tva: 0.12,
              montantPromoHT: 0,
              quantite: 1,
              montantNetHT: 0.6,
              numeroLigne: 7,
              codeArticle: "0609999",
              nomArticle: "ECO-PMCB CODE INTERNE BLOC-BAIE",
              cpv: false,
              numeroLigneParent: null,
              prixUnitCatTTC: 0.72,
              criteres: [
                {
                  codeCritere: "FAM",
                  valeurCritere: "001",
                },
                {
                  codeCritere: "ART_TYPE",
                  valeurCritere: "EXV",
                },
              ],
              version: null,
              tauxTVA: 20,
              categorieArticle: "ECO-PARTICIPATION (PMCB)",
              modeADV: null,
              sousFamille: "DIVERS",
              tauxPromotion: null,
              unite: "UN",
              numeroCommandeVente: null,
              ecoPartHT: null,
              codeFamille: "G7",
              typePromo: null,
              parentLine: null,
              prixUnitAchatHT: 0,
              nombreIterationRetard: null,
              montantNetTTC: 0.72,
              prixUnitVenteTTC: 0.72,
              codeGrandeFamille: "G9",
              magasinOrigine: null,
              codePromotion: null,
              libellePromotion: null,
              prixUnitNetHT: 0.6,
              prixUnitCatHT: 0.6,
              typeArticle: "VENTE EXCLUSIVE",
              numeroDevis: null,
              montantPromoTTC: 0,
              dateMADMagasin: null,
              numeroPosteCommandeVente: null,
              prixUnitNetTTC: 0.72,
              infoStatutLigne: null,
              documentId: "C_24000195_0028",
              commentaires: null,
              dateModTech: "2024-03-15T10:15:35.416",
            },
            {
              remiseTTC: 0,
              codeCategorieArticle: "G3469",
              sourceconfigurateur: null,
              codeSousFamille: "G3469",
              infoFournisseur: null,
              origineADV: "N",
              grandeFamille: "FENETRES",
              prixUnitVenteHT: 13.33,
              ecoPartTTC: null,
              libelleArticle: null,
              famille: "PRODUITS DE MISE EN OEUVRE FENETRES",
              dateDisponibiliteReelle: null,
              tva: 2.67,
              montantPromoHT: 0,
              quantite: 1,
              montantNetHT: 13.33,
              numeroLigne: 17,
              codeArticle: "2570090",
              nomArticle: "ROUL.ETANCH. TP600 10/1.5-3 GRIS 12.5M",
              cpv: false,
              numeroLigneParent: null,
              prixUnitCatTTC: 16,
              criteres: [
                {
                  codeCritere: "FAM",
                  valeurCritere: "020",
                },
                {
                  codeCritere: "ART_TYPE",
                  valeurCritere: "ORD",
                },
              ],
              version: null,
              tauxTVA: 20,
              categorieArticle: "ETANCHEITE",
              modeADV: "",
              sousFamille: "ETANCHEITE",
              tauxPromotion: null,
              unite: "UN",
              numeroCommandeVente: null,
              ecoPartHT: null,
              codeFamille: "G654",
              typePromo: null,
              parentLine: null,
              prixUnitAchatHT: 0,
              nombreIterationRetard: null,
              montantNetTTC: 16,
              prixUnitVenteTTC: 16,
              codeGrandeFamille: "G13",
              magasinOrigine: null,
              codePromotion: null,
              libellePromotion: null,
              prixUnitNetHT: 13.33,
              prixUnitCatHT: 13.33,
              typeArticle: "ORDINAIRE",
              numeroDevis: null,
              montantPromoTTC: 0,
              dateMADMagasin: "2024-03-20T00:00:00.000",
              numeroPosteCommandeVente: null,
              prixUnitNetTTC: 16,
              infoStatutLigne: "ALF",
              documentId: "C_24000195_0028",
              commentaires: null,
              dateModTech: "2024-03-15T10:15:35.416",
            },
            {
              remiseTTC: 0,
              codeCategorieArticle: "G3469",
              sourceconfigurateur: null,
              codeSousFamille: "G3469",
              infoFournisseur: null,
              origineADV: "N",
              grandeFamille: "FENETRES",
              prixUnitVenteHT: 13.33,
              ecoPartTTC: null,
              libelleArticle: null,
              famille: "PRODUITS DE MISE EN OEUVRE FENETRES",
              dateDisponibiliteReelle: null,
              tva: 2.67,
              montantPromoHT: 0,
              quantite: 1,
              montantNetHT: 13.33,
              numeroLigne: 8,
              codeArticle: "2570090",
              nomArticle: "ROUL.ETANCH. TP600 10/1.5-3 GRIS 12.5M",
              cpv: false,
              numeroLigneParent: null,
              prixUnitCatTTC: 16,
              criteres: [
                {
                  codeCritere: "FAM",
                  valeurCritere: "020",
                },
                {
                  codeCritere: "ART_TYPE",
                  valeurCritere: "ORD",
                },
              ],
              version: null,
              tauxTVA: 20,
              categorieArticle: "ETANCHEITE",
              modeADV: "",
              sousFamille: "ETANCHEITE",
              tauxPromotion: null,
              unite: "UN",
              numeroCommandeVente: null,
              ecoPartHT: null,
              codeFamille: "G654",
              typePromo: null,
              parentLine: null,
              prixUnitAchatHT: 0,
              nombreIterationRetard: null,
              montantNetTTC: 16,
              prixUnitVenteTTC: 16,
              codeGrandeFamille: "G13",
              magasinOrigine: null,
              codePromotion: null,
              libellePromotion: null,
              prixUnitNetHT: 13.33,
              prixUnitCatHT: 13.33,
              typeArticle: "ORDINAIRE",
              numeroDevis: null,
              montantPromoTTC: 0,
              dateMADMagasin: "2024-03-20T00:00:00.000",
              numeroPosteCommandeVente: null,
              prixUnitNetTTC: 16,
              infoStatutLigne: "ALF",
              documentId: "C_24000195_0028",
              commentaires: null,
              dateModTech: "2024-03-15T10:15:35.416",
            },
            {
              remiseTTC: 0,
              codeCategorieArticle: "G622",
              sourceconfigurateur: null,
              codeSousFamille: "G48",
              infoFournisseur: null,
              origineADV: null,
              grandeFamille: "AUTRES",
              prixUnitVenteHT: 0,
              ecoPartTTC: null,
              libelleArticle: null,
              famille: "DIVERS",
              dateDisponibiliteReelle: null,
              tva: 0,
              montantPromoHT: 0,
              quantite: 1,
              montantNetHT: 0,
              numeroLigne: 14,
              codeArticle: "0610001",
              nomArticle: "ECO-PMCB QUINCAILLERIE FENETRE VOLETS",
              cpv: false,
              numeroLigneParent: null,
              prixUnitCatTTC: 0,
              criteres: [
                {
                  codeCritere: "FAM",
                  valeurCritere: "001",
                },
                {
                  codeCritere: "ART_TYPE",
                  valeurCritere: "EXV",
                },
              ],
              version: null,
              tauxTVA: 20,
              categorieArticle: "ECO-PARTICIPATION (PMCB)",
              modeADV: null,
              sousFamille: "DIVERS",
              tauxPromotion: null,
              unite: "UN",
              numeroCommandeVente: null,
              ecoPartHT: null,
              codeFamille: "G7",
              typePromo: null,
              parentLine: null,
              prixUnitAchatHT: 0,
              nombreIterationRetard: null,
              montantNetTTC: 0,
              prixUnitVenteTTC: 0,
              codeGrandeFamille: "G9",
              magasinOrigine: null,
              codePromotion: null,
              libellePromotion: null,
              prixUnitNetHT: 0,
              prixUnitCatHT: 0,
              typeArticle: "VENTE EXCLUSIVE",
              numeroDevis: null,
              montantPromoTTC: 0,
              dateMADMagasin: null,
              numeroPosteCommandeVente: null,
              prixUnitNetTTC: 0,
              infoStatutLigne: null,
              documentId: "C_24000195_0028",
              commentaires: null,
              dateModTech: "2024-03-15T10:15:35.416",
            },
            {
              remiseTTC: 0,
              codeCategorieArticle: "G3469",
              sourceconfigurateur: null,
              codeSousFamille: "G3469",
              infoFournisseur: null,
              origineADV: "N",
              grandeFamille: "FENETRES",
              prixUnitVenteHT: 16.67,
              ecoPartTTC: null,
              libelleArticle: null,
              famille: "PRODUITS DE MISE EN OEUVRE FENETRES",
              dateDisponibiliteReelle: null,
              tva: 3.33,
              montantPromoHT: 0,
              quantite: 1,
              montantNetHT: 16.67,
              numeroLigne: 5,
              codeArticle: "2567440",
              nomArticle: "MEMBRANE ADHESIVE ME508 70MM",
              cpv: false,
              numeroLigneParent: null,
              prixUnitCatTTC: 20,
              criteres: [
                {
                  codeCritere: "FAM",
                  valeurCritere: "020",
                },
                {
                  codeCritere: "ART_TYPE",
                  valeurCritere: "ORD",
                },
              ],
              version: null,
              tauxTVA: 20,
              categorieArticle: "ETANCHEITE",
              modeADV: "",
              sousFamille: "ETANCHEITE",
              tauxPromotion: null,
              unite: "UN",
              numeroCommandeVente: null,
              ecoPartHT: null,
              codeFamille: "G654",
              typePromo: null,
              parentLine: null,
              prixUnitAchatHT: 0,
              nombreIterationRetard: null,
              montantNetTTC: 20,
              prixUnitVenteTTC: 20,
              codeGrandeFamille: "G13",
              magasinOrigine: null,
              codePromotion: null,
              libellePromotion: null,
              prixUnitNetHT: 16.67,
              prixUnitCatHT: 16.67,
              typeArticle: "STANDARD",
              numeroDevis: null,
              montantPromoTTC: 0,
              dateMADMagasin: "2024-03-20T00:00:00.000",
              numeroPosteCommandeVente: null,
              prixUnitNetTTC: 20,
              infoStatutLigne: "ALF",
              documentId: "C_24000195_0028",
              commentaires: null,
              dateModTech: "2024-03-15T10:15:35.416",
            },
            {
              remiseTTC: 0,
              codeCategorieArticle: "G3467",
              sourceconfigurateur: null,
              codeSousFamille: "G3467",
              infoFournisseur: null,
              origineADV: "N",
              grandeFamille: "FENETRES",
              prixUnitVenteHT: 55,
              ecoPartTTC: null,
              libelleArticle: null,
              famille: "PRODUITS DE MISE EN OEUVRE FENETRES",
              dateDisponibiliteReelle: null,
              tva: 11,
              montantPromoHT: 0,
              quantite: 1,
              montantNetHT: 55,
              numeroLigne: 15,
              codeArticle: "2540010",
              nomArticle: "KIT ETANCHEITE MENUIS. EXT POSE APPLIQUE",
              cpv: false,
              numeroLigneParent: null,
              prixUnitCatTTC: 66,
              criteres: [
                {
                  codeCritere: "FAM",
                  valeurCritere: "020",
                },
                {
                  codeCritere: "ART_TYPE",
                  valeurCritere: "ORD",
                },
              ],
              version: null,
              tauxTVA: 20,
              categorieArticle: "KIT DE POSE FENETRE",
              modeADV: "",
              sousFamille: "KIT DE POSE FENETRE",
              tauxPromotion: null,
              unite: "UN",
              numeroCommandeVente: null,
              ecoPartHT: null,
              codeFamille: "G654",
              typePromo: null,
              parentLine: null,
              prixUnitAchatHT: 0,
              nombreIterationRetard: null,
              montantNetTTC: 66,
              prixUnitVenteTTC: 66,
              codeGrandeFamille: "G13",
              magasinOrigine: null,
              codePromotion: null,
              libellePromotion: null,
              prixUnitNetHT: 55,
              prixUnitCatHT: 55,
              typeArticle: "Non utilisé",
              numeroDevis: null,
              montantPromoTTC: 0,
              dateMADMagasin: "2024-03-20T00:00:00.000",
              numeroPosteCommandeVente: null,
              prixUnitNetTTC: 66,
              infoStatutLigne: "ALF",
              documentId: "C_24000195_0028",
              commentaires: null,
              dateModTech: "2024-03-15T10:15:35.416",
            },
            {
              remiseTTC: 0,
              codeCategorieArticle: null,
              sourceconfigurateur: "NEC",
              codeSousFamille: null,
              infoFournisseur: null,
              origineADV: "N",
              grandeFamille: null,
              prixUnitVenteHT: 1447.92,
              ecoPartTTC: null,
              libelleArticle: "DIMENSIONS H 1000x L 1000 1 VTL - Uw: 1.",
              famille: null,
              dateDisponibiliteReelle: null,
              tva: 289.58,
              montantPromoHT: 0,
              quantite: 1,
              montantNetHT: 1447.92,
              numeroLigne: 6,
              codeArticle: "2640440",
              nomArticle: "MEN.FRAPPE OC HERA ALU+VRI AUTRE COULEUR",
              cpv: false,
              numeroLigneParent: null,
              prixUnitCatTTC: 1737.5,
              criteres: [
                {
                  codeCritere: "FAM",
                  valeurCritere: "020",
                },
                {
                  codeCritere: "ART_TYPE",
                  valeurCritere: "STA",
                },
              ],
              version: "000001",
              tauxTVA: 20,
              categorieArticle: null,
              modeADV: "",
              sousFamille: null,
              tauxPromotion: null,
              unite: "UN",
              numeroCommandeVente: "001844",
              ecoPartHT: null,
              codeFamille: null,
              typePromo: null,
              parentLine: null,
              prixUnitAchatHT: 0,
              nombreIterationRetard: null,
              montantNetTTC: 1737.5,
              prixUnitVenteTTC: 1737.5,
              codeGrandeFamille: null,
              magasinOrigine: null,
              codePromotion: null,
              libellePromotion: null,
              prixUnitNetHT: 1447.92,
              prixUnitCatHT: 1447.92,
              typeArticle: null,
              numeroDevis: null,
              montantPromoTTC: 0,
              dateMADMagasin: "2024-05-16T00:00:00.000",
              numeroPosteCommandeVente: 7087,
              prixUnitNetTTC: 1737.5,
              infoStatutLigne: "ALF",
              documentId: "C_24000195_0028",
              commentaires: {
                libelle1Commentaire: "DIMENSIONS H 1000x L 1000 1 VTL - Uw: 1.",
                dateCommentaire: null,
                libelle2Commentaire: "3 W/m2.K - Sw: 0.46 - MONTANT ELIGIBLE M",
                libelle3Commentaire: "A PRIME RENOV'",
                libelle4Commentaire: null,
              },
              dateModTech: "2024-03-15T10:15:35.416",
            },
          ],
          suiviDevis: {
            dateProjet: null,
            commentairesProjet: null,
            propCreditFaite: null,
            devisDoublon: null,
            statutTransfoDevis: null,
            dateFinValiditeDevis: null,
            repPropCredit: null,
            typePoseEnvisage: null,
            attributionRelance: null,
            dateRelanceExigee: null,
            budget: null,
          },
          codeFinancement: null,
          universDocument: {
            famille2: "G123",
            famille1: "G654",
            libelleFamille2: "ACCESSOIRES FENETRES",
            libelleFamille1: "PRODUITS DE MISE EN OEUVRE FENETRES",
          },
          mntRemiseHt: 0,
          sourceSystem: "GC2000",
          dateDispoReel: null,
          mntEcopartHt: 0,
          sousType: null,
          documentRef: "24000195",
          shippingMode: "MAGASIN",
          commentairesGeneraux: null,
          codeMotifAnnul: null,
          customerId: "11002800216236",
          montantNetTTC: 5076.06,
          partPoseTTC: null,
          statutDoc: "En attente de réception fournisseur",
          refChantierArtisan: null,
          suiviCommande: {
            modeEnlevement: null,
            dateMaDispo: "2024-05-16T00:00:00.000",
            magasinEnlevement: "0028",
            sousStatutDispo: null,
            statutCommande: "MODIF",
            dateEnlevement: "2024-03-22T00:00:00.000",
            statutPrepCommande: "ALF",
          },
          magasinOrigine: "0028",
          mntRemiseTtc: 0,
          dateMADMagasin: null,
          precDocumentId: null,
          modificationDate: "2024-03-14T00:30:50.000",
          livraisonType: {
            transport: null,
          },
          codeFamilleFinancement: null,
          mntTvaSurEcopart: null,
          mntPromoHt: 0,
          agentCreateur: "Administrateur (002800)",
          documentId: "C_24000195_0028",
        },
      },
    },
  };
  //return useSWR(documentId ? payload : null, documentService.getDocumentByID, swrOptionsNoRetry);
};
