/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
import styled from "@mui/styled-engine-sc";
import Select from "@mui/material/Select";
import React from "react";
import { dimensions } from "../../themes/variables";

const SelectWrapper = React.forwardRef((props: any, ref: any) => {
  return <Select {...props} ref={ref} />;
});

const StyledSelect = styled(SelectWrapper)`
  ${({ theme }) => `
    &.margin-top-0 {
      margin-top: 0;
    }
    .MuiOutlinedInput-input {
      padding: 0 ${theme.spacing(3)};
      padding-right: ${theme.spacing(4)};
      height: ${dimensions.searchBar.height}px;
      line-height: ${dimensions.searchBar.height}px;
      color: ${theme.palette.text.primary};
      background: ${theme.palette.white.main};
      font-weight: 400;


      &.Mui-disabled {
        color: ${theme.palette.action.disabled};
        background:  ${theme.palette.action.disabledBackground};;
      }
    }

    &.no-border {
      font-size: 12px;

      .MuiSelect-standard {
        padding-top: 0;
        padding-bottom: 0;
      }
      ${theme.breakpoints.up("sm")} {
        .MuiSelect-icon {
          top: -4px;
          transform: none;
        }
      }
      ${theme.breakpoints.down("sm")} {
        .MuiOutlinedInput-input {
          height: auto;
          min-height: 40px;
        }
        .MuiSelect-icon {
          top: 20px;
        }
      }

      &:before, &:after {
        display: none;
      }
    }

    .MuiSelect-icon {
      top: 50%;
      transform: translateY(-50%);
      color: ${theme.palette.text.secondary};
      width: 20px;
    }

    .MuiOutlinedInput-notchedOutline {
      border: ${theme.palette.gray.neutralGray300} 1px solid;
    }

    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border: ${theme.palette.gray.neutralGray300} 1px solid;
    }

    &.Mui-error .MuiOutlinedInput-notchedOutline {
      border: ${theme.palette.primary.main} 1px solid;
    }

    .MuiInputLabel-animated {
      transition: none;
    }

    .MuiSelect-filled {
      border-radius: 0;
    }

    &.MuiFilledInput-root {
      border-radius: 0;
      background: none;

      .MuiSelect-filled {
        font-weight: 400;
        color: ${theme.palette.text.primary};
        padding-left: 0;
        padding-top: 17px;
      }
      .MuiSelect-select {
        &:focus {
          background: none;
        }
      }
      &:after {
        border-bottom: 1px solid ${theme.palette.gray.neutralGray400};
      }
    }

    &.select-phone {
      .MuiSelect-icon {
        top: 68%;
        width: 16px;
      }
    }

    &.flex {
      .MuiSelect-multiple {
        display: flex;
      }
    }
  `}
`;

export { StyledSelect };
