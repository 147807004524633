import { useEffect, useRef, useState } from "react";
import { Skeleton } from "@mui/material";
import { site } from "../../../_foundation/constants/site";

interface Props {
  src: string;
  alt?: string;
  className?: string;
  width?: string | number;
  height?: string | number;
  transparent?: boolean;
}

const ImageLayout: React.FC<Props> = ({ src, alt, className, width, height, transparent }) => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const [imgSrc, setImgSrc] = useState(src);
  const imgRef = useRef<HTMLImageElement>(null);
  const imgDefault = site.defaultImage;

  useEffect(() => {
    setImgSrc(src);
    if (window["__isPrerender__"]) {
      setLoaded(true);
    }
  }, [src]);

  // const setDefaultUrl = () => {
  //   if (imgRef.current) {
  //     imgRef.current.src = imgDefault;
  //   }
  // };

  return (
    <>
      {src && !loaded && (
        <Skeleton variant="rectangular" animation="wave" width={width} height={height} sx={{ margin: "0 auto" }}>
          {width === "100%" && <div style={{ paddingTop: "100%" }}></div>}
        </Skeleton>
      )}
      <img
        ref={imgRef}
        className={className}
        src={imgSrc}
        alt={alt}
        width={width}
        height={height}
        style={{ margin: "0 auto", display: `${!loaded ? "none" : "block"}`, opacity: transparent ? 0 : 1 }}
        onLoad={() => setLoaded(true)}
        onError={() => setImgSrc(window["__isPrerender__"] ? src : imgDefault)}
      />
    </>
  );
};

export { ImageLayout };
