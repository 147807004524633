/* eslint-disable react-hooks/exhaustive-deps */
//Standard libraries
import React, { useEffect, ChangeEvent, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import Axios, { Canceler } from "axios";
import getDisplayName from "react-display-name";
//Custom libraries
//Redux
import { sessionErrorSelector } from "../../../redux/selectors/error";
import { SESSION_ERROR_LOGIN_REQUESTED_ACTION } from "../../../redux/actions/user";
import {
  RESET_SESSION_ERROR_ACTION,
  CANCEL_SESSION_ERROR_ACTION,
  CANCEL_REMEMBER_ME_SESSION_ERROR_ACTION,
} from "../../../redux/actions/error";
import {
  loginStatusSelector,
  logonIdSelector,
  userInitStatusSelector,
  forUserIdSelector,
  rememberMeSelector,
} from "../../../redux/selectors/user";
import { siteSelector } from "../../../redux/selectors/site";
//UI
import { StyledGrid } from "@hcl-commerce-store-sdk/react-component";
import { useCSRForUser } from "../../../_foundation/hooks/useCSRForUser";
import { HOME } from "../../../constants/routes";
//Foundation
import { useStoreLocatorValue } from "../../../_foundation/context/store-locator-context";
import { STORELOCATORACTIONS } from "../../../_foundation/constants/common";
import { StyledBox, StyledButton, StyledModal, StyledTextField, StyledTypography } from "../../../styled-mui";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

export const SessionErrorDialog = () => {
  const un = useSelector(logonIdSelector);
  const forUserId = useSelector(forUserIdSelector);
  const mySite = useSelector(siteSelector);
  const formRef = useRef<HTMLFormElement>(null);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [disabled, setDisabled] = React.useState(true);

  const [open, setOpen] = React.useState(false);
  const [logonInputProps, setLogonInputProps] = React.useState<any>({});

  const { t } = useTranslation();

  const { errorMsgKey, handled } = useSelector(sessionErrorSelector);
  const widgetName = getDisplayName(SessionErrorDialog);
  const dispatch = useDispatch();

  const loginStatus = useSelector(loginStatusSelector);
  const userInitStatus = useSelector(userInitStatusSelector);
  const rememberMe = useSelector(rememberMeSelector);

  const { handleForUserSessionError } = useCSRForUser();

  const storeLocatorDispach = useStoreLocatorValue().dispatch;

  const CancelToken = Axios.CancelToken;
  const cancels: Canceler[] = [];
  const navigate = useNavigate();

  const payloadBase: any = {
    widget: widgetName,
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c);
    }),
  };

  const handleOnChange = (evt: ChangeEvent<HTMLInputElement>, type: string) => {
    evt.persist();
    const value: string | null = evt.target.value;
    if (type === "username") {
      setUsername(value || "");
    }
    if (type === "password") {
      setPassword(value || "");
    }
    setDisabled(!formRef.current || !formRef.current.checkValidity());
  };

  const handleSubmit = async (evt: any) => {
    evt.preventDefault();

    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return;
    }
    const token = await executeRecaptcha("sessionErrorDialog");

    dispatch(
      SESSION_ERROR_LOGIN_REQUESTED_ACTION({
        body: {
          logonId: username,
          logonPassword: password,
          recaptchaToken: token,
        },
        skipErrorSnackbar: true,
        ...payloadBase,
      })
    );
  };
  const handleCancel = useCallback(() => {
    setOpen(false);
    const payload = {
      widget: widgetName,
    };
    if (rememberMe) {
      navigate(HOME);
      dispatch(CANCEL_REMEMBER_ME_SESSION_ERROR_ACTION(payload));
    } else {
      dispatch(CANCEL_SESSION_ERROR_ACTION(payload));
      storeLocatorDispach({ type: STORELOCATORACTIONS.RESET_STORE_SELECTOR });
    }
  }, [dispatch, navigate, rememberMe, widgetName, storeLocatorDispach]);

  useEffect(() => {
    if (userInitStatus && !loginStatus && handled === false) {
      handleCancel();
    }
  }, [loginStatus, userInitStatus, handled]);

  useEffect(() => {
    setOpen(handled === false);
    if (handled === true) {
      dispatch(RESET_SESSION_ERROR_ACTION());
      navigate(0);
    }
    if (mySite) {
      if (mySite.isB2B) {
        setLogonInputProps({
          maxLength: 100,
          type: "text",
          label: t(`SignIn.Label.B2B`),
        });
      } else {
        setLogonInputProps({
          maxLength: 100,
          type: "email",
          placeholder: "name@domain.com",
          label: t(`SignIn.Label.Email`),
        });
      }
    }
  }, [handled, dispatch, mySite]);

  useEffect(() => {
    setUsername(un);
  }, [un]);

  useEffect(() => {
    setDisabled(!formRef.current || !formRef.current.checkValidity());
  }, [formRef.current]);

  useEffect(() => {
    return () => {
      cancels.forEach((cancel) => cancel());
    };
  }, []);

  if (handled === undefined || handled === null || !loginStatus) {
    return <></>;
  } else if (forUserId) {
    handleForUserSessionError(errorMsgKey);
    return <></>;
  } else {
    return (
      <>
        <StyledModal disableEscapeKeyDown open={open}>
          <StyledBox className="modal-container small">
            <form onSubmit={handleSubmit} name="signInForm" noValidate ref={formRef}>
              <StyledTypography component="p" variant="h5" className="">
                {t("SessionError.Dialog.Title")}
              </StyledTypography>

              <StyledTypography variant="body1" component="p" pt={3}>
                {t("SessionError.Dialog.Description")}
              </StyledTypography>
              <StyledTextField
                variant="standard"
                focused
                className="underline-gray"
                name="username"
                onChange={(e) => handleOnChange(e, "username")}
                autoFocus
                value={username}
                required
                fullWidth
                inputProps={logonInputProps}
                label={logonInputProps.label}
                sx={{ my: 6 }}
              />
              <StyledTextField
                variant="standard"
                focused
                className="underline-gray"
                label={t("SessionError.Password")}
                type="password"
                onChange={(e) => handleOnChange(e, "password")}
                name="password"
                required
                fullWidth
              />
              <StyledGrid container justifyContent="space-between" alignItems="center" pt={6}>
                <StyledGrid item>
                  <StyledButton testId="session-error-cancel" color="black" variant="" onClick={handleCancel}>
                    {t("SessionError.CancelButton")}
                  </StyledButton>
                </StyledGrid>
                <StyledGrid item>
                  <StyledButton testId="session-error-submit" color="secondary" type="submit" disabled={disabled}>
                    {t("SessionError.SubmitButton")}
                  </StyledButton>
                </StyledGrid>
              </StyledGrid>
            </form>
          </StyledBox>
        </StyledModal>
      </>
    );
  }
};
