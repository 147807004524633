import { Pictogramme } from "../types/store-types";
import { SEARCH_FIND_PROFILE, SEARCH_PLP_PROFILE } from "./common";

type SortOption = {
  key: string;
  value: string;
  translationKey: string;
};

export const SORT_OPTIONS = {
  // for identifier: LP_HCL_V2_findProductsByCategory
  priceSortOptionsForProdsByCategory: [
    {
      key: "SN_NO_SORT", //recommendation
      value: "8", //orderBy value
      translationKey: "CommerceEnvironment.listSettings.relevance",
    },
    {
      key: "SN_SORT_BY_NAME", //nom
      value: "2",
      translationKey: "CommerceEnvironment.listSettings.name",
    },
    {
      key: "SN_SORT_LOW_TO_HIGH", //prix croissant
      value: "9",
      translationKey: "CommerceEnvironment.listSettings.priceLowToHigh",
    },
    {
      key: "SN_SORT_HIGH_TO_LOW", //prix decroissant
      value: "10",
      translationKey: "CommerceEnvironment.listSettings.priceHighToLow",
    },
    {
      key: "SN_SORT_BEST_SELL", //meilleures ventes
      value: "5",
      translationKey: "CommerceEnvironment.listSettings.bestSell",
    },
    {
      key: "SN_SORT_BEST_SHOW", //les plus vus
      value: "6",
      translationKey: "CommerceEnvironment.listSettings.bestShow",
    },
    {
      key: "SN_SORT_BEST_NOTE", //meilleures notes
      value: "7",
      translationKey: "CommerceEnvironment.listSettings.bestNote",
    },
  ],
  // for identifier: LP_HCL_V2_findProductsBySearchTerm
  priceSortOptionsForProdsBySearch: [
    {
      key: "SN_NO_SORT", //recommendation
      value: "0", //orderBy value
      translationKey: "CommerceEnvironment.listSettings.relevance",
    },
    {
      key: "SN_SORT_BY_NAME",
      value: "2",
      translationKey: "CommerceEnvironment.listSettings.name",
    },
    {
      key: "SN_SORT_LOW_TO_HIGH",
      value: "8",
      translationKey: "CommerceEnvironment.listSettings.priceLowToHigh",
    },
    {
      key: "SN_SORT_HIGH_TO_LOW",
      value: "9",
      translationKey: "CommerceEnvironment.listSettings.priceHighToLow",
    },
    {
      key: "SN_SORT_BEST_SELL",
      value: "5",
      translationKey: "CommerceEnvironment.listSettings.bestSell",
    },
    {
      key: "SN_SORT_BEST_SHOW",
      value: "6",
      translationKey: "CommerceEnvironment.listSettings.bestShow",
    },
    {
      key: "SN_SORT_BEST_NOTE",
      value: "7",
      translationKey: "CommerceEnvironment.listSettings.bestNote",
    },
  ],
  getDefaultSortValue: function (identifier?: string): any {
    if (identifier === SEARCH_PLP_PROFILE || !identifier) {
      return this.priceSortOptionsForProdsByCategory[0].value;
    } else if (identifier === SEARCH_FIND_PROFILE) {
      return this.priceSortOptionsForProdsBySearch[0].value;
    }
  },
  getSortOptionsByIdentifier: function (identifier: string): SortOption[] | undefined {
    if (identifier === SEARCH_PLP_PROFILE) {
      return this.priceSortOptionsForProdsByCategory;
    } else if (identifier === SEARCH_FIND_PROFILE) {
      return this.priceSortOptionsForProdsBySearch;
    }
  },
};

//Attribute DEFINING
export const ATTR_IDENTIFIER = {
  PickUp: "PickUpInStore",
  DIMENSION: "custom.items.childSkuCount.numeric", //was nbDimensions.raw as attribute identifier
  ECO_PART: "eco-part-total",
  COULEUR: "COULEUR",
  LARGEUR: "LARGEUR",
  HAUTEUR: "HAUTEUR",
  PROFONDEUR: "PROFONDEUR",
  LONGUEUR: "LONGUEUR",
  FINITION: "FINITION",
  PRODUCT_TYPE: "InternalTypology",
  FURNITURE_KIT: "isMeubleEnKit",
  CONFIGURATOR_LINK: "lienConfigurateur",
  CONFIGURATOR_LINK_TARGET: "lienConfigurateurTargetType",
  SALES_UNIT_BOTTE: "coeffConversionPrix",
  SALES_UNIT_COLISE: "coeffConversionQuantite",
  AVIS_NB: "avNb",
  AVIS_NOTE: "avNote",
  AVIS_NOTE_FACET: "AV-NOTE-FACET",
  ENERGY: "ATC-NORME-COV",
  ORIGINE: "ATC-ORIGINE",
  GARANTIE: "COMMUNES-3-COMM-GARANTIE",
  OLD_PRICE_END: "oldPriceEnd", //also custom.oldPriceEndDate.raw at product level
  OLD_PRICE: "oldPrice", //also custom.oldPrice.raw at product level
  ATC_POINT_ROGUE: "ATC-POINT-ROUGE", //product type=item level attribute
  ITEM_PERCENTAGE_OFF: "ItemPercentageOff",
};

export const ATTR_UNIT_OF_MEASURE = {
  CMT: "CMT",
  MMT: "MMT",
};

//Product Type
export const PRODUCT_TYPE = {
  COLISE: "colisé",
  BOTTE: "botte",
  SUR_MESURE: "sur-mesure",
  STANDARD: "standard",
};

export const RDV_TYPE = {
  COMMERCIAL: "commercial",
  STORE: "RI",
  HOME: "CAD",
};

export enum TYPE_CARD {
  RECOMMENDATION = "RECOMMENDATION",
  LIST = "LIST",
  INDISPENSABLE = "INDISPENSABLE",
  INSPIRATION = "INSPIRATION",
}

export enum USAGE_PICTOGRAMME {
  BADGE = "BADGE",
  LABEL_MKTG = "LABEL_MKTG",
  LABEL_LEGAL = "LABEL_LEGAL",
  LABEL_MRKT = "LABEL_MRKT",
}

// export const usageFileNew = {
//   IMAGE_S: ["Image_desktop_XS", "Image_mobile_XS", "ANGLEIMAGES_THUMBNAIL", "ICON"],
//   IMAGE_M: [
//     "Image_mobile_M_ambiance",
//     "Image_mobile_M_detoure",
//     "Image_desktop_M_ambiance",
//     "Image_desktop_M_detoure",
//     "GRIDIMAGES_AMBIANCE",
//     "GRIDIMAGES_DETOURE",
//   ],
//   IMAGE_L: ["Image_desktop_L", "Image_mobile_L", "ANGLEIMAGES_FULLIMAGE", "GRIDIMAGES_FULLIMAGE"],
//   USERMANUAL: ["USERMANUAL"], /* PDFs etc. */
// };

export enum USAGE_FILE {
  USERMANUAL = "USERMANUAL",
  IMAGE_MOBILE_S = "Image_mobile_S",
  IMAGE_MOBILE_L = "Image_mobile_L",
  IMAGE_DESKTOP_S = "Image_desktop_S",
  IMAGE_DESKTOP_L = "Image_desktop_L",
}

export const TAGS_OPTIONS: Pictogramme[] = [
  {
    identifier: "COMMUNE-SUITE-SPEC-NAT-NIVEAU",
    description: "Série Limitée",
    className: "",
    value: "Série Limitée",
    order: 1,
  },
  // {
  //   identifier: "",
  //   description: "Promotion",
  //   className: "promo",
  //   order: 2,
  // },
  {
    identifier: "BEST-SELLER",
    description: "Best-seller",
    className: "",
    order: 3,
  },
  {
    identifier: "ATC-CAMPAGNE-PRIX",
    description: "Prix qualité",
    value: "Prix qualité", //"Premier prix qualité",
    order: 4,
  },
  {
    identifier: "ATC-NOUVEAUTE",
    description: "Nouveauté",
    deactivationValue: "0",
    order: 5,
  },
];

//export const CATEGORIES_DISPLAY_TOOL = ["3074457345616678183", "3074457345616678188"];
