import styled from "@mui/styled-engine-sc";
import { palette } from "../../../themes/color-palette";

export const StyledProductRecommended = styled("div")`
  ${({ theme }) => `
    background: ${palette.blue.background};
    color: ${palette.white.main};
    padding-bottom: ${theme.spacing(20)};

    ${theme.breakpoints.down("sm")} {
      padding-right: 0;
      padding-bottom: ${theme.spacing(10)};
    }

    .keen-slider.overflow-visible {
      overflow: visible;
    }

    &.bg-beige {
      background: ${palette.beige.light};
      color: ${palette.text.primary};
    }

    &.modal {
      padding-bottom: ${theme.spacing(5)};
      border-radius: 0 0 8px 8px;

      .arrow-big {
        top: calc(50% + 52px);
      }
    }

    &.order-page {
      padding-bottom: 0;
    }



    .product-recommended {
      position: relative;

      .keen-slider.justify-center {
        justify-content: center;
        gap: 20px;
      }

    }
  `}
`;
