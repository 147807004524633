import useSWR from "swr";
import { swrOptionsNoRetry } from "../../utils/hooks/swr/constants";
import { useSite } from "./useSite";

const useFetchStaticJson = ({ file, isLocal = true, folder = "" }) => {
  const { mySite } = useSite();
  const fetchRemoteFeed = async ({ file, isLocal, folder }) => {
    const path = isLocal ? `${process.env.PUBLIC_URL}/statics${folder}` : `${mySite.assetsJsonUrl}${folder}`;
    return await fetch(`${path}/${file}`)
      .then((response) => {
        return response.json();
      })
      .then((resp) => {
        return resp;
      });
  };

  const payload = {
    isLocal,
    file,
    folder,
  };

  return useSWR(file ? payload : null, fetchRemoteFeed, swrOptionsNoRetry);
};

export default useFetchStaticJson;
