/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Services - Account and Contract
 * These services provide APIs to manage accounts and contracts.  a contract is an agreement that represents the terms and conditions that apply to a transaction. An account is a relationship between the merchant and the financial institution that processes transactions for that merchant.
 *
 * The version of the OpenAPI document: 9.1.6
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 * @interface SuiviCommande
 */
export interface SuiviCommande {
    /**
     * 
     * @type {string}
     * @memberof SuiviCommande
     */
    dateEnlevement?: string;
    /**
     * 
     * @type {string}
     * @memberof SuiviCommande
     */
    dateMaDispo?: string;
    /**
     * 
     * @type {string}
     * @memberof SuiviCommande
     */
    magasinEnlevement?: string;
    /**
     * 
     * @type {string}
     * @memberof SuiviCommande
     */
    modeEnlevement?: string;
    /**
     * 
     * @type {string}
     * @memberof SuiviCommande
     */
    sousStatutDispo?: string;
    /**
     * 
     * @type {string}
     * @memberof SuiviCommande
     */
    statutCommande?: string;
    /**
     * 
     * @type {string}
     * @memberof SuiviCommande
     */
    statutPrepCommande?: SuiviCommandeStatutPrepCommandeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum SuiviCommandeStatutPrepCommandeEnum {
    Prep = 'PREP',
    Disp = 'DISP',
    Annl = 'ANNL',
    Comp = 'COMP'
}



