import styled from "@mui/styled-engine-sc";
import SvgIcon from "@mui/material/SvgIcon";

const CustomSvg = styled((props) => (
  <SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} />
))`
  ${({ theme }) => `
    width:16px;
    height:16px;

    &.big {
      width:24px;
      height:24px;
    }
  `}
`;

export const SvgIconOrder = (props) => {
  const { className = "" } = props;

  return (
    <CustomSvg {...{ className }}>
      <path d="M3.333 14.666c-.367 0-.68-.133-.94-.393a1.284 1.284 0 0 1-.393-.94V5.8a1.66 1.66 0 0 1-.474-.467 1.17 1.17 0 0 1-.193-.667v-2c0-.366.133-.68.393-.94.26-.26.574-.393.94-.393h10.667c.367 0 .68.133.94.393.26.26.393.574.393.94v2c0 .254-.066.48-.193.667A1.662 1.662 0 0 1 14 5.8v7.533c0 .367-.134.68-.394.94-.26.26-.573.393-.94.393H3.333Zm0-8.666v7.333h9.333V6H3.333Zm10-1.334v-2H2.666v2h10.667ZM6.666 9.333h2.667a.649.649 0 0 0 .473-.193.649.649 0 0 0 .194-.474.649.649 0 0 0-.194-.473A.649.649 0 0 0 9.333 8H6.666a.649.649 0 0 0-.473.193.649.649 0 0 0-.193.473c0 .187.066.347.193.474a.649.649 0 0 0 .473.193Z" />
    </CustomSvg>
  );
};
