//Standard libraries
import { getSite } from "../../hooks/useSite";

const jsonService = {
  async fetchStaticJson({ file, isLocal = true }) {
    const mySite = getSite();
    const path = isLocal ? `${process.env.PUBLIC_URL}/statics` : `${mySite?.assetsJsonUrl}`;
    const response = await fetch(`${path}/${file}`);
    const data = await response.json();
    return data;
  },
};

export default jsonService;
