import SvgIcon from "@mui/material/SvgIcon";
import styled from "@mui/styled-engine-sc";

const Svg = styled((props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} />
))`
  ${({ theme }) => `
    width:24px;
    height:24px;

    &.small {
      width:16px;
      height:16px;
    }

  `}
`;

export const SvgTruck = (props) => {
  const { className = "" } = props;

  return (
    <Svg {...{ className }}>
      <path d="M6.008 19.97a2.9 2.9 0 0 1-2.13-.878A2.876 2.876 0 0 1 3 16.966c-.55 0-1.02-.2-1.41-.59C1.2 15.988 1 15.519 1 14.97V5.996a1.97 1.97 0 0 1 .6-1.407C1.99 4.199 2.46 4 3.01 4h11.994c.55 0 1.02.2 1.409.589.39.39.59.858.59 1.407v1.997h2.499c.17 0 .32.03.45.1s.25.169.35.299l2.498 3.324c.07.08.12.18.15.279.03.1.05.21.05.33v3.673c0 .279-.1.519-.29.708-.19.19-.43.29-.71.29h-1c0 .828-.289 1.537-.879 2.126-.59.588-1.29.878-2.129.878-.84 0-1.539-.29-2.129-.878a2.876 2.876 0 0 1-.88-2.126H8.987c0 .828-.29 1.537-.88 2.126-.589.588-1.289.878-2.128.878l.03-.03Zm0-1.996c.28 0 .52-.1.71-.29a.97.97 0 0 0 .29-.708c0-.28-.1-.52-.29-.709a.974.974 0 0 0-.71-.29c-.28 0-.52.1-.71.29a.97.97 0 0 0-.29.709c0 .28.1.519.29.708.19.19.43.29.71.29ZM3.009 5.996v8.983h.8c.28-.299.61-.539.98-.728.37-.19.779-.28 1.229-.28.45 0 .86.09 1.23.28.369.19.689.429.979.728h6.797V5.996H3.009Zm14.993 11.978c.28 0 .52-.1.71-.29a.97.97 0 0 0 .29-.708c0-.28-.1-.52-.29-.709a.974.974 0 0 0-.71-.29c-.28 0-.52.1-.71.29a.97.97 0 0 0-.29.709c0 .28.1.519.29.708.19.19.43.29.71.29Zm-1-4.99h4.249L19 9.988h-1.998v2.994Z" />
    </Svg>
  );
};
