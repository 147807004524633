import styled from "@mui/styled-engine-sc";

const Pro = styled((props) => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    style={{
      display: "block",
      margin: "0 auto",
    }}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  />
))`
  ${({ theme }) => `
    width:80px;
    height:80px;

    &.small {
      width:48px;
      height:48px;
    }
    &.medium {
      width:54px;
      height:54px;
    }
    &.big {
      width: 100px;
      height: auto;
      max-width: 100%;
    }
  `}
`;

export const SvgPro = (props) => {
  const { className = "" } = props;

  return (
    <Pro {...{ className }}>
      <path
        d="M76.8762 30.4413C76.546 29.3984 75.4361 28.8195 74.3932 29.1496L60.7872 33.441C56.5628 26.4227 48.8795 21.7247 40.0911 21.7247C26.753 21.7247 15.9456 32.5368 15.9456 45.8701C15.9456 47.7263 16.1609 49.53 16.558 51.2666L75.5844 32.9243C76.6274 32.5942 77.2063 31.4843 76.8762 30.4413Z"
        fill="#E6658B"
      />
      <path
        d="M65.722 69.4415C58.609 75.6321 49.4939 79.0432 40.0482 79.0432C18.4937 79.0432 0.957977 61.5285 0.957977 40C0.957977 18.4715 18.4937 0.956823 40.0482 0.956823C49.1059 0.956823 57.9385 4.12391 64.9125 9.87442L65.5208 9.13766C58.3743 3.24363 49.3262 0 40.0482 0C17.9669 0 0 17.9452 0 40C0 62.0548 17.9669 80 40.0482 80C49.7238 80 59.0641 76.5076 66.3495 70.1638C73.5631 63.8823 78.2955 55.2422 79.6702 45.8318L78.7218 45.6931C77.3806 54.8786 72.7632 63.3082 65.7172 69.4415H65.722Z"
        fill="#252427"
      />
      <path
        d="M40.0818 19.3518C39.6411 19.3518 39.2004 19.3661 38.7645 19.3852C38.1179 18.6676 37.4713 17.9596 36.8151 17.2707L36.4654 17.6008C37.0306 18.1988 37.5958 18.8111 38.1562 19.4331C25.2235 20.4186 15.0019 31.2307 15.0019 44.3966C15.0019 45.5926 15.0881 46.7934 15.2606 47.9703L7.69735 50.2524L7.83625 50.7116L15.5288 48.3913L15.6198 48.6927C15.8833 50.1902 16.276 51.6685 16.8077 53.0941L17.258 52.9267C16.7263 51.5154 16.3383 50.0466 16.0796 48.554L50.3369 38.1246C50.9692 39.5551 51.5775 41.0238 52.1379 42.5212L52.5882 42.3538C52.0325 40.8707 51.429 39.4068 50.8015 37.9859L62.3116 34.4839C62.9726 35.9622 63.4947 37.5075 63.8492 39.0862L64.3186 38.981C63.9641 37.3879 63.442 35.833 62.7762 34.3452L71.6567 31.6421C77.3902 30.0395 78.9373 30.5131 79.0379 30.7236C79.105 30.9437 78.2619 32.3645 73.0984 34.8523L67.2979 37.8089L67.7338 38.6604L73.5247 35.7086C76.7771 34.1395 80.5276 31.9435 79.9384 30.3839C79.4738 29.1544 76.5999 29.2644 71.3885 30.7236L62.6038 33.3979C58.4127 24.8583 49.6184 19.3613 40.0818 19.3613V19.3518ZM40.0818 20.3086C49.2065 20.3086 57.6175 25.5376 61.6794 33.6706L50.653 37.0291C47.9994 31.1972 44.7471 25.9395 41.2361 21.8108L41.0445 21.9734C40.5703 21.4185 40.0913 20.8683 39.6076 20.3229C39.7656 20.3229 39.9285 20.3086 40.0866 20.3086H40.0818ZM38.9753 20.3373C39.5453 20.9784 40.1105 21.629 40.6709 22.2844L40.4985 22.4279C43.9328 26.4657 47.1133 31.6039 49.7238 37.3113L16.1707 47.5254C16.0365 46.492 15.9647 45.4443 15.9647 44.3966C15.9647 31.4843 26.1911 20.9162 38.9753 20.3373Z"
        fill="#252427"
      />
    </Pro>
  );
};
