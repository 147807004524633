/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Services - Account and Contract
 * These services provide APIs to manage accounts and contracts.  a contract is an agreement that represents the terms and conditions that apply to a transaction. An account is a relationship between the merchant and the financial institution that processes transactions for that merchant.
 *
 * The version of the OpenAPI document: 9.1.6
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../../base';
// @ts-ignore
import { ComLapeyreCommerceControllersNearestStoreRequest } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { ComLapeyreCommerceControllersNearestStoreResponse } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { ComLapeyreCommerceControllersShippingRequest } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { ComLapeyreCommerceControllersShippingResponse } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { ComLapeyreCommerceControllersStockRequest } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { ComLapeyreCommerceControllersStockResponse } from '../../../../../com/hcl/commerce/typescript/axios';
/**
 * StockApi - axios parameter creator
 * @export
 */
export const StockApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get the nearest store for dedicated postal code
         * @summary getNearestStores
         * @param {string} storeId The store identifier.
         * @param {ComLapeyreCommerceControllersNearestStoreRequest} comLapeyreCommerceControllersNearestStoreRequest The request object for stock.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nearestStore: async (storeId: string, comLapeyreCommerceControllersNearestStoreRequest: ComLapeyreCommerceControllersNearestStoreRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('nearestStore', 'storeId', storeId)
            // verify required parameter 'comLapeyreCommerceControllersNearestStoreRequest' is not null or undefined
            assertParamExists('nearestStore', 'comLapeyreCommerceControllersNearestStoreRequest', comLapeyreCommerceControllersNearestStoreRequest)
            const localVarPath = `/store/{storeId}/resources/getNearestStores`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(comLapeyreCommerceControllersNearestStoreRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the shipping date for the nearest and soonest delivering store.
         * @summary getShippingDate
         * @param {string} storeId The store identifier.
         * @param {ComLapeyreCommerceControllersShippingRequest} comLapeyreCommerceControllersShippingRequest The request object for shipping date.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shippingDate: async (storeId: string, comLapeyreCommerceControllersShippingRequest: ComLapeyreCommerceControllersShippingRequest, responseFormat?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('shippingDate', 'storeId', storeId)
            // verify required parameter 'comLapeyreCommerceControllersShippingRequest' is not null or undefined
            assertParamExists('shippingDate', 'comLapeyreCommerceControllersShippingRequest', comLapeyreCommerceControllersShippingRequest)
            const localVarPath = `/store/{storeId}/resources/getShippingDate`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(comLapeyreCommerceControllersShippingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the stock for dedicated store, product and quantity.
         * @summary getStocks
         * @param {string} storeId The store identifier.
         * @param {ComLapeyreCommerceControllersStockRequest} comLapeyreCommerceControllersStockRequest The request object for stock.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stock: async (storeId: string, comLapeyreCommerceControllersStockRequest: ComLapeyreCommerceControllersStockRequest, responseFormat?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('stock', 'storeId', storeId)
            // verify required parameter 'comLapeyreCommerceControllersStockRequest' is not null or undefined
            assertParamExists('stock', 'comLapeyreCommerceControllersStockRequest', comLapeyreCommerceControllersStockRequest)
            const localVarPath = `/store/{storeId}/resources/getAvailability`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(comLapeyreCommerceControllersStockRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StockApi - functional programming interface
 * @export
 */
export const StockApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StockApiAxiosParamCreator(configuration)
    return {
        /**
         * Get the nearest store for dedicated postal code
         * @summary getNearestStores
         * @param {string} storeId The store identifier.
         * @param {ComLapeyreCommerceControllersNearestStoreRequest} comLapeyreCommerceControllersNearestStoreRequest The request object for stock.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async nearestStore(storeId: string, comLapeyreCommerceControllersNearestStoreRequest: ComLapeyreCommerceControllersNearestStoreRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComLapeyreCommerceControllersNearestStoreResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.nearestStore(storeId, comLapeyreCommerceControllersNearestStoreRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the shipping date for the nearest and soonest delivering store.
         * @summary getShippingDate
         * @param {string} storeId The store identifier.
         * @param {ComLapeyreCommerceControllersShippingRequest} comLapeyreCommerceControllersShippingRequest The request object for shipping date.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shippingDate(storeId: string, comLapeyreCommerceControllersShippingRequest: ComLapeyreCommerceControllersShippingRequest, responseFormat?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComLapeyreCommerceControllersShippingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shippingDate(storeId, comLapeyreCommerceControllersShippingRequest, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the stock for dedicated store, product and quantity.
         * @summary getStocks
         * @param {string} storeId The store identifier.
         * @param {ComLapeyreCommerceControllersStockRequest} comLapeyreCommerceControllersStockRequest The request object for stock.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stock(storeId: string, comLapeyreCommerceControllersStockRequest: ComLapeyreCommerceControllersStockRequest, responseFormat?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComLapeyreCommerceControllersStockResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stock(storeId, comLapeyreCommerceControllersStockRequest, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StockApi - factory interface
 * @export
 */
export const StockApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StockApiFp(configuration)
    return {
        /**
         * Get the nearest store for dedicated postal code
         * @summary getNearestStores
         * @param {string} storeId The store identifier.
         * @param {ComLapeyreCommerceControllersNearestStoreRequest} comLapeyreCommerceControllersNearestStoreRequest The request object for stock.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nearestStore(storeId: string, comLapeyreCommerceControllersNearestStoreRequest: ComLapeyreCommerceControllersNearestStoreRequest, options?: any): AxiosPromise<ComLapeyreCommerceControllersNearestStoreResponse> {
            return localVarFp.nearestStore(storeId, comLapeyreCommerceControllersNearestStoreRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the shipping date for the nearest and soonest delivering store.
         * @summary getShippingDate
         * @param {string} storeId The store identifier.
         * @param {ComLapeyreCommerceControllersShippingRequest} comLapeyreCommerceControllersShippingRequest The request object for shipping date.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shippingDate(storeId: string, comLapeyreCommerceControllersShippingRequest: ComLapeyreCommerceControllersShippingRequest, responseFormat?: string, options?: any): AxiosPromise<ComLapeyreCommerceControllersShippingResponse> {
            return localVarFp.shippingDate(storeId, comLapeyreCommerceControllersShippingRequest, responseFormat, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the stock for dedicated store, product and quantity.
         * @summary getStocks
         * @param {string} storeId The store identifier.
         * @param {ComLapeyreCommerceControllersStockRequest} comLapeyreCommerceControllersStockRequest The request object for stock.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stock(storeId: string, comLapeyreCommerceControllersStockRequest: ComLapeyreCommerceControllersStockRequest, responseFormat?: string, options?: any): AxiosPromise<ComLapeyreCommerceControllersStockResponse> {
            return localVarFp.stock(storeId, comLapeyreCommerceControllersStockRequest, responseFormat, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StockApi - object-oriented interface
 * @export
 * @class StockApi
 * @extends {BaseAPI}
 */
export class StockApi extends BaseAPI {
    /**
     * Get the nearest store for dedicated postal code
     * @summary getNearestStores
     * @param {string} storeId The store identifier.
     * @param {ComLapeyreCommerceControllersNearestStoreRequest} comLapeyreCommerceControllersNearestStoreRequest The request object for stock.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockApi
     */
    public nearestStore(storeId: string, comLapeyreCommerceControllersNearestStoreRequest: ComLapeyreCommerceControllersNearestStoreRequest, options?: any) {
        return StockApiFp(this.configuration).nearestStore(storeId, comLapeyreCommerceControllersNearestStoreRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the shipping date for the nearest and soonest delivering store.
     * @summary getShippingDate
     * @param {string} storeId The store identifier.
     * @param {ComLapeyreCommerceControllersShippingRequest} comLapeyreCommerceControllersShippingRequest The request object for shipping date.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockApi
     */
    public shippingDate(storeId: string, comLapeyreCommerceControllersShippingRequest: ComLapeyreCommerceControllersShippingRequest, responseFormat?: string, options?: any) {
        return StockApiFp(this.configuration).shippingDate(storeId, comLapeyreCommerceControllersShippingRequest, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the stock for dedicated store, product and quantity.
     * @summary getStocks
     * @param {string} storeId The store identifier.
     * @param {ComLapeyreCommerceControllersStockRequest} comLapeyreCommerceControllersStockRequest The request object for stock.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockApi
     */
    public stock(storeId: string, comLapeyreCommerceControllersStockRequest: ComLapeyreCommerceControllersStockRequest, responseFormat?: string, options?: any) {
        return StockApiFp(this.configuration).stock(storeId, comLapeyreCommerceControllersStockRequest, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }
}
