import styled from "@mui/styled-engine-sc";
import SvgIcon from "@mui/material/SvgIcon";

const CustomSvg = styled((props) => (
  <SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} />
))`
  ${({ theme }) => `
    width:16px;
    height:16px;
  `}
`;

export const SvgContactUs = (props) => {
  const { className = "" } = props;

  return (
    <CustomSvg {...{ className }}>
      <path d="M1.33333 11.3334V2.00004C1.33333 1.81337 1.39999 1.65337 1.52666 1.52671C1.65333 1.40004 1.81333 1.33337 1.99999 1.33337H10.6667C10.8533 1.33337 11.0133 1.40004 11.14 1.52671C11.2667 1.65337 11.3333 1.81337 11.3333 2.00004V8.00004C11.3333 8.18671 11.2667 8.34671 11.14 8.47337C11.0133 8.60004 10.8533 8.66671 10.6667 8.66671H3.99999L1.33333 11.3334ZM4.66666 12C4.48 12 4.31999 11.9334 4.19333 11.8067C4.06666 11.68 3.99999 11.52 3.99999 11.3334V10H12.6667V4.00004H14C14.1867 4.00004 14.3467 4.06671 14.4733 4.19337C14.6 4.32004 14.6667 4.48004 14.6667 4.66671V14.6667L12 12H4.66666ZM10 2.66671H2.66666V8.12004L3.45333 7.33337H10.0067V2.66671H10Z" />
    </CustomSvg>
  );
};
