import styled from "@mui/styled-engine-sc";
import SvgIcon from "@mui/material/SvgIcon";

const CustomSvg = styled((props) => (
  <SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} />
))`
  ${({ theme }) => `
    width:16px;
    height:16px;
  `}
`;

export const SvgArrowBack = (props) => {
  const { className = "" } = props;

  return (
    <CustomSvg {...{ className }}>
      <path d="M0.25 8C0.25 7.87171 0.269896 7.74342 0.309691 7.625C0.349485 7.50658 0.419126 7.39803 0.518613 7.29934L7.09467 0.796052C7.29365 0.598684 7.53241 0.5 7.80103 0.5C8.06964 0.5 8.30841 0.598684 8.50738 0.796052C8.70635 0.993421 8.80584 1.22039 8.80584 1.48684C8.80584 1.75329 8.70635 1.98026 8.50738 2.17763L3.63254 7.01316L14.775 7.01316C15.0536 7.01316 15.2924 7.11184 15.4714 7.29934C15.6505 7.48684 15.75 7.72368 15.75 8C15.75 8.27632 15.6505 8.51316 15.4615 8.70066C15.2725 8.88816 15.0337 8.98684 14.7551 8.98684L3.63254 8.98684L8.50738 13.8224C8.70635 14.0197 8.80584 14.2467 8.80584 14.5132C8.80584 14.7796 8.70635 15.0066 8.50738 15.2039C8.30841 15.4013 8.06964 15.5 7.80103 15.5C7.53241 15.5 7.29365 15.4013 7.09467 15.2039L0.528561 8.69079C0.429075 8.5921 0.359434 8.48355 0.31964 8.36513C0.279845 8.24671 0.259948 8.12829 0.259948 7.99013L0.25 8Z" />
    </CustomSvg>
  );
};
