/* eslint-disable react-hooks/exhaustive-deps */
//Standard libraries
import React, { useMemo, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
//Redux
import {
  orderItemsSelector,
  // cartModalOpenSelector,
  cartModalOpenItemAddedSelector,
  itemsAddedInCartOneTimeSelector,
} from "../../../redux/selectors/order";
import * as orderActions from "../../../redux/actions/order";
//UI
import { StyledGrid } from "@hcl-commerce-store-sdk/react-component";
import {
  StyledBox,
  StyledButton,
  StyledIconButton,
  StyledLink,
  StyledModal,
  StyledTypography,
} from "../../../styled-mui";

import * as ROUTES from "../../../constants/routes";
import { SvgClose } from "../../svg/close";
import { SvgCheckThick } from "../../svg/checkThick";
import { Hidden } from "@mui/material";
import { SvgChevronBack } from "../../svg/chevronBack";
import { StyledOrderItem } from "../../elements/order-item/styled-orderItem";
import { ImageLayout } from "../../elements/image";
import { ProductAvis } from "../../elements/product-avis/ProductAvis";
import storeUtil from "../../../utils/storeUtil";
import ProductRecommended from "../../elements/product-recommanded/ProductRecommended";
import { FIND_PRODUCT_DETAIL_BY_ID_CART, PRODUCT_SUMMARY_PROFILE, WIDGET_CROWNPEAK } from "../../../constants/common";
import { FormattedProductPriceDisplay } from "../formatted-price-display/formatted-price-display";
import { PRODUCT_TYPE } from "../../../constants/catalog";
import { currentContractIdSelector } from "../../../redux/selectors/contract";
import productsService from "../../../_foundation/apis/search/products.service";
import { useSite } from "../../../_foundation/hooks/useSite";
import Axios, { Canceler } from "axios";
import { localStorageUtil } from "../../../_foundation/utils/storageUtil";
import { site } from "../../../_foundation/constants/site";
import { preciseCalculate } from "../../../utils/genericUtil";

/**
 * useFetchCpProduct component to fetch wp posts
 *
 * @param {number} widgetId Number of posts to fetch (default 3)
 * @param {string | null} [category] Wordpress category to fetch
 */

const CartConfirmModal: React.FC = () => {
  const orderItems = useSelector(orderItemsSelector);
  // const modalOpen = useSelector(cartModalOpenSelector);
  const itemsAdded = useSelector(cartModalOpenItemAddedSelector);
  const qty = useSelector(itemsAddedInCartOneTimeSelector);
  const contract = useSelector(currentContractIdSelector);
  const dispatch = useDispatch();
  const [FpcProductIds, setfpcProductIds] = useState([]);
  const location = useLocation();
  const [isCartData, setIsCartData] = useState<any | null>(null);
  // const [loading, setLoading] = useState<boolean>(true);
  // const [error, setError] = useState<boolean>(false);
  const [isFetched, setIsFetched] = useState<boolean>(false);

  const { mySite } = useSite();
  const CancelToken = Axios.CancelToken;
  const cancels: Canceler[] = [];
  const storeId: string = mySite ? mySite.storeID : "";
  const widgetName = WIDGET_CROWNPEAK.ADD_TO_CART;
  const miniCartItems = useMemo(() => {
    return orderItems.filter((i) => itemsAdded.some((itemAdd) => itemAdd.orderItemId === i.orderItemId));
  }, [orderItems, itemsAdded]);

  const widgetId = WIDGET_CROWNPEAK.ADD_TO_CART;
  const variables =
    FpcProductIds.length > 0 ? `{ "$productId": ${FpcProductIds.map((item) => `"${item}"`).join(",")} }` : null;
  const handleCloseModal = () => {
    setIsCartData(null);
    setIsFetched(false);
    dispatch(orderActions.CLOSE_CONFIRM_MODAL_ACTION({}));
    setfpcProductIds([]);
  };

  useEffect(() => {
    // Call the function whenever the URL changes
    setIsCartData(null);
    setIsFetched(false);
    handleCloseModal();
  }, [location.pathname]);

  useEffect(() => {
    if (miniCartItems && miniCartItems.length > 0) {
      // setLoading(true);
      const productIds: any = [];
      miniCartItems.forEach((item) => {
        productIds.push(item.productId);
      });
      const parameters: any = {
        id: productIds,
        contractId: contract,
      };
      productsService
        .findProductsUsingGET(parameters)
        .then((res) => {
          if (res.data.contents && res.data.contents.length > 0) {
            const parentProductIds: any = [];
            res.data.contents.forEach((content) => {
              parentProductIds.push(content.parentCatalogEntryID);
            });
            const params: any = {
              id: parentProductIds,
              contractId: contract,
              profileName: FIND_PRODUCT_DETAIL_BY_ID_CART,
              excludedAttribute:
                mySite?.storeCfg?.userData?.PDP_FILTER_ATTRIBUTE &&
                mySite?.storeCfg?.userData?.PDP_FILTER_ATTRIBUTE.length > 0 &&
                mySite?.storeCfg?.userData?.PDP_FILTER_ATTRIBUTE,
            };
            productsService
              .findProductsUsingGET(params)
              .then((productData: any) => {
                if (productData.data.contents && productData.data.contents.length > 0) {
                  const fpcProducts: any = [];
                  productData.data.contents.forEach((product) => {
                    fpcProducts.push(product.partNumber);
                  });
                  setfpcProductIds(fpcProducts);
                }
              })
              .catch((e) => {
                console.log("Could not retrieve product details page informarion", e);
              });
          }
        })
        .catch((e) => {
          console.log("could not retreive the parent product details", e);
        });
    }
  }, [miniCartItems]);

  useEffect(() => {
    if (FpcProductIds.length > 0) {
      fetchRemoteFeed();
    } else {
      setIsFetched(false); // Hide the modal if no FPC product IDs
    }

    return () => {
      cancels.forEach((cancel) => cancel());
    };
  }, [FpcProductIds]);

  const fetchRemoteFeed = async () => {
    // setError(false);
    // setLoading(true);

    const ebProfileid = localStorageUtil.getCrownPeakProfileId();
    const url = new URL(
      `${site.crownpeakUrl}/widget/${widgetId}/recommendations${ebProfileid ? `/${ebProfileid}` : ""}`
    );

    url.searchParams.append("fields", "id,recommendations.id");
    if (variables) {
      const variableValue = variables.match(/FPC\d+/g);
      const VariableObjectArray = { $productId: variableValue };
      url.searchParams.append("variables", JSON.stringify(VariableObjectArray));
    }

    // https://api-console.early-birds.io/widget/5e54f8e291c86c8a8c8fea82/recommendations/{{ebProfileId}}?variables={"$productId":"FPC5768157"}
    await fetch(url)
      .then((response) => {
        return response.json();
      })
      .then((resp) => {
        //const products: any[] = [];
        if (resp && resp.recommendations) {
          const params: any = {
            contractId: contract,
            storeId: storeId,
            widget: widgetName,
            excludedAttribute:
              mySite?.storeCfg?.userData?.PLP_FILTER_ATTRIBUTE &&
              mySite?.storeCfg?.userData?.PLP_FILTER_ATTRIBUTE.length > 0 &&
              mySite?.storeCfg?.userData?.PLP_FILTER_ATTRIBUTE,
            profileName: PRODUCT_SUMMARY_PROFILE,

            cancelToken: new CancelToken(function executor(c) {
              cancels.push(c);
            }),
            partNumber: resp.recommendations?.map((p) => p.id),
          };
          productsService
            .findProductsUsingGET(params)
            .then((productData: any) => {
              const products = productData?.data?.contents || []; // Fallback to empty array
              const productMap = new Map(products.map((product: any) => [product.partNumber, product]));

              // Sort products based on the order of recommendations
              const sortedProducts = resp.recommendations.map((rec: any) => {
                return productMap.get(rec.id);
              });

              setIsFetched(true);
              setIsCartData({
                widgetId,
                recommendationId: resp.id,
                products: sortedProducts.filter((item) => item && item.partNumber), // Set data with merged recommendations and products
              });
            })

            .catch((e) => {
              // setError(true);
              console.log("Could not retrieve product details page informarion", e);
            });
        }
      })
      .catch((_err) => {
        // If remote fetch fail fallback to the local feed
        setIsFetched(true);
        console.log("error fetching remote feeds", _err);
        // setError(true);
      });
    // setLoading(false);
  };

  return (
    <>
      {miniCartItems.length > 0 && isFetched && (
        <StyledModal open={isFetched} onClose={() => handleCloseModal()} aria-labelledby="" aria-describedby="">
          <StyledBox className="modal-container modal-add-to-cart">
            <StyledIconButton onClick={() => handleCloseModal()} className="modal-close">
              <SvgClose color="black" />
            </StyledIconButton>
            <StyledBox sx={{ overflowY: { xs: "scroll", md: "auto" }, maxHeight: { xs: "75vh", md: "auto" } }}>
              <StyledGrid container px={5} columns={9} className="" sx={{ textAlign: { xs: "center", md: "left" } }}>
                <StyledGrid item xs={9} md="auto">
                  <SvgCheckThick className="rounded-green big" />
                </StyledGrid>
                <StyledGrid item xs={9} md flexGrow="1" pl={{ xs: 0, md: 5 }} pt={{ xs: 6, md: 0 }}>
                  <StyledTypography variant="h5">
                    {`${qty} produit${qty > 1 ? "s" : ""}`}
                    <Hidden smDown>
                      <br />
                    </Hidden>
                    {`${qty > 1 ? " ont été ajoutés à votre panier" : " a été ajouté à votre panier"} `}
                  </StyledTypography>
                </StyledGrid>
                <StyledGrid item xs={9} md="auto" pt={{ xs: 4, md: 2 }} pr={4} sx={{ order: { xs: "4", md: "3" } }}>
                  <StyledButton
                    onClick={() => handleCloseModal()}
                    startIcon={<SvgChevronBack />}
                    size="medium"
                    color="secondary"
                    variant="text"
                    className=""
                    sx={{ textTransform: "none", fontWeight: 400, width: { xs: "100%", md: "auto" } }}>
                    Continuer mes achats
                  </StyledButton>
                </StyledGrid>
                <StyledGrid item xs={9} md="auto" pt={{ xs: 4, md: 0 }} sx={{ order: { xs: "3", md: "4" } }}>
                  <StyledLink to={ROUTES.CART} onClick={() => handleCloseModal()}>
                    <StyledButton
                      size="medium"
                      color="secondary"
                      className=""
                      sx={{ width: { xs: "100%", md: "auto" } }}>
                      Voir mon panier
                    </StyledButton>
                  </StyledLink>
                </StyledGrid>
              </StyledGrid>
              <StyledOrderItem>
                {miniCartItems.map((item, index) => {
                  const productType = storeUtil.getTypeProduct(item.attributes, item);
                  const salesUnit = 1 / item.xitem_COEFF_CONVERSION;

                  // item.xitem_TYPE_CONVERSION == "QUANTITE"
                  //   ? 1 / item.xitem_COEFF_CONVERSION
                  //   : item.xitem_COEFF_CONVERSION;

                  // storeUtil.getSalesUnitProduct(item.attributes, productType);

                  let totalAdjustmentValueforOne: any = 0;
                  if (item.adjustment && item.adjustment.length > 0) {
                    item.adjustment.forEach((adjustmentItem) => {
                      if (adjustmentItem.displayLevel === "OrderItem") {
                        totalAdjustmentValueforOne =
                          totalAdjustmentValueforOne + parseFloat(adjustmentItem?.xadju_unitLevelAdjustment);
                      }
                    });
                  }

                  //divide by item?.quantity because order api is same for a singular product but user can add same product to cart with different quantity at different time so can't use frontend quantity state
                  // const totalAdjustmentValueforOne: number = parseFloat(totalAdjustment) / parseFloat(item?.quantity);
                  const unitPrice = parseFloat(item.unitPrice);
                  const ecoPartTTC = parseFloat(item.xitem_ECOPART_TTC);
                  const promotionalOfferPrice =
                    Math.abs(totalAdjustmentValueforOne) > 0
                      ? preciseCalculate([unitPrice, totalAdjustmentValueforOne, ecoPartTTC])
                      : null;

                  const meterPrice = item.boxPrice;
                  // Math.floor(
                  //   // salesUnit *
                  //   (promotionalOfferPrice && promotionalOfferPrice > 0
                  //     ? promotionalOfferPrice
                  //     : salesUnit * item.price.filter((price) => price.usage.toLowerCase() === "offer")[0].value) *
                  //     100
                  // ) / 100;
                  const promoPercent: number = storeUtil.getPromotionalPercentage(item);
                  return (
                    <StyledBox key={index} px={5}>
                      <StyledGrid
                        item
                        container
                        columns={{ xs: 4, md: 9 }}
                        xs={9}
                        sm={8}
                        mx={"auto"}
                        mt={{ xs: 10, sm: 8 }}
                        mb={{ xs: 5, sm: 10 }}
                        className="order-item modal"
                        alignItems="center">
                        <StyledGrid item xs={1.5} className="order-item-left">
                          <StyledBox p={2} className="order-item-left-image no-border">
                            <ImageLayout
                              src={storeUtil.getUrlPicture(item.thumbnailRaw)}
                              alt={item.name}
                              width="100%"
                            />
                          </StyledBox>
                        </StyledGrid>
                        <StyledGrid
                          container
                          item
                          xs={7.5}
                          md={10}
                          px={{ xs: 4, sm: 5 }}
                          pt={{ xs: 4, sm: 0 }}
                          pb={{ xs: 5, sm: 0 }}
                          alignItems="center"
                          className="order-item-right">
                          <StyledGrid item xs={12} md>
                            <StyledTypography variant="subtitle2" component="p" pb={1} className="">
                              {item.name}
                            </StyledTypography>
                            <ProductAvis avis={storeUtil.getAvisProduct(item)} />
                            <StyledTypography variant="body2" component="p" pt={3}>
                              {storeUtil.getAttributesProduct(item)}
                            </StyledTypography>
                          </StyledGrid>
                          <StyledGrid item pl={{ xs: 0, sm: 3 }} pt={{ xs: 3, sm: 0 }}>
                            <FormattedProductPriceDisplay
                              hideInformation
                              quantity={qty}
                              productPartNumber={item.partNumber}
                              pourcentPromo={promoPercent}
                              ecopart={item?.xitem_ECOPART_TTC * qty}
                              prices={item.price}
                              promotionalMin={
                                promotionalOfferPrice && promotionalOfferPrice > 0 ? unitPrice + ecoPartTTC : null
                              }
                              showPriceIsNull={true}
                              promotionalOfferPrice={promotionalOfferPrice}
                              type={productType}
                              meterSize={
                                productType === PRODUCT_TYPE.COLISE || productType === PRODUCT_TYPE.BOTTE
                                  ? salesUnit
                                  : null
                              }
                              meterPrice={
                                productType === PRODUCT_TYPE.COLISE || productType === PRODUCT_TYPE.BOTTE
                                  ? meterPrice
                                  : null
                              }
                              fromCartConfirmation={true}
                            />
                          </StyledGrid>
                        </StyledGrid>
                      </StyledGrid>
                    </StyledBox>
                  );
                })}
              </StyledOrderItem>
              {isCartData?.products.length > 0 && FpcProductIds && FpcProductIds.length > 0 && (
                <div onClick={() => handleCloseModal()}>
                  <ProductRecommended
                    title="Les clients Lapeyre ont aussi acheté ces produits"
                    description=""
                    isInModal={true}
                    isModalLoader={false}
                    bgBeige={true}
                    origin="CartConfirmModal"
                    widgetId={WIDGET_CROWNPEAK.ADD_TO_CART}
                    variable={`{ "$productId": ${FpcProductIds.map((item) => `"${item}"`)} }`}
                    isCartData={isCartData}
                  />
                </div>
              )}
            </StyledBox>
          </StyledBox>
        </StyledModal>
      )}
    </>
  );
};

export default CartConfirmModal;
