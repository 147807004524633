/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Services - Account and Contract
 * These services provide APIs to manage accounts and contracts.  a contract is an agreement that represents the terms and conditions that apply to a transaction. An account is a relationship between the merchant and the financial institution that processes transactions for that merchant.
 *
 * The version of the OpenAPI document: 9.1.6
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Commentaires } from './commentaires';
import { LivraisonType } from './livraison-type';
import { OrderItem } from './order-item';
import { SavInfos } from './sav-infos';
import { SuiviCommande } from './suivi-commande';
import { SuiviDevis } from './suivi-devis';
import { SuiviInstallation } from './suivi-installation';
import { UniversDocument } from './univers-document';
import { UniversProduit } from './univers-produit';

/**
 * 
 * @export
 * @interface Order
 */
export interface Order {
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    acompteRegle?: number;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    agentCreateur?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    agentModificateur?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    codeFamilleFinancement?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    codeFinancement?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    codeMotifAnnul?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    codeOrganismeFinancement?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    codeTransport?: string;
    /**
     * 
     * @type {Commentaires}
     * @memberof Order
     */
    commentairesGeneraux?: Commentaires;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    creationDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    customerId?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    dateDispoReel?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    dateDisponibiliteReelle?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    dateMADMagasin?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    dateMiseADispo?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    documentId?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    documentRef?: string;
    /**
     * 
     * @type {Array<OrderItem>}
     * @memberof Order
     */
    items?: Array<OrderItem>;
    /**
     * 
     * @type {LivraisonType}
     * @memberof Order
     */
    livraisonType?: LivraisonType;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    magasinCreation?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    magasinOrigine?: string;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    mntEcopartHt?: number;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    mntPromoHt?: number;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    mntPromoTtc?: number;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    mntRemiseHt?: number;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    mntRemiseTtc?: number;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    mntTvaSurEcopart?: number;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    modeReglement?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    modificationDate?: string;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    montantFinancement?: number;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    montantNetHT?: number;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    montantNetHT_facture?: number;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    montantNetTTC?: number;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    montantNetTTC_facture?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof Order
     */
    nextDocs?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    partPoseTTC?: number;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    precDocumentCreationDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    precDocumentId?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    precDocumentType?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    refChantierArtisan?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    refCommandeArtisan?: string;
    /**
     * 
     * @type {SavInfos}
     * @memberof Order
     */
    savInfos?: SavInfos;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    shippingMode?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    sourceSystem?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    sousType?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    statutDoc?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    statutTransaction?: OrderStatutTransactionEnum;
    /**
     * 
     * @type {SuiviCommande}
     * @memberof Order
     */
    suiviCommande?: SuiviCommande;
    /**
     * 
     * @type {SuiviDevis}
     * @memberof Order
     */
    suiviDevis?: SuiviDevis;
    /**
     * 
     * @type {SuiviInstallation}
     * @memberof Order
     */
    suiviInstallation?: SuiviInstallation;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    universDoc?: string;
    /**
     * 
     * @type {UniversDocument}
     * @memberof Order
     */
    universDocument?: UniversDocument;
    /**
     * 
     * @type {UniversProduit}
     * @memberof Order
     */
    universProduit?: UniversProduit;
    /**
     * 
     * @type {boolean}
     * @memberof Order
     */
    vrProject?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    webId?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum OrderStatutTransactionEnum {
    Prep = 'PREP',
    Disp = 'DISP',
    Annl = 'ANNL',
    Comp = 'COMP'
}



