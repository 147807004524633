//Standard libraries
import React from "react";
import { StyledGrid } from "@hcl-commerce-store-sdk/react-component";
import { StyledContainer } from "../../styled-mui/container/styled-container";
import { RegistrationNewsletter } from "./RegistrationNewsletter";
import { SvgFacebook } from "../svg/facebook";
import { SvgPinterest } from "../svg/pInterest";
import { SvgYoutube } from "../svg/youtube";
import { SvgInstagram } from "../svg/instagram";
import { StyledTypography, StyledIconButton, StyledBox } from "../../styled-mui";
import AsyncCall from "../../_foundation/gtm/async.service";
import { CLICK } from "../../_foundation/constants/gtm";
import { useTranslation } from "react-i18next";

/**
 * FooterReassurance component
 *
 * @param props
 */
const FooterContact: React.FC = (props: any) => {
  const { t } = useTranslation();

  return (
    <StyledBox sx={{ bgcolor: "tertiary.light", textAlign: "center", width: "100%" }}>
      <StyledContainer className="no-max-width">
        <StyledGrid container py={15}>
          <StyledGrid item xs={12} sm={6}>
            <RegistrationNewsletter cid="registrationNewsletter-page" />
          </StyledGrid>
          <StyledGrid
            container
            item
            xs={12}
            sm={6}
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
            mt={{
              xs: 10,
              sm: 0,
            }}>
            <StyledGrid item mb={2}>
              <StyledTypography variant="h5" component="div">
                {t("Footer.Contact.FollowUs.Title")}
              </StyledTypography>
            </StyledGrid>
            <StyledGrid item mb={4}>
              <StyledTypography variant="body2" component="div" sx={{ maxWidth: "460px" }}>
                {t("Footer.Contact.FollowUs.Description")}
              </StyledTypography>
            </StyledGrid>
            <StyledGrid item>
              <a
                href="https://www.facebook.com/Lapeyre"
                target="_blank"
                rel="noreferrer"
                onClick={() => {
                  AsyncCall.sendClick(CLICK.FOOTER, {
                    textclick: "Facebook_https://www.facebook.com/Lapeyre",
                  });
                }}>
                <StyledIconButton color="black" className="footer-social-link" title="Facebook">
                  <SvgFacebook />
                </StyledIconButton>
              </a>
              <a
                href="https://www.pinterest.fr/lapeyre/"
                target="_blank"
                rel="noreferrer"
                onClick={() => {
                  AsyncCall.sendClick(CLICK.FOOTER, {
                    textclick: "Pinterest_https://www.pinterest.fr/lapeyre/",
                  });
                }}>
                <StyledIconButton color="black" className="footer-social-link" title="Pinterest">
                  <SvgPinterest />
                </StyledIconButton>
              </a>
              <a
                href="https://www.instagram.com/lapeyreofficiel/"
                target="_blank"
                rel="noreferrer"
                onClick={() => {
                  AsyncCall.sendClick(CLICK.FOOTER, {
                    textclick: "Instagram_https://www.instagram.com/lapeyreofficiel/",
                  });
                }}>
                <StyledIconButton color="black" className="footer-social-link" title="Instagram">
                  <SvgInstagram />
                </StyledIconButton>
              </a>
              <a
                href="https://www.youtube.com/c/lapeyre"
                target="_blank"
                rel="noreferrer"
                onClick={() => {
                  AsyncCall.sendClick(CLICK.FOOTER, {
                    textclick: "Youtube_https://www.youtube.com/c/lapeyre",
                  });
                }}>
                <StyledIconButton color="black" className="footer-social-link" title="Youtube">
                  <SvgYoutube />
                </StyledIconButton>
              </a>
            </StyledGrid>
          </StyledGrid>
        </StyledGrid>
      </StyledContainer>
    </StyledBox>
  );
};

export default FooterContact;
