//Standard libraries
import React, { useEffect, useState } from "react";
import { StyledGrid, StyledIconButton } from "@hcl-commerce-store-sdk/react-component";
import { StyledBox, StyledTypography } from "../../styled-mui";
import { SvgClose } from "../svg/close";
//foundation
import { localStorageUtil } from "../../_foundation/utils/storageUtil";
import HTMLReactParser from "html-react-parser";
import { scrollToTop } from "../../utils/scrollToUtil";
import useFetchStaticJson from "../../_foundation/hooks/use-static-json";
/**
 * BannerInfo component
 */

const BannerInfo: React.FC = (props) => {
  const [banner, setBanner] = useState<any>([]);

  const handleClose = (event) => {
    localStorageUtil.setBannerId(banner.map((b) => b.identifier).join("-"));
    setBanner(null);
    scrollToTop();
  };

  const { data, isLoading, error } = useFetchStaticJson({ file: "banner-event.json", isLocal: false });

  useEffect(() => {
    if (data) {
      const bannerContent = data?.bannerContents || [];
      const newBanner: any[] = [];
      bannerContent?.forEach((banner) => {
        if (banner != null) {
          const sDateStart = banner.dateStart;
          const sDateEnd = banner.dateEnd;
          const dateStart = Date.parse(sDateStart);
          const dateEnd = Date.parse(sDateEnd);
          const now = Date.now();
          if ((!sDateStart && !sDateEnd) || (dateStart <= now && dateEnd >= now)) {
            const newMessage = HTMLReactParser(banner.textDefilant);
            const ids = localStorageUtil.getBannerId();
            let find = false;
            ids?.split("-")?.forEach((id) => {
              if (id !== banner.id) {
                find = true;
                return;
              }
            });
            //
            if (!find) {
              newBanner.push({
                message: newMessage,
                identifier: banner.id,
              });
            }
          }
        }
      });

      if (newBanner.length > 0) {
        setBanner(newBanner);
        scrollToTop();
      }
    }
  }, [data]);

  if (isLoading || error) {
    return <></>;
  }

  return (
    <>
      {banner?.length > 0 && (
        <StyledGrid
          container
          className="banner-info"
          px={{
            xs: 5,
            md: 6,
          }}>
          <StyledGrid item xs={12}>
            <StyledBox className="banner-info-scroll">
              <StyledTypography variant="body2" component="div" className="banner-info-text">
                {banner.map((b, index) => (
                  <React.Fragment key={index}>{b.message}</React.Fragment>
                ))}
              </StyledTypography>
            </StyledBox>
            <StyledIconButton onClick={handleClose} className="banner-info-svg" title="Fermer">
              <SvgClose color="gray" className="" />
            </StyledIconButton>
          </StyledGrid>
        </StyledGrid>
      )}
    </>
  );
};

export default BannerInfo;
