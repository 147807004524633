import store from "../redux/store";
import { USER_ZIPCODE } from "../_foundation/constants/user";
import { localStorageUtil } from "../_foundation/utils/storageUtil";

export const initUserZipcode = (details) => {
  const filterPrimaryAddress = details?.contact?.filter((address) => address.primary === "true");
  const zipToSave =
    filterPrimaryAddress && filterPrimaryAddress.length > 0 ? filterPrimaryAddress[0].zipCode : details.zipCode;
  if (
    !localStorageUtil.get(USER_ZIPCODE) ||
    localStorageUtil.get(USER_ZIPCODE) === undefined ||
    localStorageUtil.get(USER_ZIPCODE) === "undefined"
  ) {
    localStorageUtil.set(USER_ZIPCODE, zipToSave);
  }
};

export const setUserZipcode = (zipcode) => {
  localStorageUtil.set(USER_ZIPCODE, zipcode);
};

export const getUserZipcode = () => {
  const state = store.getState();
  const zipcodeStorage = localStorageUtil.get(USER_ZIPCODE);
  const zipcodeUser = state?.user?.details?.zipCode;
  const zipcodeAddr = state?.account?.address?.zipCode;

  return zipcodeStorage || zipcodeUser || zipcodeAddr;
};

export function buildAddressData(address: any) {
  let finalAddressData: any = { ...address };

  let fullNameString: string = "";
  if (finalAddressData.firstName !== undefined && finalAddressData.firstName !== "") {
    fullNameString = finalAddressData.firstName;
  }
  if (finalAddressData.lastName !== undefined && finalAddressData.lastName !== "") {
    if (fullNameString !== "") {
      fullNameString += " ";
    }
    fullNameString += finalAddressData.lastName;
    finalAddressData = {
      ...finalAddressData,
      fullNameString: fullNameString,
    };
  }

  const cityStateZipList: string[] = [];
  if (finalAddressData.zipCode !== undefined && finalAddressData.zipCode !== "") {
    cityStateZipList.push(finalAddressData.zipCode);
  }
  if (finalAddressData.city !== undefined && finalAddressData.city !== "") {
    cityStateZipList.push(finalAddressData.city);
  }

  if (cityStateZipList.length > 0) {
    const cityStateZipString = cityStateZipList.join(" ");
    finalAddressData = {
      ...finalAddressData,
      cityStateZipString: cityStateZipString,
    };
  }
  return finalAddressData;
}
