import styled from "@mui/styled-engine-sc";
import SvgIcon from "@mui/material/SvgIcon";

const CustomSvg = styled((props) => (
  <SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} />
))`
  ${({ theme }) => `
    width:16px;
    height:16px;

    &.medium {
      width:20px;
      height:20px;
    }

    &.big {
      width:24px;
      height:24px;
    }
  `}
`;

export const SvgIconProject = (props) => {
  const { className = "" } = props;

  return (
    <CustomSvg {...{ className }}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.00083892 2.53346C0.00083892 1.18688 1.09007 0.0976562 2.43664 0.0976562C3.78321 0.0976562 4.87244 1.18688 4.87244 2.53346V3.00188H11.4773C11.8469 3.00188 12.1465 3.30148 12.1465 3.67105C12.1465 4.04063 11.8469 4.34023 11.4773 4.34023H4.87244V11.1993C4.87244 11.5689 4.57284 11.8685 4.20326 11.8685C3.83369 11.8685 3.53409 11.5689 3.53409 11.1993C3.53409 10.5919 3.04406 10.1018 2.43664 10.1018C1.82922 10.1018 1.33919 10.5919 1.33919 11.1993C1.33919 11.2251 1.33773 11.2505 1.33489 11.2756C1.3743 11.9011 1.89499 12.3971 2.53032 12.3971H11.4772C11.8468 12.3971 12.1464 12.6967 12.1464 13.0663C12.1464 13.4359 11.8468 13.7355 11.4772 13.7355H2.53032C1.13021 13.7355 -0.00585938 12.5994 -0.00585938 11.1993C-0.00585938 11.167 -0.00357566 11.1353 0.00083892 11.1042V2.53346ZM1.33919 9.0236C1.66888 8.85719 2.04169 8.76349 2.43664 8.76349C2.83159 8.76349 3.2044 8.85719 3.53409 9.0236V2.53346C3.53409 1.92604 3.04406 1.43601 2.43664 1.43601C1.82922 1.43601 1.33919 1.92604 1.33919 2.53346V9.0236ZM9.08886 5.07731L11.8786 7.39187V11.4067H6.29103V7.39124L9.08886 5.07731ZM7.62938 8.02113V10.0684H8.41901V8.93747H9.75736V10.0684H10.5403V8.0205L9.08751 6.81519L7.62938 8.02113Z"
      />
    </CustomSvg>
  );
};
