/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2021
 *
 *==================================================
 */
//Get product info
export const UPDATE_CATEGORIES_STATE = "UPDATE_CATEGORIES_STATE_REQUESTED";
export const UPDATE_HEADER_LINK_STATE = "UPDATE_HEADER_LINK_STATE_REQUESTED";
export const UPDATE_CATEGORY_STATE = "UPDATE_CATEGORY_STATE_REQUESTED";
export const UPDATE_TOP_CATEGORY_STATE = "UPDATE_TOP_CATEGORY_STATE_REQUESTED";
export const UPDATE_FILTERED_CATEGORY_STATE = "UPDATE_FILTERED_CATEGORY_STATE_REQUESTED";
