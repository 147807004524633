/* eslint-disable react-hooks/exhaustive-deps */
/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
import React, { useState, useEffect, useRef, useMemo, ReactNode } from "react";
import { useLocation, useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { Canceler } from "axios";
import { useTranslation } from "react-i18next";
import * as ROUTES from "../../constants/routes";
import CartButton from "./CartButton";
import MegaMenu from "./MegaMenu";
import ExpandedMenu from "./ExpandedMenu";
import { SearchBar } from "../elements/search-bar";
import { userNameSelector } from "../../redux/selectors/user";
import { addressDetailsSelector } from "../../redux/selectors/account";
import { StoreLocatorButton } from "./storeLocatorButton";
import { StyledHeader } from "./styled-header";
import { StyledGrid } from "@hcl-commerce-store-sdk/react-component";
import { SvgMyAccount } from "../svg/myAccount";
import { SvgMenuIcon } from "../svg/menuIcon";
import BannerInfo from "./BannerInfo";
import { numItemsSelector } from "../../redux/selectors/order";
import AccountSidebar from "../elements/account-sidebar/AccountSidebar";
import { ImageLayout } from "../elements/image";
import { useStoreLocatorValue } from "../../_foundation/context/store-locator-context";
import { seoSelector } from "../../redux/selectors/seo";
import { localStorageUtil } from "../../_foundation/utils/storageUtil";
import { LOCAL_STORAGE_KEY_POPPER_EXP } from "../../constants/common";
import useUpdateEffect from "../../utils/hooks/useUpdateEffect";
import AsyncCall from "../../_foundation/gtm/async.service";
import { CLICK } from "../../_foundation/constants/gtm";
import { useCategoryTop } from "../../_foundation/hooks/use-category-top";
import { GetTopCategorySelector } from "../../redux/selectors/category";
import { Hidden /*useMediaQuery, useTheme */ } from "@mui/material";
import {
  StyledButton,
  StyledLink,
  StyledTypography,
  StyledBox,
  StyledIconButton,
  StyledSwipeableDrawer,
  /* StyledPopper */
} from "../../styled-mui";
// import { STORES_OPEN_MODAL_ACTION } from "../../redux/actions/stores";
// import { modalType } from "../../types/store-types";
// import { SvgClose } from "../svg/close";
// import ChevronRightIcon from "@mui/icons-material/ChevronRight";
// import { CURRENT_USER, PAGE_TYPE } from "../../_foundation/constants/common";

interface HeaderProps {
  loggedIn: boolean;
}

const Buttonify = ({ children, ...props }) => {
  const { testId } = props;
  return (
    <StyledButton testId={testId} variant="text" color="black" {...props}>
      {children}
    </StyledButton>
  );
};

const renderNestedMenu = (items: Record<any, any>[], parentKey: string) => {
  const validItems = items.filter((item) => item?.seo?.href !== window.location.pathname); //filter out self canonical seo

  if (validItems.length === 0) {
    return null;
  }

  return (
    <ul key={parentKey}>
      {validItems.map((item: any, index: number) => {
        const href = item?.seo?.href || "";
        const itemKey = `${parentKey || "root"}-${item.id || index}`;
        return (
          <li key={itemKey}>
            <a href={href}>{item.name}</a>
            {item.children?.length > 0 && renderNestedMenu(item.children, itemKey)}
          </li>
        );
      })}
    </ul>
  );
};

/**
 * Header component
 * displays Header, Mini Cart and Mega Menu
 * @param props
 */
const Header: React.FC<HeaderProps> = (props: any) => {
  useCategoryTop(); // call categories API for navigation and other categories and sets global state

  const headerRef = useRef<HTMLDivElement>(null);
  const [scrollDir, setScrollDir] = useState("up");
  const [open, setOpen] = useState<boolean>(false);
  const [openAccountMenu, setOpenAccountMenu] = useState<boolean>(false);
  const navigate = useNavigate();
  const location: any = useLocation();
  const { pathname } = location;
  const hideHeaderRoutes = [
    ROUTES.CART,
    `${ROUTES.CHECKOUT}/${ROUTES.CHECKOUT_SHIPPING}`,
    `${ROUTES.CHECKOUT}/${ROUTES.CHECKOUT_PAYMENT}`,
    ROUTES.ORDER_CONFIRMATION,
  ];

  // const dispatch = useDispatch();
  // const theme = useTheme();
  // const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useTranslation();

  const numItemsCart = useSelector(numItemsSelector);
  const addressDetails = useSelector(addressDetailsSelector);
  const userNameDetails = useSelector(userNameSelector);
  const categories = useSelector(GetTopCategorySelector);
  const firstName =
    addressDetails?.attributes?.filter((attr) => attr?.pProfileAttrKey === "userProfileField2")[0]?.pProfileAttrValue ??
    userNameDetails?.firstName;
  const lastName = addressDetails?.displayName ?? userNameDetails?.lastName;
  const userName =
    firstName && lastName
      ? `${firstName.substr(0, 1).toUpperCase()}.${lastName[0].toUpperCase() + lastName.slice(1)}`
      : null;
  const zipCode = addressDetails?.zipCode ?? userNameDetails?.zipCode;
  const loggedIn = props.loggedIn;
  const cancels: Canceler[] = [];

  useEffect(() => {
    const threshold = 0;
    let lastScrollY = window.scrollY;
    let ticking = false;

    const element = document.getElementById("main");
    if (element) {
      setTimeout(() => {
        element.style.paddingTop = `${headerRef?.current?.clientHeight}px`;
      }, 500);
    }

    const updateScrollDir = () => {
      const scrollY = window.scrollY;
      if (headerRef?.current && headerRef.current.clientHeight < window.scrollY) {
        if (Math.abs(scrollY - lastScrollY) < threshold) {
          ticking = false;
          return;
        }
        setScrollDir(scrollY > lastScrollY ? "down" : "up");
        lastScrollY = scrollY > 0 ? scrollY : 0;
      }
      ticking = false;
    };

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir);
        ticking = true;
      }
    };

    window.addEventListener("scroll", onScroll);

    if (headerRef.current) {
      if (scrollDir === "down") {
        headerRef.current.classList.add("compact");
      } else {
        headerRef.current.classList.remove("compact");
      }
    }

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, [scrollDir]);

  useEffect(() => {
    cancels.splice(0, cancels.length).forEach((cancel) => {
      cancel();
    });
  }, []);

  const toggleOpen = () => {
    setOpenAccountMenu(false);
    setOpen(!open);
  };
  const turnOffOpen = () => setOpen(false);

  const onClose = () => setOpenAccountMenu(false);

  const getBoundingClientRectHeader = () => {
    const elementHeader = document.getElementById("header");
    if (elementHeader) {
      return elementHeader.getBoundingClientRect();
    }
  };

  const [openPopper, setOpenPopper] = useState<boolean>(false); // eslint-disable-line @typescript-eslint/no-unused-vars
  const { storeLocator } = useStoreLocatorValue();
  const selectedStore = storeLocator?.selectedStore || null;
  const seoInfo = useSelector(seoSelector);
  const pageParams = Object.values(seoInfo)[0];
  // const [arrowRef, setArrowRef] = useState(null);
  // const id = openPopper ? "simple-popper" : undefined;
  // const currentUser = localStorageUtil.get(CURRENT_USER);

  // const filterPrimaryAddress = currentUser?.details?.contact?.filter((address) => address.primary == "true");
  // const zipToSave =
  //   filterPrimaryAddress && filterPrimaryAddress.length > 0
  //     ? filterPrimaryAddress[0]?.zipCode
  //     : currentUser?.details?.zipCode;

  // const handleClosePopper = (_event: React.MouseEvent<HTMLElement>) => {
  //   setOpenPopper(false);
  //   localStorageUtil.setWithExpiry(LOCAL_STORAGE_KEY_POPPER_EXP, "header_popper_expiry_date", { days: 2 });
  // };

  useUpdateEffect(() => {
    if (pageParams) {
      const hasAlreadyClosedPopper = localStorageUtil.getWithExpiry(LOCAL_STORAGE_KEY_POPPER_EXP);
      if (!selectedStore && !hasAlreadyClosedPopper) {
        setOpenPopper(true);
      }
    }
  }, [pageParams]);

  // const handleOpenModal = () => {
  //   setOpenPopper(false);
  //   dispatch(STORES_OPEN_MODAL_ACTION({ modalType: modalType.MapsSelectStore }));
  // };

  const navigateToHome = () => {
    navigate(ROUTES.HOME);
    setOpenAccountMenu(false);
    AsyncCall.sendClick(CLICK.HEADER, { linkclick: "/" });
  };

  const SeoMenu: ReactNode | null = useMemo(() => {
    if (!categories?.length) {
      return null;
    }
    return renderNestedMenu(categories, "firstKey");
  }, [location.pathname, categories, renderNestedMenu]);

  return (
    <StyledHeader id="header" ref={headerRef}>
      {(hideHeaderRoutes.indexOf(pathname) === -1 ||
        (hideHeaderRoutes.indexOf(pathname) > -1 &&
          numItemsCart === 0 &&
          !pathname.includes(ROUTES.ORDER_CONFIRMATION))) && (
        <>
          <BannerInfo />
          <StyledGrid container alignItems="center" className="header-container">
            <StyledGrid item xs>
              <StyledGrid container alignItems="center">
                <Hidden mdUp>
                  <StyledGrid item ml={5}>
                    <button className="menu-hamburger" data-testid="menu-hamburger-element" onClick={toggleOpen}>
                      <SvgMenuIcon />
                    </button>
                  </StyledGrid>
                </Hidden>
                <StyledGrid item>
                  <div
                    className="header-branding"
                    onClick={navigateToHome}
                    style={{ cursor: pathname === "/" ? "default" : "pointer" }}>
                    <ImageLayout
                      src="/images/type-icons/lapeyre-logo.svg"
                      alt="Lapeyre"
                      width="213"
                      height="68"
                      className="company-header-logo"
                    />
                  </div>
                </StyledGrid>
                <Hidden mdDown>
                  <StyledGrid item className={`header-searchBarSection`} xs>
                    <SearchBar showSearchBar={true} />
                  </StyledGrid>
                </Hidden>
              </StyledGrid>
            </StyledGrid>
            <StyledGrid item>
              <StyledGrid container className="header-topbarSection" alignItems="center" direction="row">
                <Hidden mdDown>
                  <StoreLocatorButton />
                </Hidden>
                {!loggedIn ? (
                  <StyledGrid item className="separateur">
                    <StyledBox className="welcome-text" display="flex" flexDirection="column">
                      <StyledLink
                        variant="text"
                        to={ROUTES.SIGNIN}
                        color="black"
                        testId="header-action-account"
                        style={{ display: "flex" }}
                        onClick={() => {
                          AsyncCall.sendClick(CLICK.HEADER, { linkclick: ROUTES.SIGNIN });
                        }}>
                        <SvgMyAccount color="black" />
                        <Hidden mdDown>
                          <StyledTypography variant="body2" component="p" ml={2}>
                            {t("Header.Actions.MyAccount")}
                          </StyledTypography>
                        </Hidden>
                      </StyledLink>
                    </StyledBox>
                  </StyledGrid>
                ) : (
                  <StyledGrid item className="separateur">
                    <StyledBox className="" display="flex" flexDirection="column">
                      <Hidden mdUp>
                        <StyledIconButton
                          //color="secondary"
                          className="no-padding"
                          onClick={() => {
                            AsyncCall.sendClick(CLICK.HEADER, { linkclick: "open_account" });
                            setOpenAccountMenu(!openAccountMenu);
                          }}>
                          <SvgMyAccount />
                        </StyledIconButton>
                      </Hidden>
                      <Hidden mdDown>
                        <StyledLink
                          to={ROUTES.ACCOUNT}
                          onClick={() => {
                            AsyncCall.sendClick(CLICK.HEADER, { linkclick: ROUTES.ACCOUNT });
                          }}>
                          <Buttonify testId="header-sign-in">
                            <SvgMyAccount color="black" />
                            <StyledTypography variant="caption" component="p" ml={2} sx={{ textAlign: "left" }}>
                              {userName}
                              <br />
                              {zipCode && (
                                <strong>
                                  {t("Header.Actions.DeliverTo")} {zipCode}
                                </strong>
                              )}
                            </StyledTypography>
                          </Buttonify>
                        </StyledLink>
                      </Hidden>
                    </StyledBox>
                  </StyledGrid>
                )}
                <StyledGrid item pl={4} pr={4}>
                  <CartButton />
                </StyledGrid>
              </StyledGrid>
            </StyledGrid>
          </StyledGrid>{" "}
          <Hidden mdUp>
            <StyledBox className="bottom-padding-3 top-padding-3 border-top header-searchBarSection-mobile">
              <SearchBar showSearchBar={true} />
            </StyledBox>
          </Hidden>
          <StyledGrid className="hide seo-links">{SeoMenu}</StyledGrid>
          <Hidden mdDown>
            <ExpandedMenu topCategories={categories} />
          </Hidden>
          <Hidden mdUp>
            <MegaMenu topCategories={categories} open={open} closeMegaMenu={turnOffOpen}></MegaMenu>
          </Hidden>
          <Hidden mdUp>
            <StyledSwipeableDrawer
              anchor={"right"}
              open={openAccountMenu}
              onClose={() => setOpenAccountMenu(false)}
              onOpen={() => setOpenAccountMenu(true)}
              sx={{
                top: `${getBoundingClientRectHeader()?.height}px`,
                ".MuiBackdrop-root": {
                  top: `${getBoundingClientRectHeader()?.height}px`,
                },
                ".MuiDrawer-paperAnchorTop,.MuiDrawer-paperAnchorRight": {
                  marginTop: 0,
                  paddingTop: 0,
                  position: "relative",
                },
              }}>
              <StyledBox px={5} py={6} sx={{ bgcolor: "tertiary.light" }}>
                <StyledBox pb={6} sx={{ textAlign: "center" }}>
                  <StyledBox
                    p={2}
                    sx={{
                      display: "inline-block",
                      borderRadius: "100%",
                      border: 1,
                      borderColor: "gray.neutralGray300",
                      bgcolor: "white.main",
                      svg: { display: "block" },
                    }}>
                    <SvgMyAccount color="black" />
                  </StyledBox>
                  <StyledTypography component="p" variant="h5" sx={{ fontWeight: 400, textTransform: "none" }} pb={4}>
                    {userName}
                  </StyledTypography>
                  <StyledTypography component="p" variant="body2">
                    {t("Header.Actions.DeliverTo")} <strong>{zipCode}</strong>
                  </StyledTypography>
                </StyledBox>
                <AccountSidebar onClose={onClose} />
              </StyledBox>
            </StyledSwipeableDrawer>
          </Hidden>
        </>
      )}
      {/* {!selectedStore && pageParams?.page?.type === PAGE_TYPE.PRODUCT_PAGE && (
        <StyledPopper
          id={id}
          open={openPopper}
          placement="bottom-start"
          disablePortal={true}
          className={isMobile ? "popper-with-arrow text-left mobile" : "popper-with-arrow text-left desktop"}
          modifiers={[
            {
              name: "arrow",
              enabled: true,
              options: {
                element: arrowRef,
              },
            },
          ]}>
          <StyledBox component="span" className={isMobile ? "arrow-top left" : "arrow-top"} ref={setArrowRef} />
          <StyledBox px={6} py={5}>
            <StyledIconButton
              onClick={handleClosePopper}
              sx={{ position: "absolute", top: 0, right: 0 }}
              className="popper-close">
              <SvgClose color="black" />
            </StyledIconButton>{" "}
            <StyledTypography variant="body1" component="p" pb={1}>
              {t("Header.StorePopper.Title")}
            </StyledTypography>
            <StyledTypography variant="body2" component="p" pb={2}>
              {t("Header.StorePopper.Description")}
            </StyledTypography>
            <StyledButton
              variant="text"
              size="small"
              className="link-hover-animated"
              endIcon={<ChevronRightIcon />}
              color="secondary"
              sx={{ textTransform: "none", fontWeight: "400" }}
              onClick={handleOpenModal}>
              {t("Header.StorePopper.Button")}
            </StyledButton>
          </StyledBox>
        </StyledPopper>
      )} */}
    </StyledHeader>
  );
};

export { Header };
