import React from "react";
import styled from "@mui/styled-engine-sc";
import MenuItem from "@mui/material/MenuItem";

const CustomMenuItem = React.forwardRef((props: any, ref: any) => <MenuItem {...props} ref={ref} />);
/**
 * Styled component on @material-ui
 * @see https://material-ui.com/api/menu-item/
 */
const StyledMenuItem = styled(CustomMenuItem)`
  ${({ theme }) => `
  padding: 0 ${theme.spacing(3)} ${theme.spacing(2)} ${theme.spacing(3)};

  &.faq-select-menu-item {
    background: ${theme.palette.white.main};
    font-weight: 400;
    text-transform: uppercase;
    min-height: auto;
    padding: 0 ${theme.spacing(5)} ${theme.spacing(2)} ${theme.spacing(5)};
  }

  &.contact-menu-item {
    font-weight: 400;
    min-height: auto;
    padding: ${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(2)} ${theme.spacing(3)};
    background:  ${theme.palette.white.main};
    border-radius: 4px;

    &.MuiMenuItem-root:hover {
      font-weight: 700;
      background: ${theme.palette.secondary.extraLight};
      cursor: pointer;
    }

    &.MuiMenuItem-root.Mui-selected {
      font-weight: 700;
      cursor: default;
    }

    &.MuiMenuItem-root.Mui-disabled.Mui-selected {
      font-weight: 400;
    }
  }

  &.quotes-select-menu-item {
    font-weight: 400;
    min-height: auto;
    padding: 0 ${theme.spacing(5)} ${theme.spacing(2)} ${theme.spacing(5)};
  }

  &.all-model-select-menu-item {
    font-weight: 400;
    min-height: auto;
    padding: 0 ${theme.spacing(10)} ${theme.spacing(2)} ${theme.spacing(5)};
    background: ${theme.palette.white.main};

    &.Mui-disabled {
      color: ${theme.palette.text.primary};
      font-weight: 700;
      opacity: 1;
      text-transform: uppercase;
      padding-bottom: ${theme.spacing(3)};
    }
  }

  &.tool-product-choice-menu-item {
    white-space: wrap;
    padding: 0 ${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(2)};
    gap: 8px;
    min-height: initial;

    &.Mui-selected {
      font-weight: 700;
    }

    ${theme.breakpoints.down("sm")} {
      .MuiListItemText-primary {
        line-height: inherit;
      }
    }
  }


  &.MuiMenuItem-root {
    //color: ${theme.palette.text.primary};

    &:hover {
      background: none;
      //color: ${theme.palette.primary.main};
      a, span {
        //color: ${theme.palette.primary.main};
      }
    }

    &.Mui-selected,
    &.Mui-selected:hover {
      background: none;
    }

    &:visited {
      //color: ${theme.palette.text.primary};
    }

    &.bordered:not(:last-child) {
      border-bottom: 1px solid ${theme.palette.grey[300]};
    }
  }

  .MuiTouchRipple-root {
    color: ${theme.palette.primary.main};
  }


  `}
`;

export { StyledMenuItem };
