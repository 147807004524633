/* eslint-disable no-useless-computed-key */
import { call, put, select } from "redux-saga/effects";
import {
  STORES_FAVORITE_GET_ERROR_ACTION,
  STORES_FAVORITE_GET_SUCCESS_ACTION,
  STORES_LIST_GET_ERROR_ACTION,
  STORES_LIST_GET_REQUEST_ACTION,
  STORES_LIST_GET_SUCCESS_ACTION,
} from "../../actions/stores";
import storeLocatorService from "../../../_foundation/apis/transaction/storeLocator.service";
import { userAttributesSelector } from "../../selectors/user";
import { modalType, searchType } from "../../../types/store-types";
import {
  // storeLocatorAvailabilitiesSelector,
  storeLocatorModalTypeSelector,
  storeLocatorNearestStoresSelector,
  storesListSelector,
} from "../../selectors/stores";
import { GENERIC_ERROR_ACTION } from "../../actions/error";
import inventoryavailabilityService from "../../../_foundation/apis/transaction/inventoryavailability.service";
import { STORELISTRADIUS } from "../../../_foundation/constants/common";

export function* getFavoriteStores(action: any) {
  const { userStoresIds } = yield select(userAttributesSelector);

  try {
    if (userStoresIds.length) {
      const response = yield call(storeLocatorService.findByStoreUniqueIds, {
        ...action.payload,
        physicalStoreId: userStoresIds,
      });
      const stores = response.data;
      yield put(STORES_FAVORITE_GET_SUCCESS_ACTION({ stores: stores?.PhysicalStore }));
    } else {
      yield put(STORES_FAVORITE_GET_ERROR_ACTION("no user favorites stores"));
    }
  } catch (error) {
    yield put(STORES_FAVORITE_GET_ERROR_ACTION(error));
  }
}

export function* getStoresList(action: any) {
  const { parameters, type, productData, productStoreId, onGeoStoresFetchSuccess, onNoGeoStoresFound } = action.payload;
  try {
    const storesListState = yield select(storesListSelector);
    const nearestStoresState = yield select(storeLocatorNearestStoresSelector);
    // const availabilitiesState = yield select(storeLocatorAvailabilitiesSelector);

    let storesList: any[] = storesListState;
    let nearestStores: any[] = nearestStoresState;
    let storesIds: any[] | null = null;
    let availabilities: any[] = [];

    if (type === searchType.geoLocalisation) {
      const modalTypeSelected = yield select(storeLocatorModalTypeSelector);
      const responseStores = yield call(storeLocatorService.findStores, parameters);
      if (responseStores?.data?.PhysicalStore && responseStores.data?.PhysicalStore.length > 0) {
        storesList = responseStores.data?.PhysicalStore;
        if (onGeoStoresFetchSuccess) {
          onGeoStoresFetchSuccess();
        }
      } else if (storesListState.length === 0 && onNoGeoStoresFound) {
        //if empty stores and no stores found near geolocation
        onNoGeoStoresFound();
        const parameters: any = {
          query: { attributeName: "isPickup", attributeValue: "1" },
        };
        yield put(
          STORES_LIST_GET_REQUEST_ACTION({
            parameters,
            type: searchType.allStores,
          })
        );
        return;
      }

      // Get availability
      if (productData && responseStores) {
        const stores: any[] = [];
        for (let index = 0; index < storesList.length; index++) {
          stores.push(parseInt(storesList[index].storeName));
        }

        // let products = {};
        // productData?.forEach((sku) => {
        //   products = { ...products, ...{ [`${sku.sku}`]: sku.quantity } };
        // });
        if (modalTypeSelected !== modalType.MapsSelectStore) {
          const responseAvailability = yield call(inventoryavailabilityService.getInventoryAvailability, {
            widget: "GetAvailabilityFromSaga",
            body: {
              ["shopIds[]"]: stores,
              products: productData,
              productStoreId,
            },
          });
          if (responseAvailability && responseAvailability.data && responseAvailability.data.result) {
            availabilities = availabilities.concat(responseAvailability.data?.result);
          }
        }
      }
    } else if (type === searchType.postalCode) {
      const response = yield call(storeLocatorService.nearestStoreLocator, parameters);

      const nearestStoresResponse = response.data?.nearestShops;
      nearestStores = nearestStores.concat(nearestStoresResponse);
      storesIds = nearestStoresResponse?.map((store) => store.strLocId) || null;

      if (storesIds && storesIds.length > 0) {
        const responseStores = yield call(storeLocatorService.findStores, {
          widget: "GetStoresListFromSaga",
          latitude: String(nearestStoresResponse[0].lat),
          longitude: String(nearestStoresResponse[0].lng),
          radius: STORELISTRADIUS,
          siteLevelStoreSearch: false,
          maxItems: 3,
        });

        storesList = storesList
          ? storesList.concat(responseStores.data?.PhysicalStore)
          : responseStores.data?.PhysicalStore;
      } else {
        const _error = { errorKey: `_ERR_STORELIST_NOT_EXIST` };
        yield put(GENERIC_ERROR_ACTION(_error));
      }

      // Get availability
      if (productData && nearestStoresResponse) {
        const stores: any[] = [];
        for (let index = 0; index < storesList.length; index++) {
          stores.push(parseInt(storesList[index].storeName));
        }

        let products = {};
        productData?.forEach((sku) => {
          products = { ...products, ...{ [`${sku.sku}`]: sku.quantity } };
        });

        const responseAvailability = yield call(inventoryavailabilityService.getInventoryAvailability, {
          widget: "GetAvailabilityFromSaga",
          body: {
            ["shopIds[]"]: stores,
            products,
            productStoreId,
          },
        });
        if (responseAvailability && !responseAvailability.data.error) {
          availabilities = availabilities.concat(responseAvailability.data?.result);
        }
      }
    } else if (type === searchType.getFavUserStores) {
      const { userStoresIds } = yield select(userAttributesSelector);
      if (userStoresIds.length) {
        const responseIds = yield call(storeLocatorService.findByStoreUniqueIds, {
          widget: "GetStoresListFromSaga",
          physicalStoreId: userStoresIds,
        });
        if (responseIds && responseIds.data && responseIds.data.PhysicalStore) {
          storesList = responseIds.data?.PhysicalStore;
        }
      } else {
        throw new Error("No store found");
      }
    } else if (type === searchType.allStores) {
      const responseStores = yield call(storeLocatorService.findStoreByGeoNodeId, parameters);
      if (responseStores?.data?.PhysicalStore && responseStores.data?.PhysicalStore.length > 0) {
        storesList = storesList
          ? storesList.concat(responseStores.data?.PhysicalStore)
          : responseStores.data?.PhysicalStore;
      }
    }

    // const dataTmp = {
    //   recordSetCount: "1",
    //   resourceId:
    //     "https://commerce-preview.dev.lape.now.hclsoftware.cloud:5443/wcs/resources/store/21/storelocator/byStoreIds?physicalStoreId=715843001",
    //   PhysicalStore: [
    //     {
    //       country: "FRANCE",
    //       Description: [
    //         {
    //           displayStoreName: "Lapeyre Rouen",
    //         },
    //       ],
    //       Attribute: [
    //         {
    //           displayValue:
    //             '{"id_magasin":"27","dates":[{"date":"2023-05-08"},{"date":"2023-05-29"},{"date":"2023-11-11"}]}',
    //           displayName: "Fermetures exceptionnelles magasins",
    //           name: "exceptionalStoreClosing",
    //           value: "fermetureExceptionnelle_27",
    //         },
    //         {
    //           displayValue:
    //             '{"id_magasin":"27","horaires":[{"date":"2023-05-08","ouverture":"09:30","fermeture":"18:00","fermetureAM":"12:00","ouverturePM":"14:00"},{"date":"2023-05-29","ouverture":"09:30","fermeture":"18:00","fermetureAM":"12:00","ouverturePM":"14:00"},{"date":"2023-11-11","ouverture":"09:30","fermeture":"18:00","fermetureAM":"12:00","ouverturePM":"14:00"}]}',
    //           displayName: "Horaires ouverture exceptionnelles magasins",
    //           name: "exceptionalStoreOpening",
    //           value: "horaireOuvertureExceptionnelle_27",
    //         },
    //         {
    //           displayValue:
    //             '{"id_magasin":"27","horaires":[{"jour":"0","ouverture":"09:30","fermeture":"19:00","fermetureAM":"12:00","ouverturePM":"14:00"},{"jour":"1","ouverture":"09:30","fermeture":"19:00","fermetureAM":"12:00","ouverturePM":"14:00"},{"jour":"2","ouverture":"09:30","fermeture":"19:00","fermetureAM":"12:00","ouverturePM":"14:00"},{"jour":"3","ouverture":"09:30","fermeture":"19:00","fermetureAM":"12:00","ouverturePM":"14:00"},{"jour":"4","ouverture":"09:30","fermeture":"19:00","fermetureAM":"12:00","ouverturePM":"14:00"},{"jour":"5","ouverture":"09:30","fermeture":"19:00","fermetureAM":"12:00","ouverturePM":"14:00"}]}',
    //           displayName: "Horaires magasins",
    //           name: "storeSchedule",
    //           value: "horaire_27",
    //         },
    //       ],
    //       city: "Rouen",
    //       latitude: "49.44690",
    //       telephone1: "0235710840                      ",
    //       storeName: "27",
    //       uniqueID: "715843001",
    //       longitude: "1.06262",
    //       stateOrProvinceName: "Haute-Normandie",
    //     },
    //   ],
    //   recordSetStartNumber: "0",
    //   resourceName: "storelocator",
    //   recordSetTotal: "1",
    //   recordSetComplete: "true",
    // };
    // storesList = dataTmp.PhysicalStore;

    //let storesListSort = storesList;
    // if (nearestStores && nearestStores.length > 0) {
    //   storesListSort = nearestStores?.map((s) => {
    //     return { ...storesList.filter((f) => f.uniqueID === s.strLocId)[0], distance: s.distance };
    //   });
    // }

    yield put(STORES_LIST_GET_SUCCESS_ACTION({ storesList, nearestStores, availabilities }));
  } catch (error) {
    console.log("SAGA ERROR : ", error);
    yield put(STORES_LIST_GET_ERROR_ACTION(error));
  }
}
