/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */

//Standard libraries
import { AxiosPromise } from "axios";
//Foundation libraries
import { getSite } from "../../hooks/useSite";
import { site } from "../../constants/site";
import { StockApi, DeliveryDateApi } from "@hcl-commerce-store-sdk/typescript-axios-transaction";

const stockApiInstance = new StockApi(undefined, site.transactionContext);
const deliverApiInstance = new DeliveryDateApi(undefined, site.transactionContext);
const stockService = {
  // /**
  //  * Get the nearest store for dedicated postal code
  //  * @summary getNearestStores
  //  * @param {string} storeId The store identifier.
  //  * @param {ComLapeyreCommerceControllersNearestStoreRequest} comLapeyreCommerceControllersNearestStoreRequest The request object for stock.
  //  * @param {*} [options] Override http request option.
  //  * @throws {RequiredError}
  //  * @memberof StockApi
  //  */
  // nearestStore(parameters: any): AxiosPromise<any> {
  //   const siteInfo = getSite();
  //   const { storeId = siteInfo?.storeID, body, ...options } = parameters;
  //   return stockApiInstance.nearestStore(storeId, body, options);
  // },

  /**
   * Get the shipping date for the nearest and soonest delivering store.
   * @summary getShippingDate
   * @param {string} storeId The store identifier.
   * @param {ComLapeyreCommerceControllersShippingRequest} comLapeyreCommerceControllersShippingRequest The request object for shipping date.
   * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StockApi
   */
  shippingDate(parameters: any): AxiosPromise<any> {
    const siteInfo = getSite();
    const { storeId = siteInfo?.storeID, responseFormat, body, ...options } = parameters;
    return stockApiInstance.shippingDate(storeId, body, responseFormat, options);
  },

  deliveryDate(parameters: any): AxiosPromise<any> {
    const siteInfo = getSite();
    const { storeId = siteInfo?.storeID, responseFormat, body, ...options } = parameters;
    return deliverApiInstance.deliveryDate(storeId, body, options);
  },

  /**
   * Get the stock for dedicated store, product and quantity.
   * @summary getStocks
   * @param {string} storeId The store identifier.
   * @param {ComLapeyreCommerceControllersStockRequest} comLapeyreCommerceControllersStockRequest The request object for stock.
   * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StockApi
   */
  stock(parameters: any): AxiosPromise<any> {
    const siteInfo = getSite();
    const { storeId = siteInfo?.storeID, responseFormat, body, ...options } = parameters;
    return stockApiInstance.stock(storeId, body, responseFormat, options);
  },
};

export default stockService;
