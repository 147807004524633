/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Services - Account and Contract
 * These services provide APIs to manage accounts and contracts.  a contract is an agreement that represents the terms and conditions that apply to a transaction. An account is a relationship between the merchant and the financial institution that processes transactions for that merchant.
 *
 * The version of the OpenAPI document: 9.1.6
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 * @interface DocumentsFilteringRequest
 */
export interface DocumentsFilteringRequest {
    /**
     * The size of the page to be returned
     * @type {string}
     * @memberof DocumentsFilteringRequest
     */
    size?: string;
    /**
     * Filter the result list by montantttc
     * @type {string}
     * @memberof DocumentsFilteringRequest
     */
    montantTTC?: string;
    /**
     * Filter the result list by datefinvalidite
     * @type {string}
     * @memberof DocumentsFilteringRequest
     */
    dateFinValidite?: string;
    /**
     * Filter the result list by datemadispo
     * @type {string}
     * @memberof DocumentsFilteringRequest
     */
    dateMaDispo?: string;
    /**
     * Filter the result list by period of creation date of document ((y/Y = year, m/M = month and d/D = day) ex : [ (2y : 2 years) or (2m : 2 months) or (2d : 2 days) ]
     * @type {string}
     * @memberof DocumentsFilteringRequest
     */
    period?: string;
    /**
     * Filter the result list by the reference of previous document
     * @type {string}
     * @memberof DocumentsFilteringRequest
     */
    precDocumentId?: string;
    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof DocumentsFilteringRequest
     */
    page?: number;
    /**
     * Filter the result list by originstore
     * @type {string}
     * @memberof DocumentsFilteringRequest
     */
    originStore?: string;
    /**
     * Filter the result list by documentRef
     * @type {string}
     * @memberof DocumentsFilteringRequest
     */
    documentRef?: string;
    /**
     * Filter the result list by type of document (D = quotes, C = orders, F = invoice, A = credit note)
     * @type {string}
     * @memberof DocumentsFilteringRequest
     */
    type?: string;
    /**
     * Filter the result list by devisstatus. Possible values ATRANSFORMER/PERDU/PRESUMETRANSF/TRANSFERE/TRANSFORME
     * @type {string}
     * @memberof DocumentsFilteringRequest
     */
    devisStatus?: DocumentsFilteringRequestDevisStatusEnum;
    /**
     * Filter the result list by creationdate
     * @type {string}
     * @memberof DocumentsFilteringRequest
     */
    creationDate?: string;
    /**
     * Filter the result list by orderStatus. Possible values PREP/DISP
     * @type {string}
     * @memberof DocumentsFilteringRequest
     */
    orderStatus?: DocumentsFilteringRequestOrderStatusEnum;
    /**
     * List of document IDs to filter the results. If provided, ignores pagination and returns a single page with all matching orders.
     * @type {Array<any>}
     * @memberof DocumentsFilteringRequest
     */
    documentIds?: Array<any>;
}

/**
    * @export
    * @enum {string}
    */
export enum DocumentsFilteringRequestDevisStatusEnum {
    Atransformer = 'ATRANSFORMER',
    Perdu = 'PERDU',
    Presumetransf = 'PRESUMETRANSF',
    Transfere = 'TRANSFERE',
    Transforme = 'TRANSFORME'
}
/**
    * @export
    * @enum {string}
    */
export enum DocumentsFilteringRequestOrderStatusEnum {
    Prep = 'PREP',
    Disp = 'DISP'
}



