import SvgIcon from "@mui/material/SvgIcon";
import styled from "@mui/styled-engine-sc";
import { palette } from "../../themes/color-palette";

const CustomSvg = styled((props) => (
  <SvgIcon width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} />
))`
  ${({ theme }) => `
    width:18px;
    height:18px;
    fill: ${palette.white.main};

    &.small {
      width:12px;
      height:12px;
    }

    &.medium {
      width:20px;
      height:20px;
    }

    &.border-gray-300 {
      rect {
        stroke: ${palette.gray.neutralGray300};
      }
    }
  `}
`;

export const SvgRadioButtonIcon = (props) => {
  const { className = "" } = props;

  return (
    <CustomSvg {...{ className }}>
      <rect x=".5" y=".5" width="17" height="17" rx="8.5" stroke="#9E9E9E" />
    </CustomSvg>
  );
};
