import { useCallback, useRef, useState } from "react";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import { StyledCircularProgress } from "@hcl-commerce-store-sdk/react-component";
import { DEFAULT_LOCATION, GOOGLEMAPZOOM } from "../../../_foundation/constants/common";
// import { StoreLocatorSideList } from "../store-locator-side-list";
// import { StoreLocatorInfoBox } from "../store-locator-infobox";
import { StoreLocatorMarker } from "../store-locator-marker";
import { useDispatch, useSelector } from "react-redux";
import { mapCoordsSelector, storesListSelector } from "../../../redux/selectors/stores";
import useUpdateEffect from "../../../utils/hooks/useUpdateEffect";
import { STORES_MAP_COORDS_SET_ACTION } from "../../../redux/actions/stores";
import { StyledBox } from "../../../styled-mui";
import { useStoreLocatorValue } from "../../../_foundation/context/store-locator-context";

export const StoreLocatorWidgetMap: React.FC = () => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY || "",
  });
  const dispatch = useDispatch();

  const { storeLocator } = useStoreLocatorValue();
  const stores = useSelector(storesListSelector);
  const { center, zoom, storeIndex } = useSelector(mapCoordsSelector);

  const [map, setMap] = useState<any>(null);
  const firstMapLoad = useRef<boolean>(true);

  const selectedStore = storeLocator?.selectedStore || null;

  useUpdateEffect(() => {
    if (stores && stores.length && map) {
      const bounds = new window.google.maps.LatLngBounds();
      for (let i = 0; i < stores.length; i++) {
        bounds.extend(new window.google.maps.LatLng(stores[i].coordinates.lat, stores[i].coordinates.lng));
      }
      if (selectedStore && firstMapLoad.current) {
        loadFavStoreOnMapHandler();
        firstMapLoad.current = false;
      } else {
        map.fitBounds(bounds);
      }
    } else {
      dispatch(STORES_MAP_COORDS_SET_ACTION({ center: DEFAULT_LOCATION, zoom: GOOGLEMAPZOOM.INIT, storeIndex: null }));
    }
  }, [stores, map]); // eslint-disable-line react-hooks/exhaustive-deps

  useUpdateEffect(() => {
    if (map) {
      map.setZoom(zoom);
      map.setCenter(center);
    }
  }, [center, zoom]); // eslint-disable-line react-hooks/exhaustive-deps

  const onLoad = useCallback(function callback(map) {
    map.setOptions({ maxZoom: 15 });
    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  const loadFavStoreOnMapHandler = () => {
    dispatch(
      STORES_MAP_COORDS_SET_ACTION({
        center: selectedStore?.coordinates,
        zoom: GOOGLEMAPZOOM.ZOOMIN,
        storeIndex: null,
      })
    );
  };

  const onMarkerClick = (store: any, index: number) => {
    dispatch(
      STORES_MAP_COORDS_SET_ACTION({ center: store.coordinates, zoom: GOOGLEMAPZOOM.ZOOMIN, storeIndex: index })
    );
  };

  return (
    <StyledBox sx={{ height: { xs: "50vh", sm: "100%" }, width: "100%" }}>
      {isLoaded ? (
        <GoogleMap
          mapContainerStyle={{
            height: "100%",
            width: "100%",
            borderRadius: "0 8px 8px 0",
          }}
          zoom={zoom}
          center={center}
          onLoad={onLoad}
          onUnmount={onUnmount}>
          {stores &&
            stores.length > 0 &&
            stores.map((store, index) => {
              return (
                <StoreLocatorMarker
                  clickedIndex={storeIndex}
                  key={store.id}
                  store={store}
                  index={index}
                  onMarkerClick={() => onMarkerClick(store, index)}
                />
              );
            })}
        </GoogleMap>
      ) : (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>
          <StyledCircularProgress />
        </div>
      )}
    </StyledBox>
  );
};
