import { DISPLAY, EMPTY_STRING, OFFER } from "../constants/common";

/**
 * Set the product offer price and display price
 * @param priceArray
 */
export const getPrices = (priceArray: any[] | null) => {
  if (priceArray) {
    let offer: string = "0";
    let list: string = "0";
    for (const price of priceArray) {
      if (price.usage === OFFER && price.value !== EMPTY_STRING) {
        offer = price.value;
      } else if (price.usage === DISPLAY && price.value !== EMPTY_STRING) {
        list = price.value;
      }
    }
    return { offer: parseFloat(offer), list: parseFloat(list) };
  }
  return { offer: 0, list: 0 };
};

export const getPrice = (priceArray: any[] | null) => {
  if (priceArray) {
    let offer: string = "0";
    let list: string = "0";
    for (const price of priceArray) {
      if (price.usage === OFFER && price.value !== EMPTY_STRING) {
        offer = price.value;
      } else if (price.usage === DISPLAY && price.value !== EMPTY_STRING) {
        list = price.value;
      }
    }
    return offer !== "0" ? parseFloat(offer) : parseFloat(list);
  }
  return 0;
};

/**
 * Caculate the TVA from a price and TVA percentage
 * @param {number} price - product price
 * @param {number} [percent=20] - taxe percentage %
 */
export const getTvaPrice = (price: number, percent: number = 20) => {
  return +Number((price * percent) / 100).toFixed(2) || 0;
};
