import styled from "@mui/styled-engine-sc";
import IconButton from "@mui/material/IconButton";
import React, { useCallback, useEffect, useState } from "react";
import { palette } from "../../themes/color-palette";

const CustomMatIconButton = React.forwardRef((props: any, ref: any) => {
  const { preventDoubleClick = false, className = "", color, testId, onClick, disabled, ...re } = props;
  const dataTestId = testId ? { "data-testid": `icon-button-${testId}` } : {};
  const [iDisabled, setIDisabled] = useState<boolean>(false);

  const onClickWrapper = useCallback(
    (event: any) => {
      if (!preventDoubleClick) {
        onClick && onClick(event);
      } else if (!iDisabled) {
        setIDisabled(true);
        onClick && onClick(event);
      }
    },
    [onClick, iDisabled, preventDoubleClick]
  );

  useEffect(() => {
    setIDisabled(false);
    //React virtual dom try to reuse the component,
    //if the component was reused, the unmount event/initialize is not happenning.
    //to fix the iDisabled state issue, we can
    //1. set it to false upon testId changes.
    //2. add `key` prop to the component(so that React know this is complete different component)
    //here we are using #1 approach.
  }, [testId]);

  return (
    <IconButton
      {...dataTestId}
      {...re}
      {...{ size: "large" }}
      ref={ref}
      color={color}
      className={className}
      disabled={disabled}
      onClick={onClickWrapper}
    />
  );
});

const StyledIconButton = styled(CustomMatIconButton)`
  ${({ theme }) => `
    &.blink {
      animation: blinker 1s infinite;
    }

    @keyframes blinker {
        from { opacity: 1.0; }
        50% { opacity: 0.3; }
        to { opacity: 1.0; }
    }

    &:hover {
      background: none;
    }
    &.add-to-cart {
      background: ${palette.blue.dark};
      padding: ${theme.spacing(2.5)} ${theme.spacing(7.5)};
      border-radius: ${theme.spacing(2)};
      transition: border-radius 150ms;

      &:hover {
        border-radius: 25px;
      }
    }

    &.drawer-close {
      position: absolute;
      right: 0;
      z-index: 21;
      color: white;
      top: -52px;
    }

    &.outlined {
      border: currentColor 1px solid;
      background: none;
      border-radius: 8px;
      padding: ${theme.spacing(2)};
    }

    &.social-connect {
      width: 100%;
    }

    &.border-gray-300 {
      border: 1px solid ${theme.palette.gray.neutralGray300};
      border-radius: 8px;
      padding: 0 ${theme.spacing(3)} ;
      height: 34px;
      margin: 0 ${theme.spacing(2)} ${theme.spacing(2)} 0;

      &:last-child {
        margin-right: 0;
      }

      &.max-height-20 {
        svg {
          max-height: 20px;
        }
      }

      &.no-margin-bottom {
        margin-bottom: 0;
      }
      svg {
        max-height: 10px;
        width: auto;

        path {
          fill: ${theme.palette.gray.neutralGray700};
        }
      }
      /* &:hover {
        opacity: 0.9;
      } */
    }

    &.rounded-gray {
      border: ${theme.palette.gray.neutralGray300} 1px solid;
      background:  ${theme.palette.white.main};
      border-radius: 100%;
      padding: ${theme.spacing(2)};
    }

    &.filledSecondary {
      background: ${palette.blue.dark};
      border-radius: 8px;
      padding: ${theme.spacing(2)};
    }

    &.adornment-icon {
      font-size: 16px;

      &.big {
        .MuiSvgIcon-root {
          font-size: 24px;
        }
      }

      &:hover {
        background: none;
      }

      .MuiSvgIcon-root {
        font-size: 16px;
      }
    }

    &.padding-small {
      padding: ${theme.spacing(1.5)};
    }
    &.no-padding {
      padding: 0;
    }
  `}
`;

export { StyledIconButton };
