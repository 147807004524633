/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import getDisplayName from "react-display-name";
import Axios, { AxiosResponse, Canceler } from "axios";
import { useSite } from "./useSite";
import { useSelector } from "react-redux";
import { currentContractIdSelector } from "../../redux/selectors/contract";
import categoryService from "../../_foundation/apis/search/categories.service";
import storeUtil from "../../utils/storeUtil";
import { CATEGORY_FIND_IDENTIFIER, FIND_SUB_CATEGORIES } from "../../constants/common";
import { TOP_CATEGORIES_DEPTH_LIMIT } from "../../configs/catalog";
import {
  UPDATE_CATEGORIES_STATE_ACTION,
  UPDATE_FILTERED_CATEGORIES_STATE_ACTION,
  UPDATE_TOP_CATEGORIES_STATE_ACTION,
} from "../../redux/actions/category";
import { useDispatch } from "react-redux";

export const useCategoryTop = () => {
  const widgetName = getDisplayName("useCategoryTop");
  const { mySite } = useSite();
  const dispatch = useDispatch();
  const contract = useSelector(currentContractIdSelector);
  const CancelToken = Axios.CancelToken;
  const storeID: string = mySite.storeID;
  const cancels: Canceler[] = [];

  const payloadBase: any = {
    widget: widgetName,
  };

  const fetchCategory = async () => {
    try {
      const catParamsForNavCat: any = {
        storeId: storeID,
        depthAndLimit: TOP_CATEGORIES_DEPTH_LIMIT,
        query: {
          contractId: contract,
          profileName: FIND_SUB_CATEGORIES,
        },
        ...payloadBase,
      };

      const navCategories = await categoryService
        .getV2CategoryResourcesUsingGET(catParamsForNavCat)
        .then((res) => res.data.contents);

      const identifiers = storeUtil.getUnivers(navCategories).map((c) => c.identifier);
      const catParamsForFIELD2 = {
        identifier: identifiers,
        catalogId: mySite.catalogID,
        query: {
          contractId: contract,
          profileName: CATEGORY_FIND_IDENTIFIER,
        },
        cancelToken: new CancelToken(function executor(c) {
          cancels.push(c);
        }),
        ...payloadBase,
      };

      const field2Categories = await categoryService
        .getV2CategoryResourcesUsingGET(catParamsForFIELD2)
        .then((response: AxiosResponse<any>) => {
          return response?.data?.contents;
        });

      /* filter categories based on FIELD2 */
      const filteredNavCat = navCategories.filter((categoryItem: Record<any, any>) => {
        const compareCategoryItem = field2Categories.find((item) => {
          return item.id === categoryItem.id;
        });
        if (compareCategoryItem && compareCategoryItem.UserData?.[0]) {
          if ("FIELD2" in compareCategoryItem.UserData[0]) {
            return (
              compareCategoryItem.UserData[0].FIELD2 === "false" || compareCategoryItem.UserData[0].FIELD2 === "null"
            );
          }
        }
        return true;
      });

      /* filter categories to reorder them as navigational categories which are filtered by FIELD2 */
      const reOrderedField2Cat = filteredNavCat.map((catItem: Record<any, any>) => {
        const category = field2Categories.find((c: Record<any, any>) => c.id === catItem.id);
        if (!category) {
          return catItem;
        }
        return category;
      });

      dispatch(UPDATE_CATEGORIES_STATE_ACTION(navCategories)); //this one flats the children to parent level

      if (filteredNavCat && filteredNavCat.length > 0) {
        // dispatch(UPDATE_CATEGORIES_STATE_ACTION(navCategories)); //this one flats the children to parent level
        dispatch(UPDATE_TOP_CATEGORIES_STATE_ACTION(filteredNavCat)); //used for navigation links in footer & header
      }

      if (reOrderedField2Cat && reOrderedField2Cat.length > 0) {
        dispatch(UPDATE_FILTERED_CATEGORIES_STATE_ACTION(reOrderedField2Cat)); //categories filtered by FIELD2
      }
    } catch (e) {
      console.warn(widgetName, "fail to fetch category ");
    }
  };

  useEffect(() => {
    if (mySite && contract) {
      fetchCategory();
    }
  }, [mySite, contract]);

  useEffect(() => {
    return () =>
      //splice to empty array
      cancels.splice(0, cancels.length).forEach((cancel) => {
        cancel();
      });
  }, []);
};
