/* eslint-disable react-hooks/exhaustive-deps */
//Standard libraries
import React, { useEffect, useState } from "react";
import { StyledGrid } from "@hcl-commerce-store-sdk/react-component";
import {
  StyledContainer,
  StyledHeaderFooterLink,
  StyledTypography,
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
  StyledBox,
} from "../../styled-mui";
import { Hidden } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSelector } from "react-redux";
import { GetStaticLinksSelector, GetTopCategorySelector } from "../../redux/selectors/category";
import { EXTERNAL_LINK, LINK_USAGE } from "../../constants/marketing";
import { cloneDeep } from "lodash-es";
import PageLoader from "../elements/loader/PageLoader";
import { useTranslation } from "react-i18next";
import AsyncCall from "../../_foundation/gtm/async.service";
import { CLICK } from "../../_foundation/constants/gtm";
/**
 * FooterLinks component
 */
const FooterLinks: React.FC = () => {
  const [navigations, setNavigations] = useState<any[]>([]);
  const topCategories = useSelector(GetTopCategorySelector);
  const StaticLinksRedux: any = useSelector(GetStaticLinksSelector);
  const { t } = useTranslation();
  useEffect(() => {
    if (StaticLinksRedux && StaticLinksRedux.navigationLinks) {
      const newNavigations: any[] = cloneDeep(StaticLinksRedux.navigationLinks) || [];

      const OurProduct = {
        title: t("Footer.NavigationLinks.Title"),
        links: [],
      };
      newNavigations.unshift(OurProduct);

      StaticLinksRedux?.headerLinks
        ?.filter((link) => link.usage === "Promo")
        .forEach((item) => {
          newNavigations[0].links.push({
            label: item.seo?.label,
            url: item.seo?.url,
            type: item.seo?.type,
            target: "_self",
            usage: item.usage,
          });
        });
      // ADD TOP CATEGORIES
      topCategories?.forEach((item) => {
        newNavigations[0].links.push({
          label: item.name,
          target: "_self",
          type: "internal",
          url: item.seo?.href,
        });
      });

      setNavigations(newNavigations);
    }
  }, [StaticLinksRedux, topCategories]);

  return (
    <StyledBox
      sx={{ bgcolor: "tertiary.main" }}
      pb={{
        xs: 0,
        md: 15,
      }}>
      <StyledContainer className="no-max-width">
        {navigations.length > 0 ? (
          <StyledGrid
            container
            pt={{
              xs: 12,
              md: 15,
            }}>
            <Hidden mdDown>
              <StyledGrid container item>
                {navigations?.map((item: any, index: number) => (
                  <StyledGrid md={3} xs={12} pr={2} item key={index}>
                    <StyledTypography variant="body1" component="p" pb={4} className="uppercase">
                      {item.title}
                    </StyledTypography>
                    <ul className="footer-links-list">
                      {item.links.map((link: any, index: number) => (
                        <StyledTypography variant="body2" component="li" pb={4} key={index}>
                          <StyledHeaderFooterLink
                            data-testid={link.url}
                            to={link.url}
                            onClick={() => {
                              AsyncCall.sendClick(CLICK.FOOTER, { textclick: link.label + "_" + link.url });
                            }}
                            target={link.target}
                            href={link.type === EXTERNAL_LINK ? link.url : null}
                            color="primary"
                            className={link.usage === LINK_USAGE.PROMO ? "primary" : ""}>
                            {link.label}
                          </StyledHeaderFooterLink>
                        </StyledTypography>
                      ))}
                    </ul>
                  </StyledGrid>
                ))}
              </StyledGrid>
            </Hidden>
            <Hidden mdUp>
              {navigations?.map((item: any, index: number) => (
                <StyledAccordion
                  testId={`footer-links-accordion`}
                  square
                  key={index}
                  sx={[
                    {
                      "&:before": {
                        backgroundColor: "gray.neutralGray500",
                      },
                    },
                  ]}>
                  <StyledAccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    onClick={() => {
                      AsyncCall.sendClick(CLICK.FOOTER, { textclick: item.title + "_NA" });
                    }}
                    id="panel1a-header">
                    <StyledTypography variant="body1" component="p" className="uppercase">
                      {item.title}
                    </StyledTypography>
                  </StyledAccordionSummary>
                  <StyledAccordionDetails>
                    <ul className="footer-links-list">
                      {item.links.map((link: any, index: number) => (
                        <StyledTypography variant="body2" component="li" pb={4} key={index}>
                          <StyledHeaderFooterLink
                            data-testid={link.url}
                            to={link.url}
                            onClick={() => {
                              AsyncCall.sendClick(CLICK.FOOTER, { textclick: link.label + "_" + link.url });
                            }}
                            target={link.target}
                            href={link.type === EXTERNAL_LINK ? link.url : null}
                            className={link.usage === LINK_USAGE.PROMO ? "primary" : ""}>
                            {link.label}
                          </StyledHeaderFooterLink>
                        </StyledTypography>
                      ))}
                    </ul>
                  </StyledAccordionDetails>
                </StyledAccordion>
              ))}
            </Hidden>
          </StyledGrid>
        ) : (
          <PageLoader />
        )}
      </StyledContainer>
    </StyledBox>
  );
};

export default FooterLinks;
