//Standard libraries
import { useState, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
//Custom libraries
import { EMPTY_STRING, PROFILE_CREATION_TYPE } from "../../constants/common";
import formsUtil from "../../utils/formsUtil";
//UI
import { StyledGrid } from "@hcl-commerce-store-sdk/react-component";
import { StyledButton, StyledTextField, StyledTypography } from "../../styled-mui";
import * as userAction from "../../redux/actions/user";
import { useDispatch, useSelector } from "react-redux";
import getDisplayName from "react-display-name";
import {
  loginStatusSelector,
  registrationNewsletterLoadingSelector,
  registrationNewsletterStatusSelector,
} from "../../redux/selectors/user";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { site } from "../../_foundation/constants/site";
import { useTranslation } from "react-i18next";
import AsyncCall from "../../_foundation/gtm/async.service";
import { CLICK } from "../../_foundation/constants/gtm";

interface RegistrationNewsletterContext {
  cid: string;
}

function RegistrationNewsletter({ cid, ...props }: RegistrationNewsletterContext) {
  const { t } = useTranslation();
  const widgetName = getDisplayName(RegistrationNewsletter);
  const registrationNewsletterStatus = useSelector(registrationNewsletterStatusSelector);
  const loading = useSelector(registrationNewsletterLoadingSelector);
  const [email, setEmail] = useState<string>(EMPTY_STRING);
  const loggedIn = useSelector(loginStatusSelector);
  const [errorEmail, setErrorEmail] = useState<boolean>(false);
  const controller = useMemo(() => new AbortController(), []);
  const dispatch = useDispatch();
  const googleApiKey = site.googleRecaptcha;
  const { executeRecaptcha } = useGoogleReCaptcha();
  const location = useLocation();

  const handleSubmitReCaptcha = async (e) => {
    e.preventDefault();

    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return;
    }
    const token = await executeRecaptcha("newsletter/footer");
    handleSubmit(token);
  };

  const payloadBase: any = {
    widget: widgetName,
    signal: controller.signal,
  };

  const checkEmail = () => {
    setErrorEmail(!formsUtil.validateEmail(email));
  };

  const resetMsg = () => {
    setTimeout(() => {
      dispatch(userAction.REGISTRATION_NEWSLETTER_RESET_ACTION({}));
      setErrorEmail(false);
    }, 4000);
  };

  const handleSubmit = (token) => {
    dispatch(userAction.REGISTRATION_NEWSLETTER_RESET_ACTION({}));

    if (email.trim() !== EMPTY_STRING && formsUtil.validateEmail(email)) {
      const payload = {
        body: {
          email: email.toLowerCase(),
          type: PROFILE_CREATION_TYPE,
          statusEmail: true,
          source_optin: "LapeyreFR souscription NL",
          recaptchaToken: token,
          optin: false,
          isFooter: true,
          newsletterOn: true,
          isMyAccount: loggedIn ? true : false,
        },
        ...payloadBase,
      };
      // AsyncCall.sendClick(CLICK.NEWSLETTER, {
      //   btn_label: "a votre ecoute",
      //   page_type: "mon compte",
      //   content_group: "espace client",
      // });
      dispatch(userAction.REGISTRATION_NEWSLETTER_REQUEST_ACTION(payload));
      setErrorEmail(false);
    } else {
      setEmail("");
      setErrorEmail(true);
    }
  };

  useEffect(() => {
    if (registrationNewsletterStatus) {
      AsyncCall.sendClick(CLICK.NEWSLETTER, {
        btn_label: "a votre ecoute",
        page_type: "mon compte",
        content_group: "espace client",
      });
      setEmail("");
      resetMsg();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registrationNewsletterStatus]);
  useEffect(() => {
    setEmail("");
    setErrorEmail(false);
  }, [location]);

  useEffect(() => {
    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledGrid container px={{ xs: 0, sm: 10 }}>
      <StyledGrid item xs={12}>
        <StyledTypography component="p" variant="h5" pb={2}>
          {t("Footer.Contact.Newsletter.Title")}
        </StyledTypography>
        <StyledTypography variant="body2" component="p" pb={4}>
          {t("Footer.Contact.Newsletter.Description")}
        </StyledTypography>
      </StyledGrid>

      <StyledGrid item xs={12}>
        <form noValidate name="registrationNewsletter" onSubmit={handleSubmitReCaptcha}>
          <StyledGrid container item columnSpacing={2}>
            <StyledGrid item xs>
              <StyledTextField
                variant="outlined"
                required
                fullWidth
                success={registrationNewsletterStatus && !errorEmail}
                error={errorEmail}
                name="text"
                helperText={
                  errorEmail
                    ? t("error-message.NEWSLETTER_ADD_ERROR")
                    : registrationNewsletterStatus
                    ? t("success-message.NEWSLETTER_ADD_SUCCESS")
                    : ""
                }
                placeholder={t("Footer.Contact.Newsletter.InputPlaceholder")}
                onChange={(e) => setEmail(e.target.value)}
                onBlur={(e) => checkEmail()}
                value={email}
                inputProps={{
                  maxLength: 100,
                  type: "text",
                }}
              />
            </StyledGrid>
            <StyledGrid item>
              <StyledButton testId="sign-in-submit" variant="outline" color="black" type="submit" loading={loading}>
                {t("Footer.Contact.Newsletter.Button")}
              </StyledButton>
            </StyledGrid>
          </StyledGrid>
          <div className="g-recaptcha" data-sitekey={googleApiKey} data-size="invisible"></div>
        </form>
      </StyledGrid>
    </StyledGrid>
  );
}

export { RegistrationNewsletter };
