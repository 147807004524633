import styled from "@mui/styled-engine-sc";
import SvgIcon from "@mui/material/SvgIcon";

const ShoppingCart = styled((props) => (
  <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} />
))`
  ${({ theme }) => `
    width:20px;
    height:20px;

    ${theme.breakpoints.down("md")} {
      width:24px;
      height:24px;
    }

    &.medium {
      width:18px;
      height:18px;
    }
  `}
`;

export const SvgShoppingCart = (props) => {
  const { className = "" } = props;

  return (
    <ShoppingCart {...{ className }}>
      <path d="M6.66156 18.3333C6.20369 18.3333 5.81242 18.1666 5.48776 17.8416C5.16309 17.5166 4.99659 17.125 4.99659 16.6666C4.99659 16.2083 5.16309 15.8166 5.48776 15.4916C5.81242 15.1666 6.20369 15 6.66156 15C7.11942 15 7.51069 15.1666 7.83536 15.4916C8.16003 15.8166 8.32653 16.2083 8.32653 16.6666C8.32653 17.125 8.16003 17.5166 7.83536 17.8416C7.51069 18.1666 7.11942 18.3333 6.66156 18.3333ZM14.9864 18.3333C14.5285 18.3333 14.1373 18.1666 13.8126 17.8416C13.4879 17.5166 13.3214 17.125 13.3214 16.6666C13.3214 16.2083 13.4879 15.8166 13.8126 15.4916C14.1373 15.1666 14.5285 15 14.9864 15C15.4443 15 15.8355 15.1666 16.1602 15.4916C16.4849 15.8166 16.6514 16.2083 16.6514 16.6666C16.6514 17.125 16.4849 17.5166 16.1602 17.8416C15.8355 18.1666 15.4443 18.3333 14.9864 18.3333ZM5.95395 4.99996L7.95191 9.16663H13.7793L16.0686 4.99996H5.95395ZM6.66156 14.1666C6.0372 14.1666 5.56268 13.8916 5.24634 13.3416C4.92999 12.7916 4.91334 12.25 5.20471 11.7083L6.32856 9.66663L3.33162 3.33329H2.48249C2.2494 3.33329 2.0496 3.24996 1.89975 3.09163C1.7499 2.93329 1.66666 2.73329 1.66666 2.49996C1.66666 2.26663 1.7499 2.06663 1.90808 1.90829C2.06625 1.74996 2.26604 1.66663 2.49914 1.66663H3.85609C4.00594 1.66663 4.15578 1.70829 4.2973 1.79163C4.43883 1.87496 4.53872 1.99163 4.61365 2.14996L5.17974 3.34163H17.4589C17.8335 3.34163 18.0916 3.48329 18.2331 3.75829C18.3746 4.03329 18.3663 4.32496 18.2081 4.63329L15.2528 9.96663C15.1029 10.2416 14.8948 10.4583 14.6451 10.6166C14.3953 10.775 14.1123 10.85 13.7876 10.85H7.58562L6.66988 12.5166H15.8522C16.0853 12.5166 16.2851 12.6 16.4349 12.7583C16.5848 12.9166 16.668 13.1166 16.668 13.35C16.668 13.5833 16.5848 13.7833 16.4266 13.9416C16.2684 14.1 16.0686 14.1833 15.8355 14.1833H6.66156V14.1666Z" />
    </ShoppingCart>
  );
};
