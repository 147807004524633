import styled from "@mui/styled-engine-sc";
import SvgIcon from "@mui/material/SvgIcon";

const CustomSvg = styled((props) => (
  <SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} />
))`
  ${({ theme }) => `
    width:16px;
    height:16px;

    &.big {
      width:24px;
      height:24px;
    }
  `}
`;

export const SvgIconLogout = (props) => {
  const { className = "" } = props;

  return (
    <CustomSvg {...{ className }}>
      <path d="M10.416 10.848a.746.746 0 0 1-.19-.493c0-.186.06-.333.19-.46l1.263-1.232h-4.9a.673.673 0 0 1-.485-.193.64.64 0 0 1-.198-.473.64.64 0 0 1 .198-.473.673.673 0 0 1 .484-.194h4.901l-1.263-1.232a.643.643 0 0 1-.204-.473c0-.18.068-.34.204-.473.123-.134.28-.2.471-.2.191 0 .342.06.471.186l2.458 2.399c.068.067.116.14.143.22A.76.76 0 0 1 14 8.01a.76.76 0 0 1-.041.253.559.559 0 0 1-.143.22l-2.458 2.399a.6.6 0 0 1-.484.193.707.707 0 0 1-.45-.207l-.008-.02ZM3.366 14c-.376 0-.697-.133-.963-.393a1.269 1.269 0 0 1-.403-.94V3.333c0-.367.137-.68.403-.94.266-.26.587-.393.962-.393h4.096c.19 0 .355.067.484.193a.64.64 0 0 1 .198.473.64.64 0 0 1-.198.473.673.673 0 0 1-.484.194H3.365v9.328h4.096c.19 0 .355.066.484.193a.64.64 0 0 1 .198.473.64.64 0 0 1-.198.473.673.673 0 0 1-.484.193H3.365V14Z" />
    </CustomSvg>
  );
};
