/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Services - Account and Contract
 * These services provide APIs to manage accounts and contracts.  a contract is an agreement that represents the terms and conditions that apply to a transaction. An account is a relationship between the merchant and the financial institution that processes transactions for that merchant.
 *
 * The version of the OpenAPI document: 9.1.6
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../../base';
// @ts-ignore
import { StorelocatorStorelocator } from '../../../../../com/hcl/commerce/typescript/axios';
/**
 * StoreLocatorApi - axios parameter creator
 * @export
 */
export const StoreLocatorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Gets store location information by a store unique ID.
         * @summary Get by store unique ID
         * @param {string} storeId The store identifier.
         * @param {string} uniqueId The unique identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {number} [pageNumber] Page number. Valid values are positive integers starting at 1. Use pageNumber with pageSize.
         * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values are integers starting with 1. Use pageSize with pageNumber.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeLocatorFindByStoreUniqueId: async (storeId: string, uniqueId: string, responseFormat?: string, pageNumber?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('storeLocatorFindByStoreUniqueId', 'storeId', storeId)
            // verify required parameter 'uniqueId' is not null or undefined
            assertParamExists('storeLocatorFindByStoreUniqueId', 'uniqueId', uniqueId)
            const localVarPath = `/store/{storeId}/storelocator/byStoreId/{uniqueId}`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"uniqueId"}}`, String(uniqueId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets store location information by one to n store unique IDs.
         * @summary Get by store unique IDs
         * @param {string} storeId The store identifier.
         * @param {Array<string>} physicalStoreId A list of physical store unique identifiers.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {number} [pageNumber] Page number. Valid values are positive integers starting at 1. Use pageNumber with pageSize.
         * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values are integers starting with 1. Use pageSize with pageNumber.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeLocatorFindByStoreUniqueIds: async (storeId: string, physicalStoreId: Array<string>, responseFormat?: string, pageNumber?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('storeLocatorFindByStoreUniqueIds', 'storeId', storeId)
            // verify required parameter 'physicalStoreId' is not null or undefined
            assertParamExists('storeLocatorFindByStoreUniqueIds', 'physicalStoreId', physicalStoreId)
            const localVarPath = `/store/{storeId}/storelocator/byStoreIds`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (physicalStoreId) {
                localVarQueryParameter['physicalStoreId'] = physicalStoreId;
            }

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets store location information by a geo node unique ID.
         * @summary Get by geo node unique ID
         * @param {string} storeId The store identifier.
         * @param {string} geoId The geo node unique identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeLocatorFindGeoNodeByGeoId: async (storeId: string, geoId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('storeLocatorFindGeoNodeByGeoId', 'storeId', storeId)
            // verify required parameter 'geoId' is not null or undefined
            assertParamExists('storeLocatorFindGeoNodeByGeoId', 'geoId', geoId)
            const localVarPath = `/store/{storeId}/storelocator/byGeoNode/{geoId}`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"geoId"}}`, String(geoId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets store location information by a specified location.
         * @summary Get by specified location
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [city] The city.
         * @param {string} [state] The state.
         * @param {string} [prov] The province.
         * @param {string} [country] The country.
         * @param {string} [radiusUOM] The radius unit of measure.
         * @param {string} [beautyCenter] The physical store attribute name that describes whether the store is a beauty center.
         * @param {string} [type] The physical store attribute name to describe the type of the store.
         * @param {string} [radius] The radius.
         * @param {number} [pageNumber] Page number. Valid values are positive integers starting at 1. Use pageNumber with pageSize.
         * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values are integers starting with 1. Use pageSize with pageNumber.
         * @param {string} [siteLevelStoreSearch] If it is \&#39;true\&#39;, a site level physical search is performed.  Otherwise, the physical store search is performed at the web store level. By default, it is \&#39;true\&#39;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeLocatorFindGeoNodeByGeoLocation: async (storeId: string, responseFormat?: string, city?: string, state?: string, prov?: string, country?: string, radiusUOM?: string, beautyCenter?: string, type?: string, radius?: string, pageNumber?: number, pageSize?: number, siteLevelStoreSearch?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('storeLocatorFindGeoNodeByGeoLocation', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/storelocator/byLocation`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }

            if (city !== undefined) {
                localVarQueryParameter['city'] = city;
            }

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }

            if (prov !== undefined) {
                localVarQueryParameter['prov'] = prov;
            }

            if (country !== undefined) {
                localVarQueryParameter['country'] = country;
            }

            if (radiusUOM !== undefined) {
                localVarQueryParameter['radiusUOM'] = radiusUOM;
            }

            if (beautyCenter !== undefined) {
                localVarQueryParameter['BeautyCenter'] = beautyCenter;
            }

            if (type !== undefined) {
                localVarQueryParameter['Type'] = type;
            }

            if (radius !== undefined) {
                localVarQueryParameter['radius'] = radius;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (siteLevelStoreSearch !== undefined) {
                localVarQueryParameter['siteLevelStoreSearch'] = siteLevelStoreSearch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets store location information by specified coordinates.
         * @summary Get by specified coordinates
         * @param {string} storeId The store identifier.
         * @param {string} latitude The latitude.
         * @param {string} longitude The longitude.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [maxItems] The maximum number of stores to return.
         * @param {string} [radiusUOM] The radius unit of measure.
         * @param {string} [beautyCenter] The physical store attribute name that describes whether the store is a beauty center.
         * @param {string} [type] The physical store attribute name to describe the type of the store.
         * @param {string} [radius] The radius.
         * @param {string} [siteLevelStoreSearch] If it is \&#39;true\&#39;, a site level physical search is performed.  Otherwise, the physical store search is performed at the web store level. By default, it is \&#39;true\&#39;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeLocatorFindStores: async (storeId: string, latitude: string, longitude: string, responseFormat?: string, maxItems?: string, radiusUOM?: string, beautyCenter?: string, type?: string, radius?: string, siteLevelStoreSearch?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('storeLocatorFindStores', 'storeId', storeId)
            // verify required parameter 'latitude' is not null or undefined
            assertParamExists('storeLocatorFindStores', 'latitude', latitude)
            // verify required parameter 'longitude' is not null or undefined
            assertParamExists('storeLocatorFindStores', 'longitude', longitude)
            const localVarPath = `/store/{storeId}/storelocator/latitude/{latitude}/longitude/{longitude}`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"latitude"}}`, String(latitude))
                .replace(`{${"longitude"}}`, String(longitude));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }

            if (maxItems !== undefined) {
                localVarQueryParameter['maxItems'] = maxItems;
            }

            if (radiusUOM !== undefined) {
                localVarQueryParameter['radiusUOM'] = radiusUOM;
            }

            if (beautyCenter !== undefined) {
                localVarQueryParameter['BeautyCenter'] = beautyCenter;
            }

            if (type !== undefined) {
                localVarQueryParameter['Type'] = type;
            }

            if (radius !== undefined) {
                localVarQueryParameter['radius'] = radius;
            }

            if (siteLevelStoreSearch !== undefined) {
                localVarQueryParameter['siteLevelStoreSearch'] = siteLevelStoreSearch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StoreLocatorApi - functional programming interface
 * @export
 */
export const StoreLocatorApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StoreLocatorApiAxiosParamCreator(configuration)
    return {
        /**
         * Gets store location information by a store unique ID.
         * @summary Get by store unique ID
         * @param {string} storeId The store identifier.
         * @param {string} uniqueId The unique identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {number} [pageNumber] Page number. Valid values are positive integers starting at 1. Use pageNumber with pageSize.
         * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values are integers starting with 1. Use pageSize with pageNumber.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storeLocatorFindByStoreUniqueId(storeId: string, uniqueId: string, responseFormat?: string, pageNumber?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StorelocatorStorelocator>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storeLocatorFindByStoreUniqueId(storeId, uniqueId, responseFormat, pageNumber, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets store location information by one to n store unique IDs.
         * @summary Get by store unique IDs
         * @param {string} storeId The store identifier.
         * @param {Array<string>} physicalStoreId A list of physical store unique identifiers.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {number} [pageNumber] Page number. Valid values are positive integers starting at 1. Use pageNumber with pageSize.
         * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values are integers starting with 1. Use pageSize with pageNumber.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storeLocatorFindByStoreUniqueIds(storeId: string, physicalStoreId: Array<string>, responseFormat?: string, pageNumber?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StorelocatorStorelocator>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storeLocatorFindByStoreUniqueIds(storeId, physicalStoreId, responseFormat, pageNumber, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets store location information by a geo node unique ID.
         * @summary Get by geo node unique ID
         * @param {string} storeId The store identifier.
         * @param {string} geoId The geo node unique identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storeLocatorFindGeoNodeByGeoId(storeId: string, geoId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StorelocatorStorelocator>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storeLocatorFindGeoNodeByGeoId(storeId, geoId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets store location information by a specified location.
         * @summary Get by specified location
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [city] The city.
         * @param {string} [state] The state.
         * @param {string} [prov] The province.
         * @param {string} [country] The country.
         * @param {string} [radiusUOM] The radius unit of measure.
         * @param {string} [beautyCenter] The physical store attribute name that describes whether the store is a beauty center.
         * @param {string} [type] The physical store attribute name to describe the type of the store.
         * @param {string} [radius] The radius.
         * @param {number} [pageNumber] Page number. Valid values are positive integers starting at 1. Use pageNumber with pageSize.
         * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values are integers starting with 1. Use pageSize with pageNumber.
         * @param {string} [siteLevelStoreSearch] If it is \&#39;true\&#39;, a site level physical search is performed.  Otherwise, the physical store search is performed at the web store level. By default, it is \&#39;true\&#39;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storeLocatorFindGeoNodeByGeoLocation(storeId: string, responseFormat?: string, city?: string, state?: string, prov?: string, country?: string, radiusUOM?: string, beautyCenter?: string, type?: string, radius?: string, pageNumber?: number, pageSize?: number, siteLevelStoreSearch?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StorelocatorStorelocator>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storeLocatorFindGeoNodeByGeoLocation(storeId, responseFormat, city, state, prov, country, radiusUOM, beautyCenter, type, radius, pageNumber, pageSize, siteLevelStoreSearch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets store location information by specified coordinates.
         * @summary Get by specified coordinates
         * @param {string} storeId The store identifier.
         * @param {string} latitude The latitude.
         * @param {string} longitude The longitude.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [maxItems] The maximum number of stores to return.
         * @param {string} [radiusUOM] The radius unit of measure.
         * @param {string} [beautyCenter] The physical store attribute name that describes whether the store is a beauty center.
         * @param {string} [type] The physical store attribute name to describe the type of the store.
         * @param {string} [radius] The radius.
         * @param {string} [siteLevelStoreSearch] If it is \&#39;true\&#39;, a site level physical search is performed.  Otherwise, the physical store search is performed at the web store level. By default, it is \&#39;true\&#39;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storeLocatorFindStores(storeId: string, latitude: string, longitude: string, responseFormat?: string, maxItems?: string, radiusUOM?: string, beautyCenter?: string, type?: string, radius?: string, siteLevelStoreSearch?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StorelocatorStorelocator>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storeLocatorFindStores(storeId, latitude, longitude, responseFormat, maxItems, radiusUOM, beautyCenter, type, radius, siteLevelStoreSearch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StoreLocatorApi - factory interface
 * @export
 */
export const StoreLocatorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StoreLocatorApiFp(configuration)
    return {
        /**
         * Gets store location information by a store unique ID.
         * @summary Get by store unique ID
         * @param {string} storeId The store identifier.
         * @param {string} uniqueId The unique identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {number} [pageNumber] Page number. Valid values are positive integers starting at 1. Use pageNumber with pageSize.
         * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values are integers starting with 1. Use pageSize with pageNumber.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeLocatorFindByStoreUniqueId(storeId: string, uniqueId: string, responseFormat?: string, pageNumber?: number, pageSize?: number, options?: any): AxiosPromise<StorelocatorStorelocator> {
            return localVarFp.storeLocatorFindByStoreUniqueId(storeId, uniqueId, responseFormat, pageNumber, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets store location information by one to n store unique IDs.
         * @summary Get by store unique IDs
         * @param {string} storeId The store identifier.
         * @param {Array<string>} physicalStoreId A list of physical store unique identifiers.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {number} [pageNumber] Page number. Valid values are positive integers starting at 1. Use pageNumber with pageSize.
         * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values are integers starting with 1. Use pageSize with pageNumber.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeLocatorFindByStoreUniqueIds(storeId: string, physicalStoreId: Array<string>, responseFormat?: string, pageNumber?: number, pageSize?: number, options?: any): AxiosPromise<StorelocatorStorelocator> {
            return localVarFp.storeLocatorFindByStoreUniqueIds(storeId, physicalStoreId, responseFormat, pageNumber, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets store location information by a geo node unique ID.
         * @summary Get by geo node unique ID
         * @param {string} storeId The store identifier.
         * @param {string} geoId The geo node unique identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeLocatorFindGeoNodeByGeoId(storeId: string, geoId: string, options?: any): AxiosPromise<StorelocatorStorelocator> {
            return localVarFp.storeLocatorFindGeoNodeByGeoId(storeId, geoId, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets store location information by a specified location.
         * @summary Get by specified location
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [city] The city.
         * @param {string} [state] The state.
         * @param {string} [prov] The province.
         * @param {string} [country] The country.
         * @param {string} [radiusUOM] The radius unit of measure.
         * @param {string} [beautyCenter] The physical store attribute name that describes whether the store is a beauty center.
         * @param {string} [type] The physical store attribute name to describe the type of the store.
         * @param {string} [radius] The radius.
         * @param {number} [pageNumber] Page number. Valid values are positive integers starting at 1. Use pageNumber with pageSize.
         * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values are integers starting with 1. Use pageSize with pageNumber.
         * @param {string} [siteLevelStoreSearch] If it is \&#39;true\&#39;, a site level physical search is performed.  Otherwise, the physical store search is performed at the web store level. By default, it is \&#39;true\&#39;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeLocatorFindGeoNodeByGeoLocation(storeId: string, responseFormat?: string, city?: string, state?: string, prov?: string, country?: string, radiusUOM?: string, beautyCenter?: string, type?: string, radius?: string, pageNumber?: number, pageSize?: number, siteLevelStoreSearch?: string, options?: any): AxiosPromise<StorelocatorStorelocator> {
            return localVarFp.storeLocatorFindGeoNodeByGeoLocation(storeId, responseFormat, city, state, prov, country, radiusUOM, beautyCenter, type, radius, pageNumber, pageSize, siteLevelStoreSearch, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets store location information by specified coordinates.
         * @summary Get by specified coordinates
         * @param {string} storeId The store identifier.
         * @param {string} latitude The latitude.
         * @param {string} longitude The longitude.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [maxItems] The maximum number of stores to return.
         * @param {string} [radiusUOM] The radius unit of measure.
         * @param {string} [beautyCenter] The physical store attribute name that describes whether the store is a beauty center.
         * @param {string} [type] The physical store attribute name to describe the type of the store.
         * @param {string} [radius] The radius.
         * @param {string} [siteLevelStoreSearch] If it is \&#39;true\&#39;, a site level physical search is performed.  Otherwise, the physical store search is performed at the web store level. By default, it is \&#39;true\&#39;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeLocatorFindStores(storeId: string, latitude: string, longitude: string, responseFormat?: string, maxItems?: string, radiusUOM?: string, beautyCenter?: string, type?: string, radius?: string, siteLevelStoreSearch?: string, options?: any): AxiosPromise<StorelocatorStorelocator> {
            return localVarFp.storeLocatorFindStores(storeId, latitude, longitude, responseFormat, maxItems, radiusUOM, beautyCenter, type, radius, siteLevelStoreSearch, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StoreLocatorApi - object-oriented interface
 * @export
 * @class StoreLocatorApi
 * @extends {BaseAPI}
 */
export class StoreLocatorApi extends BaseAPI {
    /**
     * Gets store location information by a store unique ID.
     * @summary Get by store unique ID
     * @param {string} storeId The store identifier.
     * @param {string} uniqueId The unique identifier.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {number} [pageNumber] Page number. Valid values are positive integers starting at 1. Use pageNumber with pageSize.
     * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values are integers starting with 1. Use pageSize with pageNumber.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreLocatorApi
     */
    public storeLocatorFindByStoreUniqueId(storeId: string, uniqueId: string, responseFormat?: string, pageNumber?: number, pageSize?: number, options?: any) {
        return StoreLocatorApiFp(this.configuration).storeLocatorFindByStoreUniqueId(storeId, uniqueId, responseFormat, pageNumber, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets store location information by one to n store unique IDs.
     * @summary Get by store unique IDs
     * @param {string} storeId The store identifier.
     * @param {Array<string>} physicalStoreId A list of physical store unique identifiers.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {number} [pageNumber] Page number. Valid values are positive integers starting at 1. Use pageNumber with pageSize.
     * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values are integers starting with 1. Use pageSize with pageNumber.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreLocatorApi
     */
    public storeLocatorFindByStoreUniqueIds(storeId: string, physicalStoreId: Array<string>, responseFormat?: string, pageNumber?: number, pageSize?: number, options?: any) {
        return StoreLocatorApiFp(this.configuration).storeLocatorFindByStoreUniqueIds(storeId, physicalStoreId, responseFormat, pageNumber, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets store location information by a geo node unique ID.
     * @summary Get by geo node unique ID
     * @param {string} storeId The store identifier.
     * @param {string} geoId The geo node unique identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreLocatorApi
     */
    public storeLocatorFindGeoNodeByGeoId(storeId: string, geoId: string, options?: any) {
        return StoreLocatorApiFp(this.configuration).storeLocatorFindGeoNodeByGeoId(storeId, geoId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets store location information by a specified location.
     * @summary Get by specified location
     * @param {string} storeId The store identifier.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {string} [city] The city.
     * @param {string} [state] The state.
     * @param {string} [prov] The province.
     * @param {string} [country] The country.
     * @param {string} [radiusUOM] The radius unit of measure.
     * @param {string} [beautyCenter] The physical store attribute name that describes whether the store is a beauty center.
     * @param {string} [type] The physical store attribute name to describe the type of the store.
     * @param {string} [radius] The radius.
     * @param {number} [pageNumber] Page number. Valid values are positive integers starting at 1. Use pageNumber with pageSize.
     * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values are integers starting with 1. Use pageSize with pageNumber.
     * @param {string} [siteLevelStoreSearch] If it is \&#39;true\&#39;, a site level physical search is performed.  Otherwise, the physical store search is performed at the web store level. By default, it is \&#39;true\&#39;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreLocatorApi
     */
    public storeLocatorFindGeoNodeByGeoLocation(storeId: string, responseFormat?: string, city?: string, state?: string, prov?: string, country?: string, radiusUOM?: string, beautyCenter?: string, type?: string, radius?: string, pageNumber?: number, pageSize?: number, siteLevelStoreSearch?: string, options?: any) {
        return StoreLocatorApiFp(this.configuration).storeLocatorFindGeoNodeByGeoLocation(storeId, responseFormat, city, state, prov, country, radiusUOM, beautyCenter, type, radius, pageNumber, pageSize, siteLevelStoreSearch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets store location information by specified coordinates.
     * @summary Get by specified coordinates
     * @param {string} storeId The store identifier.
     * @param {string} latitude The latitude.
     * @param {string} longitude The longitude.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {string} [maxItems] The maximum number of stores to return.
     * @param {string} [radiusUOM] The radius unit of measure.
     * @param {string} [beautyCenter] The physical store attribute name that describes whether the store is a beauty center.
     * @param {string} [type] The physical store attribute name to describe the type of the store.
     * @param {string} [radius] The radius.
     * @param {string} [siteLevelStoreSearch] If it is \&#39;true\&#39;, a site level physical search is performed.  Otherwise, the physical store search is performed at the web store level. By default, it is \&#39;true\&#39;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreLocatorApi
     */
    public storeLocatorFindStores(storeId: string, latitude: string, longitude: string, responseFormat?: string, maxItems?: string, radiusUOM?: string, beautyCenter?: string, type?: string, radius?: string, siteLevelStoreSearch?: string, options?: any) {
        return StoreLocatorApiFp(this.configuration).storeLocatorFindStores(storeId, latitude, longitude, responseFormat, maxItems, radiusUOM, beautyCenter, type, radius, siteLevelStoreSearch, options).then((request) => request(this.axios, this.basePath));
    }
}
