/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
import { createReducer, AnyAction } from "@reduxjs/toolkit";
import initStates, { storesDefaultState } from "./initStates";
import { StoresState } from "./reducerStateInterface";
import {
  STORES_OPEN_MODAL_ACTION,
  STORES_CLOSE_MODAL_ACTION,
  STORES_FAVORITE_GET_REQUEST_ACTION,
  STORES_FAVORITE_GET_SUCCESS_ACTION,
  STORES_FAVORITE_GET_ERROR_ACTION,
  STORES_RESET_ACTION,
  STORES_LIST_GET_REQUEST_ACTION,
  STORES_LIST_GET_SUCCESS_ACTION,
  STORES_LIST_GET_ERROR_ACTION,
  STORES_MAP_COORDS_SET_ACTION,
} from "../actions/stores";
import { transformStoresJson } from "../../utils/storeLocatorUtils";
// import { STORES_FAVORITE_GET_ERROR, STORES_FAVORITE_GET_REQUEST, STORES_FAVORITE_GET_SUCCESS } from "../actions/stores";

/**
 * User reducer
 * handles states used by user related components
 * @param state State object managed by user reducer
 * @param action The dispatched action
 */
const storesReducer = createReducer(initStates.stores, (builder) => {
  builder.addCase(STORES_OPEN_MODAL_ACTION, (state: StoresState, action: AnyAction) => {
    state.storeLocatorModalOpen = true;
    state.storeLocatorModalType = action.payload.modalType;
    state.productData = action.payload.productData || null;
  });

  builder.addCase(STORES_CLOSE_MODAL_ACTION, (state: StoresState, action: AnyAction) => {
    state.storeLocatorModalOpen = false;
    state.storesList = [];
    state.map = storesDefaultState.map;
    state.productData = null;
    state.nearestStores = [];
    state.availabilities = [];
  });

  builder.addCase(STORES_FAVORITE_GET_REQUEST_ACTION, (state: StoresState, action: AnyAction) => {
    state.isLoadingUserFavStores = true;
  });

  builder.addCase(STORES_FAVORITE_GET_SUCCESS_ACTION, (state: StoresState, action: AnyAction) => {
    const storesData = transformStoresJson(action.payload.stores);
    state.userFavStores = storesData;
    state.isLoadingUserFavStores = false;
  });

  builder.addCase(STORES_FAVORITE_GET_ERROR_ACTION, (state: StoresState, action: AnyAction) => {
    state.isLoadingUserFavStores = false;
  });

  builder.addCase(STORES_LIST_GET_REQUEST_ACTION, (state: StoresState, action: AnyAction) => {
    state.isLoadingStoresList = true;
  });

  builder.addCase(STORES_LIST_GET_SUCCESS_ACTION, (state: StoresState, action: AnyAction) => {
    const storesData = transformStoresJson(action.payload.storesList);
    state.storesList = storesData;
    state.nearestStores = action.payload.nearestStores;
    state.isLoadingStoresList = false;
    state.availabilities = action.payload.availabilities;
  });

  builder.addCase(STORES_LIST_GET_ERROR_ACTION, (state: StoresState, action: AnyAction) => {
    state.isLoadingStoresList = false;
    // state.nearestStores = [];
  });

  builder.addCase(STORES_MAP_COORDS_SET_ACTION, (state: StoresState, action: AnyAction) => {
    state.map = action.payload;
  });

  builder.addCase(STORES_RESET_ACTION, (state: StoresState, action: AnyAction) => {
    // Object.assign(state, storesDefaultState);
    state.storesList = [];
    state.nearestStores = [];
    state.availabilities = [];
  });
});

export default storesReducer;
