import SvgIcon from "@mui/material/SvgIcon";
import styled from "@mui/styled-engine-sc";

const Svg = styled((props) => (
  <SvgIcon width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} />
))`
  ${({ theme }) => `
    width:14px;
    height:14px;
  `}
`;

export const SvgLocalisation = (props) => {
  const { className = "" } = props;

  return (
    <Svg {...{ className }}>
      <path
        d="M6.467 13.132v-.573c-1.359-.144-2.502-.68-3.429-1.603-.927-.928-1.459-2.068-1.598-3.42H.868a.517.517 0 0 1-.381-.154.517.517 0 0 1-.154-.38c0-.151.05-.278.154-.382a.517.517 0 0 1 .38-.153h.573c.145-1.359.68-2.501 1.604-3.429.927-.927 2.067-1.459 3.42-1.597V.868c0-.15.05-.278.153-.381a.517.517 0 0 1 .381-.154c.15 0 .278.05.381.154.104.103.154.23.154.38v.574c1.355.138 2.495.67 3.42 1.597.924.928 1.461 2.07 1.603 3.429h.573c.15 0 .277.05.38.153.104.104.154.23.154.381s-.05.278-.153.381a.516.516 0 0 1-.381.154h-.573c-.139 1.355-.67 2.495-1.598 3.42-.927.927-2.07 1.461-3.428 1.603v.573c0 .15-.05.278-.154.381a.516.516 0 0 1-.38.154.516.516 0 0 1-.382-.154.516.516 0 0 1-.153-.38h.006ZM7 11.413c1.214 0 2.253-.43 3.119-1.296.865-.862 1.296-1.902 1.296-3.119 0-1.216-.431-2.253-1.296-3.118C9.257 3.015 8.218 2.584 7 2.584c-1.217 0-2.253.43-3.118 1.296-.866.862-1.297 1.902-1.297 3.118 0 1.217.431 2.254 1.297 3.119.862.865 1.901 1.296 3.118 1.296Zm0-2.2c-.62 0-1.146-.215-1.574-.643a2.143 2.143 0 0 1-.644-1.575c0-.62.216-1.145.644-1.574a2.143 2.143 0 0 1 1.574-.643c.62 0 1.146.215 1.574.643.428.429.644.954.644 1.574 0 .62-.216 1.146-.644 1.574a2.143 2.143 0 0 1-1.574.644Zm-.003-1.078c.322 0 .59-.109.81-.327.218-.219.33-.488.33-.807 0-.319-.11-.59-.328-.809a1.098 1.098 0 0 0-.806-.33c-.319 0-.59.109-.809.327-.219.219-.33.488-.33.806 0 .322.108.591.327.81.219.218.487.33.806.33Z"
        fill="#212121"
      />
    </Svg>
  );
};
