/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Custom libraries
import {
  ADDRESSLINE1,
  ADDRESSLINE2,
  ADDRESSLINE3,
  ADDRESS_LABEL_ATTRKEY,
  ADDRESS_LABEL_ATTRKEY_VALUE,
  ADDRESS_LABEL_VALUE,
  ADDRESS_LINE,
  ADDRESS_MAIN_LABEL_VALUE,
  ATTRIBUTES,
  COUNTRIES_PHONE,
  EMPTY_STRING,
  PASSWORD_LOW,
  PASSWORD_MODERATE,
  PASSWORD_STRONG,
  REG_EX,
} from "../constants/common";
import storeUtil from "./storeUtil";

const formsUtil = {
  optionalAddressFields: ["addressLine2", "addressLine3", "email1", "phone1"],
  addressFormFields: [
    "personTitle",
    "firstName",
    "lastName",
    "addressLine1",
    "addressLine2",
    "addressLine3",
    "addressField1",
    "city",
    "country",
    "zipCode",
    "phone1",
    "nickName",
    "email1",
    "primary",
    "addressType",
  ],

  validateAddressForm: (formData: any) => {
    for (const key in formData) {
      if (!formsUtil.optionalAddressFields.includes(key)) {
        if (formData[key] !== undefined && `${formData[key]}`?.trim() === "") {
          return false;
        }
      }
    }
    return true;
  },

  // validatePhoneNumber: (phoneNumber: string, country: string = ""): boolean => {
  //   let result = false;
  //   if (phoneNumber === undefined) {
  //     return false;
  //   }
  //   const phoneNumberTrim = phoneNumber.replaceAll(" ", "");
  //   switch (country?.toLocaleLowerCase()) {
  //     case "es":
  //       result = REG_EX.PHONE_ES.test(phoneNumberTrim);
  //       break;
  //     case "ch":
  //       result = REG_EX.PHONE_SU.test(phoneNumberTrim);
  //       break;
  //     case "ad":
  //       result = REG_EX.PHONE_AN.test(phoneNumberTrim);
  //       break;
  //     case "fr":
  //       result = REG_EX.PHONE_FR.test(phoneNumberTrim);
  //       break;
  //     case "mc":
  //       result = REG_EX.PHONE_MO.test(phoneNumberTrim);
  //       break;
  //     case "it":
  //       result = REG_EX.PHONE_IT.test(phoneNumberTrim);
  //       break;
  //     case "de":
  //       result = REG_EX.PHONE_AL.test(phoneNumberTrim);
  //       break;
  //     case "lu":
  //       result = REG_EX.PHONE_LU.test(phoneNumberTrim);
  //       break;
  //     case "be":
  //       result = REG_EX.PHONE_BE.test(phoneNumberTrim);
  //       break;
  //     case "gb":
  //       result = REG_EX.PHONE_UK.test(phoneNumberTrim);
  //       break;
  //     default:
  //       result = REG_EX.PHONE.test(phoneNumberTrim);
  //       break;
  //   }
  //   return result;
  // },
  validatePhoneNumber: (phone: string) => {
    // Remove spaces from the phone number to handle cases with spaces between digits
    const cleanedPhone = phone.replaceAll(" ", "");

    // Regex for phone number starting with '0' and length must be 10 digits
    const REG_EX_PHONE_0 = /^0\d{9}$/;

    // Regex for phone number starting with '+33' and exactly 9 digits after '+33'
    const REG_EX_PHONE_33 = /^\+33\d{9}$/;

    // Check if the phone number is empty
    if (!cleanedPhone || cleanedPhone.trim() === "") {
      return false; // Error: Phone number is empty or undefined
    }
    // Validate phone numbers starting with '0'
    if (cleanedPhone.startsWith("0")) {
      return REG_EX_PHONE_0.test(cleanedPhone); // Validate for phone starting with '0'
    }

    // Validate phone numbers starting with '+33'
    if (cleanedPhone.startsWith("+33")) {
      return REG_EX_PHONE_33.test(cleanedPhone); // Validate for phone starting with '+33'
    }

    // If phone number starts with something else, no error
    return true;
  },

  validateFieldString: (field) => {
    return field.trim() === "";
  },

  validateCityRegex: (field) => {
    const regex = /^[A-Za-z\s]+$/; // Regex to match only letters and spaces
    return regex.test(field.trim());
  },

  getPhoneCodeCountry: (country: string) => {
    return COUNTRIES_PHONE[country?.toLocaleLowerCase()]?.code || "fr";
  },

  getPhoneNumberWithIndicator: (phoneNumber: string, country: string = "france") => {
    const PHONE = REG_EX.PHONE_START;
    const indicator = `+${COUNTRIES_PHONE[country?.toLocaleLowerCase()]?.indicator || "33"}`;
    if (phoneNumber !== undefined && phoneNumber.trim() !== "" && PHONE.test(phoneNumber.trim())) {
      const cleaned = ("" + phoneNumber).replace(/\D/g, "");
      let match: any = null;
      switch (country?.toLocaleLowerCase()) {
        case "france":
          match = cleaned.match(/^(\d{1})(\d{1})(\d{2})(\d{2})(\d{2})(\d{2})$/);
          if (match) {
            return `${indicator} ${match[2]} ${match[3]} ${match[4]} ${match[5]} ${match[6]}`;
          }
          break;
        default:
          break;
      }
      return phoneNumber.replace(PHONE, indicator);
    } else {
      return phoneNumber;
    }
  },

  validateEmail: (email: any) => {
    const EMAIL = REG_EX.EMAIL;
    return EMAIL.test(email);
  },

  validateNickName: (nickName: string) => {
    const NICKNAME_ALPHA_NUMERIC_SPECIAL_CHAR = REG_EX.NICKNAME_ALPHA_NUMERIC_SPECIAL_CHAR;
    return NICKNAME_ALPHA_NUMERIC_SPECIAL_CHAR.test(nickName);
  },

  validateZipCode: (zipcode: string, country: string = "france") => {
    switch (country?.toLocaleLowerCase()) {
      case "france":
        return REG_EX.CP_FRANCE.test(zipcode);
      case "belgique":
        return REG_EX.CP_BELGIUM.test(zipcode);
      case "suisse":
        return REG_EX.CP_SWISS.test(zipcode);
      case "pays-bas":
        return REG_EX.CP_NETHERLANDS.test(zipcode);
      default: {
        return REG_EX.CP_FRANCE.test(zipcode);
      }
    }
  },

  getCharAlpha: (input: string, regex) => {
    let m;
    let charAlpha = "";

    while ((m = regex.exec(input)) !== null) {
      // This is necessary to avoid infinite loops with zero-width matches
      if (m.index === regex.lastIndex) {
        regex.lastIndex++;
      }
      const tab: string[] = [];
      m.forEach((match: string, groupIndex) => {
        if (groupIndex === 0) tab.push(match);
      });
      charAlpha += tab.join("");
    }
    return charAlpha;
  },

  checkPassword: (input: string) => {
    const PASSWORD_MAJ = REG_EX.PASSWORD_MAJ;
    const PASSWORD_MIN = REG_EX.PASSWORD_MIN;
    const PASSWORD_NUMBER = REG_EX.PASSWORD_NUMBER;
    const PASSWORD_NOWORD = REG_EX.PASSWORD_NOWORD;
    const PASSWORD_ALPHA = REG_EX.PASSWORD_ALPHA;
    const NOWHITESPACE = REG_EX.NOWHITESPACE;
    const MAJ = PASSWORD_MAJ.test(input);
    const MIN = PASSWORD_MIN.test(input);
    const NUMBER = PASSWORD_NUMBER.test(input);
    const NOWORD = PASSWORD_NOWORD.test(input);
    const LENGTH = input.length > 7;
    const REPEATCON = !REG_EX.MAX_CHAR_CONSECUTIVE_2.test(input) && input.length > 4;
    const sort = input.split("").sort().join("");
    const REPEATMAX = !REG_EX.MAX_CHAR_CONSECUTIVE_4.test(sort) && input.length > 4;
    const charAlpha = formsUtil.getCharAlpha(input, PASSWORD_ALPHA);
    const LENGTHALPHA = charAlpha.length > 6;

    let level = 0;
    const levelMax = 8;
    let validate = false; // input == null || input.trim() === "";

    if (NOWHITESPACE.test(input) && LENGTH && LENGTHALPHA && REPEATCON && REPEATMAX && NUMBER && MIN && MAJ && NOWORD) {
      validate = true;
      level++;
    }
    if (REPEATCON) level++;
    if (REPEATMAX) level++;
    if (LENGTHALPHA) level++;
    if (MAJ) level++;
    if (MIN) level++;
    if (NUMBER) level++;
    if (NOWORD) level++;

    const secure = level <= 3 ? PASSWORD_LOW : level > 3 && level <= 6 ? PASSWORD_MODERATE : PASSWORD_STRONG;

    return {
      validate,
      secure: validate ? secure : PASSWORD_LOW,
      pourcent: Math.ceil((level / levelMax) * 100),
      status: { MAJ, MIN, NUMBER, NOWORD, LENGTH, LENGTHALPHA, REPEATCON, REPEATMAX },
    };
  },

  removeLeadingTrailingWhiteSpace: (formData: any): any => {
    const result: any = {};
    // remove leading and trailing white space from all form input fields.
    if (formData !== undefined && formData !== null) {
      Object.keys(formData).forEach((key) => {
        const value = `${formData[key]}`?.trim();
        result[key] = value;
      });
    }
    return result;
  },

  transformLabelAddress: (AddressData: any): any => {
    AddressData[ADDRESS_LABEL_ATTRKEY] = ADDRESS_LABEL_ATTRKEY_VALUE;
    AddressData[ADDRESS_LABEL_VALUE] = [AddressData[ADDRESS_LABEL_ATTRKEY_VALUE]];
    delete AddressData[ADDRESS_LABEL_ATTRKEY_VALUE];
  },

  transformAddressLineToArray: (AddressData: any): any => {
    AddressData[ADDRESS_LINE] = [AddressData[ADDRESSLINE1]];
    if (AddressData[ADDRESSLINE2] && AddressData[ADDRESSLINE2].trim() !== EMPTY_STRING) {
      AddressData[ADDRESS_LINE].push(AddressData[ADDRESSLINE2]);
    } else {
      AddressData[ADDRESS_LINE].push("");
    }
    if (AddressData[ADDRESSLINE3] && AddressData[ADDRESSLINE3].trim() !== EMPTY_STRING) {
      AddressData[ADDRESS_LINE].push(AddressData[ADDRESSLINE3]);
    } else {
      AddressData[ADDRESS_LINE].push("");
    }
    delete AddressData[ADDRESSLINE1];
    delete AddressData[ADDRESSLINE2];
    delete AddressData[ADDRESSLINE3];
    delete AddressData[ATTRIBUTES];
  },

  transformAddressArrayToLine: (AddressData: any) => {
    let newAddress = { ...AddressData };
    if (AddressData.addressLine && AddressData.addressLine.length > 2) {
      newAddress[ADDRESSLINE1] = AddressData.addressLine[0];
      newAddress[ADDRESSLINE2] = AddressData.addressLine[1];
      newAddress[ADDRESSLINE3] = AddressData.addressLine[2].length > 0 ? AddressData.addressLine[2] : "0";
      newAddress = formsUtil.removeIgnorableAddressFormFields(newAddress);
    }
    newAddress[ADDRESS_LABEL_ATTRKEY_VALUE] = formsUtil.getAddressLabel(AddressData);
    return newAddress;
  },

  getAddressLabel: (AddressData: any) => {
    let attrLabels: any[] = AddressData.attributes?.filter((a) => a.contactInfoAttrKey === ADDRESS_LABEL_ATTRKEY_VALUE);
    if (attrLabels?.length > 0) {
      return attrLabels[0][ADDRESS_MAIN_LABEL_VALUE];
    } else {
      attrLabels = AddressData.Attributes?.filter((a) => a.attrKey === ADDRESS_LABEL_ATTRKEY_VALUE);
      return attrLabels?.length > 0 ? attrLabels[0][ADDRESS_LABEL_VALUE] : "";
    }
  },

  removeIgnorableAddressFormFields: (formData: any): any => {
    const result: any = { ...formData };
    for (const key in result) {
      if (!formsUtil.addressFormFields.includes(key)) {
        delete result[key];
      }
    }
    return result;
  },

  getRegisteredInitialAddress: (address): any => {
    const keys = [
      "addressId",
      "addressLine",
      "addressType",
      "attributes",
      "city",
      "country",
      "email1",
      "firstName",
      "lastName",
      "nickName",
      "phone1",
      "primary",
      "state",
      "zipCode",
      "personTitle",
    ].filter((k) => address[k] != null); // != is intentional (instead of !== since != [or ==] will equate null and undefined)
    const rc = Object.assign({ main: true }, ...keys.map((k) => ({ [k]: address[k] })));
    return rc;
  },

  filterList: (list, constraint) => {
    /*const asMap = */ storeUtil.toMap(constraint, "nickName");
    return constraint;
    // return (list ?? []).filter((a) => asMap[a?.nickName]).map((a) => ({ ...a, ...asMap[a.nickName] }));
  },

  /**
   * Filter out addresses that aren't in the address book (personal)
   * @param addrs List of addresses to scan
   * @param addressDetails Personal address book
   * @returns Filtered list of addresses
   */
  filterAddresses: (addrs: any[], addressDetails) => {
    const { contactList = [] } = addressDetails ?? {};
    const full: any[] = [addressDetails, ...contactList].filter(Boolean);
    return formsUtil.filterList(addrs, full);
  },

  /**
   * Filter out addresses that aren't in the org-address book
   * @param addrs List of addresses to scan
   * @param orgAddressDetails Org address book
   * @returns Filtered list of addresses
   */
  filterOrgAddresses: (addrs: any[], orgAddressDetails) => {
    const { contactInfo, addressBook = [] } = orgAddressDetails ?? {};
    const full: any[] = [contactInfo, ...addressBook].filter(Boolean);
    return formsUtil.filterList(addrs, full);
  },

  /**
   * @param _addr
   * @returns true if `_addr` has a non-empty country field and non-empty first address-line
   */
  validAddr: (_addr) => {
    return _addr?.country && (_addr.addressLine?.at(0) || _addr.address1);
  },

  /**
   *
   * @param addr
   * @returns true if "addr" has france in country field otherwise false
   */
  validFrAddr: (addr: Record<any, any>) => {
    const country = addr?.country?.toLowerCase();
    return country === "france" || country === "fr";
  },

  isToday: (someDate) => {
    const today = new Date();
    return (
      someDate.getDate() === today.getDate() &&
      someDate.getMonth() === today.getMonth() &&
      someDate.getFullYear() === today.getFullYear()
    );
  },

  checkList: (value: string) => {
    return value === "" || value === "-1" ? true : false;
  },

  /**
   *
   * @param str takes a string to be filtered
   * @description This function should be used to filter out numeric values from string.
   * @returns modified string which allows all characters but not numbers
   */
  filterOutNumericInput: function (str: string) {
    return str.replace(/\d/g, "");
  },
};

export default formsUtil;
