import React, { useCallback, useState, useEffect, useMemo } from "react";
import styled from "@mui/styled-engine-sc";
import MatButton from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import { debounce } from "lodash-es";
import { palette } from "../../themes/color-palette";

const CustomMatButton = React.forwardRef((props: any, ref: any) => {
  const {
    children,
    preventDoubleClick = false,
    variant = "contained",
    className = "",
    color = "primary",
    testId,
    onClick,
    disabled,
    loading,
    loadingPosition = "center",
    ...re
  } = props;
  const dataTestId = testId ? { "data-testid": `button-${testId}` } : {};
  const [iDisabled, setIDisabled] = useState<boolean>(false);

  const debounced = useMemo(
    () =>
      //debounce to prevent double click
      debounce(() => {
        setIDisabled(false);
      }, 2000),
    [setIDisabled]
  );

  const onClickWrapper = useCallback(
    (event: any) => {
      if (!preventDoubleClick) {
        onClick && onClick(event);
      } else if (!iDisabled) {
        setIDisabled(true);
        onClick && onClick(event);
        debounced();
      }
    },
    [onClick, iDisabled, debounced, preventDoubleClick]
  );

  useEffect(() => {
    setIDisabled(false);
    //React virtual dom try to reuse the component,
    //if the component was reused, the unmount event/initialize is not happenning.
    //to fix the iDisabled state issue, we can
    //1. set it to false upon testId changes.
    //2. add `key` prop to the component(so that React know this is complete different component)
    //here we are using #1 approach.
  }, [testId]);

  useEffect(() => {
    return () => {
      debounced.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return typeof loading === "boolean" ? (
    <LoadingButton
      {...dataTestId}
      {...re}
      ref={ref}
      variant={variant}
      color={color}
      className={className}
      disabled={disabled}
      loading={loading}
      loadingPosition={loadingPosition}
      onClick={onClickWrapper}>
      <span style={{ display: "flex" }}>{children}</span>
    </LoadingButton>
  ) : (
    <MatButton
      {...dataTestId}
      {...re}
      ref={ref}
      variant={variant}
      color={color}
      className={className}
      disabled={disabled}
      onClick={onClickWrapper}>
      {children}
    </MatButton>
  );
});

const StyledButton = styled(CustomMatButton)`
  ${({ theme }) => `
      max-height: 47px;
        &.MuiButton-containedPrimary.Mui-disabled {
          background: ${theme.palette.gray.neutralGray400};
          color: ${theme.palette.white.main};
          //opacity: 0.5;
        }
         &.nowrap {
          white-space: nowrap;
          }

        &.accordion-show-expanded {
          display: none;
        }

        .MuiAccordionSummary-expandIconWrapper.Mui-expanded & {
          &.accordion-show-summary {
            display: none;
          }

          &.accordion-show-expanded {
            display:flex;
            transform: rotate(180deg);
          }
        }

        &.MuiButtonBase-root {
          border-radius: ${theme.shape.borderRadius}px;
          transition: border-radius 150ms, background-color 250ms cubic-bezier(0.4,0,0.2,1) 0ms,box-shadow 250ms cubic-bezier(0.4,0,0.2,1) 0ms,border-color 250ms cubic-bezier(0.4,0,0.2,1) 0ms,color 250ms cubic-bezier(0.4,0,0.2,1) 0ms;

          &:not(.MuiButton-text) {
            border-radius: ${theme.shape.borderRadius}px;
            box-shadow: 0px 1px 2px 2px rgba(0,0,0,0.005);
            &:hover {
              border-radius: 25px;
              box-shadow: 0px 1px 2px 2px rgba(0,0,0,0.0025);
            }
          }

          &.MuiLoadingButton-root{
            &.MuiLoadingButton-loading:has(.MuiLoadingButton-loadingIndicatorCenter) {
              color: transparent;
            }
          }
        }

        &.secondary-color-text-button {
          color: ${theme.palette.text.secondary};
        }

        &.alert-color-text-button {
          color: ${theme.palette.text.alert};
        }

        .MuiButton-endIcon {
          margin-left:${theme.spacing(1)};
        }
        .MuiButton-startIcon {
          margin-right:${theme.spacing(1)};
        }

        &.MuiButton-text {
          box-shadow: none;
          min-width: unset;
          background: none;
        }

        &.MuiButton-sizeExtraSmall {
          font-size: ${theme.typography.caption.fontSize}px;
          line-height: ${theme.typography.caption.lineHeight};
          padding: ${theme.spacing(2)} ${theme.spacing(3)};

          .MuiSvgIcon-root {
            width: 12px;
            height: 12px;
          }
        }

        &.MuiButton-sizeSmall {
          font-size: ${theme.typography.body3.fontSize}px;
          line-height: ${theme.typography.body3.lineHeight};
          padding: ${theme.spacing(2)} ${theme.spacing(3)};
        }

        &.MuiButton-sizeMedium {
          font-size: ${theme.typography.body1.fontSize}px;
          line-height: ${theme.typography.body3.lineHeight};
          padding: ${theme.spacing(3)} ${theme.spacing(5)};

          .MuiButton-startIcon {
            margin-right:${theme.spacing(2)};
          }
        }

        &.MuiButton-containedPrimary {
          &:hover {
            background:${theme.palette.primary.main};
          }
          &.Mui-disabled {
            color: ${theme.palette.white.main};
            background-color: ${theme.palette.gray.neutralGray400};
          }
          .MuiLoadingButton-loadingIndicator {
            color: ${theme.palette.white.main};
          }
        }

        &.MuiButton-containedSecondary {
          &:hover {
            background:${theme.palette.secondary.main};
            color: ${theme.palette.white.main};
          }
          &.Mui-disabled {
            color: ${theme.palette.white.main};
            background-color: ${theme.palette.gray.neutralGray400};
          }
          .MuiLoadingButton-loadingIndicator {
            color: ${theme.palette.white.main};
          }
        }

        &.MuiButton-containedGray {
          background: ${theme.palette.gray.neutralGray400};
          color: ${theme.palette.white.main};
        }

        &.MuiButton-containedTertiary {
          &.Mui-disabled {
            .MuiSvgIcon-root {
              opacity: 0.5;
            }
          }
        }

        &.MuiButton-outline.MuiButton-sizeMedium {
          padding: ${theme.spacing(2.75)} ${theme.spacing(5)};
          &.edit-store{
          padding: ${theme.spacing(2.75)} ${theme.spacing(3.5)};
          }
        }

        &.MuiButton-outlinePrimary {
          border: 1px solid ${theme.palette.primary.main};
          color: ${theme.palette.primary.main};
          &:hover {
            background: transparent;
          }
          &.Mui-disabled {
            border: none;
            color: ${theme.palette.primary.main};
            opacity: 0.5;
          }
          .MuiLoadingButton-loadingIndicator {
            color: ${theme.palette.primary.main};
          }
        }

        &.MuiButton-outlineSecondary {
          border: 1px solid ${theme.palette.secondary.main};
          color: ${theme.palette.secondary.main};
          &:hover {
            background: transparent;
          }
          &.Mui-disabled {
            border: none;
            color: ${theme.palette.secondary.main};
            opacity: 0.5;
          }
          .MuiLoadingButton-loadingIndicator {
            color: ${theme.palette.secondary.main};
          }
        }

        &.MuiButton-outlineTertiary {
          border: 1px solid ${theme.palette.tertiary.main};
          color: ${theme.palette.tertiary.main};
          &:hover {
            background: transparent;
          }
          &.Mui-disabled {
            border: none;
            color: ${theme.palette.tertiary.main};
            opacity: 0.5;
          }
          .MuiLoadingButton-loadingIndicator {
            color: ${theme.palette.black.main};
          }
        }

        &.MuiButton-outlineBlack {
          border: 1px solid ${theme.palette.black.main};
          color: ${theme.palette.black.main};

          &:hover {
            background: transparent;
          }

          &.Mui-disabled {
            border: none;
            color: ${theme.palette.black.main};
            opacity: 0.5;
          }

          .MuiLoadingButton-loadingIndicator {
            color: ${theme.palette.black.main};
          }
        }

        &.MuiButton-outlineGray {
          border: 1px solid ${theme.palette.gray.neutralGray300};
          color: ${theme.palette.black.main};

          &:hover {
            background: transparent;
          }

          &.Mui-disabled {
            border: none;
            color: ${theme.palette.black.main};
            opacity: 0.5;
          }

          .MuiLoadingButton-loadingIndicator {
            color: ${theme.palette.black.main};
          }
        }

        &.MuiButton-outlineWhite {
          border: 1px solid ${theme.palette.white.main};
          color: ${theme.palette.white.main};
          &:hover {
            background: transparent;
          }
          &.Mui-disabled {
            border: none;
            color: ${theme.palette.white.main};
            opacity: 0.5;
          }
          .MuiLoadingButton-loadingIndicator {
            color: ${theme.palette.white.main};
          }
        }

        &.MuiButton-dashed {
          border-width: 1px;
          border-style: dashed;
          width: 100%;
          //height: 100%;
          max-height: none;
          padding: ${theme.spacing(2)};
          flex-direction: column;

          ${theme.breakpoints.down("sm")} {
            flex-direction: row;
            width: 100%;
            padding: ${theme.spacing(2)};
          }

          &.MuiButton-dashedSecondary {
            color: ${theme.palette.secondary.main};
            border-color: ${theme.palette.secondary.main};
            background: ${theme.palette.white.main};
          }

          .MuiButton-startIcon {
            margin: 0 0 ${theme.spacing(1.5)} 0;

            ${theme.breakpoints.down("sm")} {
              margin: 0;
            }

            svg {
              font-size: 24px;
            }
          }

          &:hover {

          }

        }


        &.MuiButton-textPrimary, &.MuiButton-textSecondary, &.MuiButton-textTertiary, &.MuiButton-textBlack, &.MuiButton-textWhite {
          //border: 1px solid transparent;
          //border-radius: 20px;
          // &:hover {
          //   border-radius: 20px;
          //   border: 1px solid currentColor;
          // }
          padding: 0;
        }


        @media screen and (max-width: 40em) {
          &.accordion-show-summary {
            display: none;
          }

          &.accordion-show-expanded {
            display: none;
          }
        }


        &.accordion-show-expanded {
          display: none;
        }

        .MuiAccordionSummary-expandIconWrapper.Mui-expanded &{
          &.accordion-show-summary {
            display: none;
          }

          &.accordion-show-expanded {
            display:flex;
            transform: rotate(180deg);
          }
        }

        &.confirm-action-button,
        &.cancel-action-button {
          background-color: ${theme.palette.background.paper};
          font-weight: 600;
        }

        &.confirm-action-button {
          border: ${theme.spacing(0.25)} solid ${theme.palette.border.alert};
          color: ${theme.palette.text.alert};
        }

        &.cancel-action-button {
          border: ${theme.spacing(0.25)} solid;
        }
        &.price-go {
          margin-top: ${theme.spacing(1)} ;
        }

        /* Store Locator Open */
        .store-locator-open, .store-locator-close {
          position: relative;
        }

        .store-locator-open:after {
          content: '';
          position: absolute;
          top: -${theme.spacing(0.75)};
          right: -${theme.spacing(0.75)};
          width: 6px;
          height: 6px;
          background: ${palette.success.main};
          border-radius: 100%;
        }

        /* Store Locator Close */
        .store-locator-close:after {
          content: '';
          position: absolute;
          top: -${theme.spacing(0.75)};
          right: -${theme.spacing(0.75)};
          width: 6px;
          height: 6px;
          background: ${palette.error.main};
          border-radius: 100%;
        }

        /* Animated on hover */

        &.link-hover-animated {
          position: relative;

          &:after {
            position: absolute;
            bottom: 0px;
            left: 0;
            content: "";
            width: 100%;
            height: 1px;
            background-color: currentColor;
            transition: transform 0.3s;
            transform: scaleX(0);
            transform-origin: right;
          }

          &:hover:after {
            transform: scaleX(1);
            transform-origin: left;
          }

        }
    `}
`;

export { StyledButton };
