import React, { useEffect, useState } from "react";
import { StyledLink } from "../../../styled-mui/link/styled-link";
import { StyledTypography } from "../../../styled-mui/typography/styled-typography";
import { EXTERNAL_LINK, LINK_USAGE } from "../../../constants/marketing";
import { useSelector } from "react-redux";
import { GetStaticLinksSelector } from "../../../redux/selectors/category";
import AsyncCall from "../../../_foundation/gtm/async.service";
import { CLICK } from "../../../_foundation/constants/gtm";

interface HeaderNavigationWidgetProps {
  productNav?: any;
  callCloseMegaMenu?: () => void;
}

/**
 * HeaderNavigationWidget
 */
const HeaderNavigationWidget: React.FC<HeaderNavigationWidgetProps> = (props) => {
  const productNav = props.productNav;
  const { callCloseMegaMenu } = props;
  const staticLinksRedux: any = useSelector(GetStaticLinksSelector);
  const [headerLinks, setHeaderLinks] = useState<any[]>([]);

  useEffect(() => {
    if (staticLinksRedux && staticLinksRedux?.headerLinks) {
      setHeaderLinks(staticLinksRedux?.headerLinks || []);
    }
  }, [staticLinksRedux]);

  return (
    <>
      {/* ADD LINK PROMOTIONS */}
      {headerLinks
        ?.filter((l) => l?.usage === LINK_USAGE.PROMO)
        ?.map((link, index) => (
          <StyledLink
            key={index}
            to={link.seo?.url}
            onClick={() => {
              AsyncCall.sendClick(CLICK.NAV, { navlink: link.seo?.url });
              if (callCloseMegaMenu) callCloseMegaMenu();
            }}
            href={link.seo?.type === EXTERNAL_LINK ? link.seo?.url : null}
            className="menu-mobile-link-top bg border">
            <StyledTypography variant="body1" color="primary" sx={{ textTransform: "uppercase" }}>
              {link.seo?.label}
            </StyledTypography>
          </StyledLink>
        ))}
      {/* ADD LINK EVENTS */}
      {headerLinks
        ?.filter((l) => l?.usage === LINK_USAGE.EVENT)
        ?.map((link, index) => (
          <StyledLink
            key={index}
            to={link.seo?.url}
            onClick={() => {
              AsyncCall.sendClick(CLICK.NAV, { navlink: link.seo?.url });
              if (callCloseMegaMenu) callCloseMegaMenu();
            }}
            href={link.seo?.type === EXTERNAL_LINK ? link.seo?.url : null}
            className="menu-mobile-link-top">
            <StyledTypography variant="body1" color="secondary" sx={{ textTransform: "uppercase" }}>
              {link.seo?.label}
            </StyledTypography>
          </StyledLink>
        ))}
      {/* ADD LINK TOP CATEGORIES */}
      {productNav}
      {/* ADD LINK STANDARD */}
      {headerLinks
        ?.filter((l) => l?.usage === LINK_USAGE.STANDARD)
        ?.map((link, index) => (
          <StyledLink
            key={index}
            to={link.seo?.url}
            onClick={() => {
              AsyncCall.sendClick(CLICK.NAV, { navlink: link.seo?.url });
              if (callCloseMegaMenu) callCloseMegaMenu();
            }}
            href={link.seo?.type === EXTERNAL_LINK ? link.seo?.url : null}
            className="menu-mobile-link-top">
            <StyledTypography variant="body1" sx={{ textTransform: "uppercase" }}>
              {link.seo?.label}
            </StyledTypography>
          </StyledLink>
        ))}
    </>
  );
};

export default HeaderNavigationWidget;
