import styled from "@mui/styled-engine-sc";
import SvgIcon from "@mui/material/SvgIcon";

const CustomSvg = styled((props) => (
  <SvgIcon width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} />
))`
  ${({ theme }) => `
    width:12px;
    height:12px;

    &.small {
      width:16px;
      height:16px;
    }

    &.medium {
      width:20px;
      height:20px;
    }
  `}
`;

export const SvgTrash = (props) => {
  const { className = "" } = props;

  return (
    <CustomSvg {...{ className }}>
      <path d="M3.5 10.5c-.275 0-.51-.1-.705-.295A.963.963 0 0 1 2.5 9.5V3c-.14 0-.26-.05-.355-.145A.487.487 0 0 1 2 2.5c0-.14.05-.26.145-.355A.487.487 0 0 1 2.5 2h2c0-.14.05-.26.145-.355A.487.487 0 0 1 5 1.5h2c.14 0 .26.05.355.145A.487.487 0 0 1 7.5 2h2c.14 0 .26.05.355.145A.487.487 0 0 1 10 2.5c0 .14-.05.26-.145.355A.487.487 0 0 1 9.5 3v6.5c0 .275-.1.51-.295.705a.963.963 0 0 1-.705.295h-5Zm0-7.5v6.5h5V3h-5Zm1 5c0 .14.05.26.145.355A.487.487 0 0 0 5 8.5c.14 0 .26-.05.355-.145A.487.487 0 0 0 5.5 8V4.5c0-.14-.05-.26-.145-.355A.487.487 0 0 0 5 4c-.14 0-.26.05-.355.145A.487.487 0 0 0 4.5 4.5V8Zm2 0c0 .14.05.26.145.355A.487.487 0 0 0 7 8.5c.14 0 .26-.05.355-.145A.487.487 0 0 0 7.5 8V4.5c0-.14-.05-.26-.145-.355A.487.487 0 0 0 7 4c-.14 0-.26.05-.355.145A.487.487 0 0 0 6.5 4.5V8Z" />
    </CustomSvg>
  );
};
