/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
import React from "react";
import styled from "@mui/styled-engine-sc";
import Drawer from "@mui/material/Drawer";

export const StyledDrawer = styled(({ ...props }) => <Drawer {...props} />)`
  ${({ theme }) => `
    &.product-list-drawer {
      .MuiBackdrop-root {
        background-color:rgba(33, 33, 33, 0.85);
      }
      .MuiDrawer-paper {
        border-radius: 8px 8px 0 0;
        overflow-y: visible;
      }
      .product-list-drawer-btn {
        &:before {
          content: "";
          display: block;
          width: 100%;
          height: 50px;
          background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
          position: absolute;
          bottom: 60px;
          left: 0;
        }
      }
    }
    &.filter-gallery-drawer {
      .MuiBackdrop-root {
        background-color:rgba(33, 33, 33, 0.6);
      }
      .MuiDrawer-paper {
        border-radius: 8px 8px 0 0;
        background: ${theme.palette.secondary.extraLight};
        padding: ${theme.spacing(5)};
        overflow-y: visible;
      }
    }
`}
`;
