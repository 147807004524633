/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Services - Account and Contract
 * These services provide APIs to manage accounts and contracts.  a contract is an agreement that represents the terms and conditions that apply to a transaction. An account is a relationship between the merchant and the financial institution that processes transactions for that merchant.
 *
 * The version of the OpenAPI document: 9.1.6
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { SuiviCommande } from './suivi-commande';
import { SuiviDevis } from './suivi-devis';
import { SuiviInstallation } from './suivi-installation';
import { UniversDocument } from './univers-document';
import { UniversProduit } from './univers-produit';

/**
 * 
 * @export
 * @interface OrderLight
 */
export interface OrderLight {
    /**
     * 
     * @type {string}
     * @memberof OrderLight
     */
    creationDate?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderLight
     */
    customerId?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderLight
     */
    dateDispoReel?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderLight
     */
    dateMiseADispo?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderLight
     */
    documentId?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderLight
     */
    documentRef?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderLight
     */
    magasinCreation?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderLight
     */
    magasinOrigine?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderLight
     */
    modificationDate?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderLight
     */
    montantNetHT?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderLight
     */
    montantNetHT_facture?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderLight
     */
    montantNetTTC?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderLight
     */
    montantNetTTC_facture?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrderLight
     */
    nextDocs?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof OrderLight
     */
    partPoseTTC?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderLight
     */
    precDocumentCreationDate?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderLight
     */
    precDocumentId?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderLight
     */
    precDocumentType?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderLight
     */
    refChantierArtisan?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderLight
     */
    refCommandeArtisan?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderLight
     */
    shippingMode?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderLight
     */
    sourceSystem?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderLight
     */
    sousType?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderLight
     */
    statutDoc?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderLight
     */
    statutTransaction?: OrderLightStatutTransactionEnum;
    /**
     * 
     * @type {SuiviCommande}
     * @memberof OrderLight
     */
    suiviCommande?: SuiviCommande;
    /**
     * 
     * @type {SuiviDevis}
     * @memberof OrderLight
     */
    suiviDevis?: SuiviDevis;
    /**
     * 
     * @type {SuiviInstallation}
     * @memberof OrderLight
     */
    suiviInstallation?: SuiviInstallation;
    /**
     * 
     * @type {string}
     * @memberof OrderLight
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderLight
     */
    universDoc?: string;
    /**
     * 
     * @type {UniversDocument}
     * @memberof OrderLight
     */
    universDocument?: UniversDocument;
    /**
     * 
     * @type {UniversProduit}
     * @memberof OrderLight
     */
    universProduit?: UniversProduit;
    /**
     * 
     * @type {boolean}
     * @memberof OrderLight
     */
    vrProject?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OrderLight
     */
    webId?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum OrderLightStatutTransactionEnum {
    Prep = 'PREP',
    Disp = 'DISP',
    Annl = 'ANNL',
    Comp = 'COMP'
}



