import styled from "@mui/styled-engine-sc";
import InputLabel from "@mui/material/InputLabel";
import { palette } from "../../themes/color-palette";

const StyledInputLabel = styled(({ ...props }) => <InputLabel {...props} />)`
  ${({ theme }) => `
    color: ${palette.gray.neutralGray600};
    font-weight: 400;

  /* &.MuiInputLabel-outlined {
    &.MuiInputLabel-shrink{
      transform: none;
    }
    &.Mui-disabled {
      color: ${theme.palette.text.disabled};
    }
    position: relative;
    margin-bottom: ${theme.spacing(1)};
    font-weight: 500;
    color: ${theme.palette.text.secondary};
    z-index: unset;
    pointer-events: unset;

    &.Mui-focused {
      color: ${theme.palette.text.primary};
    }
  } */
`}
`;

export { StyledInputLabel };
