/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
export const site = {
  storeName: process.env.REACT_APP_STORENAME as string,
  transactionContext: process.env.REACT_APP_TRANSACTION_CONTEXT as string,
  searchContext: process.env.REACT_APP_SEARCH_CONTEXT as string,
  dxContext: process.env.REACT_APP_DX_CONTEXT as string,
  cmcPath: "/lobtools/cmc/ManagementCenterMain",
  approvalsContext: process.env.REACT_APP_APPROVALS_CONTEXT as string,
  oneTrustDomain: process.env.REACT_APP_DATA_DOMAIN_SCRIPT as string,
  configuratorUrl: process.env.REACT_APP_CONFIGURATOR_URL as string,
  defaultImage: `${process.env.PUBLIC_URL}/images/bientot_disponible.svg` as string,
  defaultLocalImageUrl: `${process.env.PUBLIC_URL}` as string,
  sofincoUri: process.env.REACT_APP_SOFINCO_URI as string,
  sofincoPartners: process.env.REACT_APP_SOFINCO_PARTNERS as string,
  sofincoCampaigns: process.env.REACT_APP_SOFINCO_CAMPAIGNS as string,
  crownpeakApiKey: process.env.REACT_APP_CROWNPEAK_API_KEY as string,
  crownpeakUrl: process.env.REACT_APP_CROWNPEAK_URI as string,
  googleMapKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY as string,
  googleRecaptcha: process.env.REACT_APP_GOOGLE_RECAPTCHA_V3 as string,
  //gtmID: process.env.REACT_APP_GOOGLE_GTM_ID as string,
  //gtmAuth: process.env.REACT_APP_GOOGLE_GTM_AUTH as string,
  //gtmPreview: process.env.REACT_APP_GOOGLE_GTM_PREVIEW as string,
  facebookAppId: process.env.REACT_APP_FACEBOOK_APPID as string,
  enableGA: process.env.REACT_APP_GOOGLE_ENABLE_GA as string,
  enableUA: process.env.REACT_APP_GOOGLE_ENABLE_UA as string,
  enableGA4: process.env.REACT_APP_GOOGLE_ENABLE_GA4 as string,
  gtmEnv: process.env.REACT_APP_GOOGLE_GTM_ENV as string,
  gtmV: (process.env.REACT_APP_GOOGLE_GTM_ENV + "_" + process.env.REACT_APP_GOOGLE_GTM_VERSION) as string,
  activeProject: process.env.REACT_APP_ACTIVE_PROJECT as string,
};
