/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React, { Suspense } from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
//Custom libraries
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
// import Bugsnag from "@bugsnag/js";
// import BugsnagPluginReact from "@bugsnag/plugin-react";
// import BugsnagPerformance from "@bugsnag/browser-performance";

import "./i18n";
//Redux
import store from "./redux/store/index";
//UI
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, Theme, StyledEngineProvider } from "@mui/material";
import { StylesProvider } from "@mui/styles";
import { StyleSheetManager, ThemeProvider as StyledThemeProvider } from "styled-components";
import { StyledCircularProgress } from "@hcl-commerce-store-sdk/react-component";
import { CurrentTheme } from "./themes";
import "./index.scss";
import { StoreLocatorProvider } from "./_foundation/context/store-locator-context";
import { StoreShippingModeProvider } from "./_foundation/context/store-shipping-mode-context";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const rootElement = document.getElementById("root");

// if (process.env.NODE_ENV === "production") {
//   Bugsnag.start({
//     apiKey: process.env.REACT_APP_BUGSNAG_API_KEY || "",
//     plugins: [new BugsnagPluginReact()],
//   });
//   BugsnagPerformance.start({ apiKey: process.env.REACT_APP_BUGSNAG_API_KEY || "" });
// }

const googleReCaptchaKey = process.env.REACT_APP_GOOGLE_RECAPTCHA_V3 || "";
const googleOAuthKey = process.env.REACT_APP_GOOGLE_OAUTH || "";
const ErrorBoundary = React.Fragment;
//const ErrorBoundary = Bugsnag.getPlugin("react")?.createErrorBoundary(React) || React.Fragment;

render(
  <ErrorBoundary>
    <Provider store={store}>
      <Suspense fallback={<StyledCircularProgress className="horizontal-padding-5" />}>
        <StyleSheetManager disableCSSOMInjection={(window as any).__isPrerender__ === true}>
          <StylesProvider injectFirst>
            <StyledThemeProvider theme={CurrentTheme}>
              <StyledEngineProvider injectFirst>
                <ThemeProvider theme={CurrentTheme}>
                  <CssBaseline />
                  <StoreLocatorProvider>
                    <StoreShippingModeProvider>
                      <GoogleOAuthProvider clientId={googleOAuthKey}>
                        <GoogleReCaptchaProvider reCaptchaKey={googleReCaptchaKey}>
                          <App />
                        </GoogleReCaptchaProvider>
                      </GoogleOAuthProvider>
                    </StoreShippingModeProvider>
                  </StoreLocatorProvider>
                </ThemeProvider>
              </StyledEngineProvider>
            </StyledThemeProvider>
          </StylesProvider>
        </StyleSheetManager>
      </Suspense>
    </Provider>
  </ErrorBoundary>,
  rootElement
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
