import styled from "@mui/styled-engine-sc";

export const StyledProductImage = styled("img")`
  ${({ theme }) => `
    position: relative;
    width: 100%;
    display: block;
    //max-height: 445px;

    &.thumbnail {
      padding: ${theme.spacing(0.5)};
      border: solid 1px ${theme.palette.grey[300]};
      border-radius: ${theme.spacing(0.25)};

      &:hover {
        cursor: pointer;
      }
    }
  `}
`;

export const StyledProductImageTag = styled("div")`
  ${({ theme }) => `
    position: absolute;
    top: 5px;
    left: 7px;
    z-index: 19;
    border-radius: 0 0 ${theme.spacing(2)} 0;
    padding: ${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(2)} ${theme.spacing(3)};
    background: ${theme.palette.secondary.main};
    color: ${theme.palette.white.main};

    &.list {
      top: 0px;
      left: 0px;
      border-radius: 8px 0 8px 0;
    }

    &.promo {
      background: ${theme.palette.primary.main};
    }

    &.promo-plp-card {
      top: 0px;
      left: 0px;
    }

    ${theme.breakpoints.down("sm")} {
      font-size: 12px;
    }

  `}
`;

export const StyledProductImageLabel = styled("ul")`
  ${({ theme }) => `
    position: absolute;
    right: 15px;
    top: 15px;
    z-index: 19;
    list-style: none;
    width: 49px;
    height: 48px;

    ${theme.breakpoints.down("sm")} {
      right: 12px;
      top: 12px;
    }

    li {
      margin-bottom: ${theme.spacing(1.5)};
    }
  `}
`;

export const StyledContainer = styled("div")`
  ${({ theme }) => `
    position:relative;
    border: solid 1px ${theme.palette.gray.neutralGray600};
    &::before {
      position: absolute;
      top: -5px;
      right: -1px;
      content: "";
      width: 1px;
      height: 4px;
      background-color: ${theme.palette.gray.neutralGray600};
    }
    &::after {
      position: absolute;
      top: -1px;
      right: -5px;
      content: "";
      width: 4px;
      height: 1px;
      border-bottom: 1px solid ${theme.palette.gray.neutralGray600};
    }
  `}
`;
