/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2021
 *
 *==================================================
 */
//Standard libraries
import { createReducer, AnyAction } from "@reduxjs/toolkit";
import cloneDeep from "lodash/cloneDeep";
//Redux
import initStates from "./initStates";
import { CategoryReducerState } from "./reducerStateInterface";
import {
  UPDATE_CATEGORIES_STATE_ACTION,
  UPDATE_HEADER_LINK_STATE_ACTION,
  UPDATE_CURRENT_CATEGORY_STATE_ACTION,
  UPDATE_TOP_CATEGORIES_STATE_ACTION,
  UPDATE_FILTERED_CATEGORIES_STATE_ACTION,
} from "../actions/category";

/**
 * Category reducer
 */
const categoryReducer = createReducer(initStates.categories, (builder) => {
  builder.addCase(UPDATE_CATEGORIES_STATE_ACTION, (state: CategoryReducerState | any, action: AnyAction) => {
    const categories = cloneDeep(action.payload);
    const topCategoriesList: any[] = [];
    if (categories) {
      for (let i = 0; i < categories.length; i++) {
        const category = categories[i];
        topCategoriesList.push(categories[i]);
        if (category.children) {
          categories.push(...category.children);
        }
      }
    }
    state.categories = topCategoriesList;
  });

  builder.addCase(UPDATE_HEADER_LINK_STATE_ACTION, (state: CategoryReducerState | any, action: AnyAction) => {
    const links = cloneDeep(action.payload);
    state.staticLinks = links?.navData;
  });

  builder.addCase(UPDATE_CURRENT_CATEGORY_STATE_ACTION, (state: CategoryReducerState | any, action: AnyAction) => {
    const { categories, currentCategoryId } = action.payload;
    if (categories && currentCategoryId) {
      const category = categories.filter((cat) => cat.identifier === currentCategoryId)[0];
      state.currentCategory = category;
    }
  });

  builder.addCase(UPDATE_TOP_CATEGORIES_STATE_ACTION, (state: CategoryReducerState, action: AnyAction) => {
    state.topCategories = action.payload;
  });

  builder.addCase(UPDATE_FILTERED_CATEGORIES_STATE_ACTION, (state: CategoryReducerState, action: AnyAction) => {
    state.filteredCategories = action.payload;
  });
});

export default categoryReducer;
