// check when a product is available for shipping
export const isAvailableForShipping = (availShipProd) => {
  return availShipProd && availShipProd.message.dateDispo && availShipProd.message.dateDispo.length > 0 ? true : false;
};

// check when all product is available in store
export const isAvailableInStore = (availStoreProd: Record<any, any>): boolean => {
  return !!availStoreProd?.cartAvailabilityDate?.length;
};

//check when a singlular product is available
export const isProductAvailableInStore = (availStoreProd: Record<any, any>, partNumber: string): boolean => {
  return !!(availStoreProd?.cartAvailabilityDate?.length || availStoreProd?.[partNumber]?.date?.length);
};

export const getAvailableForShippingAttributes = (dataAvailabilityShipping, order) => {
  return (
    dataAvailabilityShipping &&
    dataAvailabilityShipping?.availabilityDetails?.find((avails) => avails.sku === order.partNumber)
  );
};

export const getAvailableInStoreAttributes = (dataAvailabilityInStore, partNumber, storeId) => {
  const productAvail: any = dataAvailabilityInStore?.result[storeId] || {};
  return productAvail;
  // return (
  //   dataAvailabilityInStore &&
  //   dataAvailabilityInStore.availabilities &&
  //   dataAvailabilityInStore.availabilities.availabilityDetails.find((avails) => avails.sku === order.partNumber)
  // );
};

export const getProductsParamsFromOrders = (orderItems) => {
  const prodTable: any[] = [];
  orderItems &&
    orderItems
      .filter((o) => o.buyable === "true")
      .forEach((order) => {
        prodTable.push({ sku: order.partNumber, quantity: order.quantity });
      });
  return prodTable.length ? prodTable : null;
};
