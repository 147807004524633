/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React, { ChangeEvent, useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { OK } from "http-status-codes";
import { useTranslation } from "react-i18next";
import Axios, { Canceler } from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import getDisplayName from "react-display-name";
import cloneDeep from "lodash/cloneDeep";
//Foundation libraries
import { useSite } from "../../../_foundation/hooks/useSite";
import siteContentService from "../../../_foundation/apis/search/siteContent.service";
import searchDisplayService from "../../../_foundation/apis/transaction/searchDisplay.service";
//Custom libraries
import { CommerceEnvironment, GENERIC_CUSTOMER, SEARCHTERM, SEARCH_FIND_PROFILE } from "../../../constants/common";
import { SEARCH } from "../../../constants/routes";
import { KEYWORD_LIMIT, PRODUCT_SEARCH_LIMIT } from "../../../configs/catalog";
//Redux
import { currentContractIdSelector } from "../../../redux/selectors/contract";
import * as searchActions from "../../../redux/actions/search";
import { resetProductListAction } from "../../../redux/actions/catalog";
//UI
import { StyledGrid } from "@hcl-commerce-store-sdk/react-component";
import { InputAdornment, ClickAwayListener, Stack, Hidden } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { commonUtil } from "@hcl-commerce-store-sdk/utils";
import storeUtil from "../../../utils/storeUtil";
import { LS_SEARCH_TERM } from "../../../_foundation/constants/common";
import {
  StyledIconButton,
  StyledTextField,
  StyledTypography,
  StyledLink,
  StyledBox,
  StyledButton,
} from "../../../styled-mui";
import { StyledSearchBar } from "./styled-search-bar";
import { SvgTrash } from "../../svg/trash";
import { ImageLayout } from "../image";
import FormattedPriceDisplay from "../formatted-price-display";

import "keen-slider/keen-slider.min.css";
import { useKeenSlider } from "keen-slider/react";
import { localStorageUtil } from "../../../_foundation/utils/storageUtil";
import PageLoader from "../../elements/loader/PageLoader";
import { debounce } from "lodash-es";
import productsService from "../../../_foundation/apis/search/products.service";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Link } from "react-router-dom";

interface SearchBarProps {
  showSearchBar: boolean;
  onChange?: any;
  onBlur?: any;
}

const SearchBar: React.FC<SearchBarProps> = ({ showSearchBar, onChange, onBlur }) => {
  const widgetName = getDisplayName(SearchBar);
  const contractId = useSelector(currentContractIdSelector);
  const [keywordSuggestions, setKeywordSuggestions] = React.useState<Array<any>>([]);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location: any = useLocation();

  //const keywordTitle = "SUGGESTIONS DE RECHERCHE";
  const [input, setInput] = React.useState("");
  const [index, setIndex] = React.useState(0);
  const { mySite } = useSite();
  const dispatch = useDispatch();
  const [showSuggestions, setshowSuggestions] = React.useState(false);
  const [recentSearch, setRecentSearch] = React.useState([]);
  const [inputFocused, setInputFocused] = React.useState(false);
  const [loadProduct, setLoadProduct] = React.useState(false);
  const [productList, setProductList] = React.useState<any[] | null>(null);

  const getSuggestions = useCallback(
    (name, index, url?) => ({
      ...cloneDeep(CommerceEnvironment.suggestionSkeleton),
      name,
      arrIndex: `${index}`,
      url: url ?? `${SEARCH}?${SEARCHTERM}=${commonUtil.encodeURLParts(name)}`,
    }),
    []
  );

  const clearSuggestions = () => {
    setIndex(0);
    setKeywordSuggestions([]);
  };

  const clearSuggestionsAndInputField = () => {
    clearKeywords();
    clearSuggestions();
    setshowSuggestions(false);
    setInputFocused(false);
    setInput("");
    //onBlur && onBlur();
  };

  const clearRecentSearch = () => {
    localStorageUtil.remove(LS_SEARCH_TERM);
    setRecentSearch([]);
  };

  const clearKeywords = () => {
    dispatch(searchActions.KEYWORDS_RESET_ACTION(""));
  };

  const setKeywordsToLocalStorage = (list: string[]) => {
    dispatch(searchActions.KEYWORDS_UPDATED_ACTION(list));
  };
  const CancelToken = Axios.CancelToken;
  const cancels: Canceler[] = [];

  const payloadBase: any = {
    widget: widgetName,
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c);
    }),
  };

  useEffect(() => {
    if (mySite && contractId) {
      setRecentSearch(localStorageUtil.get(LS_SEARCH_TERM)?.split("|") || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mySite, t, contractId]);

  useEffect(() => {
    const queryString = location.search;
    const params = new URLSearchParams(queryString);
    const searchTermValue = params.get(SEARCHTERM);
    if (searchTermValue === null) {
      setInput("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    return () => {
      cancels.forEach((cancel) => cancel());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const debouncedSearch = useRef(debounce((searchTerm) => retrieveSuggestions(searchTerm), 500)).current;

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch]);

  const retrieveSuggestions = (searchTerm: any) => {
    searchTerm = searchTerm.trim();
    if (searchTerm.length > 1) {
      const storeID = mySite.storeID;
      const catalogId = mySite.catalogID;

      const parameters: any = {
        responseFormat: "application/json",
        storeId: storeID,
        term: searchTerm,
        limit: KEYWORD_LIMIT,
        contractId: contractId,
        catalogId: catalogId,
        // query: { searchTerm },
        ...payloadBase,
      };

      siteContentService.findKeywordSuggestionsByTermUsingGET(parameters).then((res) => {
        if (res.status === OK) {
          const keywordSuggestions = res?.data.suggestionView[0].entry;
          if (keywordSuggestions) {
            let nameListIndex = 1;
            const keywords = keywordSuggestions.map(({ term }) => getSuggestions(term, nameListIndex++));
            setLoadProduct(true);
            setKeywordSuggestions(keywords);
            setshowSuggestions(true);
            const terms = keywords.map(({ name }) => name);
            setKeywordsToLocalStorage(terms);

            const parametersPdt = {
              contractId: contractId,
              limit: PRODUCT_SEARCH_LIMIT,
              offset: 0,
              searchTerm: searchTerm,
              query: { profileName: SEARCH_FIND_PROFILE, customerSegment: GENERIC_CUSTOMER },
              ...payloadBase,
            };
            productsService.findProductsUsingGET(parametersPdt).then((res) => {
              const _productList = res?.data?.contents;
              if (_productList && _productList.length > 0) {
                setProductList(_productList);
              }
              setLoadProduct(false);
            });
          } else {
            clearKeywords();
            clearSuggestions();
          }
        }
      });
    } else if (searchTerm.length === 0) {
      onBlur && onBlur();
      //setInputFocused(true);
    }
  };

  const saveSearchTearm = (name: string | null = null) => {
    const oldSearch: string[] = recentSearch || [];
    //exit if already exist
    if (
      oldSearch.some(
        (e) =>
          (name != null && e.toLocaleLowerCase() === name.toLocaleLowerCase()) ||
          e.toLocaleLowerCase() === input.toLocaleLowerCase()
      )
    )
      return;

    if (oldSearch.length > 2) {
      oldSearch.splice(-1);
    }
    oldSearch.unshift(name || input);

    localStorageUtil.set(LS_SEARCH_TERM, oldSearch.join("|"));
  };

  const submitSearch = (props: any) => {
    props.preventDefault();
    debouncedSearch.cancel();
    if (input?.trim()) {
      saveSearchTearm();
      let url = "";
      const storeID = mySite.storeID;
      const searchTerm = input.trim();
      const parameters: any = { storeId: storeID, searchTerm, ...payloadBase };
      searchDisplayService
        .getSearchDisplayView(parameters)
        .then((res) => {
          if (res.status === OK) {
            dispatch(resetProductListAction({ parameters: { ...parameters, widget: widgetName } }));
            url = res?.data.redirecturl;

            if (url === undefined) {
              url = SEARCH + "?" + SEARCHTERM + "=" + searchTerm;
            }
            clearSuggestionsAndInputField();
            redirectTo(url);
          }
        })
        .catch((e) => {
          console.log("Error in getSearchDisplayView API call", e);
        });
    }
  };

  const redirectTo = (url: string) => {
    clearSuggestions();
    //setShowSearchBar(false);
    //redirect
    if (url.startsWith("http")) {
      window.location.href = url;
    } else {
      navigate(url);
    }
  };

  const handleFocus = () => {
    if (input === "") setInputFocused(true);
  };

  const handleSearchTermChange = (event: ChangeEvent) => {
    event.persist();

    const element = event.currentTarget as HTMLInputElement;
    setInput(element.value);
    if (inputFocused === true) {
      setInputFocused(false);
    } else if (element.value.length === 0 && !inputFocused) {
      setInputFocused(true);
    }
    debouncedSearch(element.value);
  };

  const [sliderRef] = useKeenSlider<HTMLDivElement>({
    initial: 0,
    slides: {
      perView: 1.2,
      spacing: 20,
      origin: "auto",
    },
  });
  return (
    <ClickAwayListener onClickAway={clearSuggestionsAndInputField}>
      <StyledSearchBar>
        <form onSubmit={submitSearch} noValidate>
          <StyledTextField
            size="small"
            autoComplete="off"
            type="text"
            placeholder={t("Header.SearchBar.SearchField")}
            value={input}
            name="searchTerm"
            onChange={handleSearchTermChange}
            InputProps={{
              onFocus: handleFocus,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <>
                  {showSuggestions ? (
                    <InputAdornment position="end">
                      <StyledIconButton
                        className="adornment-icon big"
                        data-testid="button-clear-search-suggestions-input-fields"
                        onClick={clearSuggestionsAndInputField}>
                        <CloseIcon titleAccess={t("Header.SearchBar.ClearButton")} />
                      </StyledIconButton>
                    </InputAdornment>
                  ) : (
                    <></>
                  )}
                </>
              ),
            }}
          />
        </form>

        {inputFocused ? (
          <>
            {recentSearch && recentSearch.length > 0 && (
              <StyledBox className="searchBar-results">
                <StyledButton
                  startIcon={<SvgTrash />}
                  color="black"
                  testId="clear-historique"
                  size="extraSmall"
                  variant="text"
                  sx={{
                    textTransform: "none",
                    fontWeight: 400,
                    position: "absolute",
                    right: "16px",
                    maxWidth: { xs: "20%", lg: "none" },
                  }}
                  className=""
                  onClick={clearRecentSearch}>
                  {t("Header.SearchBar.DeleteHistoryTitle")}
                </StyledButton>

                <ul>
                  <StyledTypography variant="body3" component="li" className="uppercase" color="secondary" pb={3}>
                    {t("Header.SearchBar.RecentSearchTitle")}
                  </StyledTypography>
                  {recentSearch.map((terms, index) => (
                    <StyledBox key={index} component="li" pb={2}>
                      <StyledLink
                        to={`${SEARCH}?${SEARCHTERM}=${commonUtil.encodeURLParts(terms)}`}
                        onClick={() => {
                          clearSuggestionsAndInputField();
                        }}>
                        <StyledTypography variant="body2">{terms}</StyledTypography>
                      </StyledLink>
                    </StyledBox>
                  ))}
                </ul>
              </StyledBox>
            )}
          </>
        ) : showSuggestions ? (
          <StyledBox className="searchBar-results">
            {keywordSuggestions.length !== 0 ? (
              <StyledGrid container columnSpacing={{ xs: 0, md: 4 }} rowSpacing={{ xs: 6, md: 0 }}>
                <StyledGrid item xs={12} sm={6} lg={7} sx={{ position: "relative" }}>
                  <ul>
                    {showSuggestions ? (
                      <>
                        <StyledTypography variant="body3" component="li" className="uppercase" color="secondary" pb={3}>
                          {t("Header.SearchBar.KeywordTitle")}
                        </StyledTypography>
                        {keywordSuggestions?.map((e: any, i: number) => (
                          <StyledBox component="li" pb={2} key={`keyword-${i}`}>
                            <StyledLink
                              testId={`keywords-${e.name}`}
                              to={e.url}
                              onClick={() => {
                                saveSearchTearm(e.name);
                                clearSuggestionsAndInputField();
                              }}>
                              <StyledTypography
                                variant="body2"
                                className={e.arrIndex === index ? "active" : ""}
                                key={e.arrIndex}
                                id={`megamenu_department_${e.id}`}
                                title={e.name}>
                                {e.name}
                              </StyledTypography>
                            </StyledLink>
                          </StyledBox>
                        ))}
                      </>
                    ) : null}
                  </ul>
                </StyledGrid>
                <StyledGrid item xs={12} sm={6} md={12} lg={5}>
                  <StyledTypography variant="body3" component="p" className="uppercase" color="secondary" pb={3}>
                    {t("Header.SearchBar.FrequentlySearchedProductsTitle")}
                  </StyledTypography>
                  {loadProduct && <PageLoader minHeight={100} />}
                  {!loadProduct && productList && productList.length > 0 && (
                    <>
                      <Hidden mdDown>
                        {productList.slice(-3).map((product, index) => (
                          <StyledBox
                            key={index}
                            mb={4}
                            sx={{ border: 1, borderRadius: 1, borderColor: "gray.neutralGray300" }}>
                            <StyledLink
                              to={
                                !mySite.storeCfg.userData.SEO_FE_DISABLE ||
                                (mySite.storeCfg.userData.SEO_FE_DISABLE &&
                                  mySite.storeCfg.userData.SEO_FE_DISABLE != "true")
                                  ? `/produits${product?.seo?.href}`
                                  : product?.seo?.href
                              }
                              id={`product_${index}`}
                              onClick={() => {
                                clearSuggestionsAndInputField();
                              }}>
                              <Stack direction="row">
                                <StyledBox className="searchBar-results-img-container">
                                  <ImageLayout
                                    src={storeUtil.getUrlPicture(product.thumbnailRaw)}
                                    alt={product.name}
                                    width="100%"
                                    //height={200}
                                    className=""
                                  />
                                </StyledBox>
                                <StyledBox sx={{ flex: 1 }}>
                                  <StyledBox p={2} pl={4} sx={{ textAlign: "right" }}>
                                    <StyledTypography
                                      variant="subtitle2"
                                      component="p"
                                      align="left"
                                      pb={1}
                                      className="line-clamp-2">
                                      {product.name}
                                    </StyledTypography>
                                    <FormattedPriceDisplay min={storeUtil.getOfferPrice(product).min} color="" />
                                  </StyledBox>
                                </StyledBox>
                              </Stack>
                            </StyledLink>
                          </StyledBox>
                        ))}
                      </Hidden>
                      <Hidden mdUp>
                        <StyledBox ref={sliderRef} className="keen-slider">
                          {productList.slice(-3).map((product, index) => (
                            <StyledBox key={index} className="keen-slider__slide">
                              <StyledBox sx={{ border: 1, borderRadius: 1, borderColor: "gray.neutralGray300" }}>
                                <StyledLink
                                  to={
                                    !mySite.storeCfg.userData.SEO_FE_DISABLE ||
                                    (mySite.storeCfg.userData.SEO_FE_DISABLE &&
                                      mySite.storeCfg.userData.SEO_FE_DISABLE != "true")
                                      ? `/produits${product?.seo?.href}`
                                      : product?.seo?.href
                                  }
                                  id={`product_${index}`}
                                  onClick={() => {
                                    clearSuggestionsAndInputField();
                                  }}>
                                  <Stack direction="row">
                                    <StyledBox className="searchBar-results-img-container">
                                      <ImageLayout
                                        src={storeUtil.getUrlPicture(product.thumbnailRaw)}
                                        alt={product.name}
                                        width="100%"
                                        //height={200}
                                        className=""
                                      />
                                    </StyledBox>
                                    <StyledBox sx={{ flex: 1 }}>
                                      <StyledBox p={2} pl={4} sx={{ textAlign: "right" }}>
                                        <StyledTypography
                                          variant="subtitle2"
                                          component="p"
                                          align="left"
                                          pb={1}
                                          className="line-clamp-2">
                                          {product.name}
                                        </StyledTypography>
                                        <FormattedPriceDisplay min={storeUtil.getOfferPrice(product).min} color="" />
                                      </StyledBox>
                                    </StyledBox>
                                  </Stack>
                                </StyledLink>
                              </StyledBox>
                            </StyledBox>
                          ))}
                        </StyledBox>
                      </Hidden>
                      <StyledBox pt={{ xs: 2, sm: 0 }} sx={{ textAlign: { xs: "right", sm: "left" } }}>
                        <StyledButton
                          component={Link}
                          to={`${SEARCH}?${SEARCHTERM}=${commonUtil.encodeURLParts(input)}`}
                          onClick={() => {
                            //saveSearchTearm(e.name);
                            clearSuggestionsAndInputField();
                          }}
                          variant="text"
                          color="secondary"
                          size="small"
                          className="link-hover-animated"
                          endIcon={<ChevronRightIcon />}
                          sx={{ textTransform: "none" }}>
                          {t("Header.SearchBar.SeeEverythingButton")}
                        </StyledButton>
                      </StyledBox>
                    </>
                  )}
                </StyledGrid>
              </StyledGrid>
            ) : (
              <StyledTypography variant="body2" component="p" className="" align="center" color="" p={4}>
                {t("Header.SearchBar.NoResultMessage")}
              </StyledTypography>
            )}
          </StyledBox>
        ) : null}
      </StyledSearchBar>
    </ClickAwayListener>
  );
};

export { SearchBar };
