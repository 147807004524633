import SvgIcon from "@mui/material/SvgIcon";
import styled from "@mui/styled-engine-sc";

const CustomSvg = styled((props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} />
))`
  ${({ theme }) => `
    width:24px;
    height:24px;

    &.auto {
      width:1em;
      height:1em;
    }

    &.small {
      width:12px;
      height:12px;
    }

    &.medium {
      width:20px;
      height:20px;
    }

  `}
`;

export const SvgChevronDown = (props) => {
  const { className = "", big } = props;

  return (
    <CustomSvg {...{ className }}>
      {big ? (
        <path d="M2.8804 6.88596C3.13066 6.63204 3.43098 6.5 3.77134 6.5C4.1117 6.5 4.41201 6.63204 4.66228 6.88596L12 14.331L19.3477 6.85549C19.578 6.62188 19.8783 6.5 20.2287 6.5C20.579 6.5 20.8693 6.63204 21.1196 6.88596C21.3699 7.13989 21.5 7.4446 21.5 7.78994C21.5 8.13527 21.3699 8.43998 21.1196 8.69391L12.6807 17.2258C12.5806 17.3273 12.4705 17.3984 12.3504 17.4391C12.2302 17.4797 12.1101 17.5 11.97 17.5C11.8298 17.5 11.7097 17.4797 11.5896 17.4391C11.4694 17.3984 11.3593 17.3273 11.2592 17.2258L2.85037 8.66343C2.62013 8.42982 2.5 8.13527 2.5 7.78993C2.5 7.4446 2.63013 7.13989 2.8804 6.88596Z" />
      ) : (
        <path d="M12.0049 15.25C11.8769 15.25 11.7489 15.2302 11.6307 15.1905C11.5125 15.1509 11.4042 15.0816 11.3057 14.9825L6.75604 10.3948C6.57878 10.2165 6.5 9.98857 6.5 9.72104C6.5 9.45351 6.59848 9.2157 6.78559 9.03735C6.97269 8.85899 7.18935 8.75991 7.47493 8.75991C7.76052 8.75991 7.98702 8.84909 8.16428 9.03735L12.0049 12.9017L15.8653 9.00762C16.0524 8.82927 16.2789 8.75 16.5448 8.75C16.8107 8.75 17.0372 8.84909 17.2243 9.03735C17.4114 9.22561 17.5 9.45351 17.5 9.73095C17.5 10.0084 17.4114 10.2462 17.2243 10.4245L12.6943 14.9825C12.5958 15.0816 12.4875 15.1509 12.3693 15.1905C12.2511 15.2302 12.1329 15.25 11.9951 15.25H12.0049Z" />
      )}
    </CustomSvg>
  );
};
