import { call, put } from "redux-saga/effects";
import { PUSHS_GET_ERROR_ACTION, PUSHS_GET_SUCCESS_ACTION } from "../../actions/pushs";
import jsonService from "../../../_foundation/apis/staticJson/json.service";

export function* getPushsData(action: any) {
  try {
    const pushsData = yield call(jsonService.fetchStaticJson, { file: "push-list.json", isLocal: false });
    yield put(PUSHS_GET_SUCCESS_ACTION({ pushsData: pushsData?.pushs }));
  } catch (error) {
    yield put(PUSHS_GET_ERROR_ACTION(error));
  }
}
